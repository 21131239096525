import React, { useEffect, useState } from "react";
import { getExternalAccount } from "api/Stripe";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { Row, Button, Card, Typography, Drawer, Radio, Space } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";

import { getAccountInfo } from "api/Stripe";
import { GET_ACCOUNTS_BY_USER } from "graphql/queries/user/getAccountsByUser";
import { ASSIGN_BILLING_ACCOUNT } from "graphql/mutations/Stripe/assignBillingAccount";
import { BankOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCreateBillingAccount } from "hooks/stripe/account";
import utility from "common/utility";

import Panel from "./index";
import styles from "../styles.module.css";
import { isEmpty, filter, isNil } from "lodash";

const BillingAccount = () => {
  const [existingAccounts, setExistingAccounts] = useState([]);
  const [useExistingAccount, setUseExistingAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const workspace = useRecoilValue(workspaceSelected);
  const account = workspace?.account?.stripe;
  const { createAccount } = useCreateBillingAccount();

  const [getUserAccounts, { loading, error, data }] = useLazyQuery(
    GET_ACCOUNTS_BY_USER,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: async ({ getUserAccounts }) => {
        Promise.all(
          getUserAccounts?.map(async (account) => {
            const getAcountInfoData = await getAccountInfo({
              account: account?.stripe,
            });
            return getAcountInfoData?.data;
          })
        ).then((promisedData) => {
          // Filter for only taking accounts that are not required for more info
          const filteredData = filter(promisedData, (d) => {
            return isEmpty(d?.requirements?.currently_due);
          });

          setExistingAccounts(filteredData);
        });
      },
    }
  );

  const [assignBillingAccount] = useMutation(ASSIGN_BILLING_ACCOUNT, {
    onCompleted: ({ assignBillingAccount }) => {
      utility.setNotification(
        "Account Saved",
        "This Account has been saved for this workspace",
        "success",
        "topRight"
      );
      console.log(assignBillingAccount);
      getExternalAccount({ account: assignBillingAccount?.stripe }).then(
        ({ data }) => {
          setAccounts(data?.data);
        }
      );
      setUseExistingAccount(false);
    },
    onError: (error) => {
      console.log(error);
      utility.setNotification(
        "Something wrong happened",
        toString(error),
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (account) {
      getExternalAccount({ account }).then(({ data }) => {
        setAccounts(data?.data);
      });
    } else {
      getUserAccounts();
    }
  }, [account, workspace]);

  const listAccounts = () => {
    // Add if user has had an account before to reuse
    return accounts?.map((account) => {
      return (
        <Card
          className={styles.bank_account}
          style={{ width: 300 }}
          cover={
            <>
              <Row className="flex items-center">{account?.bank_name}</Row>
              <Row>XXXX{account?.last4}</Row>
            </>
          }
          actions={[
            <Button disabled type="primary" icon={<EditOutlined />}>
              Manage
            </Button>,
            <Button disabled type="primary" icon={<DeleteOutlined />}>
              Delete
            </Button>,
          ]}
        ></Card>
      );
    });
  };

  const handlePaymentDetails = () => {
    createAccount({ workspace: workspace });
  };

  const handleUseExisting = () => {
    if (existingAccounts?.length >= 1) {
      setUseExistingAccount(true);
    }
  };

  const listExistingAccounts = () => {
    return existingAccounts?.map((account) => {
      return (
        <Radio key={account?.id} value={account?.id}>
          {account?.settings?.dashboard?.display_name}
        </Radio>
      );
    });
  };

  const handleSubmitExisting = () => {
    assignBillingAccount({
      variables: {
        input: {
          workspaceId: workspace?._id,
          projectId: workspace?.project?._id,
          account: selectedAccount,
        },
      },
    });
  };

  return (
    <>
      <Panel header="Payout Account">
        {isEmpty(accounts) && ( // add check user has no accounts before
          <>
            <div className="flex">
              <BankOutlined className="mt-2" />
              <div className="ml-3">
                <Typography.Text className="font-semibold text-base mb-1">
                  Payment Method
                </Typography.Text>
                <br />
                <Typography.Text>
                  Select your saved account information or enter new account
                  information to receive payments from yout client.
                </Typography.Text>
                <br />
                <Button
                  className="mt-3"
                  key="payment_details"
                  type="primary"
                  onClick={handlePaymentDetails}
                >
                  {account ? "Continue Onboarding" : "Onboard an Account"}
                </Button>
                {!isEmpty(existingAccounts) && (
                  <Button
                    className="mt-3"
                    key="payment_details"
                    onClick={handleUseExisting}
                  >
                    Use Existing Account
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
        {listAccounts()}
      </Panel>
      <Drawer
        title="Select an account"
        width={320}
        closable={false}
        onClose={() => setUseExistingAccount(false)}
        visible={useExistingAccount}
      >
        <Radio.Group
          onChange={(e) => setSelectedAccount(e?.target?.value)}
          value={selectedAccount}
        >
          <Space direction="vertical">{listExistingAccounts()}</Space>
        </Radio.Group>
        <br />
        <Button
          disabled={isNil(selectedAccount)}
          className="mt-3"
          key="payment_details"
          type="primary"
          onClick={handleSubmitExisting}
        >
          Submit
        </Button>
      </Drawer>
    </>
  );
};

export default BillingAccount;
