import axios from "axios";

export const STRIPE_API = "https://api.stripe.com/v1/";

const Bearer = `Bearer ${
  process.env.REACT_APP_BE === "be-dev.windu.io"
    ? process.env.REACT_APP_DEV_STRIPE
    : process.env.REACT_APP_STRIPE
}`;

export const getAccountInfo = ({ account }) => {
  return axios.get(`${STRIPE_API}accounts/${account}`, {
    headers: {
      "Stripe-Account": account,
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const getExternalAccount = ({ account }) => {
  return axios.get(`${STRIPE_API}accounts/${account}/external_accounts`, {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};
