import React, { useState } from "react";
import { Table, Button } from "@fishyvisions/windu-uikit";
import { useQuery, useMutation } from "@apollo/client";
import { map, size, filter } from "lodash";
import { Row, Col, Dropdown, Menu, Popconfirm, Pagination } from "antd";
import moment from "moment";
import IntegrationModal from "./modal";
import { GET_INTEGRATIONS } from "graphql/queries/Integrations/getIntegrations";
import EmptyData from "../../../components/EmptyData";

const Integrations = () => {
  const { data, loading, refetch } = useQuery(GET_INTEGRATIONS);

  const [addIntegrationVisible, setAddIntegrationVisible] = useState(false);
  const addIntegration = () => {
    setAddIntegrationVisible(true);
  };

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (data) => <img style={{ width: "50px" }} src={data} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Client ID",
      dataIndex: "client_id",
      key: "client_id",
    },
    {
      title: "Client Secret",
      dataIndex: "client_secret",
      key: "client_secret",
    },
  ];

  return (
    <>
      <Row className="mb-5">
        <Col className="text-right" xs={24}>
          <Button onClick={addIntegration} theme="primary">
            Create Integration
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            locale={{
              emptyText: <EmptyData />,
            }}
            scroll={{ y: 400 }}
            columns={columns}
            dataSource={data?.getIntegrations}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
      <IntegrationModal
        refetch={refetch}
        onCancel={() => setAddIntegrationVisible(false)}
        isVisible={addIntegrationVisible}
        onClose={() => setAddIntegrationVisible(false)}
      />
    </>
  );
};

export default Integrations;
