import Icons from "common/icons";

const imgExtension = ["jpeg", "png", "jpg"];

export const fileIcons = {
  pdf: Icons.PDF,
  txt: Icons.TXT,
  doc: Icons.DOC,
  docx: Icons.DOC,
};

export const fileName = (file) => file.split("/").pop();
export const extension = (file) => file.split(".").pop();

export const attachedIsImage = (file) => imgExtension.includes(extension(file));

export function isImgUrl(url) {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}
