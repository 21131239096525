import { Play } from "@phosphor-icons/react";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";
import React, { useState } from "react";
import { Button, Popconfirm } from "antd";
import { useMutation } from "@apollo/client";

import _ from "lodash";
import moment from "moment";
import { useTimer } from "contexts/timer-context";

import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState, useRecoilValue } from "recoil";

import { activeActivity } from "recoil/atoms/Timer/activity";
import utility from "common/utility";
import { START_PLAN_ACTIVITY } from "graphql/mutations/member/startPlanActivity";
import { START_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/startBacklogActivity";
import ContinueWithTimebox from "components/Modals/ContinueWithTimebox";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";

const HandleStartActivity = ({ isPausedActivity, isHovered, item }) => {
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [continueWtihTimeboxModal, setContinueWtihTimeboxModal] =
    useState(false);
  const user = useRecoilValue(userSession);
  const { startHandler, resetHandler } = useTimer(); // global startHandler from context

  const [startPlannedActivity] = useMutation(START_PLAN_ACTIVITY, {
    onCompleted: ({ startPlannedActivity }) => {
      setActivity({
        active: true,
        data: startPlannedActivity,
      });
      utility.setNotification(
        "Success",
        `Your activity has started!`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
      resetHandler();
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const [startBacklogActivity] = useMutation(START_BACKLOG_ACTIVITY, {
    onError: () => {
      setActivity({
        active: false,
        data: null,
      });
      resetHandler(); // reset the counter if exists

      utility.setNotification(
        "Error",
        `Error start activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: ({ startBacklogActivity }) => {
      setActivity({
        data: startBacklogActivity,
        active: true,
      });

      utility.setNotification(
        "Success",
        `Activity has been started`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const [continueActivity] = useMutation(CONTINUE_ACTIVITY, {
    onCompleted: async ({ continueActivity }) => {
      utility.setNotification(
        "Success",
        "Activity has been continued",
        "success",
        "topRight"
      );
      setActivity({
        active: true,
        data: continueActivity,
      });
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      if (
        errorMessage !==
        "Error: There is another activity running. Please stop it before starting a new one"
      )
        setActivity({
          active: false,
          data: null,
        });

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      GET_PLAN_CALENDAR_ACTIVITY,
      GET_TODAY_WIDGET_DATA,
      GET_PAUSED_ACTIVITY,
    ],
  });

  const continueActivityFunction = (activity) => {
    const pausedCopy = _.cloneDeep(activity?.time?.paused);

    const lastPause = _.last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    const timebox = utility.calculateTimeDifference([
      activity?.end,
      activity?.start,
    ]);

    continueActivity({
      variables: {
        input: {
          activity_id: activity._id,
          time: moment.utc(new Date()),
          timebox,
          eventId: activity.id,
        },
      },
    });
  };

  const handleStart = (item) => {
    if (activity.active) {
      utility.setNotification(
        "You cannnot start this activity",
        `There is a current activity already running`,
        "error",
        "topRight"
      );
      return;
    }

    // continue activity if it is paused from an event
    if (item?.status === 2) {
      continueActivityFunction(item);
      return;
    }
    // start an activity which planned but not set event
    if (_.isEmpty(item?.planned) && _.isEmpty(item.id)) {
      const noTimebox = _.isEmpty(item?.timebox) || item?.timebox == "00:00:00";
      if (noTimebox) {
        setContinueWtihTimeboxModal(item);
      } else {
        handleStartCalback({ item, timebox: item?.timebox });
      }
      return;
    }
    // start and event of an activity which have planned
    if (!_.isEmpty(item?.planned)) {
      const timebox = utility.calculateTimeDifference([item?.end, item?.start]);
      handleStartCalback({ item, timebox, eventId: item.id });
      return;
    }
  };

  const handleStartCalback = ({ item, timebox, eventId }) => {
    const start = moment.utc();
    startHandler(start);

    if (item.backlog !== null) {
      const payload = {
        activityId: item._id,
        backlogId: item?.backlog?._id,
        date_start: start,
        timebox,
      };

      if (item?.backlog?.group) {
        // if belongs to a group set the groupid
        payload.groupId = item?.backlog?.group;
      }

      if (eventId) {
        payload.eventId = eventId;
      }
      startBacklogActivity({ variables: { input: { ...payload } } });

      const activityBeStart = {
        ...item,
        time: { paused: [] },
        created_by: {
          email: user.email,
          full_name: user.full_name,
          _id: user._id,
        },
        timebox,
      };
      setActivity({
        active: true,
        data: activityBeStart,
      });
    } else {
      const payload = {
        activity_id: item._id,
        date_start: start,
        timebox,
      };
      if (eventId) {
        payload.eventId = eventId;
      }
      startPlannedActivity({
        variables: {
          input: {
            ...payload,
          },
        },
      });

      const activityBeStart = {
        ...item,
        time: { paused: [] },
        created_by: {
          email: user.email,
          full_name: user.full_name,
          _id: user._id,
        },
        timebox,
      };
      setActivity({
        active: true,
        data: activityBeStart,
      });
    }
  };

  return (
    <div>
      {isPausedActivity ? (
        <div style={{ visibility: isHovered ? "visible" : "hidden" }}>
          <Popconfirm
            title="Are you sure to continue this activity?"
            okText="Continue"
            cancelText="Cancel"
            onConfirm={() => {
              handleStart(item);
            }}
          >
            <Button
              shape="circle"
              size="large"
              className=" bg-white  ml-4 flex items-center justify-center"
              icon={<Play size={24} color="#4e35c2" weight="fill" />}
            />
          </Popconfirm>
        </div>
      ) : (
        <div style={{ visibility: isHovered ? "visible" : "hidden" }}>
          <Popconfirm
            title="Are you sure to start this activity?"
            okText="Start"
            cancelText="Cancel"
            onConfirm={() => {
              handleStart(item);
            }}
          >
            <Button
              shape="circle"
              size="large"
              className="ml-4 flex items-center justify-center"
              style={{ backgroundColor: "#62c375" }}
              icon={<Play size={24} color="white" weight="fill" />}
            />
          </Popconfirm>
        </div>
      )}

      {continueWtihTimeboxModal && (
        <ContinueWithTimebox
          isVisible={continueWtihTimeboxModal}
          setVisible={setContinueWtihTimeboxModal}
          activity={continueWtihTimeboxModal}
          text="Keep up the great work, would you like to set timebox ?"
          fromPlanned={true}
          handleStartCalback={handleStartCalback}
        />
      )}
    </div>
  );
};

export default HandleStartActivity;
