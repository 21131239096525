import Cookies from "js-cookie";

export const parseWorkspaceIntegration = (id, key) => {
  try {
    const workspace = JSON.parse(Cookies.get(id));
    const integration = JSON.parse(workspace[key]);
    return integration;
  } catch (e) {
    return false;
  }
};

export const setWorkspaceIntegration = (id, data) => {
  const workspace = Cookies.get(id);
  if (workspace !== undefined) {
    const payload = {
      ...JSON.parse(workspace),
      ...data,
    };
    Cookies.set(id, payload, {
      expires: 30,
    });
    return payload;
  }

  return Cookies.set(id, JSON.stringify(data), {
    expires: 1,
  });
};

export const removeWorkspaceIntegration = (id, key) => {
  try {
    const workspace = JSON.parse(Cookies.get(id));
    delete workspace[key];

    Cookies.set(id, workspace, {
      expires: 30,
    });
  } catch (e) {
    return false;
  }
};

export const parseToken = (refresh_token, access_token) => {
  const object = { refresh_token, access_token };
  const token = JSON.stringify(object);
  return token;
};
