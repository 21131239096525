import React from "react";
import { FlagOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, notification } from "antd";
import moment from "moment";
import moment_tz from "moment-timezone";
import {
  sum,
  map,
  head,
  isEmpty,
  findIndex,
  orderBy,
  filter,
  last,
  find,
  split,
  includes,
  uniq,
  forEach,
  size,
  concat,
} from "lodash";
import { times } from "common/constants";
import { useQuery } from "@apollo/client";
import { LIST_PLANS } from "graphql/queries/plan/listPlans";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { convertFromRaw } from "draft-js";
import { Flag } from "@phosphor-icons/react";

const handleZero = (number) => {
  const digits = number.toString().length;
  return digits === 1 || number < 0 ? `0${number}` : number;
};

const utility = {
  prettyTime(time) {
    const duration = moment.duration(time, "seconds");
    const formatted = duration.format("hh:mm:ss");
    return formatted;
  },
  intoTimeFormat(time, value) {
    switch (time) {
      case 0:
      case 1:
      case 2:
        return `00:00:${value}`;
      case 4:
      case 5:
        return `00:${value}`;
      default:
        return value;
    }
  },
  getIntegrationBarWidth(elem) {
    const computedStyle = window.getComputedStyle(elem, null);
    const screenWidth = window.screen.width;
    const navWidth = (100 / screenWidth) * 100;
    const bodyPercentage =
      ((screenWidth - parseInt(computedStyle["width"])) / screenWidth) * 100;

    return bodyPercentage - navWidth;
  },
  doesDataExist(data) {
    if (data) {
      return true;
    }
    return false;
  },
  getAvatar(data) {
    return data.avatar ? (
      <Avatar src={data.avatar} />
    ) : (
      <Avatar icon={<UserOutlined />} />
    );
  },
  getCurrencySymbol(currency) {
    switch (currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "JPY":
        return "¥";
      case "GBP":
        return "£";
      case "CAD":
        return "$";
      case "CHF":
        return "CHF";
      case "CNH":
        return "¥";
      case "SEK":
        return "kr";
      case "NZD":
        return "$";
      default:
        return "$";
    }
  },

  getTotal(client, selectedActivities, rate) {
    const mapTotal = selectedActivities.map((activity) => {
      const time = moment(activity?.time?.total_time, "hh:mm:ss");
      const hours = time.hour();
      const minutes = time.minute();

      if (minutes <= 40 && minutes > 15) {
        return hours * rate + rate / 2;
      }
      if (minutes > 40) {
        return (hours + 1) * rate;
      }
      return hours * rate;
    });
    return `${utility.getCurrencySymbol(client.currency)}${sum(mapTotal)}`;
  },

  downloadInvoice(blob, fileName) {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    window.addEventListener("focus", (e) => URL.revokeObjectURL(link.href), {
      once: true,
    });
  },
  generateTimes(date) {
    const x =
      moment(date?.time?.end?.date).diff(
        moment(date?.time?.start?.date),
        "days"
      ) + 1;

    return new Array(x).fill(times).flat();
  },

  setNotification(title, description, type, placement, onClick = () => {}) {
    const getTypeColor = () => {
      switch (type) {
        case "success":
          return "bg-green-500";
        case "error":
          return "bg-red-500";
        case "info":
          return "bg-blue-500";
        case "warning":
          return "bg-yellow-500";
        default:
          return "bg-white";
      }
    };

    notification.open({
      message: title,
      description,
      className: `${getTypeColor()} text-white`,
      onClick: () => onClick(),
      placement,
    });
  },
  calculateTimeDifference(times) {
    let a = Math.max(
      moment.duration(times[0])._milliseconds,
      moment.duration(times[1])._milliseconds
    );
    let b = Math.min(
      moment.duration(times[0])._milliseconds,
      moment.duration(times[1])._milliseconds
    );

    const totalhours = moment.duration(a).subtract(b);

    var ms = totalhours._milliseconds;
    var ticks = ms / 1000;
    var hh = handleZero(Math.floor(ticks / 3600));
    var mm = handleZero(Math.floor((ticks % 3600) / 60));
    var ss = handleZero(ticks % 60);

    return `${hh}:${mm}:${ss}`;
  },
  calculateTimeDifferenceV2(start, end) {
    const difference = moment(end, "MM/DD/YYYY HH:mm:ss").diff(
      moment(start, "MM/DD/YYYY HH:mm:ss")
    );
    const diff = moment
      .duration(difference, "milliseconds")
      .format("HH:mm:ss", { trim: false });
    return diff;
  },

  calculatePausedTime(activity) {
    const start = moment(
      new Date(activity?.time?.start),
      "MM/DD/YY HH:mm:ss"
    ).format("MM/DD/YY hh:mm:ss");

    const pausedTime = map(activity?.time?.paused);
    if (head(pausedTime)?.continue !== null) {
      // Activity has been continued
      return pausedTime.reduce((previous, current, idx) => {
        const paused = moment(
          new Date(current?.time),
          "MM/DD/YY HH:mm:ss"
        ).format("MM/DD/YY hh:mm:ss");
        const continued = previous?.continue
          ? moment(new Date(previous?.continue), "MM/DD/YY HH:mm:ss").format(
              "MM/DD/YY hh:mm:ss"
            )
          : null;

        if (idx === 0) {
          // Only on the first iteration we take the difference from start and paused
          return {
            ...current,
            total: utility.calculateTimeDifferenceV2(start, paused),
          };
        }
        if (continued) {
          return {
            ...current,
            total: utility.calculateTimeTotal([
              utility.calculateTimeDifferenceV2(continued, paused),
              previous?.total,
            ]),
          };
        }
        return previous;
      }, null);
    }

    return pausedTime.reduce((previous, current) => {
      let paused = moment(new Date(current?.time), "MM/DD/YY HH:mm:ss").format(
        "MM/DD/YY hh:mm:ss"
      );

      return { total: utility.calculateTimeDifferenceV2(start, paused) };
    }, start);
  },
  calculateTimeTotal(times) {
    // add array of times together in time format
    const totalhours = times
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(times[0])
      );
    var ms = totalhours._milliseconds;
    var ticks = ms / 1000;
    var hh = handleZero(Math.floor(ticks / 3600));
    var mm = handleZero(Math.floor((ticks % 3600) / 60));
    var ss = handleZero(ticks % 60);

    return `${hh}:${mm}:${ss}`;
  },

  timeIsValidForRender(activity, time, date) {
    const startTime = Number(
      moment.utc(activity.time.start).local().format("HH")
    );
    const endTime = Number(moment.utc(activity.time.end).local().format("HH"));
    const now = Number(time?.number);

    if (endTime < startTime) {
      /// For handling times that were paused and  carried over in the next days

      const filterPausedTimes = activity?.paused.filter(
        (record) =>
          moment(record.time, "MM/DD/YY HH:mm:ss").format("MM/DD/YY") === date
      );
      return (
        startTime <= now &&
        Number(
          moment(filterPausedTimes[0]?.time, "MM/DD/YY HH:mm:ss").format("HH")
        ) >= now
      );
    }

    return startTime <= now && now <= endTime;
  },

  getActivitiesBars(activity, column, row, timezone) {
    const {
      time: { start, end, paused },
    } = activity;

    const pausedDay = filter(paused, (pause) => {
      if (
        moment_tz(new Date(pause.time)).tz(timezone).format("DD") ===
        moment(new Date(row)).format("DD")
      ) {
        return pause;
      }
    });

    const cell = Number(column);
    const nroPauses = pausedDay.length;

    if (nroPauses > 0) {
      const activityTime = map(pausedDay, (pauses, index) => {
        if (index === 0) {
          return {
            start,
            start_hour: Number(
              moment_tz(new Date(start)).tz(timezone).format("HH")
            ),
            end_hour: Number(
              moment_tz(new Date(pauses.time)).tz(timezone).format("HH")
            ),
            originalE: moment_tz(new Date(pauses.time))
              .tz(timezone)
              .format("YY-MM-DD hh:mm:ss a"),
            originalS: moment_tz(new Date(start))
              .tz(timezone)
              .format("YY-MM-DD hh:mm:ss a"),
            end: pauses.time,
            active: true,
          };
        }
        return {
          originalE: moment_tz(new Date(pauses.time))
            .tz(timezone)
            .format("YY-MM-DD hh:mm:ss a"),
          originalS: moment_tz(pausedDay[index - 1].continue)
            .tz(timezone)
            .format("YY-MM-DD hh:mm:ss a"),
          start: pausedDay[index - 1].continue,
          start_hour: Number(
            moment_tz(new Date(pausedDay[index - 1].continue))
              .tz(timezone)
              .format("HH")
          ),
          end_hour: Number(
            moment_tz(new Date(pauses.time)).tz(timezone).format("HH")
          ),
          end: pauses.time,
          active: true,
        };
      });

      activityTime.push({
        originalE: moment_tz(new Date(end))
          .tz(timezone)
          .format("YY-MM-DD hh:mm:ss a"),
        originalS: moment_tz(last(pausedDay).continue)
          .tz(timezone)
          .format("YY-MM-DD hh:mm:ss a"),
        start_hour: Number(
          moment_tz(last(pausedDay).continue).tz(timezone).format("HH")
        ),
        end_hour: Number(moment_tz(end).tz(timezone).format("HH")),
        start: last(pausedDay).continue,
        end,
        active: true,
      });

      let pauseInterval = [];
      if (localStorage.getItem("showPauses") === "true") {
        pauseInterval = map(pausedDay, (pauses) => {
          return {
            start_hour: Number(
              moment_tz(pauses.time).tz(timezone).format("HH")
            ),
            end_hour: Number(
              moment_tz(pauses.continue).tz(timezone).format("HH")
            ),
            start: pauses.time,
            end: pauses.continue,
            active: false,
          };
        });
      }

      const timegather = [...activityTime, ...pauseInterval];

      const times = filter(
        timegather,
        (time) => time.start_hour <= cell && time.end_hour >= cell
      );

      return times;
    }

    const startTimeHour = Number(moment_tz(start).tz(timezone).format("HH"));
    const endTimeHour = Number(moment_tz(end).tz(timezone).format("HH"));

    if (startTimeHour <= cell && endTimeHour >= cell)
      return [
        {
          ...activity,
          start: activity.time.start,
          end: activity.time.end,
          active: true,
        },
      ];

    return [];
  },

  // Used for rendering activity bars to ensure it is rendered within the appropriate time block
  continueTimeIsValidForRender(activity, date, time) {
    const filterPausedTimes = activity?.paused.filter(
      (record) =>
        moment(record.continue, "MM/DD/YY HH:mm:ss").format("MM/DD/YY") === date
    );
    if (!isEmpty(filterPausedTimes)) {
      const now = Number(time);
      const startTime = Number(
        moment(filterPausedTimes[0]?.continue, "MM/DD/YY HH:mm:ss").format("HH")
      );
      const endTime = Number(activity?.time?.end?.hour);
      return startTime <= now && now <= endTime;
    }
    return false;
  },
  reFormatPausedTime(paused) {
    const pausedTimes = map(paused, (pausedActivity) => {
      return {
        _id: pausedActivity?._id,
        minute: parseInt(
          moment.utc(pausedActivity?.time).local().format("mm") * 1.21 // 1.21 is the amount of minutes within a given according to width of calendar hour
        ),
        hour: moment.utc(pausedActivity?.time).local().format("HH"),
        date: moment.utc(pausedActivity?.continue).local().format("MM/DD/YY"),
      };
    });
    return pausedTimes;
  },
  calculatePausedWidth(activity, paused) {
    const arrayLength = activity?.time?.paused.length - 1;
    const pausedIndex = findIndex(activity?.time?.paused, ["_id", paused?._id]);
    const isLastPaused = arrayLength === pausedIndex;
    if (isLastPaused) {
      return utility.calculateTimeDifferenceV2(
        moment
          .utc(activity?.time?.paused[pausedIndex]?.continue)
          .format("MM/DD/YY HH:mm:ss"),
        moment.utc(activity?.time?.end).format("MM/DD/YY HH:mm:ss")
      );
    } else {
      // If not last take next paused time as stop
    }
  },
  orderActivitiesByDate(activities) {
    const sortedActivity = orderBy(
      activities,
      (activity) => moment(activity.planned_date).format("YYYYMMDD"),
      ["asc"]
    );

    return sortedActivity;
  },
  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  },
  usePermissions(element) {
    const user = useRecoilValue(userSession);
    let location = useLocation();
    const { loading: listPlansLoading, data } = useQuery(LIST_PLANS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

    const plan = find(
      data?.listPlans,
      (plan) => plan?.name === user?.plan?.name
    );
    switch (location?.pathname) {
      case "/projects":
        return find(plan?.permissions, (permission) => {
          return permission?.role?.key === "MAX_PROJECTS";
        });

      case "/timeline":
        return find(plan?.permissions, (permission) => {
          return permission?.role?.key === "MAX_HISTORICAL";
        });
      default:
        break;
    }
  },
  donwloadHanlder({ url }) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", `invoice.pdf`);
    document.body.appendChild(link);
    link.click();
  },

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  hasInvoiceAttached(url) {
    const isInvoices = url.split("/")[3] === "invoice";
    if (isInvoices) {
      const params = queryString.parse(last(split(url, "?")));
      return params.id;
    }
    return false;
  },
  addTimeToDate(date, time = "00:00:00") {
    const [h, m, s] = split(time, ":");
    let dateUpdated = date;

    dateUpdated = moment(dateUpdated).add(Number(h), "hours").format();
    dateUpdated = moment(dateUpdated).add(Number(m), "minutes").format();
    dateUpdated = moment(dateUpdated).add(Number(s), "seconds").format();

    return dateUpdated;
  },
  getDatesInRange(startDate, endDate) {
    var startDate = moment(startDate);
    var endDate = moment(endDate);
    var datesBetween = [];

    var startingMoment = startDate;

    while (startingMoment <= endDate) {
      datesBetween.push(startingMoment.clone()); // clone to add new object
      startingMoment.add(1, "days");
    }

    return datesBetween;
  },
  convertToMins(time) {
    const hours = Number(time?.split(":")[0]);
    const mins = Number(time?.split(":")[1]);
    const secs = Number(time?.split(":")[2]);
    const totalMins = hours * 60 + mins + 0.0166667 * secs;
    return totalMins;
  },
  convertMinutesToHHMMSS(minutes) {
    if (isNaN(minutes) || minutes < 0) {
      return "00:00:00"; // Handle invalid input
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    const seconds = Math.round((minutes % 1) * 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  },

  truncateFileName(fileName) {
    const maxLength = 20; // Maximum length of the truncated file name
    const extension = fileName.split(".").pop(); // Get the file extension
    const truncatedName = fileName.slice(0, maxLength - extension.length - 4); // Subtract 4 for '****' and '.' characters
    return `${truncatedName}****.${extension}`;
  },
  convertTimeboxInMins(timebox) {
    const hours = Number(timebox?.split(":")[0]);
    const mins = Number(timebox?.split(":")[1]);
    const totalMins = hours * 60 + mins;

    return totalMins;
  },
  getPriority(priority) {
    const priorityOptions = [
      {
        name: "Not Set",
        color: "lightgrey",
      },
      {
        name: "Low",
        color: "green",
      },
      {
        name: "Medium",
        color: "orange",
      },
      {
        name: "High",
        color: "red",
      },
    ];

    return priorityOptions[priority] || priorityOptions[0];
  },
  getPlainText(editorData) {
    return editorData
      ? convertFromRaw(JSON.parse(editorData)).getPlainText()
      : "";
  },

  haveInvoicingAccess(user) {
    if (user?.plan && user?.addons) {
      const hasInvoicingAddon = user.addons.some(
        (addon) => addon.name === "Invoicing"
      );
      if (hasInvoicingAddon) {
        return true;
      }
    }
    if (
      user?.extra &&
      user.extra?.foreignPlan &&
      user.extra.foreignPlan?.addons
    ) {
      const hasInvoicingAddon = user.extra.foreignPlan.addons.some((addon) =>
        addon.addons.includes("Invoicing")
      );
      if (hasInvoicingAddon) {
        return true;
      }
    } else return false;
  },
  useAddonChecker(addon) {
    const user = useRecoilValue(userSession);
    const reFormatUserAddonsResponse = user?.addons.map((addon) => {
      return addon?.name;
    });
    const reformatForeignAddonsResponse = user?.extra?.foreignPlan?.addons.map(
      (addon) => {
        return addon?.name;
      }
    );
    const addons = uniq([
      ...reformatForeignAddonsResponse,
      ...reFormatUserAddonsResponse,
    ]);
    return includes(addons, addon);
  },
  backlogDataFormatter({ backlogData = null, timezone }) {
    let result = {};
    const subgroups = [];
    if (backlogData) {
      const groupData = filter(backlogData.items, (item) => {
        // check if group has subgroups
        forEach(item.items, (subitem) => {
          if (subitem.type === "backlog_sub_group") {
            subgroups.push(subitem);
          }
        });

        const newActivityItem = {
          ...item,
          activities: item.items,
        };

        return newActivityItem.type === "backlog_group";
      });

      const gatherGroups = concat(groupData, subgroups);
      result.gatherGroups = gatherGroups;

      let grpActivities = [];
      forEach(gatherGroups, (grp) => {
        grpActivities.push(
          ...map(grp.items, (item) => {
            return {
              ...item,
              grp_id: grp._id,
            };
          })
        );
      });

      const activityData = filter(
        backlogData.items,
        (item) => item.type === "backlog_activity"
      );

      const totalActivites = [...activityData, ...grpActivities];
      const withDates = filter(totalActivites, (item) => item.planned_date);

      const outDatedActivites = filter(
        withDates,
        (item) =>
          moment(item.planned_date).tz(timezone).isBefore(moment()) &&
          !moment(item.planned_date).tz(timezone).isSame(moment(), "day")
      );

      result.outDatedActivites = outDatedActivites;
      result.withDates = withDates;

      const treeData = {
        rootId: backlogData._id,
        items: {
          [backlogData._id]: {
            id: backlogData._id,
            children: map(backlogData.items, (item) => item._id),
            hasChildren: true,
            isExpanded: true,
            container: true,
            root: true,
            data: {
              title: "root",
              type: "root",
            },
          },
        },
      };

      // here start the map
      map(backlogData.items, (item) => {
        const treeItem = {
          [item._id]: {
            id: item._id,
            children: [...map(item.items, (subItems) => subItems._id)],
            hasChildren: size(item.items) > 0,
            isExpanded: localStorage.getItem(item._id) === "true",
            container: item.type === "backlog_group",
            data: {
              _id: item._id,
              title: item.title,
              description: item.description,
              details: item.details,
              priority: item.priority,
              type: item.type,
              assigned: item.assigned,
              planned: item.planned_date,
              tags: item.tags,
              content: item?.content,
              timebox: item?.timebox,
              created_by: item?.created_by,
              created_at: item?.created_at,
              fileSources: item?.fileSources,
              start_date: item?.start_date,
              end_date: item?.end_date,
              tasks: item?.tasks,
              playbook: item?.playbook,
              key: item?.key,
            },
          },
        };
        treeData.items = { ...treeItem, ...treeData.items };

        // add group child to the main list too
        if (item.type === "backlog_group") {
          map(item.items, (subItems) => {
            if (subItems.type === "backlog_sub_group") {
              const treeItem = {
                [subItems._id]: {
                  id: subItems._id,
                  children: [
                    ...map(subItems.activities, (activity) => activity._id),
                  ],
                  hasChildren: size(subItems.activities) > 0,
                  isExpanded: localStorage.getItem(subItems._id) === "true",
                  container: subItems.type === "backlog_sub_group",
                  data: {
                    _id: subItems._id,
                    title: subItems.title,
                    description: subItems.description,
                    details: subItems.details,
                    priority: subItems.priority,
                    type: subItems.type,
                    assigned: subItems.assigned,
                    planned: subItems.planned_date,
                    tags: subItems.tags,
                    content: subItems?.content,
                    timebox: subItems?.timebox,
                    created_by: subItems?.created_by,
                    created_at: subItems?.created_at,
                    fileSources: subItems?.fileSources,
                    start_date: subItems?.start_date,
                    end_date: subItems?.end_date,
                    type: "backlog_sub_group",
                    tasks: subItems?.tasks,
                    playbook: subItems?.playbook,
                    key: item?.key,
                  },
                },
              };
              treeData.items = { ...treeItem, ...treeData.items };
              map(subItems.activities, (activity) => {
                const treeItem = {
                  [activity._id]: {
                    id: activity._id,
                    children: [],
                    hasChildren: false,
                    isExpanded: false,
                    container: false,
                    data: {
                      _id: activity._id,
                      title: activity.title,
                      description: activity.description,
                      details: activity.details,
                      priority: activity.priority,
                      type: "backlog_activity", // by default
                      assigned: activity.assigned,
                      planned: activity.planned_date,
                      tags: activity.tags,
                      content: activity?.content,
                      timebox: activity?.timebox,
                      created_by: activity?.created_by,
                      created_at: activity?.created_at,
                      fileSources: activity?.fileSources,
                      start_date: activity?.start_date,
                      end_date: activity?.end_date,
                      tasks: activity?.tasks,
                      playbook: activity?.playbook,
                      key: item?.key,
                    },
                  },
                };
                treeData.items = { ...treeItem, ...treeData.items };
              });
            } else {
              const treeItem = {
                [subItems._id]: {
                  id: subItems._id,
                  children: [],
                  hasChildren: false,
                  isExpanded: false,
                  container: false,
                  data: {
                    _id: subItems._id,
                    title: subItems.title,
                    description: subItems.description,
                    details: subItems.details,
                    type: "backlog_activity", // by default
                    assigned: subItems.assigned,
                    planned: subItems.planned_date,
                    tags: subItems.tags,
                    content: subItems?.content,
                    timebox: subItems?.timebox,
                    created_by: subItems?.created_by,
                    created_at: subItems?.created_at,
                    fileSources: subItems?.fileSources,
                    start_date: subItems?.start_date,
                    end_date: subItems?.end_date,
                    tasks: subItems?.tasks,
                    playbook: subItems?.playbook,
                    priority: subItems.priority,
                    key: item?.key,
                  },
                },
              };
              treeData.items = { ...treeItem, ...treeData.items };
            }
          });
        }
      });

      result.treeData = treeData;
    }

    return result;
  },

  haveChatAccess(user) {
    // Check if the user has a plan and the plan has the "Chat" addon
    if (user?.plan && user?.addons) {
      const hasChatAddon = user.addons.some((addon) => addon.name === "Chat");
      if (hasChatAddon) {
        return true;
      }
    }
    if (
      user?.extra &&
      user.extra?.foreignPlan &&
      user.extra.foreignPlan?.addons
    ) {
      const hasChatAddon = user.extra.foreignPlan.addons.some((addon) =>
        addon.addons.includes("Chat")
      );
      if (hasChatAddon) {
        return true;
      }
    } else return false;
  },

  getPriorityFlag(currentPriority) {
    const priorityColors = {
      0: "rgb(224 222 222)",
      3: "red",
      2: "orange",
      1: "green",
    };
    const color = priorityColors[currentPriority] || "rgb(224 222 222)";

    return <Flag size={24} weight="fill" style={{ fill: color }} />;
  },
};

export default utility;
