import React from "react";
import { Row, Col, Button, Typography } from "antd";
import styles from "../styles.module.css";

const { Text } = Typography;

const Auth = ({ logo, text, onConnect, loading }) => {
  return (
    <Row style={{ display: "flex", alignItems: "center" }}>
      <Col md={2}>
        <img style={{ width: 50 }} src={logo} alt="Logo" />
      </Col>
      <Col md={15}>
        <div style={{ width: "80%", margin: "auto" }}>
          <Text>{text}</Text>
        </div>
      </Col>
      <Col md={7}>
        <Button
          onClick={onConnect}
          type="primary"
          size="large"
          color="#029875"
          className={styles.connectBtn + " px-7 w-full"}
          loading={loading}
        >
          Connect
        </Button>
      </Col>
    </Row>
  );
};

export default Auth;
