// Import the functions you need from the firebase SDKs
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  deleteToken,
  onMessage,
} from "firebase/messaging";
import { UI_URL } from "common/constants";
import { UNREGISTER_DEVICE } from "graphql/mutations/device/unregisterDevice";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

// show push notifications when the app/site is active/opened
onMessage(messaging, (payload) => {
  console.log(
    "[firebase-messaging-sw.js] Received foreground message ",
    payload
  );

  const { notification } = payload;
  // create a new notification object

  new Notification(notification.title, {
    body: notification.body,
  });
});

export function getFCMToken() {
  console.log("Requesting permission...");

  // Convert environment variables to URL `search` parameters
  const fbConfig = new URLSearchParams(firebaseConfig).toString();

  // Service worker URL w/config variables
  const swUrl = `${UI_URL()}/firebase-messaging-sw.js?${fbConfig}`;

  return navigator.serviceWorker.register(swUrl).then((registration) => {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Add the public key generated from the console here.
        return getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_ID,
        });
      } else {
        console.log("Do not have permission");
      }
    });
  });
}

export function deleteFCMToken(client) {
  deleteToken(messaging).then((res) => {
    console.log("token removed ", res);
    const token = localStorage.getItem("FCMToken");

    // if there is a token register, unregister it
    if (token) {
      client.mutate({
        mutation: UNREGISTER_DEVICE,
        variables: { token },
      });
      localStorage.removeItem("FCMToken");
    }
  });
}
