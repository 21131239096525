import React from "react";
import { Avatar } from "antd";

const UserAvatar = ({ user, ...props }) => {
  if (user?.avatar?.length < 1)
    return (
      <Avatar
        {...props}
        src={`https://ui-avatars.com/api/?name=${user?.full_name}&bold=true&background=7263CC&color=FFFFFF`}
      />
    );

  if (user?.avatar) return <Avatar src={user?.avatar} {...props} />;

  return (
    <Avatar {...props} src={`https://ui-avatars.com/api/?name=N+A&bold=true`} />
  );
};

export default UserAvatar;
