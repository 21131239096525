import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Card,
  Typography,
  Button,
  Popover,
  Row,
  Form as AntForm,
  Col,
  Upload,
  Progress,
  Dropdown,
  Spin,
  Checkbox,
  Input,
  Tooltip,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Editor } from "react-draft-wysiwyg";
import _ from "lodash";
import { map, uniqBy, debounce, isEmpty } from "lodash";
import { Form, Field, useFormikContext } from "formik";

import {
  AntDatePicker,
  AntInput,
  AntSelect,
} from "components/FormikCustomInputs";
import { GET_PROJECTS } from "graphql/queries/projects/getProjects";
import { GET_PROJECT_TAGS } from "graphql/queries/projects/getProjectTags";
import Loader from "components/Loader";
import { useLazyQuery } from "@apollo/client";

import { userSession } from "recoil/atoms/User/UserSession";

import utility from "common/utility";

import { TimeBoxField } from "components/CustomComponents/TimeboxInput";
import { GET_USER_FREE_TIME } from "graphql/queries/timebox/getUserFreeTime";
import moment from "moment";
import { useRecoilValue } from "recoil";
import AssigneSelectDropdown from "../NewBacklogActivity/AssigneSelectDropdown";
import UserAvatar from "components/UserAvatar";
import CalendarPicker from "components/CalendarPicker";
import { Checks } from "@phosphor-icons/react";

const gutter = [10, 0];
const { Text } = Typography;

const defaultProjectFilters = {
  status: "Active",
  sort: `{"updated_at": -1}`,
  relationship: "",
};

const toolbarOptions = {
  options: ["inline", "list"],
};

const defaultParameters = {
  size: 10,
  offset: 0,
  search: "",
  filters: defaultProjectFilters,
};

const NewActivityContent = ({
  setVisibleView,
  visibleView,
  form,
  isVisible,
  onClose,
  progress,
  creating,
  title,
}) => {
  const [editorState, seteditorState] = useState();
  const [queryParameters, setQueryParameters] = useState(defaultParameters);
  const [projectTags, setProjectTags] = React.useState([]);
  const [projects, setProjects] = useState([]);
  const [files, setFiles] = React.useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const getDefaultUsers = () => {
    if (selectedProject) {
      const { manager, members, created_by } = selectedProject;
      const allMembers = _.cloneDeep(members);
      allMembers.push(created_by);

      if (manager) {
        allMembers.push(manager);
      }

      const gatherMembers = _.uniqBy([...new Set([...allMembers])], "email");

      if (gatherMembers) return gatherMembers;

      return [];
    } else return [];
  };
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);

  const user = useRecoilValue(userSession);

  const [newTask, setNewTask] = useState({
    _id: _.uniqueId("newtask-"),
    description: null,
    planned: null,
    assigned: null,
    completed: false,
  });

  const [userFreetime, setUserFreetime] = useState("180");
  const { values, setFieldValue, submitCount, errors, handleSubmit } =
    useFormikContext();

  const props = {
    name: "file",
    multiple: true,
    accept: ".txt, .doc, .docx, .jpg, .jpeg, .png, .pdf",
    customRequest: ({ onSuccess }) => {
      onSuccess("ok");
    },
    onChange({ fileList }) {
      setFiles((prevFiles) => _.uniqBy([...prevFiles, ...fileList], "uid"));
    },
  };

  useEffect(() => {
    if (files) {
      setFieldValue("files", files);
    }
  }, [files]);

  useEffect(() => {
    setFilterSearchUser(getDefaultUsers());
  }, [selectedProject]);

  const [getProjects, { loading }] = useLazyQuery(GET_PROJECTS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjects }) => {
      const gatherProjects = uniqBy(
        [...new Set([...projects, ...getProjects.data])],
        "_id"
      );

      setProjects(gatherProjects);
    },
  });

  const [getUserFreeTime, { loading: freeTimeloading }] = useLazyQuery(
    GET_USER_FREE_TIME,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          userId: user._id,
          date: values?.date ? values?.date : moment().format(),
        },
      },
      onCompleted: ({ getUserFreeTime }) => {
        setUserFreetime(getUserFreeTime);
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    getUserFreeTime();
  }, []);

  const [getProjectTags] = useLazyQuery(GET_PROJECT_TAGS, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjectTags }) => {
      const tags = getProjectTags?.tags || [];
      setProjectTags(tags);
    },
  });

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setQueryParameters({
        ...queryParameters,
        offset: queryParameters.offset + 10,
      });
      target.scrollTo(0, target.scrollHeight);
    }
  };
  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };
  const onSubmit = () => form.current.submitForm();

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  useEffect(() => {
    if (isVisible) {
      getProjects();
    }
  }, [isVisible]);

  const handleProjectTags = (v) => {
    getProjectTags({ variables: { input: { projectId: v } } });
  };

  const handleOnClose = () => {
    onClose();
  };

  const editorStateHandler = (v) => {
    seteditorState(v);
    setFieldValue("details", v);
  };

  const timeboxDropdown = () => {
    return (
      <AntForm.Item
        labelCol={{ span: 24 }}
        style={{ background: "#F8F8F8", width: 620, padding: 12 }}
        className="rounded"
        label={
          <div>
            <Typography.Text className="mr-2">Timebox</Typography.Text>
            <Popover
              content={
                <div className="mr-2 w-96">
                  <Typography.Text>
                    Our Time-Boxing technology helps make suggestions for a
                    user's workload, with rules, by analyzing a user's behavior,
                    and workload. This helps to determine when work time begins
                    affecting health, which is where the chances of burnout
                    increase.
                  </Typography.Text>
                </div>
              }
            >
              <QuestionCircleOutlined />
            </Popover>
          </div>
        }
      >
        <Spin spinning={freeTimeloading} indicator={<LoadingOutlined spin />}>
          <TimeBoxField
            freeTime={userFreetime}
            onChange={(v) => setFieldValue("timebox", v)}
          />
        </Spin>
      </AntForm.Item>
    );
  };
  const handleSearch = (v) => {
    if (selectedProject) {
      const { manager, members, created_by } = selectedProject;
      const allMembers = [...members, created_by];

      if (manager) {
        allMembers.push(manager);
      }

      const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");

      const matches = gatherMembers.filter(
        (member) =>
          new RegExp(v, "i").test(member.full_name) ||
          new RegExp(v, "i").test(member.email)
      );

      setFilterSearchUser(_.isEmpty(v) ? gatherMembers : matches);
    }
  };

  const handleAddNewTask = () => {
    setFieldValue("tasks", [...values?.tasks, newTask]);
    setNewTask({
      _id: _.uniqueId("newtask-"),
      description: null,
      planned: null,
      assigned: null,
      completed: false,
    });
  };

  return (
    <Card
      title={title}
      bodyStyle={{ padding: "14px 28px" }}
      extra={
        visibleView === "new" ? (
          <div className="flex items-center">
            <Dropdown
              overlay={() => timeboxDropdown()}
              placement={"bottomRight"}
            >
              <div className={styles.timeboxDiv}>
                <ClockCircleOutlined style={{ color: "#4E35C2" }} />
                <Text className="ml-2" style={{ color: "#4E35C2" }}>
                  {values?.timebox ? values?.timebox : "00:00:00"}
                </Text>
              </div>
            </Dropdown>
            <CloseOutlined className="ml-3" onClick={handleOnClose} />
          </div>
        ) : (
          <DownOutlined onClick={() => setVisibleView("new")} />
        )
      }
      className={visibleView === "duplicate" ? styles.hideBodyStyle : "mt-5"}
    >
      <div style={{ display: visibleView !== "new" && "none" }}>
        <Form onSubmit={handleSubmit} className={styles.mainFrom}>
          <Row gutter={gutter}>
            <Col
              xs={14}
              style={{ height: 510, overflowX: "hidden", paddingBottom: 14 }}
            >
              <Field
                placeholder="Activity title"
                required={true}
                label="Title"
                name="title"
                size="large"
                hasFeedback={false}
                component={AntInput}
                help={false}
                style={{ marginBottom: "0px" }}
                spellCheck={true}
              />
              <div
                className={`${styles.editorDiv} ${
                  submitCount > 0 && errors.details && styles.editorErrorDiv
                } `}
              >
                <Field
                  placeholder="What are you going to accomplish?"
                  required
                  render={({ field }) => (
                    <AntForm.Item
                      required
                      labelCol={{ span: 24 }}
                      label="Details"
                      validateStatus={
                        submitCount > 0 && errors.details ? "error" : ""
                      }
                      help={
                        submitCount > 0 && errors.details ? errors.details : ""
                      }
                    >
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(editorState) =>
                          editorStateHandler(editorState)
                        }
                        wrapperClassName="flex flex-col-reverse"
                        placeholder="What are you going to accomplish?"
                        toolbar={toolbarOptions}
                        spellCheck={true}
                      />
                    </AntForm.Item>
                  )}
                />
              </div>
              <div className="flex items-center mb-2">
                <Checks size={26} style={{ color: "#7CBF7E" }} />
                <Text className="ml-2">Tasks</Text>
              </div>
              {/* new task */}
              <div className={styles.newTaskDiv}>
                <div>
                  <div className="flex items-start">
                    <Checkbox
                      defaultChecked={newTask.completed}
                      checked={newTask.completed}
                      onChange={(v) =>
                        setNewTask({ ...newTask, completed: v.target.checked })
                      }
                    />
                    <div className="ml-3 flex-1">
                      <Input
                        value={newTask.description}
                        spellCheck={true}
                        onChange={(v) => {
                          setNewTask({
                            ...newTask,
                            description: v.target?.value,
                          });
                        }}
                      />
                      <div className="flex justify-between mt-1 ml-1 ">
                        <div className="flex items-center ">
                          <Dropdown
                            trigger={["click"]}
                            overlay={() => (
                              <AssigneSelectDropdown
                                handleSearch={handleSearch}
                                values={values}
                                filterSearchUser={filterSearchUser}
                                setValue={(v) =>
                                  setNewTask({ ...newTask, assigned: v })
                                }
                                value={newTask?.assigned}
                              />
                            )}
                          >
                            {newTask?.assigned ? (
                              <div className="cursor-pointer">
                                <UserAvatar
                                  size={24}
                                  user={newTask?.assigned}
                                />
                                <Text
                                  className="ml-2 text-xs underline"
                                  style={{ color: "#A5ACB8" }}
                                >
                                  {newTask?.assigned?.full_name}
                                </Text>
                              </div>
                            ) : (
                              <div className="flex items-center cursor-pointer">
                                <UserAddOutlined style={{ color: "#A5ACB8" }} />
                                <Text
                                  style={{ color: "#A5ACB8" }}
                                  className="text-xs ml-1 underline"
                                >
                                  Assignee
                                </Text>
                              </div>
                            )}
                          </Dropdown>
                          <div
                            className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                          >
                            <CalendarPicker
                              initialDate={moment()}
                              onDateChange={(date) =>
                                setNewTask({ ...newTask, planned: date })
                              }
                              label={
                                <Text
                                  className="text-xs underline"
                                  style={{ color: "#A5ACB8" }}
                                >
                                  {newTask?.planned
                                    ? moment(newTask?.planned).format(
                                        "dddd, MMMM D"
                                      )
                                    : "Schedule"}
                                </Text>
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            size="small"
                            onClick={handleAddNewTask}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* display the tasks */}
              {_.map(values?.tasks, (task) => {
                return (
                  <div className={styles.newTaskDiv}>
                    <div>
                      <div className="flex items-start">
                        <Checkbox
                          defaultChecked={task.completed}
                          onChange={(v) => {
                            const updatedTasks = values.tasks.map((t) =>
                              t._id === task._id
                                ? { ...t, completed: v.target.checked }
                                : t
                            );
                            setFieldValue("tasks", updatedTasks);
                          }}
                        />
                        <div className="ml-3 flex-1">
                          <div>
                            <Text
                              editable={{
                                onChange: (newDescription) => {
                                  const updatedTasks = values.tasks.map((t) =>
                                    t._id === task._id
                                      ? { ...t, description: newDescription }
                                      : t
                                  );
                                  setFieldValue("tasks", updatedTasks);
                                },
                                icon: (
                                  <EditOutlined style={{ color: "#A5ACB8" }} />
                                ),
                              }}
                            >
                              {task?.description}
                            </Text>

                            <Popconfirm
                              placement="topRight"
                              title="Are you sure to delete this task?"
                              onConfirm={() => {
                                const updatedTasks = values.tasks.filter(
                                  (t) => t._id !== task._id
                                );
                                setFieldValue("tasks", updatedTasks);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Remove task">
                                <DeleteOutlined
                                  style={{ color: "#A5ACB8" }}
                                  className="ml-2"
                                />
                              </Tooltip>
                            </Popconfirm>
                          </div>

                          <div className="flex items-center mt-1 mr-1 justify-end ">
                            <div className="flex items-center mr-2">
                              <CalendarPicker
                                initialDate={
                                  task?.planned ? task.planned : moment()
                                }
                                onDateChange={(date) => {
                                  const updatedTasks = values.tasks.map((t) =>
                                    t._id === task._id
                                      ? { ...t, planned: date }
                                      : t
                                  );
                                  setFieldValue("tasks", updatedTasks);
                                }}
                              />
                              <Text className="ml-1 text-xs underline">
                                {task?.planned
                                  ? moment(task?.planned).format("dddd, MMMM D")
                                  : "Schedule"}
                              </Text>
                            </div>
                            <Dropdown
                              trigger={["click"]}
                              overlay={() => (
                                <AssigneSelectDropdown
                                  handleSearch={handleSearch}
                                  values={values}
                                  filterSearchUser={filterSearchUser}
                                  setValue={(v) => {
                                    const updatedTasks = values.tasks.map((t) =>
                                      t._id === task._id
                                        ? { ...t, assigned: v }
                                        : t
                                    );
                                    setFieldValue("tasks", updatedTasks);
                                  }}
                                  value={task?.assigned}
                                />
                              )}
                            >
                              {task?.assigned ? (
                                <div className="cursor-pointer">
                                  <UserAvatar size={24} user={task?.assigned} />
                                  <Text className="ml-2 text-xs underline">
                                    {task?.assigned?.full_name}
                                  </Text>
                                </div>
                              ) : (
                                <div className="flex items-center cursor-pointer">
                                  <UserAddOutlined />
                                  <Text className="text-xs ml-1 underline">
                                    Assignee
                                  </Text>
                                </div>
                              )}
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col
              xs={10}
              style={{ padding: "4px 24px" }}
              className="flex flex-col justify-between"
            >
              <div>
                <div className={styles.projectSelect}>
                  <Field
                    placeholder="Select project"
                    options={map(projects, (project) => {
                      return {
                        label: project.title,
                        value: project._id,
                        project: project,
                      };
                    })}
                    withLabel="projectTitle"
                    onPopupScroll={onScroll}
                    required
                    label="Project"
                    name="project"
                    component={AntSelect}
                    size="large"
                    inputType="select"
                    filterOption={false}
                    loading={loading}
                    showSearch
                    labelColNotReq={true}
                    onSearch={(value) => delayedQuery(value)}
                    onCallBackChange={(v, obj) => {
                      handleProjectTags(v);
                      setSelectedProject(obj?.project);
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        {loading && (
                          <div>
                            <Loader size={"small"} />
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <Field
                  mode="tags"
                  placeholder="Select tags"
                  className="mt-2"
                  size="large"
                  inputType="select"
                  name="selectedTags"
                  component={AntSelect}
                  disabled={isEmpty(values?.project)}
                  options={_.map(projectTags, (item) => {
                    return { value: item, label: item };
                  })}
                />
                <div className={styles.projectSelect}>
                  <Field
                    component={AntDatePicker}
                    name="date"
                    label="Select a date"
                    size="large"
                    inputType="picker"
                    className="w-full mt-3"
                    labelColNotReq={true}
                    disabledDate={(current) => {
                      return moment(current).isBefore(
                        moment(new Date()).subtract(1, "days")
                      );
                    }}
                    getPopupContainer={(node) => node.parentNode}
                    onCallBackChange={(v) => {
                      getUserFreeTime();
                      setFieldValue("date", v);
                    }}
                  />
                </div>

                <div>
                  <div className="flex items-center justify-between my-3">
                    <Typography.Text>Attachment</Typography.Text>
                    <Upload {...props} showUploadList={false} fileList={files}>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </div>
                  <div className="mt-2 max-h-44 overflow-y-auto">
                    {files.map((file) => (
                      <div
                        className="flex items-center justify-between mt-3 py-1 px-2"
                        key={file.uid}
                        style={{ background: "#FAFAFA" }}
                      >
                        <Text style={{ color: "#1890FF" }}>
                          {utility.truncateFileName(file?.name)}
                        </Text>

                        <DeleteOutlined
                          className=" cursor-pointer"
                          onClick={() => {
                            setFiles((prevFiles) =>
                              prevFiles.filter((f) => f.uid !== file.uid)
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {progress > 0 && (
                    <Progress percent={progress} showInfo={false} />
                  )}
                </div>
              </div>
              <div className="p-4 flex items-center justify-evenly">
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button
                  className={`${styles.blackButton} outline-none border-none ml-2`}
                  type="primary"
                  onClick={onSubmit}
                  loading={creating || (progress > 0 && progress < 100)}
                  icon={<PlusOutlined />}
                >
                  Plan Activity
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
};

export default NewActivityContent;
