import gql from "graphql-tag";

export const GET_ACTIVITY = gql`
  query (
    $start_date: String!
    $end_date: String!
    $projectId: ID
    $memberId: [ID]
  ) {
    getActivity(
      start_date: $start_date
      end_date: $end_date
      projectId: $projectId
      memberId: $memberId
    ) {
      meta {
        count
        total_time
      }
      data {
        date
        total_time

        children {
          _id
          title
          description
          details
          activity_type
          meta
          time {
            total_time
            start
            end
            range
            day_duration
            paused {
              _id
              created_at
              time
              continue
              total
            }
          }
          project {
            title
            status
            color
          }
          created_by {
            email
            avatar
            full_name
          }
        }
      }
    }
  }
`;
