import gql from "graphql-tag";

export const STOP_ACTIVITY = gql`
  mutation($input: stopActivityInput) {
    stopActivity(input: $input) {
      _id
      title
      description
      status
      activity_type
      meta
      time {
        total_time
        start
        end
        created_at
      }
      project {
        title
        color
      }
      created_by {
        email
        full_name
        avatar
      }
      created_at
    }
  }
`;
