import gql from "graphql-tag";

export const CREATE_INTEGRATION = gql`
  mutation ($input: createIntegrationInput) {
    createIntegration(input: $input) {
      _id
      name
      refresh_token
      access_token
    }
  }
`;
