import gql from "graphql-tag";

export const GET_BACKLOG = gql`
  query getBacklog($workspaceId: ID, $view: String) {
    getBacklog(workspaceId: $workspaceId, view: $view) {
      _id
      total
      items {
        tasks {
          _id
          description
          planned
          completed
          assigned {
            _id
            full_name
            email
            avatar
          }
        }
        playbook {
          _id
          title
          description
          activities {
            _id
            title
            details
            tags
            priority
            fileSources
            status
            tasks {
              _id
              description
              planned
              completed
              assigned {
                _id
                full_name
                email
                avatar
              }
            }
            assigned {
              _id
              email
              full_name
              avatar
            }
            created_by {
              _id
              email
              full_name
              avatar
            }
          }
        }
        _id
        title
        priority
        description
        key
        details
        planned_date
        start_date
        end_date
        tags
        created_at
        assigned {
          _id
          email
          full_name
          avatar
        }
        created_by {
          _id
          email
          full_name
          avatar
        }
        type
        content
        timebox
        fileSources
        items {
          _id
          title
          description
          details
          planned_date
          priority
          start_date
          end_date
          tags
          created_at
          key
          playbook {
            _id
            title
            description
            activities {
              _id
              title
              tags
              priority
              fileSources
              details
              status
              tasks {
                _id
                description
                planned
                completed
                assigned {
                  _id
                  full_name
                  email
                  avatar
                }
              }
              assigned {
                _id
                email
                full_name
                avatar
              }
              created_by {
                _id
                email
                full_name
                avatar
              }
            }
          }
          tasks {
            _id
            description
            planned
            completed
            assigned {
              _id
              full_name
              email
              avatar
            }
          }
          assigned {
            _id
            email
            full_name
            avatar
          }
          created_by {
            _id
            email
            full_name
            avatar
          }
          type
          content
          timebox
          fileSources
          activities {
            _id
            title
            details
            description
            planned_date
            tags
            key
            content
            timebox
            created_at
            fileSources
            priority
            playbook {
              _id
              title
              description
              activities {
                _id
                title
                content
                details
                fileSources
                tags
                priority
                status
                tasks {
                  _id
                  description
                  planned
                  completed
                  assigned {
                    _id
                    full_name
                    email
                    avatar
                  }
                }
                created_by {
                  _id
                  email
                  full_name
                  avatar
                }
              }
            }
            assigned {
              _id
              email
              full_name
              avatar
            }
            created_by {
              _id
              email
              full_name
              avatar
            }
          }
        }
      }
    }
  }
`;
