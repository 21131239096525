import gql from "graphql-tag";

export const GET_INVOICE = gql`
  query ($invoiceId: ID!, $workspaceId: ID) {
    getInvoice(invoiceId: $invoiceId, workspaceId: $workspaceId) {
      _id
      name
      invoiceURL
      code
      created_by
      status
      issue_date
      payment_due
      currency
      total_due
      period
      created_at
      notes
      showInvoiceBreakdown
      stripe_invoice {
        hosted_url
      }

      items {
        description
        hours
        cost
        activities {
          _id
          status
          time {
            total_time
          }
          handoff {
            _id
            handoff_at
            user {
              _id
              email
              avatar
              full_name
              rates {
                currency
                rate
              }
            }
            time {
              total_time
              start
              end
            }
          }
          created_by {
            _id
            email
            avatar
            full_name
            occupation
            rates {
              currency
              rate
            }
          }
        }
      }
    }
  }
`;
