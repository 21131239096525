import gql from "graphql-tag";

export const CREATE_CHAT = gql`
  mutation ($input: createChatInput) {
    createChat(input: $input) {
      _id
      type
    }
  }
`;
