import { useQuery } from "@apollo/client";
import { map, upperFirst } from "lodash";
import { Card, Col, Divider, Row, Tooltip, Typography } from "antd";
import { GET_INVEST_OVERVIEW } from "graphql/queries/widget/getInvestOverview";
import React from "react";
import _ from "lodash";
import Loader from "components/Loader";
import {
  ProjectOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

const Invest_Overview = () => {
  const { loading, data } = useQuery(GET_INVEST_OVERVIEW);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Card
        loading={loading}
        bodyStyle={{ padding: 20, height: "100%" }}
        className="shadow-none border-none outline-none h-full"
      >
        <div className="flex w-full items-center justify-between mx-2">
          <div className="flex items-center">
            <div
              style={{
                background: "#F8F8F8",
                borderRadius: "50%",
                width: "34px",
                height: "34px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="mr-2"
            >
              <ProjectOutlined style={{ fontSize: "14px", color: "black" }} />
            </div>
            <Title style={{ margin: 0 }} level={4}>
              Overview
            </Title>
          </div>

          <div>
            <Tooltip title="Get a comprehensive overview of your projects.">
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        </div>

        <div className="flex justify-between mt-3 h-3/4 ">
          <div
            className="flex flex-col w-1/2 p-8  justify-center "
            style={{ borderRight: "1px solid #D9D9D9" }}
          >
            <Text className="text-base">Projects</Text>
            <Text className="text-2xl" strong>
              {_.get(data.getInvestOverview, "projectCount")}
            </Text>
          </div>

          <div className="flex flex-col w-1/2 p-8 justify-center">
            <div className="flex flex-col">
              <Text className="text-base">Total Payment</Text>
              <Text className="text-xl" strong>
                $
                {_.toNumber(
                  _.get(data.getInvestOverview, "totalPayment", 0)
                ).toLocaleString()}
              </Text>
            </div>
            <div className="flex flex-col mt-6 ">
              <Text className="text-base">Outstanding</Text>
              <Text className="text-xl" strong>
                $
                {_.toNumber(
                  _.get(data?.getInvestOverview, "outstanding", 0)
                ).toLocaleString()}
              </Text>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Invest_Overview;
