import React, { useState } from "react";
import { FileImageTwoTone } from "@ant-design/icons";
import { Upload, Form as AntdForm } from "antd";
import { isEmpty, includes } from "lodash";
import { api, AWS_URL } from "common/constants";
import ImgCrop from "antd-img-crop";
import styles from "./styles.module.css";

const ImageUpload = ({ onDone, initialValues = {} }) => {
  const fileListValue = !isEmpty(initialValues) ? [initialValues] : [];
  const [fileList, setFileList] = useState(fileListValue);

  const onChange = ({ fileList: newFileList }) => {
    onDone(newFileList[0]);
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onRemove = async (file) => {
    const { url } = file;
    const fileName = url?.substr(url.lastIndexOf("/") + 1);
    if (includes(url, AWS_URL)) {
      const formData = new FormData();
      formData.append("file_name", fileName);

      const result = await fetch(`${api}/delete/avatar`, {
        method: "DELETE",
        body: fileName,
      }).then((response) => response.json());

      return result?.operation;
    }

    return true;
  };

  return (
    <AntdForm.Item className="block" label="Avatar">
      <div className={styles.uploadContainer}>
        <ImgCrop rotate>
          <Upload
            className={styles.upload}
            action={`${api}/upload/avatar`}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={onRemove}
            multiple={false}
          >
            {fileList.length < 1 && (
              <FileImageTwoTone
                style={{ fontSize: "32px" }}
                twoToneColor="#4E35C2"
              />
            )}
          </Upload>
        </ImgCrop>
      </div>
    </AntdForm.Item>
  );
};

export default ImageUpload;
