import gql from "graphql-tag";

export const UPDATE_BACKLOG_ACTIVITY = gql`
  mutation updateBacklogActivity($input: updateBacklogActivityInput) {
    updateBacklogActivity(input: $input) {
      _id
      title
      description
      planned_date
      assigned {
        _id
      }
    }
  }
`;
