import React from "react";
import { Avatar, Card, Input, Typography, Popover, Spin, Badge } from "antd";
import {
  LoadingOutlined,
  CloseOutlined,
  MinusOutlined,
  SendOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  useLazyQuery,
  useSubscription,
  useMutation,
  useApolloClient,
} from "@apollo/client";
import Message from "components/ChatContent/Message";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import Loader from "components/Loader";
import { GET_MESSAGES } from "graphql/queries/message/getMessages";
import { NEW_MESSAGE } from "graphql/subscription/message/newMessage";
import { SEND_MESSAGE } from "graphql/mutations/message/sendMessage";
import { CREATE_CHAT } from "graphql/mutations/message/createChat";
import { MARK_AS_READ } from "graphql/mutations/message/markAsReadMessages";
import { GET_WORKSPACES_MESSAGES } from "graphql/queries/message/getWorkspacesMessages";
import { ADD_ACTIVE_CHAT } from "graphql/mutations/message/addActiveChat";
import { GET_ACTIVE_CHATS } from "graphql/queries/message/getActiveChats";
import { useRecoilValue, useRecoilState } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import { userSession } from "recoil/atoms/User/UserSession";
import UserAvatar from "components/UserAvatar";
import cx from "classnames";
import styled from "./styles.module.css";

const limit = 50;
const page = 0;

const ChatPopUp = ({ setchatPopUpVisiblity }) => {
  const [newMessage, setNewMessage] = React.useState("");
  const [chat, setChat] = React.useState(null);
  const workspace = useRecoilValue(workspaceSelected);
  const [chatSelected, setChatSelected] = useRecoilState(chatSelectedState);
  const user = useRecoilValue(userSession);
  const client = useApolloClient();
  const messagesEndRef = React.useRef(null);

  const [getMessages, { data, loading }] = useLazyQuery(GET_MESSAGES, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ getMessages }) => {
      let messagesObj = {};
      if (!_.isEmpty(chat)) {
        const gatherMessages = _.unionBy(
          chat.messages,
          getMessages.messages,
          "_id"
        );

        messagesObj = {
          ...chat,
          messages: gatherMessages,
        };
      } else {
        messagesObj = {
          chatId: getMessages._id,
          messages: getMessages.messages,
        };
      }

      setChat(messagesObj);
      // update the topbar messages indicator
      await client.refetchQueries({
        include: [GET_WORKSPACES_MESSAGES],
      });
    },
  });

  const [addActiveChat] = useMutation(ADD_ACTIVE_CHAT, {
    refetchQueries: [
      {
        query: GET_ACTIVE_CHATS,
        variables: { workspaceId: workspace?._id },
      },
    ],
  });

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const { data: messageData, error: messageError } =
    useSubscription(NEW_MESSAGE);

  const [createChat, { loading: creating }] = useMutation(CREATE_CHAT);

  const [sendMessage, { loading: sending }] = useMutation(SEND_MESSAGE, {
    onCompleted: () => {
      setNewMessage("");
      scrollToBottom();
    },
  });

  const [markRead] = useMutation(MARK_AS_READ);

  const addChatMessage = () => {
    const { newMessage } = messageData;
    // if the message receveid belongs to the chat opened, adds it
    if (chat.chatId === newMessage.chatId) {
      const messagesObj = {
        ...chat,
        messages: [
          {
            _id: newMessage._id,
            content: newMessage.content,
            from: newMessage.from,
            created_at: newMessage.created_at,
            unread: newMessage.unread,
          },
          ...chat.messages,
        ],
      };

      setChat(messagesObj);
      // if the message received is from a user different than the user session, it marks as read
      if (newMessage.from._id !== user._id) {
        markRead({
          variables: {
            input: {
              messageId: newMessage._id,
            },
          },
        });
      }
    }
  };

  React.useEffect(() => {
    if (chatSelected) {
      setChat(null); // the chat before adding the new messages
      if (chatSelected.chatId) {
        // find messages using the chat id
        getMessages({
          variables: {
            input: {
              chatId: chatSelected.chatId,
              limit: limit,
              page: page,
            },
          },
        });
      } else {
        // if chatid is null it means it is a new conversation
        // find by workspace id and participants ids
        getMessages({
          variables: {
            input: {
              participants: _.map(
                chatSelected.participants,
                (user) => user._id
              ),
              workspaceId: chatSelected.workspaceId,
              limit: limit,
              page: page,
            },
          },
        });
      }
    }
  }, [chatSelected.chatId]);

  React.useEffect(() => {
    if (messageError) console.warn(messageError);
    if (messageData) addChatMessage();
  }, [messageError, messageData]);

  const loadMore = () => {
    const nextPage = data?.getMessages.currentPage + 1;

    getMessages({
      variables: {
        input: {
          chatId: chat.chatId,
          limit: limit,
          page: nextPage,
        },
      },
    });
  };

  const handleActiveChat = () => {
    addActiveChat({
      variables: { input: { chatId: chat.chatId, workspaceId: workspace._id } },
    });
    setChatSelected({});
    setchatPopUpVisiblity(false);
  };

  const onSend = () => {
    if (newMessage.trim() === "") return;

    if (_.isEmpty(chat.chatId)) {
      const participants = _.map(chatSelected.participants, (user) => user._id);
      createChat({
        variables: {
          input: {
            participants: [...participants, user._id],
            type: "Individual",
            workspaceId: workspace._id,
          },
        },
      }).then(({ data }) => {
        setChat({ ...chat, chatId: data.createChat._id });
        sendMessage({
          variables: {
            input: {
              chatId: data.createChat._id,
              content: newMessage,
            },
          },
        });
      });
    } else {
      sendMessage({
        variables: {
          input: {
            chatId: chat.chatId,
            content: newMessage,
          },
        },
      });
    }
  };

  const ChatContainerTitle = () => {
    if (_.size(chatSelected.participants) === 1) {
      return (
        <div className="flex justify-between">
          <div className="flex items-center">
            <Badge
              color={
                _.get(chatSelected, "participants[0].isOnline", false)
                  ? "green"
                  : "gray"
              }
              className={styled.activeStatusStyle}
              size="default"
              offset={[-5, 35]}
              dot={true}
            >
              <UserAvatar
                user={_.get(chatSelected, "participants[0]", {})}
                size="large"
              />
            </Badge>
            <div className="flex flex-col ml-2">
              <Typography.Text>
                {_.get(chatSelected, "participants[0].full_name", "")}
              </Typography.Text>
              <Typography.Text className="text-sm">
                {_.get(chatSelected, "participants[0].email", "")}
              </Typography.Text>
            </div>
          </div>
          <div className="flex jutify-between">
            <MinusOutlined
              className="text-xl mr-3"
              onClick={() => handleActiveChat()}
            />
            <CloseOutlined
              className="text-xl"
              onClick={() => {
                setChatSelected({});
                setchatPopUpVisiblity(false);
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="flex justify-between">
        {_.size(chatSelected.participants) >= 1 ? (
          <Avatar.Group maxCount={3}>
            {_.map(chatSelected?.participants, (participant) => (
              <Popover
                content={
                  <div>
                    {`${participant.email} `}
                    <Badge
                      color={participant.isOnline ? "green" : "gray"}
                      className={styled.activeStatusStyle}
                      size="default"
                      dot={true}
                    />
                  </div>
                }
                trigger="hover"
                key={participant._id}
              >
                <UserAvatar user={participant} />
              </Popover>
            ))}
          </Avatar.Group>
        ) : (
          <div className="flex items-center">
            <Avatar
              icon={<StopOutlined className={styled.iconContainer} />}
              size="large"
            />

            <div className="flex flex-col ml-2">
              <Typography.Text className="text-sm" type="danger">
                (removed)
              </Typography.Text>
            </div>
          </div>
        )}

        <div className="flex jutify-between">
          <MinusOutlined
            className="text-xl mr-3"
            onClick={() => handleActiveChat()}
          />
          <CloseOutlined
            className="text-xl"
            onClick={() => {
              setChatSelected({});
              setchatPopUpVisiblity(false);
            }}
          />
        </div>
      </div>
    );
  };

  const hasMessages = !_.isEmpty(chat?.messages);
  return (
    <Card title={<ChatContainerTitle />}>
      <div
        id="scrollableInternal"
        className={cx("overflow-y-auto flex px-2", {
          "flex-col-reverse": hasMessages,
          "justify-center": !hasMessages,
          "items-center": !hasMessages,
        })}
        style={{ height: 300 }}
      >
        <InfiniteScroll
          dataLength={_.size(chat?.messages)}
          inverse={true}
          next={() => loadMore()}
          hasMore={!_.isEmpty(data?.getMessages?.messages)}
          loader={loading && <Loader size="large" />}
          className={"flex flex-col-reverse"}
          scrollableTarget="scrollableInternal"
          scrollThreshold={"100%"}
          initialScrollY={0}
        >
          <div ref={messagesEndRef} />
          {creating ||
            (sending && (
              <div className="m-3 flex">
                <Typography.Text className="mr-3">sending...</Typography.Text>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
              </div>
            ))}
          {_.map(chat?.messages, (message, index) => {
            const sent = message.from.email === user.email;

            const withAvatar =
              message.from.email !== chat.messages[index + 1]?.from.email;

            return (
              <Message
                message={message}
                sent={sent}
                key={message._id}
                userInfo={message.from}
                withAvatar={withAvatar}
                userRemoved={
                  !_.some(chatSelected.participants, {
                    email: message.from.email,
                  })
                }
              />
            );
          })}
        </InfiniteScroll>
      </div>
      <div className="flex mt-2 items-center">
        <Input
          className="w-full mr-2"
          placeholder="Send a message"
          value={newMessage}
          onChange={({ target }) => setNewMessage(target.value)}
          onPressEnter={() => onSend()}
          disabled={_.size(chatSelected.participants) < 1}
        />
        <SendOutlined
          className={cx({
            "cursor-pointer": _.size(chatSelected.participants) > 1,
            "cursor-not-allowed": _.size(chatSelected.participants) <= 1,
          })}
          style={{ fontSize: 22 }}
          onClick={() => onSend()}
        />
      </div>
    </Card>
  );
};

export default ChatPopUp;
