import React, { useState } from "react";
import { Typography, List, Menu, ConfigProvider, Card, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { Icon as WIcon } from "@fishyvisions/windu-uikit";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { sortBy } from "lodash";
import Loader from "components/Loader";
import { GET_ACTIVITY_BY_PROJECT } from "graphql/queries/member/getActivityByProject";
import styles from "../styles.module.css";
import EmptyData from "../../EmptyData";
import DragHandle from "../DragHandle";
import { LineChart, Line, Tooltip } from "recharts";

const { Text, Title } = Typography;

const ProjectPerformance = ({}) => {
  const [activityByProjectDays, setActivityByProjectDays] = useState(7);
  const [selected, setSelected] = React.useState("7");

  const { loading: activityByProjectLoading, data } = useQuery(
    GET_ACTIVITY_BY_PROJECT,
    {
      variables: { days: activityByProjectDays },
    }
  );

  const handleActivityByProject = (i) => {
    setSelected(i.key);
    setActivityByProjectDays(Number(i.key));
  };

  const getFilterName = () => {
    switch (selected) {
      case "7":
        return <Text>Last 7 days</Text>;
      case "14":
        return <Text>Last 14 days</Text>;

      case "21":
        return <Text>Last 21 days</Text>;

      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleActivityByProject} selectedKeys={[selected]}>
      <Menu.Item key={7}>
        <a>Last 7 days</a>
      </Menu.Item>
      <Menu.Item key={14}>
        <a>Last 14 days</a>
      </Menu.Item>
      <Menu.Item key={21}>
        <a>Last 21 days</a>
      </Menu.Item>
    </Menu>
  );

  const listLoading = {
    spinning: activityByProjectLoading,
    indicator: <Loader size="large" />,
  };

  const projectsData = sortBy(data?.getActivityByProject, [
    function (o) {
      return moment.duration(o.status.value)._milliseconds;
    },
  ]).reverse();

  return (
    <>
      <DragHandle />
      <Card
        bodyStyle={{ padding: 20, height: "100%" }}
        className="shadow-none border-none outline-none tour-projectperformancewidget"
        menu={menu}
        style={{ height: "450px" }}
      >
        <div className="flex justify-between mb-3">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Title level={4}>Project Performance</Title>
            </div>
            {getFilterName()}
          </div>
          <div>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <EllipsisOutlined
                style={{ fontSize: 35 }}
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
        </div>

        <ConfigProvider renderEmpty={() => <EmptyData marginTop />}>
          <List
            className={styles.listWidget}
            loading={listLoading}
            itemLayout="horizontal"
            size="large"
            dataSource={projectsData}
            renderItem={(item) => {
              const isLoss = !item?.status?.isPositive;
              const isGain = item?.status?.isPositive;
              const result = item?.status?.value;
              return (
                <List.Item key={item.title} className="flex items-center">
                  <List.Item.Meta
                    avatar={
                      <LineChart width={120} height={120} data={item.values}>
                        <Line
                          type="monotone"
                          dataKey="time"
                          stroke={isLoss ? "#e53e3e" : "#38a169"}
                          strokeWidth={2}
                        />
                        <Tooltip
                          labelFormatter={(position) =>
                            item.values[position].date
                          }
                          formatter={(value) => [
                            value === 0
                              ? "00:00:00"
                              : moment.duration(value).format("hh:mm:ss"),
                            "Total time",
                          ]}
                        />
                      </LineChart>
                    }
                  />
                  <div>
                    {isLoss && (
                      <div className="grid">
                        <Title level={5} className="text-right">
                          {item.title}
                        </Title>
                        <div className="flex justify-end">
                          <Text className="text-red-700 mr-3">{result}</Text>
                          <WIcon
                            color="#e53e3e"
                            className="pr-2"
                            type="ChevDown"
                            size="small"
                          />
                        </div>
                      </div>
                    )}
                    {isGain && (
                      <div className="grid">
                        <Title level={5} className="text-right">
                          {item.title}
                        </Title>
                        <div className="flex justify-end">
                          <Text className="text-green-700 mr-3">{result}</Text>
                          <WIcon
                            color="#38a169"
                            className="pr-2"
                            type="ChevUp"
                            size="small"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </List.Item>
              );
            }}
          />
        </ConfigProvider>
      </Card>
    </>
  );
};

export default ProjectPerformance;
