import { Col, Input, Row } from "antd";
import SelectedIntegration from "components/Modals/SelectedIntegration";
import React, { useState } from "react";
import AppCard from "./AppCard";
import { useQuery } from "@apollo/client";
import Loader from "components/Loader";
import _ from "lodash";
import { GET_ALL_WIDGETS } from "graphql/queries/widget/getAllWidgets";

const Integration = () => {
  const [allWidget, setAllWidget] = useState([]);
  const [widgetDataClone, setwidgetDataClone] = useState([]);
  const [selectedIntegration, setselectedIntegration] = useState();
  const {
    loading: integrationsLoading,
    error,
    data: integrationData,
    refetch,
  } = useQuery(GET_ALL_WIDGETS, {
    onCompleted: ({ getAllWidgets }) => {
      const getAvailableWidgets = _.filter(
        getAllWidgets,
        (item) => item?.widget_category?.is_released
      );
      const notMiroWidget = _.filter(
        getAvailableWidgets,
        (item) => item?.label !== "Miro"
      );

      setAllWidget(notMiroWidget);
      setwidgetDataClone(notMiroWidget);
    },
  });

  const handleSelectionClick = (item) => {
    if (item?.no_of_connection !== 0) {
      setselectedIntegration(item);
    }
  };
  const handleSearch = (v) => {
    const filtetData = _.filter(widgetDataClone, (item) =>
      _.includes(_.lowerCase(item.label), _.lowerCase(v))
    );
    setAllWidget(filtetData);
  };
  if (integrationsLoading) return <Loader />;
  return (
    <div>
      <div>
        <Input.Search
          className=" w-80"
          size="large"
          onChange={(v) => handleSearch(v?.target?.value)}
          placeholder="Search integration"
        />
      </div>
      <div className=" mt-14">
        <div className="flex flex-wrap">
          {_.map(allWidget, (item) => {
            return (
              <div>
                <AppCard
                  data={item}
                  onClick={() => handleSelectionClick(item)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {selectedIntegration && (
        <SelectedIntegration
          visible={selectedIntegration}
          setVisible={setselectedIntegration}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default Integration;
