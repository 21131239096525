import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import _ from "lodash";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import DuplicateActivites from "./DuplicateActivites";
import NewActivityContent from "./NewActivity";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { map } from "lodash";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { UPDATE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateBacklogActivity";
import { CREATE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/createBacklogActivity";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import queryString from "query-string";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  details: yup.string().required("This field is required"),
});

const NewBacklogActivityModal = ({
  isVisible,
  onClose,
  backlogId,
  title,
  editingItem,
  backlogGroups,
  fromPlanned,
  setEditingItem,
  backlogRefetch,
  selectedGroup,
}) => {
  const location = useLocation();
  const [visibleView, setVisibleView] = useState("new");
  const user = useRecoilValue(userSession);
  const [progress, setProgress] = React.useState(0);
  const [duplicateTasks, setDuplicateTasks] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [fileSources, setFileSources] = useState([]);
  const [showDuplicateHeader, setShowDuplicateHeader] = useState(false);
  const form = React.useRef();
  const { id } = queryString.parse(location.search);
  const handleOnClose = () => {
    onClose();
  };

  const initialFormValues = {
    title: editingItem ? editingItem.data?.title : "",
    details:
      editingItem && editingItem?.data?.details
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(editingItem.data?.details))
          )
        : [],
    assigned: editingItem ? editingItem.data?.assigned?._id : null,
    date:
      editingItem && editingItem.data?.planned
        ? moment(editingItem.data?.planned)
        : null,
    timebox:
      editingItem && editingItem.data?.timebox
        ? editingItem.data?.timebox
        : "00:00:00",
    selected_group: selectedGroup,
    priority:
      editingItem && editingItem.data?.priority
        ? editingItem.data?.priority
        : null,
    selectedTags:
      editingItem && editingItem.data?.tags ? editingItem.data?.tags : [],
    tasks:
      editingItem && editingItem.data?.tasks ? editingItem.data?.tasks : [],
    playbook:
      editingItem && editingItem.data?.playbook
        ? editingItem.data?.playbook
        : [],
  };

  const [createBacklogActivity, { loading: creating }] = useMutation(
    CREATE_BACKLOG_ACTIVITY,
    {
      onCompleted: async () => {
        if (backlogRefetch) backlogRefetch();
        utility.setNotification(
          "Success",
          `Your activity was created!`,
          "success",
          "topRight"
        );
        onClose();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
      awaitRefetchQueries: true,
    }
  );

  const [updateBacklogActivity, { loading: updating }] = useMutation(
    UPDATE_BACKLOG_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          `Activity updated successfully`,
          "success",
          "topRight"
        );
        form.current.resetForm();
        onClose();
        if (backlogRefetch) backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PLAN_CALENDAR_ACTIVITY,
        },
        {
          query: GET_TODAY_WIDGET_DATA,
        },
      ],
    }
  );

  const handleDone = async (values) => {
    const {
      details,
      title,
      assigned,
      date,
      selected_group,
      timebox,
      files,
      selectedTags,
      priority,
      tasks,
      playbook,
    } = values;
    const contentData = !_.isEmpty(details)
      ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
      : "";

    let filesObj = [];
    if (files?.length > 0) {
      filesObj = await uploadActivityFiles({
        userId: user?._id,
        fileList: files,
        updateProgress: setProgress,
      });
      setProgress(0);
    }

    const payload = {
      planned_date: moment.utc(date),
      backlogId,
      title: _.upperFirst(title),
      details: contentData,
      assigned: assigned ?? null,
      created_by: user._id,
      tags: selectedTags,
      timebox: isNaN(timebox) ? timebox : "",
      priority,
      tasks: _.map(tasks, (task) => {
        const newData = _.omit(task, ["_id"]);
        return {
          ...newData,
          assigned: task?.assigned?._id,
        };
      }),
    };
    if (editingItem) {
      // update activity

      if (fromPlanned) {
        payload.activityId = editingItem.data._id;
      } else {
        payload.activityId = editingItem.id || editingItem.data._id;
      }
      updateBacklogActivity({
        variables: {
          input: {
            ...payload,
            fileSources: [
              ...(filesObj ? map(filesObj, (file) => file?.Location) : []),
              ...(fileSources || []),
            ],
          },
        },
      });
    } else {
      payload.selected_group = selected_group;
      createBacklogActivity({
        variables: {
          input: {
            ...payload,
            fileSources: map(filesObj, (file) => file?.Location),
            playbook: _.map(playbook, (goal) => {
              return {
                title: goal?.title,
                description: goal?.description,
                activities: goal?.activities,
              };
            }),
          },
        },
      });
    }
  };

  useEffect(() => {
    // Check if editingItem has changed
    if (editingItem) {
      // Update the form values with the new editingItem data
      form.current.setValues({
        title: editingItem.data?.title || "",
        details: editingItem.data?.details
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(editingItem.data?.details))
            )
          : EditorState.createEmpty(),
        assigned: editingItem.data?.assigned?._id || null,
        date: editingItem.data?.planned
          ? moment(editingItem.data?.planned)
          : null,
        timebox: editingItem.data?.timebox || "00:00:00",
        selected_group: null,
        priority: editingItem.data?.priority || null,
        selectedTags: editingItem.data?.tags || [],
        tasks: editingItem.data?.tasks || [],
        playbook:
          editingItem && editingItem.data?.playbook
            ? editingItem.data?.playbook
            : [],
      });

      setDuplicateTasks([]);
      setVisibleView("new");
    }
  }, [editingItem]);

  return (
    <Modal
      closable={false}
      onCancel={handleOnClose}
      visible={isVisible}
      width={900}
      footer={null}
      style={{ background: "transparent", boxShadow: "none", border: "none" }}
      bodyStyle={{
        background: "transparent",
        boxShadow: "none",
        border: "none",
        padding: 0,
      }}
      className={styles.activityModalClass}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => handleDone(values, props)}
        innerRef={form}
      >
        {() => {
          return (
            <>
              {!_.isEmpty(duplicateTasks) && _.isEmpty(editingItem) && (
                <div
                  onClick={() => setVisibleView("duplicate")}
                  className={visibleView === "new" && "cursor-pointer"}
                >
                  <DuplicateActivites
                    setVisibleView={setVisibleView}
                    visibleView={visibleView}
                    onClose={onClose}
                    duplicateTasks={duplicateTasks}
                    setEditingItem={setEditingItem}
                    filteredActivities={filteredActivities}
                    setFilteredActivities={setFilteredActivities}
                    showDuplicateHeader={showDuplicateHeader}
                  />
                </div>
              )}
              <div
                onClick={() => setVisibleView("new")}
                className={visibleView === "duplicate" && "cursor-pointer"}
              >
                <NewActivityContent
                  key={editingItem ? "editing" : "new"}
                  setVisibleView={setVisibleView}
                  visibleView={visibleView}
                  form={form}
                  isVisible={isVisible}
                  onClose={onClose}
                  progress={progress}
                  creating={creating || updating}
                  title={title ? title : "Plan an activity"}
                  editingItem={editingItem}
                  backlogGroups={backlogGroups}
                  fromPlanned={fromPlanned}
                  setFileSources={setFileSources}
                  fileSources={fileSources}
                  setDuplicateTasks={setDuplicateTasks}
                  showDuplicateHeader={showDuplicateHeader}
                  setShowDuplicateHeader={setShowDuplicateHeader}
                  filteredActivities={filteredActivities}
                  backlogId={backlogId}
                  setEditingItem={setEditingItem}
                  backlogRefetch={backlogRefetch}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NewBacklogActivityModal;
