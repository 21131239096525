import { ArrowLeft, CaretLeft } from "@phosphor-icons/react";
import { Typography } from "antd";
import React from "react";
import EmptyCardsImage from "common/assets/quickStart/articlesCard.svg";
import { Crisp } from "crisp-sdk-web";
import Cookies from "js-cookie";

const { Text } = Typography;

const SelectedCategory = ({
  category,
  setSelectedCategory,
  setCategoryPageOpen,
  setSelectedHelpArticle,
  selectedHelpArticle,
}) => {
  const removeCrispSavedData = () => {
    //  let's remove old crisp cookies and local storage
    // first get all the data from local storage
    let localStorageData = Object.keys(localStorage);
    localStorageData.map((data) => {
      if (data.includes("crisp")) {
        localStorage.removeItem(data);
      }
    });

    // now the cookies
    let cookies = Object.keys(Cookies.get());
    cookies.map((cookie) => {
      if (cookie.includes("crisp")) {
        Cookies.remove(cookie);
      }
    });
  };
  const ArticleCard = ({ article }) => {
    const handleHelpArticleClick = () => {
      //  let's remove old crisp cookies and local storage
      removeCrispSavedData();
      Crisp.chat.show();
      Crisp.chat.open();

      Crisp.message.show("picker", {
        id: "article-help-picker",
        text: `Hey 👋 Welcome to Windu.  Seems like you need help with ${article.name}. Please choose the following?`,
        choices: [
          {
            value: "meeting",
            label: "Book a Meeting",
            selected: false,
          },
          {
            value: "chat",
            label: "Get Real-Time Help",
            selected: false,
          },
        ],
      });
      setSelectedHelpArticle(article);
    };
    return (
      <div
        className="w-64 flex flex-col items-center p-2 rounded-lg cursor-pointer"
        style={{ background: "#fafafc" }}
        onClick={handleHelpArticleClick}
      >
        <img src={EmptyCardsImage} />
        <div className="p-3 flex flex-col">
          <Text strong className="text-base">
            {article.name}
          </Text>
          <Text className="mt-2">{article.description}</Text>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex items-center ml-2">
        <Text
          className="cursor-pointer"
          onClick={() => setCategoryPageOpen(null)}
        >
          Quick Start
        </Text>
        <CaretLeft size={16} />
        <Text
          className="cursor-pointer"
          onClick={() => setSelectedCategory(null)}
        >
          Categories
        </Text>
        <CaretLeft size={16} />
        <Text className="font-semibold"> {category?.name} </Text>
      </div>

      <div className="flex flex-col my-3">
        <Text className="text-xl mb-1" strong>
          {category?.name} Tips
        </Text>
        <Text>{category?.description}</Text>
      </div>
      <div className="flex items-start gap-3 p-5 flex-wrap">
        {category?.articles?.map((article) => (
          <ArticleCard key={article?.key} article={article} />
        ))}
      </div>
    </div>
  );
};

export default SelectedCategory;
