import { Divider, Select, Typography } from "antd";
import React from "react";
import RightCSVImage from "common/assets/csvImport.svg";
import { ArrowDown, ArrowRight } from "@phosphor-icons/react";
const { Title, Text } = Typography;

const winduColumnsFields = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
  {
    title: "Tags",
    dataIndex: "tags",
    key: "tags",
    isMultiple: true,
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Tasks",
    dataIndex: "tasks",
    key: "tasks",
    isMultiple: true,
  },
];
const MappingColumns = ({
  importCsvData,
  setImportCsvData,
  setColumnMappings,
}) => {
  const excleColumns = Object.keys(importCsvData[0]);

  const handleColumnSelect = (winduColumn, excelColumn) => {
    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [winduColumn]: excelColumn,
    }));
  };

  return (
    <div className="flex">
      <div className="w-3/5">
        <Text className="text-xl" strong>
          Map columns from Excel into this project
        </Text>

        <div className="flex items-center gap-1 my-3">
          <Text className="text-gray-500">
            Customize fields within Windu manually
          </Text>
          <ArrowDown size={20} color="#667085" />
        </div>
        <div className="flex items-center justify-between mt-2 pl-2 pr-4">
          <Text className="text-base" strong>
            Windu Columns
          </Text>
          <Text className="text-base" strong>
            Excel Columns
          </Text>
        </div>
        <Divider />

        <div className="p-3">
          {winduColumnsFields.map((field) => (
            <div className="flex items-center justify-between mb-3">
              <Text className="text-base">{field.title}</Text>
              <div className="flex items-center w-3/5">
                <ArrowRight size={24} color="#333333" />
                <Select
                  className="flex-1 ml-3"
                  placeholder="Select Column"
                  size="large"
                  mode={field.isMultiple ? "multiple" : undefined}
                  onChange={(excelColumn) =>
                    handleColumnSelect(field.dataIndex, excelColumn)
                  }
                  options={excleColumns.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <img src={RightCSVImage} alt="Windu Import" />
      </div>
    </div>
  );
};

export default MappingColumns;
