import axios from "axios";
import Cookies from "js-cookie";

export const GITHUB_AUTHORIZE = (id) =>
  `https://github.com/login/oauth/authorize?scope=user:email,repo&client_id=${process.env.REACT_APP_GITHUB_CLIENT}&state=${id}`;

export const getOrgs = () => {
  return axios.get(`${Cookies.getJSON("GITHUB").organizations_url}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.getJSON("GITHUB").access_token}`,
    },
  });
};

export const getOrgRepos = (org) => {
  return axios.get(`https://api.github.com/orgs/${org}/repos?per_page=100`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.getJSON("GITHUB").access_token}`,
    },
  });
};

export const getRepos = (id, token) => {
  return axios.post(
    `https://api.github.com/graphql`,
    {
      query: `query viewer {
        viewer {
          login
          repositories(ownerAffiliations: [OWNER, COLLABORATOR, ORGANIZATION_MEMBER], first: 100) {
            nodes {
              id
              nameWithOwner
              
            }
            pageInfo {
              hasNextPage
              endCursor
              startCursor
              hasPreviousPage
            }
          }
        }
      }`,
    },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getPullRequestsFromRepo = (repo, id, token) => {
  return axios.post(
    `https://api.github.com/graphql`,
    {
      query: `query search {
        search(query: "${repo}", type: REPOSITORY, first: 100) {
          nodes { 
            ... on Repository {
              id
              name
              pullRequests(first: 100, states: OPEN) {
                nodes {
                  createdAt
                  baseRefName
                  title
                  labels(first:5) {
                    nodes{
                      name
                      color
                    } 
                  }
                  headRefName
                  url
                  author {
                    avatarUrl(size: 50)
                    login
                  }
                }
              }
            }
          }
        }
      }`,
    },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
