import gql from "graphql-tag";

export const FISTBUMP_USER = gql`
  mutation ($input: activityIdInput) {
    fistBumpUser(input: $input) {
      _id
      title
      description
    }
  }
`;
