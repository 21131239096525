import gql from "graphql-tag";

export const UPDATE_ACTIVITY_SOURCES = gql`
  mutation ($input: updateActivitySourcesInput) {
    updateActivitySources(input: $input) {
      _id
      title
      description
      status
      activity_type
      meta
      fileSources
      created_at
    }
  }
`;
