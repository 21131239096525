import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styles from "./styles.module.css";

const Loader = ({ size = "default" }) => {
  const antIcon = <LoadingOutlined spin />;
  return <Spin className={styles.loader} indicator={antIcon} size={size} />;
};

export default Loader;
