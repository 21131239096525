import React from "react";
import _ from "lodash";
import MondayLogo from "common/assets/mondayLogo.png";
import ClickUpLogo from "common/assets/clickup.svg";
import GitHubLogo from "common/assets/gitHubLogo.png";
import TrelloLogo from "common/assets/trelloLogo.png";
import TodoistLogo from "common/assets/todoist.png";
import miroLogo from "common/assets/miro.png";
import figmaLogo from "common/assets/figma.svg";
import SlackLogo from "common/assets/slack.png";
import { CalendarOutlined, SlackOutlined } from "@ant-design/icons";

export const getIntegrationLogo = (type, style) => {
  switch (type) {
    case "jira":
      return (
        <img
          style={{ width: 25, ...style }}
          src="https://windu.s3.us-east-2.amazonaws.com/assets/jira-logo.png"
        />
      );
    case "jira cloud":
      return (
        <img
          style={{ width: 25, ...style }}
          src="https://windu.s3.us-east-2.amazonaws.com/assets/jira-logo.png"
        />
      );
    case "monday":
      return <img style={{ width: 25, ...style }} src={MondayLogo} />;
    case "trello":
      return <img style={{ width: 25, ...style }} src={TrelloLogo} />;
    case "github":
      return <img style={{ width: 25, ...style }} src={GitHubLogo} />;
    case "miro":
      return <img style={{ width: 25, ...style }} src={miroLogo} />;
    case "todoist":
      return <img style={{ width: 25, ...style }} src={TodoistLogo} />;
    case "clickup":
      return <img style={{ width: 25, ...style }} src={ClickUpLogo} />;
    case "figma":
      return <img style={{ width: 25, ...style }} src={figmaLogo} />;

    case "meet":
      return <CalendarOutlined style={{ fontSize: 25, ...style }} />;
    case "google":
      return <CalendarOutlined style={{ fontSize: 25, ...style }} />;
    case "GOOGLE_MEET":
      return <CalendarOutlined style={{ fontSize: 25, ...style }} />;
    case "meetings":
      return <CalendarOutlined style={{ fontSize: 25, ...style }} />;
    case "slack":
      return <img style={{ width: 25, ...style }} src={SlackLogo} />;

    default:
      break;
  }
};
