import {
  CaretLeft,
  DotsThree,
  DotsThreeVertical,
  Strategy,
  X,
} from "@phosphor-icons/react";
import {
  Button,
  Card,
  Divider,
  Typography,
  Form as AntForm,
  Tag,
  Dropdown,
  Menu,
  Tooltip,
} from "antd";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { FlagFilled, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { AntInput } from "components/FormikCustomInputs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import _ from "lodash";
import NewActivitySlideDrawer from "./Playbook/newPlaybookActvitiy";
import { useMutation } from "@apollo/client";
import { CREATE_PLAYBOOK_GOAL } from "graphql/mutations/Playbook/createGoal";
import utility from "common/utility";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { REMOVE_PLAYBOOK_ACTIVITY } from "graphql/mutations/Playbook/removePlaybookActivity";
import { UPDATE_PLAYBOOK_ACTIVITY } from "graphql/mutations/Playbook/updatePlaybookActivity";
import AssigneSelectDropdown from "views/Workspace/Backlog/AssigneSelectDropdown";
import UserAvatar from "components/UserAvatar";
import { UPDATE_PLAYBOOK_GOAL } from "graphql/mutations/Playbook/updatePlaybookGoal";
import { REMOVE_PLAYBOOK_GOAL } from "graphql/mutations/Playbook/removePlaybookGoal";

const { Text } = Typography;

const toolbarOptions = {
  options: ["inline", "list"],
};

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  description: yup.string().trim().required("This field is required"),
});

const PlaybookView = ({
  setPlaybookView,
  filterSearchUser,
  setFilterSearchUser,
  handleSearch,
  projectTags,
  mainFormValues,
  editingItem,
  backlogId,
  setEditingItem,
  backlogRefetch,
  setMainFormValues,
  workspace,
}) => {
  const [isSlideDrawerOpen, setIsSlideDrawerOpen] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const user = useRecoilValue(userSession);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [editingPlaybookActivity, setEditingPlaybookActivity] = useState(null);
  const [editingGoal, setEditingGoal] = useState(null);

  const newGoalFormRef = useRef(null);

  const [createPlaybookGoal, { loading: creatingGoal }] = useMutation(
    CREATE_PLAYBOOK_GOAL,
    {
      onCompleted: ({ createGoal }) => {
        setEditingItem({ data: createGoal });
        setIsSlideDrawerOpen(false);
        backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    }
  );

  const [removePlaybookActivity, { loading: removingPlaybookActivity }] =
    useMutation(REMOVE_PLAYBOOK_ACTIVITY, {
      onCompleted: ({ removePlaybookActivity }) => {
        setEditingItem({ data: removePlaybookActivity });
        backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  const [updatePlaybookActivity, { loading: updatingPlaybookActivity }] =
    useMutation(UPDATE_PLAYBOOK_ACTIVITY, {
      onCompleted: ({ updatePlaybookActivity }) => {
        setEditingItem({ data: updatePlaybookActivity });
        if (backlogRefetch) backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  const [updatePlaybookGoal, { loading: updatingPlaybookGoal }] = useMutation(
    UPDATE_PLAYBOOK_GOAL,
    {
      onCompleted: ({ updatePlaybookGoal }) => {
        setEditingItem({ data: updatePlaybookGoal });
        if (backlogRefetch) backlogRefetch();
        setIsSlideDrawerOpen(false);
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    }
  );
  const [removePlaybookGoal, { loading: removingPlaybookGoal }] = useMutation(
    REMOVE_PLAYBOOK_GOAL,
    {
      onCompleted: ({ removePlaybookGoal }) => {
        setEditingItem({ data: removePlaybookGoal });
        if (backlogRefetch) backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    }
  );

  const editorStateHandler = (v, setFieldValue) => {
    // seteditorState(v);
    setFieldValue("description", v);
  };

  const handleCreateNewGoal = async (value) => {
    const goalDescription = !_.isEmpty(value?.description)
      ? JSON.stringify(convertToRaw(value?.description?.getCurrentContent()))
      : "";

    if (_.isEmpty(editingItem)) {
      // new activity update the formValues

      const payload = [
        ...(mainFormValues?.playbook || []),
        {
          _id: _.uniqueId("id-"),
          title: value?.title,
          description: goalDescription,
          activities: [],
        },
      ];
      setIsSlideDrawerOpen(false);
      setMainFormValues("playbook", payload);
    } else {
      createPlaybookGoal({
        variables: {
          input: {
            title: value?.title,
            description: goalDescription,
            activityId: editingItem?.data?._id,
            backlogId,
          },
        },
      });
    }
  };

  const handleDeleteGoalActivity = (activity, goal) => {
    if (editingItem) {
      // send request to delete
      removePlaybookActivity({
        variables: {
          input: {
            activityId: activity._id,
            goalId: goal._id,
            backlogActivityId: editingItem.data?._id,
            projectId: workspace?.project?._id,
          },
        },
      });
    } else {
      const newPlaybookValue = _.map(mainFormValues?.playbook, (item) => {
        if (item._id == goal._id) {
          return {
            ...item,
            activities: _.filter(
              item.activities,
              (goalActivity) => goalActivity?._id !== activity._id
            ),
          };
        } else return item;
      });
      setMainFormValues("playbook", newPlaybookValue);
    }
  };

  const handleUpdateGoal = (value) => {
    if (editingItem) {
      const descriptionData = !_.isEmpty(value?.description)
        ? JSON.stringify(convertToRaw(value?.description?.getCurrentContent()))
        : "";
      updatePlaybookGoal({
        variables: {
          input: {
            activityId: editingItem?.data?._id,
            title: value?.title,
            description: descriptionData,
            goalId: editingGoal?._id,
          },
        },
      });
    } else {
      const newPlaybookValue = _.map(mainFormValues?.playbook, (item) => {
        if (item._id == value._id) {
          return {
            ...item,
            title: value?.title,
            description: value?.description,
          };
        } else return item;
      });
      setMainFormValues("playbook", newPlaybookValue);
    }
  };

  const NewGoalSlideDrawer = ({ editingGoal, setEditingGoal }) => {
    return (
      <div className="p-2">
        <div
          className="flex items-center justify-between px-2.5 pt-1 pb-4 "
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
        >
          <Text className="text-base " strong style={{ color: "#808080" }}>
            {_.isEmpty(editingGoal) ? "Create a New Goal" : "Edit Goal"}
          </Text>
          <X
            color="#0d0d0d"
            className="cursor-pointer"
            onClick={() => setIsSlideDrawerOpen(false)}
            size={18}
          />
        </div>
        <div className="mt-2">
          <Formik
            initialValues={{
              title: editingGoal?.title || "",
              description:
                editingGoal && editingGoal?.description
                  ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(editingGoal?.description))
                    )
                  : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (!_.isEmpty(editingGoal)) {
                handleUpdateGoal(values);
              } else {
                handleCreateNewGoal(values);
              }
            }}
            innerRef={newGoalFormRef}
          >
            {({ values, setFieldValue, submitCount, errors, handleSubmit }) => {
              return (
                <div>
                  <Form onSubmit={handleSubmit} className={styles.mainFrom}>
                    <div className="flex justify-between flex-col">
                      <div>
                        <Field
                          placeholder="Marketing goals"
                          required={true}
                          label="Topic"
                          name="title"
                          size="large"
                          hasFeedback={false}
                          component={AntInput}
                          spellCheck="true"
                          help={false}
                          style={{ marginBottom: "0px" }}
                        />
                        <div
                          className={`${styles.drawerEditorDiv} ${
                            submitCount > 0 &&
                            errors.description &&
                            styles.editorErrorDiv
                          } `}
                        >
                          <Field
                            placeholder="What are you going to accomplish?"
                            required
                            render={({ field }) => (
                              <AntForm.Item
                                required
                                labelCol={{ span: 24 }}
                                label="Description"
                                validateStatus={
                                  submitCount > 0 && errors.description
                                    ? "error"
                                    : ""
                                }
                                help={
                                  submitCount > 0 && errors.description
                                    ? errors.description
                                    : ""
                                }
                              >
                                <Editor
                                  editorState={values?.description}
                                  onEditorStateChange={(editorState) =>
                                    editorStateHandler(
                                      editorState,
                                      setFieldValue
                                    )
                                  }
                                  wrapperClassName="flex flex-col-reverse"
                                  placeholder="What are you going to accomplish?"
                                  toolbar={toolbarOptions}
                                  spellCheck="true"
                                />
                              </AntForm.Item>
                            )}
                          />
                        </div>
                      </div>
                      <div className=" ml-16 mt-16">
                        <div className="p-4 flex gap-1 items-center justify-center">
                          <Button onClick={() => setIsSlideDrawerOpen(false)}>
                            Cancel
                          </Button>
                          <Button
                            className={`${styles.blackButton} outline-none border-none ml-2`}
                            type="primary"
                            onClick={handleSubmit}
                            icon={<PlusOutlined />}
                            loading={
                              creatingGoal ||
                              updatingPlaybookGoal ||
                              (progress > 0 && progress < 100)
                            }
                          >
                            {_.isEmpty(editingGoal) ? "Create" : "Update"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  };

  const updateActivityPriority = ({ priority, item, goal }) => {
    if (editingItem) {
      updatePlaybookActivity({
        variables: {
          input: {
            activityId: item._id,
            priority: Number(priority),
            projectId: workspace?.project?._id,
            backlogActivityId: editingItem?.data._id,
            title: item?.title,
            details: item?.details,
            assigned: item?.created_by ? item.created_by?._id : null,
            tags: item?.tags ? item.tags : [],
            fileSources: item?.fileSources,
            tasks: item?.tasks
              ? _.map(item?.tasks, (task) => {
                  return {
                    ..._.omit(task, ["_id"]),
                  };
                })
              : [],
          },
        },
      });
    } else {
      const newPlaybookValue = _.map(mainFormValues?.playbook, (p) => {
        if (p._id === goal._id) {
          return {
            ...p,
            activities: _.map(p?.activities, (a) => {
              if (a._id === item._id) {
                return {
                  ...a,
                  priority: Number(priority),
                };
              }
              return a;
            }),
          };
        }
        return p;
      });
      setMainFormValues("playbook", newPlaybookValue);
    }
  };

  const selectPriority = (item, goal) => {
    const currentPriority = item?.priority ? item?.priority : 0;
    return (
      <Menu
        onClick={(v) => updateActivityPriority({ priority: v.key, item, goal })}
        selectedKeys={item?.priority}
      >
        <Menu.Item
          key={0}
          style={{ background: currentPriority === 0 && "#F5F4FA" }}
          disabled={currentPriority === 0}
        >
          <FlagFilled style={{ color: "lightgrey" }} className="icon-medium" />
          <Text className="ml-2">No Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={3}
          style={{ background: currentPriority === 3 && "#F5F4FA" }}
          disabled={currentPriority === 3}
        >
          <FlagFilled style={{ color: "red" }} className="icon-medium" />
          <Text className="ml-2">High Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={2}
          disabled={currentPriority === 2}
          style={{ background: currentPriority === 2 && "#F5F4FA" }}
        >
          <FlagFilled style={{ color: "orange" }} className="icon-medium" />
          <Text className="ml-2">Medium Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={1}
          style={{ background: currentPriority === 1 && "#F5F4FA" }}
          disabled={currentPriority === 1}
        >
          <FlagFilled style={{ color: "green" }} className="icon-medium" />
          <Text className="ml-2">Low Priority</Text>
        </Menu.Item>
      </Menu>
    );
  };

  const updateAssignee = (assignee, item, goal) => {
    if (editingItem) {
      updatePlaybookActivity({
        variables: {
          input: {
            activityId: item._id,
            projectId: workspace?.project?._id,
            backlogActivityId: editingItem?.data._id,
            title: item?.title,
            details: item?.details,
            assigned: assignee?._id,
            tags: item?.tags ? item.tags : [],
            fileSources: item?.fileSources,
            tasks: item?.tasks
              ? _.map(item?.tasks, (task) => {
                  return {
                    ..._.omit(task, ["_id"]),
                  };
                })
              : [],
            priority: item?.priority,
          },
        },
      });
    } else {
      const newPlaybookValue = _.map(mainFormValues?.playbook, (p) => {
        if (p._id === goal._id) {
          return {
            ...p,
            activities: _.map(p?.activities, (a) => {
              if (a._id === item._id) {
                return {
                  ...a,
                  assigned: assignee?._id,
                };
              }
              return a;
            }),
          };
        }
        return p;
      });
      setMainFormValues("playbook", newPlaybookValue);
    }
  };

  const handleDeleteGoal = (goal) => {
    if (editingItem) {
      removePlaybookGoal({
        variables: {
          input: {
            activityId: editingItem.data._id,
            goalId: goal._id,
          },
        },
      });
    } else {
      const newPlaybookValue = _.filter(
        mainFormValues?.playbook,
        (p) => p._id !== goal._id
      );
      setMainFormValues("playbook", newPlaybookValue);
    }
  };

  const GoalColumn = ({ goal, index }) => {
    return (
      <div className="h-full" style={{ minWidth: "258px", width: 258 }}>
        <div className="h-full flex flex-col">
          <div
            className="flex items-center justify-between h-10 rounded p-2"
            style={{ border: "1px solid #d9d9d9" }}
          >
            <Text className="text-base">{goal.title}</Text>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setEditingGoal(goal);
                      setIsSlideDrawerOpen("newGaol");
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      handleDeleteGoal(goal);
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
            >
              <DotsThreeVertical size={24} color="#0d0d0d" weight="bold" />
            </Dropdown>
          </div>
          <div
            className="mt-3 h-full rounded-md p-1.5 overflow-y-scroll "
            style={{ background: "#F8F8F8", border: "1px solid #DDE2E4" }}
          >
            <Button
              icon={<PlusOutlined />}
              size="large"
              className="w-full mt-1"
              onClick={() => {
                setIsSlideDrawerOpen("newActivity");
                setSelectedGoal(goal?._id);
              }}
            >
              Add New Activity
            </Button>

            <div className="mt-2">
              {_.map(goal?.activities, (activity) => {
                console.log(activity);
                return (
                  <div className="flex flex-col p-4 bg-white border border-gray-200 rounded-md shadow mb-3">
                    <div className="w-full">
                      {activity?.status === 7 ? (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  setEditingPlaybookActivity(activity);
                                  setIsSlideDrawerOpen("newActivity");
                                  setSelectedGoal(goal?._id);
                                }}
                                key="edit"
                              >
                                Edit Activity
                              </Menu.Item>
                              <Menu.Item
                                onClick={() =>
                                  handleDeleteGoalActivity(activity, goal)
                                }
                                key="delete"
                              >
                                Delete Activity
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <DotsThreeVertical
                            size={24}
                            color="#0d0d0d"
                            weight="bold"
                            className="ml-auto cursor-pointer"
                          />
                        </Dropdown>
                      ) : (
                        <div className="h-5"></div>
                      )}
                    </div>
                    <div className="mt-1">
                      <Text strong>
                        {_.truncate(activity?.title, {
                          length: 22,
                          omission: "...",
                        })}
                      </Text>
                    </div>
                    {activity?.tags && (
                      <div className="flex flex-wrap mt-1">
                        {activity?.tags.map((tag) => (
                          <Tag key={tag} color="blue" className="mb-2 mr-2">
                            {tag}
                          </Tag>
                        ))}
                      </div>
                    )}

                    <div className="flex items-center justify-between mt-2">
                      <div>
                        {activity?.status === 7 ? (
                          <Dropdown
                            overlay={() => selectPriority(activity, goal)}
                          >
                            <div className="flex items-center">
                              <FlagFilled
                                style={{
                                  color: utility.getPriority(activity?.priority)
                                    .color,
                                }}
                                className="icon-medium"
                              />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className="flex items-center">
                            <FlagFilled
                              style={{
                                color: utility.getPriority(activity?.priority)
                                  .color,
                              }}
                              className="icon-medium"
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <div>
                          {activity?.status === 7 ? (
                            <Dropdown
                              trigger={["click"]}
                              overlay={() => (
                                <AssigneSelectDropdown
                                  filterSearchUser={filterSearchUser}
                                  setValue={(v) => {
                                    if (v?._id !== activity?.created_by?._id) {
                                      updateAssignee(v, activity, goal);
                                    }
                                  }}
                                  initialValue={filterSearchUser}
                                  value={
                                    _.isEmpty(editingItem)
                                      ? _.find(
                                          filterSearchUser,
                                          (user) =>
                                            user?._id === activity?.assigned
                                        )
                                      : activity?.created_by
                                  }
                                />
                              )}
                            >
                              {activity?.created_by || activity?.assigned ? (
                                <Tooltip
                                  title={
                                    _.isEmpty(editingItem)
                                      ? _.find(
                                          filterSearchUser,
                                          (user) =>
                                            user?._id === activity?.assigned
                                        )?.email
                                      : activity?.created_by?.email
                                  }
                                >
                                  <>
                                    <UserAvatar
                                      size={35}
                                      user={
                                        _.isEmpty(editingItem)
                                          ? _.find(
                                              filterSearchUser,
                                              (user) =>
                                                user?._id === activity?.assigned
                                            )
                                          : activity?.created_by
                                      }
                                      className="cursor-pointer"
                                    />
                                  </>
                                </Tooltip>
                              ) : (
                                <div className="flex items-center">
                                  <UserOutlined />
                                </div>
                              )}
                            </Dropdown>
                          ) : activity?.created_by || activity?.assigned ? (
                            <Tooltip
                              title={
                                _.isEmpty(editingItem)
                                  ? _.find(
                                      filterSearchUser,
                                      (user) => user?._id === activity?.assigned
                                    )?.email
                                  : activity?.created_by?.email
                              }
                            >
                              <>
                                <UserAvatar
                                  size={35}
                                  user={
                                    _.isEmpty(editingItem)
                                      ? _.find(
                                          filterSearchUser,
                                          (user) =>
                                            user?._id === activity?.assigned
                                        )
                                      : activity?.created_by
                                  }
                                  className="cursor-pointer"
                                />
                              </>
                            </Tooltip>
                          ) : (
                            <div className="flex items-center">
                              <UserOutlined />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      title={
        <div className="flex items-center gap-2">
          <CaretLeft
            onClick={() => setPlaybookView(false)}
            className="cursor-pointer"
            size={24}
            color="#0d0d0d"
          />
          <Strategy size={24} color="#0d0d0d" />

          <Text className="text-base" strong>
            Playbook
          </Text>
        </div>
      }
      bodyStyle={{ padding: "14px 28px" }}
      extra={
        <div className="flex items-center">
          <Button onClick={() => setPlaybookView(false)}>Cancel</Button>
          <Button
            className={`${styles.blackButton} outline-none border-none ml-3`}
            style={{ color: "white" }}
            onClick={() => setPlaybookView(false)}
          >
            Save Changes
          </Button>
        </div>
      }
    >
      <div style={{ height: 506 }} className="flex items-center">
        <div className="h-full flex items-center gap-4 overflow-x-scroll">
          {/* Main Visible Part */}
          {_.map(mainFormValues?.playbook, (goal, index) => {
            return <GoalColumn goal={goal} index={index} />;
          })}
          <div className="h-full" style={{ width: "258px" }}>
            <div className="h-full flex flex-col">
              <Button
                icon={<PlusOutlined />}
                size="large"
                className="w-full"
                onClick={() => setIsSlideDrawerOpen("newGaol")}
              >
                Create New Goal
              </Button>
              <div
                className="mt-3 h-full rounded-md "
                style={{ background: "#F8F8F8", border: "1px solid #DDE2E4" }}
              ></div>
            </div>
          </div>
        </div>
        <div
          className={
            isSlideDrawerOpen
              ? styles.rightSlideDrawer
              : styles.rightHiddenSlideDrawer
          }
        >
          <div
            className={
              isSlideDrawerOpen
                ? styles.slideDrawerContent
                : styles.slideDrawerContentHidden
            }
          >
            {isSlideDrawerOpen &&
              (isSlideDrawerOpen === "newGaol" ? (
                <NewGoalSlideDrawer
                  editingGoal={editingGoal}
                  setEditingGoal={setEditingGoal}
                />
              ) : (
                <NewActivitySlideDrawer
                  setIsSlideDrawerOpen={setIsSlideDrawerOpen}
                  filterSearchUser={filterSearchUser}
                  setFilterSearchUser={setFilterSearchUser}
                  handleSearch={handleSearch}
                  projectTags={projectTags}
                  mainFormValues={mainFormValues}
                  mainActivityValues={editingItem?.data}
                  backlogId={backlogId}
                  selectedGoal={selectedGoal}
                  setMainActivityData={setEditingItem}
                  editingPlaybookActivity={editingPlaybookActivity}
                  setEditingPlaybookActivity={setEditingPlaybookActivity}
                  setMainFormValues={setMainFormValues}
                  workspace={workspace}
                  backlogRefetch={backlogRefetch}
                />
              ))}
          </div>
          {/* Slide Drawer */}
        </div>
      </div>
    </Card>
  );
};

export default PlaybookView;
