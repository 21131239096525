import React, { useCallback, useState } from "react";
import propTypes from "prop-types";
import {
  debounce,
  trim,
  uniqBy,
  map,
  toString,
  filter,
  isEmpty,
  isNil,
} from "lodash";
import { useMutation, useApolloClient } from "@apollo/client";
import { Button } from "antd";
import { Formik, Form, Field } from "formik";
import Modal from "../BaseModal/Modal";
import { useRecoilState } from "recoil";
import { AntAutoComplete, AntItemField } from "components/FormikCustomInputs";
import { SEARCH_USER } from "graphql/queries/searchUser";
import { INVITE_MANAGER } from "graphql/mutations/manager/inviteManager";
import { GET_REQUESTS } from "graphql/queries/request/getRequests";
import { GET_NOTIFICATIONS } from "graphql/queries/notification/getNotification";
import { userSession } from "recoil/atoms/User/UserSession";
import utility from "common/utility";
import Loader from "components/Loader";
import * as yup from "yup";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { useLimitWithInvitee } from "hooks/payPerUser";
import styles from "./styles.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const InviteManagerModal = ({
  isVisible,
  onClose,
  projectSelected,
  refetch,
}) => {
  const { pastLimit, setUserLimit } = useLimitWithInvitee();
  const [promptSetLimit, setPromptSetLimit] = useState(false);
  const [user] = useRecoilState(userSession);
  const [filterSearchUser, setFilterSearchUser] = useState([]);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();

  const client = useApolloClient();

  const [inviteManager, { loading }] = useMutation(INVITE_MANAGER, {
    onCompleted({ inviteManager }) {
      createGoogleEvent(user?._id, "INVITE_MANAGER", "app_engagement");

      if (inviteManager.email === user.email) refetch();
      utility.setNotification(
        "Success",
        `${inviteManager.email} has been invite to be a Manager for ${projectSelected.label}`,
        "success",
        "topRight"
      );
      form.current.resetForm();
      onClose();
    },
    onError(error) {
      utility.setNotification(
        "Error",
        `${toString(error)}`,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_REQUESTS, GET_NOTIFICATIONS],
  });

  const initialFormValues = {
    manager: [],
  };

  const form = React.useRef();

  const onSubmit = () => {
    form.current.submitForm();
  };

  const handleDone = (values) => {
    const managerEmail = trim(values?.manager);
    const isPastLimit = pastLimit([managerEmail]);

    if (isPastLimit) {
      if (isNil(setUserLimit)) {
        setPromptSetLimit(true);
        // lets prompt them them to set
      } else {
        // if already set lets prompt them that they are alreadty past limit and to change in settings
        setPromptSetLimit(true);
      }
    } else {
      inviteManager({
        variables: {
          input: { email: managerEmail, projectId: projectSelected.value },
        },
      });
    }
  };

  const validationSchema = yup.object().shape({
    manager: yup
      .string()
      .email("Invalid email")
      .required("This field is required"),
  });

  const handleOnClose = () => {
    form.current.resetForm();
    setFilterSearchUser([]);
    onClose();
  };

  const handleSearch = useCallback(
    debounce(async (v) => {
      if (!isEmpty(v)) {
        setFetching(true);
        const { data } = await client.query({
          query: SEARCH_USER,
          variables: { value: v },
        });
        const res = filter(data?.searchUser, (userFound) => {
          return (
            userFound.email !== user.email && userFound.user_type === "User"
          );
        });

        setFetching(false);
        setFilterSearchUser(uniqBy(res, "email"));
      } else {
        setFilterSearchUser([]);
      }
    }, 300),
    []
  );

  const handleAssignSelf = () => {
    form.current.setFieldValue("manager", user?.email);
  };

  if (promptSetLimit) {
    utility.setNotification(
      "User limit exceed.",
      "In order to select more users please set more users to your current plan",
      "error",
      "topRight"
    );
    history.push("/plans");
  }
  return (
    <>
      <Modal
        title={`Invite a manager to ${projectSelected.label}`}
        width={500}
        footer={null}
        visible={isVisible}
        onClose={handleOnClose}
        content={
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleDone(values)}
            innerRef={form}
          >
            {({ handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className={styles.managerSelect}>
                    <Field
                      component={AntItemField}
                      label="Select a Manager"
                      required="true"
                    />
                    <Button
                      onClick={handleAssignSelf}
                      type="link"
                      style={{
                        padding: "0px 0px 0px 12px",
                        top: "-30px",
                        marginBottom: "-10px",
                      }}
                    >
                      Assign myself
                    </Button>
                    <Field
                      inputType="autoComplete"
                      name="manager"
                      placeholder="Select a Manager"
                      component={AntAutoComplete}
                      selectOptions={map(filterSearchUser, (user) => {
                        return { value: user.email, label: user.email };
                      })}
                      onSearch={handleSearch}
                      dropdownRender={(menu) => <div>{menu}</div>}
                      loading={fetching}
                      hasFeedback
                      required={true}
                      size="large"
                      notFoundContent={
                        fetching ? (
                          <Loader size="small" />
                        ) : (
                          <div className="flex justify-center">No Data</div>
                        )
                      }
                      getPopupContainer={(node) => node.parentNode}
                      filterOption={false}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <div className="flex justify-center items-center">
            <Button onClick={() => handleOnClose()}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => onSubmit()}
              className="ml-3"
              loading={loading}
            >
              Invite
            </Button>
          </div>
        }
      />
    </>
  );
};

InviteManagerModal.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
};

export default InviteManagerModal;
