import React from "react";
import { withRouter } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _, { includes } from "lodash";
import { Button, List, Tag, Typography } from "antd";
import utility from "common/utility";
import FistBumpNotifcationImage from "common/assets/FistBump/notification.png";
import useOpenActivityDrawer from "common/openActivityDrawer";

const { Text } = Typography;

const NotificationCard = ({
  dismiss,
  subject,
  title,
  _id,
  pathname,
  type,
  created_at,
  reject,
  accept,
  meta,
  url,
  handleHandoffAction,
  setAcceptProjectData = () => {},
  showRateModal = false,
  setNotificationDropdownVisible,
}) => {
  const history = useHistory();
  let pageAction = { action: () => {}, label: "" };
  const managerViewPath = ["/people", "/people?view=managers", "/", "/projects"];
  const memberViewPath = ["/people", "/people?view=members", "/"];
  const clientViewPath = ["/clients", "/", "/projects"];
  const removeViewPath = ["/projects", "/"];
  const { openActivityDrawer } = useOpenActivityDrawer();

  const onRefresh = () => {
    history.go(pathname);
  };

  const onNavigate = () => {
    if (type === "Manager")
      history.push({
        pathname: "/people",
        search: queryString.stringify({ view: "managers" }),
      });

    if (type === "Member")
      history.push({
        pathname: "/people",
        search: queryString.stringify({ view: "members" }),
      });

    if (type === "Client")
      history.push({
        pathname: "/clients",
      });

    if (type === "Remove")
      history.push({
        pathname: "/projects",
      });
  };

  if (type === "Manager") {
    pageAction.label = includes(managerViewPath, pathname)
      ? "Refresh page"
      : `Go to ${type} page`;

    pageAction.action = includes(managerViewPath, pathname)
      ? onRefresh
      : onNavigate;
  }

  if (type === "Member") {
    pageAction.label = includes(memberViewPath, pathname)
      ? "Refresh page"
      : `Go to ${type} page`;

    pageAction.action = includes(memberViewPath, pathname)
      ? onRefresh
      : onNavigate;
  }

  if (type === "Client") {
    pageAction.label = includes(clientViewPath, pathname)
      ? "Refresh page"
      : `Go to ${type} page`;

    pageAction.action = includes(clientViewPath, pathname)
      ? onRefresh
      : onNavigate;
  }

  if (type === "Remove") {
    pageAction.label = includes(removeViewPath, pathname)
      ? "Refresh page"
      : `Go to Project page`;

    pageAction.action = includes(clientViewPath, pathname)
      ? onRefresh
      : onNavigate;
  }

  const NotifcationAction = () => {
    switch (type) {
      case "Invite": {
        const data = utility.IsJsonString(meta?.value)
          ? JSON.parse(meta?.value)
          : null;

        const hasInvoicing = _.some(
          data?.addons,
          (a) => a.name === "Invoicing"
        );

        return (
          <>
            <div className="flex justify-items-start">
              <Button
                type="primary"
                className="mr-4 px-6"
                onClick={(e) => {
                  if (hasInvoicing) {
                    showRateModal(true);
                    setAcceptProjectData({
                      workspaceId: data?.project?.workspaceId,
                      status: "Accepted",
                      _id,
                      requestId: data?.requestId,
                      projectId: data?.project._id,
                    });
                  } else {
                    accept({
                      status: "Accepted",
                      _id,
                      requestId: data?.requestId,
                      projectId: data?.project._id,
                    });
                  }
                  e.stopPropagation();
                }}
              >
                Accept
              </Button>
              <Button
                className="px-6"
                onClick={(e) => {
                  reject({
                    status: "Rejected",
                    _id,
                    requestId: data?.requestId,
                    projectId: data?.project._id,
                  });
                  e.stopPropagation();
                }}
              >
                Reject
              </Button>
            </div>
          </>
        );
      }
      case "":
        return null;
      case "Remove":
        return (
          <div className="flex justify-items-start">
            <Button className="px-6" onClick={onRefresh}>
              Refresh Page
            </Button>
          </div>
        );
      case "Member":
        return (
          <div className="flex justify-items-start">
            <Tag color="green">Accepted</Tag>
          </div>
        );

      case "reminder":
        if (meta?.key === "WORKSPACE_ID")
          return (
            <div className="flex justify-items-start">
              <Button
                onClick={() => history.push(`/workspace?id=${meta?.value}`)}
                type="primary"
                className="px-6"
              >
                Go to workspace
              </Button>
            </div>
          );
      case "Handoff":
        if (meta?.key === "handoffData")
          return (
            <div className="flex justify-items-start">
              <Button
                onClick={() => handleHandoffAction(meta?.value)}
                type="primary"
                className="px-6"
              >
                View activity
              </Button>
            </div>
          );

      case "Github":
        if (url)
          return (
            <Button
              onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
            >
              View pull request
            </Button>
          );
      case "Reward":
        if (meta?.value)
          return (
            <Button
              onClick={() =>
                window.open(meta.value, "_blank", "noopener,noreferrer")
              }
            >
              Redeem now
            </Button>
          );
      case "activity":
        if (meta?.key === "activityId")
          return (
            <div className="flex justify-items-start">
              <Button
                onClick={() => {
                  openActivityDrawer({ _id: meta?.value }, true, false);
                  setNotificationDropdownVisible(false);
                }}
                type="primary"
                className="px-6"
              >
                View activity
              </Button>
            </div>
          );
        if (meta?.key === "backlogId")
          return (
            <div className="flex justify-items-start">
              <Button
                onClick={() => {
                  openActivityDrawer({ _id: meta?.value }, true, true);
                  setNotificationDropdownVisible(false);
                }}
                type="primary"
                className="px-6"
              >
                View activity
              </Button>
            </div>
          );

      case "giftLink":
        if (url)
          return (
            <Button
              onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
            >
              Redeem now
            </Button>
          );
      default:
        return "";
    }
  };

  return (
    <List.Item key={_id} className="p-5">
      <List.Item.Meta
        className="flex items-center"
        title={type !== "FistBump" && title}
        description={
          <>
            <div className="flex justify-between items-center">
              {type === "FistBump" ? (
                <div className="flex items-center">
                  <div className="flex flex-col">
                    <Typography.Text
                      style={{ fontSize: 16 }}
                      className="font-semibold"
                    >
                      Keep up the good work
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: 16 }}>
                      {subject}
                    </Typography.Text>
                  </div>
                  <img
                    src={FistBumpNotifcationImage}
                    alt="Windu"
                    style={{
                      maxWidth: "80px",
                      transform: "scale(1.5)",
                      marginLeft: 10,
                    }}
                  />
                </div>
              ) : (
                <div className="mb-2 mr-3">{subject}</div>
              )}
              {type !== "Handoff" && (
                <div className="ml-2">
                  <CloseOutlined
                    onClick={(e) => {
                      dismiss(_id);
                      e.stopPropagation();
                    }}
                    className="bg-gray-200"
                    style={{ padding: 5, borderRadius: "50%", fontSize: 12 }}
                  />
                </div>
              )}
            </div>
            <Text className="text-xs mb-2">
              {moment(created_at).format("MM/DD/YYYY, h:mm:ss a")}
            </Text>
          </>
        }
      />
      <NotifcationAction />
    </List.Item>
  );
};

export default withRouter(NotificationCard);
