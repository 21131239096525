import React from "react";
import { Field } from "formik";
import { map } from "lodash";
import FloaterInput from "components/FloaterInput";
import cc from "currency-codes";

const currency = map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const RateInput = ({ submitCount }) => {
  return (
    <>
      <Field
        component={FloaterInput}
        name="currency"
        label="Currency"
        placeholder="Currency"
        submitCount={submitCount}
        options={currency}
        loading={false}
        inputType="select"
        showSearch
        size="large"
        required
        hasFeedback
      />
      <Field
        component={FloaterInput}
        name="rate"
        label="Set hourly rate"
        placeholder="Set hourly rate"
        submitCount={submitCount}
        hasFeedback
        size="large"
        type="number"
        min="0"
      />
    </>
  );
};

export default RateInput;
