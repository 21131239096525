import React from "react";
import Icon from "@ant-design/icons";
import Icons from "common/icons";
import { useRecoilValue } from "recoil";
import { customizeState } from "recoil/atoms/Dashboard/customize";
import styles from "./styles.module.css";

const DragHandle = () => {
  const customize = useRecoilValue(customizeState);

  if (customize)
    return (
      <div className="drag-handle w-full h-full absolute z-10 rounded bg-gray-400 opacity-75 z-10">
        <Icon
          className="w-full h-full flex items-center justify-center text-white cursor-move"
          component={Icons.DragAndDrop}
        />
      </div>
    );
  return null;
};
export default DragHandle;

/*


    right: 0px; 
    text-align: center;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: gray;
    opacity: 71%;

    */
