import gql from "graphql-tag";

export const GET_WORKSPACE_RATE = gql`
  query($input: getWorkspacesWidgetsInput) {
    getWorkspaceRate(input: $input) {
      currency
      rate
    }
  }
`;
