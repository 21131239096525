import { Button, Modal, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { REMOVE_WORKSPACE } from "graphql/mutations/workspace/removeWorkspace";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import utility from "common/utility";

const { Text } = Typography;

const DeleteWorkspaceModal = ({ isVisible, setisVisible, workspaceId }) => {
  const history = useHistory();

  const [removeWorkspace] = useMutation(REMOVE_WORKSPACE, {
    onCompleted: () => {
      history.push("/");
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [{ query: GET_WORKSPACES }],
  });

  const workspaceDeleteHandler = () => {
    removeWorkspace({ variables: { input: { workspaceId } } });
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      closable={false}
      onCancel={() => setisVisible(false)}
      width="630px"
    >
      <div className="flex flex-col mt-4 px-4">
        <Text className="font-semibold text-lg">Delete Workspace</Text>
        <Text className="mt-2 text-base">
          Are you sure you want to delete this workspace?
        </Text>
        <Text className="mt-1 text-base">
          You will lose all of your invoice history, as well as communication
          with your team and client.
        </Text>
        <div className="flex w-full justify-evenly mt-8">
          <Button
            size="large"
            className="px-14"
            onClick={() => setisVisible(false)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            className="px-4"
            onClick={workspaceDeleteHandler}
          >
            Delete Workspace
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWorkspaceModal;
