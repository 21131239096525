import { Modal, Typography, Form as AntForm, Popover, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ContinueImage from "common/assets/timebox/continue.svg";
import {
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { TimeBoxField } from "components/CustomComponents/TimeboxInput";
import moment from "moment";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTimer } from "contexts/timer-context";
import { last, cloneDeep, get } from "lodash";
import utility from "common/utility";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { useLazyQuery, useMutation } from "@apollo/client";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { updateContinueActivityList } from "common/cacheUtilities";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { GET_USER_FREE_TIME } from "graphql/queries/timebox/getUserFreeTime";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";

const currentTime = moment().format();

const ContinueWithTimebox = ({
  isVisible,
  setVisible,
  activity,
  text = "Keep up the great work, since you are continuing this activity lets apply a new timebox!",
  fromPlanned,
  handleStartCalback,
}) => {
  const [timebox, setTimebox] = useState("00:00:00");
  const user = useRecoilValue(userSession);
  const setActivity = useSetRecoilState(activeActivity);
  const setRefetchIndicator = useSetRecoilState(refetchIndicator);
  const { startHandler } = useTimer(); // global startHandler from context
  const [userFreetime, setUserFreetime] = useState(180);

  const [getUserFreeTime, { loading: freeTimeloading }] = useLazyQuery(
    GET_USER_FREE_TIME,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          userId: user._id,
          date: currentTime,
        },
      },
      onCompleted: ({ getUserFreeTime }) => {
        setUserFreetime(getUserFreeTime);
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    getUserFreeTime();
  }, []);

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true };
    });
  };

  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: ({ continueActivity }) => {
        handleRefetchingViews();
        setActivity({
          active: true,
          data: continueActivity,
        });
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
        setVisible(false);
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_PAUSED_ACTIVITY, GET_TODAY_WIDGET_DATA],
    }
  );

  const continueActivityFunction = (activity, timebox) => {
    const pausedCopy = cloneDeep(activity?.time?.paused);
    const lastPause = last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: {
          activity_id: activity._id,
          time: moment.utc(new Date()),
          timebox,
        },
      },
    });
  };

  const handleSkip = () => {
    if (fromPlanned) {
      handleStartCalback({ item: activity, timebox: "00:00:00" });
      setVisible(false);
    } else {
      continueActivityFunction(activity, "00:00:00");
    }
  };

  const handleContinue = () => {
    if (fromPlanned) {
      handleStartCalback({ item: activity, timebox });
      setVisible(false);
    } else {
      continueActivityFunction(activity, timebox);
    }
  };

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title={
        <div className="flex items-center justify-between">
          <Typography.Text>
            Would you like to continue using timebox?
          </Typography.Text>
          <div>
            <CloseOutlined onClick={() => setVisible(false)} />
          </div>
        </div>
      }
      okText="Continue"
      cancelText="Skip"
      width={700}
      onCancel={handleSkip}
      onOk={handleContinue}
      closable={false}
    >
      <div className="flex justify-center items-center flex-col">
        <img src={ContinueImage} width="200" />
        <Typography.Text className=" text-base m-4 px-4 text-center">
          {text}
        </Typography.Text>
      </div>
      <div>
        <AntForm.Item
          labelCol={{ span: 24 }}
          label={
            <div>
              <Typography.Text className="mr-2">Timebox</Typography.Text>
              <Popover
                content={
                  <div className="mr-2 w-96">
                    <Typography.Text>
                      Our Time-Boxing technology helps make suggestions for a
                      user's workload, with rules, by analyzing a user's
                      behavior, and workload. This helps to determine when work
                      time begins affecting health, which is where the chances
                      of burnout increase.
                    </Typography.Text>
                  </div>
                }
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>
          }
        >
          <Spin spinning={freeTimeloading} indicator={<LoadingOutlined spin />}>
            <TimeBoxField
              freeTime={userFreetime}
              onChange={(v) => setTimebox(v)}
            />
          </Spin>
        </AntForm.Item>
      </div>
    </Modal>
  );
};

export default ContinueWithTimebox;
