import { Button, Form } from "antd";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import React from "react";
import CustomTimePicker from "./TimePicker";
import * as yup from "yup";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState } from "recoil";
import { CREATE_REMINDER } from "graphql/mutations/reminder/createReminder";
import utility from "common/utility";
import { get } from "lodash";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import { GET_USER } from "graphql/queries/getUser";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const validationSchema = yup.object().shape({
  phone_number: yup.string().required("Phone is required"),
});

const DropdownContent = ({
  activeReminder,
  setactiveReminder,
  onRemove,
  onCreate,
  setshowReminderDropdown,
  callback,
}) => {
  const [user] = useRecoilState(userSession);

  const [createReminder] = useMutation(CREATE_REMINDER, {
    onCompleted: () => {
      if (callback) callback();
      utility.setNotification(
        `Success`,
        "Reminder Created",
        "success",
        "topRight"
      );
      onCreate();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      utility.setNotification(
        "Phone number added successfully",
        `information updated`,
        "success",
        "topRight"
      );

      setshowReminderDropdown(false);
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
  });

  const handleDone = (values) => {
    const { phone_number = "", time, timeZone } = values;
    const phone = parsePhoneNumber(phone_number);

    if (!user?.phone_number?.phone) {
      updateUser({
        variables: {
          input: {
            name: user?.full_name,
            phone_number: {
              phone: phone?.number || "",
              short: phone?.country || "",
              code: phone?.countryCallingCode || "",
            },
          },
        },
      });
    } else {
      createReminder({
        variables: {
          input: {
            time,
            timeZone,
            phone_number: {
              phone: phone?.number || "",
              short: phone?.country || "",
              code: phone?.countryCallingCode || "",
            },
          },
        },
      });
      setactiveReminder(true);
    }
  };

  if (activeReminder) {
    return (
      <div className="flex flex-col px-10 py-4">
        <Button onClick={onRemove}>Remove</Button>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{ phone_number: user?.phone_number?.phone || "" }}
      onSubmit={(values) => handleDone(values)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        return !user?.phone_number?.phone ? (
          <div style={{ maxWidth: 210 }}>
            <Form onSubmit={handleSubmit}>
              <div className="field-container w-full">
                <Form.Item
                  help={
                    get(errors, "phone_number", null)
                      ? "Phone number is not valid"
                      : false
                  }
                  validateStatus={
                    get(errors, "phone_number", null) ? "error" : "success"
                  }
                  name="phone_number"
                  initialValue={values.phone_number}
                  label={"Phone number"}
                  placeholder="Phone number"
                  required={true}
                  labelCol={{ span: 24 }}
                >
                  <PhoneInput
                    international
                    placeholder="Phone number"
                    value={values.phone_number}
                    onChange={(value) => setFieldValue("phone_number", value)}
                    className="ant-input ant-input-lg focus:ant-input-focused input-phone-number"
                  />
                </Form.Item>
              </div>
              <Button
                onClick={handleSubmit}
                type="submit"
                className="mt-2 ml-1"
              >
                Save
              </Button>
            </Form>
          </div>
        ) : (
          <div className="flex items-center flex-col">
            <div className="flex justify-between">
              <CustomTimePicker setFieldValue={setFieldValue} />
            </div>
            <Button type="submit" className="mt-3" onClick={handleSubmit}>
              Create
            </Button>
          </div>
        );
      }}
    </Formik>
  );
};

export default DropdownContent;
