import React from "react";
import { Modal as AntModal, Divider } from "antd";
import { bool, func, element, string } from "prop-types";

const Modal = ({
  title,
  isVisible,
  onClose,
  content,
  actions,
  footer,
  ...props
}) => {
  return (
    <AntModal
      //closeIcon TO DO
      title={title}
      visible={isVisible}
      onCancel={() => onClose()}
      footer={footer}
      {...props}
    >
      <div>
        {content}
        {!footer && <Divider />}
        {actions}
      </div>
    </AntModal>
  );
};

Modal.propTypes = {
  title: string,
  isVisible: bool,
  onClose: func,
  content: element,
  actions: element,
};

export default Modal;
