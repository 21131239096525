import gql from "graphql-tag";

export const GET_MONDAY_BOARD = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getMondayBoards(input: $input) {
      id
      name
    }
  }
`;
