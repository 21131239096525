import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Button, Divider, Typography } from "antd";
import _ from "lodash";
import { instanceOf } from "prop-types";
import { useMutation } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { REGISTER } from "graphql/mutations/register";
import { GET_USER } from "graphql/queries/getUser";
import { validationRegisterSchema } from "./validationSchema";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { LOGIN_WITH_GOOGLE } from "graphql/mutations/user/loginWithGoogle";
import GoogleLoginBtn from "./GoogleLogin";
import Cookies from "js-cookie";
import FloaterInput from "components/FloaterInput";
import WinduLogo from "common/assets/WinduLogo.svg";
import styles from "./styles.module.css";
import utility from "common/utility";

const { Text, Title } = Typography;

const initialFormValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const Register = ({ client, setView, referralCode }) => {
  const [emailSet, setEmailSet] = useState(null);

  const form = React.useRef();

  const handleError = (error) => {
    const { message } = error;
    utility.setNotification(
      "Something wrong happened",
      message,
      "error",
      "topRight"
    );
  };

  const [register, { loading }] = useMutation(REGISTER, {
    onError: (error) => handleError(error),
    onCompleted: () => {
      createGoogleEvent(null, "REGISTER_USER", "app_engagement");

      window.location.href = "/login?confirm=true";
    },
    update(_, { data }) {
      const payload = {
        getUser: {
          ...data.register.user,
        },
      };
      client.writeQuery({
        query: GET_USER,
        data: payload,
      });
    },
  });

  const onRegister = (values) => {
    const { email, password, name } = values;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    register({
      variables: {
        input: {
          email: _.toLower(email),
          password,
          name: name,
          timezone,
          referralCode,
        },
      },
    });
  };

  const [googleLogin, { loading: loadingGoogle }] = useMutation(
    LOGIN_WITH_GOOGLE,
    {
      onError: (error) => handleError(error),
      onCompleted: ({ googleLogin }) => {
        Cookies.set("token", googleLogin.token, { expires: 7 }); // expires 7 days from now

        if (googleLogin?.new_user) {
          createGoogleEvent(null, "REGISTER_USER", "app_engagement");
          window.location.href = "/?withTour=true";
        } else {
          createGoogleEvent(
            googleLogin.user?._id,
            "LOGIN_USER",
            "app_engagement"
          );

          window.location.href = "/";
        }
      },
    }
  );

  const handleGoogleLogin = ({ profileObj }) => {
    const { email, name, imageUrl } = profileObj;
    googleLogin({
      variables: {
        input: {
          email: _.toLower(email),
          name,
          avatar: imageUrl,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          platform: "web",
          referralCode,
        },
      },
    });
  };

  const FirstStep = ({ submitCount, values, errors }) => {
    const handleEmailNext = () => {
      const hasError = errors["email"];

      if (_.isEmpty(hasError) && !_.isEmpty(values?.email)) {
        setEmailSet(values?.email);
      }
    };
    return (
      <>
        <Field
          component={FloaterInput}
          name="email"
          label="Work or SSO email"
          placeholder="Work or SSO email"
          submitCount={submitCount}
          hasFeedback
          required={true}
          size="large"
        />
        <Button
          className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
          type="primary"
          size="large"
          loading={loading || loadingGoogle}
          onClick={handleEmailNext}
        >
          Next
        </Button>
      </>
    );
  };

  const NameAndPasswordStep = ({ submitCount }) => {
    return (
      <>
        <Field
          component={FloaterInput}
          name="name"
          label="Full Name"
          placeholder="Your full name here"
          submitCount={submitCount}
          hasFeedback
          required={true}
          size="large"
        />
        <Field
          component={FloaterInput}
          name="password"
          label="Password"
          placeholder="Your secret password"
          submitCount={submitCount}
          hasFeedback
          required={true}
          size="large"
          inputType="password"
        />
        <Field
          component={FloaterInput}
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm password"
          submitCount={submitCount}
          hasFeedback
          required={true}
          size="large"
          inputType="password"
        />
        <Button
          onClick={() => {
            form.current.submitForm();
          }}
          className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
          type="primary"
          size="large"
          loading={loading || loadingGoogle}
        >
          Sign Up
        </Button>
      </>
    );
  };

  return (
    <div
      className="px-12 text-center w-3/4 m-auto flex flex-col justify-between"
      style={{ marginTop: "20%", maxWidth: 500, height: "80vh" }}
    >
      <div>
        {_.isEmpty(emailSet) ? (
          <div className="mb-6">
            <div className="flex items-baseline justify-center">
              <img src={WinduLogo} alt="windu" style={{ height: 30 }} />
              <Title level={2} className="ml-2">
                Windu
              </Title>
            </div>
            <Text className="text-base my-4">
              Create your free account and save time with windu <br /> today!
            </Text>
          </div>
        ) : (
          <div className="mb-6">
            <div className="flex items-baseline justify-center">
              <Title level={2} className="ml-2">
                Welcome to Windu!
              </Title>
            </div>
            <Text className="text-base my-2">
              You're signing up as {emailSet}
              <Text
                className="ml-1 cursor-pointer"
                onClick={() => setEmailSet(null)}
                style={{ color: "#F5A623" }}
              >
                Change
              </Text>
            </Text>
          </div>
        )}
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationRegisterSchema}
          onSubmit={onRegister}
          innerRef={form}
        >
          {({ submitCount, handleSubmit, values, errors }) => {
            return (
              <Form onSubmit={handleSubmit} className="text-left">
                {_.isEmpty(emailSet) ? (
                  <FirstStep
                    submitCount={submitCount}
                    values={values}
                    errors={errors}
                  />
                ) : (
                  <NameAndPasswordStep submitCount={submitCount} />
                )}

                <Divider>
                  <Text style={{ color: "#64748B" }}>OR</Text>
                </Divider>

                <GoogleLoginBtn
                  onSuccess={handleGoogleLogin}
                  text={"Register using Google"}
                />
                <div className="flex text-center items-center justify-center my-10">
                  <Text>Already using windu?</Text>
                  <Button
                    type="text"
                    className="p-0 m-0 ml-1.5"
                    onClick={() => setView("login")}
                  >
                    <Text style={{ color: "#F5A623" }} className="underline">
                      Login here
                    </Text>
                  </Button>
                </div>
                <div className="my-4 text-center">
                  <Text>
                    By signing up, I agree to Windu's
                    <a
                      rel="noreferrer"
                      href="https://www.windu.io/privacy-policy/"
                      target="_blank"
                    >
                      &nbsp;
                      <Text className="text-black underline">
                        Privacy Policy
                      </Text>
                      &nbsp;
                    </a>
                    and
                    <br />
                    <a
                      rel="noreferrer"
                      href="https://www.windu.io/terms-service/"
                      target="_blank"
                    >
                      &nbsp;
                      <Text className="text-black underline">
                        Terms of Service
                      </Text>
                      &nbsp;
                    </a>
                  </Text>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="text-center">
        <Text>Copyright © Windu 2023 | All rights reserved</Text>
      </div>
    </div>
  );
};

Register.propTypes = {
  client: instanceOf(Object).isRequired,
};

export default withApollo(Register);
