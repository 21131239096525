import React from "react";
import { Card, Badge, Image } from "antd";
import Icon, { CloudDownloadOutlined } from "@ant-design/icons";
import { fileIcons, fileName, extension, attachedIsImage } from "./common";
import _ from "lodash";
import styles from "./styles.module.css";

const AttachedFile = ({ file, index }) => {
  if (attachedIsImage(file))
    return (
      <Badge
        key={index}
        count={
          <a href={file} download>
            <CloudDownloadOutlined className="icon bg-white rounded-full p-1" />
          </a>
        }
        offset={[0, 10]}
        className={`mr-4 ${styles.imgContainer}`}
      >
        <Image src={file} className="max-w-lg	max-h-lg" />
      </Badge>
    );
  return (
    <Card size="small" style={{ boxShadow: "none" }} className="bg-gray-100">
      <div className="flex justify-center items-center">
        <Icon
          component={fileIcons[extension(file)]}
          className="icon-large mr-2"
        />
        <p>{_.truncate(fileName(file), { length: 20 })}</p>
      </div>
    </Card>
  );
};
export default AttachedFile;
