import React from "react";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import Settings from "./Settings";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import moment from "moment";

const View = ({ token, view, onRejected }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  return (
    <>
      <div className="p-5"> Today is {moment().format("MMMM DD YYYY")}</div>
      {view === "upcoming" && (
        <Upcoming token={token} id={id} onRejected={onRejected} view={view} />
      )}
      {view === "completed" && (
        <Completed token={token} id={id} onRejected={onRejected} view={view} />
      )}
      {view === "settings" && (
        <Settings token={token} id={id} onRejected={onRejected} view={view} />
      )}
    </>
  );
};
export default View;
