import nanoid from "nanoid";

export const DemoCategoryData = [
  {
    key: 1,
    name: "Productivity Tips",
    description:
      "Explore some best practices of our Productivity solutions with helpful tips.",
    articles: [
      {
        key: nanoid(),
        name: "Keeping my backlog organized",
        description:
          "Learn how Windu's agile framework makes it easy to organise your projects, prevent task duplication in your backlog, and aids in task prioritisation.",
      },
      {
        key: nanoid(),
        name: "Marking your activity as complete",
        description:
          "You have put in the work, let's wrap it up. Learn how to seamlessly mark tasks as complete and allocate your most precious resource, time.",
      },
      {
        key: nanoid(),
        name: "How to send a gift card to my team",
        description:
          "We provide a step-by-step guide on how to keep your team motivated using the universally appreciated gesture of gift cards.",
      },
      {
        key: nanoid(),
        name: "Plan out your tasks in advance",
        description:
          "Looking to predict your project's outcomes? We will guide you on strategically planning tasks ahead of time for optimal results.",
      },
      {
        key: nanoid(),
        name: "Best practices for breaking down an activity",
        description:
          "Let's help you turn your complex project goals into attainable subtasks, making them easier to achieve within manageable timeframes.",
      },
      {
        key: nanoid(),
        name: "Avoiding duplicates in the backlog",
        description:
          "Helping you focus on crushing your project goals, we will show you how to use Windu’s backlog manager to prevent task duplication.",
      },
      {
        key: nanoid(),
        name: "Receive a SMS as a reminder",
        description:
          "Explore effective ways to integrate SMS reminders into your project workflows. From meeting deadlines to taking breaks or switching tasks, we'll ensure you stay on top your deadlines effortlessly.",
      },
    ],
  },
  {
    key: 2,
    name: "Time Tracking",
    description:
      "Explore some best practices of our time tracking solutions with helpful tips.",
    articles: [
      {
        key: nanoid(),
        name: "Avoid working too much on an activity",
        description:
          "Using insights from your past performance and current workload, we will help you work smarter, prevent burnout, and suggest the ideal time to allocate to each task using Windu Timebox.",
      },
      {
        key: nanoid(),
        name: "Pausing my time and continuing it later",
        description:
          "Let's make every second count, including your well-deserved breaks. We will show you how to pause and resume your tasks while staying on top of your goals.",
      },
      {
        key: nanoid(),
        name: "Using windu inside of Figma",
        description:
          "Find out how to use the Figma Plugin to effortlessly track the time you spend on designs and assign frames to tasks in your projects. ",
      },
      {
        key: nanoid(),
        name: "How to track your time with Monday.com",
        description:
          "Need advanced time tracking for your Monday.com projects, we have got you covered.",
      },
      {
        key: nanoid(),
        name: "Track how long meetings take and push them as activities",
        description:
          "Discover the simple steps to sync your calendar with Windu, capture time spend in meetings and  convert those appointments into activities in your project.",
      },
      {
        key: nanoid(),
        name: "How to pull your current Jira sprint and track your time",
        description:
          "Explore how integrating Windu with Jira makes it easy to keep track of the time you spend on issues throughout your sprints. ",
      },
      {
        key: nanoid(),
        name: "Track time outside of Windu",
        description:
          "Whether  on the web using a Chrome extension or on the go with our mobile app, see how Windu can seamlessly fit into your workflow.",
      },
    ],
  },
  {
    key: 3,
    name: "Analytics & Reports Tips",
    description:
      "Explore some best practices of our Analytics & Reports solutions with helpful tips.",
    articles: [
      {
        key: nanoid(),
        name: "Segment and analyze my project activities",
        description:
          "Give your reports an extra layer of insight with custom tags and categorisation. Know how and when to apply them to your activities for deeper analysis of your project. ",
      },
      {
        key: nanoid(),
        name: "How to take my project’s time and send it as an invoice",
        description:
          "After all your hard work, it's time to present the results to your client. Let's walk you through few easy steps to generate and send detailed invoices.",
      },
      {
        key: nanoid(),
        name: "View who is working in real-time",
        description:
          "Learn how to enhance team collaboration using the Active Timer and Recent Activity Widget, keeping you informed about your team's activities in real time.",
      },
      {
        key: nanoid(),
        name: "View how much I contributed for today",
        description:
          "Curious about your daily input on a project? Let's dive into the timeline report for a quick self-assessment.",
      },
      {
        key: nanoid(),
        name: "Viewing how long it took for an activity to go through design and development",
        description:
          "Discover how Windu seamlessly consolidates your team's efforts, from managing tasks in Jira,  logging design hours in Figma, to Handoff and deployment by developers on GitHub.",
      },
    ],
  },
];
