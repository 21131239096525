import { Badge, Card, Progress, Typography } from "antd";
import React from "react";
import _ from "lodash";

const { Text, Title } = Typography;

const stepsInfo = [
  {
    key: 1,
    title: "Select mode",
    description: "Set your default mode.",
    uniqId: "defaultModeSet",
  },
  {
    key: 2,
    title: "Example project",
    description: "Experience teams using windu with real data",
    uniqId: "exampleProject",
  },
  {
    key: 3,
    title: "Create your first project",
    description: "Plan an activity for your first project",
    uniqId: "firstProject",
  },
  {
    key: 4,
    title: "Invite others",
    description: "Invite people to join",
    uniqId: "inviteOther",
  },
];

const ProgressCard = ({ stepCovered }) => {
  const steps = {
    ...stepCovered,
  };
  delete steps.heardAboutPrompt;

  const coveredSteps = _.filter(steps, (item) => item);

  const progressPercnetage = Math.round(
    (coveredSteps.length / stepsInfo.length) * 100
  );
  const StepCard = ({ data }) => {
    const isCovered = stepCovered[data.uniqId];

    return (
      <div className="flex mb-5">
        <div>
          <Badge
            count={data.key}
            style={{
              background: "#F2F2F2",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            color="#A6A6A6"
          />
        </div>
        <div className="ml-4">
          <Title
            level={4}
            style={{ marginBottom: 4 }}
            delete={isCovered}
            mark={isCovered}
          >
            {data.title}
          </Title>
          <Text className="text-base">{data.description}</Text>
        </div>
      </div>
    );
  };
  return (
    <Card style={{ width: "84%", margin: "auto", borderRadius: "12px" }}>
      <Title level={3}>Let's make Windu a better experience for you!</Title>
      <Text className="text-base">Get the most of Windu App!</Text>
      <Progress
        className="mt-5"
        strokeColor="#6DD75C"
        percent={progressPercnetage}
      />
      <div className="mt-7">
        {stepsInfo.map((item) => (
          <StepCard data={item} />
        ))}
      </div>
    </Card>
  );
};

export default ProgressCard;
