import gql from "graphql-tag";

export const BACKLOG_CHANGE = gql`
  subscription backlogChange {
    backlogChange {
      workspaceId
      action
    }
  }
`;
