import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { useLazyQuery } from "@apollo/client";
import utility from "common/utility";
import _ from "lodash";
import Invoice from "./Invoice/index";
import Loader from "components/Loader";
import { GET_VISITOR } from "graphql/queries/Visitor/getVisitor";

const Generator = () => {
  const [user, setUser] = useRecoilState(userSession);
  const [getUserData, { loading }] = useLazyQuery(GET_VISITOR, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getVisitor }) => {
      setUser(getVisitor);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      if (errorMessage !== "you must be logged in to query this schema") {
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
        Cookies.remove("token");
        window.location.href = "/generator";
      }
    },
  });

  useEffect(() => {
    if (Cookies.get("token")) {
      getUserData();
    }
  }, [Cookies.get("token")]);

  if (loading) return <Loader size="large" />;
  return <Invoice />;
};

export default Generator;
