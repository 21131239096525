import React from "react";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import Dashboard from "views/Dashboard";
import Clients from "views/Clients";
import NotFound from "views/Provisional/404";
import TimeLine from "views/TimeLine";
import Projects from "views/Projects";
import ProjectDetail from "views/Projects/Details";
import Teams from "views/Teams";
import Workspace from "views/Workspace";
import Import from "views/Import";
import Integration from "views/Integration";
import Authorize from "views/Authorize";
import moment from "moment";
import Report from "views/Report";
import { isNil } from "lodash";
import QuickStart from "views/QuickStart";
import Setting from "views/Setting";
import { INDIVIDUAL_PLAN } from "constants/plans";
import Search from "views/Search";
import CalendarView from "views/Calendar";

const withUserTypeComponent = ({ match: { path }, user }) => {
  const { user_type } = user;

  const isIndividualPlan =
    user?.plan?.name === INDIVIDUAL_PLAN || isNil(user?.plan);

  switch (user_type) {
    case "User":
      switch (path) {
        case "/":
          return <Dashboard />;
        case "/projects":
          return <Projects />;
        case "/projects/:id":
          return <ProjectDetail />;
        case "/timeline":
          return <TimeLine />;
        case "/clients":
          return <Clients />;
        case "/settings":
          return <Setting />;
        case "/people":
          return <Teams />;
        case "/search":
          return <Search />;
        case "/calendar":
          return <CalendarView />;
        case "/integration":
          return <Integration />;
        case "/workspace":
          return (
            <OptimizelyFeature feature="workspace">
              {(_, variables) => {
                return <Workspace flags={variables} />;
              }}
            </OptimizelyFeature>
          );
        case "/import":
          return (
            <OptimizelyFeature feature="import">
              {(_, variables) => {
                return variables?.showImport ? <Import /> : <NotFound />;
              }}
            </OptimizelyFeature>
          );

        case "/quickstart":
          return <QuickStart />;

        case "/report":
          if (isIndividualPlan) return <NotFound />;
          else
            return (
              <OptimizelyFeature feature="reports">
                {(isEnabled) => {
                  return isEnabled ? <Report /> : <NotFound />;
                }}
              </OptimizelyFeature>
            );
        case "/authorize":
          return <Authorize />;

        default:
          return <NotFound />;
      }

    default:
      return <NotFound />;
  }
};
export default withUserTypeComponent;
