import React, { useEffect } from "react";
import { Row, Button, Divider, Badge, Card, Typography } from "antd";
import SelectProjects from "components/Fields/SelectProjects";
import { AntSelect } from "components/FormikCustomInputs";
import _ from "lodash";
import { Field, FieldArray, useFormikContext } from "formik";
import AddSync from "../common/AddSync";
import RemoveSync from "../common/RemoveSync";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_TRELLO_BOARD } from "graphql/queries/Trello/getTrelloBoards";
import { GET_TRELLO_LIST } from "graphql/queries/Trello/getTrelloLists";
import utility from "common/utility";

const Settings = ({ workspaceId }) => {
  const { values, setFieldValue } = useFormikContext();
  const [trelloBoards, setTrelloBoards] = React.useState([]);
  const [columns, setColumns] = React.useState({});

  const { data, loading } = useQuery(GET_TRELLO_BOARD, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: workspaceId } },
    onCompleted: ({ getTrelloBoards }) => {
      const options = getTrelloBoards.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }));
      setTrelloBoards(options);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [getTrelloList] = useLazyQuery(GET_TRELLO_LIST, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    _.map(values?.settings?.sync, (e, index) => {
      getTrelloList({
        variables: {
          input: { workspace: workspaceId, trello_board_id: e?.trello_board },
        },
      }).then((data) => {
        const options = data?.data?.getTrelloLists.map((item) => ({
          key: item.id,
          value: item.id,
          label: item.name,
        }));
        setColumns((prevStae) => ({ ...prevStae, [index]: options }));
      });
    });
  }, []);

  const handleChange = (v, index) => {
    setFieldValue(`settings.sync[${index}]`, {
      ...values?.settings?.sync[index],
      trello_board: v,
    });

    getTrelloList({
      variables: { input: { workspace: workspaceId, trello_board_id: v } },
    }).then((data) => {
      const options = data?.data?.getTrelloLists.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }));
      setColumns((prevStae) => ({ ...prevStae, [index]: options }));
    });
  };

  return (
    <div>
      <FieldArray
        className="m-0"
        name="settings.sync"
        render={(arrayHelpers) => {
          const sync = values?.settings?.sync;
          const isGreaterThanZero = sync?.length > 0;
          return (
            <div>
              {sync && isGreaterThanZero ? (
                sync.map((e, index) => (
                  <div>
                    <div className="flex items-center">
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.trello_board`}
                        label="Select the board"
                        placeholder="Project A"
                        selectOptions={trelloBoards}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handleChange(v, index)}
                      />
                      <Divider
                        style={{
                          width: 15,
                          minWidth: 15,
                          borderColor: "#F5A623",
                          marginTop: 60,
                        }}
                        dashed
                      />

                      <SelectProjects
                        size={1000}
                        name={`settings.sync.${index}.windu_project`}
                        label="Select a Project"
                        placeholder="Project B"
                      />
                      <Button
                        style={{
                          fontSize: 22,
                          marginTop: "15px",
                          color: "#644ACB",
                        }}
                        icon={<RemoveSync />}
                        type="link"
                        onClick={() => arrayHelpers.remove(index)}
                      ></Button>
                    </div>
                    <Typography.Text strong>Select Column</Typography.Text>

                    {!_.isEmpty(values?.settings?.sync[index]?.trello_board) &&
                      !_.isEmpty(
                        values?.settings?.sync[index]?.windu_project
                      ) && (
                        <>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.list_pull`}
                              label="Column to Pull Activities from"
                              placeholder="Select a Column"
                              selectOptions={columns[index]}
                              inputType="select"
                              hasFeedback
                              mode="tags"
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                              onCallBackChange={(v) => {
                                const selectedColumn = _.filter(
                                  columns[index],
                                  (item) => v.includes(item.value)
                                );

                                setFieldValue(`settings.sync[${index}]`, {
                                  ...values?.settings?.sync[index],
                                  list_pull_clone: _.map(
                                    selectedColumn,
                                    (item) => ({
                                      name: item.label,
                                      id: item.value,
                                    })
                                  ),
                                  list_pull: _.map(
                                    selectedColumn,
                                    (item) => item.value
                                  ),
                                });
                              }}
                            />
                          </Row>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.list_push_start.id`}
                              label="Column to Push Started Activities"
                              placeholder="Select a Column"
                              selectOptions={columns[index]}
                              inputType="select"
                              hasFeedback
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                              onCallBackChange={(v) => {
                                const selectedColumn = _.filter(
                                  columns[index],
                                  (item) => item.value === v
                                );
                                setFieldValue(`settings.sync[${index}]`, {
                                  ...values?.settings?.sync[index],
                                  list_push_start: {
                                    name: selectedColumn[0].label,
                                    id: selectedColumn[0].value,
                                  },
                                });
                              }}
                            />
                          </Row>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.list_push_finished.id`}
                              label="Column to Push Finished Activities"
                              placeholder="Select a Column"
                              selectOptions={columns[index]}
                              inputType="select"
                              hasFeedback
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                              onCallBackChange={(v) => {
                                const selectedColumn = _.filter(
                                  columns[index],
                                  (item) => item.value === v
                                );
                                setFieldValue(`settings.sync[${index}]`, {
                                  ...values?.settings?.sync[index],
                                  list_push_finished: {
                                    name: selectedColumn[0].label,
                                    id: selectedColumn[0].value,
                                  },
                                });
                              }}
                            />
                          </Row>
                        </>
                      )}
                  </div>
                ))
              ) : (
                <div>
                  <div className="flex items-center">
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].trello_board`}
                      label="Select the board"
                      placeholder="Project A"
                      selectOptions={trelloBoards}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handleChange(v, 0)}
                    />
                    <Divider
                      style={{
                        width: 15,
                        minWidth: 15,
                        borderColor: "#F5A623",
                        marginTop: 60,
                      }}
                      dashed
                    />

                    <SelectProjects
                      size={1000}
                      name={`settings.sync[0]?.windu_project`}
                      label="Select a Project"
                      placeholder="Project B"
                    />
                  </div>
                  <Typography.Text strong>Select Column</Typography.Text>
                  {!_.isEmpty(values?.settings?.sync[0]?.trello_board) &&
                    !_.isEmpty(values?.settings?.sync[0]?.windu_project) && (
                      <>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`settings.sync[0].list_pull.id`}
                            label="Column to Pull Activities from"
                            placeholder="Select a Column"
                            selectOptions={columns[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            mode="multiple"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                            onCallBackChange={(v) => {
                              const selectedColumn = _.filter(
                                columns[0],
                                (item) => item.value === v
                              );
                              setFieldValue(`settings.sync[0]`, {
                                ...values?.settings?.sync[0],
                                list_pull: {
                                  name: selectedColumn[0].label,
                                  id: selectedColumn[0].value,
                                },
                              });
                            }}
                          />
                        </Row>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`settings.sync[0].list_push_start.id`}
                            label="Column to Push Started Activities"
                            placeholder="Select a Column"
                            selectOptions={columns[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                            onCallBackChange={(v) => {
                              const selectedColumn = _.filter(
                                columns[0],
                                (item) => item.value === v
                              );
                              setFieldValue(`settings.sync[0]`, {
                                ...values?.settings?.sync[0],
                                list_push_start: {
                                  name: selectedColumn[0].label,
                                  id: selectedColumn[0].value,
                                },
                              });
                            }}
                          />
                        </Row>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`settings.sync[0].list_push_finished.id`}
                            label="Column to Push Finished Activities"
                            placeholder="Select a Column"
                            selectOptions={columns[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                            onCallBackChange={(v) => {
                              const selectedColumn = _.filter(
                                columns[0],
                                (item) => item.value === v
                              );
                              setFieldValue(`settings.sync[${0}]`, {
                                ...values?.settings?.sync[0],
                                list_push_finished: {
                                  name: selectedColumn[0].label,
                                  id: selectedColumn[0].value,
                                },
                              });
                            }}
                          />
                        </Row>
                      </>
                    )}
                </div>
              )}
              <Button
                className="mt-3 flex items-center"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    trello_board: "",
                    windu_project: "",
                    list_pull: [],
                    list_push_start: {
                      name: "",
                      id: "",
                    },
                    list_push_finished: {
                      name: "",
                      id: "",
                    },
                  })
                }
              >
                Add New Project
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Settings;
