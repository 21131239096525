import gql from "graphql-tag";

export const REMOVE_BACKLOG_ACTIVITY = gql`
  mutation removeBacklogActivity($input: removeBacklogActivityInput) {
    removeBacklogActivity(input: $input) {
      _id
      title
      description
      planned_date
      assigned {
        _id
      }
    }
  }
`;
