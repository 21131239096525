import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useQuery } from "@apollo/client";
import { GET_FIGMA_WINDU_USER } from "graphql/queries/Figma/getFigmaWinduUser";
import { isNil } from "lodash";
import { Row } from "antd";
import { useRecoilState } from "recoil";
import { LoadingOutlined } from "@ant-design/icons";
import FigmaTimer from "components/FigmaTimer";
import { userSession } from "recoil/atoms/User/UserSession";
import Cookies from "js-cookie";
import { TimerProvider } from "contexts/timer-context";
import { UI_URL } from "common/constants";

const Figma = () => {
  const [user, setUser] = useRecoilState(userSession);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { id } = queryString.parse(window.location.search);
  const { startPolling, stopPolling } = useQuery(GET_FIGMA_WINDU_USER, {
    variables: { id },
    pollInterval: 2000,
    onCompleted: ({ getUserByFigmaPlugin }) => {
      if (!isNil(getUserByFigmaPlugin)) {
        window.parent.postMessage(
          {
            pluginMessage: { save: true, value: getUserByFigmaPlugin.user._id },
            pluginId: "*",
          },
          "*"
        );
        setUser({
          ...getUserByFigmaPlugin?.user,
          token: getUserByFigmaPlugin.token,
        });
        Cookies.set("token", getUserByFigmaPlugin.token, {
          secure: true,
          sameSite: "none",
        });
        stopPolling();
        setLoading(false);
      }
    },
    onError: () => {
      setError(true);
      window.parent.postMessage(
        {
          pluginMessage: { error: true },
          pluginId: "*",
        },
        "*"
      );
    },
  });

  // poll here? and find user info and return into postmessage
  useEffect(() => {
    startPolling(2000);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (error) {
      window.open(`${UI_URL()}/authorize?state=${id}&app=figma`, "_blank");
    }
  }, [error]);

  if (loading)
    return (
      <Row style={{ justifyContent: "center" }}>
        <LoadingOutlined style={{ fontSize: 30 }} size={25} spin={true} />
      </Row>
    );
  return (
    <TimerProvider initialTime={"00:00:00"} throttling={1000}>
      <FigmaTimer />
    </TimerProvider>
  );
};

export default Figma;
