import React from "react";
import { Modal, Typography, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCreateBillingAccount } from "hooks/stripe/account";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import Loader from "components/Loader";

const { Text } = Typography;

const AccountSetupWarningModal = ({
  isVisible,
  onCancel,
  data,
  loading,
  somethingIsDue,
}) => {
  const { createAccount } = useCreateBillingAccount();
  const workspace = useRecoilValue(workspaceSelected);

  const handlePaymentDetails = () => {
    createAccount({ workspace: workspace });
  };

  const getContent = () => {
    if (somethingIsDue)
      return (
        <div>
          <Text type="secondary">
            Looks like you have some information that still needs to be
            collected.
          </Text>
          <br />
          <Text className="mt-3" style={{ fontSize: 12 }}>
            Please go back to onboarding
          </Text>
        </div>
      );

    return (
      <div>
        <Text type="secondary">
          You don’t have payment details set up with this Workspace. Before
          sending an invoice to your client, please verify account details.
        </Text>
        <br />
        <Text className="mt-3" style={{ fontSize: 12 }}>
          You’ll be able to select from your saved bank account information or
          set up a new account.
        </Text>
      </div>
    );
  };
  return (
    <Modal
      cancelText={null}
      title="Hold up!"
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="payment_details"
          type="primary"
          onClick={handlePaymentDetails}
        >
          Onboard an Account
        </Button>,
      ]}
    >
      {loading ? (
        <Loader size={"small"} />
      ) : (
        <div className="flex ">
          <ExclamationCircleOutlined
            className="pr-3 mt-3"
            style={{ fontSize: 25, color: "#F24E1E" }}
          />
          {getContent()}
        </div>
      )}
    </Modal>
  );
};
export default AccountSetupWarningModal;
