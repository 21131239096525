import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import InfoHover from "components/InfoHover";
import { INDIVIDUAL_PLAN, TEAM_PLAN } from "constants/plans";

export const planInfo = [
  { type: INDIVIDUAL_PLAN, description: "All basic Windu features" },
  { type: INDIVIDUAL_PLAN, description: "Activity planning" },
  { type: INDIVIDUAL_PLAN, description: "30 days activity history" },
  { type: INDIVIDUAL_PLAN, description: "Progress Widgets on dashboard" },
  { type: INDIVIDUAL_PLAN, description: "Notifications & nudges" },
  { type: INDIVIDUAL_PLAN, description: "Chrome extension" },
  {
    type: INDIVIDUAL_PLAN,
    description: "Workspaces, dedicated to your project and client details",
  },
  {
    type: INDIVIDUAL_PLAN,
    description: "Communication with & invoicing for your client",
  },
  {
    type: TEAM_PLAN,
    description:
      "Team management and team member details, as well as project-specific chats",
  },
  {
    type: TEAM_PLAN,
    description:
      "Integrations: Connect to and customize  tools you use for team collaboration, engineering, project management, and design",
  },
  { type: TEAM_PLAN, description: "Messaging within Windu app" },
];

const Check = ({ color = "white" }) => {
  return <CheckCircleOutlined className="text-2xl mr-3" style={{ color }} />;
};

export const planInfoV2 = ({ currency = "USD" }) => {
  return {
    Individual: {
      name: INDIVIDUAL_PLAN,
      // description: "Everything you need to start tracking your time.",
      currency: currency,
      details: [
        {
          icon: <Check />,
          text: "Dashboard",
        },
        {
          icon: <Check />,
          text: "Reminders",
        },

        {
          icon: <Check />,

          text: "Unlimited Activities",
        },
        { icon: <Check />, text: "Unlimited Projects" },

        {
          icon: <Check />,
          text: "Time Management",
        },
        {
          icon: <Check />,
          text: "Unlimited Storage",
        },
      ],
    },
    Team: {
      name: TEAM_PLAN,
      // description:
      //  "Make tracking your time easier with deeply integrated widgets and get paid for your time!",
      currency: currency,
      details: [
        { icon: <Check color="black" />, text: "All of Individual + " },
        {
          icon: <Check color="black" />,
          text: "Active Timer",
        },
        {
          icon: <Check color="black" />,
          text: "Team Management",
        },

        {
          icon: <Check color="black" />,
          text: "Reports",
          info: (
            <InfoHover
              text={
                "Gain insight from helpful reports from a range of different templates."
              }
              color="black"
            />
          ),
        },
        {
          icon: <Check color="black" />,
          text: "Project Invites",
        },
        {
          icon: <Check color="black" />,
          text: "Dedicated Support",
        },
      ],
    },
    Business: {
      name: "Business",
      //    description: "The ultimate plan for having teams and multiple clients",
      currency: currency,
      details: [
        { icon: <Check />, text: "All of Pro +" },
        {
          icon: <Check />,
          text: "Client Portal",
          info: (
            <InfoHover
              text={"A happy space for your client and your projects."}
            />
          ),
        },
        {
          icon: <Check />,
          text: "Windu Labs",
          info: (
            <InfoHover text="A custom service brought to you by Windu, where we provide custom integrations for your workspace" />
          ),
        },
      ],
    },
  };
};
