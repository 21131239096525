import gql from "graphql-tag";

export const GET_PLAN_CALENDAR_ACTIVITY = gql`
  query {
    getPlanCalendarActivity {
      _id
      planned_date
      title
      details
      status
      activity_type
      tags
      key
      content
      timebox
      fileSources
      playbookReference {
        title
        key
        playbook {
          _id
          title
          activities {
            title
            isCompleted
            tasks {
              description
              completed
              assigned {
                full_name
                email
                _id
                avatar
              }
            }
          }
        }
      }

      planned {
        _id
        start
        end
        timebox
      }
      workspace {
        _id
        project {
          _id
          title
        }
      }
      priority
      tasks {
        _id
        description
        planned
        completed
        assigned {
          _id
          full_name
          email
          avatar
        }
      }
      backlog {
        _id
        group
        workspace {
          _id
        }
      }
      project {
        _id
        title
        color
        members {
          _id
          full_name
          email
          avatar
        }
        created_by {
          _id
          full_name
          email
          avatar
        }
        manager_id {
          _id
          full_name
          email
          avatar
        }
      }
      time {
        start
        paused {
          created_at
          time
          continue
          total
        }
      }
    }
  }
`;
