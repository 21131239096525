import gql from "graphql-tag";

export const REGISTER = gql`
  mutation($input: registerInput) {
    register(input: $input) {
      _id
      email
      full_name
    }
  }
`;
