import { Progress } from "antd";
import React from "react";
import _ from "lodash";
import styles from "./styles.module.less";
import { useTimer } from "contexts/timer-context";
import utility from "common/utility";

const convertToMins = (time) => {
  const hours = Number(time?.split(":")[0]);
  const mins = Number(time?.split(":")[1]);
  const secs = Number(time?.split(":")[2]);
  const totalMins = hours * 60 + mins + 0.0166667 * secs;
  return totalMins;
};

const TimeboxProgressBar = ({ activity }) => {
  const { time } = useTimer();
  let totalTime = "00:00:00";
  if (!_.isEmpty(activity.time.paused)) {
    const pausedActivities = activity.time.paused;
    const lastWithTimebox = _.last(
      _.filter(pausedActivities, (item) => !_.isEmpty(item?.timebox))
    );

    const filteredPuasedList = pausedActivities.slice(
      _.indexOf(pausedActivities, lastWithTimebox) + 1
    );

    _.forEach(filteredPuasedList, (item) => {
      totalTime = utility.calculateTimeTotal([totalTime, item.total]);
    });

    totalTime = utility.calculateTimeTotal([totalTime, time]);
  } else {
    totalTime = utility.calculateTimeTotal([totalTime, time]);
  }
  const timeBoxTime = convertToMins(activity.timebox);
  const totalTimeInMins = convertToMins(totalTime);
  const progressPercentage = (totalTimeInMins / timeBoxTime) * 100;
  return (
    <div className={styles.progressBarClass}>
      <Progress
        percent={_.isNaN(progressPercentage) ? 0 : progressPercentage}
        showInfo={false}
        strokeColor={
          progressPercentage > 100 ? "red" : { from: "#008F50", to: "#008F50" }
        }
        trailColor="#8FD89E"
        size="default"
        strokeLinecap="butt"
        status="active"
      />
    </div>
  );
};

export default TimeboxProgressBar;
