import {
  Button,
  Card,
  Divider,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import cx from "classnames";
import _ from "lodash";
import Upcoming from "./Upcoming";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import { CREATE_BACKLOG } from "graphql/mutations/Backlog/createBacklog";
import { BACKLOG_CHANGE } from "graphql/subscription/backlog/backlogChange";
import CreateGroupModal from "components/Modals/CreateGroup";
import queryString from "query-string";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import Loader from "components/Loader";
import utility from "common/utility";
import RallyBar from "./Rally/RallyBar";
import { userSession } from "recoil/atoms/User/UserSession";
import NewBacklogActivityModal from "components/Modals/NewBacklogActivity";
import {
  FunnelSimple,
  ListBullets,
  SquaresFour,
  X,
} from "@phosphor-icons/react";
import KanbanView from "./KanbanView";
import Cookies from "js-cookie";
import ListView from "./ListView";
import { SearchOutlined } from "@ant-design/icons";

const { Text } = Typography;

const filterDefaultState = {
  assignee: [],
  priority: undefined,
  tags: [],
  keyword: "",
  isFiltering: false,
};

const Backlog = ({ showRally }) => {
  const [data, setData] = React.useState(null);
  const [upcomingData, setUpcomingData] = useState([]);
  const [mainData, setMainData] = React.useState();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [isElementDraging, setIsElementDraging] = React.useState(false);
  const [createActivityModal, setcreateActivityModal] = useState(false);
  const [createGroupModal, setcreateGroupModal] = useState(false);
  const [selectedMenu, setselectedMenu] = React.useState("list");
  const [editingItem, setEditingItem] = React.useState(null);
  const workspace = useRecoilValue(workspaceSelected);
  const [outDatedCount, setOutDatedCount] = useState(0);
  const user = useRecoilValue(userSession);
  const [backlogGroups, setbacklogGroups] = useState([]);
  const [isUpcoming, setIsUpcoming] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filterInitialData, setFilterInitialData] = useState({
    members: [],
    tags: [],
  });
  const [advanceFilters, setAdvanceFilters] = useState(filterDefaultState);

  const { data: backlogData, error: backlogError } =
    useSubscription(BACKLOG_CHANGE);

  const [getBacklog, { loading: loadingBacklog, data: backlogInfo, refetch }] =
    useLazyQuery(GET_BACKLOG, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: false,
      onError: () => {
        setData(null);
        utility.setNotification(
          "Error",
          `Backlog Information`,
          "error",
          "topRight"
        );
      },
    });

  const [createBacklog, { loading: creating }] = useMutation(CREATE_BACKLOG, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error create backlog`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Backlog has been init`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: id },
      },
    ],
  });

  React.useEffect(() => {
    if (backlogInfo) {
      const { getBacklog } = backlogInfo;
      const result = utility.backlogDataFormatter({
        backlogData: getBacklog,
        timezone: user?.timezone,
      });

      const { treeData, outDatedActivites, withDates, gatherGroups } = result;

      setbacklogGroups(gatherGroups);
      setOutDatedCount(outDatedActivites);
      setUpcomingData(withDates);
      setData(treeData);
      setMainData(treeData);
    }
  }, [backlogInfo, selectedMenu]);

  React.useEffect(() => {
    if (workspace) {
      if (selectedMenu !== "upcoming") {
        setData(null);
      }
      setAdvanceFilters(filterDefaultState);

      const getBacklogView = Cookies.get(`workspace-${id}`);
      const selectedView = JSON.parse(getBacklogView)?.view
        ? JSON.parse(getBacklogView)?.view
        : "list";

      if (isUpcoming) {
        setselectedMenu("upcoming");
      } else {
        setselectedMenu(selectedView);
      }

      getBacklog({
        variables: { workspaceId: workspace._id, view: selectedView },
      });
      const members = [
        ...workspace.project.members,
        workspace.project.created_by,
        {
          _id: "unassigned",
          email: "unassigned",
          name: "unassigned",
        },
      ];

      if (workspace.project.manager) {
        members.push(workspace.project.manager);
      }

      setFilterInitialData({
        members: _.uniqBy(members, "_id"),
        tags: workspace?.project?.tags,
      });
    }
  }, [workspace, selectedMenu]);

  const handlesFiltering = (mainData) => {
    const isUnassigned = _.includes(advanceFilters.assignee, "unassigned");
    // getting all the backlog activities
    let filteredData = [];
    if (!_.isEmpty(advanceFilters.assignee)) {
      if (isUnassigned) {
        filteredData = _.filter(mainData?.items, (item) => {
          return (
            (_.includes(advanceFilters.assignee, item?.data?.assigned?._id) ||
              item?.data?.assigned === null) &&
            item.data.type === "backlog_activity"
          );
        });
      } else {
        filteredData = _.filter(mainData?.items, (item) => {
          return _.includes(advanceFilters.assignee, item?.data?.assigned?._id);
        });
      }
    } else {
      filteredData = _.filter(mainData?.items, (item) => {
        return item.data.type === "backlog_activity";
      });
    }

    if (_.includes([0, 1, 2, 3], advanceFilters.priority)) {
      if (advanceFilters.priority === 0) {
        // If advanceFilters priority is 0, check for both 0 and null in the item
        filteredData = _.filter(filteredData, (item) => {
          return item?.data?.priority === 0 || item?.data?.priority === null;
        });
      } else {
        // If advanceFilters priority is not 0, directly compare with item priority
        filteredData = _.filter(filteredData, (item) => {
          return advanceFilters.priority === item?.data?.priority;
        });
      }
    }
    if (!_.isEmpty(advanceFilters.tags)) {
      filteredData = _.filter(filteredData, (item) => {
        return !_.isEmpty(
          _.intersection(advanceFilters.tags, item?.data?.tags)
        );
      });
    }
    if (!_.isEmpty(advanceFilters.keyword)) {
      filteredData = _.filter(filteredData, (item) => {
        return item?.data?.title
          ?.toLowerCase()
          .includes(advanceFilters.keyword.toLowerCase());
      });
    }
    // backlog items activities
    const itemIds = _.map(filteredData, (item) => item.id);

    const getTheGroup = _.filter(mainData?.items, (item) => {
      return item.data.type === "backlog_group";
    });

    const getTheSubGroup = _.filter(mainData?.items, (item) => {
      return item.data.type === "backlog_sub_group";
    });

    const filterGroup = _.filter(getTheGroup, (items) => {
      const grpChildren = _.filter(items.children, (item) => {
        // check if the children is an activity or subgroup
        const itemData = mainData?.items[item]?.data;
        return (
          _.includes(itemIds, item) || itemData?.type === "backlog_sub_group"
        );
      });
      return !_.isEmpty(grpChildren);
    });

    const filterSubGroup = _.filter(getTheSubGroup, (items) => {
      const grpChildren = _.filter(items.children, (item) => {
        return _.includes(itemIds, item);
      });
      return !_.isEmpty(grpChildren);
    });

    // removing extra i.d in children
    const parsedGroupData = _.map(filterGroup.reverse(), (items) => {
      const grpChildren = _.filter(items.children, (item) => {
        const itemData = mainData?.items[item]?.data;
        if (
          itemData?.type === "backlog_activity" &&
          _.includes(itemIds, item)
        ) {
          return true;
        } else if (itemData?.type === "backlog_sub_group") {
          const grpSubChildren = _.filter(
            mainData?.items[item]?.children,
            (item) => {
              return _.includes(itemIds, item);
            }
          );
          return !_.isEmpty(grpSubChildren);
        }
      });

      return {
        ...items,
        children: grpChildren,
      };
    });

    const parsedSubGroupData = _.map(filterSubGroup, (items) => {
      const grpChildren = _.filter(items.children, (item) => {
        return _.includes(itemIds, item);
      });

      return {
        ...items,
        children: grpChildren,
      };
    });

    const getRootGroup = _.filter(mainData?.items, (item) => {
      return item.root;
    });

    const fullData = [
      ...filteredData,
      ...parsedGroupData,
      ...parsedSubGroupData,
    ];

    const newRootChildren = _.filter(fullData, (item) => {
      return _.includes(getRootGroup[0]?.children, item.id);
    });

    const newRootData = {
      ...getRootGroup[0],
      children: _.map(newRootChildren, (item) => item.id),
    };
    const newData = [newRootData, ...fullData];
    //  replacing index number to id
    var resData = newData.reduce((a, x) => {
      a[x?.id] = x;
      return a;
    }, {});

    const payload = {
      ...data,
      items: resData,
      total: _.size(filteredData),
    };

    return payload;
  };

  React.useEffect(() => {
    if (advanceFilters.isFiltering) {
      const isEqualWithoutIsFiltering = (obj1, obj2) => {
        return _.isEqual(
          _.omit(obj1, ["isFiltering"]),
          _.omit(obj2, ["isFiltering"])
        );
      };
      if (isEqualWithoutIsFiltering(advanceFilters, filterDefaultState)) {
        setAdvanceFilters(filterDefaultState);
      } else {
        const filteredData = handlesFiltering(mainData);
        setData(filteredData);
      }
    } else {
      setData(mainData);
    }
  }, [advanceFilters, mainData]);

  React.useEffect(() => {
    if (backlogError) console.warn(backlogError);
    if (backlogData) refreshBacklog();
  }, [backlogData, backlogError]);

  const handleSetSelectedView = (view) => {
    setAdvanceFilters(filterDefaultState);
    if (view === "upcoming") {
      setselectedMenu(view);
      setIsUpcoming(true);
    } else {
      const savedView = Cookies.get(`workspace-${id}`);
      Cookies.set(
        `workspace-${id}`,
        JSON.stringify({
          view: view,
          tab: savedView ? JSON.parse(savedView)?.tab : null,
        }),
        {
          expires: 365,
        }
      );
      setselectedMenu(view);
    }
  };

  const createDropdown = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => setcreateActivityModal(true)} key="Activity">
          Activity
        </Menu.Item>
        <Menu.Item onClick={() => setcreateGroupModal(true)} key="Group">
          Group
        </Menu.Item>
      </Menu>
    );
  };

  const refreshBacklog = () => {
    const { backlogChange } = backlogData;
    if (id === backlogChange.workspaceId) {
      // current backlog - refetch
      refetch();
    }
  };

  const filterMenu = () => {
    return (
      <Card
        size="small"
        title="Advanced filter"
        style={{ width: 400 }}
        extra={
          <Button
            type="text"
            disabled={!advanceFilters.isFiltering}
            onClick={() => setAdvanceFilters(filterDefaultState)}
          >
            Clear all
          </Button>
        }
      >
        <div className="flex items-center flex-col w-full pb-3">
          <div className="flex items-start flex-col w-full">
            <Text style={{ color: "#7A7B7C" }}>Search this project</Text>
            <Input
              placeholder="Enter keyword"
              className="mt-1"
              prefix={<SearchOutlined />}
              value={advanceFilters.keyword}
              onChange={(e) => {
                setAdvanceFilters({
                  ...advanceFilters,
                  keyword: e.target.value,
                  isFiltering: true,
                });
              }}
            />
          </div>
          <Divider className="my-3" />
          <div className="flex items-start flex-col w-full">
            <Text style={{ color: "#7A7B7C" }}>Assigned to</Text>
            <Select
              maxTagCount={"responsive"}
              placeholder="Filter by Assignee"
              className="w-full mt-1"
              prefix={<SearchOutlined />}
              value={advanceFilters.assignee}
              mode="multiple"
              options={_.map(filterInitialData.members, (member) => {
                return {
                  label: member.email,
                  value: member._id,
                  full_name: member.full_name,
                };
              })}
              showSearch
              onChange={(v) =>
                setAdvanceFilters({
                  ...advanceFilters,
                  assignee: v,
                  isFiltering: true,
                })
              }
              dropdownRender={(menu) => <div>{menu}</div>}
              filterOption={(input, option) =>
                (_.lowerCase(option?.label) ?? "").includes(_.lowerCase(input))
              }
            />
          </div>
          <div className="flex items-start flex-col w-full mt-2">
            <Text style={{ color: "#7A7B7C" }}>Tags</Text>
            <Select
              maxTagCount={"responsive"}
              placeholder="Filter by tags"
              className="w-full mt-1"
              value={advanceFilters.tags}
              mode="multiple"
              options={_.map(filterInitialData.tags, (tag) => {
                return {
                  label: tag,
                  value: tag,
                  full_name: tag,
                };
              })}
              showSearch
              onChange={(v) =>
                setAdvanceFilters({
                  ...advanceFilters,
                  tags: v,
                  isFiltering: true,
                })
              }
              dropdownRender={(menu) => <div>{menu}</div>}
              filterOption={(input, option) =>
                (_.lowerCase(option?.label) ?? "").includes(_.lowerCase(input))
              }
            />
          </div>
          <div className="flex items-start flex-col w-full mt-2">
            <Text style={{ color: "#7A7B7C" }}>Priority</Text>
            <Select
              placeholder="Filter by priority"
              className="w-full mt-1"
              allowClear
              value={advanceFilters.priority}
              options={[
                {
                  label: "High",
                  value: 3,
                },
                {
                  label: "Medium",
                  value: 2,
                },
                {
                  label: "Low",
                  value: 1,
                },
                {
                  label: "Not set",
                  value: 0,
                },
              ]}
              onChange={(v) =>
                setAdvanceFilters({
                  ...advanceFilters,
                  priority: v,
                  isFiltering: true,
                })
              }
              dropdownRender={(menu) => <div>{menu}</div>}
            />
          </div>
        </div>
      </Card>
    );
  };

  if (!backlogInfo?.getBacklog && !loadingBacklog)
    return (
      <div className="flex justify-center items-center h-full">
        <Button
          size="large"
          type="primary"
          className="py-2 px-10"
          onClick={() => createBacklog({ variables: { workspaceId: id } })}
        >
          Init Backlog
        </Button>
      </div>
    );

  const getFilterNumbers = () => {
    let numbers = 0;

    if (!_.isEmpty(advanceFilters.assignee)) numbers += 1;
    if (!_.isEmpty(advanceFilters.tags)) numbers += 1;
    if (_.includes([0, 1, 2, 3], advanceFilters.priority)) numbers += 1;
    if (!_.isEmpty(advanceFilters.keyword)) numbers += 1;

    return numbers;
  };

  return (
    <div>
      {showRally && <RallyBar />}
      <Row className="pt-3 mb-2 pl-4 flex justify-between px-2">
        <Dropdown overlay={createDropdown} placement="bottomRight">
          <Button size="large" type="primary" className="py-2 px-10">
            Create
          </Button>
        </Dropdown>
        {!isUpcoming && (
          <div className="flex items-center">
            <div>
              <Dropdown overlay={() => filterMenu()} trigger={["click"]}>
                <div
                  className="flex items-center gap-3 rounded-lg px-4 py-2 mr-4 cursor-pointer"
                  style={{ background: "#F9FAFB" }}
                >
                  <FunnelSimple weight="bold" size={24} color="#667085" />
                  <Text style={{ color: "#344054" }}>
                    Filter : {getFilterNumbers()}{" "}
                  </Text>
                  {getFilterNumbers() > 0 && (
                    <X
                      size={18}
                      weight="bold"
                      color="#667085"
                      className="cursor-pointer"
                      onClick={() => setAdvanceFilters(filterDefaultState)}
                    />
                  )}
                </div>
              </Dropdown>
            </div>

            <div className="flex items-center mr-2">
              <div
                style={{
                  background: selectedMenu === "list" && "#F6F8F9",
                  border: "2px solid #DDE2E4",
                  borderRadius: "6px 0px 0px 6px",
                }}
                className="p-0.5 cursor-pointer"
              >
                <Tooltip title="Kanban View">
                  <SquaresFour
                    size={28}
                    weight="fill"
                    onClick={() => handleSetSelectedView("kanban")}
                    style={{
                      fill: selectedMenu === "kanban" ? "#F5A925" : "#B0BABF",
                    }}
                  />
                </Tooltip>
              </div>

              <div
                style={{
                  background: selectedMenu === "kanban" && "#F6F8F9",
                  border: "2px solid #DDE2E4",
                  borderRadius: "0px 6px 6px 0px",
                }}
                className="p-0.5 cursor-pointer"
              >
                <Tooltip title="List View">
                  <ListBullets
                    size={28}
                    weight="bold"
                    onClick={() => handleSetSelectedView("list")}
                    style={{
                      fill: selectedMenu === "list" ? "#F5A925" : "#B0BABF",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </Row>
      <>
        <div className="flex w-full justify-between">
          <div className="pl-2 h-full w-40">
            <Button
              onClick={() => {
                const getBacklogView = Cookies.get(`workspace-${id}`);
                const selectedView = JSON.parse(getBacklogView)?.view
                  ? JSON.parse(getBacklogView)?.view
                  : "list";
                handleSetSelectedView(selectedView);
                setIsUpcoming(false);
              }}
              className={cx({
                "font-normal text-base": true,
                " font-semibold":
                  selectedMenu === "list" || selectedMenu === "kanban",
              })}
              type="text"
            >
              Inbox
              {backlogInfo && (
                <Tag className="ml-2">
                  {backlogInfo?.getBacklog?.total || 0}
                </Tag>
              )}
            </Button>
            {/* <Button
              onClick={() => handleSetSelectedView("upcoming")}
              className={cx({
                "font-normal text-base mt-3": true,
                " font-semibold": selectedMenu === "upcoming",
              })}
              type="text"
            >
              Upcoming
              <Tag className="ml-2" color={!_.isEmpty(outDatedCount) && "red"}>
                {_.isEmpty(outDatedCount)
                  ? _.size(upcomingData)
                  : _.size(outDatedCount)}
              </Tag>
            </Button> */}
          </div>
          <div className="flex-1 overflow-y-auto">
            {loadingBacklog || creating ? (
              <Loader />
            ) : (
              <>
                {selectedMenu === "list" && !_.isEmpty(data) && (
                  <ListView
                    data={data}
                    setData={setData}
                    setIsElementDraging={setIsElementDraging}
                    setcreateGroupModal={setcreateGroupModal}
                    setcreateActivityModal={setcreateActivityModal}
                    setEditingItem={setEditingItem}
                    isElementDraging={isElementDraging}
                    isFiltering={advanceFilters.isFiltering}
                    totalCount={_.size(backlogInfo?.getBacklog?.items) || 0}
                  />
                )}
                {selectedMenu === "kanban" && (
                  <KanbanView
                    kanbanData={backlogInfo}
                    refetch={refetch}
                    createActivityModal={setcreateActivityModal}
                    setSelectedGroup={setSelectedGroup}
                    setcreateGroupModal={setcreateGroupModal}
                    setEditingItem={setEditingItem}
                    filters={advanceFilters}
                  />
                )}
                {selectedMenu === "upcoming" && (
                  <Upcoming
                    data={upcomingData}
                    inboxData={data}
                    backlogId={data?.rootId}
                    setIsElementDraging={setIsElementDraging}
                    isElementDraging={isElementDraging}
                    setcreateActivityModal={setcreateActivityModal}
                    setEditingItem={setEditingItem}
                    setOutDatedCount={setOutDatedCount}
                    refetch={refetch}
                    totalCount={backlogInfo?.getBacklog?.total || 0}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </>

      {createActivityModal && (
        <NewBacklogActivityModal
          isVisible={createActivityModal}
          setEditingItem={setEditingItem}
          editingItem={editingItem}
          title={editingItem ? "Edit activity" : "Create an activity"}
          backlogId={data?.rootId} // backlog id
          onClose={() => {
            setEditingItem(null);
            setcreateActivityModal(false);
          }}
          backlogGroups={backlogGroups}
          backlogRefetch={refetch}
          selectedGroup={selectedGroup}
        />
      )}

      {createGroupModal && (
        <CreateGroupModal
          isVisible={createGroupModal}
          editingItem={editingItem}
          title={editingItem ? "Edit Group" : "Create a Group"}
          backlogId={data?.rootId} // backlog id
          onClose={() => {
            setEditingItem(null);
            setcreateGroupModal(false);
          }}
          backlogRefetch={refetch}
        />
      )}
    </div>
  );
};

export default Backlog;
