import gql from "graphql-tag";

export const GET_SELECTED_ACTIVITY = gql`
  query ($activityId: ID!) {
    getSelectedActivity(activityId: $activityId) {
      _id
      title
      details
      description
      status
      isCompleted
      meta
      playbook {
        _id
        title
        description
        activities {
          _id
          title
          content
          fileSources
          isCompleted
          tags
          priority
          tasks {
            _id
            description
            planned
            completed
            assigned {
              _id
              full_name
              email
              avatar
            }
          }
          created_by {
            _id
            email
            full_name
            avatar
          }
        }
      }
      playbookReference {
        _id
        title
        key
        playbook {
          _id
          title
          activities {
            _id
            title
            isCompleted
            time {
              _id
              start
              end
              day_duration
              total_time
              paused {
                _id
                created_at
                time
                continue
                total
                timebox
              }
            }
            tasks {
              description
              completed
              assigned {
                full_name
                email
                _id
                avatar
              }
            }
          }
        }
      }
      tasks {
        _id
        description
        planned
        completed
        assigned {
          _id
          full_name
          email
          avatar
        }
      }
      commentCounts
      created_at
      initiated_by {
        _id
        full_name
        email
        avatar
      }
      initiated_at
      activity_persona {
        _id
        github {
          link
          status
          title
        }
        slackMessageURL
      }
      project {
        title
        color
        _id
        members {
          _id
          email
          avatar
          full_name
        }
        created_by {
          _id
          email
          avatar
          full_name
        }
        manager_id {
          _id
          email
          avatar
          full_name
        }
      }
      time {
        _id
        start
        end
        day_duration
        total_time
        paused {
          _id
          created_at
          time
          continue
          total
          timebox
        }
      }
      created_by {
        _id
        full_name
        email
        avatar
      }
      activity_type
      tags
      key
      content
      timebox
      fileSources
      handoff {
        _id
        user {
          _id
          full_name
          email
          avatar
        }
        timebox
        time {
          _id
          start
          end
          day_duration
          total_time
          paused {
            _id
            created_at
            time
            continue
            total
            timebox
          }
        }
        handoff_at
      }
    }
  }
`;
