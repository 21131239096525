import React from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Tooltip,
  Avatar,
  Button,
  Badge,
  Result,
  Divider,
} from "antd";
import { EnterOutlined, MailOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Editor } from "react-draft-wysiwyg";
import queryString from "query-string";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { useParams, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PROJECT } from "graphql/queries/projects/getProject";
import { REMOVE_PROJECT } from "graphql/mutations/projects/removeProject";
import { REMOVE_MANAGER } from "graphql/mutations/manager/removeManager";
import { ARCHIVE_PROJECT } from "graphql/mutations/projects/archiveProject";
import { LEAVE_PROJECT } from "graphql/mutations/projects/leaveProject";
import { UPDATE_PROJECT } from "graphql/mutations/projects/updateProject";
import { REMOVE_CLIENT } from "graphql/mutations/client/removeClient";
import { userSession } from "recoil/atoms/User/UserSession";
import { workspacesList } from "recoil/atoms/Workspaces";
import UserAvatar from "components/UserAvatar";
import utility from "common/utility";
import {
  updateProjectOverview,
  updateProjectPerformance,
} from "common/cacheUtilities";
import Loader from "components/Loader";
import ProjectMenuAction from "components/ProjectTable/ProjectMenuAction";
import AddMemberModal from "components/Modals/AddMember";
import InviteClientModal from "components/Modals/InviteClientModal";
import InviteManagerModal from "components/Modals/InviteManagerModal";
import styles from "./styles.module.css";

const { Paragraph, Text } = Typography;

const sizePerPage = 10;
const initialOffset = 0;
const initialSearch = "";

const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "colorPicker",
  ],
};

const ProjectDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const user = useRecoilValue(userSession);
  const workspaces = useRecoilValue(workspacesList);
  const [isInviteMemberVisible, setIsInviteMemberVisible] =
    React.useState(false);
  const [isAssignClientVisible, setIsAssignClientVisible] =
    React.useState(false);
  const [isInviteManagerVisible, setIsInviteManagerVisible] =
    React.useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [showNotesBtn, setShowNotesBtn] = React.useState(false);
  const { loading, data, error, refetch } = useQuery(GET_PROJECT, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    onCompleted: ({ getProject }) => {
      if (getProject?.notes) {
        const editorValue = EditorState.createWithContent(
          convertFromRaw(JSON.parse(getProject?.notes))
        );

        // fill notes editor state
        setEditorState(editorValue);
      }
    },
  });

  const [updateProject, { loading: updating }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Project updated`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: { id },
      },
    ],
  });

  const [leaveProject] = useMutation(LEAVE_PROJECT, {
    onCompleted: ({ leaveProject }) => {
      utility.setNotification(
        "Success",
        `You have left ${leaveProject?.title}`,
        "success",
        "topRight"
      );

      history.goBack();
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [removeManager] = useMutation(REMOVE_MANAGER, {
    onCompleted: ({ removeManager }) => {
      const manager = removeManager?.email || "You";
      utility.setNotification(
        "Manager removed successfully",
        `${manager} has been removed, you can invite a new manager`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: { id },
      },
    ],
  });

  const [archiveProject] = useMutation(ARCHIVE_PROJECT, {
    onCompleted: ({ archiveProject }) => {
      const message =
        archiveProject.status === "Active"
          ? `${archiveProject.title} is now an active project`
          : `${archiveProject.title} has been archived`;

      utility.setNotification(
        "Project archived successfully",
        message,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: { id },
      },
    ],
  });

  const [removeProject] = useMutation(REMOVE_PROJECT, {
    onCompleted: ({ removeProject }) => {
      utility.setNotification(
        "Project removed successfully",
        `${removeProject.title} has been removed`,
        "success",
        "topRight"
      );

      history.goBack();
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    update: (cache, { data: { removeProject } }) => {
      updateProjectOverview(cache, "remove");
      updateProjectPerformance(cache, removeProject, "remove");
    },
  });

  const [removeClient] = useMutation(REMOVE_CLIENT, {
    onCompleted: ({ removeClient }) => {
      utility.setNotification(
        "Client removed successfully",
        `${removeClient.email} has been removed, you can invite a new client`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: { id },
      },
    ],
  });

  // check user login is owner or manager
  React.useEffect(() => {
    if (data?.getProject) {
      const project = data.getProject;
      const userId = String(user._id);
      const managerId = String(project.manager_id?._id);
      const ownerId = String(project.created_by?._id);
      const hasAccess = managerId === userId || ownerId === userId;

      if (!hasAccess) {
        history.goBack();
      }
    }
  }, [data]);

  // check everytime the editor is updated
  React.useEffect(() => {
    const currentNotes = JSON.stringify(
      convertToRaw(editorState?.getCurrentContent())
    );
    const originalNotes = data?.getProject?.notes;

    setShowNotesBtn(_.isEqual(currentNotes, originalNotes));
  }, [editorState]);

  if (loading) return <Loader size="large" />;

  if (error) {
    return (
      <Result
        status="error"
        title="Fetching Failed"
        subTitle={toString(error)}
      />
    );
  }

  const { getProject: project } = data;

  const isInWorkspace = _.filter(
    workspaces,
    (workspace) => workspace.project._id === project._id
  );

  const handleUpdateNotes = () => {
    const contentData = !_.isEmpty(editorState)
      ? JSON.stringify(convertToRaw(editorState?.getCurrentContent()))
      : "";

    updateProject({
      variables: {
        input: {
          projectId: id,
          notes: contentData,
        },
      },
    });
  };

  const onProjectLeave = (projectId) => {
    leaveProject({ variables: { input: { projectId } } });
  };

  const onMembersList = (projectId) => {
    history.push({
      pathname: "/people",
      search: queryString.stringify({ projectId }),
    });
  };

  const onArchive = (projectId) => {
    archiveProject({ variables: { input: { projectId } } });
  };

  const onRemove = (projectId) => {
    removeProject({ variables: { input: { projectId } } });
  };

  const onRemoveClient = ({ client, project }) => {
    removeClient({
      variables: { input: { clientId: client, projectId: project } },
    });
  };

  const onRemoveManager = ({ manager, project }) => {
    removeManager({
      variables: { input: { managerId: manager, projectId: project } },
    });
  };

  return (
    <div>
      <Row gutter={8}>
        <Col xxl={8} xl={8} lg={24} flex={1}>
          <div className="flex justify-between items-center">
            <Button
              size="large"
              className="w-32 my-2"
              icon={<EnterOutlined />}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <ProjectMenuAction
              onRemove={() => onRemove(project._id)}
              onRemoveClient={() =>
                onRemoveClient({
                  client: project.client._id,
                  project: project._id,
                })
              }
              onRemoveManager={() =>
                onRemoveManager({
                  manager: project.manager_id._id,
                  project: project._id,
                })
              }
              onArchive={() => onArchive(project._id)}
              setProjectSelected={() => null}
              onMembersList={() => onMembersList(id)}
              setIsInviteManagerVisible={setIsInviteManagerVisible}
              setIsInviteMemberVisible={setIsInviteMemberVisible}
              setIsAssignClientVisible={setIsAssignClientVisible}
              hasActivities={project.total_activities > 0}
              hasClient={!_.isEmpty(project.client)}
              hasManager={!_.isEmpty(project.manager_id)}
              isArchived={project.status === "Archived"}
              isOwner={project.created_by._id === user._id}
              hasPermissions={
                project.manager?._id === user._id ||
                project.created_by._id === user._id
              }
              isManager={project.manager_id?._id === user._id}
              title={project.title}
              onProjectLeave={() => onProjectLeave(project._id)}
              isInWorkspace={!_.isEmpty(isInWorkspace)}
            />
          </div>

          <Card className="w-full shadow-none border-none outline-none my-2">
            <div className="flex justify-between mb-3">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <Badge
                    className={`${styles.projectDot} mr-2`}
                    color={`${
                      _.isEmpty(project.color) || project.color === "none"
                        ? "#808080"
                        : project.color
                    }`}
                  />
                  <Paragraph
                    className="text-lg font-semibold"
                    style={{ marginBottom: "0px" }}
                    editable={{
                      icon: <EditOutlined className="icon-small mb-2" />,
                      autoSize: true,
                      tooltip: "click to edit title",
                      onChange: (value) => {
                        if (value && value !== project.title) {
                          updateProject({
                            variables: {
                              input: {
                                projectId: project._id,
                                title: value,
                              },
                            },
                          });
                        }
                      },
                    }}
                  >
                    {project.title}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Paragraph
                editable={{
                  icon: <EditOutlined className="icon-small mb-2" />,
                  tooltip: "click to edit description",
                  autoSize: true,
                  onChange: (value) => {
                    if (value && value !== project.description) {
                      updateProject({
                        variables: {
                          input: {
                            projectId: project._id,
                            description: value,
                          },
                        },
                      });
                    }
                  },
                }}
              >
                {project.description}
              </Paragraph>
            </div>
            <Text className="text-base">{`Total time ${project.total_time}`}</Text>
            <Divider />
            <Avatar.Group maxCount={5}>
              {_.map(project.members, (member) => (
                <Tooltip title={member.email} placement="top" key={member._id}>
                  <UserAvatar user={member} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Card>
          <Card className="w-full shadow-none border-none outline-none my-2">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="grid items-center justify-between">
                  <Text className="text-base font-semibold">Manager</Text>
                  {project.manager_id ? (
                    <div className="mt-3 flex items-center">
                      <div>
                        <UserAvatar user={project.manager_id} />
                      </div>
                      <div className="ml-3 grid">
                        <Text>{project.manager_id?.full_name}</Text>
                        <Text className={"text-xs"}>
                          {project.manager_id?.email}
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <Text className="my-3">No Manager</Text>
                  )}
                </div>
              </div>
              <div className="grid items-center">
                <Button
                  onClick={() =>
                    window.open(`mailto:${project.manager_id.email}`)
                  }
                  icon={
                    <MailOutlined className="cursor-pointer icon-medium text-white" />
                  }
                  className="focus:bg-black hover:bg-black bg-black border-none"
                />
              </div>
            </div>
          </Card>

          <Card className="w-full shadow-none border-none outline-none my-2">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="grid items-center justify-between">
                  <Text className="text-base font-semibold mb-3">Rewards</Text>
                </div>
              </div>
            </div>
            <div class="text-center flex justify-center">
              <img
                src={`https://windu.s3.us-east-2.amazonaws.com/assets/Rewards-C+(2).svg`}
              />
            </div>
          </Card>
        </Col>
        <Col xxl={16} xl={16} lg={16} className="2xl:block xl:flex">
          <Card
            className={`${styles.editorContent} shadow-none border-none outline-none h-full`}
          >
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="grid items-center justify-between">
                  <Text className="text-base font-semibold">Notes</Text>
                </div>
              </div>
              {!showNotesBtn && (
                <Button
                  type="primary"
                  onClick={handleUpdateNotes}
                  loading={updating}
                >
                  Save
                </Button>
              )}
            </div>
            <div className="my-3">
              <Editor
                editorState={editorState}
                onEditorStateChange={(editorState) =>
                  setEditorState(editorState)
                }
                wrapperClassName="h-full"
                placeholder="Write updates here"
                toolbar={toolbarOptions}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <AddMemberModal
        isVisible={isInviteMemberVisible}
        onClose={() => setIsInviteMemberVisible(false)}
        projectSelected={{ value: project._id, label: project.title }}
      />

      <InviteClientModal
        isVisible={isAssignClientVisible}
        onClose={() => setIsAssignClientVisible(false)}
        projectSelected={{ value: project._id, label: project.title }}
      />

      <InviteManagerModal
        isVisible={isInviteManagerVisible}
        onClose={() => setIsInviteManagerVisible(false)}
        projectSelected={{ value: project._id, label: project.title }}
        refetch={refetch}
      />
    </div>
  );
};

export default ProjectDetail;
