import React from "react";
import styles from "../styles.module.css";
import "./style.css";

const SideLegend = ({ activityWidth }) => {
  return (
    <div>
      <div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#2e1269" }}
            className={styles.sideLegend}
          ></div>
          {<span className="pl-2.5">More</span>}
        </div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#5c23ca" }}
            className={styles.sideLegend}
          ></div>
        </div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#967dde" }}
            className={styles.sideLegend}
          ></div>
        </div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#da7d01" }}
            className={styles.sideLegend}
          ></div>
        </div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#ffbd4f" }}
            className={styles.sideLegend}
          ></div>
        </div>
        <div className="flex mb-3">
          <div
            style={{ backgroundColor: "#f9d9a4" }}
            className={styles.sideLegend}
          ></div>
          <span className="pl-2.5">Less</span>
        </div>
      </div>
    </div>
  );
};

export default SideLegend;
