import React, { useState } from "react";
import {
  Card,
  Button,
  Dropdown,
  Typography,
  List,
  Badge,
  Tooltip,
  Popconfirm,
} from "antd";
import { useLazyQuery, useSubscription, useMutation } from "@apollo/client";
import {
  MinusOutlined,
  PlusSquareOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { GET_CHATS } from "graphql/queries/message/getChats";
import { REMOVE_CHAT } from "graphql/mutations/message/removeChat";
import { NEW_MESSAGE } from "graphql/subscription/message/newMessage";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import _ from "lodash";
import ChatList from "./ChatList";
import NewChat from "./NewChat";
import Loader from "components/Loader";

const ChatDropdown = ({
  chatDropdownVisiblity,
  setchatDropdownVisiblity,
  setchatPopUpVisiblity,
}) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [newChatVisiblity, setnewChatVisiblity] = useState(false);
  const workspace = useRecoilValue(workspaceSelected);
  const chatPopUpHandler = () => {
    setchatPopUpVisiblity(true);
    setchatDropdownVisiblity(false);
  };

  const [getChats, { loading, data, refetch }] = useLazyQuery(GET_CHATS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const { data: messageData, error: messageError } =
    useSubscription(NEW_MESSAGE);

  const [removeChat, { loading: removing }] = useMutation(REMOVE_CHAT, {
    refetchQueries: [
      {
        query: GET_CHATS,
        variables: { workspaceId: id },
      },
    ],
  });

  const handleRemoveChat = (chatId) => {
    removeChat({ variables: { chatId } });
  };

  React.useEffect(() => {
    if (chatDropdownVisiblity) {
      getChats({
        variables: {
          input: { workspaceId: id },
        },
      });
    }
  }, [chatDropdownVisiblity]);

  React.useEffect(() => {
    if (messageData) refetch();
  }, [messageData, messageError]);

  const listLoading = {
    spinning: loading || removing,
    indicator: <Loader size="large" />,
  };

  return (
    <div className="mb-2 rounded-2xl">
      <Card
        style={{ height: "500px", width: "370px" }}
        bodyStyle={{ padding: 0 }}
      >
        <div className="flex justify-between p-4 pb-2">
          <div className="flex items-center">
            <div className=" flex flex-col ml-1">
              <Typography.Text className="font-semibold text-lg">
                Chat
              </Typography.Text>
              <Typography.Text>
                {_.size(data?.getChats) > 1
                  ? `${_.size(data?.getChats)} Conversations`
                  : `${_.size(data?.getChats)} Conversation`}
              </Typography.Text>
            </div>
          </div>
          <div>
            <MinusOutlined
              className="text-xl"
              onClick={() => setchatDropdownVisiblity(false)}
            />
          </div>
        </div>
        <div className="flex justify-center my-2">
          <Dropdown
            overlay={
              <NewChat
                setnewChatVisiblity={setnewChatVisiblity}
                onDone={() => chatPopUpHandler()}
                newChatVisiblity={newChatVisiblity}
              />
            }
            trigger={["click"]}
            placement="bottomCenter"
            visible={newChatVisiblity}
            onVisibleChange={(flag) => setnewChatVisiblity(flag)}
          >
            <Button
              className="text-lg flex items-center"
              type="text"
              style={{ color: "#4E35C2" }}
            >
              <PlusSquareOutlined style={{ fontSize: "22px" }} /> Start new chat
            </Button>
          </Dropdown>
        </div>

        <div className="overflow-x-auto" style={{ height: 360 }}>
          <List
            loading={listLoading}
            dataSource={data?.getChats || []}
            renderItem={(item) => {
              return (
                <List.Item key={item._id} className="hover:bg-purple-50">
                  <ChatList chat={item} onClick={() => chatPopUpHandler()} />
                  {item.unread > 0 && (
                    <Badge count={item.unread} className="mr-4" />
                  )}
                  {_.size(item.participants) <= 1 && (
                    <Popconfirm
                      className="mr-4"
                      placement="topRight"
                      title={"Do you want to remove this chat"}
                      onConfirm={() => handleRemoveChat(item._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Remove Chat">
                        <DeleteFilled className="icon-medium" />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </List.Item>
              );
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default ChatDropdown;
