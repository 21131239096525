import React, { useEffect, useState } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { AntSelect } from "components/FormikCustomInputs";
import { listCalendars } from "api/Google";
import utility from "common/utility";
import { useQuery } from "@apollo/client";
import { map, get, includes } from "lodash";
import { Button, Divider } from "antd";
import SelectProjects from "components/Fields/SelectProjects";
import { GET_CALENDAR_PROJECTS } from "graphql/queries/Google/getCalendarProjects";
import Loader from "components/Loader";
import RemoveSync from "../common/RemoveSync";
import AddSync from "../common/AddSync";
import useCreateIntegrationHook from "common/createIntegration";

const Settings = ({ token, view, onRejected, id }) => {
  const { values, setFieldValue, submitCount } = useFormikContext();
  const [calendars, setCalendars] = useState([]);
  const [loadingcalendars, setLoadingCalendars] = useState(true);
  const { getWorkspaceIntegration } = useCreateIntegrationHook();

  const {
    getCalendarProjects,
    loading: loadingCalendarProjects,
    refetch,
  } = useQuery(GET_CALENDAR_PROJECTS, {
    variables: { input: { workspace: id } },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getCalendarProjects }) => {
      const matrixValues = map(
        getCalendarProjects?.calendar_project_subscription,
        (subscription) => {
          return {
            calendar: subscription?.calendar,
            project: subscription?.project?._id,
          };
        }
      );
      setFieldValue("subscriptions", matrixValues);
    },

    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (submitCount >= 1) {
      refetch();
    }
  }, [submitCount]);

  useEffect(() => {
    listCalendars(token)
      .then((d) => {
        setLoadingCalendars(false);
        const data = map(d?.data?.items, (calendar) => {
          return {
            value: calendar.id,
            label: calendar?.summaryOverride
              ? calendar?.summaryOverride
              : calendar.summary,
            meta: (
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: calendar.backgroundColor,
                  marginRight: 10,
                }}
              ></div>
            ),
          };
        }).filter(
          (calendar) =>
            !includes(calendar.value, "#holiday") &&
            !includes(calendar.value, "#contacts")
        );

        setCalendars(data);
      })
      .catch((e) => {
        onRejected();
      });
  }, [view]);

  if (loadingCalendarProjects || loadingcalendars)
    return <Loader size="large" />;

  return (
    <>
      <FieldArray
        className="m-0"
        name="subscriptions"
        render={(arrayHelpers) => {
          const subscriptions = values.subscriptions;
          return (
            <div>
              {subscriptions && subscriptions.length > 0 ? (
                subscriptions.map((subscription, index) => (
                  <div className="flex items-center" key={index}>
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`subscriptions.${index}.calendar`}
                      label="Select a Calendar"
                      placeholder="Select a Calendar"
                      selectOptions={calendars}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                    />

                    <Divider
                      style={{
                        width: 15,
                        minWidth: 15,
                        borderColor: "#F5A623",
                        marginTop: 60,
                      }}
                      dashed
                    />
                    <SelectProjects
                      size={1000}
                      name={`subscriptions.${index}.project`}
                      label="Select a Project"
                    />

                    <Button
                      style={{
                        fontSize: 22,
                        marginTop: "15px",
                        color: "#644ACB",
                      }}
                      icon={<RemoveSync />}
                      type="link"
                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                    ></Button>
                  </div>
                ))
              ) : (
                <div className="flex items-center">
                  <Field
                    className="m-0"
                    component={AntSelect}
                    name={`subscriptions[0].calendar`}
                    label="Select a Calendar"
                    placeholder="Select a Calendar"
                    selectOptions={calendars}
                    inputType="select"
                    hasFeedback
                    required={true}
                    size="large"
                    filterOption={false}
                    getPopupContainer={(node) => node.parentNode}
                  />

                  <Divider
                    style={{
                      width: 30,
                      minWidth: 30,
                      borderColor: "#F5A623",
                      marginTop: 60,
                    }}
                    dashed
                  />
                  <SelectProjects
                    name={`subscriptions[0].project`}
                    label="Select a Project"
                  />
                </div>
              )}
              <Button
                className="mt-3"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    calendar: "",
                    project: "",
                  })
                } // insert an empty string at a position
              >
                Add Sync
              </Button>
              <br />
              <br />
              <br />
            </div>
          );
        }}
      />
    </>
  );
};
export default Settings;
