export const createGoogleEvent = (userId, event_name, event_category) => {
  if (
    process.env.NODE_ENV !== "development" &&
    process.env.REACT_APP_BE === "be.windu.io"
  ) {
    window.gtag("event", event_name, {
      event_category: event_category,
      user_id: userId,
    });
  }
};

export const createGoogleConfig = (userId) => {
  if (
    process.env.NODE_ENV !== "development" &&
    process.env.REACT_APP_BE === "be.windu.io"
  ) {
    window.gtag("config", "G-BTT10SENGD", {
      user_id: userId,
    });
  }
};
