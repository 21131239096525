import gql from "graphql-tag";

export const GET_USERS_BY_EMAILS = gql`
  query($emails: [String]!) {
    getUsersByEmails(emails: $emails) {
      _id
      full_name
      email
      avatar
    }
  }
`;
