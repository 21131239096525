import gql from "graphql-tag";

export const NEW_MESSAGE = gql`
  subscription newMessage {
    newMessage {
      _id
      content

      to
      from {
        _id
        email
        full_name
        avatar
      }
      chatId
      workspaceId
      type
      created_at
      unread
      fileSources
    }
  }
`;
