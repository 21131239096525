import gql from "graphql-tag";

export const REMOVE_ACTIVITY = gql`
  mutation($input: activityIdInput) {
    removeActivity(input: $input) {
      _id
      title
      description
      planned_date
    }
  }
`;
