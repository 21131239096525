import React, { useEffect, useState } from "react";
import moment from "moment";
import _, { map } from "lodash";
import {
  Button,
  Form as AntForm,
  Progress,
  Spin,
  Typography,
  Popover,
  Row,
  Col,
  Card,
  Upload,
  Dropdown,
  Modal,
  Input,
  Checkbox,
  Tooltip,
  Popconfirm,
} from "antd";
import utility from "common/utility";
import * as yup from "yup";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_PROJECT_TAGS } from "graphql/queries/projects/getProjectTags";
import { GET_USER_FREE_TIME } from "graphql/queries/timebox/getUserFreeTime";
import { AntDatePicker, AntSelect } from "components/FormikCustomInputs";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { AntInput } from "components/FormikCustomInputs";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { TimeBoxField } from "components/CustomComponents/TimeboxInput";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { useRecoilValue } from "recoil";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CHANGE_DATE_PLAN_ACTIVITY } from "graphql/mutations/member/planDateChange";
import styles from "./styles.module.css";
import AssigneSelectDropdown from "../NewBacklogActivity/AssigneSelectDropdown";
import UserAvatar from "components/UserAvatar";
import CalendarPicker from "components/CalendarPicker";
import { Checks } from "@phosphor-icons/react";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";

const { Text } = Typography;

const toolbarOptions = {
  options: ["inline", "list"],
};

const EditActivity = ({ isVisible, onClose, editingItem }) => {
  const todoistTask = editingItem?.data?.todoist_project;
  const user = useRecoilValue(userSession);
  const [projectTags, setProjectTags] = React.useState([]);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [userFreetime, setUserFreetime] = useState("180");
  const [files, setFiles] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [fileSources, setFileSources] = useState([]);
  const [timebox, setTimebox] = useState("");
  const form = React.useRef();
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);
  const props = {
    name: "file",
    multiple: true,
    accept: ".txt, .doc, .docx, .jpg, .jpeg, .png, .pdf",
    customRequest: ({ onSuccess }) => {
      onSuccess("ok");
    },
    onChange({ fileList }) {
      setFiles(fileList);
    },
  };
  const [newTask, setNewTask] = useState({
    _id: _.uniqueId("newtask-"),
    description: null,
    planned: null,
    assigned: null,
    completed: false,
  });

  const [getUserFreeTime, { loading: freeTimeloading }] = useLazyQuery(
    GET_USER_FREE_TIME,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,

      onCompleted: ({ getUserFreeTime }) => {
        const userFreetime = Number(getUserFreeTime) + Number(timebox);
        setUserFreetime(userFreetime > 480 ? 480 : userFreetime);
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const [plannedActivityDateChange, { loading }] = useMutation(
    CHANGE_DATE_PLAN_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          `Activity updated successfully`,
          "success",
          "topRight"
        );
        handleOnClose();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "This activity can't be planned",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
    }
  );

  useEffect(() => {
    if (!_.isEmpty(editingItem?.data?.fileSources)) {
      setFileSources(editingItem?.data?.fileSources);
    }
    if (!_.isEmpty(editingItem?.data?.timebox)) {
      const totalMins = convertTimeboxInMins(editingItem?.data?.timebox);
      setTimebox(totalMins);
    }
    if (!_.isEmpty(editingItem?.data?.planned_date)) {
      getUserFreeTime({
        variables: {
          input: {
            userId: user._id,
            date: editingItem?.data?.planned_date,
          },
        },
      });
    }
  }, [editingItem]);

  const convertTimeboxInMins = (timebox) => {
    const hours = Number(timebox?.split(":")[0]);
    const mins = Number(timebox?.split(":")[1]);
    const totalMins = hours * 60 + mins;

    return totalMins;
  };

  useEffect(() => {
    if (!_.isEmpty(editingItem) && !_.isEmpty(editingItem.data?.details)) {
      seteditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(editingItem.data?.details))
        )
      );
    } else {
      seteditorState(EditorState.createEmpty());
    }
  }, [editingItem]);

  useQuery(GET_PROJECT_TAGS, {
    variables: { input: { projectId: editingItem.data?.project?._id } },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjectTags }) => {
      const tags = getProjectTags?.tags || [];
      setProjectTags(tags);
    },
  });

  const onSubmit = () => form.current.submitForm();

  const handleDone = async (values) => {
    const { title, date, timebox, selectedTags, details, tasks } = values;
    const contentData = !_.isEmpty(details)
      ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
      : "";

    let filesObj = [];
    if (files.length > 0) {
      filesObj = await uploadActivityFiles({
        userId: user?._id,
        fileList: files,
        updateProgress: setProgress,
      });
      setProgress(0);
    }

    const payload = {
      planned_date: moment.utc(date),
      title: _.upperFirst(title),
      details: contentData,
      tags: selectedTags,
      timebox: isNaN(timebox) ? timebox : "",
      fileSources: [
        ...(filesObj ? map(filesObj, (file) => file?.Location) : []),
        ...(fileSources || []),
      ],
      projectId: editingItem.data?.project?._id,
      tasks: _.map(tasks, (task) => {
        const newData = _.omit(task, ["_id"]);
        return {
          ...newData,
          assigned: task?.assigned?._id,
        };
      }),
    };

    plannedActivityDateChange({
      variables: {
        input: {
          activity_id: editingItem?.data._id,
          isTodoist: !_.isEmpty(todoistTask),
          ...payload,
        },
      },
    });
  };

  const handleOnClose = () => {
    onClose();
  };

  const validationSchema = yup.object().shape({
    title: yup.string().trim().required("This field is required"),
    details: yup.string().required("This field is required"),
    date: yup.date().required("This field is required").nullable(),
  });

  const initialFormValues = {
    title: editingItem ? editingItem.data?.title : "",
    details:
      editingItem && editingItem?.data?.details
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(editingItem.data?.details))
          )
        : EditorState.createEmpty(),
    date: moment(editingItem.data?.planned_date),
    timebox:
      editingItem && editingItem.data?.timebox
        ? convertTimeboxInMins(editingItem.data?.timebox)
        : timebox,
    selectedTags:
      editingItem && editingItem?.data?.tags ? editingItem?.data?.tags : [],
    tasks:
      editingItem && editingItem?.data?.tasks ? editingItem?.data?.tasks : [],
  };

  const gutter = [10, 0];
  const getSelectedUserTime = ({ date }) => {
    if (!_.isEmpty(date)) {
      getUserFreeTime({
        variables: {
          input: {
            userId: user._id,
            date: date,
          },
        },
      });
    }
  };

  const timeboxDropdown = () => {
    return (
      <AntForm.Item
        labelCol={{ span: 24 }}
        style={{ background: "#F8F8F8", width: 620, padding: 12 }}
        className="rounded"
        label={
          <div>
            <Typography.Text className="mr-2">Timebox</Typography.Text>
            <Popover
              content={
                <div className="mr-2 w-96">
                  <Typography.Text>
                    Our Time-Boxing technology helps make suggestions for a
                    user's workload, with rules, by analyzing a user's behavior,
                    and workload. This helps to determine when work time begins
                    affecting health, which is where the chances of burnout
                    increase.
                  </Typography.Text>
                </div>
              }
            >
              <QuestionCircleOutlined />
            </Popover>
          </div>
        }
      >
        <Spin spinning={freeTimeloading} indicator={<LoadingOutlined spin />}>
          <Field
            placeholder="Activity title"
            name="timebox"
            component={TimeBoxField}
            freeTime={userFreetime}
          />
        </Spin>
      </AntForm.Item>
    );
  };
  const handleAddNewTask = (setFieldValue, values) => {
    setFieldValue("tasks", [...values?.tasks, newTask]);
    setNewTask({
      _id: _.uniqueId("newtask-"),
      description: null,
      planned: null,
      assigned: null,
      completed: false,
    });
  };

  const handleSearch = (v) => {
    const { manager, members, created_by } = editingItem.data?.project;
    const allMembers = [...members, created_by];

    if (manager) {
      allMembers.push(manager);
    }

    const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");

    const matches = gatherMembers.filter(
      (member) =>
        new RegExp(v, "i").test(member.full_name) ||
        new RegExp(v, "i").test(member.email)
    );

    setFilterSearchUser(_.isEmpty(v) ? gatherMembers : matches);
  };

  return (
    <Modal
      closable={false}
      onCancel={handleOnClose}
      visible={isVisible}
      width={900}
      footer={null}
      style={{ background: "transparent", boxShadow: "none", border: "none" }}
      bodyStyle={{
        background: "transparent",
        boxShadow: "none",
        border: "none",
        padding: 0,
      }}
      className={styles.activityModalClass}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleDone(values)}
        innerRef={form}
      >
        {({ handleSubmit, values, setFieldValue, errors, submitCount }) => {
          return (
            <Card
              title={"Edit activity"}
              bodyStyle={{ padding: "7px 14px" }}
              extra={
                <div className="flex items-center">
                  <Dropdown overlay={() => timeboxDropdown(setFieldValue)}>
                    <div className={styles.timeboxDiv}>
                      <ClockCircleOutlined style={{ color: "#4E35C2" }} />
                      <Text className="ml-2" style={{ color: "#4E35C2" }}>
                        {values?.timebox
                          ? Number(values?.timebox)
                            ? editingItem?.data?.timebox
                            : values?.timebox
                          : "00:00:00"}
                      </Text>
                    </div>
                  </Dropdown>
                  <CloseOutlined className="ml-3" onClick={handleOnClose} />
                </div>
              }
              className={"mt-5"}
            >
              <Form onSubmit={handleSubmit}>
                <Row gutter={gutter}>
                  <Col
                    xs={14}
                    style={{
                      height: 510,
                      overflowX: "hidden",
                      paddingBottom: 14,
                    }}
                  >
                    <Field
                      placeholder="Activity title"
                      required={true}
                      label="Title"
                      name="title"
                      size="large"
                      hasFeedback={false}
                      component={AntInput}
                      help={false}
                      style={{ marginBottom: "0px" }}
                      spellCheck={true}
                    />
                    <div className={styles.editorDiv}>
                      <Field
                        placeholder="What are you going to accomplish?"
                        required
                        render={() => (
                          <AntForm.Item
                            required
                            labelCol={{ span: 24 }}
                            label="Details"
                            validateStatus={
                              submitCount > 0 && errors.details ? "error" : ""
                            }
                            help={
                              submitCount > 0 && errors.details
                                ? errors.details
                                : ""
                            }
                          >
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={(editorState) => {
                                seteditorState(editorState);
                                setFieldValue("details", editorState);
                              }}
                              wrapperClassName="flex flex-col-reverse"
                              placeholder="What are you going to accomplish?"
                              toolbar={toolbarOptions}
                              spellCheck={true}
                            />
                          </AntForm.Item>
                        )}
                      />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="flex items-center mb-2">
                      <Checks size={26} style={{ color: "#7CBF7E" }} />
                      <Text className="ml-2">Tasks</Text>
                    </div>
                    <div className={styles.newTaskDiv}>
                      <div>
                        <div className="flex items-start">
                          <Checkbox
                            defaultChecked={newTask.completed}
                            checked={newTask.completed}
                            onChange={(v) =>
                              setNewTask({
                                ...newTask,
                                completed: v.target.checked,
                              })
                            }
                          />
                          <div className="ml-3 flex-1">
                            <Input
                              value={newTask.description}
                              spellCheck={true}
                              onChange={(v) => {
                                setNewTask({
                                  ...newTask,
                                  description: v.target?.value,
                                });
                              }}
                            />
                            <div className="flex justify-between mt-1 ml-1 ">
                              <div className="flex items-center ">
                                <Dropdown
                                  trigger={["click"]}
                                  overlay={() => (
                                    <AssigneSelectDropdown
                                      handleSearch={handleSearch}
                                      values={values}
                                      filterSearchUser={filterSearchUser}
                                      setValue={(v) =>
                                        setNewTask({ ...newTask, assigned: v })
                                      }
                                      value={newTask?.assigned}
                                    />
                                  )}
                                >
                                  {newTask?.assigned ? (
                                    <div className="cursor-pointer">
                                      <UserAvatar
                                        size={24}
                                        user={newTask?.assigned}
                                      />
                                      <Text
                                        className="ml-2 text-xs underline"
                                        style={{ color: "#A5ACB8" }}
                                      >
                                        {newTask?.assigned?.full_name}
                                      </Text>
                                    </div>
                                  ) : (
                                    <div className="flex items-center cursor-pointer">
                                      <UserAddOutlined
                                        style={{ color: "#A5ACB8" }}
                                      />
                                      <Text
                                        style={{ color: "#A5ACB8" }}
                                        className="text-xs ml-1 underline"
                                      >
                                        Assignee
                                      </Text>
                                    </div>
                                  )}
                                </Dropdown>
                                <div
                                  className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                                >
                                  <CalendarPicker
                                    initialDate={moment()}
                                    onDateChange={(date) =>
                                      setNewTask({ ...newTask, planned: date })
                                    }
                                    label={
                                      <Text
                                        className="text-xs underline"
                                        style={{ color: "#A5ACB8" }}
                                      >
                                        {newTask?.planned
                                          ? moment(newTask?.planned).format(
                                              "dddd, MMMM D"
                                            )
                                          : "Schedule"}
                                      </Text>
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <Button
                                  icon={<CheckOutlined />}
                                  type="primary"
                                  size="small"
                                  onClick={() =>
                                    handleAddNewTask(setFieldValue, values)
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* display the tasks */}
                    {_.map(values?.tasks, (task) => {
                      return (
                        <div className={styles.newTaskDiv}>
                          <div>
                            <div className="flex items-start">
                              <Checkbox
                                defaultChecked={task.completed}
                                onChange={(v) => {
                                  const updatedTasks = values.tasks.map((t) =>
                                    t._id === task._id
                                      ? { ...t, completed: v.target.checked }
                                      : t
                                  );
                                  setFieldValue("tasks", updatedTasks);
                                }}
                              />
                              <div className="ml-3 flex-1">
                                <div>
                                  <Text
                                    editable={{
                                      onChange: (newDescription) => {
                                        const updatedTasks = values.tasks.map(
                                          (t) =>
                                            t._id === task._id
                                              ? {
                                                  ...t,
                                                  description: newDescription,
                                                }
                                              : t
                                        );
                                        setFieldValue("tasks", updatedTasks);
                                      },
                                      icon: (
                                        <EditOutlined
                                          style={{ color: "#A5ACB8" }}
                                        />
                                      ),
                                    }}
                                  >
                                    {task?.description}
                                  </Text>

                                  <Popconfirm
                                    placement="topRight"
                                    title="Are you sure to delete this task?"
                                    onConfirm={() => {
                                      const updatedTasks = values.tasks.filter(
                                        (t) => t._id !== task._id
                                      );
                                      setFieldValue("tasks", updatedTasks);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title="Remove task">
                                      <DeleteOutlined
                                        style={{ color: "#A5ACB8" }}
                                        className="ml-2"
                                      />
                                    </Tooltip>
                                  </Popconfirm>
                                </div>

                                <div className="flex items-center mt-1 mr-1 justify-end ">
                                  <div className="flex items-center mr-2">
                                    <CalendarPicker
                                      initialDate={
                                        task?.planned ? task.planned : moment()
                                      }
                                      onDateChange={(date) => {
                                        const updatedTasks = values.tasks.map(
                                          (t) =>
                                            t._id === task._id
                                              ? { ...t, planned: date }
                                              : t
                                        );
                                        setFieldValue("tasks", updatedTasks);
                                      }}
                                      label={
                                        <Text className="ml-1 text-xs underline">
                                          {task?.planned
                                            ? moment(task?.planned).format(
                                                "dddd, MMMM D"
                                              )
                                            : "Schedule"}
                                        </Text>
                                      }
                                    />
                                  </div>
                                  <Dropdown
                                    trigger={["click"]}
                                    overlay={() => (
                                      <AssigneSelectDropdown
                                        handleSearch={handleSearch}
                                        values={values}
                                        filterSearchUser={filterSearchUser}
                                        setValue={(v) => {
                                          const updatedTasks = values.tasks.map(
                                            (t) =>
                                              t._id === task._id
                                                ? { ...t, assigned: v }
                                                : t
                                          );
                                          setFieldValue("tasks", updatedTasks);
                                        }}
                                        value={task?.assigned}
                                      />
                                    )}
                                  >
                                    {task?.assigned ? (
                                      <div className="cursor-pointer">
                                        <UserAvatar
                                          size={24}
                                          user={task?.assigned}
                                        />
                                        <Text className="ml-2 text-xs underline">
                                          {task?.assigned?.full_name}
                                        </Text>
                                      </div>
                                    ) : (
                                      <div className="flex items-center cursor-pointer">
                                        <UserAddOutlined />
                                        <Text className="text-xs ml-1 underline">
                                          Assignee
                                        </Text>
                                      </div>
                                    )}
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                  <Col
                    xs={10}
                    style={{ padding: "4px 24px" }}
                    className="flex flex-col justify-between"
                  >
                    <div>
                      <Field
                        mode="tags"
                        placeholder="Select tags"
                        className="mt-2"
                        size="large"
                        inputType="select"
                        name="selectedTags"
                        component={AntSelect}
                        options={_.map(projectTags, (item) => {
                          return { value: item, label: item };
                        })}
                      />
                      <div className={styles.projectSelect}>
                        <Field
                          component={AntDatePicker}
                          name="date"
                          label="Select a date"
                          size="large"
                          inputType="picker"
                          className="w-full mt-3"
                          labelColNotReq={true}
                          disabledDate={(current) => {
                            return moment(current).isBefore(
                              moment(new Date()).subtract(1, "days")
                            );
                          }}
                          getPopupContainer={(node) => node.parentNode}
                          onCallBackChange={(v) =>
                            getSelectedUserTime({ date: v })
                          }
                          required={true}
                        />
                      </div>
                      <div>
                        <div className="flex items-center justify-between my-3">
                          <Typography.Text>Attachment</Typography.Text>
                          <Upload {...props} showUploadList={false}>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </div>
                        <div className="mt-2 max-h-44 overflow-y-auto">
                          {files.map((file) => (
                            <div
                              className="flex items-center justify-between mt-3 py-1 px-2"
                              key={file.uid}
                              style={{ background: "#FAFAFA" }}
                            >
                              <Text style={{ color: "#1890FF" }}>
                                {utility.truncateFileName(file?.name)}
                              </Text>

                              <DeleteOutlined
                                className=" cursor-pointer"
                                onClick={() => {
                                  setFiles((prevFiles) =>
                                    prevFiles.filter((f) => f.uid !== file.uid)
                                  );
                                }}
                              />
                            </div>
                          ))}

                          {fileSources.map((file) => (
                            <div
                              className="flex items-center justify-between mt-3 py-1 px-2"
                              key={file}
                              style={{ background: "#FAFAFA" }}
                            >
                              <Text style={{ color: "#1890FF" }}>
                                {utility.truncateFileName(file)}
                              </Text>

                              <DeleteOutlined
                                className=" cursor-pointer"
                                onClick={() => {
                                  setFileSources((prevFiles) =>
                                    prevFiles.filter((f) => f !== file)
                                  );
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        {progress > 0 && (
                          <Progress percent={progress} showInfo={false} />
                        )}
                      </div>
                    </div>
                    <div className="p-4 flex items-center justify-evenly">
                      <Button onClick={handleOnClose}>Cancel</Button>
                      <Button
                        className={`${styles.blackButton} outline-none border-none`}
                        type="primary"
                        onClick={onSubmit}
                        loading={loading || (progress > 0 && progress < 100)}
                        icon={<PlusOutlined />}
                      >
                        Update Activity
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditActivity;
