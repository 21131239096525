import gql from "graphql-tag";

export const GET_MESSAGES = gql`
  query ($input: getMessagesInput) {
    getMessages(input: $input) {
      _id
      currentPage
      markedAsRead
      messages {
        _id
        content
        from {
          _id
          email
          avatar
          full_name
        }
        created_at
        updated_at
        unread
      }
    }
  }
`;
