import React from "react";
import { useRecoilValue } from "recoil";
import { Typography, Avatar, Tooltip, Table, Badge } from "antd";
import { array, func, bool } from "prop-types";
import _, { map, isEmpty } from "lodash";
import { userSession } from "recoil/atoms/User/UserSession";
import { useHistory } from "react-router";
import ProjectMenuAction from "./ProjectMenuAction";
import UserAvatar from "components/UserAvatar";
import Loader from "components/Loader";
import EmptyData from "../EmptyData";
import { workspacesList } from "recoil/atoms/Workspaces";

const { Title, Text, Link } = Typography;

const ClientProjectTable = ({
  projects,
  onRemove,
  onRemoveClient,
  onRemoveManager,
  onArchive,
  onMembersList,
  setProjectSelected,
  setIsInviteMemberVisible,
  setIsInviteManagerVisible,
  loading,
  onProjectLeave,
  setIsAssignClientVisible = () => {},
}) => {
  const history = useHistory();
  const user = useRecoilValue(userSession);
  const workspaces = useRecoilValue(workspacesList);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, row) => {
        const color = row?.color;

        const userId = String(user._id);
        const managerId = String(row?.manager?._id);
        const ownerId = String(row?.created_by?._id);
        const hasAccess = managerId === userId || ownerId === userId;

        return (
          <div className="flex pl-4">
            <Badge
              size={30}
              className="mr-2"
              color={`${
                isEmpty(color) || color === "none" ? "#808080" : color
              }`}
            />
            {hasAccess ? (
              <Title level={5}>
                <Link
                  onClick={() =>
                    history.push({
                      pathname: `/projects/${row._id}`,
                    })
                  }
                >
                  {value}
                </Link>
              </Title>
            ) : (
              <Title level={5}>{value}</Title>
            )}
          </div>
        );
      },
    },
    {
      title: "Total Members",
      key: "total_members",
      render: ({ total_members, members }) => {
        if (total_members < 1) return <Text>{total_members}</Text>;
        return (
          <Avatar.Group maxCount={3}>
            {map(members, (member) => (
              <Tooltip title={member.email} placement="top" key={member._id}>
                <UserAvatar user={member} />
              </Tooltip>
            ))}
          </Avatar.Group>
        );
      },
    },
    {
      title: "Total Time",
      dataIndex: "total_time",
      key: "total_time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (value) => {
        if (value === "Active") return <Text type="success">{value}</Text>;
        return <Text type="danger">{value}</Text>;
      },
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (values) => {
        return (
          <React.Fragment>
            {values ? (
              <div className="flex items-center">
                <Tooltip
                  title={values?.email}
                  placement="top"
                  key={values?._id}
                >
                  <UserAvatar user={values} />
                </Tooltip>
              </div>
            ) : (
              <div>Unassigned</div>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "created_by",
      key: "created_by",
      render: (values) => {
        return (
          <React.Fragment>
            <Tooltip title={values?.email} placement="top" key={values?._id}>
              <UserAvatar user={values} />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: ({
        _id,
        total_activities,
        client,
        status,
        title,
        manager,
        created_by,
      }) => {
        const isInWorkspace = _.filter(
          workspaces,
          (workspace) => workspace.project._id === _id
        );
        return (
          <ProjectMenuAction
            onRemove={() => onRemove(_id)}
            onRemoveClient={() =>
              onRemoveClient({ client: client._id, project: _id })
            }
            onRemoveManager={() =>
              onRemoveManager({ manager: manager._id, project: _id })
            }
            onArchive={() => onArchive(_id)}
            onMembersList={() => onMembersList(_id)}
            setProjectSelected={() =>
              setProjectSelected({ value: _id, label: title })
            }
            setIsInviteManagerVisible={setIsInviteManagerVisible}
            setIsInviteMemberVisible={setIsInviteMemberVisible}
            setIsAssignClientVisible={setIsAssignClientVisible}
            hasActivities={total_activities > 0}
            hasClient={!isEmpty(client)}
            hasManager={!isEmpty(manager)}
            isArchived={status === "Archived"}
            isOwner={created_by._id === user._id}
            hasPermissions={
              manager?._id === user._id || created_by._id === user._id
            }
            isManager={manager?._id === user._id}
            title={title}
            onProjectLeave={() => onProjectLeave(_id)}
            isInWorkspace={!isEmpty(isInWorkspace)}
          />
        );
      },
    },
  ];

  const tableLoading = {
    spinning: loading,
    indicator: <Loader size="large" />,
  };

  return (
    <Table
      locale={{
        emptyText: <EmptyData />,
      }}
      rowKey="_id"
      columns={columns}
      dataSource={map(projects, (project) => ({
        ...project,
        key: project._id,
      }))}
      pagination={false}
      loading={tableLoading}
    />
  );
};

ClientProjectTable.propTypes = {
  projects: array,
  setProjectSelected: func,
  onRemove: func,
  onArchive: func,
  setIsInviteMemberVisible: func,
  loading: bool,
};

export default ClientProjectTable;
