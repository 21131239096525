import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Modal, Popover, Typography, Form as AntForm, Button } from "antd";
import { getUserIP } from "common/getUserIp";
import utility from "common/utility";
import CreditCardInput from "components/CustomComponents/MaskedInput";
import { AntInput } from "components/FormikCustomInputs";
import { Formik, Form, Field } from "formik";
import { ADD_CARD_TO_TANGO } from "graphql/mutations/Rewards/addCardToTango";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";

const { Text } = Typography;
const validationSchema = yup.object().shape({
  first_name: yup.string().trim().required("This field is required"),
  last_name: yup.string().trim().required("This field is required"),
  city: yup.string().trim().required("This field is required"),
  street: yup.string().trim().required("This field is required"),
  state: yup.string().trim().required("This field is required"),
  postal: yup.string().trim().required("This field is required"),
  country: yup.string().trim().required("This field is required"),
  email: yup.string().email("Invalid email").required("This field is required"),
});

const formatDate = (expDate) => {
  const month = expDate.slice(0, 2);
  const year = expDate.slice(2);

  // Determine the century based on the current year
  const currentYear = new Date().getFullYear();
  const century = currentYear.toString().slice(0, 2);

  // Construct the formatted date
  const formattedDate = `${century}${year}-${month}`;
  return formattedDate;
};

const AddCreditCardModal = ({ isVisible, setVisible, refetch }) => {
  const [userIP, setUserIP] = useState("");
  const formRef = React.useRef();

  useEffect(() => {
    const fetchUserIP = async () => {
      const ipData = await getUserIP();
      setUserIP(ipData);
    };

    fetchUserIP();
  }, []);

  const initialFormValues = {
    first_name: "",
    last_name: "",
    city: "",
    state: "",
  };

  const [addCardDetails, { loading: addingCard }] = useMutation(
    ADD_CARD_TO_TANGO,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        refetch();
        utility.setNotification(
          "Success",
          "Card added successfully",
          "success",
          "topRight"
        );
        setVisible(false);
      },
    }
  );

  const onSubmit = (v) => {
    const month = v?.card?.exp.slice(0, 2);
    if (Number(month) > 12) {
      utility.setNotification(
        "Something wrong happened",
        "Invalid expiry date",
        "warning",
        "topRight"
      );
      return null;
    }

    addCardDetails({
      variables: {
        input: {
          first_name: v.first_name,
          last_name: v.last_name,
          street: v.street,
          city: v.city,
          state: v.state,
          postal: v.postal,
          country: v.country,
          email: v.email,
          card: {
            number: v.card.number,
            exp: formatDate(v.card.exp),
            cvv: v.card.cvv,
          },
          ipAddress: userIP,
        },
      },
    });
  };

  const handleCardChange = (v, setFieldValue) => {
    setFieldValue("card", v);
  };

  return (
    <Modal
      visible={isVisible}
      title="Add credit card"
      width={500}
      closable
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
        innerRef={formRef}
      >
        {({ submitCount, handleSubmit, setFieldValue, values }) => {
          const cardError =
            submitCount > 0 &&
            ((values.card?.number?.length !== 15 &&
              values.card?.number?.length !== 16) ||
              values.card?.exp?.length !== 4 ||
              values.card?.cvv?.length < 2);

          return (
            <Form onSubmit={handleSubmit}>
              <div
                className="overflow-y-scroll overflow-x-hidden pr-2"
                style={{ height: 420 }}
              >
                <AntForm.Item
                  labelCol={{ span: 24 }}
                  className="rounded mb-1"
                  help={
                    cardError ? (
                      <Text className="ml-2 text-red-500">
                        Please enter valid card details
                      </Text>
                    ) : null
                  }
                  validateStatus={cardError ? "error" : null}
                  required
                  label={
                    <div>
                      <Typography.Text className="mr-2">
                        Card Information
                      </Typography.Text>
                      <Popover
                        content={
                          <div className="mr-2 w-96">
                            <Typography.Text>
                              All data is securely stored within Tango, and we
                              do not retain any card information in our
                              database. To learn more about the security of
                              Tango, please
                              <Typography.Link
                                href="https://www.tangocard.com/legal/privacy-notice"
                                target="_blank"
                              >
                                click here
                              </Typography.Link>{" "}
                              .
                            </Typography.Text>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </div>
                  }
                >
                  <CreditCardInput
                    setData={(v) => handleCardChange(v, setFieldValue)}
                    error={cardError}
                  />
                </AntForm.Item>

                <div className="flex items-center gap-2">
                  <Field
                    label="First name"
                    required
                    name="first_name"
                    placeholder="First name"
                    component={AntInput}
                    submitCount={() => {
                      if (!cardError) {
                        handleSubmit();
                      }
                    }}
                    hasFeedback
                    size="large"
                  />

                  <Field
                    label="Last name"
                    required
                    name="last_name"
                    placeholder="Last name"
                    component={AntInput}
                    submitCount={() => {
                      if (!cardError) {
                        handleSubmit();
                      }
                    }}
                    hasFeedback
                    size="large"
                  />
                </div>

                <Field
                  label="Street address"
                  required
                  name="street"
                  component={AntInput}
                  placeholder="Street address"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                />
                <Field
                  label="City"
                  required
                  name="city"
                  component={AntInput}
                  placeholder="City"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                />
                <div className="flex items-center justify-between ">
                  <div style={{ width: "70%" }}>
                    <Field
                      label="State"
                      required
                      name="state"
                      component={AntInput}
                      placeholder="State"
                      submitCount={submitCount}
                      hasFeedback
                      size="large"
                    />
                  </div>
                  <div style={{ width: "28%" }}>
                    <Field
                      label="Postal"
                      required
                      name="postal"
                      component={AntInput}
                      placeholder="Postal"
                      submitCount={submitCount}
                      hasFeedback
                      size="large"
                    />
                  </div>
                </div>

                <Field
                  label="Country"
                  required
                  name="country"
                  component={AntInput}
                  placeholder="Country"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                />
                <Field
                  label="Email address"
                  required
                  name="email"
                  component={AntInput}
                  placeholder="Email"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                />
              </div>

              <div className="flex items-center justify-end mt-4 ">
                <Button onClick={() => setVisible(false)} size="large">
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="ml-4"
                  onClick={handleSubmit}
                  loading={addingCard}
                >
                  + Add card
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCreditCardModal;
