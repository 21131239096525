import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  List,
  Popconfirm,
  Tooltip,
  Typography,
} from "antd";
import UserAvatar from "components/UserAvatar";
import React from "react";
import styles from "../styles.module.css";
import { isNil, get } from "lodash";
import { REMOVE_MEMBER_ALL_PROJECT } from "graphql/mutations/member/removeMemberAllProjects";
import { useMutation } from "@apollo/client";
import { GET_USER } from "graphql/queries/getUser";
import utility from "common/utility";

const Members = ({ user }) => {
  const userSettingCount = user?.settings?.uniqueProjectsUsers || 1;
  const userCount = `${user?.extra?.uniqueProjectsParticipants.length}/${
    isNil(userSettingCount) ? "?? (Change Plan to Set)" : userSettingCount
  }`;

  const [removeMemver, { loading }] = useMutation(REMOVE_MEMBER_ALL_PROJECT, {
    onCompleted: () => {
      utility.setNotification(
        `Member removed.`,
        "Member removed successfully.",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_USER],
  });
  const handleMemberRemove = (member) => {
    removeMemver({ variables: { memberId: member._id } });
  };
  return (
    <div className={styles.memberContainer}>
      <Card
        title={
          <div className="flex items-center justify-between">
            <Typography.Text className="text-lg" strong>
              User management
            </Typography.Text>
            <div className="flex items-center">
              <div className="mr-2">
                <Typography.Text className="text-base">
                  {userCount} users
                </Typography.Text>
              </div>
              <Tooltip title="All the unique members you have invited into your projects.">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          </div>
        }
      >
        <List
          dataSource={user?.extra?.uniqueProjectsParticipants}
          className=" max-h-60 overflow-y-scroll"
          renderItem={(item) => (
            <List.Item
              style={{ border: "none", padding: "5px 0" }}
              key={item?._id}
            >
              <div className="flex items-center w-full px-5 py-3 justify-between bg-gray-200 rounded mb-2">
                <div className="flex items-center">
                  <UserAvatar user={item} style={{ fontSize: 32 }} />
                  <Typography.Text className="ml-2 text-lg" strong>
                    {item?.full_name}
                  </Typography.Text>
                </div>
                <Popconfirm
                  onConfirm={() => handleMemberRemove(item)}
                  title="Are you sure you want to remove this member."
                  cancelText="No"
                  okText="Yes"
                >
                  <DeleteOutlined style={{ color: "red", fontSize: 20 }} />
                </Popconfirm>
              </div>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default Members;
