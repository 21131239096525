import React, { useEffect, useState } from "react";
import { Typography, Tag, Dropdown, Card as AntCard, Menu, Button } from "antd";
import Icon, {
  EllipsisOutlined,
  LeftSquareFilled,
  RightSquareFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { last, map, cloneDeep, size } from "lodash";
import Icons from "common/icons";
import { useRecoilState } from "recoil";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import utility from "common/utility";
import EmptyData from "../../EmptyData";
import _ from "lodash";
import { pausedActivities } from "recoil/atoms/PausedActivity";
import useStopActivityHook from "common/useStopActivityHook";
import ActivityHandoffModal from "components/Modals/ActivityHandoff";
import DragHandle from "../DragHandle";
import ContinueActivityAction from "./ContinueActivity";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import useOpenActivityDrawer from "common/openActivityDrawer";
import ContinueWithTimebox from "components/Modals/ContinueWithTimebox";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";

const { Text, Title } = Typography;

const ContinueActivity = () => {
  const [pausedActivitiesData, setPausedActivitiesData] = React.useState([]);
  const [continueWtihTimeboxModal, setContinueWtihTimeboxModal] =
    useState(false);
  const [filtered, setFiltered] = React.useState([]);
  const { handleStopTimer, isLoading } = useStopActivityHook();
  const [handoffModal, setHandoffModal] = useState(false);
  const [selectedHandoffActivity, setSelectedHandoffActivity] = useState();
  const [queryParameters, setQueryParameters] = useState({
    size: 4,
    offset: 0,
  });

  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);
  const [pausedActivity] = useRecoilState(pausedActivities);
  const { openActivityDrawer } = useOpenActivityDrawer();
  const [isVisible, setIsVisible] = useState(null);

  React.useEffect(() => {
    setPausedActivitiesData(pausedActivity);
    setFiltered(pausedActivity?.slice(0, queryParameters.size));
  }, [pausedActivity]);

  const { loading, refetch } = useQuery(GET_PAUSED_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const sortByDates = _.sortBy(data?.getPausedActivity, "time.start", -1);
      setFiltered(sortByDates?.slice(0, queryParameters.size));
    },
  });

  const handleStopActivity = (activity) => {
    const {
      time: { paused },
    } = activity;
    handleStopTimer({ activity, endTime: last(paused)?.time });
  };

  useEffect(() => {
    if (pausedActivities) {
      const { offset, size } = queryParameters;
      const copy = cloneDeep(pausedActivitiesData);
      const sortByDates = _.sortBy(copy, "time.start");
      setFiltered(sortByDates.slice(offset * size, offset * size + 4));
    }
  }, [queryParameters, pausedActivities]);

  const nextPage = () => {
    const payload = {
      ...queryParameters,
      offset: queryParameters.offset + 1,
    };
    setQueryParameters(payload);
  };

  const lastPage = () => {
    setQueryParameters({
      ...queryParameters,
      offset: queryParameters.offset - 1,
    });
  };

  const activityMenu = (activity) => {
    const wholeTeam = _.uniqBy(
      [
        activity?.project?.created_by,
        activity?.project?.manager_id,
        ...activity?.project?.members,
      ],
      "_id"
    );
    return (
      <Menu className="py-0 pt-1" onClick={() => setIsVisible(false)}>
        <ContinueActivityAction
          activity={activity}
          setContinueWtihTimeboxModal={setContinueWtihTimeboxModal}
          stepIndex={stepIndex}
        />
        <Menu.Divider className="my-0" />
        <Menu.Item
          onClick={() => handleStopActivity(activity)}
          className="py-4 flex items-center"
          icon={
            <Icon component={Icons.CheckMark} className="icon-medium mr-4" />
          }
          key="finish"
        >
          Finish
        </Menu.Item>

        {_.size(wholeTeam) > 1 && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              key="handofff"
              onClick={() => {
                setHandoffModal(true);
                setSelectedHandoffActivity(activity);
              }}
              className="py-4 flex items-center"
              icon={
                <UsergroupAddOutlined
                  style={{ color: "#E9A13B", fontSize: 20 }}
                  className="mr-4"
                />
              }
            >
              Handoff
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const ActivityCard = ({ activity }) => {
    const {
      time: { paused },
    } = activity;
    const totalPauses = map(paused, (pauses) => pauses.total);
    const timeSpent = utility.calculateTimeTotal(totalPauses);

    return (
      <AntCard
        key={activity?._id}
        className="mb-3 shadow-none rounded-lg cursor-pointer tour-firstcontinuewidget"
        bodyStyle={{
          padding: "14px 18px ",
        }}
        bordered={false}
      >
        <div className="flex items-center justify-between">
          <div
            className="flex flex-col ml-2"
            onClick={() => openActivityDrawer(activity, true)}
          >
            <div className="flex items-center">
              <Text className="text-green-600 text-lg mr-2">{timeSpent}</Text>
              <Tag
                color={`${
                  activity?.project.color && activity?.project.color !== "none"
                    ? activity?.project.color
                    : "#808080"
                }`}
              >
                {activity?.project?.title}
              </Tag>
              <div>{getIntegrationLogo(activity?.activity_type)}</div>
            </div>

            <Text className="text-lg" strong>
              {activity?.title}
            </Text>
          </div>

          <Dropdown
            overlay={() => activityMenu(activity)}
            trigger={["click"]}
            arrow
            visible={isVisible == activity._id}
            onVisibleChange={(v) => {
              if (v) {
                setIsVisible(activity._id);
                if (stepIndex.step == 5) {
                  setStepIndex({ step: 6, isAcitve: true });
                }
              } else {
                if (stepIndex.step != 6) {
                  setIsVisible(false);
                }
              }
            }}
          >
            <EllipsisOutlined
              style={{ fontSize: 35 }}
              className="cursor-pointer"
            />
          </Dropdown>
        </div>
      </AntCard>
    );
  };

  return (
    <>
      <DragHandle />
      <AntCard
        style={{ background: "none" }}
        loading={loading || isLoading}
        bodyStyle={{ padding: 0, height: "100%" }}
        className="h-full shadow-none  border-none outline-none tour-continuewidget tour-continuewidget-second"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Title level={4}>Continue Activity</Title>
          </div>
          <div>
            {queryParameters.offset > 0 && (
              <Button
                icon={
                  <LeftSquareFilled
                    style={{ fontSize: 32, color: "#262626" }}
                    className="cursor-pointer"
                  />
                }
                onClick={lastPage}
                className=" border-none"
              />
            )}
            {!(size(pausedActivitiesData) < 5) && (
              <Button
                className="border-none"
                icon={
                  <RightSquareFilled
                    style={{ fontSize: 32, color: "#262626" }}
                    className="cursor-pointer"
                  />
                }
                onClick={nextPage}
                disabled={_.size(filtered) < 4}
              />
            )}
          </div>
        </div>
        {_.isEmpty(filtered) && <EmptyData />}
        <div className="mt-4">
          {map(filtered, (activity) => {
            return <ActivityCard activity={activity} />;
          })}
          {handoffModal && (
            <ActivityHandoffModal
              isVisible={handoffModal}
              setHandoffModal={setHandoffModal}
              selectedHandoffActivity={selectedHandoffActivity}
              callback={refetch}
            />
          )}
        </div>
      </AntCard>
      {continueWtihTimeboxModal && (
        <ContinueWithTimebox
          isVisible={continueWtihTimeboxModal}
          setVisible={setContinueWtihTimeboxModal}
          activity={continueWtihTimeboxModal}
        />
      )}
    </>
  );
};

export default ContinueActivity;
