import gql from "graphql-tag";

export const GET_REMINDER = gql`
  query {
    getReminder {
      _id
      time
      sent
      user {
        email
      }
    }
  }
`;
