import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Typography, Avatar, Button } from "antd";
import { useMutation, useApolloClient } from "@apollo/client";
import Cookies from "js-cookie";
import Login from "./Login";
import Register from "./Register";
import cx from "classnames";
import utility from "common/utility";
import mobileIcon from "common/assets/mobile_icon.png";
import { LOGOUT } from "graphql/mutations/user/logout";
import { deleteFCMToken } from "common/firebaseUtilities";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import OnboardingSvg from "common/assets/onboard.svg";
import ForgotPassword from "./ForgotPassword";
import WinduLogo from "common/assets/WinduLogo.svg";

const { Paragraph, Title } = Typography;

const Onboard = () => {
  const client = useApolloClient();
  const location = useLocation();
  const { trial, figma, referral } = queryString.parse(location.search);
  const [view, setView] = useState("login");
  const platform = utility.getMobileOperatingSystem();
  const [referralCode, setReferralCode] = useState("");

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      Cookies.remove("token");
      window.location.href = "/login";
    },
  });

  useEffect(() => {
    if (trial) {
      setView("register");
    }
    if (referral) {
      setView("register");
      setReferralCode(referral);
    }
  }, [trial]);

  React.useEffect(() => {
    // when login page is loaded if there is a token in cookies we call the logout
    const token = Cookies.get("token");
    if (token) logout();

    // remove subcribe token
    deleteFCMToken(client); // remove fcm token - call mutation to remove device on server
  }, []);

  const onRedirect = () => {
    if (platform === "Android")
      window.location.href =
        "https://play.google.com/store/apps/details?id=fishyvisions.windu.dev&hl=en_US&gl=US";

    if (platform === "iOS")
      window.location.href = "https://apps.apple.com/us/app/windu/id1577381068";
  };

  const OnBoardingView = () => {
    if (view == "login") return <Login setView={setView} figma={figma} />;
    if (view == "register")
      return (
        <Register
          setView={setView}
          client={client}
          referralCode={referralCode}
        />
      );
    if (view == "forgot") return <ForgotPassword setView={setView} />;
  };

  if (
    platform === "iOS" ||
    platform === "Android" ||
    platform === "Windows Phone"
  ) {
    return (
      <>
        <div>
          <div
            className={cx(
              "flex justify-around absolute top-0 border-b-2 w-full shadow-md bg-white p-4"
            )}
            onClick={() => onRedirect()}
          >
            <Avatar
              src={mobileIcon}
              shape="square"
              className="icon-large"
              size={45}
            />
            <div>
              <Title level={5}>Download Mobile App</Title>
              <Paragraph>Start, Pause, Stop, and Track on the go.</Paragraph>
            </div>
          </div>
        </div>
        <div className="mt-24 flex justify-center flex-col items-center">
          <div className="flex justify-center">
            <img
              src={WinduLogo}
              alt="windu"
              className="p-5"
              style={{ width: 200 }}
            />
          </div>
          <Button type="primary" onClick={() => onRedirect()}>
            Download Mobile App
          </Button>
        </div>
      </>
    );
  }
  return (
    <Layout className="flex justify-center min-h-screen">
      <Row style={{ height: "100vh" }} className="bg-white">
        <Col xs={24} sm={24} md={12} className="bg-white flex">
          <OnBoardingView />
        </Col>
        <Col xs={0} sm={0} md={12} className="m-auto p-5">
          <div
            className=" rounded-xl"
            style={{ background: "#B1A5EB", height: "92vh" }}
          >
            <img
              src={OnboardingSvg}
              alt="windu-onboarding"
              style={{ minHeight: "80vh" }}
              className="p-5"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default Onboard;
