import axios from "axios";
import moment from "moment";
import { UI_URL } from "common/constants";

export const CALENDAR_LIST_RESOURCE =
  "https://www.googleapis.com/calendar/v3/users/me/calendarList";

export const EVENTS_LIST_CALENDAR_RESOURCE = (cal, dateMin, dateMax) =>
  `https://www.googleapis.com/calendar/v3/calendars/${cal}/events?timeMin=${dateMin}&timeMax=${dateMax}&singleEvents=true&orderBy=startTime`;

export const GOOGLE_OAUTH_TOKEN = (code) =>
  `https://oauth2.googleapis.com/token?grant_type=authorization_code&code=${code}&client_id=${
    process.env.REACT_APP_GOOGLE_CLIENT
  }&client_secret=${
    process.env.REACT_APP_GOOGLE_SECRET
  }&redirect_uri=${UI_URL()}`;

export const GOOGLE_REFESH = (code) =>
  `https://oauth2.googleapis.com/token?grant_type=refresh_token&refresh_token=${code}&client_id=${
    process.env.REACT_APP_GOOGLE_CLIENT
  }&client_secret=${
    process.env.REACT_APP_GOOGLE_SECRET
  }&redirect_uri=${UI_URL()}`;

export const listCalendars = (token) => {
  const Bearer = `Bearer ${token}`;
  return axios.get(CALENDAR_LIST_RESOURCE, {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const listCalendarEvents = (cal, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(
    EVENTS_LIST_CALENDAR_RESOURCE(
      cal,
      moment.utc().format().toString(),
      moment.utc().add(7, "days").startOf("day").format().toString()
    ),
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
      },
    }
  );
};

export const listCompletedCalendarEvents = (cal, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(
    EVENTS_LIST_CALENDAR_RESOURCE(
      cal,
      moment.utc().subtract(7, "days").startOf("day").format().toString(),
      moment.utc().format().toString()
    ),
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
      },
    }
  );
};

export const getRefreshToken = (code) => {
  const Bearer = `Bearer ${code}`;

  return axios.post(GOOGLE_OAUTH_TOKEN(code), {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const refreshToken = (code) => {
  const Bearer = `Bearer ${code}`;
  return axios.post(GOOGLE_REFESH(code), {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};
