import React, { useEffect, useState } from "react";
import utility from "common/utility";
import _ from "lodash";
import Axios from "axios";

const getMondayUser = async ({ token }) => {
  try {
    return Axios.post(
      "https://api.monday.com/v2",
      {
        query: `query{
                me{
                  email
                  id
                  name
                  photo_small
                }
              }`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const getGithubUser = async ({ token }) => {
  try {
    var config = {
      method: "get",
      url: "https://api.github.com/user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await Axios(config)
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const getGoogleUser = async ({ token }) => {
  try {
    var config = {
      method: "get",
      url: "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await Axios(config)
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const getTrelloUser = async ({ token }) => {
  try {
    var config = {
      method: "get",
      url: `https://api.trello.com/1/members/me?key=${process.env.REACT_APP_TRELLO_CLIENT}&token=${token}`,
      headers: {
        Cookie:
          "dsc=3398ba49d46f7e4e8cc6d38f6ba1729c020bb9045264dd2184be12a860859cc1; preAuthProps=s%3A628db8db5fec714938b972e9%3AisEnterpriseAdmin%3Dfalse.LNtQgUbxJZ3necTquz7qIgOMmg59QBjVSGyi4s6Odxs",
      },
    };
    return await Axios(config)
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const getJiraUser = async ({ token }) => {
  try {
    var config = {
      method: "get",
      url: "https://api.atlassian.com/me",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
    return await Axios(config)
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const getTodoistUser = async ({ token }) => {
  try {
    var config = {
      method: "get",
      url: "https://api.todoist.com/sync/v9/user",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
    return await Axios(config)
      .then((response) => {
        return { data: response?.data };
      })
      .catch(function (error) {
        return { error };
      });
  } catch (error) {
    throw new Error(error);
  }
};

const useCheckIntegrationConnectionHook = () => {
  const checkIntegrationConnection = async ({ type, token }) => {
    switch (type) {
      case "jira cloud":
        const validJiraUser = await getJiraUser({ token });
        return validJiraUser;
      case "monday":
        const mondayUser = await getMondayUser({ token });
        return mondayUser;
      case "github":
        const githubUser = await getGithubUser({ token });
        return githubUser;
      case "google":
        const validGoogleUser = await getGoogleUser({ token });
        return validGoogleUser;
      case "trello":
        const validTrelloUser = await getTrelloUser({ token });
        return validTrelloUser;
      case "todoist":
        const validTodoistUser = await getTodoistUser({ token });
        return validTodoistUser;
      default:
        break;
    }
  };
  return { checkIntegrationConnection, loading: false };
};

export default useCheckIntegrationConnectionHook;
