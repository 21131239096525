import React, { useState } from "react";
import { Typography, Tag } from "antd";
import styles from "../styles.module.css";
import { map } from "lodash";
import { onBoardingInfoV2 } from "../onBoardingData";

const { Text, Title } = Typography;

const First = ({ values, setFieldValue, errors }) => {
  const hasErrors = errors["expertise"];
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...values?.expertise, tag]
      : values?.expertise.filter((t) => t !== tag);
    setFieldValue("expertise", nextSelectedTags);
  };
  return (
    <div className={styles.radioButtonStyles}>
      <div className="flex flex-col text-center">
        <Title>Tell us about yourself</Title>
        <Text className="text-lg mt-2">
          By sharing information about yourself, such as your job title, work
          experience, and interests, we can offer features and tools that are
          specifically designed to help you be more productive and successful in
          your work.
        </Text>
      </div>
      <div className="mt-5 flex flex-col">
        <Text className=" text-xl font-semibold mt-2">
          {onBoardingInfoV2().Work.question}
        </Text>
        <Text className=" mt-1 mb-3">Select all that apply:</Text>
        {hasErrors && (
          <Text style={{ color: "#ff4d4f" }}>Please select atleast one</Text>
        )}
        <div className={styles.radioButtonStyles}>
          {map(onBoardingInfoV2().Work.options, (tag) => {
            return (
              <Tag.CheckableTag
                key={tag.value}
                checked={values?.expertise.indexOf(tag.value) > -1}
                onChange={(checked) => handleChange(tag.value, checked)}
                className="text-base"
              >
                {tag.value}
              </Tag.CheckableTag>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default First;
