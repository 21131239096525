import gql from "graphql-tag";

export const REMOVE_CLIENT = gql`
  mutation removeClient($input: clientInput) {
    removeClient(input: $input) {
      _id
      email
    }
  }
`;
