import gql from "graphql-tag";

export const CONFIRM_EMAIL = gql`
  mutation($userId: ID!) {
    confirmEmail(userId: $userId) {
      token
      user {
        _id
        email
        full_name
      }
    }
  }
`;
