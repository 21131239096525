import gql from "graphql-tag";

export const CHECKOUT_SESSION = gql`
  mutation checkoutSession($input: checkoutSessionInput) {
    checkoutSession(input: $input) {
      id
      cancel_url
      payment_status
      status
      submit_type
      subscription
      success_url
      url
    }
  }
`;
