import React from "react";
import "tailwindcss/tailwind.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UIfx from "uifx";
import _ from "lodash";
import { useSetRecoilState } from "recoil";
import { messageSoundObj } from "recoil/atoms/Messages/MessageSounds";
import MainLayout from "./providers/MainLayout";
import PrivateRoute from "./providers/PrivateRoute";
import Onboard from "views/Onboard";
import ConfirmEmail from "views/ConfirmEmail";
import withUserTypeComponent from "providers/withUserTypeComponent";
import ProjectInvite from "views/ProjectInvite";
import AppProvider from "providers/AppProvider";
import { TimerProvider } from "contexts/timer-context";
import ResetPassword from "views/ResetPassword";
import NotFound from "views/Provisional/404";
import ClientInviteAccept from "views/Onboard/Invite/Client";
import Admin from "views/Admin/index";
import Figma from "views/figma";
import AdminRoute from "providers/AdminRoute";
import { soundLink } from "common/constants";
import { privateRoutes } from "common/routes";
import "./styles/main.css";
import Generator from "views/Generator";
import { useFeature } from "@optimizely/react-sdk";
import { Crisp } from "crisp-sdk-web";
import WinduNavigation from "components/WinduNavigation";
function App() {
  const setSound = useSetRecoilState(messageSoundObj);
  const path = window.location.pathname;

  React.useEffect(() => {
    const audio = new UIfx(soundLink);
    setSound(audio);
  }, []);

  React.useEffect(() => {
    // Hide the Crisp chat when the page loads
    Crisp.chat.hide();
    Crisp.message.onMessageReceived((data) => {
      Crisp.chat.show();
    });
    Crisp.chat.onChatClosed(() => {
      // Executed once the chat was closed
      Crisp.chat.hide();
    });
  }, []);

  React.useEffect(() => {
    if (path.includes("/referral")) {
      const parts = path.split("-");
      window.location.href = `/login?referral=${parts[1]}`;
    }
  }, []);

  const [isEnabled, generatorVariables] = useFeature("invoice_generator");

  return (
    <Router>
      <Switch>
        <Route exact path={privateRoutes}>
          <AppProvider>
            <TimerProvider initialTime={"00:00:00"} throttling={1000}>
              <WinduNavigation>
                <MainLayout>
                  {_.map(privateRoutes, (route) => (
                    <PrivateRoute
                      key={route}
                      path={route}
                      exact
                      component={withUserTypeComponent}
                    />
                  ))}
                </MainLayout>
              </WinduNavigation>
            </TimerProvider>
          </AppProvider>
        </Route>
        <AdminRoute path="/admin" exact component={Admin} />
        <Route path="/invite/client" component={ClientInviteAccept} />
        <Route path="/project/invite" exact component={ProjectInvite} />
        <Route path="/confirm" exact component={ConfirmEmail} />
        <Route path="/figma" exact component={Figma} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/login" component={Onboard} />
        {generatorVariables?.showGenerator && (
          <Route path="/generator" component={Generator} />
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
