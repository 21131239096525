import gql from "graphql-tag";

export const GET_PAUSED_ACTIVITY = gql`
  query {
    getPausedActivity {
      _id
      title
      description
      meta
      status
      tags
      key
      content
      fileSources
      timebox
      handoff {
        _id
        handoff_at
        user {
          _id
          email
          avatar
          full_name
          rates {
            currency
            rate
          }
        }
        time {
          total_time
          start
          end
        }
      }
      activity_type
      created_by {
        email
        avatar
        full_name
      }
      project {
        _id
        title
        color
        members {
          _id
        }
        created_by {
          _id
        }
        manager_id {
          _id
        }
      }
      time {
        start
        paused {
          created_at
          time
          continue
          total
        }
      }
    }
  }
`;
