import React from "react";
import { onBoardingInfoV2 } from "../onBoardingData";
import { Typography, Radio } from "antd";
import { map } from "lodash";
import { Field } from "formik";
import { AntSelect } from "components/FormikCustomInputs";

const { Text } = Typography;

const Four = ({ values, setFieldValue, errors }) => {
  const hasErrors = errors?.companyWorkers;

  return (
    <div className=" mt-12">
      <Text className="text-lg">How many people work at your company?</Text>
      {hasErrors && (
        <div>
          <Text style={{ color: "#ff4d4f" }}>Please select atleast one</Text>
        </div>
      )}
      <div className="flex mt-8">
        <Radio.Group
          onChange={(v) => setFieldValue("companyWorkers", v?.target.value)}
          defaultValue={values.companyWorkers}
        >
          {map(onBoardingInfoV2().CompanyWorkers.options, (item) => (
            <Radio.Button className="ml-2" value={item.value} key={item.key}>
              {item.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      {values?.teamMembers !== "Only Me" && (
        <div className="mt-12 ">
          <Text className="text-lg">
            Where does your company stand financially?
          </Text>
          <div style={{ width: "80%" }} className="mt-4">
            <Field
              size="large"
              component={AntSelect}
              name="funding"
              placeholder=""
              selectOptions={onBoardingInfoV2().Funding.options}
              inputType="select"
              required
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Four;
