import React from "react";

const TimeCounter = ({ time }) => {
  return (
    <div style={{ color: "#3C2E94" }} className="ml-3 text-xl font-semibold">
      {time}
    </div>
  );
};
export default TimeCounter;
