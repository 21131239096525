import gql from "graphql-tag";

export const GET_CHATS = gql`
  query ($input: getChatsInput) {
    getChats(input: $input) {
      _id
      unread
      lastMessage {
        created_at
        content

        from {
          email
        }
      }
      participants {
        _id
        email
        avatar
        full_name
        isOnline
      }
    }
  }
`;
