import React from "react";

const NotFound = () => (
  <img
    className="w-full h-screen"
    src="https://windu.s3.us-east-2.amazonaws.com/assets/windu2_404+page.jpg"
    alt="404"
  />
);

export default NotFound;
