import gql from "graphql-tag";

export const GET_CURRENT_ACTIVITY = gql`
  query {
    getCurrentActivity {
      _id
      title
      description
      details
      created_at
      status
      initiated_by {
        _id
        full_name
        email
        avatar
      }
      activity_persona {
        _id
        github {
          link
          status
          title
        }
      }
      project {
        title
        color
        _id
      }
      time {
        _id
        start
        end
        day_duration
        total_time
        paused {
          _id
          created_at
          time
          continue
          total
          timebox
        }
      }
      created_by {
        _id
        full_name
        email
        avatar
      }
      activity_type
      tags
      key
      content
      timebox
      fileSources
      meta
      handoff {
        _id
        user {
          _id
          full_name
          email
          avatar
        }
        timebox
        time {
          _id
          start
          end
          day_duration
          total_time
          paused {
            _id
            created_at
            time
            continue
            total
            timebox
          }
        }
        handoff_at
      }
    }
  }
`;
