import React, { useEffect, useState } from "react";
import _, { size, filter, isEmpty } from "lodash";
import { useLazyQuery, useSubscription, useApolloClient } from "@apollo/client";
import {
  Badge,
  ConfigProvider,
  Dropdown,
  List,
  Tooltip,
  Typography,
} from "antd";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { GET_ACTIVE_TIMERS } from "graphql/queries/Activity/getActiveTimers";
import { START_ACTIVITY } from "graphql/subscription/activeUsers/startActivity";
import { STOP_ACTIVITY } from "graphql/subscription/activeUsers/stopActivity";
import AvatarTimer from "components/AvatarTimer";
import { ClockCircleOutlined } from "@ant-design/icons";
import cx from "classnames";
import { userSession } from "recoil/atoms/User/UserSession";
import { updateRecentActivities } from "common/cacheUtilities";
import styles from "./styles.module.css";
import { wsClient } from "graphql/config/client";
import EmptyData from "../EmptyData";
import Loader from "components/Loader";

const { Title, Text } = Typography;

const ActiveTimers = () => {
  const [timers, setTimers] = React.useState([]);
  const [showDropdown, setshowDropdown] = useState(false);
  const [isFistBumpLoading, setisFistBumpLoading] = useState(false);

  const user = useRecoilValue(userSession);
  const client = useApolloClient();

  const [getActiveTimers, { loading, refetch }] = useLazyQuery(
    GET_ACTIVE_TIMERS,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ activeTimers }) => {
        setTimers(activeTimers);
      },
    }
  );

  const { data: startActivityData, error: startActivityError } =
    useSubscription(START_ACTIVITY);

  const { data: stopActivityData, error: stopActivityError } =
    useSubscription(STOP_ACTIVITY);

  const addActiveUser = () => {
    const { startActivity } = startActivityData;
    const { created_by } = startActivity;

    if (user._id !== created_by._id) setTimers([...timers, startActivity]);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        getActiveTimers();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const removeActiveMember = () => {
    const { stopActivity } = stopActivityData;
    const { created_by } = stopActivity;

    if (!isEmpty(stopActivity?.time?.end) && user._id !== created_by._id)
      updateRecentActivities(client, stopActivity, "stop");

    const filtered = filter(timers, (time) => time._id !== stopActivity._id);
    setTimers(filtered);
  };

  useEffect(() => {
    getActiveTimers();
  }, []);

  wsClient.on("reconnected", () => {
    const disconnected = localStorage.getItem("wsDisconnected");

    const msDiff = moment(moment(new Date()).format("MM/DD/YY HH:mm:ss")).diff(
      new Date(disconnected)
    );

    if (msDiff >= 10000) refetch();
  });

  useEffect(() => {
    if (startActivityError) console.warn(startActivityError);
    if (startActivityData) {
      addActiveUser();
    }
  }, [startActivityError, startActivityData]);

  useEffect(() => {
    if (stopActivityError) console.warn(stopActivityError);
    if (stopActivityData) {
      removeActiveMember();
    }
  }, [stopActivityError, stopActivityData]);

  const byProjectTimers = _.groupBy(timers, "project.title");
  const newTimerData = _.map(byProjectTimers, (item, index) => {
    return {
      project: index,
      timer: item,
    };
  });

  const tableLoading = {
    spinning: loading || isFistBumpLoading,
    indicator: <Loader />,
  };
  const menu = () => {
    if (showDropdown) {
      return (
        <div className={cx("bg-white", styles.listContainer)}>
          <div className="h-12 flex items-end px-3 pb-1">
            <Title className="uppercase font-semibold" level={5}>
              Active Timers
            </Title>
            <div
              className="flex justify-center rounded ml-6  items-center"
              style={{
                minWidth: 40,
                height: 22,
                background: "#DFF3E4",
                border: "1px solid #63C276",
                color: "#63C276",
              }}
            >
              <span>{timers.length}</span>
            </div>
          </div>
          <ConfigProvider renderEmpty={() => <EmptyData />}>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={newTimerData}
              loading={tableLoading}
              className="overflow-y-auto"
              style={{ width: "400px", maxHeight: "350px", border: "none" }}
              renderItem={(timer) => {
                return (
                  <div>
                    <div className="w-full text-base uppercase font-semibold bg-gray-50 p-1 px-4 mt-4">
                      <Text>{timer.project}</Text>
                    </div>
                    {timer.timer.map((item) => (
                      <AvatarTimer
                        activity={item}
                        getActiveTimers={getActiveTimers}
                        setshowDropdown={setshowDropdown}
                        isFistBumpLoading={isFistBumpLoading}
                        setIsFistBumpLoading={setisFistBumpLoading}
                      />
                    ))}
                  </div>
                );
              }}
            />
          </ConfigProvider>
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <>
      <Dropdown
        className="mr-12 tutorial-active-timer"
        placement="bottomRight"
        trigger={["click"]}
        overlay={menu}
        visible={showDropdown}
        onVisibleChange={setshowDropdown}
        arrow
      >
        <Badge
          style={{ backgroundColor: "#62c376" }}
          className="cursor-pointer"
          count={size(timers)}
          onClick={() => setshowDropdown(true)}
        >
          <Tooltip title="Active Timers">
            <ClockCircleOutlined
              style={{ color: "#3C2E94" }}
              className="icon"
            />
          </Tooltip>
        </Badge>
      </Dropdown>
    </>
  );
};
export default ActiveTimers;
