import gql from "graphql-tag";

export const UPDATE_INVOICE_STATUS = gql`
  mutation updateInvoiceStatus($input: updateInvoiceInput) {
    updateInvoiceStatus(input: $input) {
      code
      name
    }
  }
`;
