import React, { useState, useEffect } from "react";
import { AntSelect } from "components/FormikCustomInputs";
import { Field } from "formik";
import { GET_PROJECTS } from "graphql/queries/projects/getProjects";
import { useLazyQuery } from "@apollo/client";
import { map, uniqBy, debounce } from "lodash";
import Loader from "components/Loader";

const defaultProjectFilters = {
  status: "Active",
  sort: `{"updated_at": -1}`,
  relationship: "",
};

const SelectProjects = ({ label, name, size }) => {
  const defaultParameters = {
    size,
    offset: 0,
    search: "",
    filters: defaultProjectFilters,
  };

  const [projects, setProjects] = useState([]);
  const [queryParameters, setQueryParameters] = useState(defaultParameters);

  const [getProjects, { loading }] = useLazyQuery(GET_PROJECTS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjects }) => {
      const gatherProjects = uniqBy(
        [...new Set([...projects, ...getProjects.data])],
        "_id"
      );
      setProjects(gatherProjects);
    },
  });

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setQueryParameters({
        ...queryParameters,
        offset: queryParameters.offset + 10,
      });
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const onSearch = (query) => {
    setProjects([]);
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  return (
    <Field
      placeholder="Select a project"
      options={map(projects, (project) => {
        return {
          label: project.title,
          value: project._id,
        };
      })}
      onPopupScroll={onScroll}
      required
      label={label}
      name={name}
      component={AntSelect}
      createText="create"
      size="large"
      inputType="select"
      filterOption={false}
      loading={loading}
      showSearch
      onSearch={(value) => delayedQuery(value)}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {loading && (
            <div>
              <Loader size={"small"} />
            </div>
          )}
        </div>
      )}
    />
  );
};

SelectProjects.defaultProps = {
  size: 10,
};

export default SelectProjects;
