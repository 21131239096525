import gql from "graphql-tag";

export const GET_CLIENTS = gql`
  query($input: getClientsInput) {
    getClients(input: $input) {
      data {
        _id
        email
        company
        full_name
        address
        projects {
          _id
          title
        }
        total_projects
        created_at
        updated_at
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
