import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { Button } from "antd";
import { bool, func, instanceOf } from "prop-types";
import { get, join, isNil, map } from "lodash";
import { useRecoilState } from "recoil";
import { PROJECT_INVITE } from "graphql/mutations/projects/projectInvite";
import { GET_REQUESTS } from "graphql/queries/request/getRequests";
import { GET_NOTIFICATIONS } from "graphql/queries/notification/getNotification";
import Modal from "../BaseModal/Modal";
import CreateProjectTeam from "./CreateProjectTeam";
import { userSession } from "recoil/atoms/User/UserSession";
import validationSchema from "./validatioSchema";
import utility from "common/utility";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { useLimitWithInvitee } from "hooks/payPerUser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddMember = ({ isVisible, onClose, projectSelected }) => {
  const { pastLimit, setUserLimit } = useLimitWithInvitee();
  const [promptSetLimit, setPromptSetLimit] = useState(false);
  const formRef = React.useRef();
  const [user] = useRecoilState(userSession);
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);
  const history = useHistory();
  const [addMember, { loading }] = useMutation(PROJECT_INVITE, {
    onCompleted: ({ projectInvite }) => {
      utility.setNotification(
        "Member invitation successfully",
        `${join(projectInvite.emails, " ")} has been invited`,
        "success",
        "topRight"
      );
      createGoogleEvent(user?._id, "INVITE_MEMBER", "app_engagement");

      formRef.current.resetForm();
      setFilterSearchUser([]);
      onClose();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
      formRef.current.resetForm();
      onClose();
    },
    refetchQueries: [GET_REQUESTS, GET_NOTIFICATIONS],
  });

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    setFilterSearchUser([]);
    onClose();
  };

  const handleDone = (values) => {
    const { member } = values;
    const isPastLimit = pastLimit(member);
    if (isPastLimit) {
      if (isNil(setUserLimit)) {
        setPromptSetLimit(true);
        // lets prompt them them to set
      } else {
        // if already set lets prompt them that they are alreadty past limit and to change in settings
        setPromptSetLimit(true);
      }
    } else {
      addMember({
        variables: {
          input: { emails: member, projectId: projectSelected.value },
        },
      });
    }
  };

  if (promptSetLimit) {
    utility.setNotification(
      "User limit exceed.",
      "In order to select more users please set more users to your current plan",
      "error",
      "topRight"
    );
    history.push("/plans");
  }
  return (
    <Modal
      title={`Invite member to ${projectSelected?.label}`}
      visible={isVisible}
      onCancel={() => handleCancel()}
      width={500}
      footer={null}
      content={
        <Formik
          initialValues={{ member: [] }}
          validationSchema={validationSchema}
          onSubmit={handleDone}
          innerRef={formRef}
        >
          {({ submitCount, setFieldValue, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <CreateProjectTeam
                  submitCount={submitCount}
                  currentEmail={user.email}
                  setFieldValue={setFieldValue}
                  project={projectSelected.id}
                  filterSearchUser={filterSearchUser}
                  setFilterSearchUser={setFilterSearchUser}
                />
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center">
          <Button className="w-32" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => onSubmit()}
            className="ml-3 w-32"
            loading={loading}
          >
            Done
          </Button>
        </div>
      }
    />
  );
};

AddMember.propTypes = {
  isVisible: bool,
  onClose: func,
  projectSelected: instanceOf(Object),
};

export default AddMember;
