import React, { useState } from "react";
import { Dropdown, Menu, Popconfirm } from "antd";
import { DeleteOutlined, MessageOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { Icon } from "@fishyvisions/windu-uikit";
import { func } from "prop-types";

const MemberMenuAction = ({
  onRemove,
  projectSelected,
  user,
  currentEmail,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  let removeDisabled = false;

  if (!isEmpty(projectSelected))
    removeDisabled =
      projectSelected.owner === user._id ||
      projectSelected.manager === user._id; 

  const ProjectMenu = (
    <Menu className="py-0">
        <Menu.Item
          icon={<DeleteOutlined className="icon-medium" />}
          className="py-4 flex items-center"
          key="2"
          disabled={!removeDisabled}
        >
          <Popconfirm
            placement="topLeft"
            title={`Are you sure to remove ${currentEmail} of ${projectSelected.label}`}
            onConfirm={() => onRemove()}
            okText="Yes"
            cancelText="No"
            disabled={!removeDisabled}
          >
            Remove Member
          </Popconfirm>
        </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={ProjectMenu}
      onVisibleChange={() => setIsVisible(!isVisible)}
      visible={isVisible}
      trigger={["click"]}
    >
      <span onClick={(e) => e.preventDefault()} className="cursor-pointer">
        <Icon type="Meatball" size="small" />
      </span>
    </Dropdown>
  );
};

MemberMenuAction.propTypes = {
  onRemove: func,
};

export default MemberMenuAction;
