import { ApolloClient, InMemoryCache, split, from } from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import moment from "moment";
import Cookies from "js-cookie";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client"; // Allows FileList, File, Blob or ReactNativeFile instances within query or mutation variables and sends GraphQL multipart requests
import { graphql, graphqlws } from "common/constants";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const error = JSON.stringify(networkError);
    const errorParsed = JSON.parse(error);
    if (
      errorParsed.name === "ServerParseError" &&
      errorParsed.statusCode === 404
    )
      console.warn(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (extensions.code === "UNAUTHENTICATED") {
        const search = window.location.search;
        const path = window.location.pathname;
        const hasSearch = search?.length > 0;
        Cookies.remove("token");
        if (search) {
          window.location.href = `/login${search}${
            hasSearch ? "&" : ""
          }redir=${path}`;
        } else {
          window.location.href = `/login?redir=${path}`;
        }
      }
    });
  }
});

const httpLink = createUploadLink({
  uri: graphql,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
});

const links = from([authLink, errorLink, httpLink]);

export const wsClient = new SubscriptionClient(graphqlws, {
  reconnect: true,
  lazy: true,
  timeout: 75000,
  minTimeout: 75000,
  inactivityTimeout: 75000, // how long the client must wait before disconnecting from the server - 1 hour of inactivity */
  connectionParams: () => {
    const token = Cookies.get("token");

    return {
      Authorization: `Bearer ${token || ""}`,
    };
  },
});

wsClient.on("disconnected", () => {
  localStorage.setItem(
    "wsDisconnected",
    moment(new Date()).format("MM/DD/YY HH:mm:ss")
  );
});

const wsLink = new WebSocketLink(wsClient);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  links
);

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        getPausedActivity: {
          merge(_, incoming) {
            return incoming;
          },
        },
        getActivityByProject: {
          merge(_, incoming) {
            return incoming;
          },
        },
        getActiveChats: {
          merge(_, incoming) {
            return incoming;
          },
        },
        getPlannedActivity: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: splitLink,
  cache,
});

export default client;
