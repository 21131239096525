import React from "react";
import { Modal, Button } from "antd";
import { Formik, Form, Field } from "formik";
import { AntInput, AntTextArea } from "components/FormikCustomInputs";
import { useMutation } from "@apollo/client";
import * as yup from "yup";
import { get } from "lodash";
import ImageUpload from "components/ImageUpload";
import { CREATE_INTEGRATION } from "graphql/mutations/Integrations/createIntegration";
import utility from "common/utility";

const IntegrationModal = ({ isVisible, onCancel, onClose, refetch }) => {
  const [createIntegration] = useMutation(CREATE_INTEGRATION, {
    onCompleted(data) {
      utility.setNotification(
        "Success",
        `Integration Created!`,
        "success",
        "topRight"
      );
      refetch();
      form.current.resetForm();
      onClose();
    },
  });

  const form = React.useRef();

  const handleDone = (values) => {
    const { name, avatar, description, clientId, clientSecret } = values;
    const payload = {
      input: {
        name,
        avatar,
        description,
        client_id: clientId,
        client_secret: clientSecret,
      },
    };
    createIntegration({ variables: payload });
  };

  const initialFormValues = {
    avatar: "",
    name: "",
    description: "",
    clientId: "",
    clientSecret: "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    clientId: yup.string().required("This field is required"),
    clientSecret: yup.string().required("This field is required"),
    avatar: yup.string().required("This field is required"),
  });

  const onSubmit = () => {
    form.current.submitForm();
  };

  return (
    <Modal
      onCancel={onCancel}
      title="Create Integration"
      visible={isVisible}
      footer={null}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleDone(values)}
        innerRef={form}
      >
        {({ handleSubmit, submitCount, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                required
                label="Avatar"
                component={ImageUpload}
                name="avatar"
                onDone={(file) => {
                  setFieldValue(
                    "avatar",
                    get(file, "response.file.Location", null)
                  );
                }}
                width="100px"
                height="80px"
              />
              <Field
                required={true}
                label="Name"
                name="name"
                component={AntInput}
              />
              <Field
                required={true}
                label="Description"
                name="description"
                component={AntTextArea}
              />
              <Field
                required={true}
                label="Client ID"
                name="clientId"
                component={AntInput}
              />
              <Field
                required={true}
                label="Client Secret"
                name="clientSecret"
                component={AntInput}
              />
              <Button type="primary" onClick={onSubmit}>
                Accept
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default IntegrationModal;
