import gql from "graphql-tag";

export const GET_WORKSPACES = gql`
  query {
    getWorkspaces {
      _id
      slack_broadcast_channel
      account {
        stripe
      }
      project {
        _id
        title
        description
        color
        tags
        created_by {
          _id
          full_name
          email
          avatar
          addons {
            _id
            name
            cost
            currency
          }
        }
        manager {
          _id
          email
          full_name
          avatar
        }
        client {
          _id
          email
          full_name
          currency
          company
          address
          user {
            email
            full_name
            avatar
            stripe
          }
        }
        members {
          _id
          email
          full_name
          avatar
        }

        created_at
        updated_at
      }
    }
  }
`;
