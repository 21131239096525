import gql from "graphql-tag";

export const GET_REPORTS = gql`
  query {
    getReports {
      _id
      reportURL
      title
      start_date
      end_date
      projects {
        _id
        title
        color
      }
      members {
        _id
        full_name
        avatar
        email
      }
    }
  }
`;
