import React, { useState, useEffect } from "react";
import Auth from "./Auth";
import { Form, Formik } from "formik";
import { useRecoilState } from "recoil";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import MondayLogo from "../../../common/assets/mondayLogo.png";
import { userSession } from "recoil/atoms/User/UserSession";
import { Menu, Button } from "antd";
import Card from "../Card";
import { useMutation, useQuery } from "@apollo/client";
import { parseWorkspaceIntegration, setWorkspaceIntegration } from "../utils";
import View from "./View";
import { CONNECT_MONDAY } from "graphql/mutations/Monday/connectMonday";
import { UPDATE_MONDAY } from "graphql/mutations/Monday/updateMonday";
import _ from "lodash";
import { GET_MONDAY_ITEMS } from "graphql/queries/Monday/getMondayItems";
import utility from "common/utility";
import { useAuthWidgets } from "../common/useAuthWidgets";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EnlargeWidget from "components/Modals/Enlarge";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";

const Monday = ({ location }) => {
  const [user] = useRecoilState(userSession);
  const [selected, setSelected] = useState("board");

  const [authenticated, setAuthenticated] = useState(false);
  const { id } = queryString.parse(location.search);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  useAuthWidgets(authenticated, "MONDAY", id);
  const { createIntegration, loading } = useCreateIntegrationHook();

  const { loading: widgetLoading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "monday", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setAuthenticated(getWorkspaceIntegration?.access_token);
      }
    },
  });

  const [updateMonday, { loading: updateLoading }] = useMutation(
    UPDATE_MONDAY,
    {
      refetchQueries: [
        {
          query: GET_MONDAY_ITEMS,
          variables: { input: { workspace: id } },
        },
      ],
    }
  );

  useEffect(() => {
    const { integration, code, id } = queryString.parse(location.search);
    /// handle auth
    if (integration === "monday" && code) {
      createIntegration({
        type: "monday",
        access_token: code,
        workspace: id,
      }).then(() => {
        setSelected("settings");
      });
    }
  }, [location]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const updateSetting = (v) => {
    setSelected("board");
    var result = _.omit(v.settings, ["_id", "access_token"]);
    updateMonday({
      variables: { input: { workspace: id, ...result } },
    });
  };

  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "monday" });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="board">
        <a>View</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7547995640599-Monday"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );
  const bodyHeight = selected === "settings" ? "70%" : "83%";

  return authenticated ? (
    <Formik
      enableReinitialize={true}
      validationSchema={null}
      initialValues={{
        settings: {
          sync: [],
        },
      }}
      render={({ values, handleSubmit }) => (
        <Form>
          <Card
            bodyStyle={{
              height: enlargeModal.visible ? " 90% " : bodyHeight,
              overflow: "auto",
            }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: 25 }}
                  className="mr-3"
                  src={MondayLogo}
                  alt="Monday"
                />
                <h5>Monday</h5>
              </div>
            }
            action={[
              selected === "settings" && (
                <div className="text-right pr-3">
                  <Button type="default" onClick={() => updateSetting(values)}>
                    Save
                  </Button>
                </div>
              ),
            ]}
            menu={menu}
          >
            <View view={selected} setSelected={setSelected} />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth
      setAuthenticated={setAuthenticated}
      isAuthenticated={authenticated}
      loading={widgetLoading || loading}
      updateMonday={updateMonday}
    />
  );
};

export default withRouter(Monday);
