import gql from "graphql-tag";

export const GET_ACTIVITY_BY_PROJECT = gql`
  query ($days: Int!) {
    getActivityByProject(days: $days) {
      _id
      title
      description
      total_time
      status {
        isPositive
        value
        status
      }
      total_activities
      values {
        time
        date
      }
      activities {
        title
        time {
          start
          total_time
        }
      }
    }
  }
`;
