import gql from "graphql-tag";

export const GET_TODAY_WIDGET_DATA = gql`
  query {
    getTodayWidgetData {
      totalTime
      workedTime
      remainingTime
    }
  }
`;
