import gql from "graphql-tag";

export const REMOVE_INTEGRATION = gql`
  mutation ($integrationId: ID!) {
    removeIntegration(integrationId: $integrationId) {
      _id
      name
      access_token
    }
  }
`;
