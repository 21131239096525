import { Button, Carousel, Modal, Select, Typography } from "antd";
import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import AllModeSvg from "common/assets/modes/mode.svg";
import InvestModeSvg from "common/assets/modes/invest.svg";
import CommandModeSvg from "common/assets/modes/command.svg";
import FocusModeSvg from "common/assets/modes/focus.svg";
import {
  ArrowRight,
  Bank,
  CaretLeft,
  CaretRight,
  Crosshair,
  FlagBanner,
  Question,
} from "@phosphor-icons/react";
import { UPDATE_SETTINGS_BY_USERID } from "graphql/mutations/user/updateSettingByUserId";
import _ from "lodash";
import { GET_USER } from "graphql/queries/getUser";
import { useMutation } from "@apollo/client";
import utility from "common/utility";

const CustomDots = ({ carouselStep }) => {
  const dotStyles = Array.from({ length: 4 }, (_, index) => ({
    width: index === carouselStep ? "9px" : "8px",
    height: index === carouselStep ? "9px" : "8px",
    borderRadius: "50%",
    backgroundColor: index === carouselStep ? "#000" : "#ccc",
    display: "inline-block",
    margin: "0 5px",
  }));

  return (
    <div style={{ textAlign: "center" }}>
      {dotStyles.map((style, index) => (
        <span key={index} style={style} />
      ))}
    </div>
  );
};

const { Text } = Typography;
const SelectModeModal = ({ isVisible, setVisible, callback, user }) => {
  const carouselRef = useRef();
  const [carouselStep, setCarouselStep] = useState(0);
  const [selectedMode, setSelectedMode] = useState("Command");

  const [updateUserSetting, { loading }] = useMutation(
    UPDATE_SETTINGS_BY_USERID,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        if (callback) callback();
        setVisible(false);
      },
      refetchQueries: [GET_USER],
    }
  );

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };
  const getModalTitle = () => {
    switch (carouselStep) {
      case 0:
        return "Welcome to Windu Modes! ";
      case 1:
        return "Command Mode ";
      case 2:
        return "Focus Mode ";
      case 3:
        return "Invest Mode ";

      default:
        return "Welcome to Windu Modes! ";
    }
  };

  const handleConitnue = () => {
    updateUserSetting({
      variables: {
        input: {
          mode: [selectedMode],
        },
      },
    });
  };

  const getModeSelectOptions = () => {
    if (user?.client_in_projects > 0) {
      return [
        {
          label: (
            <div className="flex items-center gap-1">
              <FlagBanner size={24} color="#312b2b" />
              <Text> Command</Text>
            </div>
          ),
          value: "Command",
        },
        {
          label: (
            <div className="flex items-center gap-1">
              <Crosshair size={24} color="#312b2b" />
              <Text> Focus</Text>
            </div>
          ),
          value: "Focus",
        },
        {
          label: (
            <div className="flex items-center gap-1">
              <Bank size={24} color="#312b2b" />
              <Text> Invest</Text>
            </div>
          ),
          value: "Invest",
        },
      ];
    } else {
      return [
        {
          label: (
            <div className="flex items-center gap-1">
              <FlagBanner size={24} color="#312b2b" />
              <Text> Command</Text>
            </div>
          ),
          value: "Command",
        },
        {
          label: (
            <div className="flex items-center gap-1">
              <Crosshair size={24} color="#312b2b" />
              <Text> Focus</Text>
            </div>
          ),
          value: "Focus",
        },
      ];
    }
  };

  return (
    <Modal
      visible={isVisible}
      title={
        <div className="flex items-center">
          <Text>{getModalTitle()}</Text>

          <a
            target="_blank"
            href="https://help.windu.io/en/article/windu-modes-sqifsu/?bust=1701370023548"
            className="ml-2"
          >
            <Question className="ml-2" />
          </a>
        </div>
      }
      width={600}
      style={{ minHeight: 700 }}
      closable
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Carousel
        ref={carouselRef}
        dots={false}
        beforeChange={(from, to) => setCarouselStep(to)}
      >
        <div>
          <div className="flex flex-col mt-4 w-full items-center ">
            <div className="flex items-center gap-2 w-full justify-between">
              <CaretLeft
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handlePrev}
              />

              <img className="w-4/5" src={AllModeSvg} alt="Windu modes" />
              <CaretRight
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handleNext}
              />
            </div>

            <Text className="text-base mt-4">
              Windu Modes shape your experience, bundling essential features
              based on roles. Seamlessly switch between Command, Focus, and
              Invest modes for versatile functionality.
            </Text>
          </div>
        </div>
        <div>
          <div className="flex flex-col mt-4 w-full items-center">
            <div className="flex items-center gap-2 w-full justify-between">
              <CaretLeft
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handlePrev}
              />

              <img className="w-4/5" src={CommandModeSvg} alt="Windu modes" />
              <CaretRight
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handleNext}
              />
            </div>

            <div className="w-full items-start">
              <Text className="text-base mt-2">
                Take Command of your product, project and team with helpful
                features for ideation, management and operations.
              </Text>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col mt-4 w-full items-center">
            <div className="flex items-center gap-2 w-full justify-between">
              <CaretLeft
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handlePrev}
              />

              <img className="w-4/5" src={FocusModeSvg} alt="Windu modes" />
              <CaretRight
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handleNext}
              />
            </div>

            <div className="w-full items-start">
              <Text className="text-base mt-2">
                Improve your productivity and focus on your projects
              </Text>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col mt-4 w-full items-center">
            <div className="flex items-center gap-2 w-full justify-between">
              <CaretLeft
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handlePrev}
              />
              <img className="w-4/5" src={InvestModeSvg} alt="Windu modes" />
              <CaretRight
                className="cursor-pointer"
                size={34}
                color="#363636"
                onClick={handleNext}
              />
            </div>

            <div className="w-full items-start">
              <Text className="text-base mt-2">
                Process and pay your financial obligations
              </Text>
            </div>
          </div>
        </div>
      </Carousel>
      <div className="w-full justify-center my-2">
        <CustomDots carouselStep={carouselStep} />
      </div>
      <div className={styles.selectModeDiv}>
        <div>
          <div className="flex items-center gap-2">
            <Text className="text-white text-base" strong>
              Select Mode
            </Text>
            <ArrowRight size={24} color="white" />
          </div>
          <Text className="text-white ">
            Use the dropdown to select your preferred mode
          </Text>
        </div>

        <div>
          <Select
            size="large"
            className=" w-40"
            placeholder="Select mode"
            value={selectedMode}
            onChange={(v) => setSelectedMode(v)}
            options={getModeSelectOptions()}
          />
        </div>
      </div>

      <div className="flex justify-end mt-3">
        <Button
          className={`${styles.nextButton} text-white  rounded-lg outline-none border-none`}
          size="large"
          onClick={handleConitnue}
          loading={loading}
        >
          Set Mode
        </Button>
      </div>
    </Modal>
  );
};

export default SelectModeModal;
