import gql from "graphql-tag";

export const GET_ACTIVITY_COMMENTS = gql`
  query ($input: getCommentInput) {
    getActivityComments(input: $input) {
      _id
      content
      created_at
      edited
      created_by {
        _id
        full_name
        email
        avatar
      }
    }
  }
`;
