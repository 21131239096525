import gql from "graphql-tag";

export const CREATE_EXAMPLE_PROJECT = gql`
  mutation {
    createExampleProject {
      _id
      title
    }
  }
`;
