import React, { useEffect } from "react";
import ProjectsList from "./ProjectsList";
import ClientProjectsList from "./ClientProjects";
import { useRecoilValue } from "recoil";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import { Crisp } from "crisp-sdk-web";
import { userSession } from "recoil/atoms/User/UserSession";

const Projects = () => {
  const user = useRecoilValue(userSession);
  // useEffect(() => {
  //   Crisp.session.reset();
  //   Crisp.setTokenId(user?._id);
  //   Crisp.user.setEmail(user?.email || "No email");
  //   Crisp.user.setNickname(user?.full_name || "No name");
  //   Crisp.load();
  //   setTimeout(() => {
  //     Crisp.session.pushEvent("projectPage");
  //   }, 2000);
  // }, []);

  const selectedModeValue = useRecoilValue(selectedMode);

  if (selectedModeValue === "invest") return <ClientProjectsList />;

  return <ProjectsList />;
};

export default Projects;
