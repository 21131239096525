import React from "react";
import { Layout, Row, Col, Typography, Button } from "antd";
import OnboardingSvg from "common/assets/onboard.svg";
import Second from "./Steps/Second";
import First from "./Steps/First";
import Third from "./Steps/Third";
import Four from "./Steps/Four";
import Five from "./Steps/Five";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { get } from "lodash";
import styles from "./styles.module.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import ChoosePlan from "./Steps/ChoosePlan";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";

const { Text } = Typography;

const initialFormValues = {
  expertise: [],
  tools: [],
  weeklyWorkHours: null,
  teamMembers: null,
  funding: null,
};

const validationSchema = [
  Yup.object().shape({
    expertise: Yup.array().min(1, "at least 1").required("required"),
  }),
  Yup.object().shape({
    weeklyWorkHours: Yup.string()
      .nullable()
      .required(`Please select the closest range`),
  }),
  Yup.object().shape({
    teamMembers: Yup.string()
      .nullable()
      .required(`Please select the closest range`),
  }),
  Yup.object().shape({
    companyWorkers: Yup.string()
      .nullable()
      .required(`Please select the closest range`),
  }),
  Yup.object().shape({
    tools: Yup.string().nullable().required(`Please select the closest range`),
  }),
  Yup.object().shape({
    funding: Yup.string()
      .nullable()
      .required(`Please select the closest range`),
  }),
];

const steps = [
  {
    title: "First",
    content: ({ ...props }) => {
      return <First {...props} />;
    },
  },
  {
    title: "Second",
    content: ({ ...props }) => {
      return <Second {...props} />;
    },
  },
  {
    title: "Third",
    content: ({ ...props }) => {
      return <Third {...props} />;
    },
  },
  {
    title: "Four",
    content: ({ ...props }) => {
      return <Four {...props} />;
    },
  },
  {
    title: "Five",
    content: ({ ...props }) => {
      return <Five {...props} />;
    },
  },
  {
    title: "Plan",
    content: ({ ...props }) => {
      return <ChoosePlan {...props} />;
    },
  },
];

const Personalize = ({ onSuccess }) => {
  const [current, setCurrent] = React.useState(0);
  const [lastStep, setLastStep] = React.useState(false);
  const formRef = React.useRef();
  const user = useRecoilValue(userSession);
  const history = useHistory();

  React.useEffect(() => {
    if (user?.created_by_invitation) {
      steps.pop();
    }
  }, []);

  React.useEffect(() => {
    const lastStep = current === steps.length - 1;
    setLastStep(lastStep);
  }, [current]);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      if (lastStep && user?.created_by_invitation) {
        history.push("/quickstart");
        onSuccess();
      }
    },
  });

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleFormSubmit = (v) => {
    if (lastStep) {
      const {
        teamMembers,
        companyWorkers,
        tools,
        weeklyWorkHours,
        expertise,
        funding,
      } = v;
      updateUser({
        variables: {
          input: {
            onboarding_data: {
              teamMembers,
              companyWorkers,
              tools,
              weeklyWorkHours,
              expertise,
              finished: true,
              funding,
            },
          },
        },
      });
    } else {
      setCurrent(current + 1);
    }
  };

  return (
    <Layout className="flex justify-center min-h-screen">
      <Row style={{ height: "100vh" }} className="bg-white">
        <Col xs={24} sm={24} md={14} className="bg-white ">
          <div
            className={`flex flex-col  m-auto ${
              steps[current].title === "Plan" ? "mt-4 w-11/12" : "mt-28 w-3/4"
            }`}
          >
            <div className="flex justify-end w-full">
              <span
                style={{ background: "#FFFBEE" }}
                className="py-2 px-4 rounded-lg mb-4"
              >
                <Text style={{ color: "#F5A623" }}>
                  Step {current + 1} of {steps.length}
                </Text>
              </span>
            </div>
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema[current]}
              onSubmit={handleFormSubmit}
              innerRef={formRef}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({
                submitCount,
                handleSubmit,
                setFieldValue,
                values,
                errors,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div
                      className="steps-content"
                      setFieldValue={setFieldValue}
                    >
                      {steps[current].content({
                        setFieldValue,
                        submitCount,
                        handleSubmit,
                        values,
                        errors,
                        handleFormSubmit,
                      })}
                    </div>
                    {steps[current].title !== "Plan" && (
                      <div className="flex w-full justify-end">
                        {current > 0 && (
                          <Button
                            onClick={() => prev()}
                            type="default"
                            className={`my-4 ml-1  flex items-center p-5`}
                          >
                            Back
                          </Button>
                        )}

                        <Button
                          htmlType="submit"
                          type="primary"
                          className={`my-4 ml-2 outline-none border-none flex items-center ${styles.blackNextButton} p-5`}
                        >
                          {lastStep ? "Done" : "Next"}
                          {!lastStep && (
                            <ArrowRightOutlined className="ml-2 text-lg" />
                          )}
                        </Button>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <Col xs={0} sm={0} md={10} className="m-auto p-5">
          <div
            className=" rounded-xl"
            style={{ background: "#B1A5EB", height: "92vh" }}
          >
            <img
              src={OnboardingSvg}
              alt="windu-onboarding"
              style={{ minHeight: "80vh" }}
              className="p-5"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default Personalize;
