import React, { useCallback, useState } from "react";
import propTypes from "prop-types";
import { debounce, uniqBy, map, toString, isEmpty, filter } from "lodash";
import { useMutation } from "@apollo/client";
import { Button, Divider } from "antd";
import { useApolloClient } from "@apollo/client";
import { Formik, Form, Field } from "formik";
import Modal from "../BaseModal/Modal";
import { AntInput, AntSelect } from "components/FormikCustomInputs";
import { INVITE_CLIENT } from "graphql/mutations/client/inviteClient";
import { GET_REQUESTS } from "graphql/queries/request/getRequests";
import utility from "common/utility";
import Loader from "components/Loader";
import * as yup from "yup";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState } from "recoil";
import { createGoogleEvent } from "api/GoogleAnalytics";
import cc from "currency-codes";
import { AutoComplete, Form as AntForm } from "antd";
import _ from "lodash";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { REGISTER_CLIENT } from "graphql/mutations/client/registerClient";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import { SEARCH_USER } from "graphql/queries/searchUser";
const { Option } = AutoComplete;
const FormItem = AntForm.Item;

const currency = _.map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const InviteClientModal = ({ isVisible, onClose, projectSelected }) => {
  const [user] = useRecoilState(userSession);
  const client = useApolloClient();
  const [clients, setClients] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [address, setAddress] = React.useState("");

  const [inviteClient, { loading }] = useMutation(INVITE_CLIENT, {
    onCompleted({ inviteClient }) {
      utility.setNotification(
        "Success",
        `${inviteClient.email} has been invite to be a client for ${projectSelected.label}`,
        "success",
        "topRight"
      );
      createGoogleEvent(user?._id, "INVITE_CLIENT", "app_engagement");
      form.current.resetForm();
      onClose();
    },
    onError(error) {
      utility.setNotification(
        "Error",
        `${toString(error)}`,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_REQUESTS],
  });

  const [registerClient, { loading: registerClientLoading }] = useMutation(
    REGISTER_CLIENT,
    {
      onCompleted({ registerClient }) {
        utility.setNotification(
          "Success",
          `${registerClient.email} has been invite to be a client for ${projectSelected.label}`,
          "success",
          "topRight"
        );
        createGoogleEvent(user?._id, "INVITE_CLIENT", "app_engagement");
        form.current.resetForm();
        onClose();
      },
      onError(error) {
        utility.setNotification(
          "Error",
          `${toString(error)}`,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_REQUESTS,
        },
        {
          query: GET_WORKSPACES,
        },
      ],
    }
  );

  const initialFormValues = {
    client: [],
    email: "",
    country: "",
    zip: "",
    state: "",
    address: "",
    currency: "USD",
    city: "",
    company: "",
  };

  const form = React.useRef();

  const onSubmit = () => {
    form.current.submitForm();
  };

  const handleDone = (values) => {
    if (isEmpty(values?.client)) {
      const { email, company, address, city, state, country, currency } =
        values;

      if (email === user.email) {
        utility.setNotification(
          "The email provided is not valid.",
          `Please try using a different email address`,
          "warning",
          "topRight"
        );
      } else {
        registerClient({
          variables: {
            input: {
              projectId: projectSelected.value,
              email,
              company,
              address,
              city,
              state,
              country,
              currency,
            },
          },
        });
      }
    } else {
      inviteClient({
        variables: {
          input: { email: values?.client, projectId: projectSelected.value },
        },
      });
    }
  };

  const validationSchema = yup.object().shape(
    {
      client: yup.string().when(["email"], {
        is: (email) => !email,
        then: yup.string().required(),
      }),
      email: yup.string().when(["client"], {
        is: (client) => !client,
        then: yup.string().email().required(),
      }),
      country: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      company: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      zip: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      currency: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      state: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      address: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
      city: yup.string().when("email", {
        is: (email) => !isEmpty(email),
        then: yup.string().required(),
      }),
    },

    [["client", "email"]]
  );

  const handleOnClose = () => {
    form.current.resetForm();
    setClients([]);
    onClose();
  };

  const handleSearch = useCallback(
    debounce(async (v) => {
      if (!isEmpty(v)) {
        setFetching(true);
        const { data } = await client.query({
          query: SEARCH_USER,
          variables: { value: v },
        });

        const res = filter(data?.searchUser, (userFound) => {
          return userFound.email !== user.email;
        });

        setFetching(false);
        setClients(uniqBy(res, "email"));
      } else {
        setClients([]);
      }
    }, 300),
    []
  );

  const setAddressField = async (v, item, setFieldValue) => {
    const fullAddress = v.split(",");

    const country = fullAddress[fullAddress.length - 1];
    const state = fullAddress[fullAddress.length - 2];
    const city = fullAddress[fullAddress.length - 3];

    const street = fullAddress
      .slice(0, fullAddress.length - 3 === 0 ? 1 : fullAddress.length - 3)
      .join();

    const placeId = item.data.placeId;

    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};

    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("country", country);
    setFieldValue("address", street);
    setFieldValue("zip", postalCode);
  };

  return (
    <>
      <Modal
        title={`Invite a client to ${projectSelected.label}`}
        width={500}
        footer={null}
        visible={isVisible}
        onClose={handleOnClose}
        content={
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleDone(values)}
            innerRef={form}
          >
            {({
              handleSubmit,
              submitCount,
              errors,
              touched,
              values,
              setFieldValue,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    component={AntSelect}
                    required
                    label="Invite Windu User"
                    name="client"
                    placeholder="Search by Email"
                    submitCount={submitCount}
                    selectOptions={map(clients, (client) => {
                      return { value: client.email, label: client.email };
                    })}
                    loading={fetching}
                    inputType="select"
                    hasFeedback
                    size="large"
                    filterOption={false}
                    showSearch
                    allowClear
                    notFoundContent={
                      fetching ? (
                        <Loader />
                      ) : (
                        <div className="flex justify-center">No Data</div>
                      )
                    }
                    onSearch={handleSearch}
                    getPopupContainer={(node) => node.parentNode}
                    dropdownRender={(menu) => <div>{menu}</div>}
                  />
                  <Divider>OR</Divider>
                  <div className="flex">
                    <Field
                      component={AntInput}
                      label="Email"
                      name="email"
                      placeholder="Email"
                      required
                      size="large"
                    />
                    <Field
                      component={AntInput}
                      label="Company"
                      name="company"
                      placeholder="Company"
                      required
                      size="large"
                    />
                  </div>

                  <PlacesAutocomplete value={address} onChange={setAddress}>
                    {({ getInputProps, suggestions, loading }) => {
                      const haveTouched = touched["address"];
                      const submitted = submitCount > 0;
                      const hasError = errors["address"];
                      const submittedError = hasError && submitted;
                      const touchedError = hasError && haveTouched;

                      return (
                        <div
                          className="field-container w-full"
                          style={{ margin: "0.25em" }}
                        >
                          <FormItem
                            className="mb-0"
                            help={
                              submittedError || touchedError ? hasError : false
                            }
                            hasFeedback={
                              submitted || haveTouched ? true : false
                            }
                            validateStatus={
                              submittedError || touchedError
                                ? "error"
                                : "success"
                            }
                            label="Address"
                            required
                            labelCol={{ span: 24 }}
                          >
                            <AutoComplete
                              onSearch={(v) =>
                                getInputProps().onChange({
                                  target: { value: v },
                                })
                              }
                              required
                              name="address"
                              value={values.address}
                              onChange={(v) => setFieldValue("address", v)}
                              onSelect={(v, item) =>
                                setAddressField(v, item, setFieldValue)
                              }
                              size="large"
                              placeholder="Address"
                            >
                              {!loading &&
                                suggestions.map((suggestion) => (
                                  <Option
                                    key={suggestion.description}
                                    value={suggestion.description}
                                    data={suggestion}
                                  >
                                    {suggestion.description}
                                  </Option>
                                ))}
                            </AutoComplete>
                          </FormItem>
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>

                  <div className="flex">
                    <Field
                      component={AntInput}
                      label="City"
                      name="city"
                      size="large"
                      placeholder="City"
                      required
                    />
                    <Field
                      component={AntInput}
                      label="State"
                      name="state"
                      size="large"
                      placeholder="State"
                      required
                    />
                    <Field
                      component={AntInput}
                      label="Country"
                      name="country"
                      required
                      size="large"
                      placeholder="Country"
                    />
                  </div>

                  <div className="flex">
                    <Field
                      component={AntInput}
                      label="Zip/Postal Code"
                      placeholder="Zip/Postal Code"
                      name="zip"
                      size="large"
                      required
                    />
                    <Field
                      component={AntSelect}
                      name="currency"
                      label="Currency"
                      placeholder="Currency"
                      submitCount={submitCount}
                      selectOptions={currency}
                      inputType="select"
                      size="large"
                      showSearch
                      required
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <div className="flex justify-center items-center">
            <Button onClick={() => handleOnClose()}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => onSubmit()}
              className="ml-3"
              loading={loading || registerClientLoading}
            >
              Submit
            </Button>
          </div>
        }
      />
    </>
  );
};

InviteClientModal.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
};

export default InviteClientModal;
