import gql from "graphql-tag";

export const UPDATE_CALENDAR_PROJECTS = gql`
  mutation($input: updateCalendarProjectsInput) {
    updateCalendarProjects(input: $input) {
      user {
        email
      }
      calendar_project_subscription {
        project {
          title
        }
        calendar
      }
    }
  }
`;
