import React, { useState } from "react";
import { Table, Icon } from "@fishyvisions/windu-uikit";
import { useQuery, useMutation } from "@apollo/client";
import _, { map, size, truncate, filter } from "lodash";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Pagination,
  Tag,
  Tooltip,
  Typography,
  Switch,
  Space,
} from "antd";
import moment from "moment";
import { GET_USERS_ADMIN } from "graphql/queries/Admin/getUsers";
import { REMOVE_USER } from "graphql/mutations/Admin/removeUser";
import utility from "common/utility";
import EmptyData from "../../components/EmptyData";
import cx from "classnames";
import { DownCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const sizePerPage = 100;
const initialOffset = 0;

const Users = () => {
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    activeUsers: false,
  });

  const [expandedKeys, setExpandedKeys] = React.useState([]);
  const { loading, error, data, refetch } = useQuery(GET_USERS_ADMIN, {
    fetchPolicy: "cache-and-network",
    variables: queryParameters,
  });
  const [removeUser] = useMutation(REMOVE_USER, {
    onCompleted: ({ removeUser }) => {
      utility.setNotification(
        "User removed successfully",
        `Member of ${removeUser.email} has been removed`,
        "success",
        "topRight"
      );
      refetch({ variables: queryParameters });
    },
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    refetchQueries: [],
  });

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const onRemove = (id) => {
    removeUser({ variables: { id } });
  };

  const menu = (id) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <Popconfirm
            placement="topLeft"
            title={"Are you sure you want to delete this user?"}
            onConfirm={() => onRemove(id)}
            okText="Yes"
            cancelText="No"
          >
            Remove User
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Timezone",
      dataIndex: "timezone",
      key: "timezone",
    },

    {
      title: "Current Role",
      dataIndex: "onboarding_data",
      key: "role",
      render: (onboarding_data) => onboarding_data?.defaultRole,
    },
    {
      title: "Purpose",
      dataIndex: "onboarding_data",
      key: "role",
      render: (onboarding_data) => onboarding_data?.purpose,
    },
    {
      title: "Tools",
      dataIndex: "onboarding_data",
      key: "role",
      render: (onboarding_data) =>
        map(onboarding_data?.tools, (item) => <Tag key={item}>{item}</Tag>),
    },
    {
      title: "Industry",
      dataIndex: "onboarding_data",
      key: "role",
      render: (onboarding_data) =>
        map(onboarding_data?.expertise, (item) => <Tag key={item}>{item}</Tag>),
    },
    {
      title: "Redirected",
      dataIndex: "onboarding_data",
      key: "role",
      render: (onboarding_data) => onboarding_data?.redirected,
    },
    {
      title: "Total Activites",
      dataIndex: "activityCount",
      key: "activityCount",
    },
    {
      title: "No. of projects",
      dataIndex: "projects",
      key: "role",
      render: (project) => size(project),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => moment(value).format("MM/DD/YYYY"),
    },
    {
      dataIndex: "",
      key: "x",
      render: ({ _id }) => {
        return (
          <Dropdown
            placement="bottomRight"
            overlay={menu(_id)}
            trigger={["click"]}
          >
            <span
              onClick={(e) => e.preventDefault()}
              className="cursor-pointer"
            >
              <Icon type="Meatball" size="small" />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  const handleExpand = (expanded, { _id }) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, _id]);
    } else {
      setExpandedKeys(filter(expandedKeys, (key) => key !== _id));
    }
  };

  const expandedRowRender = (e) => {
    const projectColumns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        fixed: "left",
        width: 250,
      },
      {
        title: "Total Members",
        key: "members",
        dataIndex: "members",
        width: 150,
        render: (value, rec) => {
          return <Text>{_.size(value)}</Text>;
        },
      },
      {
        title: "Total Time",
        dataIndex: "total_time",
        key: "total_time",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100,
        render: (value) => {
          if (value === "Active") return <Text type="success">{value}</Text>;
          return <Text type="danger">{value}</Text>;
        },
      },
      {
        title: "Owner",
        dataIndex: "created_by",
        key: "created_by",
        width: 250,
        render: (values) => {
          return (
            <React.Fragment>
              <div className="flex items-center">
                <Title level={5}>
                  {truncate(values?.email, { length: 25 })}
                </Title>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Created",
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        render: (value) => moment(value).format("MM/DD/YYYY"),
      },
    ];
    return (
      <div>
        <Table
          rowKey="_id"
          columns={projectColumns}
          dataSource={map(e.projects, (project) => ({
            ...project,
            key: project._id,
          }))}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      <Row>
        <Space
          align="center"
          style={{
            marginBottom: 16,
          }}
        >
          Active Users:{" "}
          <Switch
            checked={queryParameters.activeUsers}
            onChange={(v) => {
              setQueryParameters({ ...queryParameters, activeUsers: v });
            }}
          />
        </Space>
      </Row>

      <Row>
        <Col xs={24}>
          <Table
            locale={{
              emptyText: <EmptyData />,
            }}
            scroll={{ y: 400 }}
            columns={columns}
            dataSource={map(data?.getUsers?.data, (user) => ({
              ...user,
              key: user._id,
            }))}
            pagination={false}
            loading={loading}
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedKeys,
              rowExpandable: (record) => size(record.projects) > 0,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (expanded && size(record.projects) < 1) onExpand(record);
                if (size(record.projects) >= 1) {
                  return (
                    <Tooltip title={expanded ? "Close" : "See project details"}>
                      <span
                        onClick={(e) => onExpand(record, e)}
                        className={cx("cursor-pointer", {
                          "expand-icon-rotate": expanded,
                        })}
                      >
                        <DownCircleOutlined className="icon-medium" />
                      </span>
                    </Tooltip>
                  );
                }
              },
            }}
            onExpand={handleExpand}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Pagination
            className="my-4 text-center"
            current={data?.getUsers?.pagination?.current_page}
            onChange={onPageChange}
            total={data?.getUsers?.pagination?.total_data || 0}
            pageSize={queryParameters.size}
            showSizeChanger
            pageSizeOptions={["5", "10", "20", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
            }
            showQuickJumper={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default Users;
