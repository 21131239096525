import * as yup from "yup";
import { parsePhoneNumber } from "react-phone-number-input";

const validationSchema = yup.object().shape({
  fullName: yup.string().trim().required("This field can't be empty"),
  phone_number: yup
    .string()
    .trim()
    .required("This field can't be empty")
    .test("valid-phone", "Phone number is not valid", function (value) {
      try {
        const phone = parsePhoneNumber(value);
        return phone !== undefined;
      } catch (error) {
        return false;
      }
    }),
});

export default validationSchema;
