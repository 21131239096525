import React, { useEffect, useState } from "react";
import { Row, Button, Divider, Badge, Card, Typography } from "antd";
import SelectProjects from "components/Fields/SelectProjects";
import { AntSelect } from "components/FormikCustomInputs";
import _ from "lodash";
import { Field, FieldArray, useFormikContext } from "formik";
import AddSync from "../common/AddSync";
import RemoveSync from "../common/RemoveSync";
import { useLazyQuery, useQuery } from "@apollo/client";
import utility from "common/utility";
import { GET_TODOIST_PROJECTS } from "graphql/queries/Todoist/getTodoistProject";

const Settings = ({ workspaceId }) => {
  const { values, setFieldValue } = useFormikContext();
  const [todoistProjects, setTodoistProjects] = useState([]);

  const { data, loading } = useQuery(GET_TODOIST_PROJECTS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: workspaceId } },
    onCompleted: ({ getTodoistProjects }) => {
      const options = getTodoistProjects.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }));
      setTodoistProjects(options);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const handleChange = (v, index) => {
    const selectedProject = _.filter(
      todoistProjects,
      (project) => project.value === v
    );
    if (!_.isEmpty(values?.settings?.sync)) {
      setFieldValue(`settings.sync[${index}]`, {
        ...values?.settings?.sync[index],
        todoist_project: v,
        todoist_project_label: selectedProject[0]?.label,
      });
    }
  };

  return (
    <div>
      <FieldArray
        className="m-0"
        name="settings.sync"
        render={(arrayHelpers) => {
          const sync = values?.settings?.sync;
          const isGreaterThanZero = sync?.length > 0;
          return (
            <div>
              {sync && isGreaterThanZero ? (
                sync.map((e, index) => (
                  <div>
                    <div className="flex items-center">
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.todoist_project`}
                        label="Select the list"
                        placeholder="Project A"
                        selectOptions={todoistProjects}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handleChange(v, index)}
                      />
                      <Divider
                        style={{
                          width: 15,
                          minWidth: 15,
                          borderColor: "#F5A623",
                          marginTop: 60,
                        }}
                        dashed
                      />

                      <SelectProjects
                        size={1000}
                        name={`settings.sync.${index}.windu_project`}
                        label="Select a Project"
                        placeholder="Project B"
                      />
                      <Button
                        style={{
                          fontSize: 22,
                          marginTop: "15px",
                          color: "#644ACB",
                        }}
                        icon={<RemoveSync />}
                        type="link"
                        onClick={() => arrayHelpers.remove(index)}
                      ></Button>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <div className="flex items-center">
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].todoist_project`}
                      label="Select the list"
                      placeholder="Project A"
                      selectOptions={todoistProjects}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handleChange(v, 0)}
                    />
                    <Divider
                      style={{
                        width: 15,
                        minWidth: 15,
                        borderColor: "#F5A623",
                        marginTop: 60,
                      }}
                      dashed
                    />

                    <SelectProjects
                      size={1000}
                      name={`settings.sync[0]?.windu_project`}
                      label="Select a Project"
                      placeholder="Project B"
                    />
                  </div>
                  <Typography.Text strong>Select Column</Typography.Text>
                </div>
              )}
              <Button
                className="mt-3 flex items-center"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    todoist_project: "",
                    windu_project: "",
                    todoist_project_label: "",
                  })
                }
              >
                Add New Project
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Settings;
