import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FlagOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Card,
  Typography,
  Button,
  Popover,
  Row,
  Form as AntForm,
  Col,
  Upload,
  Progress,
  Dropdown,
  Spin,
  Checkbox,
  Input,
  Popconfirm,
  Tooltip,
  Divider,
  Badge,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import _ from "lodash";
import { Form, Field, useFormikContext } from "formik";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  AntDatePicker,
  AntInput,
  AntSelect,
} from "components/FormikCustomInputs";
import { GET_PROJECT_TAGS } from "graphql/queries/projects/getProjectTags";
import Loader from "components/Loader";
import { useLazyQuery, useMutation } from "@apollo/client";
import utility from "common/utility";
import { TimeBoxField } from "components/CustomComponents/TimeboxInput";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import UserAvatar from "components/UserAvatar";
import CalendarPicker from "components/CalendarPicker";
import AssigneSelectDropdown from "./AssigneSelectDropdown";
import {
  CaretUpDown,
  Checks,
  Eye,
  EyeSlash,
  MagicWand,
  Plus,
  Question,
  Strategy,
  XCircle,
} from "@phosphor-icons/react";
import { getDuplicateTasks } from "api/GetDuplicates";
import PlaybookView from "./PlaybookView";
import { REMOVE_PLAYBOOK } from "graphql/mutations/Playbook/removePlaybook";

const gutter = [10, 0];
const { Text } = Typography;

const toolbarOptions = {
  options: ["inline", "list"],
};

const NewActivityContent = ({
  setVisibleView,
  visibleView,
  form,
  onClose,
  progress,
  creating,
  title,
  editingItem,
  backlogGroups,
  fromPlanned,
  fileSources,
  setFileSources,
  setDuplicateTasks,
  showDuplicateHeader,
  setShowDuplicateHeader,
  filteredActivities,
  backlogId,
  setEditingItem,
  backlogRefetch,
}) => {
  const [editorState, seteditorState] = useState();
  const [projectTags, setProjectTags] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [fetching, setFetching] = useState(false);
  const [workspace, setWorkspace] = useState();
  const workspaceData = useRecoilValue(workspaceSelected);

  const [playbookSelected, setPlaybookSelected] = useState(false);
  const [playbookView, setPlaybookView] = useState(false);

  const [timebox, setTimebox] = useState("");
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  useEffect(() => {
    if (fromPlanned) {
      setWorkspace(editingItem.data?.backlog?.workspace);
    } else {
      setWorkspace(workspaceData);
    }
  }, [workspaceData]);

  const { values, setFieldValue, submitCount, errors, handleSubmit } =
    useFormikContext();

  const props = {
    name: "file",
    multiple: true,
    accept: ".txt, .doc, .docx, .jpg, .jpeg, .png, .pdf",
    customRequest: ({ onSuccess }) => {
      onSuccess("ok");
    },
    onChange({ fileList }) {
      setFiles((prevFiles) => _.uniqBy([...prevFiles, ...fileList], "uid"));
    },
  };

  useEffect(() => {
    if (!_.isEmpty(editingItem?.data?.fileSources)) {
      setFileSources(editingItem?.data?.fileSources);
    }
    if (!_.isEmpty(editingItem?.data?.timebox)) {
      const totalMins = utility.convertTimeboxInMins(
        editingItem?.data?.timebox
      );
      setTimebox(totalMins);
    }
    if (!_.isEmpty(editingItem) && !_.isEmpty(editingItem.data?.details)) {
      seteditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(editingItem.data?.details))
        )
      );
    } else {
      seteditorState(EditorState.createEmpty());
    }

    if (!_.isEmpty(editingItem?.data?.tasks)) {
      setPlaybookSelected(false);
    } else if (!_.isEmpty(editingItem?.data?.playbook)) {
      setPlaybookSelected(true);
    }
  }, [editingItem]);

  const [filterSearchUser, setFilterSearchUser] = React.useState([]);

  useEffect(() => {
    if (files) {
      setFieldValue("files", files);
    }
  }, [files]);

  const [removePlaybookMutation, { loading: removingPlaybook }] = useMutation(
    REMOVE_PLAYBOOK,
    {
      onCompleted: ({ removePlaybook }) => {
        setEditingItem({ data: removePlaybook });
        backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    }
  );

  const getDefaultUsers = () => {
    const assigned = _.get(editingItem, "data.assigned", null);
    if (workspace) {
      const {
        project: { manager, members, created_by },
      } = workspace;
      const allMembers = _.cloneDeep(members);
      allMembers.push(created_by);

      if (manager) {
        allMembers.push(manager);
      }

      const gatherMembers = _.uniqBy([...new Set([...allMembers])], "email");

      if (gatherMembers) return setFilterSearchUser(gatherMembers);

      return [];
    } else if (assigned) {
      return setFilterSearchUser([{ ...assigned }]);
    } else return setFilterSearchUser([]);
  };
  useEffect(() => {
    getDefaultUsers();
  }, [workspace]);

  const handleSearch = (v) => {
    const { manager, members, created_by } = workspace?.project;
    const allMembers = [...members, created_by];

    if (manager) {
      allMembers.push(manager);
    }

    const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");

    const matches = gatherMembers.filter(
      (member) =>
        new RegExp(v, "i").test(member.full_name) ||
        new RegExp(v, "i").test(member.email)
    );

    setFilterSearchUser(_.isEmpty(v) ? gatherMembers : matches);
  };

  const [getProjectTags] = useLazyQuery(GET_PROJECT_TAGS, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjectTags }) => {
      const tags = getProjectTags?.tags || [];
      setProjectTags(tags);
    },
  });

  const onSubmit = () => form.current.submitForm();

  useEffect(() => {
    if (workspace) {
      getProjectTags({
        variables: { input: { projectId: workspace?.project?._id } },
      });
      getDuplicateTasks(
        {
          title: "Unleash the Digital Dragons.",
          description: "Prepare the server to handle mythical digital beasts",
          tags: [],
        },
        workspace?._id
      );
    }
  }, [workspace]);

  const handleOnClose = () => {
    onClose();
  };

  const editorStateHandler = (v) => {
    seteditorState(v);
    setFieldValue("details", v);
    getDuplicatesRequest(
      values?.title,
      v,
      values?.selectedTags,
      values?.tasks,
      workspace
    );
  };

  const getStatusClassNames = (status) => {
    switch (status) {
      case 1:
        // low
        return styles.lowPrioritySelect;
      case 2:
        // mid
        return styles.midPrioritySelect;
      case 3:
        // high
        return styles.highPrioritySelect;

      default:
        return styles.prioritySelect;
    }
  };

  const getDuplicatesRequest = useCallback(
    _.debounce(async (title, details, tags, tasks, workspace) => {
      if (_.isEmpty(editingItem)) {
        // Check if editingItem is not empty

        const contentData = !_.isEmpty(details)
          ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
          : "";
        const contentInText = utility.getPlainText(contentData);

        const descriptionWithTasks = tasks.reduce(
          (accumulator, task, index) => {
            if (index > 0) {
              accumulator += " ";
            }

            if (task.description) {
              accumulator += task.description;
            }

            return accumulator;
          },
          contentInText
        );

        if (title && contentInText?.length > 50) {
          const new_task = {
            title,
            description: descriptionWithTasks,
            tags,
          };

          setDuplicateLoading(true);
          const duplicateTasks = await getDuplicateTasks(
            new_task,
            workspace?._id
          );
          setDuplicateLoading(false);
          if (!_.isEmpty(duplicateTasks)) {
            setDuplicateTasks(duplicateTasks);
          }
        }
      }
    }, 1500),
    [editingItem]
  );

  const magicWandStyle = () => {
    const contentData = !_.isEmpty(values?.details)
      ? JSON.stringify(convertToRaw(values?.details?.getCurrentContent()))
      : "";
    const contentInText = utility.getPlainText(contentData);

    if (_.isEmpty(values?.title) || contentInText.length < 50) {
      return {
        color: "#344054",
        border: "1px solid #D9DBDE",
        background: "#F7F7F7",
        borderRadius: 4,
      };
    } else {
      if (_.isEmpty(filteredActivities)) {
        return {
          color: "white",
          border: "1px solid #62C377",
          background: "#8BCE99",
          borderRadius: 4,
        };
      } else {
        return {
          color: "white",
          border: "1px solid #F00",
          background: "#FF7575",
          borderRadius: 4,
        };
      }
    }
  };

  const ActivityTasks = () => {
    const handleAddNewTask = () => {
      setFieldValue("tasks", [...values?.tasks, newTask]);
      setNewTask({
        _id: _.uniqueId("newtask-"),
        description: null,
        planned: null,
        assigned: null,
        completed: false,
      });
      getDuplicatesRequest(
        values?.title,
        values?.details,
        values?.selectedTags,
        [...values?.tasks, newTask],
        workspace
      );
    };
    const [newTask, setNewTask] = useState({
      _id: _.uniqueId("newtask-"),
      description: null,
      planned: null,
      assigned: null,
      completed: false,
    });
    return (
      <>
        <div className="flex items-center mb-2">
          <Checks size={26} style={{ color: "#7CBF7E" }} />
          <Text className="ml-2">Tasks</Text>
        </div>
        {/* new task */}
        <div className={styles.newTaskDiv}>
          <div>
            <div className="flex items-start">
              <Checkbox
                defaultChecked={newTask.completed}
                checked={newTask.completed}
                onChange={(v) =>
                  setNewTask({ ...newTask, completed: v.target.checked })
                }
              />
              <div className="ml-3 flex-1">
                <Input
                  value={newTask.description}
                  spellCheck={true}
                  onChange={(v) => {
                    setNewTask({
                      ...newTask,
                      description: v.target?.value,
                    });
                  }}
                />
                <div className="flex justify-between mt-1 ml-1 ">
                  <div className="flex items-center ">
                    <Dropdown
                      trigger={["click"]}
                      overlay={() => (
                        <AssigneSelectDropdown
                          handleSearch={handleSearch}
                          values={values}
                          filterSearchUser={filterSearchUser}
                          setValue={(v) =>
                            setNewTask({ ...newTask, assigned: v })
                          }
                          value={newTask?.assigned}
                        />
                      )}
                    >
                      {newTask?.assigned ? (
                        <div className="cursor-pointer">
                          <UserAvatar size={24} user={newTask?.assigned} />
                          <Text
                            className="ml-2 text-xs underline"
                            style={{ color: "#A5ACB8" }}
                          >
                            {newTask?.assigned?.full_name}
                          </Text>
                        </div>
                      ) : (
                        <div className="flex items-center cursor-pointer">
                          <UserAddOutlined style={{ color: "#A5ACB8" }} />
                          <Text
                            style={{ color: "#A5ACB8" }}
                            className="text-xs ml-1 underline"
                          >
                            Assignee
                          </Text>
                        </div>
                      )}
                    </Dropdown>
                    <div
                      className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                    >
                      <CalendarPicker
                        initialDate={moment()}
                        onDateChange={(date) =>
                          setNewTask({ ...newTask, planned: date })
                        }
                        label={
                          <Text
                            className="text-xs underline"
                            style={{ color: "#A5ACB8" }}
                          >
                            {newTask?.planned
                              ? moment(newTask?.planned).format("dddd, MMMM D")
                              : "Schedule"}
                          </Text>
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      icon={<CheckOutlined />}
                      type="primary"
                      size="small"
                      onClick={handleAddNewTask}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* display the tasks */}
        {_.map(values?.tasks, (task) => {
          return (
            <div className={styles.newTaskDiv}>
              <div>
                <div className="flex items-start">
                  <Checkbox
                    defaultChecked={task.completed}
                    onChange={(v) => {
                      const updatedTasks = values.tasks.map((t) =>
                        t._id === task._id
                          ? { ...t, completed: v.target.checked }
                          : t
                      );
                      setFieldValue("tasks", updatedTasks);
                    }}
                  />
                  <div className="ml-3 flex-1">
                    <div>
                      <Text
                        editable={{
                          onChange: (newDescription) => {
                            const updatedTasks = values.tasks.map((t) =>
                              t._id === task._id
                                ? { ...t, description: newDescription }
                                : t
                            );
                            setFieldValue("tasks", updatedTasks);
                          },
                          icon: <EditOutlined style={{ color: "#A5ACB8" }} />,
                        }}
                      >
                        {task?.description}
                      </Text>

                      <Popconfirm
                        placement="topRight"
                        title="Are you sure to delete this task?"
                        onConfirm={() => {
                          const updatedTasks = values.tasks.filter(
                            (t) => t._id !== task._id
                          );
                          setFieldValue("tasks", updatedTasks);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title="Remove task">
                          <DeleteOutlined
                            style={{ color: "#A5ACB8" }}
                            className="ml-2"
                          />
                        </Tooltip>
                      </Popconfirm>
                    </div>

                    <div className="flex items-center mt-1 mr-1 justify-end ">
                      <div className="flex items-center mr-2">
                        <CalendarPicker
                          initialDate={task?.planned ? task.planned : moment()}
                          onDateChange={(date) => {
                            const updatedTasks = values.tasks.map((t) =>
                              t._id === task._id ? { ...t, planned: date } : t
                            );
                            setFieldValue("tasks", updatedTasks);
                          }}
                          label={
                            <Text className="text-xs underline">
                              {task?.planned
                                ? moment(task?.planned).format("dddd, MMMM D")
                                : "Schedule"}
                            </Text>
                          }
                        />
                      </div>
                      <Dropdown
                        trigger={["click"]}
                        overlay={() => (
                          <AssigneSelectDropdown
                            handleSearch={handleSearch}
                            values={values}
                            filterSearchUser={filterSearchUser}
                            setValue={(v) => {
                              const updatedTasks = values.tasks.map((t) =>
                                t._id === task._id ? { ...t, assigned: v } : t
                              );
                              setFieldValue("tasks", updatedTasks);
                            }}
                            value={task?.assigned}
                          />
                        )}
                      >
                        {task?.assigned ? (
                          <div className="cursor-pointer">
                            <UserAvatar size={24} user={task?.assigned} />
                            <Text className="ml-2 text-xs underline">
                              {task?.assigned?.full_name}
                            </Text>
                          </div>
                        ) : (
                          <div className="flex items-center cursor-pointer">
                            <UserAddOutlined />
                            <Text className="text-xs ml-1 underline">
                              Assignee
                            </Text>
                          </div>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const removePlaybook = () => {
    if (editingItem) {
      // send a request to remove playbook
      removePlaybookMutation({
        variables: {
          activityId: editingItem?.data?._id,
        },
      });
    } else {
      setFieldValue("playbook", []);
    }
  };

  const PlaybookComponent = () => {
    if (!_.isEmpty(values.playbook)) {
      return (
        <div
          className="flex items-center w-64 rounded-md p-2 mt-3 justify-between"
          style={{ background: "#D9D9D9" }}
        >
          <div className="flex items-center">
            <Badge count={_.size(values?.playbook)}>
              <div style={{ background: "#BCB0F1" }} className="p-2 rounded-md">
                <Strategy size={30} color="white" type="bold" />
              </div>
            </Badge>
            <div
              className="flex flex-col ml-2 cursor-pointer"
              onClick={() => setPlaybookView(true)}
            >
              <Text className="text-base mx-2 text-black ">
                {editingItem?.data?.key}
              </Text>
              <Text className="mx-2 text-black">View Playbook</Text>
            </div>
          </div>

          <div className=" relative -top-7 left-4">
            <Popconfirm
              okText="Yes"
              cancelText="No"
              title={
                <Text>
                  Are you certain? <br /> This action will delete all goals and
                  activities inside the playbook.
                </Text>
              }
              onConfirm={removePlaybook}
            >
              <XCircle
                className=" cursor-pointer"
                size={24}
                color="#848484"
                weight="fill"
              />
            </Popconfirm>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="flex items-center w-64 rounded-md p-2 mt-3 cursor-pointer"
          style={{ border: "2px dotted #D9D9D9" }}
          onClick={() => {
            setPlaybookView(true);
          }}
        >
          <div style={{ background: "#BCB0F1" }} className="p-2 rounded-md">
            <Strategy size={30} color="white" type="bold" />
          </div>
          <Text className="text-base mx-2 text-black">Create Playbook</Text>
          <Plus className="ml-3" size={24} color="#0d0d0d" weight="bold" />
        </div>
      );
    }
  };

  if (playbookView)
    return (
      <PlaybookView
        setPlaybookView={setPlaybookView}
        playbookView={playbookView}
        workspace={workspace}
        filterSearchUser={filterSearchUser}
        setFilterSearchUser={setFilterSearchUser}
        handleSearch={handleSearch}
        projectTags={projectTags}
        mainFormValues={values}
        setMainFormValues={setFieldValue}
        editingItem={editingItem}
        backlogId={backlogId}
        setEditingItem={setEditingItem}
        backlogRefetch={backlogRefetch}
      />
    );

  return (
    <Card
      title={title}
      bodyStyle={{ padding: "14px 28px" }}
      extra={
        <div className="flex items-center">
          {_.isEmpty(editingItem) && (
            <div className={styles.duplicateBoxDiv}>
              <MagicWand style={magicWandStyle()} size={22} />
              <div className="ml-1 flex items-center">
                <Text strong>{_.size(filteredActivities)}</Text>
                <Text style={{ color: "#808080" }} className="ml-1">
                  Duplicate
                </Text>
              </div>
              <Popover
                title="Duplicate Activity"
                content={
                  <div style={{ maxWidth: 320 }}>
                    <Text>
                      Our helpful AI feature can prevent you from adding the
                      same activity twice in your workspace. Just remember to
                      include a title and a description with at least 50
                      characters, and it'll do the rest! 😊🤖
                    </Text>
                  </div>
                }
              >
                <Question size={22} />
              </Popover>

              {!_.isEmpty(filteredActivities) ? (
                <div
                  style={{ borderLeft: "1px solid #808080 " }}
                  className="pl-1"
                >
                  {!showDuplicateHeader ? (
                    <Tooltip title="View duplicate">
                      <EyeSlash
                        onClick={() => {
                          setShowDuplicateHeader(true);
                          setVisibleView("duplicate");
                        }}
                        className="cursor-pointer"
                        size={22}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Hide Duplicate">
                      <Eye
                        onClick={() => {
                          setShowDuplicateHeader(false);
                          setVisibleView("new");
                        }}
                        size={22}
                        className="cursor-pointer"
                      />
                    </Tooltip>
                  )}
                </div>
              ) : duplicateLoading ? (
                <LoadingOutlined style={{ color: "#24B874" }} />
              ) : (
                ""
              )}
            </div>
          )}

          {visibleView === "new" ? (
            <div className="flex items-center">
              <CloseOutlined className="ml-3" onClick={handleOnClose} />
            </div>
          ) : (
            <DownOutlined onClick={() => setVisibleView("new")} />
          )}
        </div>
      }
      className={visibleView === "duplicate" ? styles.hideBodyStyle : "mt-5"}
    >
      <div style={{ display: visibleView !== "new" && "none" }}>
        <Form onSubmit={handleSubmit} className={styles.mainFrom}>
          <Row gutter={gutter}>
            <Col
              xs={14}
              style={{ height: 510, overflowX: "hidden", paddingBottom: 14 }}
            >
              <Field
                placeholder="Activity title"
                required={true}
                label="Title"
                name="title"
                size="large"
                hasFeedback={false}
                component={AntInput}
                spellCheck="true"
                help={false}
                style={{ marginBottom: "0px" }}
                onCallBackChange={(v) => {
                  getDuplicatesRequest(
                    v,
                    values?.details,
                    values?.selectedTags,
                    values?.tasks,
                    workspace
                  );
                }}
              />
              <div
                className={`${styles.editorDiv} ${
                  submitCount > 0 && errors.details && styles.editorErrorDiv
                } `}
              >
                <Field
                  placeholder="What are you going to accomplish?"
                  required
                  render={({ field }) => (
                    <AntForm.Item
                      required
                      labelCol={{ span: 24 }}
                      label="Details"
                      validateStatus={
                        submitCount > 0 && errors.details ? "error" : ""
                      }
                      help={
                        submitCount > 0 && errors.details ? errors.details : ""
                      }
                    >
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(editorState) =>
                          editorStateHandler(editorState)
                        }
                        wrapperClassName="flex flex-col-reverse"
                        placeholder="What are you going to accomplish?"
                        toolbar={toolbarOptions}
                        spellCheck="true"
                      />
                    </AntForm.Item>
                  )}
                />
              </div>

              {_.isEmpty(values?.tasks) && _.isEmpty(values?.playbook) && (
                <div>
                  <Text>Components</Text>
                  <div
                    className="flex items-center justify-between  rounded-md p-2 w-44 my-2"
                    style={{
                      border: "1px solid #D0D5DD",
                      boxShadow: "0px 0px 0px 4px #F2F4F7",
                    }}
                  >
                    <div className="flex items-center gap-1">
                      <Strategy size={24} color="#667085" />
                      <Text className="text-base text-black">
                        {playbookSelected ? "Playbook" : "Tasks"}
                      </Text>
                    </div>

                    <div
                      className="flex items-center ml-2 cursor-pointer"
                      onClick={() => setPlaybookSelected(!playbookSelected)}
                    >
                      <Divider type="vertical" />
                      <CaretUpDown size={24} color="#667085" weight="bold" />
                    </div>
                  </div>
                </div>
              )}

              {playbookSelected ? <PlaybookComponent /> : <ActivityTasks />}
            </Col>
            <Col
              xs={10}
              style={{ padding: "4px 24px" }}
              className="flex flex-col justify-between"
            >
              <div>
                <Field
                  mode="tags"
                  placeholder="Select tags"
                  className="mt-2"
                  size="large"
                  inputType="select"
                  name="selectedTags"
                  component={AntSelect}
                  options={_.map(projectTags, (item) => {
                    return { value: item, label: item };
                  })}
                  onCallBackChange={(v) => {
                    getDuplicatesRequest(
                      values?.title,
                      values?.details,
                      v,
                      values.tasks,
                      workspace
                    );
                  }}
                />
                {fromPlanned != true && (
                  <div className={styles.projectSelect}>
                    <Field
                      component={AntSelect}
                      name="assigned"
                      label="Assignee"
                      placeholder="Search by email"
                      className="my-2"
                      selectOptions={_.map(filterSearchUser, (user) => {
                        return {
                          value: user._id,
                          labelText: user?.email,
                          label: (
                            <div>
                              <UserAvatar
                                user={user}
                                size={30}
                                className="mr-2"
                              />
                              <Typography.Text>{user?.email}</Typography.Text>
                            </div>
                          ),
                        };
                      })}
                      optionLabelProp="label"
                      loading={fetching}
                      inputType="select"
                      hasFeedback
                      size="large"
                      labelColNotReq={true}
                      showSearch
                      notFoundContent={
                        fetching ? (
                          <Loader size="small" />
                        ) : (
                          <div className="flex justify-center">No Data</div>
                        )
                      }
                      filterOption={false}
                      allowClear
                      onSearch={(v) => handleSearch(v, values)}
                      getPopupContainer={(node) => node.parentNode}
                      dropdownRender={(menu) => {
                        return <div>{menu}</div>;
                      }}
                    />
                  </div>
                )}
                <div>
                  {_.isEmpty(editingItem) && (
                    <div className={styles.projectSelect}>
                      <Field
                        component={AntSelect}
                        name="selected_group"
                        label="Select group"
                        placeholder="Select group"
                        selectOptions={_.map(backlogGroups, (item) => {
                          return { value: item?._id, label: item?.title };
                        })}
                        loading={fetching}
                        inputType="select"
                        className="my-2"
                        labelColNotReq={true}
                        hasFeedback
                        size="large"
                        showSearch
                        notFoundContent={
                          <div className="flex justify-center">No Data</div>
                        }
                        filterOption={(input, option) =>
                          (_.lowerCase(option?.label) ?? "").includes(
                            _.lowerCase(input)
                          )
                        }
                        getPopupContainer={(node) => node.parentNode}
                        dropdownRender={(menu) => <div>{menu}</div>}
                        allowClear={true}
                      />
                    </div>
                  )}

                  <div className={getStatusClassNames(values?.priority)}>
                    <Field
                      placeholder="Not set"
                      name="priority"
                      inputType="select"
                      className="my-2"
                      labelColNotReq={true}
                      size="large"
                      label="Priority"
                      component={AntSelect}
                      options={[
                        { value: 0, label: "Not set" },
                        { value: 1, label: "Low" },
                        { value: 2, label: "Medium" },
                        { value: 3, label: "High" },
                      ]}
                      suffixIcon={<FlagOutlined />}
                    />
                  </div>

                  <div>
                    <div className="flex items-center justify-between my-3 ml-1.5">
                      <Typography.Text>Attachment</Typography.Text>
                      <Upload
                        {...props}
                        fileList={files}
                        showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </div>
                    <div className="mt-2 max-h-44 overflow-y-auto">
                      {files.map((file) => (
                        <div
                          className="flex items-center justify-between mt-3 py-1 px-2"
                          key={file.uid}
                          style={{ background: "#FAFAFA" }}
                        >
                          <Text style={{ color: "#1890FF" }}>
                            {utility.truncateFileName(file?.name)}
                          </Text>

                          <DeleteOutlined
                            className=" cursor-pointer"
                            onClick={() => {
                              setFiles((prevFiles) =>
                                prevFiles.filter((f) => f.uid !== file.uid)
                              );
                            }}
                          />
                        </div>
                      ))}

                      {fileSources.map((file) => (
                        <div
                          className="flex items-center justify-between mt-3 py-1 px-2"
                          key={file}
                          style={{ background: "#FAFAFA" }}
                        >
                          <Text style={{ color: "#1890FF" }}>
                            {utility.truncateFileName(file)}
                          </Text>

                          <DeleteOutlined
                            className=" cursor-pointer"
                            onClick={() => {
                              setFileSources((prevFiles) =>
                                prevFiles.filter((f) => f !== file)
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    {progress > 0 && (
                      <Progress percent={progress} showInfo={false} />
                    )}
                  </div>
                </div>
              </div>

              <div className="p-4 flex items-center justify-evenly">
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button
                  className={`${styles.blackButton} outline-none border-none ml-2`}
                  type="primary"
                  onClick={onSubmit}
                  loading={creating || (progress > 0 && progress < 100)}
                  icon={<PlusOutlined />}
                >
                  {_.isEmpty(editingItem)
                    ? " Create Activity"
                    : "Update Activity"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
};

export default NewActivityContent;
