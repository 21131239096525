import utility from "common/utility";
import _ from "lodash";

const generateUpdatedItemList = (columns) => {
  let updatedItemList = [];
  _.forEach(columns, (column) => {
    if (column?._id === "root") {
      updatedItemList.push(
        ..._.map(column.items, (task) => ({
          itemId: task._id,
          itemType: "backlog_activity",
        }))
      );
    } else {
      updatedItemList.push({
        itemId: column._id,
        itemType: "backlog_group",
      });
    }
  });
  return updatedItemList;
};

const onDragEndColumn = (
  result,
  backlogId,
  updateBacklog,
  handleSetData,
  internalData
) => {
  if (!result.destination) return;

  const newColumns = Array.from(internalData.columns);
  const [movedColumn] = newColumns.splice(result.source.index, 1);
  newColumns.splice(result.destination.index, 0, movedColumn);

  if (newColumns[0]._id !== "root") return;

  let updatedItemList = generateUpdatedItemList(newColumns);

  updateBacklog({
    variables: {
      input: {
        backlogId: backlogId,
        items: updatedItemList,
        view: "kanban",
      },
    },
  });

  const newData = {
    ...internalData,
    columns: newColumns,
  };

  handleSetData(newData);
};

export const onDragEndTask = ({
  result,
  incomingData,
  updateBacklog,
  updateBacklogGroup,
  handleSetData,
  backlogId,
}) => {
  const internalData = _.cloneDeep(incomingData);
  const { destination, source, draggableId, type } = result;
  if (!destination) return; // No valid destination

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return; // No change in position
  }

  if (type === "COLUMN") {
    onDragEndColumn(
      result,
      backlogId,
      updateBacklog,
      handleSetData,
      internalData
    ); // Handle column reordering
    return;
  }

  const startColumn = internalData.columns.find((column) => {
    if (column._id === source.droppableId) {
      column.from = "group";
      return true;
    } else {
      const filteredData = column.items.find(
        (task) => task._id === source.droppableId
      );
      if (filteredData) {
        column.from = "sub_group";
        return true;
      }
    }
  });

  const endColumn = internalData.columns.find((column) => {
    if (column._id === destination.droppableId) {
      column.to = "group";
      return true;
    } else {
      const filteredData = column.items.find(
        (task) => task._id === destination.droppableId
      );
      if (filteredData) {
        column.to = "sub_group";
        return true;
      }
    }
  });

  const isSubGroup = (draggableId) => {
    return internalData.columns.some((column) =>
      column.items.some(
        (task) => task._id === draggableId && task.type === "backlog_sub_group"
      )
    );
  };

  if (destination?.droppableId === "new-group-column") {
    // console.log("create a new Group from sub-group");
    const sourceGroup = startColumn;

    const sourceTask = sourceGroup.items.find(
      (task) => task._id === draggableId
    );

    if (sourceTask) {
      const removedSubGroup = _.find(
        sourceGroup.items,
        (task) => task._id === draggableId
      );

      sourceGroup.items = sourceGroup.items.filter(
        (task) => task._id !== draggableId
      );

      const updatedColumns = internalData.columns.map((column) => {
        if (column._id === sourceGroup._id) {
          return sourceGroup;
        } else {
          return column;
        }
      });

      const updatedColumnsWithRemovedSubgroups = [
        ...updatedColumns,
        { ...removedSubGroup, type: "backlog_group" },
      ];

      let updatedItemList = generateUpdatedItemList(
        updatedColumnsWithRemovedSubgroups
      );

      updateBacklog({
        variables: {
          input: {
            backlogId: backlogId,
            items: updatedItemList,
            view: "kanban",
          },
        },
      });

      updateBacklogGroup({
        variables: {
          input: {
            groupId: sourceGroup._id, // destination group id
            backlogId: backlogId,
            items: _.map(sourceGroup.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      const newData = {
        ...internalData,
        columns: updatedColumnsWithRemovedSubgroups,
      };

      handleSetData(newData);
    }
  }

  if (!startColumn || !endColumn) {
    // Handle invalid source or destination columns
    return;
  }

  //  Dropping into root ,
  if (endColumn._id === "root") {
    if (isSubGroup(draggableId)) {
      utility.setNotification(
        "Dropping sub groups into root not allowed.",
        "If you want to create a new coloumn please drop the sub group at the end.",
        "warning",
        "topRight"
      );
    } else {
      if (startColumn._id === "root") {
        // You are re-arranging within the root column
        // Perform the re-arrange operation within the root column
        const sourceTask = startColumn.items.find(
          (task) => task._id === draggableId
        );

        if (sourceTask) {
          // Find the index of the source task in the root column
          const sourceIndex = startColumn.items.findIndex(
            (task) => task._id === draggableId
          );

          // Calculate the correct destination index for the task
          const destinationIndex = destination.index;

          // Remove the task from its current position in the root column
          startColumn.items.splice(sourceIndex, 1);

          // Insert the task at the correct position within the root column
          startColumn.items.splice(destinationIndex, 0, sourceTask);

          const updatedColumns = internalData.columns.map((column) => {
            if (column._id === startColumn._id) {
              return startColumn;
            }
            return column;
          });

          let updatedItemList = generateUpdatedItemList(updatedColumns);

          updateBacklog({
            variables: {
              input: {
                backlogId: backlogId,
                items: updatedItemList,
                view: "kanban",
              },
            },
          });

          const newData = {
            ...internalData,
            columns: updatedColumns,
          };
          handleSetData(newData);
        }
      } else if (startColumn.from === "group") {
        // You are moving an item from another column to the root column
        const sourceTask = startColumn.items.find(
          (task) => task._id === draggableId
        );

        if (sourceTask) {
          // Remove the task from its current position in the source column
          startColumn.items = startColumn.items.filter(
            (task) => task._id !== draggableId
          );

          // Insert the task into the root column at the specified destination index
          endColumn.items.splice(destination.index, 0, sourceTask);

          const updatedColumns = internalData.columns.map((column) => {
            if (column._id === startColumn._id) {
              return startColumn;
            } else if (column._id === endColumn._id) {
              return endColumn;
            }
            return column;
          });

          let updatedItemList = generateUpdatedItemList(updatedColumns);
          updateBacklog({
            variables: {
              input: {
                backlogId: backlogId,
                items: updatedItemList,
                view: "kanban",
              },
            },
          });

          updateBacklogGroup({
            variables: {
              input: {
                groupId: startColumn._id, // destination group id
                backlogId: backlogId,
                items: _.map(startColumn.items, (item) => {
                  return {
                    itemId: item._id,
                    itemType:
                      item?.type === "backlog_activity"
                        ? "backlog_activity"
                        : "backlog_group",
                  };
                }),
              },
            },
          });

          const newData = {
            ...internalData,
            columns: updatedColumns,
          };

          handleSetData(newData);
        }
      } else {
        // You are moving an item from another column subgroup to the root column
        const sourceSubGroup = startColumn.items.find(
          (subGroup) => subGroup._id === source.droppableId
        );
        const destinationColumn = endColumn;

        if (sourceSubGroup) {
          const sourceTask = sourceSubGroup.items[source.index];

          if (sourceTask) {
            // Remove the task from the source sub-group
            sourceSubGroup.items.splice(source.index, 1);

            // Insert the task into the main column of the destination column
            destinationColumn.items.splice(destination.index, 0, sourceTask);

            const updatedStartColumns = internalData.columns.map((column) => {
              if (column._id === startColumn._id) {
                const updatedTasks = column.items.map((subGroup) => {
                  if (subGroup._id === source.droppableId) {
                    return {
                      ...subGroup,
                      items: sourceSubGroup.items,
                    };
                  }
                  return subGroup;
                });

                return {
                  ...startColumn,
                  items: updatedTasks,
                };
              }
              return column;
            });

            const updatedEndColumns = internalData.columns.map((column) => {
              if (column._id === endColumn._id) {
                return {
                  ...endColumn,
                  items: destinationColumn.items,
                };
              }
              return column;
            });

            const updatedColumns = updatedStartColumns.map((updatedColumn) => {
              const matchingEndColumn = updatedEndColumns.find(
                (column) => column._id === updatedColumn._id
              );

              if (matchingEndColumn) {
                return matchingEndColumn;
              }
              return updatedColumn;
            });

            let updatedItemList = generateUpdatedItemList(updatedColumns);

            updateBacklog({
              variables: {
                input: {
                  backlogId: backlogId,
                  items: updatedItemList,
                  view: "kanban",
                },
              },
            });

            updateBacklogGroup({
              variables: {
                input: {
                  groupId: sourceSubGroup._id, // destination group id
                  backlogId: backlogId,
                  items: _.map(sourceSubGroup.items, (item) => {
                    return {
                      itemId: item._id,
                      itemType:
                        item?.type === "backlog_activity"
                          ? "backlog_activity"
                          : "backlog_group",
                    };
                  }),
                },
              },
            });

            const newData = {
              ...internalData,
              columns: updatedColumns,
            };

            handleSetData(newData);
          }
        }
      }
    }

    return null;
  }

  // Condition one: A task can be dragged from a sub-group to a column within the same column
  if (
    startColumn.from === "sub_group" &&
    endColumn.to === "group" &&
    startColumn === endColumn
  ) {
    // console.log(
    //   "Moving a task from a sub-group to a column within the same column."
    // );

    const sourceSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === source.droppableId
    );

    if (sourceSubGroup) {
      const sourceTask = sourceSubGroup.items[source.index];

      if (sourceTask) {
        sourceSubGroup.items.splice(source.index, 1); // Remove the specific task from the source sub-group
        endColumn.items.splice(destination.index, 0, sourceTask); // Insert the task at the destination

        const updatedColumns = internalData.columns.map((column) => {
          if (column._id === startColumn._id) {
            const updatedTasks = column.items.map((subGroup) => {
              if (subGroup._id === source.droppableId) {
                return {
                  ...subGroup,
                  items: sourceSubGroup.items, // Update the source sub-group's items
                };
              }
              return subGroup;
            });

            return {
              ...startColumn,
              items: updatedTasks,
            };
          }
          if (column._id === endColumn._id) {
            return {
              ...endColumn,
            };
          }
          return column;
        });

        updateBacklogGroup({
          variables: {
            input: {
              groupId: endColumn?._id, // destination group id
              backlogId: backlogId,
              items: _.map(endColumn?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        // #2 call to remove the activity from old group
        updateBacklogGroup({
          variables: {
            input: {
              groupId: sourceSubGroup?._id, // old group id
              backlogId: backlogId,
              items: _.map(sourceSubGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        const newData = {
          ...internalData,
          columns: updatedColumns,
        };

        handleSetData(newData);
      }
    }
  }

  if (
    startColumn.from === "sub_group" &&
    endColumn.to === "sub_group" &&
    startColumn === endColumn
  ) {
    // console.log(
    //   "Moving a task from one sub-group to another within the same column."
    // );

    // Find the source and destination sub-groups
    const sourceSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === source.droppableId
    );
    const destinationSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === destination.droppableId
    );

    if (sourceSubGroup && destinationSubGroup) {
      const sourceTask = sourceSubGroup.items[source.index];

      if (sourceTask) {
        // Remove the task from the source sub-group
        sourceSubGroup.items.splice(source.index, 1);

        // Insert the task into the destination sub-group
        destinationSubGroup.items.splice(destination.index, 0, sourceTask);

        const updatedColumns = internalData.columns.map((column) => {
          if (column._id === startColumn._id) {
            const updatedTasks = column.items.map((subGroup) => {
              if (subGroup._id === source.droppableId) {
                return {
                  ...subGroup,
                  items: sourceSubGroup.items,
                };
              }
              if (subGroup._id === destination.droppableId) {
                return {
                  ...subGroup,
                  items: destinationSubGroup.items,
                };
              }
              return subGroup;
            });

            return {
              ...startColumn,
              items: updatedTasks,
            };
          }
          return column;
        });

        if (sourceSubGroup._id === destinationSubGroup?._id) {
          // console.log("re arranging the subgroup in same sub groups ");
          updateBacklogGroup({
            variables: {
              input: {
                groupId: destinationSubGroup?._id, // destination group id
                backlogId: backlogId,
                items: _.map(destinationSubGroup?.items, (item) => {
                  return {
                    itemId: item._id,
                    itemType:
                      item?.type === "backlog_activity"
                        ? "backlog_activity"
                        : "backlog_group",
                  };
                }),
              },
            },
          });
        } else {
          // #1 call to add the activity to the new group
          updateBacklogGroup({
            variables: {
              input: {
                groupId: destinationSubGroup?._id, // destination group id
                backlogId: backlogId,
                items: _.map(destinationSubGroup?.items, (item) => {
                  return {
                    itemId: item._id,
                    itemType:
                      item?.type === "backlog_activity"
                        ? "backlog_activity"
                        : "backlog_group",
                  };
                }),
              },
            },
          });

          // #2 call to remove the activity from old group
          updateBacklogGroup({
            variables: {
              input: {
                groupId: sourceSubGroup?._id, // old group id
                backlogId: backlogId,
                items: _.map(sourceSubGroup?.items, (item) => {
                  return {
                    itemId: item._id,
                    itemType:
                      item?.type === "backlog_activity"
                        ? "backlog_activity"
                        : "backlog_group",
                  };
                }),
              },
            },
          });
        }

        const newData = {
          ...internalData,
          columns: updatedColumns,
        };

        handleSetData(newData);
      }
    }
  }

  if (
    startColumn.from === "sub_group" &&
    endColumn.to === "sub_group" &&
    startColumn !== endColumn
  ) {
    // console.log(
    //   "Moving a task from one sub-group to another in a different column sub_group."
    // );

    // Find the source and destination sub-groups
    const sourceSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === source.droppableId
    );
    const destinationSubGroup = endColumn.items.find(
      (subGroup) => subGroup._id === destination.droppableId
    );

    if (sourceSubGroup && destinationSubGroup) {
      const sourceTask = sourceSubGroup.items[source.index];

      if (sourceTask) {
        // Remove the task from the source sub-group
        sourceSubGroup.items.splice(source.index, 1);

        // Insert the task into the destination sub-group
        destinationSubGroup.items.splice(destination.index, 0, sourceTask);

        // #1 call to add the activity to the new group
        updateBacklogGroup({
          variables: {
            input: {
              groupId: destinationSubGroup?._id, // destination group id
              backlogId: backlogId,
              items: _.map(destinationSubGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        // #2 call to remove the activity from old group
        updateBacklogGroup({
          variables: {
            input: {
              groupId: sourceSubGroup?._id, // old group id
              backlogId: backlogId,
              items: _.map(sourceSubGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        const updatedStartColumns = internalData.columns.map((column) => {
          if (column._id === startColumn._id) {
            const updatedTasks = column.items.map((subGroup) => {
              if (subGroup._id === source.droppableId) {
                return {
                  ...subGroup,
                  items: sourceSubGroup.items,
                };
              }
              return subGroup;
            });

            return {
              ...startColumn,
              items: updatedTasks,
            };
          }
          return column;
        });

        const updatedEndColumns = internalData.columns.map((column) => {
          if (column._id === endColumn._id) {
            const updatedTasks = column.items.map((subGroup) => {
              if (subGroup._id === destination.droppableId) {
                return {
                  ...subGroup,
                  items: destinationSubGroup.items,
                };
              }
              return subGroup;
            });

            return {
              ...endColumn,
              items: updatedTasks,
            };
          }
          return column;
        });

        const newData = {
          ...internalData,
          columns: updatedStartColumns.map((updatedColumn) => {
            const matchingEndColumn = updatedEndColumns.find(
              (column) => column._id === updatedColumn._id
            );

            if (matchingEndColumn) {
              return matchingEndColumn;
            }
            return updatedColumn;
          }),
        };

        handleSetData(newData);
      }
    }
  }

  // Condition four: A task can be dropped from another column sub-group to another column group (sub_group to group)
  if (
    startColumn.from === "sub_group" &&
    endColumn.to === "group" &&
    startColumn !== endColumn
  ) {
    // console.log(
    //   "Moving a task from a sub-group to the main column of a different column."
    // );

    // Find the source sub-group and destination column
    const sourceSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === source.droppableId
    );
    const destinationColumn = endColumn;

    if (sourceSubGroup) {
      const sourceTask = sourceSubGroup.items[source.index];

      if (sourceTask) {
        // Remove the task from the source sub-group
        sourceSubGroup.items.splice(source.index, 1);

        // Insert the task into the main column of the destination column
        destinationColumn.items.splice(destination.index, 0, sourceTask);

        const updatedStartColumns = internalData.columns.map((column) => {
          if (column._id === startColumn._id) {
            const updatedTasks = column.items.map((subGroup) => {
              if (subGroup._id === source.droppableId) {
                return {
                  ...subGroup,
                  items: sourceSubGroup.items,
                };
              }
              return subGroup;
            });

            return {
              ...startColumn,
              items: updatedTasks,
            };
          }
          return column;
        });

        const updatedEndColumns = internalData.columns.map((column) => {
          if (column._id === endColumn._id) {
            return {
              ...endColumn,
              items: destinationColumn.items,
            };
          }
          return column;
        });

        updateBacklogGroup({
          variables: {
            input: {
              groupId: destinationColumn?._id, // destination group id
              backlogId: backlogId,
              items: _.map(destinationColumn?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        // #2 call to remove the activity from old group
        updateBacklogGroup({
          variables: {
            input: {
              groupId: sourceSubGroup?._id, // old group id
              backlogId: backlogId,
              items: _.map(sourceSubGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });

        const newData = {
          ...internalData,
          columns: updatedStartColumns.map((updatedColumn) => {
            const matchingEndColumn = updatedEndColumns.find(
              (column) => column._id === updatedColumn._id
            );

            if (matchingEndColumn) {
              return matchingEndColumn;
            }
            return updatedColumn;
          }),
        };

        handleSetData(newData);
      }
    }
  }

  if (
    startColumn.from === "group" &&
    endColumn.to === "group" &&
    startColumn !== endColumn
  ) {
    // console.log("Moving a task between columns (groups).");

    // Find the source group and destination group
    const sourceGroup = startColumn;
    const destinationGroup = endColumn;

    const sourceTask = sourceGroup.items.find(
      (task) => task._id === draggableId
    );

    if (sourceTask) {
      // Remove the task from the source group
      sourceGroup.items = sourceGroup.items.filter(
        (task) => task._id !== draggableId
      );

      // Calculate the correct destination index for the task
      const destinationIndex = destination.index;

      // Insert the task into the destination group at the correct index
      destinationGroup.items.splice(destinationIndex, 0, sourceTask);

      const updatedColumns = internalData.columns.map((column) => {
        if (column._id === sourceGroup._id) {
          return sourceGroup;
        } else if (column._id === destinationGroup._id) {
          return destinationGroup;
        } else {
          return column;
        }
      });

      updateBacklogGroup({
        variables: {
          input: {
            groupId: destinationGroup?._id, // destination group id
            backlogId: backlogId,
            items: _.map(destinationGroup?.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      // // #2 call to remove the activity from old group

      if (sourceGroup._id === "root") {
        let updatedItemList = generateUpdatedItemList(updatedColumns);

        updateBacklog({
          variables: {
            input: {
              backlogId: backlogId,
              items: updatedItemList,
              view: "kanban",
            },
          },
        });
      } else {
        updateBacklogGroup({
          variables: {
            input: {
              groupId: sourceGroup?._id, // old group id
              backlogId: backlogId,
              items: _.map(sourceGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });
      }

      const newData = {
        ...internalData,
        columns: updatedColumns,
      };

      handleSetData(newData);
    }
  }

  if (
    startColumn.from === "group" &&
    endColumn.to === "sub_group" &&
    startColumn !== endColumn
  ) {
    // console.log("Moving a task from a group to a sub-group in another column.");
    if (isSubGroup(draggableId)) {
      // console.log(
      //   "Dropping sub-group inside another sub-group is not allowed."
      // );

      return;
    }

    // Find the source group and destination sub-group
    const sourceGroup = startColumn;
    const destinationSubGroup = endColumn.items.find(
      (subGroup) => subGroup._id === destination.droppableId
    );

    const sourceTask = sourceGroup.items.find(
      (task) => task._id === draggableId
    );

    if (sourceTask && destinationSubGroup) {
      // Remove the task from the source group
      sourceGroup.items = sourceGroup.items.filter(
        (task) => task._id !== draggableId
      );

      // Specify the destination index for the task in the sub-group
      const destinationIndex = destination.index;

      // Insert the task into the destination sub-group at the specified index
      destinationSubGroup.items.splice(destinationIndex, 0, sourceTask);

      const updatedColumns = internalData.columns.map((column) => {
        if (column._id === sourceGroup._id) {
          return sourceGroup;
        } else if (column._id === destinationSubGroup._id) {
          return destinationSubGroup;
        } else {
          return column;
        }
      });

      updateBacklogGroup({
        variables: {
          input: {
            groupId: destinationSubGroup?._id, // destination group id
            backlogId: backlogId,
            items: _.map(destinationSubGroup?.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      // // #2 call to remove the activity from old group

      if (sourceGroup._id === "root") {
        let updatedItemList = generateUpdatedItemList(updatedColumns);

        updateBacklog({
          variables: {
            input: {
              backlogId: backlogId,
              items: updatedItemList,
              view: "kanban",
            },
          },
        });
      } else {
        updateBacklogGroup({
          variables: {
            input: {
              groupId: sourceGroup?._id, // old group id
              backlogId: backlogId,
              items: _.map(sourceGroup?.items, (item) => {
                return {
                  itemId: item._id,
                  itemType:
                    item?.type === "backlog_activity"
                      ? "backlog_activity"
                      : "backlog_group",
                };
              }),
            },
          },
        });
      }

      const newData = {
        ...internalData,
        columns: updatedColumns,
      };

      handleSetData(newData);
    }
  }

  if (
    startColumn.from === "group" &&
    endColumn.to === "sub_group" &&
    startColumn === endColumn
  ) {
    // console.log(
    //   "Moving a task from a group to a sub-group within the same column."
    // );

    if (isSubGroup(draggableId)) {
      if (startColumn.to === "sub_group" && endColumn.to === "sub_group") {
        // console.log(
        //   "Dropping sub-group inside another sub-group is not allowed."
        // );

        return;
      }
    }

    // Find the source group and destination sub-group
    const sourceGroup = startColumn;
    const destinationSubGroup = startColumn.items.find(
      (subGroup) => subGroup._id === destination.droppableId
    );

    const sourceTask = sourceGroup.items.find(
      (task) => task._id === draggableId
    );

    if (sourceTask && destinationSubGroup) {
      // Remove the task from the source group
      sourceGroup.items = sourceGroup.items.filter(
        (task) => task._id !== draggableId
      );

      // Specify the destination index for the task in the sub-group
      const destinationIndex = destination.index;

      // Insert the task into the destination sub-group at the specified index
      destinationSubGroup.items.splice(destinationIndex, 0, sourceTask);

      const updatedColumns = internalData.columns.map((column) => {
        if (column._id === sourceGroup._id) {
          return sourceGroup;
        } else {
          return column;
        }
      });

      updateBacklogGroup({
        variables: {
          input: {
            groupId: destinationSubGroup?._id, // destination group id
            backlogId: backlogId,
            items: _.map(destinationSubGroup?.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      // // #2 call to remove the activity from old group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: sourceGroup?._id, // old group id
            backlogId: backlogId,
            items: _.map(sourceGroup?.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      const newData = {
        ...internalData,
        columns: updatedColumns,
      };

      handleSetData(newData);
    }
  }

  if (
    startColumn.from === "group" &&
    endColumn.to === "group" &&
    startColumn === endColumn
  ) {
    // console.log("Moving a task within the same group (rearrange)");

    // Find the source group
    const sourceGroup = startColumn;

    // Get the source task
    const sourceTask = sourceGroup.items.find(
      (task) => task._id === draggableId
    );

    if (sourceTask) {
      // Remove the task from its current position
      sourceGroup.items = sourceGroup.items.filter(
        (task) => task._id !== draggableId
      );

      // Calculate the correct destination index for the task
      const destinationIndex = destination.index;

      // Insert the task at the correct position within the same group
      sourceGroup.items.splice(destinationIndex, 0, sourceTask);

      // Create an updated copy of the columns
      const updatedColumns = internalData.columns.map((column) => {
        if (column._id === sourceGroup._id) {
          return sourceGroup;
        }
        return column;
      });

      // // #2 call to remove the activity from old group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: sourceGroup?._id, // old group id
            backlogId: backlogId,
            items: _.map(sourceGroup?.items, (item) => {
              return {
                itemId: item._id,
                itemType:
                  item?.type === "backlog_activity"
                    ? "backlog_activity"
                    : "backlog_group",
              };
            }),
          },
        },
      });

      // Create the updated data
      const newData = {
        ...internalData,
        columns: updatedColumns,
      };

      // Update the state and trigger a re-render
      handleSetData(newData);
    }
  }

  //  Condtion one a task can be drag sub group to coulmn with in the coulum
  //  A task can be put inside a sub group from a sub group with in the same coumn
  //  A task can be dropped from another column sub group to other column sub group (sub_group to sub_group)
  //  A task can be dropped from another column sub group to another column group (sub_group to group)
  //  A task can be dropped from another column group to another column group (group to group)
  //  A task can be dropped from another column group to another column sub group (group to sub_group)
  // Moving a task from a group to a sub-group within the same column.
};
