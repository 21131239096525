import React from "react";

import { Typography } from "antd";

const { Text } = Typography;

const RemainingTime = ({ data }) => {
  return (
    <div className="flex flex-col text-center w-full" style={{ minHeight: 70 }}>
      <Text className="text-lg px-4" style={{ color: "#4E4B66" }}>
        Remaining time
      </Text>
      <Text
        className="font-semibold px-2 text-2xl pb-2"
        style={{ color: "#3C2E94" }}
      >
        {data}
      </Text>
    </div>
  );
};

export default RemainingTime;
