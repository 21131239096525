import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Layout, Row, Col, Typography, Button } from "antd";
import { Formik, Form } from "formik";
import queryString from "query-string";
import { isEmpty, debounce, get } from "lodash";
import { GET_REQUEST } from "graphql/queries/request/getRequest";
import { ACCEPT_PROJECT_INVITE } from "graphql/mutations/business/acceptProjectInvite";
import Loader from "components/Loader";
import UserInput from "components/UserInput";
import NotFound from "views/Provisional/404";
import utility from "common/utility";
import { validationRateOnlySchema, validationSchema } from "./validationSchema";
import OnboardingSvg from "common/assets/onboard.svg";
import WinduLogo from "common/assets/WinduLogo.svg";
import RateInput from "components/RateInput";

const { Text, Title } = Typography;

const ProjectInvite = () => {
  const history = useHistory();
  const location = useLocation();

  const request = queryString.parse(location?.search).request;
  const projectId = queryString.parse(location?.search).project;
  const formRef = React.useRef();

  const initialFormValues = {
    name: "",
    password: "",
    external: false,
    currency: "USD",
    rate: "0",
  };

  const { loading, error, data } = useQuery(GET_REQUEST, {
    fetchPolicy: "network-only",
    variables: { id: request },
  });

  const [acceptProjectInvite, { loading: inviteLoading }] = useMutation(
    ACCEPT_PROJECT_INVITE,
    {
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: ({ acceptProjectInvite }) => {
        if (acceptProjectInvite) {
          utility.setNotification(
            "Success",
            `Invitation Accepted`,
            "success",
            "topRight"
          );
        }
        if (!acceptProjectInvite) {
          utility.setNotification(
            "Rejected",
            `You have successfully rejected the invitation`,
            "error",
            "topRight"
          );
        }
        debounceNavigate();
      },
    }
  );

  const debounceNavigate = debounce(() => history.push("/"), 500);

  const handleDone = (values = {}) => {
    const { invitee } = data.getRequest || {};
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const payload = {
      ...values,
      rate: String(values.rate),
      email: invitee,
      status: "Accepted",
      requestId: request,
      projectId,
      timezone: timeZone,
    };
    acceptProjectInvite({ variables: { input: payload } });
  };

  const handleAccept = () => {
    formRef.current.submitForm();
  };

  if (loading) return <Loader size="large" />;
  if (isEmpty(data.getRequest) || error) return <NotFound />;
  if (data.getRequest.status !== "Pending") return <NotFound />;

  let title = "";
  if (data?.getRequest?.type === "Member") {
    title = `You have been invited by ${data.getRequest.inviter.email} to be a Member`;
  } else {
    title = `You have been invited by ${data.getRequest.inviter.email} to be a Manager`;
  }

  const isNewUser = data.getRequest?.newUser;

  const renderUserInput = (props) => {
    const request = data.getRequest;
    const addons = get(request, "project.created_by.addons", []);

    const hasInvoicing = addons.some((a) => a.name === "Invoicing");

    if (isNewUser) return <UserInput {...props} />;
    if (!isNewUser && hasInvoicing) return <RateInput {...props} />;

    return null;
  };

  const formValidation = !isNewUser
    ? validationRateOnlySchema
    : validationSchema;

  return (
    <Layout className="flex justify-center min-h-screen">
      <Row style={{ height: "100vh" }} className="bg-white">
        <Col xs={24} sm={24} md={12} className="bg-white">
          <div className="flex flex-col w-3/4 max-w-lg px-12 m-auto justify-center h-full text-center">
            <div className="mb-3">
              <div className="flex items-baseline justify-center">
                <img src={WinduLogo} alt="windu" style={{ height: 30 }} />
                <Title level={2} className="ml-2">
                  Windu's invite
                </Title>
              </div>
              <Text className="text-base my-2">
                {!isNewUser && <b>{data.getRequest?.invitee} </b>}
                {title}.
                {isNewUser && (
                  <>
                    You're signing up as <b>{data.getRequest?.invitee}</b>
                  </>
                )}
              </Text>
            </div>

            <Formik
              initialValues={initialFormValues}
              validationSchema={formValidation}
              onSubmit={handleDone}
              innerRef={formRef}
            >
              {({ submitCount, handleSubmit, setFieldValue, values }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    {renderUserInput({
                      setFieldValue,
                      submitCount,
                      values,
                      email: data.getRequest?.invitee,
                    })}

                    <div className="mt-3">
                      <Button
                        size="large"
                        key="submit"
                        className="w-full blackNextButton text-white outline-none border-none"
                        type="primary"
                        onClick={() => handleAccept()}
                        loading={inviteLoading}
                      >
                        Accept
                      </Button>
                    </div>

                    <div className="my-4 text-center">
                      <Text>
                        By accepting, you agree to Windu's
                        <a
                          rel="noreferrer"
                          href="https://www.windu.io/privacy-policy/"
                          target="_blank"
                        >
                          &nbsp;
                          <Text className="text-black underline">
                            Privacy Policy
                          </Text>
                          &nbsp;
                        </a>
                        and
                        <br />
                        <a
                          rel="noreferrer"
                          href="https://www.windu.io/terms-service/"
                          target="_blank"
                        >
                          &nbsp;
                          <Text className="text-black underline">
                            Terms of Service
                          </Text>
                          &nbsp;
                        </a>
                      </Text>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} className="m-auto p-5">
          <div
            className="rounded-xl"
            style={{ background: "#B1A5EB", height: "92vh" }}
          >
            <img
              src={OnboardingSvg}
              alt="windu-onboarding"
              style={{ minHeight: "80vh" }}
              className="p-5"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default ProjectInvite;
