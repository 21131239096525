import React from "react";
import { Collapse } from "antd";

const { Panel: RRPanel } = Collapse;

const Panel = ({ header, children, className }) => {
  return (
    <Collapse
      expandIconPosition="right"
      ghost
      className={`collapse-panel-custom ${className}`}
    >
      <RRPanel header={header} key={header}>
        <div className="w-3/4 mr-auto ml-4 flex flex-col">{children}</div>
      </RRPanel>
    </Collapse>
  );
};

export default Panel;
