import React from "react";

import { has, map, find, flatten } from "lodash";
import ZoomLogo from "../../../common/assets/zoomIcon.png";
import MicrosoftLogo from "common/assets/microsoftTeams.png";

export const returnEvents = ({ data, getCalendarProjects, cal }) => {
  const mapProject = map(data, (e) => {
    const isConfirmed = e?.status === "confirmed";
    const hasHangoutLink = has(e, "hangoutLink");
    const hasZoomLink = e?.location?.toLowerCase()?.includes("zoom");
    const hasMicrosoftTeams = e?.description
      ?.toLowerCase()
      ?.includes("microsoft teams");

    if ((isConfirmed && hasHangoutLink) || hasZoomLink || hasMicrosoftTeams) {
      const findProject = find(
        getCalendarProjects?.calendar_project_subscription,
        {
          calendar: cal,
        }
      );
      return { ...e, project: findProject?.project };
    }
  });

  return flatten(mapProject);
};

export const getHeader = (type) => {
  if (type === "google")
    return (
      <div className="flex">
        <img
          className="mr-3"
          style={{ width: 25 }}
          src="https://windu.s3.us-east-2.amazonaws.com/assets/google_meet.png"
        />
        Google Meet
      </div>
    );
  if (type === "zoom")
    return (
      <div className="flex">
        <img className="mr-3" style={{ width: 25 }} src={ZoomLogo} />
        Zoom
      </div>
    );
  if (type === "microsoft")
    return (
      <div className="flex">
        <img className="mr-3" style={{ width: 25 }} src={MicrosoftLogo} />
        Teams
      </div>
    );
};
