import gql from "graphql-tag";

export const RESEND_VERIFICATION_MAIL = gql`
  mutation ($email: String!) {
    resendVerificationMail(email: $email) {
      full_name
      email
      user_type
    }
  }
`;
