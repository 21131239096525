import React from "react";
import { Table } from "@fishyvisions/windu-uikit";
import { useQuery } from "@apollo/client";
import { map } from "lodash";
import { Row, Col } from "antd";
import { GET_ROLES } from "graphql/queries/roles/getRoles";
import EmptyData from "../../components/EmptyData";

const Roles = () => {
  const { loading, error, data } = useQuery(GET_ROLES);

  if (error) return null;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Count",
      dataIndex: "users",
      key: "users",
      render: (users) => users.length,
    },
  ];
  return (
    <>
      <Row>
        <Col xs={24}>
          <Table
            locale={{
              emptyText: <EmptyData />,
            }}
            columns={columns}
            dataSource={map(data?.getRoles, (role) => ({
              ...role,
              key: role._id,
            }))}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default Roles;
