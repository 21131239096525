import { Card, Dropdown, Menu, Pagination, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_INVEST_INVOICES } from "graphql/queries/invoice/getInvestInvoices";
import { ArrowDownOutlined, MoreOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const sizePerPage = 5;
const initialOffset = 0;

const Invoices = () => {
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
  });

  const columns = [
    {
      title: "Invoice",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (item) => <Text>{item?.title}</Text>,
    },
    {
      title: (
        <Text>
          Billing date <ArrowDownOutlined className="ml-2" />
        </Text>
      ),
      dataIndex: "issue_date",
      key: "issue_date",
      render: (item) => <Text>{moment(item).format("MMM DD, YYYY")}</Text>,
    },
    {
      title: "Amount",
      dataIndex: "total_due",
      key: "amount",
      render: (item, data) => {
        if (item) return `${data?.currency} ${item}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <Tag color={item === "PAID" ? "success" : "processing"}>{item}</Tag>
      ),
    },
    {
      dataIndex: "action",
      key: "pdfLink",
      render: (item, data) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                {data?.status === "PENDING" && (
                  <>
                    <Menu.Item
                      onClick={() =>
                        window.open(data.stripe_invoice?.hosted_url, "_blank")
                      }
                    >
                      Pay
                    </Menu.Item>
                    <Menu.Divider />
                  </>
                )}
                <Menu.Item
                  onClick={() => window.open(data.invoiceURL, "_blank")}
                >
                  Download
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];
  const { loading, data } = useQuery(GET_INVEST_INVOICES, {
    notifyOnNetworkStatusChange: true,
    // onCompleted:({getInvestInvoices})=>{
    //     setallInvoices(getInvestInvoices)
    // },
    fetchPolicy: "network-only",
    variables: { input: queryParameters },
  });

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  return (
    <Card
      className="w-full rounded-lg"
      bodyStyle={{ padding: 0 }}
      title={
        <div className="flex flex-col">
          <Text className="text-lg" strong>
            Invoice History
          </Text>
          <Text className="text-base">Download your previous invoices.</Text>
        </div>
      }
    >
      <Table
        loading={loading}
        dataSource={data?.getInvestInvoices?.data}
        columns={columns}
        pagination={false}
      />
      <div className="my-2 flex justify-end">
        <Pagination
          className="pr-4 text-center"
          current={data?.getInvestInvoices?.pagination?.current_page}
          onChange={onPageChange}
          total={data?.getInvestInvoices?.pagination?.total_data || 0}
          pageSize={queryParameters.size}
          showTotal={(total, range) =>
            `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
          }
        />
      </div>
    </Card>
  );
};

export default Invoices;
