import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "./styles.module.css";
import { Dropdown, Typography } from "antd";
import _ from "lodash";
import HappyClock from "common/assets/timebox/happy_clock.svg";
import OkClock from "common/assets/timebox/ok_clock.svg";
import SadClock from "common/assets/timebox/sad_clock.svg";
import TimeField from "react-simple-timefield";

export const TimeBoxField = ({
  defaultMaxValue,
  minValue,
  defaultValue = 0,
  field,
  freeTime = 180,
  form,
  bgColor = "#D9D9D9",
  filledBgColor = "#3C2E94",
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(field?.value || defaultValue);
  const [maxValue, setmaxValue] = useState(defaultMaxValue);
  const [timebox, setTimebox] = React.useState("00:00:00");
  const [showDropdown, setShowDropdown] = useState(true);
  const { setFieldValue } = form || {};
  const valuePercentage = ((value - minValue) / (maxValue - minValue)) * 100;

  useEffect(() => {
    if (!isNaN(value)) {
      const hours =
        Math.floor(value / 60) > 9
          ? Math.floor(value / 60)
          : `0${Math.floor(value / 60)}`;
      const mins = value % 60 > 9 ? value % 60 : `0${value % 60}`;
      setTimebox(`${hours}:${mins}:00`);
    } else {
      const hours = Number(value?.split(":")[0]);
      const mins = Number(value?.split(":")[1]);
      const totalMins = hours * 60 + mins;
      if (totalMins > maxValue) {
        setmaxValue(totalMins);
      }
      setValue(totalMins);
    }
  }, [value]);

  useEffect(() => {
    if (form) {
      setFieldValue(field?.name, timebox);
    }
    if (onChange) {
      onChange(timebox);
    }
  }, [timebox]);

  const setSliderValue = (timebox) => {
    const hours = Number(timebox?.split(":")[0]);
    const mins = Number(timebox?.split(":")[1]);
    const totalMins = hours * 60 + mins;
    if (totalMins > maxValue) {
      setmaxValue(totalMins);
    }
    if (totalMins) {
      setValue(totalMins);
    }
  };

  const getTimeBoxBars = () => {
    const greenBarLength =
      freeTime <= 0 ? 0 : Math.floor((freeTime * 100) / maxValue);
    const yellowBar =
      freeTime <= 0
        ? 0
        : greenBarLength < 91
        ? 10
        : Math.abs(Number(100 - greenBarLength));
    const redBar =
      freeTime <= 0
        ? 100
        : Math.abs(
            greenBarLength < 91
              ? Number(100) - Number(greenBarLength + yellowBar)
              : 0
          );
    const currentValue = (value * 100) / maxValue;

    const getDropdownMessage = (type) => {
      return (
        <div
          className="flex p-3 mb-5 bg-white items-center shadow rounded-sm"
          style={{ width: 300 }}
          {...props}
        >
          <Typography.Text>
            {type === "green" && (
              <div className="flex items-center">
                <img style={{ width: 25 }} className="mr-2" src={HappyClock} />
                <Typography.Text>
                  Balance : What you typically do in the past.
                </Typography.Text>
              </div>
            )}
            {type === "yellow" && (
              <div className="flex items-center">
                <img style={{ width: 25 }} className="mr-2" src={OkClock} />{" "}
                <Typography.Text> Might be a little extra</Typography.Text>
              </div>
            )}
            {type === "red" && (
              <div className="flex items-center">
                <img style={{ width: 25 }} className="mr-2" src={SadClock} />
                <Typography.Text>
                  Health: Taking into account what you have planned and your
                  schedule for your health.
                </Typography.Text>
              </div>
            )}
          </Typography.Text>
        </div>
      );
    };

    return (
      <Dropdown
        overlay={
          <div style={{ marginLeft: `${valuePercentage}%` }}>
            {(value * 100) / maxValue < greenBarLength &&
              currentValue > 1 &&
              getDropdownMessage("green")}
            {currentValue < Number(greenBarLength + 10) &&
              currentValue > greenBarLength &&
              getDropdownMessage("yellow")}
            {currentValue > Number(greenBarLength + 10) &&
              getDropdownMessage("red")}
          </div>
        }
        visible={showDropdown}
        placement={"topRight"}
      >
        <div className="flex mt-2 items-baseline pl-3">
          <div
            className="w-full flex overflow-hidden"
            style={{ width: `${greenBarLength}%` }}
          >
            {[...Array(Math.floor(greenBarLength))].map((e, i) => (
              <span
                className="h-4 w-1.5 mr-1.5 bg-green-500"
                style={{ border: "1px solid #62C376", background: "#62C376" }}
                key={i}
              ></span>
            ))}
          </div>

          <div
            className=" w-full flex overflow-hidden"
            style={{ width: `${yellowBar}%` }}
          >
            {[...Array(Math.floor(yellowBar))].map((e, i) => (
              <span
                className="h-6 w-1.5 mr-1"
                style={{ border: "1px solid #F3A536", background: "#F3A536" }}
                key={i}
              ></span>
            ))}
          </div>

          <div
            className=" w-full flex overflow-hidden"
            style={{ width: `${redBar}%` }}
          >
            {[...Array(Math.floor(redBar))].map((e, i) => (
              <span
                className="h-9 w-1.5 mr-1.5"
                style={{ border: "1px solid red", background: "red" }}
                key={i}
              ></span>
            ))}
          </div>
        </div>
      </Dropdown>
    );
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={styled.timboxContainer}>
      <div className="flex items-end">
        <div className=" w-3/4 mr-4 px-2">
          <div>{getTimeBoxBars()}</div>
          <input
            type="range"
            min={minValue}
            max={maxValue}
            value={value}
            className={styled.sliderRange}
            id="myRange"
            onChange={handleChange}
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => {
              setShowDropdown(false);
            }}
            style={{
              background: `linear-gradient(
            to right,
            ${filledBgColor} 0%,
            ${filledBgColor} ${valuePercentage}%,
            ${bgColor} ${valuePercentage}%,
            ${bgColor} 100%
          )`,
            }}
          />
        </div>

        <div className="w-1/4">
          <TimeField
            value={timebox}
            colon=":"
            showSeconds
            onChange={(v) => setSliderValue(v.target.value)}
            style={{ width: "100%" }}
            className="flex text-center h-10 rounded p-2 text-lg"
          />
        </div>
      </div>
    </div>
  );
};

TimeBoxField.propTypes = {
  minValue: PropTypes.number,
  defaultMaxValue: PropTypes.number,
  defaultValue: PropTypes.number,
};

TimeBoxField.defaultProps = {
  minValue: 0,
  defaultMaxValue: 480,
};
