import React from "react";
import { Avatar, Tag, Typography } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import styles from "./styles.module.css";
import RallyIcon from "common/assets/rallyIcon.svg";

const RallyBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const query = { id };

  const handleRallyClick = () => {
    history.push({
      search: queryString.stringify({
        ...query,
        view: "rally",
      }),
    });
  };

  return (
    <div className={styles.rallyDiv} onClick={handleRallyClick}>
      <div>
        <Avatar
          className="mr-2"
          src="https://joeschmoe.io/api/v1/random"
          size="large"
        />
        <Avatar
          className="mr-2"
          src="https://joeschmoe.io/api/v1/random"
          size="large"
        />
        <Avatar
          className="mr-2"
          src="https://joeschmoe.io/api/v1/random"
          size="large"
        />
        <Avatar
          className="mr-2"
          src="https://joeschmoe.io/api/v1/random"
          size="large"
        />
      </div>
      {false === true && ( // TBD
        <div>
          <Typography.Text strong>
            Drag here to move the activity to rally
          </Typography.Text>
        </div>
      )}
      <div className="flex items-center">
        <img src={RallyIcon} alt="windu rally" className="mr-2" />
        <Typography.Text strong className="mr-2">
          Rally
        </Typography.Text>
        <Tag>3</Tag>
      </div>
    </div>
  );
};

export default RallyBar;
