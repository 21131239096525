import React from "react";
import { Typography } from "antd";

const { Text } = Typography;
const Step = ({ icon, title }) => {
  return (
    <div style={{ background: "#ECEFF3", padding: 10, marginBottom: " 5px" }}>
      {icon} <Text style={{ fontSize: "12px", paddingLeft: 5 }}>{title}</Text>
    </div>
  );
};
export default Step;
