import gql from "graphql-tag";

export const REMOVE_ACTIVITIES = gql`
  mutation ($input: removeActivitiesInput) {
    removeActivities(input: $input) {
      _id
      title
      project {
        _id
      }
    }
  }
`;
