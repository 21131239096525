import React from "react";
import { Divider, Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import { useRecoilValue, useRecoilState } from "recoil";
import cx from "classnames";
import Icons from "common/icons";
import Icon from "@ant-design/icons";
import { routing } from "constants/routing";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import { getCollapsed } from "recoil/atoms/Navigation/getCollapsed";
import { userSession } from "recoil/atoms/User/UserSession";
import NavBar from "components/NavBar";
import NavFooter from "./NavFooter";
import Handle from "./Handle";
import styles from "./styles.module.less";
import menuStyles from "components/NavBar/styles.module.less";
import Cookies from "js-cookie";

const { Sider } = Layout;

const WinduNavigation = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const selectedModeValue = useRecoilValue(selectedMode);
  const [collapsed, setCollapsed] = useRecoilState(getCollapsed);
  const user = useRecoilValue(userSession);

  const { pathname, search } = location;
  const paddingCollapsed = collapsed ? 0 : 25;

  const queryParams = queryString.parse(search);

  React.useEffect(() => {
    const route = _.find(
      routing,
      (route) => _.includes(location.pathname, route.path) && route.path !== "/"
    );

    if (_.isEmpty(user?.settings?.mode)) {
      history.replace("/quickstart");
    } else {
      if (selectedModeValue) {
        if (_.isEmpty(route) || !_.includes(route.modes, selectedModeValue)) {
          const withTour = queryString.parse(location?.search)?.withTour;
          const activityId = queryString.parse(location?.search)?.activityId;
          if (!withTour && !activityId) {
            history.replace("/");
          }
        }
      }
    }
  }, [selectedModeValue, location.pathname, user]);

  const getDefaultState = Cookies.get("collapsed");

  React.useEffect(() => {
    if (getDefaultState && getDefaultState === "true") {
      setCollapsed(getDefaultState);
    }
  }, []);

  return (
    <Layout>
      {selectedModeValue && (
        <Sider
          style={{ position: "relative", overflow: "visible" }}
          theme="light"
          className={cx(styles.winduNav, "tutorial-nav")}
          collapsed={collapsed}
          onCollapse={(value) => {
            setCollapsed(value);
            Cookies.set("collapsed", value);
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Handle
              collapsed={collapsed}
              setCollapsed={(value) => {
                setCollapsed(value);
                Cookies.set("collapsed", value);
              }}
            />
            <NavBar
              user={user}
              currentView={pathname}
              query={queryParams}
              setCollapsed={(value) => {
                setCollapsed(value);
                Cookies.set("collapsed", value);
              }}
              collapsed={collapsed}
            />
            <div>
              <div
                style={{
                  paddingLeft: paddingCollapsed,
                  paddingRight: paddingCollapsed,
                }}
              >
                <Menu
                  style={{ background: "#130935" }}
                  mode="vertical"
                  className="mt-3"
                  selectedKeys={[pathname]}
                >
                  <Menu.Item
                    key={"/settings"}
                    title={"Settings"}
                    onClick={() => {
                      history.push({
                        pathname: "/settings",
                      });
                    }}
                    className={cx(
                      "nav-item-windu",
                      styles.settingMenuItem,
                      `tutorial-settings`,
                      collapsed && styles.collapsedMenuItem
                    )}
                    icon={
                      <Icon
                        style={{
                          width: collapsed ? "100%" : "initial",
                          display: collapsed ? "flex" : "initial",
                          justifyContent: collapsed ? "center" : "initial",
                        }}
                        className={cx(
                          styles.icon,
                          "/settings" === pathname && styles.iconSelected,
                          "ml-2"
                        )}
                        component={Icons["Settings"]}
                      />
                    }
                  >
                    {!collapsed && "Settings"}
                  </Menu.Item>
                </Menu>
              </div>
              <Divider />
              <NavFooter collapsed={collapsed} />
            </div>
          </div>
        </Sider>
      )}

      {React.cloneElement(children)}
    </Layout>
  );
};
export default WinduNavigation;
