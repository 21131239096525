import gql from "graphql-tag";

export const CREATE_PROJECT = gql`
  mutation($input: createProjectInput) {
    createProject(input: $input) {
      _id
      created_at
      description
      title
      total_time
      total_activities
      activities {
        _id
        title
      }
    }
  }
`;
