import gql from "graphql-tag";

export const GET_INVEST_INVOICES = gql`
  query ($input: getInvestInvoiceInput) {
    getInvestInvoices(input: $input) {
      pagination {
        total_data
        total_pages
        current_page
      }
      data {
        _id
        name
        invoiceURL
        total_due
        currency
        stripe_invoice {
          hosted_url
        }
        status
        payment_due
        issue_date
        project {
          _id
          title
          color
        }
      }
    }
  }
`;
