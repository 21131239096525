import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { ArrowRight, Clock, DotsSixVertical } from "@phosphor-icons/react";
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Popover,
  Radio,
  Select,
  TimePicker,
  Typography,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  EditOutlined,
  ExpandAltOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import styles from "./styles.module.css";
import _ from "lodash";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";
import Loader from "components/Loader";
import { useMutation, useQuery } from "@apollo/client";
import EmptyData from "components/EmptyData";
import { UPDATE_ACTIVITY } from "graphql/mutations/Activity/updateActivity";
import { UPDATE_SELECTED_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateSelectedBacklogActivity";
import utility from "common/utility";
import { REMOVE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/removeBacklogActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { REMOVE_ACTIVITY } from "graphql/mutations/Activity/removeActivity";
import { Icon } from "@fishyvisions/windu-uikit";
import EditActivity from "components/Modals/EditActivity";
import NewBacklogActivityModal from "components/Modals/NewBacklogActivity";
import PlanActivityModal from "components/Modals/PlanActivity";
import ActivityHandoffModal from "components/Modals/ActivityHandoff";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import useOpenActivityDrawer from "common/openActivityDrawer";
import {
  CustomToolbarHeader,
  DayCalendarHeader,
  WeekCalendarHeader,
} from "./CalendarUtil";
import { workspacesList } from "recoil/atoms/Workspaces";
import RemovePastEvents from "components/Modals/RemovePastEvents";

const { Text } = Typography;

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarView = () => {
  const [isItemDragging, setisItemDragging] = useState(false);
  const [events, setEvents] = useState([]);
  const [activitySelectorDrawer, setActivitySelectorDrawer] = useState(false);
  const [selectedActivityView, setSelectedActivityView] = useState("All");
  const [allActivityData, setAllActivityData] = useState([]);
  const [selectedCalendarView, setSelectedCalendarView] = useState("week");
  const [handoffModal, setHandoffModal] = useState(false);
  const [selectedHandoffActivity, setSelectedHandoffActivity] = useState();
  const [editItem, setEditItem] = useState(null);
  const [showPlanActivityModal, setShowPlanActivityModal] =
    React.useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isBlankSlotSelected, setIsBlankSlotSelected] = useState(false);
  const [selectedActivityFromDrawer, setSelectedActivityFromDrawer] =
    useState(null);
  const [removePastEventModal, setRemovePastEventModal] = useState(false);

  const workspaceList = useRecoilValue(workspacesList);

  const calendarRef = useRef();
  const user = useRecoilValue(userSession);

  const formats = useMemo(
    () => ({
      timeGutterFormat: (date, culture, localizer) =>
        localizer.format(date, "h A", culture),
    }),
    []
  );

  const { openActivityDrawer } = useOpenActivityDrawer();

  const { loading, data, refetch } = useQuery(GET_PLAN_CALENDAR_ACTIVITY, {
    fetchPolicy: "cache-and-network",
  });

  const getFormattedData = (data) => {
    const plannedActivity = data.filter((item) => {
      if (!_.isEmpty(item?.planned) && item?.status !== 2) {
        const plannedEvents = item?.planned;
        const sortedEvents = [...plannedEvents].sort((a, b) =>
          moment(a?.start).isBefore(moment(b?.start)) ? -1 : 1
        );
        const plannedEvent = moment(_.last(sortedEvents).start).isAfter(
          moment().format("YYYY-MM-DD")
        );
        return plannedEvent;
      } else {
        return false;
      }
    });
    const pausedActivity = data?.filter((item) => item?.status === 2);
    const overdue = data?.filter((item) => {
      if (!_.isEmpty(item?.planned) && item?.status !== 2) {
        const plannedEvents = item?.planned;
        const sortedEvents = [...plannedEvents].sort((a, b) =>
          moment(a?.start).isBefore(moment(b?.start)) ? -1 : 1
        );
        const isOverdue = moment(_.last(sortedEvents).start).isBefore(
          moment().format("YYYY-MM-DD")
        );
        return isOverdue;
      } else {
        return false;
      }
    });
    const unplanned = data?.filter(
      (item) => _.isEmpty(item?.planned) && item?.status !== 2
    );
    const newData = {
      overdue,
      unplanned,
      planned: plannedActivity,
      paused: pausedActivity,
    };
    return newData;
  };
  useEffect(() => {
    if (data?.getPlanCalendarActivity) {
      let filteredData = getFormattedData(data?.getPlanCalendarActivity);

      setAllActivityData(filteredData);

      //  now we will filter the events from planned

      const plannedActivityData = data?.getPlanCalendarActivity?.filter(
        (item) => {
          return !_.isEmpty(item?.planned);
        }
      );
      // _id gonna be the planned _id and we gonna create
      let plannedEvents = [];
      _.forEach(plannedActivityData, (activity) => {
        activity.planned.forEach((element) => {
          plannedEvents.push({
            id: element._id,
            title: element?.title,
            start: moment(element.start).toDate(),
            end: moment(element.end).toDate(),
            ...activity,
          });
        });
      });
      setEvents(plannedEvents);
    }
  }, [data?.getPlanCalendarActivity]);

  useEffect(() => {
    if (data?.getPlanCalendarActivity) {
      const newData = getFormattedData(data?.getPlanCalendarActivity);

      if (_.isEmpty(selectedProject)) {
        setAllActivityData(newData);
      } else {
        const newFilteredData = _.mapValues(newData, (items) => {
          const filteredItems = items.filter((element) => {
            return element?.project?._id === selectedProject;
          });

          return _.isEmpty(filteredItems) ? [] : filteredItems;
        });
        setAllActivityData(newFilteredData);
      }
    }
  }, [selectedProject, data?.getPlanCalendarActivity]);

  useEffect(() => {
    const closeDrawerOnOutsideClick = (event) => {
      // Check if the click is outside the activity selector
      if (
        activitySelectorDrawer &&
        isBlankSlotSelected &&
        !document
          .getElementById("yourActivitySelectorId")
          .contains(event.target)
      ) {
        setIsBlankSlotSelected(false);
        // filter out the blank event
        const newEventData = events.filter(
          (event) => event.id !== "blankEvent"
        );
        setEvents(newEventData);
        setActivitySelectorDrawer(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", closeDrawerOnOutsideClick);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", closeDrawerOnOutsideClick);
    };
  }, [activitySelectorDrawer, isBlankSlotSelected]);

  const [updateActivity, { loading: updating }] = useMutation(UPDATE_ACTIVITY, {
    onCompleted: () => {},
  });

  const [updateBacklogActivity, { loading: updatingBacklogActivity }] =
    useMutation(UPDATE_SELECTED_BACKLOG_ACTIVITY, {
      onCompleted: () => {},
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  const [removeActivity] = useMutation(REMOVE_ACTIVITY, {
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Your activity has been removed!`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const [removeBacklogActivity] = useMutation(REMOVE_BACKLOG_ACTIVITY, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Activity has been removed`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const handleEventDrop = ({ event, start: eventStart, end: endtime }) => {
    let start = eventStart;
    let end = endtime;
    // check if start time is same as other event end time
    // Check if start time is the same as other event end times
    const startTimeOverlaps = events.some((item) => {
      return item.id !== event.id && moment(eventStart).isSame(item.end);
    });

    // Check if end time is the same as other event start times
    const endTimeOverlaps = events.some((item) => {
      return item.id !== event.id && moment(endtime).isSame(item.start);
    });
    if (startTimeOverlaps) {
      start = moment(eventStart).add(1, "minute").toDate();
    }

    if (endTimeOverlaps) {
      end = moment(endtime).subtract(1, "minute").toDate();
    }

    if (selectedCalendarView === "month") {
      utility.setNotification(
        "Drop Error",
        "You can't drop an event in the month view",
        "warning",
        "topRight"
      );
      return;
    }
    if (start < moment().toDate()) {
      utility.setNotification(
        "Error",
        `You can't drop an event in the past`,
        "error",
        "topRight"
      );
      return;
    }
    const updatedEvents = events.map((e) =>
      e.id === event.id ? { ...e, start, end } : e
    );

    // Check for event overlap
    if (!hasOverlap(updatedEvents, event.id)) {
      const updatedActivity = _.filter(updatedEvents, (item) => {
        return item?._id === event?._id;
      });
      const planActivityEvents = _.map(updatedActivity, (item) => {
        return {
          start: item.start,
          end: item.end,
          timebox: utility.calculateTimeDifference([item.end, item.start]),
        };
      });
      if (_.isEmpty(event?.workspace?._id)) {
        updateActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      } else {
        updateBacklogActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      }

      setEvents(updatedEvents);
    } else {
      utility.setNotification(
        "Overlapping events",
        "Event cannot overlap with other events",
        "warning",
        "topRight"
      );
    }
  };

  const hasOverlap = (eventList, currentEventId) => {
    const currentEvent = eventList.find((event) => event.id === currentEventId);

    if (!currentEvent) {
      return false; // Event not found in the list
    }

    for (const event of eventList) {
      if (
        event.id !== currentEventId &&
        moment(event.start).isSameOrBefore(currentEvent.end) &&
        moment(event.end).isSameOrAfter(currentEvent.start)
      ) {
        return true; // Overlapping events found
      }
    }

    return false; // No overlapping events
  };
  const handleEventResize = ({ event, start: eventStart, end: endtime }) => {
    let start = eventStart;
    let end = endtime;
    // check if start time is same as other event end time
    // Check if start time is the same as other event end times
    const startTimeOverlaps = events.some((item) => {
      return item.id !== event.id && moment(eventStart).isSame(item.end);
    });

    // Check if end time is the same as other event start times
    const endTimeOverlaps = events.some((item) => {
      return item.id !== event.id && moment(endtime).isSame(item.start);
    });

    // If there is an overlap, adjust start and end times accordingly
    if (startTimeOverlaps) {
      start = moment(eventStart).add(1, "minute").toDate();
    }

    if (endTimeOverlaps) {
      end = moment(endtime).subtract(1, "minute").toDate();
    }

    if (selectedCalendarView === "month") {
      utility.setNotification(
        "Resizing Error",
        "You can't resize an event in the month view",
        "warning",
        "topRight"
      );
      return;
    }

    if (start < moment().toDate()) {
      utility.setNotification(
        "Error",
        `You can't drop an event in the past`,
        "error",
        "topRight"
      );
      return;
    }

    const minEventDuration = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (end - start < minEventDuration) {
      utility.setNotification(
        "Error",
        "Event duration should be a minimum of 5 minutes",
        "warning",
        "topRight"
      );
      return;
    }

    const updatedEvents = events.map((e) =>
      e.id === event.id ? { ...e, start, end } : e
    );
    // Check for event overlap
    if (!hasOverlap(updatedEvents, event.id)) {
      const updatedActivity = _.filter(updatedEvents, (item) => {
        return item?._id === event?._id;
      });
      const planActivityEvents = _.map(updatedActivity, (item) => {
        return {
          start: item.start,
          end: item.end,
          timebox: utility.calculateTimeDifference([item.end, item.start]),
        };
      });
      if (_.isEmpty(event?.workspace?._id)) {
        updateActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      } else {
        updateBacklogActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      }
      setEvents(updatedEvents);
    } else {
      utility.setNotification(
        "Overlapping events",
        "Event cannot overlap with other events",
        "warning",
        "topRight"
      );
    }
  };

  const handleDropFromOutside = useCallback(
    ({ start, end, resource }) => {
      if (selectedCalendarView === "month") {
        utility.setNotification(
          "Resizing Error",
          "You can't resize an event in the month view",
          "warning",
          "topRight"
        );
        return;
      }
      if (isItemDragging) {
        if (start < moment().toDate()) {
          utility.setNotification(
            "Error",
            `You can't drop an event in the past`,
            "error",
            "topRight"
          );
          return;
        }
        const newEvent = {
          id: Math.random().toString(36).substr(2, 9),
          start,
          end,
          ...isItemDragging,
        };

        // check if from backlog or not
        if (isItemDragging?.workspace?._id) {
          // from backlog update backlog activity with the new event

          const payload = {
            activity: isItemDragging?._id,
            planned: [
              ...(isItemDragging?.planned || []),
              {
                start,
                end,
                timebox: "00:15:00",
              },
            ],
          };

          if (isItemDragging?.category === "unplanned") {
            payload.planned_date = moment(start).format();
          }

          updateBacklogActivity({
            variables: {
              input: payload,
            },
          });
        } else {
          // else non backlog activity
          updateActivity({
            variables: {
              input: {
                activity: isItemDragging?._id,
                planned: [
                  ...(isItemDragging?.planned || []),
                  {
                    start,
                    end,
                    timebox: "00:15:00",
                  },
                ],
              },
            },
          });
        }

        // remove it from unplanned and move to planned one
        if (isItemDragging?.category === "unplanned") {
          setAllActivityData((prevData) => {
            const updatedUnplanned = prevData.unplanned.filter(
              (item) => item._id !== isItemDragging?._id
            );
            return {
              ...prevData,
              unplanned: updatedUnplanned,
              planned: [
                ...prevData.planned,
                {
                  ...isItemDragging,
                  planned_date: moment(start).format(),
                },
              ],
            };
          });
        }
        if (isItemDragging?.category === "overdue") {
          setAllActivityData((prevData) => {
            const updatedOverdue = prevData.overdue.filter(
              (item) => item._id !== isItemDragging?._id
            );
            return {
              ...prevData,
              overdue: updatedOverdue,
              planned: [
                ...prevData.planned,
                {
                  ...isItemDragging,
                  planned_date: moment(start).format(),
                },
              ],
            };
          });

          setRemovePastEventModal(isItemDragging);
        }
        setEvents((prevEvents) => [...prevEvents, newEvent]);
      }
    },
    [isItemDragging]
  );

  const isPastTime = (time) => {
    const currentTime = new Date();
    return time < currentTime;
  };

  const handeRemoveEvent = (event, setVisible) => {
    let events = [];
    if (calendarRef && calendarRef.current) {
      events = calendarRef?.current?.props?.events;
    }
    const updatedEvents = events.filter((e) => e.id !== event.id);

    const updatedActivity = _.filter(updatedEvents, (item) => {
      return item?._id === event?._id;
    });
    const planActivityEvents = _.map(updatedActivity, (item) => {
      return {
        start: item.start,
        end: item.end,
        timebox: utility.calculateTimeDifference([item.end, item.start]),
      };
    });
    if (_.isEmpty(event?.workspace?._id)) {
      updateActivity({
        variables: {
          input: {
            activity: event?._id,
            planned: planActivityEvents,
          },
        },
      });
    } else {
      updateBacklogActivity({
        variables: {
          input: {
            activity: event?._id,
            planned: planActivityEvents,
          },
        },
      });
    }
    setEvents(updatedEvents);
    setVisible(false);
  };

  const handleUpdateEvent = (updatedDate, event, setVisible) => {
    const { end, start } = updatedDate;
    let events = [];
    if (calendarRef && calendarRef.current) {
      events = calendarRef?.current?.props?.events;
    }
    const updatedEvents = events.map((e) =>
      e.id === event.id ? { ...e, start: start.toDate(), end: end.toDate() } : e
    );
    // Check for event overlap
    const minEventDuration = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (end - start < minEventDuration) {
      utility.setNotification(
        "Error",
        "Event duration should be a minimum of 5 minutes",
        "warning",
        "topRight"
      );
      return;
    }
    if (!hasOverlap(updatedEvents, event.id)) {
      const updatedActivity = _.filter(updatedEvents, (item) => {
        return item?._id === event?._id;
      });
      const planActivityEvents = _.map(updatedActivity, (item) => {
        return {
          start: item.start,
          end: item.end,
          timebox: utility.calculateTimeDifference([item.end, item.start]),
        };
      });
      if (_.isEmpty(event?.workspace?._id)) {
        updateActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      } else {
        updateBacklogActivity({
          variables: {
            input: {
              activity: event?._id,
              planned: planActivityEvents,
            },
          },
        });
      }

      setEvents(updatedEvents);
      setVisible(false);
    } else {
      utility.setNotification(
        "Overlapping events",
        "Event cannot overlap with other events",
        "warning",
        "topRight"
      );
    }
  };

  const EventContentDropdown = ({ event, setVisible }) => {
    const initialValue = {
      title: event?.title || "",
      start: moment(event?.start) || "",
      end: moment(event?.end) || "",
      date: moment(event?.start),
      timebox:
        utility.calculateTimeDifference([event?.end, event?.start]) || "",
      id: event?.id,
    };
    const [curentEventData, setCurentEventData] = useState(initialValue);
    return (
      <div className={styles.eventDropdownClass}>
        <div>
          <Text>Title</Text>
          <Input
            value={initialValue.title}
            disabled
            className="w-full mt-2"
            size="large"
          />
        </div>
        <div className="mt-4">
          <Text>Date</Text>
          <DatePicker
            defaultValue={initialValue.date}
            className="w-full mt-2"
            size="large"
            disabledDate={(current) => {
              return moment(current).isBefore(
                moment(new Date()).subtract(1, "days")
              );
            }}
            onChange={(newDate) => {
              setCurentEventData((prev) => {
                const startTime = moment(prev.start);
                const newStartTime = moment(newDate).set({
                  hour: startTime.hours(),
                  minute: startTime.minutes(),
                  second: startTime.seconds(),
                });

                const endTime = moment(prev.end);
                const newEndTime = moment(newDate).set({
                  hour: endTime.hours(),
                  minute: endTime.minutes(),
                  second: endTime.seconds(),
                });

                return {
                  ...prev,
                  date: newDate,
                  start: newStartTime,
                  end: newEndTime,
                  timebox: utility.calculateTimeDifference([
                    newEndTime,
                    newStartTime,
                  ]),
                };
              });
            }}
            allowClear={false}
          />
        </div>
        <div className="mt-4">
          <Text>Duration</Text>
          <div className="flex items-center mt-2">
            <div className=" flex gap-2 items-center w-9/12">
              <TimePicker
                format="h:mm a"
                use12Hours
                value={curentEventData?.start}
                onChange={(v) =>
                  setCurentEventData((prev) => ({ ...prev, start: v }))
                }
                size="large"
                showNow={false}
                allowClear={false}
              />
              <ArrowRight size={22} color="#342d2d" />
              <TimePicker
                format="h:mm a"
                use12Hours
                value={curentEventData?.end}
                onChange={(v) =>
                  setCurentEventData((prev) => ({ ...prev, end: v }))
                }
                size="large"
                showNow={false}
                allowClear={false}
              />
            </div>

            <div className="ml-4 w-3/12">
              <Input
                disabled
                value={utility.calculateTimeDifference([
                  curentEventData?.end,
                  curentEventData?.start,
                ])}
                size="large"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end mt-8 mb-1.5">
          <Button
            type="primary"
            onClick={() =>
              handleUpdateEvent(curentEventData, event, setVisible)
            }
          >
            Save
          </Button>

          <Popconfirm
            title="Are you sure you want to remove this event."
            onConfirm={() => handeRemoveEvent(event, setVisible)}
            okText="Remove"
            cancelText="Cancel"
          >
            <Button className="ml-4" type="default" danger>
              Remove
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  const CustomEvent = ({ event }) => {
    const [dropDownVisible, setDropDownVisible] = useState(false);
    return (
      <Popover
        className="h-full"
        trigger="click"
        visible={dropDownVisible}
        content={() => (
          <EventContentDropdown event={event} setVisible={setDropDownVisible} />
        )}
        title={
          <div className="flex items-center justify-between p-2">
            <Text>Schedule Activity</Text>
            <div>
              <ExpandAltOutlined
                onClick={() => {
                  setDropDownVisible(false);
                  openActivityDrawer(event, true, event?.backlog !== null);
                }}
                size={26}
              />
              <CloseOutlined
                onClick={() => {
                  setDropDownVisible(false);
                }}
                className="ml-4"
                size={26}
              />
            </div>
          </div>
        }
        onOpenChange={(v) => setDropDownVisible(v)}
      >
        <div className="w-full flex flex-col my-1">
          <p className="font-semibold">{event?.key}</p>

          <p className="my-1">
            {moment(event?.start).format("h:mm a")} -{" "}
            {moment(event?.end).format("h:mm a")}
          </p>
          <p>{event.title}</p>
        </div>
      </Popover>
    );
  };

  const CustomEventWrapper = (props) => {
    const data = props.event;
    const eventClassNmae = props.children.props.children.props?.className;
    const projectColor = data?.project?.color || "";

    const projectColorClass = isPastTime(data?.start)
      ? ""
      : `projectColor${projectColor?.substring(1)}`;

    const newClassNmae = isPastTime(data?.start)
      ? styles.pastEvent
      : styles.futureEvent;
    const eventDiv = React.cloneElement(props.children.props.children, {
      className: `${eventClassNmae}  ${newClassNmae}  ${styles[projectColorClass]}`,
    });
    const wrapper = React.cloneElement(props.children, {}, eventDiv);
    return wrapper;
  };

  const calendarComponents = useMemo(
    () => ({
      week: {
        header: (v) => <WeekCalendarHeader v={v} calendarRef={calendarRef} />,
      },
      work_week: {
        header: (v) => <WeekCalendarHeader v={v} calendarRef={calendarRef} />,
      },
      day: {
        header: (v) => <DayCalendarHeader v={v} calendarRef={calendarRef} />,
      },
      timeGutterHeader: () => (
        <div className="flex w-full h-full items-center justify-center">
          <Clock size={30} color="#C3CAD9" />
        </div>
      ),
      toolbar: (toolbar) => (
        <CustomToolbarHeader
          toolbar={toolbar}
          setActivitySelectorDrawer={setActivitySelectorDrawer}
          setShowPlanActivityModal={setShowPlanActivityModal}
          setSelectedCalendarView={setSelectedCalendarView}
        />
      ),
      timeSlotWrapper: (props) => {
        const { value, children } = props;
        const style = isPastTime(value)
          ? { backgroundColor: "#fafafa" }
          : { backgroundColor: "white" };
        return React.cloneElement(React.Children.only(children), {
          style,
        });
      },
      event: CustomEvent,
      eventWrapper: CustomEventWrapper,
    }),
    []
  );

  const handleRemove = (item) => {
    const fromBacklog = item.backlog !== null;

    if (fromBacklog) {
      // call backlog mutation
      const payload = {
        activityId: item._id,
        backlogId: item?.backlog?._id,
      };

      if (item?.backlog?.group) {
        // if belongs to a group set the groupid
        payload.groupId = item?.backlog?.group;
      }
      removeBacklogActivity({ variables: { input: { ...payload } } });
    } else {
      removeActivity({
        variables: {
          input: {
            activity_id: item?._id,
          },
        },
      });
    }
  };

  const dropdownMenu = ({ activity }) => {
    const fromBacklog = activity.backlog !== null;
    const backlogData = activity.backlog;
    let wholeTeam = [];
    if (!fromBacklog) {
      wholeTeam = _.uniqBy(
        [
          activity?.project?.created_by,
          activity?.project?.manager_id,
          ...activity?.project?.members,
        ],
        "_id"
      );
    }

    const isOutDated = false;

    const isPausedActivity = activity?.status === 2;
    return (
      <Menu className="py-0 pt-1">
        {!isPausedActivity && (
          <>
            <Menu.Item key="10" className="py-4 flex">
              <div
                onClick={() =>
                  setEditItem({
                    activity: activity,
                    backlog: fromBacklog ? backlogData : false,
                  })
                }
              >
                <div className="flex items-center">
                  <EditOutlined style={{ fontSize: "20px" }} />
                  <div className="pl-3">Edit Activity</div>
                </div>
              </div>
            </Menu.Item>
            <Menu.Divider className="my-0" />
          </>
        )}

        {!isPausedActivity && (
          <Menu.Item key="3" className="py-4 flex">
            <Popconfirm
              title="Are you sure to remove this activity?"
              onConfirm={() => handleRemove(activity)}
              okText="Remove"
              cancelText="Cancel"
            >
              <div className="flex items-center">
                <DeleteOutlined style={{ fontSize: "20px" }} />
                <div className="pl-3">Remove Activity</div>
              </div>
            </Popconfirm>
          </Menu.Item>
        )}

        {!fromBacklog && _.size(wholeTeam) > 1 && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item key="4" className="py-4 flex">
              <div
                className="flex items-center"
                onClick={() => {
                  setHandoffModal(true);
                  setSelectedHandoffActivity(activity);
                }}
              >
                <UsergroupAddOutlined
                  style={{ fontSize: 20 }}
                  className="mr-2"
                />
                Hand Off
              </div>
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const handleCancleButton = () => {
    setActivitySelectorDrawer(false);
    setIsBlankSlotSelected(false);
    setSelectedActivityFromDrawer(null);
    // also remove that blank event from the events state
    const newEventData = events.filter((event) => event.id !== "blankEvent");
    setEvents(newEventData);
  };

  const handleSelectSlot = (slotInfo) => {
    // check if there is already a selected activity
    const eventsData = events.filter((event) => event.id !== "blankEvent");

    // slotInfo.start and slotInfo.end provide the selected time range

    // Create a new blank event object
    const newBlankEvent = {
      id: "blankEvent",
      start: slotInfo.start,
      end: slotInfo.end,
      title: "New Blank Event",
      allDay: false,
      isSelectActivity: true,

      // Other properties as needed
    };
    // check for past event
    if (isPastTime(slotInfo.start)) {
      utility.setNotification(
        "Past event",
        `You can't plan an event in the past`,
        "warning",
        "topRight"
      );
      return;
    }

    const newEventData = [...eventsData, newBlankEvent];

    if (hasOverlap(newEventData, newBlankEvent.id)) {
      utility.setNotification(
        "Overlap event",
        `You can't plan an event in the same time`,
        "warning",
        "topRight"
      );
      return;
    }

    setActivitySelectorDrawer(true);
    setIsBlankSlotSelected(true);
    // Update the events state to include the new blank event
    setEvents(newEventData);

    // Optionally, you can handle other logic such as opening a modal for further customization
    // showModalForNewEvent(newBlankEvent);
  };

  const handleAddButton = () => {
    const selectedSlot = events.find((event) => event.id === "blankEvent");

    const { start, end } = selectedSlot;
    const filteredEvents = _.filter(
      events,
      (event) => event.id !== "blankEvent"
    );

    if (selectedActivityFromDrawer) {
      if (start < moment().toDate()) {
        utility.setNotification(
          "Error",
          `You can't drop an event in the past`,
          "error",
          "topRight"
        );
        return;
      }
      const newEvent = {
        id: Math.random().toString(36).substr(2, 9),
        ...selectedActivityFromDrawer,
        start,
        end,
      };

      // // check if from backlog or not
      if (selectedActivityFromDrawer?.workspace?._id) {
        // from backlog update backlog activity with the new event

        const payload = {
          activity: selectedActivityFromDrawer?._id,
          planned: [
            ...(selectedActivityFromDrawer?.planned || []),
            {
              start,
              end,
              timebox: utility.calculateTimeDifference([end, start]),
            },
          ],
        };

        if (isItemDragging?.category === "unplanned") {
          payload.planned_date = moment(start).format();
        }

        updateBacklogActivity({
          variables: {
            input: payload,
          },
        });
      } else {
        // else non backlog activity
        updateActivity({
          variables: {
            input: {
              activity: selectedActivityFromDrawer?._id,
              planned: [
                ...(selectedActivityFromDrawer?.planned || []),
                {
                  start,
                  end,
                  timebox: utility.calculateTimeDifference([end, start]),
                },
              ],
            },
          },
        });
      }

      // remove it from unplanned and move to planned one
      if (selectedActivityFromDrawer?.category === "unplanned") {
        setAllActivityData((prevData) => {
          const updatedUnplanned = prevData.unplanned.filter(
            (item) => item._id !== selectedActivityFromDrawer?._id
          );
          return {
            ...prevData,
            unplanned: updatedUnplanned,
            planned: [
              ...prevData.planned,
              {
                ...selectedActivityFromDrawer,
                planned_date: moment(start).format(),
              },
            ],
          };
        });
      }

      if (selectedActivityFromDrawer?.category === "overdue") {
        setAllActivityData((prevData) => {
          const updatedOverdue = prevData.overdue.filter(
            (item) => item._id !== selectedActivityFromDrawer?._id
          );
          return {
            ...prevData,
            overdue: updatedOverdue,
            planned: [
              ...prevData.planned,
              {
                ...selectedActivityFromDrawer,
                planned_date: moment(start).format(),
              },
            ],
          };
        });

        setRemovePastEventModal(selectedActivityFromDrawer);
      }

      setEvents([...filteredEvents, newEvent]);

      setTimeout(() => {
        setActivitySelectorDrawer(false);
        setIsBlankSlotSelected(false);
        setSelectedActivityFromDrawer(null);
      }, 150);
    }
  };

  const clearPastEventAction = () => {
    const selectedEvent = _.find(events, (event) => {
      return (
        event._id === removePastEventModal?._id && !isPastTime(event.start)
      );
    });

    if (removePastEventModal?.workspace?._id) {
      updateBacklogActivity({
        variables: {
          input: {
            activity: removePastEventModal?._id,
            planned: [
              {
                start: selectedEvent?.start,
                end: selectedEvent?.end,
                timebox: utility.calculateTimeDifference([
                  selectedEvent?.end,
                  selectedEvent?.start,
                ]),
              },
            ],
          },
        },
      });
    } else {
      updateActivity({
        variables: {
          input: {
            activity: removePastEventModal?._id,
            planned: [
              {
                start: selectedEvent?.start,
                end: selectedEvent?.end,
                timebox: utility.calculateTimeDifference([
                  selectedEvent?.end,
                  selectedEvent?.start,
                ]),
              },
            ],
          },
        },
      });
    }

    const filteredEvents = _.filter(events, (event) => {
      if (event._id === removePastEventModal?._id) {
        return !isPastTime(event.start);
      } else {
        return true;
      }
    });

    setEvents(filteredEvents);

    setRemovePastEventModal(false);
  };

  if (loading) return <Loader />;

  return (
    <div className="flex bg-white rounded-lg py-4" style={{ height: "85vh" }}>
      <div
        id="yourActivitySelectorId"
        className={`${styles.activitySelector} ${
          activitySelectorDrawer
            ? styles.showActivitySelector
            : styles.hideActivitySelector
        }`}
      >
        <div className="px-2">
          <div className="w-full flex justify-between items-center mr-2 mb-2">
            <Text strong className="text-lg uppercase">
              Planned
            </Text>
            <DoubleLeftOutlined size={26} onClick={handleCancleButton} />
          </div>

          <div className="my-3">
            <Select
              className="w-full"
              options={_.map(workspaceList, (item) => ({
                label: item.project.title,
                value: item.project._id,
              }))}
              onChange={(value) => setSelectedProject(value)}
              showSearch
              placeholder="Select project"
              allowClear
            />
          </div>

          <div className={styles.radioButtonStyles}>
            <Radio.Group
              onChange={(v) => setSelectedActivityView(v.target.value)}
              defaultValue={selectedActivityView}
            >
              {_.map(
                ["All", "Overdue", "Paused", "Unplanned", "Planned"],
                (item) => (
                  <Radio.Button
                    size="small"
                    className="mr-2"
                    value={item}
                    key={item}
                  >
                    {item}
                  </Radio.Button>
                )
              )}
            </Radio.Group>
          </div>

          <div
            style={{ height: "66vh" }}
            className="flex justify-between flex-col"
          >
            <div
              className="mt-4 overflow-y-scroll"
              style={{ height: isBlankSlotSelected ? "88%" : "100%" }}
            >
              {!_.isEmpty(allActivityData) &&
                selectedActivityView !== "All" &&
                allActivityData[_.lowerCase(selectedActivityView)].map(
                  (activity) => (
                    <div
                      key={activity.id}
                      draggable={selectedCalendarView !== "month"}
                      onDragStart={() =>
                        setisItemDragging({
                          ...activity,
                          category: _.lowerCase(selectedActivityView),
                        })
                      }
                      onDragEnd={() => setisItemDragging(false)}
                      className="w-full p-2  flex items-center justify-between  rounded-lg mb-4 "
                      style={{
                        border: "0.786px solid rgba(0, 0, 0, 0.06)",
                        background: "#F8F8F8",
                      }}
                    >
                      <div className="flex items-center">
                        <div
                          className="flex items-center"
                          style={{ cursor: "grab" }}
                        >
                          {selectedCalendarView !== "month" &&
                            !isBlankSlotSelected && (
                              <DotsSixVertical size={24} color="#342d2d" />
                            )}
                          {isBlankSlotSelected && (
                            <Radio
                              checked={
                                activity?._id ===
                                selectedActivityFromDrawer?._id
                              }
                              onChange={() => {
                                setSelectedActivityFromDrawer({
                                  ...activity,
                                  category: _.lowerCase(selectedActivityView),
                                });
                              }}
                            />
                          )}

                          <Text style={{ color: "#344054" }} className="ml-2">
                            {activity?.key}
                          </Text>
                        </div>
                        <Divider className="mx-2" type="vertical" />
                        <div className="flex items-start flex-col">
                          <Text
                            className="text-base cursor-pointer"
                            strong
                            onClick={() => {
                              openActivityDrawer(
                                activity,
                                true,
                                activity?.backlog !== null
                              );
                            }}
                          >
                            {_.truncate(activity?.title, {
                              length: 20,
                              omission: "...",
                            })}
                          </Text>
                          <Text>
                            {!_.isEmpty(activity?.planned)
                              ? moment(
                                  _.first(activity?.planned)?.start
                                ).format("MMM DD YYYY")
                              : "Not set"}
                          </Text>
                        </div>
                      </div>

                      <div>
                        <Dropdown
                          overlay={() => dropdownMenu({ activity })}
                          trigger={["click"]}
                          arrow
                        >
                          <div className="cursor-pointer h-7 mx-2">
                            <Icon size="small" type="Meatball" />
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  )
                )}
              {!_.isEmpty(allActivityData) &&
                selectedActivityView !== "All" &&
                _.isEmpty(
                  allActivityData[_.lowerCase(selectedActivityView)]
                ) && (
                  <div className="mt-4">
                    <EmptyData />
                  </div>
                )}

              {!_.isEmpty(allActivityData) &&
                selectedActivityView === "All" &&
                Object.keys(allActivityData).map((category) => {
                  if (_.size(allActivityData[category]))
                    return (
                      <div key={category} className="mb-4">
                        <Text className="text-base font-semibold mb-4 capitalize">
                          {category}
                        </Text>
                        {allActivityData[category].map((activity) => (
                          <div
                            key={activity._id}
                            draggable={selectedCalendarView !== "month"}
                            onDragStart={() =>
                              setisItemDragging({
                                ...activity,
                                category: _.lowerCase(category),
                              })
                            }
                            onDragEnd={() => setisItemDragging(false)}
                            className="w-full p-2  flex items-center justify-between  rounded-lg mb-4"
                            style={{
                              border: "0.786px solid rgba(0, 0, 0, 0.06)",
                              background: "#F8F8F8",
                            }}
                          >
                            <div className="flex items-center">
                              <div
                                className="flex items-center"
                                style={{
                                  cursor: isBlankSlotSelected ? "auto" : "grab",
                                }}
                              >
                                {selectedCalendarView !== "month" &&
                                  !isBlankSlotSelected && (
                                    <DotsSixVertical
                                      size={24}
                                      color="#342d2d"
                                    />
                                  )}
                                {isBlankSlotSelected && (
                                  <Radio
                                    checked={
                                      activity?._id ===
                                      selectedActivityFromDrawer?._id
                                    }
                                    onChange={() => {
                                      setSelectedActivityFromDrawer({
                                        ...activity,
                                        category: _.lowerCase(category),
                                      });
                                    }}
                                  />
                                )}
                                <Text
                                  style={{ color: "#344054" }}
                                  className="ml-2"
                                >
                                  {activity?.key}
                                </Text>
                              </div>
                              <Divider className="mx-2" type="vertical" />
                              <div className="flex items-start flex-col">
                                <Text
                                  onClick={() => {
                                    openActivityDrawer(
                                      activity,
                                      true,
                                      activity?.backlog !== null
                                    );
                                  }}
                                  className="text-base cursor-pointer"
                                  strong
                                >
                                  {_.truncate(activity?.title, {
                                    length: 20,
                                    omission: "...",
                                  })}
                                </Text>
                                <Text>
                                  {!_.isEmpty(activity?.planned)
                                    ? moment(
                                        _.first(activity?.planned)?.start
                                      ).format("MMM DD YYYY")
                                    : "Not set"}
                                </Text>
                              </div>
                            </div>

                            <div>
                              <Dropdown
                                overlay={() => dropdownMenu({ activity })}
                                trigger={["click"]}
                                arrow
                              >
                                <div className="cursor-pointer h-7 mx-2">
                                  <Icon size="small" type="Meatball" />
                                </div>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                })}
            </div>
            {isBlankSlotSelected && (
              <div className="flex items-center justify-end ">
                <Button onClick={handleCancleButton}>Cancel</Button>
                <Button
                  disabled={_.isEmpty(selectedActivityFromDrawer)}
                  className="ml-4"
                  type="primary"
                  onClick={handleAddButton}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`${
          activitySelectorDrawer ? styles.calendarContainer : "w-full"
        }  ${styles.calendarContainerDiv}  ${
          selectedCalendarView === "day" ? styles.calendarContainerDivDay : ""
        } `}
      >
        <DnDCalendar
          ref={calendarRef}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          events={events}
          onEventDrop={handleEventDrop}
          onDropFromOutside={handleDropFromOutside}
          defaultView={selectedCalendarView}
          view={selectedCalendarView}
          views={
            selectedCalendarView === "work_week"
              ? ["day", "work_week"]
              : ["day", "week", "work_week", "month"]
          }
          scrollToTime={new Date(2023, 1, 1, 8)}
          onEventResize={handleEventResize}
          onView={(view) => setSelectedCalendarView(view)}
          resizable
          allDay={false}
          components={calendarComponents}
          formats={formats}
          step={15}
          timeslots={4}
          selectable={selectedCalendarView !== "month"}
          onSelectSlot={handleSelectSlot}
        />
      </div>

      {handoffModal && (
        <ActivityHandoffModal
          isVisible={handoffModal}
          setHandoffModal={setHandoffModal}
          selectedHandoffActivity={selectedHandoffActivity}
          callback={refetch}
        />
      )}
      {showPlanActivityModal && (
        <PlanActivityModal
          isVisible={showPlanActivityModal}
          onClose={() => setShowPlanActivityModal(false)}
        />
      )}

      {!_.isEmpty(editItem) && (
        <>
          {!_.isEmpty(editItem?.backlog) ? (
            <NewBacklogActivityModal
              isVisible={editItem}
              setEditItem={setEditItem}
              editingItem={{
                data: {
                  ...editItem?.activity,
                  assigned: user,
                  planned: editItem.activity.planned_date,
                  backlog: {
                    ...editItem.activity.backlog,
                    workspace: {
                      ...editItem.activity.backlog.workspace,
                      project: editItem.activity.project,
                    },
                  },
                },
              }}
              title={"Edit activity"}
              backlogId={editItem?.backlog._id} // backlog id
              onClose={() => {
                setEditItem(null);
              }}
              fromPlanned={true}
            />
          ) : (
            <EditActivity
              isVisible={editItem}
              editingItem={{ data: editItem?.activity }}
              title={"Edit activity"}
              onClose={() => {
                setEditItem(null);
              }}
              refetch={refetch}
            />
          )}
        </>
      )}

      {removePastEventModal && (
        <RemovePastEvents
          isVisible={removePastEventModal}
          setVisible={setRemovePastEventModal}
          handleContinue={clearPastEventAction}
        />
      )}
    </div>
  );
};

export default CalendarView;
