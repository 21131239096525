import gql from "graphql-tag";

export const GET_JIRA_ACTIVITY = gql`
  query {
    getJiraActivity {
      description
      title
      time {
        start
        end
        total_time
      }
      meta
      activity_type
    }
  }
`;
