import React, { useState, useEffect } from "react";
import Auth from "./Auth";
import { Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import TrelloLogo from "../../../common/assets/trelloLogo.png";
import { Menu, Button } from "antd";
import Card from "../Card";
import View from "./View";

import _ from "lodash";
import { UPDATE_TRELLO } from "graphql/mutations/Trello/updateTrello";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TRELLO_CARDS } from "graphql/queries/Trello/getTrelloCards";
import utility from "common/utility";
import { useAuthWidgets } from "../common/useAuthWidgets";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import EnlargeWidget from "components/Modals/Enlarge";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import { useRecoilState } from "recoil";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";

const Trello = ({ location }) => {
  const [selected, setSelected] = useState("board");
  const [authenticated, setAuthenticated] = useState(false);
  const { id } = queryString.parse(location.search);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);
  useAuthWidgets(authenticated, "TRELLO", id);

  const { createIntegration } = useCreateIntegrationHook();

  const { loading: widgetLoading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "trello", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setAuthenticated(getWorkspaceIntegration?.access_token);
      }
    },
  });

  const [updateTrello] = useMutation(UPDATE_TRELLO, {
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_TRELLO_CARDS,
        variables: { input: { workspace: id } },
      },
    ],
  });

  useEffect(() => {
    const { integration, code, id } = queryString.parse(location.search);
    /// handle auth
    if (integration === "trello" && code) {
      createIntegration({
        type: "trello",
        access_token: code,
        workspace: id,
      });
      setAuthenticated(code);
      setSelected("board");
      window.history.pushState({ path: `` }, "", `/workspace?id=${id}`);
    }
  }, [location]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const saveSync = (values) => {
    updateTrello({
      variables: {
        input: {
          workspace: id,
          sync: _.map(values?.settings?.sync, (item) => {
            return {
              list_push_finished: item.list_push_finished,
              list_pull: item.list_pull_clone,
              list_push_start: item.list_push_start,
              trello_board: item.trello_board,
              windu_project: item.windu_project,
            };
          }),
        },
      },
    });
    setSelected("board");
  };

  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "trello" });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="board">
        <a>View</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7546448023831-Trello"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );
  const bodyHeight = selected === "settings" ? "70%" : "83%";

  return authenticated ? (
    <Formik
      enableReinitialize={true}
      validationSchema={null}
      initialValues={{
        settings: {
          sync: [],
        },
      }}
      render={({ values, handleSubmit }) => (
        <Form>
          <Card
            bodyStyle={{
              height: enlargeModal.visible ? " 90% " : bodyHeight,
              overflow: "auto",
            }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: 25 }}
                  className="mr-3"
                  src={TrelloLogo}
                  alt="Trello"
                />
                <h5>Trello</h5>
              </div>
            }
            action={[
              selected === "settings" && (
                <div className="text-right pr-3">
                  <Button onClick={() => saveSync(values)} type="default">
                    Save
                  </Button>
                </div>
              ),
            ]}
            menu={menu}
          >
            <View view={selected} setSelected={setSelected} />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth
      setAuthenticated={setAuthenticated}
      isAuthenticated={authenticated}
      loading={widgetLoading}
      updateTrello={updateTrello}
    />
  );
};

export default withRouter(Trello);
