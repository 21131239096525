import React from "react";
import { Typography, Card, Button } from "antd";
import styles from "./styles.module.css";
import queryString from "query-string";
import { FileSearchOutlined } from "@ant-design/icons";
import { useRecoilValue, useRecoilState } from "recoil";
import _ from "lodash";
import { userSession } from "recoil/atoms/User/UserSession";
import utility from "common/utility";
import { payInvoice } from "recoil/atoms/Invoices/pay";

const InvoiceAttachment = ({ file, missingAccount }) => {
  const [isPaying, setPayInvoice] = useRecoilState(payInvoice);

  const user = useRecoilValue(userSession);
  const isClient = user?.user_type === "Client";
  const isInvoice = utility.hasInvoiceAttached(file);
  const params = queryString.parse(_.last(_.split(file, "?")));

  return (
    <Card
      style={{ boxShadow: "none" }}
      bodyStyle={{
        padding: 8,
        minWidth: 320,
      }}
    >
      <div className={styles.invoiceContainer}>
        <div className="flex flex-col">
          <FileSearchOutlined style={{ fontSize: "50px" }} />
        </div>
        <div className="flex ml-3 items-center">
          <div className="flex flex-col ml-2 text-left">
            <Typography.Text>
              {_.truncate(params.name, { length: 30 })}
            </Typography.Text>
            <Typography.Text>
              {_.truncate(params.period, { length: 30 })}
            </Typography.Text>
            {isClient && (
              <Button
                disabled={missingAccount}
                onClick={() => setPayInvoice(isInvoice)}
                style={{ width: 150 }}
              >
                Pay
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InvoiceAttachment;
