import gql from "graphql-tag";

export const GET_ALL_WORKSAPCE_WIDGET = gql`
  query ($workspace: ID!) {
    getAllWorksapceWidget(workspace: $workspace) {
      _id
      name
      label
      widget_category {
        name
        is_released
      }
      isInWorkspace
    }
  }
`;
