import { GET_TRELLO_CARDS } from "graphql/queries/Trello/getTrelloCards";
import React from "react";
import _ from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import utility from "common/utility";
import Loader from "components/Loader";
import {
  Collapse,
  Typography,
  Avatar,
  Row,
  Col,
  Tag,
  Button,
  Tooltip,
  Popconfirm,
} from "antd";
import cx from "classnames";

import Icons from "common/icons";
import Icon from "@ant-design/icons";
import moment from "moment";

import styles from "./styles.module.css";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { useRecoilState, useRecoilValue } from "recoil";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { CREATE_TRELLO_ACTIVITY } from "graphql/mutations/Trello/createTrelloActivity";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import { STOP_TRELLO_ACTIVITY } from "graphql/mutations/Trello/stopTrelloActivity";
import EmptyData from "components/EmptyData";
import { pausedActivities } from "recoil/atoms/PausedActivity";
import { useTimer } from "contexts/timer-context";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";

const { Panel } = Collapse;

const { Link, Text } = Typography;

const Board = ({ workspaceId, setSelected }) => {
  const user = useRecoilValue(userSession);
  const [boardData, setBoardData] = React.useState([]);
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [pausedActivity] = useRecoilState(pausedActivities);
  const { startHandler } = useTimer(); // global startHandler from context

  const { loading, refetch } = useQuery(GET_TRELLO_CARDS, {
    variables: { input: { workspace: workspaceId } },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getTrelloCards }) => {
      setBoardData(getTrelloCards);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });
  const [getCurrentActivity, { loading: getCurrentActivityLoading }] =
    useLazyQuery(GET_CURRENT_ACTIVITY, {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ getCurrentActivity }) => {
        if (_.has(getCurrentActivity, "time.start"))
          setActivity({
            active: true,
            data: getCurrentActivity,
          });
      },
    });

  const [pauseActivity, { loading: pauseLoading }] = useMutation(
    PAUSE_ACTIVITY,
    {
      onCompleted: ({ pauseActivity }) => {
        utility.setNotification(
          `${pauseActivity?.title} has been paused`,
          "",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );

  const [createTrelloActivity, { loading: startTimerLoading }] = useMutation(
    CREATE_TRELLO_ACTIVITY,
    {
      onCompleted: ({ createTrelloActivity }) => {
        utility.setNotification(
          `${createTrelloActivity?.title} started`,
          "You should be able to see your activity timer",
          "success",
          "topRight"
        );
        getCurrentActivity();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const [stopTrelloActivity, { loading: stopTrelloActiviy }] = useMutation(
    STOP_TRELLO_ACTIVITY,
    {
      onCompleted: ({ stopTrelloActivity }) => {
        utility.setNotification(
          `${stopTrelloActivity?.title} stopped`,
          "You should be able to see your activity created",
          "success",
          "topRight"
        );
        refetch();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );

  const handleStop = (board) => {
    stopTrelloActivity({
      variables: {
        input: {
          activity_id: activity?.data?._id,
          date_end: moment.utc(),
          trello_board_id: board.board_id,
        },
      },
    });
  };

  const handlePause = () => {
    const time = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const startTrelloActivity = (card, board) => {
    createTrelloActivity({
      variables: {
        input: {
          workspace: workspaceId,
          title: card.name,
          description: card?.desc,
          trello_card_id: card.id,
          trello_board_id: board.board_id,
          date_start: moment.utc(new Date()),
        },
      },
    });
  };

  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );

  const handleContinueActivity = (activity) => {
    const pausedCopy = _.cloneDeep(activity?.time?.paused);

    const lastPause = _.last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: { activity_id: activity._id, time: moment.utc(new Date()) },
      },
    });
  };

  const stopContinueActivity = (activity) => {
    const {
      _id,
      time: { paused },
      meta,
    } = activity[0];

    stopTrelloActivity({
      variables: {
        input: {
          activity_id: _id,
          date_end: _.last(paused)?.time,
          trello_board_id:
            utility.IsJsonString(meta) && JSON.parse(meta)?.board_id,
        },
      },
    });
  };

  if (loading) return <Loader size="large" />;

  if (_.isEmpty(boardData))
    return (
      <div className="flex items-center flex-col h-3/5">
        <EmptyData />
        <div className="flex flex-col items-center">
          <Typography.Text>No item available</Typography.Text>
          <Button
            onClick={() => setSelected("settings")}
            className="mt-2"
            type="default"
            style={{ background: "#004DC1", color: "white" }}
          >
            Set another board
          </Button>
        </div>
      </div>
    );

  return (
    <div>
      <Collapse
        expandIconPosition={"right"}
        bordered={false}
        defaultActiveKey={boardData[0]?.board_id}
      >
        {_.map(boardData, (board) => (
          <Panel
            header={
              <Typography.Text strong>{board.board_name}</Typography.Text>
            }
            key={board.board_id}
          >
            <div
              style={{
                backgroundImage:
                  board?.background !== null && `url(${board.background})`,
                backgroundColor:
                  board?.background === null && board?.background_color,
                backgroundSize: "cover",
              }}
              className="px-3 pt-2"
            >
              <Row gutter={[34, 34]}>
                {_.isEmpty(board?.cards) && (
                  <div className="m-12 p-4 text-base bg-white rounded flex justify-between">
                    <div className=" w-4/5 ">
                      <Typography.Text>
                        Sorry but currently you do not have anything to do!
                        Please make sure you are following windu's best
                        practices.
                      </Typography.Text>
                    </div>

                    <div className="bg-gray-200 w-1/5 rounded-md flex items-center justify-center  max-h-12 px-1">
                      <Typography.Link
                        target="_blank"
                        href="https://fishyvisions.zendesk.com/hc/en-us/sections/7546448023831-Trello"
                        className="text-base text-center"
                        style={{ color: "black" }}
                      >
                        Learn more
                      </Typography.Link>
                    </div>
                  </div>
                )}
                {_.map(board?.cards, (card) => {
                  const isInPaused = _.filter(pausedActivity, (paused) => {
                    return (
                      utility.IsJsonString(paused?.meta) &&
                      JSON.parse(paused?.meta)?.trello_card_id === card?.id
                    );
                  });
                  return (
                    <Col className="gutter-row my-2" span={12} key={card.id}>
                      <div className={styles.trelloTicketWrapper}>
                        {card?.due && (
                          <div className="mb-1">
                            <Tag color="magenta">
                              {moment(card?.due).diff(moment(), "days")} Days
                              Left
                            </Tag>
                          </div>
                        )}

                        <div className={styles.trelloTicketBody}>
                          <div className={styles.verticalStick} />
                          <div style={{ width: "100%" }} className={"px-3"}>
                            <Link target="_blank" href={card.url}>
                              <Text className={styles.ticketTitle}>
                                {_.truncate(card?.name, {
                                  length: 80,
                                })}
                              </Text>
                              <Text
                                className={styles.ticketDescription + " py-2"}
                              >
                                {_.truncate(card?.desc, {
                                  length: 130,
                                })}
                              </Text>
                            </Link>
                          </div>
                        </div>
                        {_.isEmpty(isInPaused) ? (
                          <div className={styles.trelloTicketFooter}>
                            {activity?.active &&
                            utility.IsJsonString(activity?.data?.meta) &&
                            JSON.parse(activity?.data?.meta)?.trello_card_id ===
                              card?.id ? (
                              <div
                                onClick={() => handlePause(card)}
                                className={cx(
                                  styles.timer,
                                  styles.timerPause,
                                  "cursor-pointer"
                                )}
                              >
                                <Icon
                                  className={cx(styles.icon, "mt-2")}
                                  component={Icons["Pause"]}
                                />
                              </div>
                            ) : (
                              <div
                                onClick={() => startTrelloActivity(card, board)}
                                className={cx(
                                  styles.timer,
                                  styles.timerOff,
                                  "cursor-pointer mr-2"
                                )}
                              >
                                <Icon
                                  className={styles.icon}
                                  component={Icons["Play"]}
                                />
                              </div>
                            )}

                            {activity?.active &&
                              utility.IsJsonString(activity?.data?.meta) &&
                              JSON.parse(activity?.data?.meta)
                                ?.trello_card_id === card?.id && (
                                <div
                                  onClick={() => handleStop(board)}
                                  className={cx(
                                    styles.timer,
                                    styles.timerOn,
                                    "cursor-pointer ml-2"
                                  )}
                                >
                                  <Icon
                                    className={styles.icon}
                                    component={Icons["Stop"]}
                                  />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className={styles.trelloTicketFooter}>
                            <div class="mr-5 ml-1 my-1.5 sm:mr-2 flex justify-beetwen items-center">
                              <Tooltip
                                placement="top"
                                title="Continue this task"
                              >
                                <Popconfirm
                                  placement="bottom"
                                  title="Click yes to continue this activity"
                                  onConfirm={() =>
                                    handleContinueActivity(isInPaused[0])
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Icon
                                    component={Icons.Continue}
                                    className="icon-medium mr-4"
                                  />
                                </Popconfirm>
                              </Tooltip>
                              <Tooltip placement="top" title="Finish this task">
                                <Popconfirm
                                  placement="bottom"
                                  title="Click yes to mark finished this activity"
                                  onConfirm={() =>
                                    stopContinueActivity(isInPaused)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Icon
                                    component={Icons.CheckMark}
                                    className="icon-medium mr-4"
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Panel>
        ))}
      </Collapse>
      {_.map(boardData, (item) => (
        <Typography.Title level={3}>{item.name}</Typography.Title>
      ))}
    </div>
  );
};

export default Board;
