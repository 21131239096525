import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

const InfoHover = ({ text, cta, color = "#ffffff" }) => {
  const learnMore = cta ? (
    <a href={cta} rel="noreferrer" target="_blank">
      Learn More
    </a>
  ) : (
    ""
  );
  return (
    <Popover className="ml-3" title={learnMore} content={text} trigger="hover">
      <InfoCircleOutlined style={{ color }} />
    </Popover>
  );
};

export default InfoHover;
