import React, { useEffect, useState } from "react";
import Panel from "./index";
import styles from "../styles.module.css";
import { isEmpty, map, isNil } from "lodash";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { workspaceSelected, workspacesList } from "recoil/atoms/Workspaces";
import { Select, Typography } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SLACK_CHANNEL_LIST } from "graphql/queries/workpace/slackChannelList";
import utility from "common/utility";
import { ADD_SLACK_BROADCAST } from "graphql/mutations/workspace/addSlackBroadcastChannel";

const SlackBroadcast = () => {
  const [channelList, setchannelList] = useState([]);
  const user = useRecoilValue(userSession);
  const workspace = useRecoilValue(workspaceSelected);

  const [getSlackChannelsList, { loading }] = useLazyQuery(SLACK_CHANNEL_LIST, {
    onCompleted: ({ getSlackChannelList }) => {
      setchannelList(getSlackChannelList);
    },
  });

  const [addSlackChannel] = useMutation(ADD_SLACK_BROADCAST, {
    onCompleted: ({ addSlackBroadcastChannel }) => {
      utility.setNotification(
        "Channel Saved",
        "This channel has been saved for this workspace",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        toString(error),
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (!isEmpty(user?.settings?.slack)) {
      getSlackChannelsList();
    }
  }, [workspace]);

  return (
    <Panel header={"Slack Broadcast"}>
      {isEmpty(user?.settings?.slack) ? (
        <div>
          <a
            href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=commands,links:read,links:write,chat:write&user_scope=`}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </div>
      ) : (
        <div className="flex flex-col">
          <Typography.Text className="font-medium">
            Please select the slack channel you want to broadcast you backlog
            activities.
          </Typography.Text>
          <Select
            loading={loading}
            defaultValue={workspace?.slack_broadcast_channel}
            options={map(channelList, (channel) => {
              return {
                value: channel?.id,
                label: channel?.name,
              };
            })}
            placeholder="Select Slack Channel"
            className="mt-2"
            size="large"
            showSearch
            onSelect={(channel) => {
              addSlackChannel({
                variables: {
                  input: { channelId: channel, workspaceId: workspace?._id },
                },
              });
            }}
          />
        </div>
      )}
    </Panel>
  );
};

export default SlackBroadcast;
