import gql from "graphql-tag";

export const RETRIEVE_CHECKOUT = gql`
  query($input: retrieveCheckoutInput) {
    retrieveCheckout(input: $input) {
      id
      customer
    }
  }
`;
