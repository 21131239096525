import React from "react";
import { Tooltip, Typography, Image, Badge, Popconfirm } from "antd";
import {
  CloudDownloadOutlined,
  DeleteFilled,
  EditFilled,
  StopOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import Linkify from "react-linkify";
import { useRecoilValue } from "recoil";
import moment from "moment";
import _ from "lodash";
import cx from "classnames";
import UserAvatar from "components/UserAvatar";
import { userSession } from "recoil/atoms/User/UserSession";
import { UPDATE_MESSAGE } from "graphql/mutations/message/updateMessage";
import { REMOVE_MESSAGE } from "graphql/mutations/message/removeMessage";
import InvoiceAttachment from "./InvoiceAttachment";
import styles from "./styles.module.css";
import utility from "common/utility";
import { extension } from "./common";
import AttachedFile from "./AttachedFile";
import MessageContent from "./MessageContent";

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

const imgExtension = ["jpeg", "png", "jpg"];

const Message = ({
  message,
  sent,
  userInfo,
  withAvatar,
  messageType,
  userRemoved = false,
  type = "",
  missingAccount,
}) => {
  const user = useRecoilValue(userSession);
  const [editing, setEditing] = React.useState(null);

  const showContent = message.content || editing === message.key;
  const showEdited = message.edited && editing !== message.key;
  const text = "Are you sure to delete this message?";

  const [updateMessage, { loading: updating }] = useMutation(UPDATE_MESSAGE);
  const [removeMessage, { loading: removing }] = useMutation(REMOVE_MESSAGE);

  const handleUpdateMessage = (value) => {
    updateMessage({
      variables: {
        input: {
          content: value,
          messageId: message._id,
        },
      },
    });
    setEditing(null);
  };

  const handleRemoveMessage = (value) => {
    removeMessage({
      variables: {
        messageId: value,
      },
    });
  };

  if (messageType === "group") {
    return (
      <div
        className={cx(
          "flex bg-white items-center justify-between hover:bg-gray-100 cursor-pointer px-2 group",
          {
            "py-2": withAvatar,
            "py-1": !withAvatar,
          }
        )}
      >
        <div style={{ width: "100%" }} className="flex items-center">
          <div>
            {withAvatar && !userRemoved && (
              <UserAvatar user={userInfo} size={40} className="mr-2" />
            )}
            {withAvatar && userRemoved && (
              <StopOutlined className="mr-2 icon-xlarge" />
            )}
          </div>
          <div style={{ width: "100%" }} className="mr-4">
            {withAvatar && (
              <div>
                <Typography.Text className="font-semibold text-base">
                  {userInfo.full_name}
                  <Typography.Text className="font-normal ml-3 text-sm text-gray-400">
                    {moment(message.created_at).format("h:mm A MMM DD, YYYY")}
                  </Typography.Text>
                  {userRemoved && (
                    <Typography.Text
                      className="font-normal ml-3 opacity-100"
                      type="danger"
                    >
                      (removed)
                    </Typography.Text>
                  )}
                </Typography.Text>
              </div>
            )}

            {!withAvatar && (
              <Tooltip
                placement="topRight"
                title={moment(message.created_at).format("h:mm A MMM DD, YYYY")}
              >
                <div className="block">
                  {showContent && (
                    <MessageContent
                      editing={editing}
                      showEdited={showEdited}
                      type={type}
                      message={message}
                      setEditing={() => setEditing(null)}
                      handleUpdateMessage={(value) =>
                        handleUpdateMessage(value)
                      }
                      withAvatar={withAvatar}
                    />
                  )}

                  {_.size(message.fileSources) > 0 && (
                    <div className="flex pl-12 break-words cursor-pointer">
                      {_.map(message.fileSources, (file, index) => {
                        if (!imgExtension.includes(extension(file))) {
                          return (
                            <Badge
                              key={index}
                              offset={[0, 10]}
                              className={`mr-4 ${styles.imgContainer}`}
                            >
                              {utility.hasInvoiceAttached(file) ? (
                                <InvoiceAttachment
                                  file={file}
                                  message={message}
                                  missingAccount={missingAccount}
                                />
                              ) : (
                                <AttachedFile index={index} file={file} />
                              )}
                            </Badge>
                          );
                        }

                        return (
                          <Badge
                            key={index}
                            count={
                              <a href={file} download>
                                <CloudDownloadOutlined className="icon bg-white rounded-full p-1 z-10 hover:bg-red" />
                              </a>
                            }
                            offset={[0, 10]}
                            className={`mr-4 ${styles.imgContainer}`}
                          >
                            <Image src={file} className="max-w-lg	max-h-lg" />
                          </Badge>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Tooltip>
            )}
            {withAvatar && (
              <>
                {showContent && (
                  <MessageContent
                    editing={editing}
                    showEdited={showEdited}
                    type={type}
                    message={message}
                    setEditing={() => setEditing(null)}
                    handleUpdateMessage={(value) => handleUpdateMessage(value)}
                    withAvatar={withAvatar}
                  />
                )}

                {_.size(message.fileSources) > 0 && (
                  <div className="flex mb-1 mt-2 cursor-pointer">
                    {_.map(message.fileSources, (file, index) => {
                      if (!imgExtension.includes(extension(file))) {
                        return (
                          <Badge
                            key={index}
                            offset={[0, 10]}
                            className={`mr-4 ${styles.imgContainer}`}
                          >
                            {utility.hasInvoiceAttached(file) ? (
                              <InvoiceAttachment
                                file={file}
                                message={message}
                                missingAccount={missingAccount}
                              />
                            ) : (
                              <AttachedFile index={index} file={file} />
                            )}
                          </Badge>
                        );
                      }

                      return (
                        <Badge
                          key={index}
                          count={
                            <a href={file} download>
                              <CloudDownloadOutlined className="icon bg-white rounded-full p-1" />
                            </a>
                          }
                          offset={[0, 10]}
                          className={`mr-4 ${styles.imgContainer}`}
                        >
                          <Image src={file} className="max-w-lg	max-h-lg" />
                        </Badge>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {message.from.email === user.email && (
          <div className="flex justify-around text-white group-hover:text-gray-400">
            <Tooltip title="Edit message">
              <EditFilled
                className="icon-medium"
                onClick={() => setEditing(message.key)}
              />
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={text}
              onConfirm={() => handleRemoveMessage(message._id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Remove message">
                <DeleteFilled className="icon-medium" />
              </Tooltip>
            </Popconfirm>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cx("flex my-1 cursor-pointer", {
        "ml-auto": sent,
        "mr-auto": !sent,
      })}
    >
      {withAvatar && !sent && !userRemoved && (
        <div style={{ minWidth: 48 }}>
          <UserAvatar user={userInfo} size={40} className="mr-2" />
        </div>
      )}

      {withAvatar && !sent && userRemoved && (
        <div style={{ minWidth: 48 }}>
          <Tooltip placement="top" title={"User has been removed"}>
            <StopOutlined className="mr-2 icon-xlarge" />
          </Tooltip>
        </div>
      )}

      <Tooltip
        placement={!sent ? "topLeft" : "topRight"}
        title={moment(message.created_at).format("MMMM DD, YYYY h:mm a")}
      >
        <div
          className={cx(
            "py-2 px-3 rounded-bl-lg rounded-br-lg max-w-lg break-words",
            {
              [styles.incomingMessages]: !sent,
              "bg-gray-200": sent,
              "rounded-tr-lg": !sent,
              "rounded-tl-lg": sent,
              "ml-12": !withAvatar,
              "mr-12": !withAvatar,
            }
          )}
        >
          <div
            className={cx(
              { "text-white": !sent, "text-black": sent },
              "break-words"
            )}
          >
            <Linkify componentDecorator={componentDecorator}>
              {message.content}
            </Linkify>
          </div>
        </div>
      </Tooltip>
      {withAvatar && sent && (
        <div style={{ minWidth: 48 }}>
          <UserAvatar user={userInfo} size={40} className="ml-2" />
        </div>
      )}
    </div>
  );
};

export default Message;
