import {
  CalendarBlank,
  CaretDown,
  ClockClockwise,
  Moon,
  Sun,
  SunHorizon,
  Warning,
} from "@phosphor-icons/react";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Radio,
  Typography,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import DragHandle from "../DragHandle";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EmptyData from "components/EmptyData";
import styles from "./styles.module.css";
import { Icon } from "@fishyvisions/windu-uikit";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import ActivityHandoffModal from "components/Modals/ActivityHandoff";
import PlanActivityModal from "components/Modals/PlanActivity";
import EditActivity from "components/Modals/EditActivity";
import NewBacklogActivityModal from "components/Modals/NewBacklogActivity";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState, useRecoilValue } from "recoil";
import useOpenActivityDrawer from "common/openActivityDrawer";
import { activeActivity } from "recoil/atoms/Timer/activity";
import utility from "common/utility";
import { REMOVE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/removeBacklogActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { REMOVE_ACTIVITY } from "graphql/mutations/Activity/removeActivity";
import HandleStartActivity from "./handleStartActivity";

const { Title, Text } = Typography;

const PlannedWidget = () => {
  const [activity, setActivity] = useRecoilState(activeActivity);
  const user = useRecoilValue(userSession);
  const [allActivityData, setAllActivityData] = useState([]);
  const [selectedActivityView, setSelectedActivityView] = useState("Today");
  const [showPlanActivityModal, setShowPlanActivityModal] =
    React.useState(false);
  const [handoffModal, setHandoffModal] = useState(false);
  const [selectedHandoffActivity, setSelectedHandoffActivity] = useState();
  const [editItem, setEditItem] = useState(null);

  const { openActivityDrawer } = useOpenActivityDrawer();

  const history = useHistory();

  const { loading, data, refetch } = useQuery(GET_PLAN_CALENDAR_ACTIVITY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.getPlanCalendarActivity) {
      const plannedActivity = data?.getPlanCalendarActivity?.filter(
        (item) =>
          item?.planned_date &&
          (moment(item?.planned_date).isAfter(moment()) ||
            moment(item?.planned_date).isSame(moment()))
      );

      const unplanned = data?.getPlanCalendarActivity?.filter((item) =>
        _.isEmpty(item?.planned)
      );
      //  now we will filter the events from planned

      const plannedActivityData = data?.getPlanCalendarActivity?.filter(
        (item) => {
          return !_.isEmpty(item?.planned);
        }
      );
      // _id gonna be the planned _id and we gonna create
      let plannedEvents = [];
      _.forEach(plannedActivityData, (activity) => {
        activity.planned.forEach((element) => {
          plannedEvents.push({
            id: element._id,
            title: element?.title,
            start: moment(element.start).toDate(),
            end: moment(element.end).toDate(),
            ...activity,
          });
        });
      });

      const todayPlannedEvents = plannedEvents.filter((event) =>
        moment(event.start).isSame(moment(), "day")
      );
      const todayPlannedEventsSorted = todayPlannedEvents.sort((a, b) =>
        moment(a.start).isBefore(moment(b.start)) ? -1 : 1
      );

      // planned for today but not set events
      // const todayPlannedActivity = data?.getPlanCalendarActivity.filter(
      //   (event) =>
      //     moment(event?.planned_date).isSame(moment(), "day") &&
      //     _.isEmpty(event?.planned)
      // );
      // upcoming events
      const upcomingEvents = plannedEvents.filter((event) =>
        moment(event.start).isAfter(moment(), "day")
      );
      const upcomingEventsSorted = upcomingEvents.sort((a, b) =>
        moment(a.start).isBefore(moment(b.start)) ? -1 : 1
      );
      // which are planned by dates not by events
      // const upcomingActivities = data?.getPlanCalendarActivity.filter(
      //   (item) =>
      //     item?.planned_date &&
      //     moment(item?.planned_date).isAfter(moment()) &&
      //     _.isEmpty(item?.planned)
      // );

      const overdueEvents = plannedEvents.filter(
        (event) =>
          moment(event.end).isBefore(moment(), "day") &&
          !_.isEmpty(event?.planned)
      );
      const overdueEventsSorted = overdueEvents.sort((a, b) =>
        moment(a.end).isBefore(moment(b.end)) ? -1 : 1
      );
      // which are planned by dates not by events
      // const overdueActivities = data?.getPlanCalendarActivity.filter(
      //   (item) =>
      //     item?.planned_date &&
      //     moment(moment(item?.planned_date).format("DD/MM/YYYY")).isBefore(
      //       moment()
      //     ) &&
      //     _.isEmpty(item?.planned)
      // );

      const newData = {
        overdue: overdueEventsSorted,
        unplanned,
        planned: plannedActivity,
        today: todayPlannedEventsSorted,
        upcoming: upcomingEventsSorted,
      };
      setAllActivityData(newData);
    }
  }, [data?.getPlanCalendarActivity]);

  const [removeActivity] = useMutation(REMOVE_ACTIVITY, {
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Your activity has been removed!`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const [removeBacklogActivity] = useMutation(REMOVE_BACKLOG_ACTIVITY, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Activity has been removed`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const showCalendarView = () => {
    history.push({
      pathname: `/calendar`,
    });

    if (_.isNil(localStorage.getItem("wstabs"))) {
      const payload = JSON.stringify(["calendar"]);

      localStorage.setItem("wstabs", payload);
    } else {
      const data = JSON.parse(localStorage.getItem("wstabs"));
      const payload = JSON.stringify(_.uniq([...data, "calendar"]));

      localStorage.setItem("wstabs", payload);
    }
  };

  const getEventTimeText = (key) => {
    if (key === "morning") {
      return (
        <div className="flex items-center my-4">
          <SunHorizon size={24} color="#342d2d" weight="bold" />
          <Text className="text-base ml-2" strong>
            Morning
          </Text>
        </div>
      );
    }
    if (key === "afternoon") {
      return (
        <div className="flex items-center my-4">
          <Sun size={24} color="#342d2d" weight="bold" />
          <Text className="text-base ml-2" strong>
            Afternoon
          </Text>
        </div>
      );
    }
    if (key === "evening") {
      return (
        <div className="flex items-center my-4">
          <Moon size={24} color="#342d2d" weight="bold" />
          <Text className="text-base ml-2" strong>
            Evening
          </Text>
        </div>
      );
    }

    return (
      <div className="flex items-center my-4">
        <ClockClockwise size={24} color="#342d2d" weight="bold" />
        <Text className="text-base ml-2" strong>
          Open time
        </Text>
      </div>
    );
  };

  const handleRemove = (item) => {
    const fromBacklog = item.backlog !== null;

    if (fromBacklog) {
      // call backlog mutation
      const payload = {
        activityId: item._id,
        backlogId: item?.backlog?._id,
      };

      if (item?.backlog?.group) {
        // if belongs to a group set the groupid
        payload.groupId = item?.backlog?.group;
      }
      removeBacklogActivity({ variables: { input: { ...payload } } });
    } else {
      removeActivity({
        variables: {
          input: {
            activity_id: item?._id,
          },
        },
      });
    }
  };

  const ActivityCard = ({ activity, type }) => {
    const [isHovered, setIsHovered] = useState(false);
    const fromBacklog = activity.backlog !== null;
    const backlogData = activity.backlog;
    let wholeTeam = [];
    if (!fromBacklog) {
      wholeTeam = _.uniqBy(
        [
          activity?.project?.created_by,
          activity?.project?.manager_id,
          ...activity?.project?.members,
        ],
        "_id"
      );
    }

    const isOutDated = false;

    const isPausedActivity = activity?.status === 2;

    const project = fromBacklog
      ? activity.backlog?.workspace?.project
      : activity.project;

    const menu = (
      <Menu className="py-0 pt-1">
        {!isPausedActivity && (
          <>
            <Menu.Item key="10" className="py-4 flex">
              <div
                onClick={() =>
                  setEditItem({
                    activity: activity,
                    backlog: fromBacklog ? backlogData : false,
                  })
                }
              >
                <div className="flex items-center">
                  <EditOutlined style={{ fontSize: "20px" }} />
                  <div className="pl-3">Edit Activity</div>
                </div>
              </div>
            </Menu.Item>
            <Menu.Divider className="my-0" />
          </>
        )}

        {!isPausedActivity && (
          <Menu.Item key="3" className="py-4 flex">
            <Popconfirm
              title="Are you sure to remove this activity?"
              onConfirm={() => handleRemove(activity)}
              okText="Remove"
              cancelText="Cancel"
            >
              <div className="flex items-center">
                <DeleteOutlined style={{ fontSize: "20px" }} />
                <div className="pl-3">Remove Activity</div>
              </div>
            </Popconfirm>
          </Menu.Item>
        )}

        {!fromBacklog && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              key="4"
              className="py-4 flex"
              disabled={_.size(wholeTeam) === 1}
            >
              <div
                className="flex items-center"
                onClick={() => {
                  if (_.size(wholeTeam) !== 1) {
                    setHandoffModal(true);
                    setSelectedHandoffActivity(activity);
                  }
                }}
              >
                <UsergroupAddOutlined
                  style={{ fontSize: 20 }}
                  className="mr-2"
                />
                Hand Off
              </div>
            </Menu.Item>
          </>
        )}
      </Menu>
    );

    if (type === "today") {
      return (
        <div
          key={activity.id}
          className="w-full p-2  flex items-center justify-between  rounded-lg mb-4 "
          style={{
            border: "0.786px solid rgba(0, 0, 0, 0.06)",
            background: "#F8F8F8",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="flex items-center">
            <div className="flex items-center">
              {activity?.start ? (
                <Text style={{ color: "#344054", minWidth: 42 }} strong>
                  {moment(activity?.start).format("hA")}
                </Text>
              ) : (
                <div className="flex items-center flex-col">
                  <Text
                    style={{ color: "#344054" }}
                    strong
                    className="uppercase"
                  >
                    {moment(activity?.planned_date).format("MMM")}
                  </Text>
                  <Text style={{ color: "#344054" }} className="text-lg ">
                    {moment(activity?.planned_date).format("DD")}
                  </Text>
                </div>
              )}
            </div>
            <Divider className="mx-2" type="vertical" />
            <div className="flex items-start flex-col">
              <Text
                style={{ color: "#344054" }}
                className="text-base  cursor-pointer"
                strong
                onClick={() => {
                  openActivityDrawer(activity, true, fromBacklog);
                }}
              >
                {_.truncate(activity?.title, {
                  length: 30,
                  omission: "...",
                })}
              </Text>
              {activity?.start ? (
                <Text style={{ color: "#344054" }}>
                  {moment(activity?.start).format("hh:mm A")} -
                  {moment(activity?.end).format("hh:mm A")}
                </Text>
              ) : (
                <Text style={{ color: "#344054" }}>
                  {moment(activity?.planned_date).format("DD MMM YYYY")}
                </Text>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <HandleStartActivity
              item={activity}
              isPausedActivity={isPausedActivity}
              isHovered={isHovered}
            />
            <Dropdown overlay={menu} trigger={["click"]} arrow>
              <div className="cursor-pointer h-7 mx-2">
                <Icon size="small" type="Meatball" />
              </div>
            </Dropdown>
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={activity.id}
          className="w-full p-2  flex items-center justify-between  rounded-lg mb-4 "
          style={{
            border: "0.786px solid rgba(0, 0, 0, 0.06)",
            background: "#F8F8F8",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="flex items-center">
            <div className="flex items-center flex-col">
              <Text style={{ color: "#344054" }} strong className="uppercase">
                {activity?.start
                  ? moment(activity?.start).format("MMM")
                  : activity?.key}
              </Text>
              <Text style={{ color: "#344054" }} className="text-lg ">
                {activity?.start && moment(activity?.start).format("DD")}
              </Text>
            </div>
            <Divider className="mx-2" type="vertical" />
            <div className="flex items-start flex-col">
              <Text
                className="text-base cursor-pointer"
                style={{ color: "#344054" }}
                strong
                onClick={() => {
                  openActivityDrawer(activity, true, fromBacklog);
                }}
              >
                {_.truncate(activity?.title, {
                  length: 30,
                  omission: "...",
                })}
              </Text>
              {activity?.start && (
                <Text style={{ color: "#344054" }}>
                  {moment(activity?.start).format("hh:mm A")} -
                  {moment(activity?.end).format("hh:mm A")}
                </Text>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <HandleStartActivity
              item={activity}
              isHovered={isHovered}
              isPausedActivity={isPausedActivity}
            />
            <Dropdown overlay={menu} trigger={["click"]} arrow>
              <div className="cursor-pointer h-7 mx-2">
                <Icon size="small" type="Meatball" />
              </div>
            </Dropdown>
          </div>
        </div>
      );
    }
  };

  const ActivityList = () => {
    if (selectedActivityView === "Today") {
      const newformattedData = {
        morning: [],
        afternoon: [],
        evening: [],
        notset: [],
      };

      allActivityData[_.lowerCase(selectedActivityView)].forEach((activity) => {
        const startTime = moment(activity.start);
        const hour = startTime.hours();

        if (activity?.start) {
          if (hour >= 6 && hour < 12) {
            newformattedData.morning.push(activity);
          } else if (hour >= 12 && hour < 18) {
            newformattedData.afternoon.push(activity);
          } else if (hour >= 18 && hour < 24) {
            newformattedData.evening.push(activity);
          } else {
            newformattedData.notset.push(activity);
          }
        } else {
          newformattedData.notset.push(activity);
        }
      });

      return (
        <div>
          {Object.keys(newformattedData).map((key) => {
            if (newformattedData[key].length > 0) {
              return (
                <div key={key}>
                  {getEventTimeText(key)}
                  {newformattedData[key].map((activity) => (
                    <ActivityCard type={"today"} activity={activity} />
                  ))}
                </div>
              );
            }
          })}
        </div>
      );
    }
    return (
      <div>
        {allActivityData[_.lowerCase(selectedActivityView)].map((activity) => {
          return <ActivityCard activity={activity} />;
        })}
      </div>
    );
  };

  return (
    <>
      <DragHandle />
      <Card
        bodyStyle={{ padding: 20 }}
        className="h-full shadow-none border-none outline-none tour-plannedwidget"
        loading={loading}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Title level={4}>Plan</Title>
          </div>
          <div className="flex items-center ">
            <div>
              <Dropdown
                placement="bottom"
                style={{ padding: 0 }}
                className={styles.popoverDiv}
                overlay={
                  <Menu className="p-0 m-0">
                    <Menu.Item className="flex items-center cursor-default">
                      <Badge
                        count={_.size(allActivityData[_.lowerCase("overdue")])}
                        color="#F8F8F8"
                        style={{ color: "black" }}
                        showZero
                      />
                      <Text className="ml-2">Overdue</Text>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item className="flex items-center cursor-default">
                      <Badge
                        count={_.size(
                          allActivityData[_.lowerCase("unplanned")]
                        )}
                        color="#F8F8F8"
                        style={{ color: "black" }}
                        showZero
                      />
                      <Text className="ml-1">Unplanned</Text>
                    </Menu.Item>
                  </Menu>
                }
              >
                <div className="flex items-center gap-1 py-1.5 px-3 border-2 rounded">
                  <Badge
                    count={
                      _.size(allActivityData[_.lowerCase("overdue")]) +
                      _.size(allActivityData[_.lowerCase("unplanned")])
                    }
                    color={
                      _.size(allActivityData[_.lowerCase("overdue")]) +
                        _.size(allActivityData[_.lowerCase("unplanned")]) >
                      0
                        ? "error"
                        : "green"
                    }
                  />
                  <Warning size={24} color="#312b2b" />
                  <CaretDown size={24} color="#312b2b" />
                </div>
              </Dropdown>
            </div>

            <Button
              shape="circle"
              size="large"
              className="bg-transparent ml-4 flex items-center justify-center"
              icon={<CalendarBlank size={24} color="#342d2d" />}
              onClick={() => {
                showCalendarView();
              }}
            />
          </div>
        </div>

        <div className={styles.radioButtonStyles}>
          <Radio.Group
            onChange={(v) => setSelectedActivityView(v.target.value)}
            defaultValue={selectedActivityView}
          >
            {_.map(["Today", "Upcoming"], (item) => (
              <Radio.Button
                size="small"
                className="mr-2"
                value={item}
                key={item}
              >
                {item}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className="mt-2 overflow-y-auto" style={{ height: 680 }}>
          {!_.isEmpty(allActivityData[_.lowerCase(selectedActivityView)]) ? (
            <ActivityList allActivityData={allActivityData} />
          ) : (
            <div className="mt-4">
              <EmptyData />
            </div>
          )}
        </div>

        <div>
          <Button
            shape="circle"
            size="large"
            className="absolute flex items-center justify-center"
            icon={<PlusOutlined style={{ fontSize: 28, color: "#fff" }} />}
            style={{
              right: 20,
              bottom: 20,
              background: "#604BC6",
              height: 50,
              width: 50,
              border: "none",
              boxShadow: "0px 0px 2px 0px rgb(96, 75, 198)",
            }}
            onClick={() => {
              setShowPlanActivityModal(true);
            }}
          />
        </div>

        {handoffModal && (
          <ActivityHandoffModal
            isVisible={handoffModal}
            setHandoffModal={setHandoffModal}
            selectedHandoffActivity={selectedHandoffActivity}
            callback={refetch}
          />
        )}
        {showPlanActivityModal && (
          <PlanActivityModal
            isVisible={showPlanActivityModal}
            onClose={() => setShowPlanActivityModal(false)}
          />
        )}

        {!_.isEmpty(editItem) && (
          <>
            {!_.isEmpty(editItem?.backlog) ? (
              <NewBacklogActivityModal
                isVisible={editItem}
                setEditItem={setEditItem}
                editingItem={{
                  data: {
                    ...editItem?.activity,
                    assigned: user,
                    planned: editItem.activity.planned_date,
                    backlog: {
                      ...editItem.activity.backlog,
                      workspace: {
                        ...editItem.activity.backlog.workspace,
                        project: editItem.activity.project,
                      },
                    },
                  },
                }}
                title={"Edit activity"}
                backlogId={editItem?.backlog._id} // backlog id
                onClose={() => {
                  setEditItem(null);
                }}
                fromPlanned={true}
              />
            ) : (
              <EditActivity
                isVisible={editItem}
                editingItem={{ data: editItem?.activity }}
                title={"Edit activity"}
                onClose={() => {
                  setEditItem(null);
                }}
                refetch={refetch}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default PlannedWidget;
