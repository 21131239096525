import React from "react";
import {
  CheckCircleOutlined,
  FormOutlined,
  MoreOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import queryString from "query-string";
import { Typography, Button, Dropdown, Tooltip } from "antd";
import { useHistory } from "react-router";

const { Text } = Typography;

const InvoiceDropDown = ({ status }) => {
  return (
    <div className="shadow-xl bg-white p-3 flex flex-col w-46 rounded items-start">
      {status === "pending" && <Button type="text">Mark as Paid</Button>}
      {status === "draft" && <Button type="text">Send Invoice</Button>}
      {status === "paid" && (
        <Button type="text"> Revert to pending payment </Button>
      )}
      {status !== "paid" && <Button type="text"> Create a Copy </Button>}
      <Button type="text">Download PDF</Button>
      <Button type="text">Delete invoice</Button>
    </div>
  );
};

const InvoiceGrid = ({ query }) => {
  const history = useHistory();
  const editDraftHandler = (invoiceId) => {
    // redirect to create invoice page with selected invoice to edit in table
    history.push({
      search: queryString.stringify({
        ...query,
        invoice: "createinvoice",
        invoiceId: invoiceId,
      }),
    });
  };
  return (
    <div>
      <div
        style={{ border: "1px solid #F0F0F0" }}
        className=" flex p-4 mt-6 rounded-sm"
      >
        <div
          className="overflow-x-auto flex flex-wrap gap-4 justify-center"
          style={{ minHeight: "65vh" }}
        >
          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Draft" placement="bottom">
                    {/* If status === "draft" then  */}
                    {true && (
                      <FormOutlined
                        onClick={() => editDraftHandler("asdf")}
                        style={{
                          fontSize: "16px",
                          border: "3px solid #0095FF",
                          borderRadius: "50%",
                          padding: 5,
                          backgroundColor: "E2F8FF",
                          color: "#0095FF",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>

          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>

          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Pending Payment" placement="bottom">
                    {/* If status === "pending" then  */}
                    {true && (
                      <RightCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#FFFBE6",
                          borderRadius: "50%",
                          color: "#FAAD14",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>

          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>

          <div
            className=" p-2 mt-2 rounded-md "
            style={{ width: 280, height: 340, backgroundColor: "#EFEEF6" }}
          >
            <div className="bg-white" style={{ height: 270 }}>
              Preview Window
            </div>
            <div className="flex justify-between mt-3.5">
              <div className="flex">
                <div className="mr-4">
                  <Tooltip title="Invoice Paid" placement="bottom">
                    {/* If status === "paid" then  */}
                    {true && (
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          backgroundColor: "#F6FFED",
                          borderRadius: "50%",
                          color: "#52C41A",
                        }}
                      />
                    )}
                  </Tooltip>
                </div>
                <div className="flex flex-col">
                  <Text className="text-xs font-semibold">
                    Windu Inovice #123
                  </Text>
                  <Text> Nov 2, 2021 </Text>
                </div>
              </div>
              <div>
                <Dropdown
                  overlay={<InvoiceDropDown status="draft" />}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "26px" }} />
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceGrid;
