import React from "react";
import { CaretUp, CaretDown } from "@phosphor-icons/react";

import styles from "./styles.module.less";

const Handle = ({ collapsed, setCollapsed }) => {
  return (
    <div
      className={styles.handle}
      style={{
        position: "absolute",
        right: "-55px",
        top: "50%",
        transform: "translateY(-50%)rotateZ(90deg)",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
      }}
      onClick={() => setCollapsed(!collapsed)}
    >
      {collapsed ? <CaretUp size={20} /> : <CaretDown size={20} />}
    </div>
  );
};
export default Handle;
