import React, { useState } from "react";
import {
  Typography,
  Button,
  Pagination,
  List,
  Result,
  Tooltip,
  Dropdown,
  Menu,
  ConfigProvider,
  Alert,
  DatePicker,
} from "antd";
import {
  CheckCircleOutlined,
  FileSearchOutlined,
  FormOutlined,
  SendOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { Icon } from "@fishyvisions/windu-uikit";
import { useRecoilValue } from "recoil";
import moment from "moment-timezone";
import { useHistory, useLocation } from "react-router";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { userSession } from "recoil/atoms/User/UserSession";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "components/Loader";
import { GET_INVOICES } from "graphql/queries/invoice/getInvoices";
import { DELETE_INVOICE } from "graphql/mutations/invoice/deleteInvoice";
import { CREATE_INVOICE_COPY } from "graphql/mutations/invoice/createInvoiceCopy";
import { UPDATE_INVOICE_STATUS } from "graphql/mutations/invoice/updateInvoiceStatus";
import { GET_WORKSPACE_ACCOUNTS } from "graphql/queries/Stripe/getWorkspaceAccounts";
import queryString from "query-string";
import utility from "common/utility";
import EmptyData from "components/EmptyData";
import AccountSetupWarningModal from "./AccountSetupWarningModal";
import { SEND_INVOICE } from "graphql/mutations/invoice/sendInvoiceEmail";
import InviteClientModal from "components/Modals/InviteClientModal";

const { Text, Title, Link } = Typography;

const sizePerPage = 5;
const initialOffset = 0;

const InvoiceHistory = ({
  missingRateWarning,
  setmissingRateWarning,
  setrateWarningModal,
  setMembersWithNotRates,
  hasClient,
  missingAccount,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const query = { id };
  const user = useRecoilValue(userSession);
  const workspace = useRecoilValue(workspaceSelected);
  const [isVisible, setIsVisible] = React.useState(false);
  const [queryParameters, setQueryParameters] = React.useState({
    size: sizePerPage,
    offset: initialOffset,
    startDate: moment(new Date()).subtract(1, "months"),
    endDate: moment(new Date()),
    workspaceId: id, // workspace id in the url
    status: "",
  });
  const [setupAccountWarning, setSetupAccountWarning] = useState(false);

  const { loading, data, error, refetch } = useQuery(GET_INVOICES, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getInvoices }) => {
      const {
        overview: { membersWithoutRates },
      } = getInvoices;
      const missingRates = _.size(membersWithoutRates) > 0;
      setMembersWithNotRates(membersWithoutRates);
      setmissingRateWarning(missingRates);
    },
  });

  const { loading: getBillingLoading, data: accountData } = useQuery(
    GET_WORKSPACE_ACCOUNTS,
    {
      variables: {
        input: {
          workspaceId: id,
          projectId: workspace?.project?._id,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const somethingIsDue = !_.isEmpty(
    accountData?.getBillingAccounts?.requirements?.currently_due
  );

  const [createInvoiceCopy, { loading: copyLoading }] = useMutation(
    CREATE_INVOICE_COPY,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Invoice created successfully",
          `Invoice has been created`,
          "success",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_INVOICES,
          variables: { input: queryParameters },
        },
      ],
    }
  );

  const [deleteInvoice, { loading: deleteInvoiceLoading }] = useMutation(
    DELETE_INVOICE,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Invoice deleted successfully",
          `Invoice has been deleted`,
          "success",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_INVOICES,
          variables: { input: queryParameters },
        },
      ],
    }
  );

  const [updateInvoiceStatus, { loading: updateInvoiceLoading }] = useMutation(
    UPDATE_INVOICE_STATUS,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Invoice updated successfully",
          `Invoice has been updated`,
          "success",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_INVOICES,
          variables: { input: queryParameters },
        },
      ],
    }
  );

  const [sendInvoiceEmail, { loading: sendInvoiceLoading }] = useMutation(
    SEND_INVOICE,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Invoice has been sent",
          `Invoice sent successfully to the client email.`,
          "success",
          "topRight"
        );
      },
    }
  );

  React.useEffect(() => {
    if (workspace) {
      setQueryParameters({
        ...queryParameters,
        workspaceId: workspace._id,
      });
    }
  }, [workspace]);

  const handleSelectedDates = (data) => {
    const startDate = data[0]?.format();
    const endDate = data[1]?.format();

    setQueryParameters({
      ...queryParameters,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const handleDeleteInvoice = ({ invoiceId }) => {
    deleteInvoice({
      variables: {
        invoiceId,
        workspaceId: workspace?._id,
      },
    });
  };

  const handleDownload = ({ invoiceURL }) => {
    utility.donwloadHanlder({ url: invoiceURL });
  };

  const handleCreateCopy = ({ invoiceId }) => {
    createInvoiceCopy({
      variables: {
        invoiceId,
        workspaceId: workspace?._id,
      },
    });
  };

  const handleUpdateInvoiceStatus = ({ status, invoiceId }) => {
    updateInvoiceStatus({
      variables: {
        input: {
          invoiceId,
          workspaceId: workspace?._id,
          status,
        },
      },
    });
  };

  if (error)
    return (
      <Result
        status="error"
        title="Fetching Failed"
        subTitle={toString(error.message)}
        extra={[
          <Button key="1" onClick={() => refetch()}>
            Try Again
          </Button>,
        ]}
      />
    );

  const listLoading = {
    spinning:
      loading ||
      copyLoading ||
      sendInvoiceLoading ||
      copyLoading ||
      deleteInvoiceLoading ||
      updateInvoiceLoading,
    indicator: <Loader size="large" />,
  };

  const InvoiceDropDown = ({ status, invoiceId, invoiceURL }) => {
    return (
      <Menu className="py-0" disabledOverflow={true}>
        {status === "PENDING" && (
          <>
            <Menu.Item
              disabled={updateInvoiceLoading}
              className="py-4 flex items-center"
              key="1"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PAID" })
              }
            >
              Mark as Paid
            </Menu.Item>
            <Menu.Item
              className="py-4 flex items-center"
              key="7"
              disabled={sendInvoiceLoading}
              onClick={() =>
                sendInvoiceEmail({
                  variables: {
                    input: { projectId: workspace?.project._id, invoiceId },
                  },
                })
              }
            >
              Email invoice to client
            </Menu.Item>
          </>
        )}
        {status === "DRAFT" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="2"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PENDING" })
              }
              disabled={updateInvoiceLoading}
            >
              Send Invoice
            </Menu.Item>
          </>
        )}
        {/* {status === "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="3"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PENDING" })
              }
            >
              Revert to pending payment
            </Menu.Item>
          </>
        )} */}
        {status !== "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="4"
              onClick={() => handleCreateCopy({ invoiceId })}
              disabled={copyLoading}
            >
              Create a Copy
            </Menu.Item>
          </>
        )}

        <>
          <Menu.Divider className="my-0" />
          <Menu.Item
            className="py-4 flex items-center"
            key="5"
            onClick={() => handleDownload({ invoiceURL })}
          >
            Download PDF
          </Menu.Item>
        </>

        {status !== "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="6"
              onClick={() => handleDeleteInvoice({ invoiceId })}
              disabled={deleteInvoiceLoading}
            >
              Delete invoice
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const getInvoiceIcon = ({ status, invoiceId }) => {
    if (status === "PAID") {
      return (
        <CheckCircleOutlined
          style={{
            fontSize: "30px",
            backgroundColor: "#F6FFED",
            borderRadius: "50%",
            color: "#52C41A",
          }}
        />
      );
    }
    if (status === "DRAFT") {
      return (
        <FormOutlined
          onClick={() => editDraftHandler(invoiceId)}
          style={{
            fontSize: "16px",
            border: "3px solid #0095FF",
            borderRadius: "50%",
            padding: 5,
            backgroundColor: "E2F8FF",
            color: "#0095FF",
          }}
        />
      );
    }
    if (status === "PENDING") {
      return (
        <SendOutlined
          style={{
            fontSize: "16px",
            border: "3px solid #FAAD14",
            borderRadius: "50%",
            padding: 5,
            backgroundColor: "#FFFBE6",
            color: "#FAAD14",
          }}
        />
      );
    }

    return null;
  };

  const previewHandler = (invoiceId) => {
    // redirect to history page with selected invoice
    history.push({
      search: queryString.stringify({
        ...query,
        invoice: "history",
        invoiceId: invoiceId,
        query: JSON.stringify(queryParameters),
      }),
    });
  };
  const createInvoiceHandler = () => {
    if (missingAccount || somethingIsDue) {
      setSetupAccountWarning(true);
    } else if (missingRateWarning) {
      setrateWarningModal(true);
    } else {
      history.push({
        search: queryString.stringify({
          ...query,
          invoice: "createinvoice",
        }),
      });
    }
  };
  const editDraftHandler = (invoiceId) => {
    // redirect to create invoice page with selected invoice to edit in table
    history.push({
      search: queryString.stringify({
        ...query,
        invoice: "editinvoice",
        invoiceId: invoiceId,
      }),
    });
  };

  return (
    <>
      <div style={{ width: "57%", padding: "20px" }}>
        {!hasClient && (
          <Alert
            message="Client Missing"
            description={
              <div className="flex justify-between">
                <Text>
                  In order to create an invoice, you must first add a client.
                </Text>

                <Button
                  type="primary"
                  size="small"
                  onClick={() => setIsVisible(true)}
                >
                  Add client
                </Button>
              </div>
            }
            type="error"
            closable
            showIcon
            className="m-4"
          />
        )}
        <div className="flex justify-between items-center px-3">
          <div>
            <Title style={{ fontSize: "16px", color: "#665AAA" }}>
              INVOICE HISTORY
            </Title>
            <Text>{data?.getInvoices?.pagination?.total_data} documents</Text>
          </div>
          <div className="flex items-center">
            <div className="flex items-start ml-16">
              <DatePicker.RangePicker
                size="medium"
                onChange={handleSelectedDates}
                defaultValue={[
                  moment(moment().subtract(1, "month").toDate(), "YYYY/MM/DD"),
                  moment(moment(), "YYYY/MM/DD"),
                ]}
                allowClear={false}
              />
            </div>

            <div className="pl-4">
              <Button
                disabled={!hasClient}
                type="primary"
                size="large"
                onClick={createInvoiceHandler}
              >
                Create invoice
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <ConfigProvider renderEmpty={() => <EmptyData />}>
            <List
              style={{ border: "1px solid #F0F0F0" }}
              className="p-4 mt-6 rounded-sm"
              loading={listLoading}
              itemLayout="horizontal"
              dataSource={data?.getInvoices.data}
              renderItem={(item) => {
                let invoiceText = "";
                if (item.status === "PENDING") invoiceText = "Invoice Pending";
                if (item.status === "DRAFT") invoiceText = "Invoice Draft";
                if (item.status === "PAID") invoiceText = "Invoice Paid";
                return (
                  <div className="bg-gray-100 p-3 mt-2 rounded-md">
                    <div className="flex justify-between ">
                      <div className="flex items-center ">
                        <div className="flex">
                          <div className="flex flex-col ">
                            <FileSearchOutlined style={{ fontSize: "50px" }} />
                            <Text
                              className="cursor-pointer"
                              onClick={() => previewHandler(item._id)}
                              style={{ fontSize: 12, color: "#5C4AC4" }}
                            >
                              view preview
                            </Text>
                          </div>
                          <div className="flex ml-3 items-center">
                            <div>
                              <Tooltip title={invoiceText} placement="bottom">
                                {getInvoiceIcon({
                                  status: item.status,
                                  invoiceId: item._id,
                                })}
                              </Tooltip>
                            </div>
                            <div className="flex flex-col ml-3 text-left">
                              <Link
                                className="font-semibold"
                                onClick={() => previewHandler(item._id)}
                              >{`${item.name} - ${
                                item.status === "DRAFT"
                                  ? "Draft Invoice"
                                  : `Invoice #${item.code}`
                              }`}</Link>
                              <Text className="text-gray-500">
                                {moment(new Date(item.created_at))
                                  .tz(user.timezone)
                                  .format("LL")}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center mr-3">
                        <Dropdown
                          placement="bottomRight"
                          overlay={
                            <InvoiceDropDown
                              status={item.status}
                              invoiceId={item._id}
                              invoiceURL={item.invoiceURL}
                            />
                          }
                          trigger={["click"]}
                        >
                          <span
                            onClick={(e) => e.preventDefault()}
                            className="cursor-pointer"
                          >
                            <Icon type="Meatball" size="small" />
                          </span>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </ConfigProvider>
          {!loading && (
            <div className="mt-5 flex justify-center">
              <Pagination
                className="my-4 text-center"
                current={data?.getInvoices?.pagination?.current_page}
                onChange={onPageChange}
                total={data?.getInvoices?.pagination?.total_data || 0}
                pageSize={queryParameters.size}
                showTotal={(total, range) =>
                  `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
                }
              />
            </div>
          )}
        </div>
        <div className="flex gap-4 mt-11 justify-center">
          <div
            className="flex flex-col rounded p-4 text-right"
            style={{
              backgroundColor: "#EDEBF9",
              width: "140px",
              height: "80px",
            }}
          >
            <Text style={{ fontSize: "24px", color: "#5C4AC4" }}>
              {data?.getInvoices?.overview?.paid}
            </Text>
            <Text style={{ color: "#5C4AC4" }}>Paid Invoices</Text>
          </div>
          <div
            className="flex flex-col rounded p-4 text-right"
            style={{
              backgroundColor: "#EDEBF9",
              width: "140px",
              height: "80px",
            }}
          >
            <Text style={{ fontSize: "24px", color: "#5C4AC4" }}>
              {data?.getInvoices?.overview?.pending}
            </Text>
            <Text style={{ color: "#5C4AC4" }}>Unpaid Invoices</Text>
          </div>
          <div
            className="flex flex-col rounded p-4 text-right"
            style={{
              backgroundColor: "#EDEBF9",
              width: "140px",
              height: "80px",
            }}
          >
            <Text style={{ fontSize: "24px", color: "#5C4AC4" }}>
              {data?.getInvoices?.overview?.draft}
            </Text>
            <Text style={{ color: "#5C4AC4" }}>Drafts</Text>
          </div>
        </div>
      </div>

      {setupAccountWarning && (
        <AccountSetupWarningModal
          isVisible={setupAccountWarning}
          onCancel={() => setSetupAccountWarning(false)}
          data={accountData}
          loading={getBillingLoading}
          somethingIsDue={somethingIsDue}
        />
      )}
      {isVisible && (
        <InviteClientModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          projectSelected={{
            value: workspace?.project?._id,
            label: workspace?.project?.title,
          }}
        />
      )}
    </>
  );
};

export default InvoiceHistory;
