import gql from "graphql-tag";

export const GET_PROJECT = gql`
  query ($id: ID!) {
    getProject(id: $id) {
      _id
      created_at
      updated_at
      description
      title
      total_time
      total_activities
      color
      status
      notes
      client {
        _id
        full_name
        email
      }
      manager_id {
        _id
        email
        avatar
        full_name
      }
      created_by {
        _id
        email
      }
      members {
        _id
        email
        full_name
        avatar
      }
    }
  }
`;
