import gql from "graphql-tag";

export const GET_MONDAY_ITEMS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getMondayItems(input: $input) {
      id
      name
      status_id
      board {
        id
        name
      }
      column_values {
        text
        title
        value
        description
        additional_info
        id
      }
      updates {
        body
        text_body
        replies {
          body
          text_body
        }
      }
      group {
        title
        color
      }
      subitems {
        id
        name
        board {
          id
          name
        }
        updates {
          body
          text_body
          replies {
            body
            text_body
          }
        }
      }
    }
  }
`;
