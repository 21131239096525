import React from "react";
import _ from "lodash";
import { Typography, Tag, List, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import moment_tz from "moment-timezone";
const { Text } = Typography;

const InvitationCard = ({
  status,
  invitee,
  _id,
  onRemove,
  onResend,
  sentAt,
  project,
  type,
}) => {
  const user = useRecoilValue(userSession);
  const isAccepted = status === "Accepted";
  const renderStatus = () => {
    switch (status) {
      case "Pending":
        return <Tag color="orange">{status}</Tag>;
      case "Accepted":
        return <Tag color="#87d068">{status}</Tag>;
      default:
        return <Tag color="#f50">{status}</Tag>;
    }
  };

  return (
    <List.Item
      key={_id}
      className="p-5"
      actions={[
        <Text className="text-gray-600">{renderStatus()}</Text>,
        <Text>Sent {moment_tz(sentAt).tz(user?.timezone).fromNow()}</Text>,
        <Button
          type="link"
          onClick={() => onResend(_id)}
          className={isAccepted ? "hidden" : ""}
        >
          Resend
        </Button>,
      ]}
    >
      <List.Item.Meta
        className="flex text-left"
        title={
          <Text>
            Invite sent to <b>{invitee}</b> ({_.upperFirst(type)})
          </Text>
        }
        description={
          <div className="flex justify-between">
            <Text className="mb-3 pr-3">{project?.title}</Text>
            <div className="ml-2">
              <CloseOutlined
                onClick={() => onRemove(_id)}
                className="bg-gray-200"
                style={{ padding: 5, borderRadius: "50%", fontSize: 12 }}
              />
            </div>
          </div>
        }
      />
    </List.Item>
  );
};

export default InvitationCard;
