import {
  Button,
  Checkbox,
  Collapse,
  Drawer,
  Dropdown,
  Input,
  Popconfirm,
  Progress,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { CaretDoubleLeft, DotsThreeVertical } from "@phosphor-icons/react";
import _ from "lodash";
import EmptyData from "components/EmptyData";
import AssigneSelectDropdown from "components/Modals/NewBacklogActivity/AssigneSelectDropdown";
import moment from "moment";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  RightSquareOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import UserAvatar from "components/UserAvatar";
import CalendarPicker from "components/CalendarPicker";
import { UPDATE_ACTIVITY } from "graphql/mutations/Activity/updateActivity";
import { useMutation } from "@apollo/client";

const { Text } = Typography;
const { Panel } = Collapse;

const PlaybookDrawer = ({
  visible,
  data,
  onClose,
  handleSearch,
  filterSearchUser,
  getActivity,
  setActivity,
  selectedActivityData,
}) => {
  const [selectedGoal, setSelectedGoal] = useState(_.first(data?.playbook));
  const [newTask, setNewTask] = useState({
    _id: _.uniqueId("newtask-"),
    description: null,
    planned: null,
    assigned: null,
    completed: false,
  });

  const [updateActivity, { loading: updating }] = useMutation(UPDATE_ACTIVITY, {
    onCompleted: () => {
      setNewTask({
        _id: _.uniqueId("newtask-"),
        description: null,
        planned: null,
        assigned: null,
        completed: false,
      });
    },
  });

  const handleUpdateTask = (updatedTasks, selectedActivity, selectedGoal) => {
    let newPayload = {};

    // first check if it's child activity or parent activity of a playbook
    if (selectedActivityData?.playbookReference) {
      // mean the selected activity is a child and ref to parent activity update the playbookReference feild
      newPayload = {
        ...selectedActivityData,
        playbookReference: {
          ...selectedActivityData.playbookReference,
          playbook: _.map(
            selectedActivityData.playbookReference.playbook,
            (item) => {
              if (item._id === selectedGoal._id) {
                return {
                  ...item,
                  activities: _.map(item.activities, (activity) => {
                    if (activity._id === selectedActivity?._id) {
                      return {
                        ...activity,
                        tasks: updatedTasks,
                      };
                    } else {
                      return activity;
                    }
                  }),
                };
              } else {
                return item;
              }
            }
          ),
        },
      };
    } else {
      // mean the selected activity is a parent and  hand the playbook field that the playbook field

      newPayload = {
        ...selectedActivityData,
        playbook: _.map(selectedActivityData.playbook, (item) => {
          if (item._id === selectedGoal._id) {
            return {
              ...item,
              activities: _.map(item.activities, (activity) => {
                if (activity._id === selectedActivity?._id) {
                  return {
                    ...activity,
                    tasks: updatedTasks,
                  };
                } else {
                  return activity;
                }
              }),
            };
          } else {
            return item;
          }
        }),
      };
    }

    setActivity((prevActivity) => ({
      ...prevActivity,
      data: newPayload,
    }));

    updateActivity({
      variables: {
        input: {
          activity: selectedActivity?._id,
          tasks: _.map(updatedTasks, (task) => {
            return {
              ..._.omit(task, "_id"),
              assigned: task.assigned?._id,
            };
          }),
        },
      },
    });
  };

  const handleAddNewTask = (activity) => {
    const filteredData = [
      ...activity?.tasks,
      {
        ...newTask,
      },
    ].map((task) => {
      return {
        ..._.omit(task, "_id"),
        assigned: task.assigned?._id,
      };
    });

    updateActivity({
      variables: {
        input: {
          activity: activity?._id,
          tasks: filteredData,
        },
      },
    }).then(() => {
      getActivity();
    });
  };

  const progressCard = (data) => {
    const totalActivities = _.size(selectedGoal?.activities);
    const completedActivities = _.size(
      _.filter(selectedGoal?.activities, { isCompleted: true })
    );

    return (
      <div
        className="flex flex-col p-4 my-3 rounded-md"
        style={{ background: "#F9FAFB", border: "1px solid #DDE2E4" }}
      >
        <Text className="mb-2">
          {completedActivities} of {totalActivities} activities completed{" "}
        </Text>

        <Progress percent={(completedActivities / totalActivities) * 100} />
      </div>
    );
  };

  return (
    <Drawer
      placement="right"
      open={visible}
      closeIcon={null}
      bodyStyle={{ paddingTop: 0, background: "#F6F7F9" }}
      width={500}
      title={false}
      headerStyle={{ display: "none" }}
      className={`${styles.activtyDrawer}`}
      style={{ zIndex: "999999999999 !important" }}
      onClose={onClose}
    >
      <div>
        <div
          className="flex items-center justify-between py-3 mb-3"
          style={{ borderBottom: "1px solid #d9d9d9" }}
        >
          <div className="flex items-center gap-1 ml-2">
            <CaretDoubleLeft
              className="cursor-pointer"
              onClick={onClose}
              size={20}
              color="#0d0d0d"
              weight="bold"
            />
            <Text strong>Playbook</Text>
          </div>
        </div>

        <div className={styles.radioButtonStyles}>
          {_.map(data?.playbook, (gaol) => {
            return (
              <Tag.CheckableTag
                key={gaol?._id}
                checked={selectedGoal?._id === gaol?._id}
                onChange={(checked) => checked && setSelectedGoal(gaol)}
                className="text-base"
              >
                {_.truncate(gaol?.title, {
                  length: 15,
                  omission: "...",
                })}
              </Tag.CheckableTag>
            );
          })}
        </div>
        {_.isEmpty(selectedGoal?.activities) ? (
          <div>
            <EmptyData />
          </div>
        ) : (
          <div>
            {progressCard()}

            <div>
              <Text className="my-2" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                Activities
              </Text>

              <Collapse
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                  <RightSquareOutlined
                    style={{ fontSize: 24 }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                ghost
                accordion
                className="activity-collapse-panel-custom"
              >
                {_.map(selectedGoal?.activities, (activity) => {
                  return (
                    <Panel
                      className="mt-3"
                      header={_.truncate(activity?.title, {
                        length: 15,
                        omission: "...",
                      })}
                      key={activity?._id}
                    >
                      {_.map(activity?.tasks, (task) => {
                        return (
                          <div className={styles.newTaskDiv}>
                            <div>
                              <div className="flex items-start">
                                <Checkbox
                                  defaultChecked={task.completed}
                                  onChange={(event) => {
                                    const { checked } = event.target;

                                    const updatedTasks = activity.tasks.map(
                                      (taskItem) =>
                                        taskItem._id === task._id
                                          ? {
                                              ...taskItem,
                                              completed: checked,
                                              completed_at: checked
                                                ? moment().format()
                                                : null,
                                            }
                                          : taskItem
                                    );

                                    handleUpdateTask(
                                      updatedTasks,
                                      activity,
                                      selectedGoal
                                    );
                                  }}
                                />
                                <div className="ml-3 flex-1">
                                  <div>
                                    <Text
                                      delete={task.completed}
                                      mark={task.completed}
                                      editable={{
                                        onChange: (newDescription) => {
                                          const updatedTasks =
                                            activity.tasks.map((t) =>
                                              t._id === task._id
                                                ? {
                                                    ...t,
                                                    description: newDescription,
                                                  }
                                                : t
                                            );

                                          handleUpdateTask(
                                            updatedTasks,
                                            activity,
                                            selectedGoal
                                          );
                                        },
                                        icon: (
                                          <EditOutlined
                                            style={{ color: "#A5ACB8" }}
                                          />
                                        ),
                                      }}
                                    >
                                      {task?.description}
                                    </Text>

                                    <Popconfirm
                                      placement="topRight"
                                      title="Are you sure to delete this task?"
                                      onConfirm={() => {
                                        const updatedTasks =
                                          activity.tasks.filter(
                                            (t) => t._id !== task._id
                                          );

                                        handleUpdateTask(
                                          updatedTasks,
                                          activity,
                                          selectedGoal
                                        );
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Remove task">
                                        <DeleteOutlined
                                          style={{ color: "#A5ACB8" }}
                                          className="ml-2"
                                        />
                                      </Tooltip>
                                    </Popconfirm>
                                  </div>

                                  <div className="flex items-center mt-1 mr-1 justify-end ">
                                    <div className="flex items-center mr-2">
                                      <CalendarPicker
                                        initialDate={
                                          task?.planned
                                            ? task.planned
                                            : moment()
                                        }
                                        onDateChange={(date) => {
                                          const updatedTasks =
                                            activity.tasks.map((t) =>
                                              t._id === task._id
                                                ? { ...t, planned: date }
                                                : t
                                            );

                                          handleUpdateTask(
                                            updatedTasks,
                                            activity,
                                            selectedGoal
                                          );
                                        }}
                                        label={
                                          <Text className=" text-xs underline">
                                            {task?.planned
                                              ? moment(task?.planned).format(
                                                  "dddd, MMMM D"
                                                )
                                              : "Schedule"}
                                          </Text>
                                        }
                                      />
                                    </div>
                                    <Dropdown
                                      trigger={["click"]}
                                      overlay={() => (
                                        <AssigneSelectDropdown
                                          handleSearch={handleSearch}
                                          filterSearchUser={filterSearchUser}
                                          setValue={(v) => {
                                            const updatedTasks =
                                              activity.tasks.map((t) =>
                                                t._id === task._id
                                                  ? { ...t, assigned: v }
                                                  : t
                                              );

                                            handleUpdateTask(
                                              updatedTasks,
                                              activity,
                                              selectedGoal
                                            );
                                          }}
                                          value={task?.assigned}
                                        />
                                      )}
                                    >
                                      {task?.assigned ? (
                                        <div className="cursor-pointer">
                                          <UserAvatar
                                            size={24}
                                            user={task?.assigned}
                                          />
                                          <Text className="ml-2 text-xs underline">
                                            {task?.assigned?.full_name}
                                          </Text>
                                        </div>
                                      ) : (
                                        <div className="flex items-center cursor-pointer">
                                          <UserAddOutlined />
                                          <Text className="text-xs ml-1 underline">
                                            Assignee
                                          </Text>
                                        </div>
                                      )}
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* new task  */}
                      {activity?.data?.status !== 3 &&
                        activity?.data?.status !== 6 && (
                          <div className={styles.newTaskDiv}>
                            <div>
                              <div className="flex items-start">
                                <Checkbox
                                  defaultChecked={newTask.completed}
                                  checked={newTask.completed}
                                  onChange={(v) =>
                                    setNewTask({
                                      ...newTask,
                                      completed: v.target.checked,
                                    })
                                  }
                                />
                                <div className="ml-3 flex-1">
                                  <Input
                                    value={newTask.description}
                                    onChange={(v) => {
                                      setNewTask({
                                        ...newTask,
                                        description: v.target?.value,
                                      });
                                    }}
                                  />
                                  <div className="flex justify-between mt-1 ml-1 ">
                                    <div className="flex items-center ">
                                      <Dropdown
                                        trigger={["click"]}
                                        overlay={() => (
                                          <AssigneSelectDropdown
                                            handleSearch={handleSearch}
                                            filterSearchUser={filterSearchUser}
                                            setValue={(v) =>
                                              setNewTask({
                                                ...newTask,
                                                assigned: v,
                                              })
                                            }
                                            value={newTask?.assigned}
                                          />
                                        )}
                                      >
                                        {newTask?.assigned ? (
                                          <div className="cursor-pointer">
                                            <UserAvatar
                                              size={24}
                                              user={newTask?.assigned}
                                            />
                                            <Text
                                              className="ml-2 text-xs underline"
                                              style={{ color: "#A5ACB8" }}
                                            >
                                              {newTask?.assigned?.full_name}
                                            </Text>
                                          </div>
                                        ) : (
                                          <div className="flex items-center cursor-pointer">
                                            <UserAddOutlined
                                              style={{ color: "#A5ACB8" }}
                                            />
                                            <Text
                                              style={{ color: "#A5ACB8" }}
                                              className="text-xs ml-1 underline"
                                            >
                                              Assignee
                                            </Text>
                                          </div>
                                        )}
                                      </Dropdown>
                                      <div
                                        className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                                      >
                                        <CalendarPicker
                                          initialDate={moment()}
                                          onDateChange={(date) =>
                                            setNewTask({
                                              ...newTask,
                                              planned: date,
                                            })
                                          }
                                          label={
                                            <Text
                                              className="text-xs underline"
                                              style={{ color: "#A5ACB8" }}
                                            >
                                              {newTask?.planned
                                                ? moment(
                                                    newTask?.planned
                                                  ).format("dddd, MMMM D")
                                                : "Schedule"}
                                            </Text>
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <Button
                                        icon={<CheckOutlined />}
                                        type="primary"
                                        size="small"
                                        onClick={() =>
                                          handleAddNewTask(activity)
                                        }
                                        loading={updating}
                                      >
                                        Add
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default PlaybookDrawer;
