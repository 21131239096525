import gql from "graphql-tag";

export const GET_CLIENT = gql`
  query ($id: String!) {
    getClient(id: $id) {
      _id
      email
      full_name
      company
      city
      state
      address
      zip
      tags
    }
  }
`;
