import gql from "graphql-tag";

export const GET_ALL_WIDGETS = gql`
  query {
    getAllWidgets {
      _id
      name
      label
      connection_required
      workspace
      no_of_connection
      widget_category {
        name
        is_released
      }
    }
  }
`;
