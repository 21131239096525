import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CALENDAR_PROJECTS } from "graphql/mutations/Google/updateCalendarProjects";
import utility from "common/utility";
import { Menu, Button } from "antd";
import { Form, Formik } from "formik";
import { useRecoilState } from "recoil";
import { widgetsState } from "recoil/atoms/Dashboard/widgets";
import { find, get } from "lodash";
import { getRefreshToken, refreshToken } from "api/Google";
import Card from "../Card";
import View from "./View";
import Auth from "./Auth";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import { parseToken } from "../utils";
import { userSession } from "recoil/atoms/User/UserSession";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { useAuthWidgets } from "../common/useAuthWidgets";
import EnlargeWidget from "components/Modals/Enlarge";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";

const GoogleMeet = () => {
  const [user] = useRecoilState(userSession);
  const [selected, setSelected] = useState("upcoming");
  const [authenticated, setAuthenticated] = useState(false);
  const [validWidgets, setValidWidgets] = useRecoilState(widgetsState);
  const [isRetrying, setIsRetrying] = useState(false);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  const { loading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "google", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      setAuthenticated(getWorkspaceIntegration?.access_token);
    },
  });

  useAuthWidgets(authenticated, "GOOGLE_MEET", id);
  const { createIntegration } = useCreateIntegrationHook();
  const tryAndRefresh = () => {
    try {
      setIsRetrying(true);
      refreshToken(data?.getWorkspaceIntegration?.refresh_token)
        .then((r) => {
          const access_token = r?.data?.access_token;
          const refresh_token = r?.data?.refresh_token;

          createIntegration({
            type: "google",
            refresh_token,
            access_token,
            workspace: id,
          });
          setAuthenticated(access_token);

          setIsRetrying(false);
        })
        .catch(() => {
          // removeIntegration
          setAuthenticated(false);
        });
    } catch (error) {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    }
  };

  const [updateCalendarProjects] = useMutation(UPDATE_CALENDAR_PROJECTS, {
    onCompleted: (data) => {
      setSelected("upcoming");
      utility.setNotification(
        "Sync Saved",
        "Your meetings are now synced with this workspace",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      tryAndRefresh();
    },
  });

  const handleGoogleLogin = ({ code }) => {
    getRefreshToken(code).then((r) => {
      const access_token = r?.data?.access_token;
      const refresh_token = r?.data?.refresh_token;
      //  const widgetId = find(validWidgets?.workspace[id], ["i", "GOOGLE_MEET"])
      // ?.widget?._id;
      createGoogleEvent(user?._id, "CONNECT_MEETING_WIDGET", "app_engagement");
      createIntegration({
        type: "google",
        access_token,
        refresh_token,
        workspace: id,
      });
      setAuthenticated(access_token);
    });
  };

  useEffect(() => {
    if (authenticated) {
      setSelected("upcoming");
    } else {
      setAuthenticated(false);
    }
    if (isRetrying) {
      setSelected("settings");
    } else {
    }
  }, [isRetrying]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="upcoming">
        <a>Upcoming</a>
      </Menu.Item>
      <Menu.Item key="completed">
        <a>Completed</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7546507141271-Meetings"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );

  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "google" });
  };

  const onSubmit = (values) => {
    updateCalendarProjects({
      variables: { input: { workspace: id, sync: values.subscriptions } },
    });
  };

  const bodyHeight = selected === "settings" ? "70%" : "79%";
  return authenticated ? (
    <Formik
      enableReinitialize={true}
      initialValues={{ subscriptions: [] }}
      validationSchema={null}
      onSubmit={onSubmit}
      render={({ values, handleSubmit }) => (
        <Form>
          <Card
            bodyStyle={{
              padding: selected !== "settings" ? 0 : 12,
              height: bodyHeight,
              overflow: "auto",
            }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <CalendarOutlined
                  className="pr-3"
                  style={{ color: "#4F3CBF" }}
                />
                Your Meetings
                <span style={{ fontSize: "12px", paddingLeft: 10 }}>
                  ({selected})
                </span>
              </div>
            }
            menu={menu}
            action={[
              selected === "settings" && (
                <div className="text-right pr-3">
                  <Button onClick={handleSubmit} type="default">
                    Save
                  </Button>
                </div>
              ),
            ]}
          >
            <View
              token={authenticated}
              onRejected={() => tryAndRefresh()}
              view={selected}
            />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth
      handleGoogleLogin={handleGoogleLogin}
      isAuthenticated={authenticated}
      setAuthenticated={setAuthenticated}
    />
  );
};

export default GoogleMeet;
