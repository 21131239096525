import React from "react";
import { Avatar, Badge, Typography, Tooltip, Popover } from "antd";
import { StopOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import UserAvatar from "components/UserAvatar";
import styled from "./styles.module.css";

const ChatList = ({ chat, onClick }) => {
  const setChatSelected = useSetRecoilState(chatSelectedState);
  const user = useRecoilValue(userSession);
  const participantsFiltered = _.filter(
    chat.participants,
    (participant) => participant._id !== user._id
  );
  return (
    <div
      className="flex items-center cursor-pointer px-4"
      onClick={() => {
        setChatSelected({
          participants: participantsFiltered,
          workspaceId: chat.workspaceId,
          chatId: chat._id,
        });
        onClick();
      }}
    >
      {_.size(participantsFiltered) > 1 && (
        <Avatar.Group maxCount={3}>
          {_.map(participantsFiltered, (participant) => (
            <Popover
              content={
                <div>
                  {`${participant.email} `}
                  <Badge
                    color={participant.isOnline ? "green" : "gray"}
                    className={styled.activeStatusStyle}
                    size="default"
                    dot={true}
                  />
                </div>
              }
              trigger="hover"
              key={participant._id}
            >
              <UserAvatar user={participant} />
            </Popover>
          ))}
        </Avatar.Group>
      )}

      {_.size(participantsFiltered) === 1 && (
        <Badge
          color={participantsFiltered[0].isOnline ? "green" : "gray"}
          className={styled.activeStatusStyle}
          size="default"
          offset={[-5, 35]}
          dot={true}
        >
          <UserAvatar user={participantsFiltered[0]} size="large" />
        </Badge>
      )}

      {_.size(participantsFiltered) === 0 && (
        <Avatar
          icon={<StopOutlined className={styled.iconContainer} />}
          size="large"
        />
      )}

      <div className="flex flex-col ml-6">
        <Typography.Text className="font-semibold text-base flex items-center capitalize">
          {_.size(participantsFiltered) === 1 && (
            <>
              {_.map(participantsFiltered, (user) => {
                return user.full_name;
              })}
            </>
          )}
        </Typography.Text>
        <Typography.Text className="text-sm">
          {moment(chat.lastMessage.created_at).fromNow()}
        </Typography.Text>
        <Typography.Text className="text-sm">
          {_.truncate(chat.lastMessage.content, { length: 60 })}
        </Typography.Text>
      </div>
    </div>
  );
};

export default ChatList;
