import gql from "graphql-tag";

export const SLACK_CHANNEL_LIST = gql`
  query {
    getSlackChannelList {
      id
      name
    }
  }
`;
