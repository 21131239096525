import React, { useState } from "react";
import {
  Badge,
  Dropdown,
  Typography,
  Button,
  ConfigProvider,
  List,
  Tooltip,
} from "antd";
import { size, filter, get, isEmpty } from "lodash";
import {
  useMutation,
  useQuery,
  useSubscription,
  useApolloClient,
} from "@apollo/client";
import { BellOutlined } from "@ant-design/icons";
import NotificationCard from "components/NotificationCard";
import RequestCard from "components/RequestCard";
import moment from "moment";
import cx from "classnames";
import { REMOVE_REQUEST } from "graphql/mutations/request/removeRequest";
import { ACCEPT_PROJECT_INVITE } from "graphql/mutations/business/acceptProjectInvite";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import EmptyData from "../../EmptyData";
import utility from "common/utility";
import { useRecoilState, useRecoilValue } from "recoil";
import { notificationList } from "recoil/atoms/Notifications/NotificationList";
import { requestList } from "recoil/atoms/Requests";
import { NEW_NOTIFICATION } from "graphql/subscription/notification/newNotification";
import { DISMISS_NOTIFICATION } from "graphql/mutations/notification/dismissNotification";
import { GET_NOTIFICATIONS } from "graphql/queries/notification/getNotification";
import { useLocation } from "react-router-dom";
import AddRate from "components/Modals/MemberRate";

import styles from "../styles.module.less";
import { messageSoundObj } from "recoil/atoms/Messages/MessageSounds";
import HandoffResponse from "components/Modals/HandoffResponse";

const { Title, Text } = Typography;

const Notifications = () => {
  const { pathname } = useLocation();
  const audio = useRecoilValue(messageSoundObj);
  const [isVisible, setIsVisible] = React.useState(false);
  const [acceptProjectData, setAcceptProjectData] = React.useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationList);
  const [requests, setRequests] = useRecoilState(requestList);
  const client = useApolloClient();
  const [handoffModal, setHandoffModal] = useState(false);
  const [selectedHandoffActivity, setSelectedHandoffActivity] = useState();
  const [selectedNotification, setSelectedNotification] = useState();
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false);

  const { data: notificationData, error: notificationError } =
    useSubscription(NEW_NOTIFICATION);

  const { loading: notificationLoading, refetch } = useQuery(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getNotifications }) => setNotifications(getNotifications),
    }
  );

  const [removeRequest] = useMutation(REMOVE_REQUEST, {
    onCompleted: ({ removeRequest }) => {
      const filtered = filter(
        requests,
        (request) => request._id !== removeRequest._id
      );
      setRequests(filtered);
      return;
    },
  });

  const [acceptProjectInvite] = useMutation(ACCEPT_PROJECT_INVITE, {
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    onCompleted: ({ acceptProjectInvite }) => {
      if (acceptProjectInvite) {
        utility.setNotification(
          "Success",
          `Invitation Accepted`,
          "success",
          "topRight"
        );
        refetch();
      }
      if (!acceptProjectInvite) {
        utility.setNotification(
          "Rejected",
          `You have successfully rejected the invitation`,
          "error",
          "topRight"
        );
        refetch();
      }
    },
    refetchQueries: [
      {
        query: GET_WORKSPACES,
      },
    ],
  });

  const [dismissNotification] = useMutation(DISMISS_NOTIFICATION, {
    onCompleted: ({ dismissNotification }) => {
      if (dismissNotification?._id) {
        const filtered = filter(
          notifications,
          (notification) => notification._id !== dismissNotification._id
        );
        setNotifications(filtered);
        return;
      }

      setNotifications([]);
    },
  });

  const addNewNotification = () => {
    const { newNotification } = notificationData;
    setNotifications([newNotification, ...notifications]);
    audio.play();
  };

  React.useEffect(() => {
    if (notificationError) console.warn(notificationError);
    if (notificationData) addNewNotification();
  }, [notificationData, notificationError]);

  const refetchWorkspaceQuery = async () => {
    await client.refetchQueries({
      include: [GET_WORKSPACES],
    });
  };

  React.useEffect(() => {
    if (notificationData) {
      const { newNotification } = notificationData;
      if (newNotification?.type === "Client") {
        refetchWorkspaceQuery();
      }
    }
  }, [notificationData]);

  const mixNotification = [...notifications].sort((a, b) => {
    return new Date(b?.created_at) - new Date(a?.created_at);
  });

  const newNotificationArray = mixNotification?.reduce((notification, item) => {
    // handle by project
    const date = moment(item.created_at).format("MM/DD/YYYY");
    if (!notification[date]) {
      notification[date] = [];
    }
    notification[date].push(item);
    return notification;
  }, {});

  const notificationArrays = Object.keys(newNotificationArray).map((date) => {
    // handle by project
    return {
      date: moment(date).format("MM/DD/YYYY"),
      notification: newNotificationArray[date],
    };
  });

  const handleAction = (data) => {
    const payload = {
      status: data?.status,
      requestId: data?.requestId,
      projectId: data?.projectId,
      notificationId: data?._id,
    };
    acceptProjectInvite({ variables: { input: payload } });
  };

  const handHandoffAction = (v, item) => {
    setSelectedHandoffActivity(v);
    setSelectedNotification(item);
    setHandoffModal(true);
  };

  const notifciationMenu = () => {
    const tabs = [{ key: "1", name: "By Date" }];

    const ListHeader = ({ item }) => {
      let header = "";
      const daysDiff = moment().diff(moment(item.date), "days");
      if (daysDiff === 0) header = "Today";
      if (daysDiff === 1) header = "Yesterday";
      if (daysDiff < 6 && daysDiff > 1) header = `${daysDiff} days ago`;
      if (daysDiff > 6) header = item.date;

      return (
        <div className="w-full text-base uppercase font-semibold bg-gray-50 p-1 px-4 mt-4">
          <Text>{header}</Text>
        </div>
      );
    };

    return (
      <div
        className={cx("bg-white", styles.listContainer)}
        style={{ width: "450px" }}
      >
        <div className="h-16 border-b-2 flex items-center justify-between	px-3">
          <Title level={5}>Notifications</Title>
          <Button
            onClick={() => dismissNotification({ variables: { id: "" } })}
          >
            Dismiss All
          </Button>
        </div>

        {tabs?.map((_, index) => {
          return (
            <div
              key={index}
              style={{ maxHeight: "73vh" }}
              className="overflow-y-auto p-0"
            >
              <ConfigProvider renderEmpty={() => <EmptyData />}>
                {isEmpty(notificationArrays) && (
                  <div className="my-6">
                    <EmptyData />
                  </div>
                )}
                {notificationArrays.map((item, index) => (
                  <div key={index}>
                    <ListHeader item={item} />
                    <List
                      itemLayout="vertical"
                      dataSource={item?.notification}
                      loading={notificationLoading}
                      className=" p-0"
                      renderItem={(notification, index) => {
                        if (notification.invitee || notification.inviter) {
                          return (
                            <RequestCard
                              key={index}
                              onRemove={(id) =>
                                removeRequest({ variables: { id } })
                              }
                              {...notification}
                            />
                          );
                        } else
                          return (
                            <NotificationCard
                              key={index}
                              pathname={pathname}
                              dismiss={(id) =>
                                dismissNotification({ variables: { id } })
                              }
                              accept={handleAction}
                              reject={handleAction}
                              showRateModal={(value) => setIsVisible(value)}
                              setAcceptProjectData={setAcceptProjectData}
                              handleHandoffAction={(v) =>
                                handHandoffAction(v, notification)
                              }
                              {...notification}
                              setNotificationDropdownVisible={
                                setNotificationDropdownVisible
                              }
                            />
                          );
                      }}
                    />
                  </div>
                ))}
              </ConfigProvider>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Dropdown
        className="mr-12 tutorial-notifications"
        placement="bottomRight"
        trigger={["click"]}
        overlay={notifciationMenu}
        arrow
        visible={notificationDropdownVisible}
        onOpenChange={setNotificationDropdownVisible}
      >
        <Badge
          className="cursor-pointer"
          count={size(mixNotification)}
          offset={50}
          onClick={(e) => e.preventDefault()}
        >
          <Tooltip title="Notifications">
            <BellOutlined style={{ color: "#3C2E94" }} className="icon" />
          </Tooltip>
        </Badge>
      </Dropdown>
      {handoffModal && (
        <HandoffResponse
          isVisible={handoffModal}
          setHandoffModal={setHandoffModal}
          selectedHandoffActivity={selectedHandoffActivity}
          selectedNotification={selectedNotification}
          notificationRefectch={refetch}
        />
      )}

      <AddRate
        isVisible={isVisible}
        workspace={acceptProjectData.workspaceId}
        onClose={() => setIsVisible(false)}
        onDone={() => {
          handleAction({
            status: "Accepted",
            _id: acceptProjectData._id,
            requestId: acceptProjectData.requestId,
            projectId: acceptProjectData.projectId,
          });
        }}
      />
    </>
  );
};
export default Notifications;
