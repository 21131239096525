import gql from "graphql-tag";

export const GET_TRELLO = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTrello(input: $input) {
      _id
      api_token
      sync {
        trello_board
        windu_project
        list_pull {
          id
          name
        }
        list_push_finished {
          id
          name
        }
        list_push_start {
          id
          name
        }
      }
    }
  }
`;
