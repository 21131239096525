import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button as AntButton,
  Result,
  Input,
  Pagination,
  Button,
} from "antd";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { debounce, toString, isEmpty, filter, get } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { instanceOf } from "prop-types";
import { ClientProjectTable } from "components/ProjectTable";
import CreateProjectModal from "components/Modals/CreateProjectModal";
import AddMemberModal from "components/Modals/AddMember";
import InviteClientModal from "components/Modals/InviteClientModal";
import InviteManagerModal from "components/Modals/InviteManagerModal";
import { REMOVE_CLIENT } from "graphql/mutations/client/removeClient";
import { REMOVE_MANAGER } from "graphql/mutations/manager/removeManager";
import { GET_CLIENT_PROJECTS } from "graphql/queries/client/getProject";
import { REMOVE_PROJECT } from "graphql/mutations/projects/removeProject";
import { ARCHIVE_PROJECT } from "graphql/mutations/projects/archiveProject";
import { LEAVE_PROJECT } from "graphql/mutations/projects/leaveProject";
import { useRecoilState, useRecoilValue } from "recoil";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import styles from "./styles.module.css";
import utility from "common/utility";
import {
  updateProjectOverview,
  updateProjectPerformance,
} from "common/cacheUtilities";
import { userSession } from "recoil/atoms/User/UserSession";

const { Title } = Typography;

const initialSearch = "";
const sizePerPage = 10;
const initialOffset = 0;

const ClientProjectsList = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isInviteMemberVisible, setIsInviteMemberVisible] = useState(false);
  const [isAssignClientVisible, setIsAssignClientVisible] = useState(false);
  const [isInviteManagerVisible, setIsInviteManagerVisible] = useState(false);
  const [refetching, setRefetchIndicator] = useRecoilState(refetchIndicator);
  const [projectSelected, setProjectSelected] = useState({});
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
  });

  const maxProjectsPermission = utility.usePermissions();
  const history = useHistory();
  const user = useRecoilValue(userSession);

  const { loading, data, error, refetch } = useQuery(GET_CLIENT_PROJECTS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
  });

  const [removeProject] = useMutation(REMOVE_PROJECT, {
    onCompleted: ({ removeProject }) => {
      const {
        getProjects: { data: projects },
      } = data;

      const result = filter(
        projects,
        (project) => project._id !== removeProject._id
      );

      if (isEmpty(result) && queryParameters.offset > 0)
        setQueryParameters({
          ...queryParameters,
          offset: queryParameters.offset - queryParameters.size,
        });

      utility.setNotification(
        "Project removed successfully",
        `${removeProject.title} has been removed`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_CLIENT_PROJECTS,
        variables: { input: queryParameters },
      },
    ],
    update: (cache, { data: { removeProject } }) => {
      updateProjectOverview(cache, "remove");
      updateProjectPerformance(cache, removeProject, "remove");
    },
  });

  const [archiveProject] = useMutation(ARCHIVE_PROJECT, {
    onCompleted: ({ archiveProject }) => {
      const message =
        archiveProject.status === "Active"
          ? `${archiveProject.title} is now an active project`
          : `${archiveProject.title} has been archived`;

      utility.setNotification(
        "Project archived successfully",
        message,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_CLIENT_PROJECTS,
        variables: { input: queryParameters },
      },
    ],
  });

  const [removeClient] = useMutation(REMOVE_CLIENT, {
    onCompleted: ({ removeClient }) => {
      utility.setNotification(
        "Client removed successfully",
        `${removeClient.email} has been removed, you can invite a new client`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_CLIENT_PROJECTS,
        variables: { input: queryParameters },
      },
      { query: GET_WORKSPACES },
    ],
  });

  const [removeManager] = useMutation(REMOVE_MANAGER, {
    onCompleted: ({ removeManager }) => {
      const manager = removeManager?.email || "You";
      utility.setNotification(
        "Manager removed successfully",
        `${manager} has been removed, you can invite a new manager`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_CLIENT_PROJECTS,
        variables: { input: queryParameters },
      },
    ],
  });

  const [leaveProject] = useMutation(LEAVE_PROJECT, {
    onCompleted: ({ leaveProject }) => {
      utility.setNotification(
        "Success",
        `You have left ${leaveProject?.title}`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_CLIENT_PROJECTS,
        variables: { input: queryParameters },
      },
    ],
  });

  React.useEffect(() => {
    if (refetching.projects && loading === false) {
      setRefetchIndicator((current) => {
        return { ...current, projects: false };
      });
      refetch();
    }
  }, [refetching]);

  const handleRemoveClient = ({ client, project }) => {
    removeClient({
      variables: { input: { clientId: client, projectId: project } },
    });
  };

  const handleRemoveManager = ({ manager, project }) => {
    removeManager({
      variables: { input: { managerId: manager, projectId: project } },
    });
  };

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const onRemove = (projectId) => {
    removeProject({ variables: { input: { projectId } } });
  };

  const onArchive = (projectId) => {
    archiveProject({ variables: { input: { projectId } } });
  };

  const onMembersList = (projectId) => {
    history.push({
      pathname: "/teams",
      search: queryString.stringify({ projectId }),
    });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const onProjectLeave = (projectId) => {
    leaveProject({ variables: { input: { projectId } } });
  };

  const handleCreateProject = () => {
    if (isEmpty(user?.plan)) {
      history.push("settings?view=subscriptions");
      utility.setNotification(
        "Something wrong happened",
        "Please select a plan first to create a project.",
        "warning",
        "topRight"
      );
    } else {
      setIsVisible(true);
    }
  };

  if (error)
    return (
      <Result
        status="error"
        title="Fetching Failed"
        subTitle={toString(error)}
        extra={[
          <AntButton key="buy" onClick={() => refetch()}>
            Try Again
          </AntButton>,
        ]}
      />
    );

  return (
    <>
      <Row className="mb-6">
        <Col xs={20}>
          <Title level={4}>Projects List</Title>
        </Col>
      {/*   <Col xs={4} className="text-right">
          <Button
            size="large"
            onClick={handleCreateProject}
            key="submit"
            type="primary"
          >
            Create project
          </Button>
        </Col> */}
      </Row>
      <Row className="mb-6">
        <Col xl={8} lg={12} xs={12} md={12} sm={12}>
          <Input.Search
            className="w-full"
            placeholder="Search project"
            size="large"
            allowClear
            onChange={({ target }) => delayedQuery(target.value)}
            onSearch={(value) => onSearch(value)}
          />
        </Col>
        <Col
          className="text-right"
          xl={{ span: 4, offset: 12 }}
          lg={{ span: 4, offset: 8 }}
          xs={{ span: 4, offset: 8 }}
          md={{ span: 4, offset: 8 }}
          sm={{ span: 4, offset: 8 }}
        >
          <Button
            className={styles.iconContainer}
            icon={<SyncOutlined />}
            size="large"
            onClick={() => refetch()}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <ClientProjectTable
            projects={data?.getClientProjects?.data}
            onRemove={onRemove}
            onRemoveClient={handleRemoveClient}
            onRemoveManager={handleRemoveManager}
            onArchive={onArchive}
            onMembersList={onMembersList}
            setProjectSelected={setProjectSelected}
            setIsInviteMemberVisible={setIsInviteMemberVisible}
            setIsInviteManagerVisible={setIsInviteManagerVisible}
            setIsAssignClientVisible={setIsAssignClientVisible}
            isInviteMemberVisible={isInviteMemberVisible}
            loading={loading}
            onProjectLeave={onProjectLeave}
          />
        </Col>
      </Row>
      {!loading && (
        <Row>
          <Col xs={24}>
            <Pagination
              className="my-4 text-center"
              current={data?.getClientProjects?.pagination?.current_page}
              onChange={onPageChange}
              total={data?.getClientProjects?.pagination?.total_data || 0}
              pageSize={queryParameters.size}
              showSizeChanger
              pageSizeOptions={["5", "10", "20", "50", "100"]}
              showTotal={(total, range) =>
                `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
              }
              showQuickJumper={true}
            />
          </Col>
        </Row>
      )}

      {isVisible && (
        <CreateProjectModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          queryParameters={queryParameters}
          maxProjects={maxProjectsPermission?.value}
          projects={data?.getClientProjects}
          fromInvest={true}
        />
      )}

      {isInviteMemberVisible && (
        <AddMemberModal
          isVisible={isInviteMemberVisible}
          onClose={() => setIsInviteMemberVisible(false)}
          projectSelected={projectSelected}
        />
      )}

      {isAssignClientVisible && (
        <InviteClientModal
          isVisible={isAssignClientVisible}
          onClose={() => setIsAssignClientVisible(false)}
          projectSelected={projectSelected}
        />
      )}

      {isInviteManagerVisible && (
        <InviteManagerModal
          isVisible={isInviteManagerVisible}
          onClose={() => setIsInviteManagerVisible(false)}
          projectSelected={projectSelected}
          refetch={refetch}
        />
      )}
    </>
  );
};

ClientProjectsList.propTypes = {
  client: instanceOf(Object),
};

export default ClientProjectsList;
