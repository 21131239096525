import React from "react";
import { Icon } from "@fishyvisions/windu-uikit";
import {
  Typography,
  Popover,
  Table,
  Input,
  Row,
  Col,
  Tooltip,
} from "antd";
import AndIcon, {
  InfoCircleOutlined,
  MessageOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { array, func } from "prop-types";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { GET_USER_ACTIVITIES } from "graphql/queries/Activity/getUserActivities";
import {
  map,
  size,
  isEmpty,
  filter,
  find,
  cloneDeep,
  findIndex,
  debounce,
  uniqBy,
} from "lodash";
import moment from "moment";
import UserAvatar from "components/UserAvatar";
import Loader from "components/Loader";
import icons from "common/icons";
import EmptyData from "../EmptyData";

const { Title } = Typography;

const ManagerTable = ({ managers, loading }) => {
  const [expandedKeys, setExpandedKeys] = React.useState([]);
  const [activitiesList, setActivitiesList] = React.useState([]);

  const [getUserActivities, { loading: loadingActivities, data }] =
    useLazyQuery(GET_USER_ACTIVITIES, {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getUserActivities }) => {
        const { data } = getUserActivities;
        if (data.length > 0) {
          const index = findIndex(
            activitiesList,
            (info) => info.user === data[0].created_by._id
          );

          const clone = cloneDeep(activitiesList);
          clone[index].activities = uniqBy(
            [...new Set([...clone[index].activities, ...data])],
            "_id"
          );
          setActivitiesList(clone);
        }
      },
    });

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (values) => {
        return (
          <div className="flex items-center">
            <UserAvatar size={35} user={values} />
            <Title level={5} className="ml-4">
              {values.full_name}
            </Title>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Total Activities",
      dataIndex: "total_activities",
      key: "total_activities",
    },
    {
      title: "Total Time",
      dataIndex: "total_time",
      key: "total_time",
    },
    {
      title: "Total Projects",
      key: "nro_projects",
      render: ({ nro_projects, projects }) => {
        return (
          <div className="flex items-center">
            <Title level={5} className="mr-3">
              {nro_projects}
            </Title>
            <Popover
              style={{ width: 500 }}
              content={map(projects, (project) => (
                <Title level={5}>{project.title}</Title>
              ))}
              placement="right"
              title="Projects related"
              trigger="hover"
              className="cursor-pointer"
            >
              <InfoCircleOutlined
                className="text-md"
                style={{ color: "#f5a623" }}
              />
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => moment(value).format("MM/DD/YYYY"),
    },
    {
      title: "Last Update",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => moment(value).format("MM/DD/YYYY"),
    }
  ];

  const delayedQuery = debounce((query, user) => {
    onSearch(query, user);
  }, 1000);

  const onSearch = (query, user) => {
    const index = findIndex(activitiesList, (info) => info.user === user);
    const clone = cloneDeep(activitiesList);
    clone[index].search = query;
    clone[index].activities = [];
    clone[index].offset = 0;
    const payload = {
      size: 10,
      offset: 0,
      search: query,
      user,
      as: "Manager",
    };
    setActivitiesList(clone);
    getUserActivities({
      variables: {
        input: payload,
      },
    });
  };

  const loadMore = (user) => {
    const index = findIndex(activitiesList, (info) => info.user === user);

    const clone = cloneDeep(activitiesList);
    clone[index].offset = clone[index].offset + 10;

    const payload = {
      size: 10,
      offset: clone[index].offset,
      search: clone[index].search,
      user,
      as: "Manager",
    };

    getUserActivities({
      variables: {
        input: payload,
      },
    });
    setActivitiesList(clone);
  };

  const handleExpand = (expanded, { _id }) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, _id]);
    } else {
      setExpandedKeys(filter(expandedKeys, (key) => key !== _id));
    }

    const userActivities = find(activitiesList, (info) => info.user === _id);
    if (!userActivities) {
      const payload = {
        size: 10,
        offset: 0,
        search: "",
        user: _id,
        as: "Manager",
      };

      const clone = cloneDeep(activitiesList);
      clone.push({ ...payload, activities: [] });

      getUserActivities({
        variables: {
          input: payload,
        },
      });
      setActivitiesList(clone);
    }
  };

  const expandedRowRender = ({ _id, total_activities, full_name }) => {
    const columns = [
      { title: "Title", dataIndex: "title", key: "title" },
      { title: "Description", dataIndex: "description", key: "description" },
      {
        title: "Total time",
        dataIndex: "time",
        key: "time",
        render: (value) => value?.total_time,
      },
      {
        title: "Status",
        key: "status",
        render: ({ status }) => {
          if (status === 1)
            return (
              <Tooltip title={"Running"}>
                <AndIcon className="icon" component={icons["Clock"]} />
              </Tooltip>
            );

          if (status === 2)
            return (
              <Tooltip title={"On pause"}>
                <AndIcon className="icon" component={icons["PauseColor"]} />
              </Tooltip>
            );

          if (status === 3)
            return (
              <Tooltip title={"Finished"}>
                <AndIcon className="icon" component={icons["CheckMark"]} />
              </Tooltip>
            );
        },
      },
      {
        title: "Project",
        dataIndex: "project",
        key: "project",
        render: (value) => value.title,
      },
      {
        title: "Created",
        dataIndex: "created_at",
        key: "created_at",
        render: (value) => moment(value).format("MM/DD/YYYY"),
      },
      {
        title: "Updated",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (value) => moment(value).format("MM/DD/YYYY"),
      },
    ];

    const userInfo = find(activitiesList, (info) => info.user === _id);

    const tableLoading = {
      spinning: loadingActivities,
      indicator: <Loader size="large" />,
    };

    return (
      <>
        <Row className="mb-6">
          <Col xl={8} lg={12} xs={18} md={18} sm={18}>
            <Input.Search
              className="w-full"
              placeholder={`Search ${full_name}'s activities`}
              size="large"
              allowClear
              onChange={({ target }) => {
                if (target.value) {
                  delayedQuery(target.value, _id);
                }
              }}
              onSearch={(value) => onSearch(value, _id)}
            />
          </Col>
        </Row>
        <div
          id={`scrollable-${_id}`}
          className={cx("overflow-y-auto")}
          style={{
            maxHeight: "400px",
          }}
        >
          <InfiniteScroll
            dataLength={size(userInfo?.activities)}
            next={() => loadMore(_id)}
            hasMore={!isEmpty(data?.getUserActivities.data)}
            scrollableTarget={`scrollable-${_id}`}
            initialScrollY={0}
          >
            <Table
              locale={{
                emptyText: <EmptyData />,
              }}
              columns={columns}
              dataSource={map(userInfo?.activities, (activity) => ({
                ...activity,
                key: activity._id,
              }))}
              loading={tableLoading}
              pagination={false}
              nestedTable={true}
            />
          </InfiniteScroll>
        </div>
        <div className="mt-3">
          {`Showing ${size(
            userInfo?.activities
          )} activities of ${total_activities}`}
        </div>
      </>
    );
  };

  const tableLoading = {
    spinning: loading,
    indicator: <Loader size="large" />,
  };

  return (
    <Table
      locale={{
        emptyText: <EmptyData />,
      }}
      rowKey="_id"
      columns={columns}
      dataSource={map(managers, (manager) => ({
        ...manager,
        key: manager._id,
      }))}
      pagination={false}
      loading={tableLoading}
      expandable={{
        expandedRowRender,
        expandedRowKeys: expandedKeys,
        expandIcon: ({ expanded, onExpand, record }) => {
          if (expanded && record?.total_activities < 1) onExpand(record);
          if (record?.total_activities >= 1) {
            return (
              <Tooltip title={expanded ? "Close" : "See Activities"}>
                <span
                  onClick={(e) => onExpand(record, e)}
                  className={cx("cursor-pointer", {
                    "expand-icon-rotate": expanded,
                  })}
                >
                  <DownCircleOutlined className="icon-medium" />
                </span>
              </Tooltip>
            );
          }
        },
      }}
      onExpand={handleExpand}
    />
  );
};

ManagerTable.propTypes = {
  managers: array,
  onMessage: func,
};

export default ManagerTable;
