import React from "react";
import { onBoardingInfoV2 } from "../onBoardingData";
import { Typography, Radio } from "antd";
import { map } from "lodash";
import styles from "../styles.module.css";

const { Text } = Typography;

const Third = ({ values, setFieldValue, errors }) => {
  const hasErrors = errors?.teamMembers;

  return (
    <div>
      <div className="mt-6">
        <Text className="text-lg">
          {onBoardingInfoV2().TeamMembers.question}
        </Text>
        {hasErrors && (
          <div>
            <Text style={{ color: "#ff4d4f" }}>Please select atleast one</Text>
          </div>
        )}

        <div className="flex mt-8">
          <Radio.Group
            onChange={(v) => setFieldValue("teamMembers", v?.target.value)}
            defaultValue={values.teamMembers}
          >
            {map(onBoardingInfoV2().TeamMembers.options, (item) => (
              <Radio.Button className="ml-2" value={item.value} key={item.key}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default Third;
