import React, { useRef } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import moment from "moment";
import { useQuery } from "@apollo/client";
import querystring from "query-string";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "react-calendar-heatmap/dist/styles.css";
import { GET_ACTIVITY_COUNT_WIDGET } from "graphql/queries/widget/getActivityCountWidget";
import SideLegend from "./SideLegend";
import "./style.css";
import { Card, Typography } from "antd";
import DragHandle from "../DragHandle";

const ActivityCount = () => {
  const history = useHistory();
  const activityRef = useRef();

  const { loading, data } = useQuery(GET_ACTIVITY_COUNT_WIDGET);

  if (loading) return <Card loading={true}></Card>;

  const loadTimeline = (value) => {
    if (value === null) return;

    const { date } = value;
    const dateFormat = moment.utc(new Date(date));
    history.push({
      pathname: "/timeline",
      search: querystring.stringify({
        projectId: "",
        memberId: "",
        start_date: dateFormat,
        end_date: dateFormat,
      }),
    });
  };
  return (
    <div ref={activityRef}>
      <DragHandle />
      <Card
        loading={loading}
        bodyStyle={{ padding: 20 }}
        className="shadow-none border-none outline-none tour-activityCountwidget"
      >
        <div className="flex items-center">
          <Typography.Title level={4}>Activity Count</Typography.Title>
        </div>

        <div className="flex items-center justify-center max-h-96 mt-5 heat-map-style-large">
          <CalendarHeatmap
            horizontal={true}
            titleForValue={(v) => v?.count}
            showMonthLabels={false}
            startDate={moment().subtract(180, "days")}
            endDate={new Date()}
            values={data?.getActivityCountWidget?.data}
            onClick={loadTimeline}
            classForValue={(value) => {
              if (!value) {
                return "color-empty";
              }
              if (value.count > 10) {
                return `color-scale-10`;
              }
              return `color-scale-${value.count}`;
            }}
            tooltipDataAttrs={(value) => {
              if (!value.count) return { "data-tip": null };
              return {
                "data-tip": `${value.count} Activity Count on ${value.date}`,
              };
            }}
          />
          <div className="ml-6">
            <SideLegend />
          </div>
        </div>

        <ReactTooltip />
      </Card>
    </div>
  );
};

export default ActivityCount;
