import gql from "graphql-tag";

export const UPDATE_INTEGRATION = gql`
  mutation ($input: updateIntegrationInput) {
    updateIntegration(input: $input) {
      _id
      name
      access_token
      refresh_token
      user_name
      avatar
    }
  }
`;
