import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Tooltip,
  Typography,
  Mentions,
} from "antd";
import React, { useEffect, useState } from "react";
import Picker from "emoji-picker-react";
import { GET_ACTIVITY_COMMENTS } from "graphql/queries/Activity/getActivityComments";
import { ADD_COMMENT } from "graphql/mutations/Comment/addComment";
import _ from "lodash";
import utility from "common/utility";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import UserAvatar from "components/UserAvatar";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import styles from "./styles.module.css";
import Loader from "components/Loader";
import { REMOVE_COMMENT } from "graphql/mutations/Comment/removeComment";
import { EDIT_COMMENT } from "graphql/mutations/Comment/editComment";
import { NEW_COMMENT } from "graphql/subscription/Comment/newComment";
import { DELETE_COMMENT } from "graphql/subscription/Comment/deleteComment";

const CommentTab = ({ activity, fromBacklog }) => {
  const [isPickerVisible, setIsPickerVisible] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState("");
  const [commentsData, setCommentsData] = useState([]);
  const user = useRecoilValue(userSession);
  const [selectedMentioned, setselectedMentioned] = useState([]);

  const allMembers = _.compact(
    _.uniqBy(
      [
        activity.project?.manager_id,
        activity.project?.created_by,
        ...(activity.project?.members || []),
      ],
      "_id"
    )
  );

  const onEmojiClick = (_, emojiObject) => {
    setNewMessage(`${newMessage}${emojiObject.emoji}`);
    setIsPickerVisible(false);
  };

  const { data: commentSub, error: messageError } =
    useSubscription(NEW_COMMENT);
  const { data: deleteComment } = useSubscription(DELETE_COMMENT);

  const { data, loading, refetch } = useQuery(GET_ACTIVITY_COMMENTS, {
    variables: {
      input: fromBacklog
        ? { backlog: activity._id }
        : { activity: activity._id },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getActivityComments }) => {
      setCommentsData(getActivityComments);
    },
    notifyOnNetworkStatusChange: false,
  });

  const [addComment, { loading: sendLoading }] = useMutation(ADD_COMMENT, {
    onCompleted: () => {
      setNewMessage("");
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [removeComment, { loading: removing }] = useMutation(REMOVE_COMMENT, {
    onCompleted: ({ deleteComment }) => {
      setNewMessage("");
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [editComment, { loading: updating }] = useMutation(EDIT_COMMENT, {
    onCompleted: ({ editComment }) => {
      setNewMessage("");
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (deleteComment?.deleteComment) {
      setCommentsData(
        _.filter(
          commentsData,
          (comment) => comment._id != deleteComment?.deleteComment?._id
        )
      );
    }
  }, [deleteComment]);

  useEffect(() => {
    if (commentSub?.newComment) {
      const newComment = commentSub?.newComment;
      if (newComment.edited) {
        const newData = commentsData.map((item) => {
          return item._id === newComment._id ? newComment : item;
        });
        setCommentsData(newData);
      } else {
        setCommentsData([newComment, ...commentsData]);
      }
    }
  }, [commentSub]);

  const handleUpdateComment = (value, comment) => {
    editComment({
      variables: {
        input: {
          commentId: comment._id,
          content: value,
        },
      },
    });
  };

  const handleAddComment = () => {
    if (!_.isEmpty(newMessage)) {
      const payload = {
        content: newMessage,
        mentioned: _.uniq(selectedMentioned),
      };
      if (fromBacklog) {
        payload.backlog = activity._id;
      } else {
        payload.activity = activity._id;
      }
      addComment({
        variables: {
          input: {
            ...payload,
          },
        },
      });
    }
  };

  const ChatContent = ({ comment }) => {
    const mentionedUsers = comment.content.match(/@(\w+)/g);

    const formatContentWithMentions = () => {
      let formattedContent = comment.content;

      if (mentionedUsers) {
        mentionedUsers.forEach((mentionedUser) => {
          const username = mentionedUser.replace("@", "");
          const mentionMarkup = `<span style="background-color: #fff3e8; color: #f5a622; font-weight: 600;">@${username}</span>`;
          formattedContent = formattedContent.replace(
            mentionedUser,
            mentionMarkup
          );
        });
      }

      return formattedContent;
    };

    const [editedContent, setEditedContent] = useState(comment.content);
    const [isEditing, setIsEditing] = useState(false);

    const handleEditStart = () => {
      setIsEditing(true);
      setEditedContent(comment.content);
    };

    const handleEditCancel = () => {
      setIsEditing(false);
    };

    const handleEditSave = () => {
      handleUpdateComment(editedContent, comment);
      setIsEditing(false);
    };

    return (
      <div className="my-4">
        <div className="flex">
          <div>
            <UserAvatar user={comment.created_by} size={40} className="mr-2" />
          </div>
          <div>
            <div>
              <Typography.Text className="font-semibold text-base">
                {comment.created_by?.full_name}
                <Typography.Text className="font-normal ml-3 text-sm text-gray-400">
                  {moment(comment.created_at).format("h:mm A MMM DD, YYYY")}
                </Typography.Text>
              </Typography.Text>
            </div>
            <div className={`flex items-center ${styles.commentContentBox}`}>
              {!isEditing && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatContentWithMentions(),
                  }}
                  className={`${styles.editableContainer} text-base break-all`}
                />
              )}
              {isEditing && (
                <Input.TextArea
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
              {comment?.edited && (
                <Typography.Text className="text-gray-400 w-20 text-xs">
                  (edited)
                </Typography.Text>
              )}
              {comment.created_by.email === user.email && (
                <div className="editRemove">
                  {!isEditing && (
                    <Tooltip title="Edit comment">
                      <EditFilled
                        className="icon-medium text-gray-400"
                        onClick={handleEditStart}
                      />
                    </Tooltip>
                  )}
                  {isEditing && (
                    <>
                      <Tooltip title="Save comment">
                        <CheckCircleFilled
                          className="icon-medium text-primary"
                          onClick={handleEditSave}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel edit">
                        <CloseCircleFilled
                          className="icon-medium text-gray-400"
                          onClick={handleEditCancel}
                        />
                      </Tooltip>
                    </>
                  )}
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure to delete this comment?"
                    onConfirm={() =>
                      removeComment({ variables: { commentId: comment._id } })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Remove comment">
                      <DeleteFilled className="icon-medium text-gray-400" />
                    </Tooltip>
                  </Popconfirm>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleMentionChange = (value) => {
    setNewMessage(value);
    const dropdown = document.querySelector(".ant-mentions-dropdown");
    if (dropdown) {
      dropdown.style.zIndex = "99999999999";
    }
  };

  const handleMentionSelect = (option, e) => {
    setselectedMentioned([...selectedMentioned, option.key]);
  };

  return (
    <div>
      <div className="flex items-center justify-center mt-3 bg-white p-1">
        <div className={`w-full mr-3 ${styles.commentInput}  `}>
          <Mentions
            style={{ width: "100%" }}
            value={newMessage}
            onChange={handleMentionChange}
            onSelect={handleMentionSelect}
            prefix="@"
            placeholder="Type your comment"
            autoSize={{ minRows: 2, maxRows: 6 }}
            rows={4}
            autoFocus
            defaultValue=""
            loading={sendLoading}
          >
            {_.map(allMembers, (item) => {
              return {
                value: _.replace(item.full_name, /\s+/g, "_"),
                label: _.replace(item.full_name, /\s+/g, "_"),
                _id: item._id,
                ...item,
              };
            }).map((option) => (
              <Mentions.Option key={option._id} value={option.value}>
                <div className="flex items-center">
                  <UserAvatar user={option} size={24} className="mr-2" />
                  {option.label}
                </div>
              </Mentions.Option>
            ))}
          </Mentions>
        </div>
        <div className="flex items-center">
          <SmileOutlined
            className="cursor-pointer icon mr-3"
            onClick={() => setIsPickerVisible(!isPickerVisible)}
          />
          <Button
            type="primary"
            onClick={handleAddComment}
            loading={sendLoading}
          >
            <SendOutlined className="cursor-pointer" />
          </Button>
        </div>
        {isPickerVisible && (
          <Picker
            native={true}
            onEmojiClick={onEmojiClick}
            pickerStyle={{
              position: "absolute",
              marginTop: 385,
              marginLeft: 140,
            }}
          />
        )}
      </div>
      <div className="mt-8">
        {loading ? (
          <div className="mt-32 relative">
            <Loader />
          </div>
        ) : (
          _.map(commentsData, (comment) => <ChatContent comment={comment} />)
        )}
      </div>
    </div>
  );
};

export default CommentTab;
