import gql from "graphql-tag";

export const LIST_PLANS = gql`
  query {
    listPlans {
      _id
      name
      currency
      cost
      stripe
      period
      permissions {
        value
        role {
          key
        }
      }
    }
  }
`;
