import { GET_OVERVIEW_WIDGET } from "graphql/queries/widget/getOverviewWidget";
import { GET_ACTIVITY_BY_PROJECT } from "graphql/queries/member/getActivityByProject";
import { GET_RECENT_ACTIVITY } from "graphql/queries/Activity/getRecentActivity";
import { GET_ACTIVITY_COUNT_WIDGET } from "graphql/queries/widget/getActivityCountWidget";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import moment from "moment";
import _ from "lodash";
import utility from "common/utility";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";

export function updateProjectOverview(cache, operation) {
  const data = cache.readQuery({
    query: GET_OVERVIEW_WIDGET,
  });

  if (data) {
    const { getOverviewWidget } = data;
    const copy = _.cloneDeep(getOverviewWidget);

    if (operation === "add") {
      copy.projects++;
    } else {
      copy.projects--;
    }

    cache.writeQuery({
      query: GET_OVERVIEW_WIDGET,
      data: {
        getOverviewWidget: copy,
      },
    });
  }
}

export function updateProjectPerformance(cache, project, operation) {
  const data = cache.readQuery({
    query: GET_ACTIVITY_BY_PROJECT,
    variables: { days: 7 }, // 7 days by default
  });

  if (data) {
    const { getActivityByProject } = data;
    const copy = _.cloneDeep(getActivityByProject);
    const newObject = {
      activities: [],
      status: { isPositive: "false", value: "00:00:00" },
      title: project.title,
      total_activities: null,
      total_time: "00:00:00",
    };

    if (operation === "add") {
      copy.push(newObject);
    } else {
      const index = _.findIndex(copy, (ele) => ele._id === project._id);
      copy.splice(index, 1);
    }

    cache.writeQuery({
      query: GET_ACTIVITY_BY_PROJECT,
      data: {
        getActivityByProject: copy,
      },
      variables: { days: 7 },
    });
  }
}

export function updatedPlannedActivity(cache, activity, operation) {
  const data = cache.readQuery({
    query: GET_PLAN_CALENDAR_ACTIVITY,
  });

  if (data) {
    const { getPlannedActivity } = data;
    const copy = _.cloneDeep(getPlannedActivity);

    let payload = {};
    if (operation === "planned") {
      payload = {
        getPlannedActivity: utility.orderActivitiesByDate([...copy, activity]),
      };
    } else {
      payload = {
        getPlannedActivity: _.filter(
          copy,
          (value) => value._id !== activity._id
        ),
      };
    }

    cache.writeQuery({
      query: GET_PLAN_CALENDAR_ACTIVITY,
      data: payload,
    });
  }
}

export function updateActivityOverview(cache, operation) {
  const data = cache.readQuery({
    query: GET_OVERVIEW_WIDGET,
  });

  if (data) {
    const { getOverviewWidget } = data;
    const copy = _.cloneDeep(getOverviewWidget);

    if (operation === "add") {
      copy.activities++;
    } else {
      copy.activities--;
    }

    cache.writeQuery({
      query: GET_OVERVIEW_WIDGET,
      data: {
        getOverviewWidget: copy,
      },
    });
  }
}

export function updateRecentActivities(cache, activity, action) {
  const data = cache.readQuery({
    query: GET_RECENT_ACTIVITY,
  });

  if (data) {
    const { getRecentActivity } = data;
    const copy = _.cloneDeep(getRecentActivity);

    let activityFormat = {};

    if (action === "stop") {
      activityFormat = {
        ...activity,
        status: 3,
      };
    }

    if (action === "paused") {
      const {
        time: { paused = [] },
      } = activity;

      activityFormat = {
        ...activity,
        status: 2,
        time: {
          ...activity.time,
          created_at: _.last(paused).created_at,
          end: _.last(paused).time,
          total_time: _.last(paused).total,
        },
      };
    }

    cache.writeQuery({
      query: GET_RECENT_ACTIVITY,
      data: {
        getRecentActivity: {
          day: _.orderBy([activityFormat, ...copy.day], ["time.end"]),
          month: _.orderBy([activityFormat, ...copy.month], ["time.end"]),
          week: _.orderBy([activityFormat, ...copy.week], ["time.end"]),
        },
      },
    });
  }
}

export function updateActivityCount(cache, activity) {
  const data = cache.readQuery({
    query: GET_ACTIVITY_COUNT_WIDGET,
  });

  if (data) {
    const { getActivityCountWidget } = data;
    const copy = _.cloneDeep(getActivityCountWidget);
    const date = moment(activity?.time?.start).format("MM/DD/YY");

    const index = _.findIndex(
      copy.data,
      (value) => value.date === String(date)
    );

    if (index >= 0) {
      const newCount = Number(copy.data[index].count) + 1;
      copy.data[index] = {
        ...copy.data[index],
        count: String(newCount),
      };
    } else {
      copy.data.push({ date, count: 1 });
    }

    cache.writeQuery({
      query: GET_ACTIVITY_COUNT_WIDGET,
      data: {
        getActivityCountWidget: copy,
      },
    });
  }
}

export function updateContinueActivityList(cache, activity, operation) {
  const data = cache.readQuery({
    query: GET_PAUSED_ACTIVITY,
  });

  if (data) {
    const { getPausedActivity } = data;
    const copy = _.cloneDeep(getPausedActivity);

    let newArray = [];

    if (operation === "continue")
      newArray = _.filter(copy, (value) => value._id !== activity._id);

    if (operation === "paused") newArray = [activity, ...copy];

    cache.writeQuery({
      query: GET_PAUSED_ACTIVITY,
      data: {
        getPausedActivity: newArray,
      },
    });
  }
}

export function updatePlannedActivityDate(cache, activity) {
  const data = cache.readQuery({
    query: GET_PLAN_CALENDAR_ACTIVITY,
  });

  if (data) {
    const { getPlannedActivity } = data;
    const copy = _.cloneDeep(getPlannedActivity);

    const payload = {
      getPlannedActivity: utility.orderActivitiesByDate(
        _.unionBy([activity], copy, "_id")
      ),
    };

    cache.writeQuery({
      query: GET_PLAN_CALENDAR_ACTIVITY,
      data: payload,
    });
  }
}
