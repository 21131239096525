import React, { useEffect, useState } from "react";
import WinduLogo from "common/assets/mobile_icon.png";
import {
  Typography,
  Button,
  Pagination,
  List,
  Result,
  Tooltip,
  Dropdown,
  Menu,
  ConfigProvider,
  Alert,
  DatePicker,
  Card,
} from "antd";
import {
  CheckCircleOutlined,
  CloseOutlined,
  FileSearchOutlined,
  FormOutlined,
  SendOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { Icon } from "@fishyvisions/windu-uikit";
import moment from "moment-timezone";
import { getExternalAccount } from "api/Stripe";
import EmptyData from "components/EmptyData";
import styles from "../styles.module.css";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_VISITOR_INVOICES } from "graphql/queries/Visitor/getInvoices";
import { GET_VISITOR_INVOICES_PREVIEW } from "graphql/queries/Visitor/getInvoicePreview";
import utility from "common/utility";
import { SEND_VISITOR_INVOICE } from "graphql/mutations/visitor.js/sendVisitorInvoice";
import { AntInput, AntInputPassword } from "components/FormikCustomInputs";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { REGISTER } from "graphql/mutations/register";
import HeroImage from "common/assets/visitor.svg";
import VisitorOnboarding from "components/Modals/VisitorOnboarding";
import { CREATE_VISITOR_BILLING_ACCOUNT } from "graphql/mutations/Stripe/createVisitorBillingAccount";
import { VISITOR_TO_USER } from "graphql/mutations/visitor.js/visitorToUser";

const { Text, Title, Link } = Typography;

const sizePerPage = 5;
const initialOffset = 0;

const handleDownload = ({ invoiceURL }) => {
  utility.donwloadHanlder({ url: invoiceURL });
};

const getInvoiceIcon = ({ status, invoiceId }) => {
  if (status === "PAID") {
    return (
      <CheckCircleOutlined
        style={{
          fontSize: "30px",
          backgroundColor: "#F6FFED",
          borderRadius: "50%",
          color: "#52C41A",
        }}
      />
    );
  }
  if (status === "DRAFT") {
    return (
      <FormOutlined
        style={{
          fontSize: "16px",
          border: "3px solid #0095FF",
          borderRadius: "50%",
          padding: 5,
          backgroundColor: "E2F8FF",
          color: "#0095FF",
        }}
      />
    );
  }
  if (status === "PENDING") {
    return (
      <SendOutlined
        style={{
          fontSize: "16px",
          border: "3px solid #FAAD14",
          borderRadius: "50%",
          padding: 5,
          backgroundColor: "#FFFBE6",
          color: "#FAAD14",
        }}
      />
    );
  }

  return null;
};

const HistoryPreview = ({ setCreateInvoice }) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const user = useRecoilValue(userSession);
  const [previewData, setPreviewData] = useState([]);
  const [newAccountCreation, setNewAccountCreation] = useState(false);
  const [visitorOnboardingModal, setVisitorOnboardingModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isBillingDetailsValid, setisBillingDetailsValid] =
    React.useState(false);

  const initialFormValues = {
    password: "",
    name: "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().trim().required("This field is required"),
    password: yup
      .string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  });

  const [queryParameters, setQueryParameters] = React.useState({
    size: sizePerPage,
    offset: initialOffset,
  });

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      offset: newOffset,
      size: pageSize,
    });
  };

  const [createVisitorBillingAccount, { loading: accountLoading }] =
    useMutation(CREATE_VISITOR_BILLING_ACCOUNT, {
      onCompleted: ({ createVisitorBillingAccount }) => {
        window.location.href = createVisitorBillingAccount;
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    });

  const [getVisitorInvoices, { loading }] = useLazyQuery(GET_VISITOR_INVOICES, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getVisitorInvoices }) => {
      setInvoiceData(getVisitorInvoices);
    },
  });

  const [getPreview, { loading: invoicePreviewLoading }] = useLazyQuery(
    GET_VISITOR_INVOICES_PREVIEW,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getVisitorInvoicePreview }) => {
        setPreviewData(getVisitorInvoicePreview);
      },
    }
  );

  const [sendInvoiceEmail, { loading: sendInvoiceLoading }] = useMutation(
    SEND_VISITOR_INVOICE,
    {
      onCompleted: () => {
        utility.setNotification(
          `Email sent`,
          "Invoice is sent successfully to client",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const InvoiceDropDown = ({ status, invoiceId, invoiceURL }) => {
    return (
      <Menu className="py-0" disabled={sendInvoiceLoading}>
        {status === "PENDING" && (
          <>
            <Menu.Item
              className="py-4 flex items-center"
              key="7"
              onClick={() =>
                sendInvoiceEmail({
                  variables: {
                    invoiceId,
                  },
                })
              }
            >
              Email invoice to client
            </Menu.Item>
          </>
        )}
        <>
          <Menu.Divider className="my-0" />
          <Menu.Item
            className="py-4 flex items-center"
            key="5"
            onClick={() => handleDownload({ invoiceURL })}
          >
            Download PDF
          </Menu.Item>
        </>
      </Menu>
    );
  };

  useEffect(() => {
    if (!_.isEmpty(invoiceData?.data)) {
      getPreview({ variables: { invoiceId: invoiceData.data[0]?._id } });
    }
  }, [invoiceData?.data]);

  const previewHandler = (id) => {
    getPreview({ variables: { invoiceId: id } });
  };

  const [registerUser, { loading: registerLoading }] = useMutation(
    VISITOR_TO_USER,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        window.location.href = "/login?confirm=true";
      },
    }
  );

  const handleDone = (values) => {
    const { password, name } = values;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    registerUser({
      variables: {
        input: { email: user.email, password, name: name, timezone },
      },
    });
  };
  const checkBillingDetailsValid = async (stripe) => {
    const getAcountInfoData = await getExternalAccount({
      account: stripe,
    });
    setisBillingDetailsValid(getAcountInfoData?.data?.data);
  };

  useEffect(() => {
    if (!_.isEmpty(user?.account)) {
      getVisitorInvoices({
        variables: { input: queryParameters },
      });
      checkBillingDetailsValid(user?.account?.stripe);
    }
  }, [user]);

  const handleOnBoarding = (title) => {
    setVisitorOnboardingModal(true);
    setModalTitle(title);
  };

  const handlePaymentDetails = () => {
    createVisitorBillingAccount({
      variables: {
        input: {
          return_url: window.location.href, /// Inject URL
        },
      },
    });
  };

  return (
    <div className="flex p-2 pl-4 justify-evenly">
      <div style={{ width: "38%" }}>
        <div className=" py-4">
          <img src={WinduLogo} style={{ width: "5.5vw" }} />
        </div>
        {user &&
          (_.isEmpty(user?.account?.stripe) ||
            _.isEmpty(isBillingDetailsValid)) && (
            <Card bodyStyle={{ background: "#FFB7B7" }} className="my-2">
              <div className="flex justify-evenly">
                <Text strong className=" w-3/5 text-base">
                  Your account still needs some attention! Please finish
                  onboarding your account.
                </Text>
                <Button
                  className=" text-white w-1/4 text-base"
                  style={{ background: "#F0195A" }}
                  onClick={handlePaymentDetails}
                  loading={accountLoading}
                >
                  View
                </Button>
              </div>
            </Card>
          )}
        {_.isEmpty(user) ? (
          <Card bodyStyle={{ background: "#F6FFED" }} className="my-2">
            <div className="flex justify-between">
              <Text strong className="mr-2 text-base">
                Send secured invoices and get paid for free by logging in or
                registering.
              </Text>
              <div className="flex flex-col items-center">
                <Button
                  className=" text-white text-base"
                  style={{ background: "#52C41A" }}
                  onClick={() => handleOnBoarding("Login")}
                >
                  Login
                </Button>
                <Button
                  type="text"
                  className="text-base mt-2"
                  onClick={() => handleOnBoarding("Register")}
                >
                  Register
                </Button>
              </div>
            </div>
          </Card>
        ) : (
          <Card bodyStyle={{ background: "#F6FFED" }} className="my-2">
            {newAccountCreation ? (
              <div className="flex justify-evenly">
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => handleDone(values)}
                >
                  {({ handleSubmit, submitCount, setFieldValue, values }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="flex justify-between">
                          <Text className="text-base">
                            Please set full name and confirm password to create
                            an account for <Text strong>{user?.email}</Text>
                          </Text>
                          <CloseOutlined
                            onClick={() => setNewAccountCreation(false)}
                          />
                        </div>
                        <div className="flex items-center mt-2">
                          <div className="flex items-start">
                            <Field
                              component={AntInput}
                              name="name"
                              placeholder="Enter your fullname"
                              submitCount={submitCount}
                              hasFeedback
                              required={true}
                              label="Full name"
                            />
                            <Field
                              component={AntInputPassword}
                              name="password"
                              placeholder="Enter your password"
                              submitCount={submitCount}
                              hasFeedback
                              label="Confirm password"
                              required={true}
                            />
                          </div>
                          <Button
                            style={{ background: "#52C41A" }}
                            onClick={handleSubmit}
                            loading={registerLoading}
                            className="text-white mt-3 ml-2"
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            ) : (
              <div className="flex justify-evenly">
                <Text strong className=" w-3/5 text-base">
                  Take it a step further, finish creating your account and start
                  tracking your time.
                </Text>
                <Button
                  className=" text-white w-1/4 text-base"
                  style={{ background: "#52C41A" }}
                  onClick={() => setNewAccountCreation(true)}
                >
                  Start
                </Button>
              </div>
            )}
          </Card>
        )}
        <div className="mt-4">
          <div className="flex justify-between items-center px-3">
            <div>
              <Title style={{ fontSize: "16px", color: "#665AAA" }}>
                INVOICE HISTORY
              </Title>
              <Text>{invoiceData?.pagination?.total_data} documents</Text>
            </div>
            <div className="flex items-center">
              <div className="pl-4">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setCreateInvoice(true)}
                  disabled={
                    _.isEmpty(user) ||
                    _.isEmpty(user?.account?.stripe) ||
                    _.isEmpty(isBillingDetailsValid)
                  }
                >
                  Create invoice
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {!_.isEmpty(user) && (
              <>
                <ConfigProvider renderEmpty={() => <EmptyData />}>
                  <List
                    style={{ border: "1px solid #F0F0F0" }}
                    className="p-4 mt-6 rounded-sm"
                    itemLayout="horizontal"
                    dataSource={invoiceData?.data}
                    loading={loading}
                    renderItem={(item) => {
                      let invoiceText = "";
                      if (item.status === "PENDING")
                        invoiceText = "Invoice Pending";
                      if (item.status === "DRAFT")
                        invoiceText = "Invoice Draft";
                      if (item.status === "PAID") invoiceText = "Invoice Paid";
                      return (
                        <div className="bg-gray-100 p-3 mt-2 rounded-md">
                          <div className="flex justify-between ">
                            <div className="flex items-center ">
                              <div
                                className="flex cursor-pointer"
                                onClick={() => previewHandler(item._id)}
                              >
                                <div
                                  className="flex flex-col py-4 px-2"
                                  style={{
                                    background: "#3C2E94",
                                    borderRadius: "8px 0px 0px 8px",
                                  }}
                                >
                                  <FileSearchOutlined
                                    style={{ fontSize: "40px", color: "white" }}
                                  />
                                </div>
                                <div className="flex ml-3 items-center">
                                  <div>
                                    <Tooltip
                                      title={invoiceText}
                                      placement="bottom"
                                    >
                                      {getInvoiceIcon({
                                        status: item.status,
                                        invoiceId: item._id,
                                      })}
                                    </Tooltip>
                                  </div>
                                  <div className="flex flex-col ml-3 text-left">
                                    <Text
                                      className="font-semibold"
                                      onClick={() => previewHandler(item._id)}
                                    >
                                      {item.name}
                                    </Text>
                                    <Text className="text-gray-500">
                                      {moment(new Date(item.created_at)).format(
                                        "LL"
                                      )}
                                    </Text>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center mr-3">
                              <Dropdown
                                placement="bottomRight"
                                overlay={
                                  <InvoiceDropDown
                                    status={item.status}
                                    invoiceId={item._id}
                                    invoiceURL={item.invoiceURL}
                                  />
                                }
                                trigger={["click"]}
                              >
                                <span
                                  onClick={(e) => e.preventDefault()}
                                  className="cursor-pointer"
                                >
                                  <Icon type="Meatball" size="small" />
                                </span>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </ConfigProvider>
                <div className="mt-5 flex justify-center">
                  <Pagination
                    className="my-4 text-center"
                    onChange={onPageChange}
                    current={invoiceData?.pagination?.current_page}
                    total={invoiceData?.pagination?.total_data || 0}
                    showTotal={(total, range) =>
                      `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
                    }
                    pageSize={queryParameters.size}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <>
        {_.isEmpty(previewData) || _.isEmpty(user) ? (
          <img src={HeroImage} style={{ width: "40vw", marginTop: "14vh" }} />
        ) : (
          <div className=" w-6/12 mt-10">
            <>
              {invoicePreviewLoading ? (
                <div>Loading preview... </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewData,
                  }}
                  className={styles.invoicePreview}
                />
              )}
            </>
          </div>
        )}
      </>

      <VisitorOnboarding
        isVisible={visitorOnboardingModal}
        onClose={() => setVisitorOnboardingModal(false)}
        title={modalTitle}
      />
    </div>
  );
};

export default HistoryPreview;
