import React, { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { Field } from "formik";
import { map, isEmpty, debounce } from "lodash";
import { AntSelect } from "components/FormikCustomInputs";
import { number, string, func } from "prop-types";
import Loader from "components/Loader";
import { GET_MEMBERS } from "graphql/queries/member/getMembers";

const sizePerPage = 6;
const initialOffset = 0;

const CreateProjectTeam = ({
  submitCount,
  filterSearchUser,
  setFilterSearchUser,
}) => {
  const queryParameters = {
    size: sizePerPage,
    offset: initialOffset,
    search: "",
    project: "",
  };

  const [getMembers, { loading }] = useLazyQuery(GET_MEMBERS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getMembers }) => {
      setFilterSearchUser(getMembers.data);
    },
  });

  const handleSearch = useCallback(
    debounce(async (v) => {
      if (!isEmpty(v)) {
        getMembers({ variables: { input: { ...queryParameters, search: v } } });
      } else {
        setFilterSearchUser([]);
      }
    }, 300),
    []
  );

  return (
    <Field
      component={AntSelect}
      name="member"
      label="Add a new member"
      placeholder="Search by email"
      mode="tags"
      submitCount={submitCount}
      selectOptions={map(filterSearchUser, (user) => {
        return { value: user.email, label: user.email };
      })}
      loading={loading}
      inputType="select"
      hasFeedback
      required={true}
      size="large"
      showSearch
      notFoundContent={
        loading ? (
          <Loader size="small" />
        ) : (
          <div className="flex justify-center">No Data</div>
        )
      }
      filterOption={false}
      onSearch={handleSearch}
      getPopupContainer={(node) => node.parentNode}
      dropdownRender={(menu) => <div>{menu}</div>}
    />
  );
};

CreateProjectTeam.propTypes = {
  submitCount: number,
  currentEmail: string,
  setFieldValue: func,
  project: string,
};

export default CreateProjectTeam;
