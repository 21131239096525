import React from "react";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { SelectionPlus } from "@phosphor-icons/react";
import { Button, Select, Tooltip, Typography } from "antd";
import {
  CaretDownOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import styles from "./styles.module.css";
import _ from "lodash";
import utility from "common/utility";

const { Text } = Typography;

export const CustomToolbarHeader = ({
  toolbar,
  setActivitySelectorDrawer,
  setShowPlanActivityModal,
  setSelectedCalendarView,
}) => {
  const goToPrevMonth = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate("prev");
  };

  const goToNextMonth = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate("next");
  };

  const goToNextWeek = () => {
    const nextWeek = moment(toolbar.date).add(1, "week").toDate();
    toolbar.onNavigate("next", nextWeek);
  };

  const goToPrevWeek = () => {
    const prevWeek = moment(toolbar.date).subtract(1, "week").toDate();
    toolbar.onNavigate("prev", prevWeek);
  };

  const goToNextDay = () => {
    const nextDay = moment(toolbar.date).add(1, "day").toDate();
    toolbar.onNavigate("next", nextDay);
  };
  const goToPrevDay = () => {
    const prevDay = moment(toolbar.date).subtract(1, "day").toDate();
    toolbar.onNavigate("prev", prevDay);
  };
  const formatWeeklyRange = (toolbar) => {
    const startDate = moment(toolbar.date).startOf("week");
    const endDate = moment(toolbar.date).endOf("week");

    const startDay = startDate.format("DD");
    const endDay = endDate.format("DD");
    const startMonth = startDate.format("MMMM");
    const endMonth = endDate.format("MMMM");
    const year = startDate.format("YYYY");

    if (startMonth === endMonth) {
      return `${startDay}-${endDay} ${startMonth} ${year}`;
    } else {
      return `${startDay} ${startMonth} -${endDay} ${endMonth} ${year}`;
    }
  };

  const date = moment(toolbar.date);
  const DayWeekChangeButtons = () => {
    if (toolbar.view === "month") return null;

    if (toolbar.view === "day") {
      return (
        <div className="flex items-center">
          <Button
            onClick={goToPrevDay}
            shape="circle"
            size="large"
            icon={<LeftOutlined />}
            className="bg-transparent"
          />
          <Text strong className="text-lg uppercase mx-4">
            {date.format("dddd MMM D")}
          </Text>
          <Button
            shape="circle"
            size="large"
            className="bg-transparent"
            icon={<RightOutlined />}
            onClick={goToNextDay}
          />
        </div>
      );
    }

    if (toolbar.view === "week" || toolbar.view === "work_week") {
      return (
        <div className="flex items-center">
          <Button
            onClick={goToPrevWeek}
            shape="circle"
            size="large"
            icon={<LeftOutlined />}
            className="bg-transparent"
          />
          <Text strong className="text-lg uppercase mx-4">
            {formatWeeklyRange(toolbar)}
          </Text>
          <Button
            shape="circle"
            size="large"
            className="bg-transparent"
            icon={<RightOutlined />}
            onClick={goToNextWeek}
          />
        </div>
      );
    }
  };

  const currentViewInCalendar = toolbar.view;
  return (
    <div className="mb-4 flex items-center justify-between px-2">
      {/* month change buttons */}
      {currentViewInCalendar === "month" ? (
        <div className="flex items-center">
          <Button
            onClick={goToPrevMonth}
            shape="circle"
            size="large"
            icon={<LeftOutlined />}
            className="bg-transparent"
          />
          <Text strong className="text-lg uppercase ml-4">
            {date.format("MMMM YYYY")}
          </Text>
          <Button
            shape="circle"
            size="large"
            className="ml-3 bg-transparent"
            icon={<RightOutlined />}
            onClick={goToNextMonth}
          />
        </div>
      ) : (
        <DayWeekChangeButtons />
      )}

      <div className={styles.viewSelectDiv}>
        <div className="flex items-center">
          <Select
            defaultValue={currentViewInCalendar}
            style={{
              width: 100,
            }}
            key={currentViewInCalendar}
            size="large"
            className={`font-semibold bg-white ${styles.viewSelectorDiv} `}
            onChange={(value) => setSelectedCalendarView(value)}
            options={[
              {
                value: "week",
                label: "Week",
              },
              {
                value: "day",
                label: "Day",
              },
              {
                value: "month",
                label: "Month",
              },
              {
                value: "work_week",
                label: "Weekdays",
              },
            ]}
            suffixIcon={<CaretDownOutlined />}
          />
          <Tooltip title="Open activity selector">
            <Button
              shape="circle"
              size="large"
              className="bg-transparent ml-4 flex items-center justify-center"
              icon={<SelectionPlus size={24} color="#342d2d" />}
              onClick={() => {
                setActivitySelectorDrawer((prev) => !prev);
              }}
            />
          </Tooltip>

          <Button
            className="ml-4"
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowPlanActivityModal(true);
            }}
          >
            Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export const WeekCalendarHeader = ({ v, calendarRef }) => {
  let events = [];
  if (calendarRef && calendarRef.current) {
    events = calendarRef?.current?.props?.events;
  }
  const isTodayDate =
    moment().format("DD/MM/YYYY") === moment(v.date).format("DD/MM/YYYY");

  const todayEvents = events.filter((event) => {
    return (
      moment(event.start).format("DD/MM/YYYY") ===
      moment(v.date).format("DD/MM/YYYY")
    );
  });

  const todayTimeBoxArray = _.map(todayEvents, (event) => {
    return utility.calculateTimeDifference([event.end, event.start]);
  });
  const totalTimeBox = utility.calculateTimeTotal(todayTimeBoxArray);

  return (
    <div className="flex items-center p-2">
      {isTodayDate ? (
        <div
          style={{
            background: "#604BC6",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
          }}
        >
          <Text className="text-lg strong text-white">
            {moment(v.date).format("DD")}
          </Text>
        </div>
      ) : (
        <Text className="text-lg strong">{moment(v.date).format("DD")}</Text>
      )}

      <div className="flex flex-col items-start ml-3">
        <Text
          className="text-base -mb-1 uppercase"
          strong
          style={{ color: "#6B7A99" }}
        >
          {moment(v.date).format("ddd")}
        </Text>
        <Text strong className="text-base" style={{ color: "#3C2E94" }}>
          {totalTimeBox}
        </Text>
      </div>
    </div>
  );
};

export const DayCalendarHeader = ({ v, calendarRef }) => {
  let events = [];
  if (calendarRef && calendarRef.current) {
    events = calendarRef?.current?.props?.events;
  }

  const todayEvents = events.filter((event) => {
    return (
      moment(event.start).format("DD/MM/YYYY") ===
      moment(v.date).format("DD/MM/YYYY")
    );
  });

  const todayTimeBoxArray = _.map(todayEvents, (event) => {
    return utility.calculateTimeDifference([event.end, event.start]);
  });
  const totalTimeBox = utility.calculateTimeTotal(todayTimeBoxArray);
  return (
    <div className="flex  p-2">
      <Text strong className="text-base ml-6" style={{ color: "#3C2E94" }}>
        {totalTimeBox}
      </Text>
    </div>
  );
};
