import gql from "graphql-tag";

export const GET_ROLES = gql`
  query {
    getRoles {
      _id
      name
      routing {
        label
        path
        inNav
      }
      users {
        _id
      }
    }
  }
`;
