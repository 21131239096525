import React from "react";
import { Alert, Button } from "antd";

const InvalidSettings = () => {
  const handleAction = () => {
    window.open(
      "https://fishyvisions.zendesk.com/hc/en-us/articles/4419863447703-Allowing-Windu-to-Access-Jira-Information",
      ""
    );
  };
  return (
    <div>
      <Alert
        className="mb-3"
        message="Heads up!"
        description="Your Jira account does not have permission to fully utilize this widget. Please reference to this helpful article."
        type="error"
        showIcon
        action={
          <Button onClick={handleAction} size="small" danger>
            View Article
          </Button>
        }
      />
    </div>
  );
};

export default InvalidSettings;
