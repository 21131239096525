import gql from "graphql-tag";

export const GET_WORKSPACE_ACCOUNTS = gql`
  query ($input: billingAccountInput) {
    getBillingAccounts(input: $input) {
      id
      payouts_enabled
      requirements {
        disabled_reason
        currently_due
      }
    }
  }
`;
