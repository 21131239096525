import gql from "graphql-tag";

export const GET_MEMBERS = gql`
  query getMembers($input: getProjectInfo) {
    getMembers(input: $input) {
      data {
        _id
        full_name
        email
        avatar
        total_time
        nro_projects
        total_activities
        projects {
          _id
          title
        }
        rates {
          currency
          rate
        }
      }
      pagination {
        current_page
        total_data
        total_pages
      }
    }
  }
`;
