import React, { useState } from "react";
import { Select } from "antd";
import { map, debounce, uniqBy, find } from "lodash";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "graphql/queries/projects/getProjects";
import Loader from "components/Loader";
import cx from "classnames";

const sizePerPage = 10;
const initialOffset = 0;

const defaultProjectFilters = {
  status: "",
  sort: `{"updated_at": -1}`,
  relationship: "",
};

const ProjectSelect = ({
  onChange,
  value,
  className,
  placeholder,
  onClear,
  initialSearch = "",
  onCurrentSelected = () => {},
}) => {
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
    filters: defaultProjectFilters,
  });
  const [projects, setProjects] = useState([]);

  const { loading } = useQuery(GET_PROJECTS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjects }) => {
      setProjects(
        uniqBy([...new Set([...projects, ...getProjects.data])], "_id")
      );
    },
  });

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setQueryParameters({
        ...queryParameters,
        offset: queryParameters.offset + 10,
      });
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const onSearch = (query) => {
    setProjects([]);
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  const handleClear = () => {
    onClear();
    setQueryParameters({
      ...queryParameters,
      search: "",
      offset: 0,
    });
  };

  // this is for interaction between projects page and members page, when consulting list of members
  React.useEffect(() => {
    if (initialSearch) {
      const found = find(
        projects,
        (project) =>
          project._id === initialSearch || project.title === initialSearch
      );

      if (found) {
        onCurrentSelected({
          label: found.title,
          value: found._id,
          manager: found.manager?._id,
          owner: found.created_by._id,
        });
      }
    }
  }, [initialSearch, projects]);

  const selectValue = value || [];

  return (
    <Select
      placeholder={placeholder}
      size="large"
      className={cx(className, "w-64")}
      onPopupScroll={onScroll}
      options={map(projects, (project) => {
        return {
          label: project.title,
          value: project._id,
          manager: project.manager?._id,
          owner: project.created_by._id,
          color:project?.color
        };
      })}
      showSearch
      value={!loading && selectValue}
      filterOption={false}
      loading={loading}
      allowClear
      onChange={onChange}
      onClear={handleClear}
      onSearch={(value) => delayedQuery(value)}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {loading && (
            <div>
              <Loader size={"small"} />
            </div>
          )}
        </div>
      )}
    />
  );
};

ProjectSelect.defaultProps = {
  onClear: () => {},
};
export default ProjectSelect;
