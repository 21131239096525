import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import HighFiveSvg from "common/assets/highFive.svg";
import styles from "./styles.module.css";
import Helmet from "react-helmet";

const TourSuccess = ({ isVisible, onClose }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  return (
    <>
      {showCalendar && (
        <Helmet>
          <link
            href="https://assets.calendly.com/assets/external/widget.css"
            rel="stylesheet"
          />
          <script
            src="https://assets.calendly.com/assets/external/widget.js"
            type="text/javascript"
            async
          ></script>
        </Helmet>
      )}
      <Modal visible={isVisible} footer={null} onCancel={onClose} width={700}>
        <div className="flex justify-center flex-col py-10">
          <div className="flex items-center mt-10 justify-center">
            {showCalendar ? (
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/fishyvisions/windu-success?primary_color=f3a536"
                style={{ minWidth: 620, height: 430 }}
              ></div>
            ) : (
              <img src={HighFiveSvg} />
            )}
          </div>
          {!showCalendar && (
            <div className="flex flex-col items-center">
              <Typography.Title level={3} className="mb-0">
                Congratulations!
              </Typography.Title>
              <Typography.Text style={{ marginTop: 4 }}>
                You just completed the tour of the dashboard with an example
                project. Feel free to schedule a complimentary meeting with our
                customer success, to ensure you learn about other features that
                you can take advantage of.
              </Typography.Text>

              <Button
                onClick={setShowCalendar}
                type="primary"
                className={`my-4 ml-1 outline-none border-none flex items-center ${styles.blackNextButton} p-5`}
              >
                Book
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default TourSuccess;
