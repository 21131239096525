import gql from "graphql-tag";

export const GET_BILLINGS = gql`
  query ($input: getBillingInput) {
    getBillings(input: $input) {
      data {
        id
        plan {
          totalAmount
          name
          cost
          currency
          stripe
          quantity
        }
        addons {
          totalAmount
          name
          cost
          currency
          stripe
        }
        totalAmount
        date
        status
        pdfLink
      }
      totalCount
    }
  }
`;
