import gql from "graphql-tag";

export const LOGOUT = gql`
  mutation {
    logout {
      logout
      email
    }
  }
`;
