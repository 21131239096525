import React, { useState, useEffect } from "react";
import { Typography, Select, Avatar, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { getPullRequestsFromRepo } from "api/Github";
import { map, isNil, isEmpty } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import styles from "../styles.module.css";
import ThreeDashImg from "../../../common/assets/threeDash.png";
import EmptyData from "../../EmptyData";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import { useRecoilValue } from "recoil";

const { Title, Text, Link } = Typography;

const OrganizationView = ({ token, repos }) => {
  const [repo, setSelectedRepo] = useState(repos[0]);
  const [pull_requests, setPull_requests] = useState([]);
  const [reloading, setReloading] = useState(false);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const enlargeModal = useRecoilValue(enlargeState);

  useEffect(() => {
    // handling default repos

    if (!isNil(repos[0]) || repo !== repos[0]) {
      setSelectedRepo(repos[0].repoName);
    }
  }, [repos]);

  const handleAddPullRequests = (pull_requests) => {
    const {
      data: {
        data: {
          search: { nodes },
        },
      },
    } = pull_requests;
    setPull_requests(nodes[0]?.pullRequests?.nodes);
  };

  useEffect(() => {
    if (!isNil(repo)) {
      getPullRequestsFromRepo(repo, id, token).then((r) => {
        handleAddPullRequests(r);
      });
    }
  }, [repo]);

  const handleReload = () => {
    setReloading(true);
    getPullRequestsFromRepo(repo, id, token).then((r) => {
      setReloading(false);
      handleAddPullRequests(r);
    });
  };

  const handleChange = (v) => {
    setSelectedRepo(v);
    getPullRequestsFromRepo(v, id, token).then((r) => {
      handleAddPullRequests(r);
    });
  };

  const createdWhen = (date) => {
    return moment(date).fromNow();
  };

  return (
    <section
      className={styles.hiddenScroll}
      style={{
        maxHeight: enlargeModal.visible ? "auto" : "366px",
        overflowY: "auto",
      }}
    >
      <div className={styles.flexBetween + " mt-4"}>
        <div>
          {!isNil(repo) && (
            <Select
              defaultValue={repo}
              style={{ width: "300px" }}
              size={"large"}
              showSearch
              placeholder="Search Organization"
              options={map(repos, (repo) => {
                return {
                  label: repo.repoName,
                  value: repo.repoName,
                };
              })}
              onChange={handleChange}
            />
          )}
        </div>
        <div className={styles.reloadIcon}>
          <ReloadOutlined
            spin={reloading}
            onClick={handleReload}
            style={{ fontSize: "18px" }}
          />
        </div>
      </div>
      <div className={"mt-3"}>
        {!isEmpty(pull_requests) ? (
          pull_requests.map((pr, i) => {
            const description = `${pr?.author?.login} -  ${createdWhen(
              pr?.createdAt
            )}`;
            return (
              <div
                key={i}
                className={
                  i + 1 < pull_requests.length ? styles.borderGray : ""
                }
              >
                <div className={styles.organizationList + " py-4"}>
                  <div>
                    <Avatar size="large" src={pr?.author?.avatarUrl} />
                  </div>

                  <div className={"pl-3"}>
                    <Link target="_blank" href={pr?.url}>
                      <Title
                        style={{ color: "#121212", fontWeight: "400" }}
                        level={4}
                      >
                        {pr.title}
                        {map(pr.labels?.nodes, (label) => {
                          return (
                            <Tag className="ml-2" color={`#${label.color}`}>
                              {label.name}
                            </Tag>
                          );
                        })}
                      </Title>
                    </Link>
                    <Text style={{ color: "#888888" }}>{description}</Text>
                    <div className={styles.orgToRepoWrapper + " mt-2"}>
                      <span className={styles.primaryPill}>
                        {pr.headRefName}
                      </span>

                      <img
                        src={ThreeDashImg}
                        width="18px"
                        alt="dash"
                        className="mx-2"
                      />
                      <span className={styles.primaryPill}>
                        {pr.baseRefName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <EmptyData />
        )}
      </div>
    </section>
  );
};
export default OrganizationView;
