import gql from "graphql-tag";

export const GET_WORKSPACE_WIDGETS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getWorkspaceWidgets(input: $input) {
      _id
      widget {
        _id
        name
        label
      }
      toggle
      i
      x
      y
      w
      h
    }
  }
`;
