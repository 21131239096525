import axios from "axios";
import _ from "lodash";

const ACCESS_KEY =
  process.env.EXCHANGE_API_KEY || "3701c13b25b043adc4c776fab9cd19ce";

const EXCHANGE_URL = "https://api.exchangeratesapi.io/v1";
export const getLatestRate = async ({ clientCurrency, memberCurrency }) => {
  const result = await axios.get(
    `${EXCHANGE_URL}/latest?access_key=${ACCESS_KEY}&base=${memberCurrency}`
  );

  const { data = {} } = result;
  const currentRate = _.find(
    data.rates,
    (_, index) => index === clientCurrency
  );
  return currentRate;
};

export const convertFromTo = async ({ from, to, amount }) => {
  const result = await axios.get(
    `${EXCHANGE_URL}/convert?access_key=${ACCESS_KEY}&from=${from}&to=${to}&amount=${amount}`
  );

  const { data = {} } = result;
  return data;
};

export const getSpecificRate = async ({ currency }) => {
  const result = await axios.get(
    `${EXCHANGE_URL}/latest?access_key=${ACCESS_KEY}&symbols=${currency}&base=USD`
  );

  const { data = {} } = result;
  return data;
};

export const getCurrencyExchangeRate = async ({ currency }) => {
  const result = await axios.get(
    `${EXCHANGE_URL}/latest?access_key=${ACCESS_KEY}&base=${currency}`
  );
  const { data = {} } = result;

  return data?.rates;
};
