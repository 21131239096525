import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`
  query {
    getNotifications {
      _id
      title
      subject
      type
      status
      created_at
      updated_at
      url
      meta {
        key
        value
      }
    }
  }
`;
