import gql from "graphql-tag";

export const GET_MANAGERS = gql`
  query($input: getManagersInput) {
    getManagers(input: $input) {
      data {
        _id
        email
        full_name
        avatar
        nro_projects
        projects {
          _id
          title
        }
        total_activities
        total_time
        created_at
        updated_at
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
