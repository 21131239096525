import gql from "graphql-tag";

export const GET_FEED_CHAT = gql`
  query getFeedChat($input: getFeedChatInput) {
    getFeedChat(input: $input) {
      _id
      currentPage
      messages {
        _id
        to
        from {
          _id
          email
          full_name
          avatar
        }
        unread
        fileSources
        content

        created_at
        updated_at
        edited
      }
      participants {
        _id
        email
        avatar
        full_name
      }
    }
  }
`;
