import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button } from "antd";
import Card from "../Card";
import styles from "../styles.module.css";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { TRELLO_AUTHORIZE } from "api/Trello";
import TrelloLogo from "../../../common/assets/trelloLogo.png";
import ConnectModal from "components/Modals/ConnectModal";

const { Text } = Typography;

const Auth = ({ loading, setAuthenticated }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [connectModal, setConnectModal] = useState(false);

  const handleAuth = () => {
    window.location.href = TRELLO_AUTHORIZE(id);
  };

  return (
    <Card title="Trello" style={{ height: "140px" }}>
      <Row>
        <Col md={2}>
          <img style={{ width: 50 }} src={TrelloLogo} alt="Monday Logo" />
        </Col>
        <Col md={15}>
          <div style={{ width: "80%", margin: "auto" }}>
            <Text>
              Connect Trello and start, pause and stop trackable tasks from your
              boards.
            </Text>
          </div>
        </Col>
        <Col md={7}>
          <Button
            onClick={() => setConnectModal({ label: "Trello", workspace: id })}
            type="primary"
            size="large"
            color="#029875"
            className={styles.connectBtn + " px-7 w-full"}
            loading={loading}
          >
            Connect
          </Button>
        </Col>
      </Row>
      {connectModal && (
        <ConnectModal
          visible={connectModal}
          setVisible={setConnectModal}
          callback={handleAuth}
          setAuthenticated={setAuthenticated}
        />
      )}
    </Card>
  );
};

export default Auth;
