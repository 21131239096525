import React, { useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Button, Layout, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { AntInputPassword, AntInput } from "components/FormikCustomInputs";
import Cookies from "js-cookie";
import Error from "components/Error";
import { useMutation } from "@apollo/client";
import { ADMIN_LOGIN } from "graphql/mutations/Admin/login";
import { Card } from "@fishyvisions/windu-uikit";
const { Title } = Typography;

const AdminLogin = ({ history }) => {
  const [error, setError] = useState({ show: false, message: "" });
  const form = useRef();
  const initialFormValues = {
    email: "",
    password: "",
  };
  const validationLoginSchema = yup.object().shape({
    email: yup
      .string()
      .required("This field is required")
      .email("This is not a valid email"),
    password: yup.string().required("This field is required"),
  });

  const [adminLogin, { loading }] = useMutation(ADMIN_LOGIN, {
    onError: (error) => handleError(error),
    onCompleted: (data) => {
      const {
        adminLogin: { token },
      } = data;
      Cookies.set("token", token, { expires: 1 });
      window.location.reload();
    },
  });

  const handleError = (error) => {
    const { message } = error;
    setError({ show: true, message });
  };

  const onLogin = (values) => {
    adminLogin({ variables: values });
  };

  const onSubmit = () => {
    form.current.submitForm();
  };

  return (
    <Layout className="h-screen w-full flex justify-center items-center">
      <Title className="mb-5">Admin</Title>
      <Card>
        {error.show && (
          <Error
            message={error.message}
            onClose={() => setError({ show: false, message: "" })}
          />
        )}
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationLoginSchema}
          onSubmit={onLogin}
          innerRef={form}
        >
          {({ submitCount, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={AntInput}
                  name="email"
                  label="Email"
                  placeholder="doe@gmail.com"
                  submitCount={submitCount}
                  hasFeedback
                  required={true}
                  size="large"
                />
                <Field
                  component={AntInputPassword}
                  name="password"
                  label="Password"
                  placeholder="Your secret password"
                  submitCount={submitCount}
                  hasFeedback
                  required={true}
                  size="large"
                />
                <div className="text-center">
                  <Button
                    size="large"
                    onClick={onSubmit}
                    key="submit"
                    className="w-full my-6 h-10"
                    theme="primary"
                    loading={loading}
                  >
                    Login
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Layout>
  );
};
export default withRouter(AdminLogin);
