import gql from "graphql-tag";

export const GET_ACTIVITY_COUNT_WIDGET = gql`
  query {
    getActivityCountWidget {
      data {
        date
        count
      }
    }
  }
`;
