import { Button, List, Typography } from "antd";
import { planInfoV2 } from "../planInfo";
import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from "lodash";
import {
  ANNUAL_DISCOUNT_PERCENTAGE,
  INDIVIDUAL_PLAN,
  INDIVIDUAL_PLAN_AMOUNT,
} from "constants/plans";

const Individual = ({
  currency,
  plans,
  rate,
  handleSubscribe,
  user,
  selectedPlan,
  isTrialUser,
  isAnnualPlan,
}) => {
  const [userCount, setUserCount] = useState(1);
  useEffect(() => {
    setUserCount(user?.settings?.uniqueProjectsUsers || 1);
  }, [user]);

  const showTrialButton =
    _.isEmpty(user?.settings?.trial) &&
    _.isEmpty(user?.plan) &&
    _.isEmpty(selectedPlan) &&
    !isTrialUser;

  const currentPlan = user?.plan?.name === INDIVIDUAL_PLAN;

  const planRate = (INDIVIDUAL_PLAN_AMOUNT * rate).toFixed(2);
  const discountPercentage = ANNUAL_DISCOUNT_PERCENTAGE;

  // Calculate discounted value
  const discountedValue = planRate - planRate * (discountPercentage / 100);

  // Format the result
  const formattedDiscountedValue = discountedValue.toFixed(2);

  return (
    <div className={styles.basicContainer}>
      <div className={styles.basicLeft}></div>
      <div className={styles.basicTop}></div>
      <div className={styles.basicPlanDetails}>
        <div className="m-8">
          <div className="flex items-center">
            <Typography.Title style={{ color: "white" }} level={4}>
              Individual
            </Typography.Title>
            {currentPlan && (
              <Typography.Text className="text-white ml-2">
                ( current plan )
              </Typography.Text>
            )}
          </div>

          <div className="flex mt-1 items-center">
            {isAnnualPlan ? (
              <div className="flex items-center">
                <Typography.Text
                  className="text-xl text-white"
                  strong
                  delete={isTrialUser || isAnnualPlan}
                >
                  {getSymbolFromCurrency(currency)}
                  {planRate}
                </Typography.Text>

                <Typography.Text
                  className="text-4xl ml-2 text-white"
                  strong
                  delete={isTrialUser}
                >
                  {getSymbolFromCurrency(currency)}
                  {formattedDiscountedValue}
                </Typography.Text>
              </div>
            ) : (
              <Typography.Text
                className="text-2xl text-white"
                strong
                delete={isTrialUser}
              >
                {getSymbolFromCurrency(currency)}
                {planRate}
              </Typography.Text>
            )}
            <div className="flex flex-col ml-1">
              {/* <Typography.Text className="text-white mb-0">
                per user
              </Typography.Text> */}
              <Typography.Text className="text-white mt-0">
                per month
              </Typography.Text>
            </div>
          </div>
          {_.isEmpty(selectedPlan) && (
            <Button
              className="w-full mt-8 mb-1"
              size="large"
              type="primary"
              onClick={() =>
                handleSubscribe(
                  _.find(
                    plans,
                    (item) =>
                      item?.name === INDIVIDUAL_PLAN &&
                      item?.period === (isAnnualPlan ? "Annual" : "Monthly")
                  ),
                  userCount
                )
              }
            >
              {user?.plan?.name === INDIVIDUAL_PLAN ? "Update " : "Choose "}
              Plan
            </Button>
          )}
          {showTrialButton && (
            <Button
              className="w-full my-1 bg-transparent text-white mb-6"
              size="large"
              type="default"
              onClick={() =>
                handleSubscribe(
                  _.find(
                    plans,
                    (item) =>
                      item?.name === INDIVIDUAL_PLAN &&
                      item?.period === (isAnnualPlan ? "Annual" : "Monthly")
                  ),
                  userCount,
                  true
                )
              }
            >
              Start Free Trial
            </Button>
          )}

          <div>
            <List
              className="mt-8 ml-2"
              dataSource={
                planInfoV2({ currency, rate })[INDIVIDUAL_PLAN]?.details
              }
              renderItem={(item) => (
                <List.Item style={{ border: "none", padding: "5px 0" }}>
                  <div className="flex items-center">
                    {item?.icon}
                    <div className="flex items-center">
                      <Typography.Text className="text-white">
                        {item.text}
                      </Typography.Text>
                      {item?.info}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Individual;
