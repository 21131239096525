import gql from "graphql-tag";

export const GET_PROJECT_TAGS = gql`
  query ($input: getProjectTagInput) {
    getProjectTags(input: $input) {
      tags
      stats {
        tag
        activities
      }
    }
  }
`;
