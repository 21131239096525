import { useQuery } from "@apollo/client";
import { Modal, Radio, Typography } from "antd";
import { LIST_ADDONS } from "graphql/queries/Addons/getAddons";
import React from "react";
import _ from "lodash";
import styles from "./styles.module.css";
import { getAddOnIcon } from "common/getAddonIcon";
import { ANNUAL_DISCOUNT_PERCENTAGE } from "constants/plans";

const { Text } = Typography;

const SelectAddon = ({
  visible,
  setVisible,
  selectedAddons,
  setselectedAddons,
  isAnnualPlan,
}) => {
  const { loading, data } = useQuery(LIST_ADDONS, {
    notifyOnNetworkStatusChange: true,
  });

  const getAddonDescription = (name) => {
    if (name == "Invoicing")
      return "Share your project with a client, manage, create and send invoices.";
    if (name == "Integration")
      return "Pull and interact with data from a range of 3rd party applications. .";
    if (name == "Chat") return "Add real-time communication inside of Windu.";
  };
  const getAddonCost = (addon, withoutDiscount) => {
    const addonCost = addon?.cost;

    if (addon.period === "Annual") {
      // Apply the annual discount if withoutDiscount is not specified
      const discountedAddonCost =
        addonCost - addonCost * (ANNUAL_DISCOUNT_PERCENTAGE / 100);
      const totalAnnualAddonCost = discountedAddonCost / 12;

      if (withoutDiscount) {
        return (addonCost / 12).toFixed(2);
      } else {
        return totalAnnualAddonCost.toFixed(2);
      }
    } else {
      return addonCost.toFixed(2);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
      title={"Available add-ons"}
    >
      <div className="mt-4 p-2">
        {_.map(
          _.filter(data?.getAddons, (addon) => {
            if (isAnnualPlan) {
              return addon.period === "Annual";
            } else {
              return addon.period === "Monthly";
            }
          }),
          (addon) => {
            const selected = _.some(selectedAddons, (item) =>
              _.isEqual(item, addon)
            );
            return (
              <div
                className={`flex items-center justify-between cursor-pointer mb-4 p-4 rounded-lg ${
                  selected ? styles.addonCardSelected : styles.addonCard
                } `}
                onClick={() => {
                  if (selected) {
                    setselectedAddons(
                      _.filter(
                        selectedAddons,
                        (item) => !_.isEqual(item, addon)
                      )
                    );
                  } else {
                    setselectedAddons([...selectedAddons, addon]);
                  }
                }}
                style={{ background: "#F8F8F8" }}
              >
                <div className="flex flex-col">
                  <div>
                    {getAddOnIcon(addon.name, selected)}
                    <Text className="text-base capitalize ml-2" strong>
                      {addon.name}
                    </Text>
                  </div>
                  <div>
                    <Text style={{ color: "#475467" }}>
                      {getAddonDescription(addon?.name)}
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col ml-2 w-1/3 items-end">
                  <div>
                    <Radio checked={selected} />
                  </div>
                  <div>
                    {isAnnualPlan && (
                      <Text className="mr-1" delete>
                        {" "}
                        ${getAddonCost(addon, true)}{" "}
                      </Text>
                    )}
                    <Text className="text-lg " strong>
                      ${getAddonCost(addon, false)}
                    </Text>
                    <Text>/month</Text>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </Modal>
  );
};

export default SelectAddon;
