import { SettingOutlined } from "@ant-design/icons";
import { Button, Drawer, Typography } from "antd";
import React, { useState } from "react";
import cx from "classnames";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import Form from "./Form";
import "../index.css";
import DeleteWorkspaceModal from "components/Modals/DeleteWorkspace";

const Settings = ({ invoice, isOwner, isManager }) => {
  const [isVisible, setisVisible] = useState(false);
  const form = React.useRef();
  const [workspaceDrawer, setworkspaceDrawer] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const query = { id };
  const settingClasses = cx("w-full flex  p-2 text-base justify-end", {
    "justify-between":
      invoice === "createinvoice" ||
      invoice === "history" ||
      invoice === "editinvoice",
  });

  const onSubmit = () => form.current.submitForm();

  return (
    <div className={settingClasses}>
      <div
        className="flex cursor-pointer"
        onClick={() => setworkspaceDrawer(true)}
      >
        <Typography.Text style={{ color: "#3C2E94" }}>Settings</Typography.Text>
        <SettingOutlined className="text-2xl mx-4" />
      </div>

      <Drawer
        footer={
          <div
            style={{ height: "100%", alignItems: "flex-end" }}
            className="flex justify-center pb-5 "
          >
            <Button
              size="large"
              className="mr-6 px-8"
              style={{ border: "1px solid #F5A623", color: "#F5A623" }}
              onClick={() => setworkspaceDrawer(false)}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" size="large">
              Save Changes
            </Button>
          </div>
        }
        footerStyle={{ border: "none" }}
        title={"Workspace Setting"}
        placement="right"
        onClose={() => setworkspaceDrawer(false)}
        visible={workspaceDrawer}
        width="500px"
        bodyStyle={{ padding: 0 }}
      >
        {workspaceDrawer && (
          <Form
            isManager={isManager}
            isOwner={isOwner}
            workspace={id}
            formRef={form}
            onSuccess={() => setworkspaceDrawer(false)}
          />
        )}
      </Drawer>
      <DeleteWorkspaceModal
        workspaceId={id}
        isVisible={isVisible}
        setisVisible={setisVisible}
      />
    </div>
  );
};

export default Settings;
