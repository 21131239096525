import axios from "axios";
import Cookies from "js-cookie";

export const getDuplicateTasks = async (new_task, workspace) => {
  const FAST_API_URL =
    process.env.REACT_APP_BE === "be-dev.windu.io"
      ? process.env.REACT_APP_FAST_API_DEV
      : process.env.REACT_APP_FAST_API;

  const token = Cookies.get("token");
  let data = JSON.stringify({
    title: new_task?.title,
    description: new_task?.description,
    workspace: workspace,
    tags: new_task?.tags ? new_task?.tags : [],
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: FAST_API_URL + "/findduplicates",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
