import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import moment_tz from "moment-timezone";
import { isEmpty, map, filter, cloneDeep, truncate, includes } from "lodash";
import { Typography, Menu, Card as AntdCard, Dropdown } from "antd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import AndIcon, {
  EllipsisOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { GET_RECENT_ACTIVITY } from "graphql/queries/Activity/getRecentActivity";
import icons from "common/icons";
import styles from "../styles.module.css";
import { userSession } from "recoil/atoms/User/UserSession";
import UserAvatar from "components/UserAvatar";
import moment from "moment-timezone";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import EmptyData from "components/EmptyData";
import DragHandle from "../DragHandle";
import useOpenActivityDrawer from "common/openActivityDrawer";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";

const { Title, Text, Paragraph } = Typography;

const RecentActivity = () => {
  const [recentActivities, setRecentActivities] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const { openActivityDrawer } = useOpenActivityDrawer();
  const [selected, setSelected] = React.useState("day");
  const [status, setStatus] = React.useState(0);
  const user = useRecoilValue(userSession);
  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);
  const { timezone } = user;
  const widgetContainerDivRef = useRef(null);

  const { loading, data, refetch } = useQuery(GET_RECENT_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getRecentActivity }) => {
      setRecentActivities(getRecentActivity);
      setFiltered(getRecentActivity);
    },
  });

  const handleRecentParam = (i) => {
    if (isNaN(Number(i.key))) {
      setSelected(i.key);
    } else {
      filterActivityStatus(i.key);
    }
  };

  useEffect(() => {
    if (!(recentActivities["day"]?.length > 0)) {
      if (!(recentActivities["week"]?.length > 0)) {
        setSelected("month");
      } else {
        setSelected("week");
      }
    } else {
      setSelected("day");
    }
  }, [recentActivities]);

  const handleScrollTop = () => {
    console.log(widgetContainerDivRef.current.scrollTop);
    widgetContainerDivRef.current.scrollTop = 0;
  };

  useEffect(() => {
    if (stepIndex.step == 11) {
      handleScrollTop();
    }
  }, [stepIndex]);

  const filterActivityStatus = (status) => {
    const copy = cloneDeep(recentActivities);
    setStatus(status);

    if (Number(status) === 0) {
      setFiltered(copy);
    } else {
      const result = filter(
        copy[selected],
        (element) => element.status === Number(status)
      );

      copy[selected] = result;
      setFiltered(copy);
    }
  };

  const handleActivityDrawer = (activity) => {
    if (includes(activity._id, "-")) {
      const activityId = activity._id.split("-")[0];
      const activityData = {
        ...activity,
        _id: activityId,
      };
      openActivityDrawer(activityData, true);
    } else {
      openActivityDrawer(activity, true);
    }
    if (stepIndex?.step == 11) {
      setStepIndex({ step: stepIndex.step + 1, isAcitve: true });
    }
  };

  const menu = (
    <Menu
      onClick={handleRecentParam}
      className="w-32"
      selectedKeys={[selected, String(status)]}
    >
      <Menu.Item key="day">Today</Menu.Item>
      <Menu.Item key="week">Last 7 Days</Menu.Item>
      <Menu.Item key="month">Last 31 Days</Menu.Item>
      <Menu.Divider />
      <Menu.Item key={0}>All</Menu.Item>
      <Menu.Item key={3}>Finished</Menu.Item>
      <Menu.Item key={2}>Paused</Menu.Item>
    </Menu>
  );

  const getFilterName = () => {
    switch (selected) {
      case "day":
        return <Text>Today</Text>;
      case "week":
        return <Text>Last 7 days</Text>;

      case "month":
        return <Text>Last 31 days</Text>;

      default:
        break;
    }
  };

  const getStatusIcon = ({ status }) => {
    switch (status) {
      case 2:
        return (
          <div>
            <AndIcon className="icon" component={icons["PauseColor"]} />
          </div>
        );
      case 3:
        return (
          <div>
            <AndIcon className="icon" component={icons["CheckMark"]} />
          </div>
        );
      case 6:
        return (
          <div>
            <AndIcon className="icon" component={icons["CheckMark"]} />
          </div>
        );
      case 4:
        return (
          <div>
            <UsergroupAddOutlined
              style={{ color: "#E9A13B", fontSize: 30 }}
              className="mr-2"
            />
          </div>
        );
      case 5:
        return (
          <div>
            <UsergroupAddOutlined
              style={{ color: "#E9A13B", fontSize: 30 }}
              className="mr-2"
            />
          </div>
        );
      default:
        break;
    }
  };

  const getActivityTime = ({ time }) => {
    if (moment().diff(moment(time), "h") <= 23)
      return (
        <Text className="mt-0.5 text-sm">
          {moment_tz(time).tz(timezone).fromNow()}
        </Text>
      );
    else
      return (
        <Text className="mt-0.5 text-sm">
          {moment_tz(time).tz(timezone).format("MM/DD/YY h:mm:ss a")}
        </Text>
      );
  };

  const style = {
    marginLeft: 12,
  };

  const ActivityCard = ({ activity, index }) => {
    return (
      <AntdCard
        key={activity?._id}
        className={`mb-3 shadow-none rounded-lg cursor-pointer`}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 18,
        }}
        bordered={false}
        onClick={() => handleActivityDrawer(activity)}
      >
        <UserAvatar
          user={activity.created_by}
          size="large"
          style={{ width: 52, height: 52 }}
        />
        <div className="flex w-3/5 flex-col ml-2">
          <Title level={5}>{`${truncate(activity?.title, {
            length: 80,
          })}`}</Title>
          {getActivityTime({ time: activity?.time?.end })}
        </div>
        <div className="flex items-center w-1/6 justify-end">
          {getStatusIcon({ status: activity.status })}
          {getIntegrationLogo(activity?.activity_type, style)}
        </div>
      </AntdCard>
    );
  };

  return (
    <>
      <DragHandle />
      <AntdCard
        style={{ background: "none" }}
        menu={menu}
        loading={loading}
        ref={widgetContainerDivRef}
        bodyStyle={{ padding: 0, height: "100%" }}
        className="shadow-none  h-full border-none outline-none tour-recentwidget"
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Title level={4}>Recent Activity</Title>
            </div>

            {getFilterName()}
          </div>
          <div>
            <Dropdown overlay={menu} trigger={["click"]} arrow>
              <EllipsisOutlined
                style={{ fontSize: 35 }}
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
        </div>
        {isEmpty(data?.getRecentActivity[selected]) && <EmptyData />}
        <div className={`${styles.recentWidget}`}>
          {map(filtered[selected], (activity, index) => {
            return <ActivityCard index={index} activity={activity} />;
          })}
        </div>
      </AntdCard>
    </>
  );
};

export default RecentActivity;
