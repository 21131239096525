import * as yup from "yup";

export const validationSchema = yup.object().shape({
  current: yup.string().trim().required("This field is required"),
  password: yup
    .string()
    .required("This field is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

export default validationSchema;
