import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { map, find, filter, last, isNil, isEmpty } from "lodash";
import cx from "classnames";
import { Typography, Avatar, Popconfirm, Tooltip } from "antd";
import Icons from "common/icons";
import Icon from "@ant-design/icons";
import moment from "moment";
import utility from "common/utility";

import styles from "./styles.module.css";

const { Text, Link } = Typography;

const JiraTicket = ({
  data,
  onRejected,
  user,
  activity,
  onCreate,
  onPause,
  onStop,
  serverUrl,
  isInPaused,
  handleContinueActivity,
  stopContinueActivity,
}) => {
  const { values } = useFormikContext();

  const assignnee = data?.fields?.assignee;
  const issueType = data?.fields?.issuetype;
  const priority = data?.fields?.priority;
  const summary = data?.fields?.summary;
  const description = map(
    data?.fields?.description?.content[0]?.content,
    (e) => e?.text
  )?.join();
  const status = data?.fields?.status;

  const findSync = find(
    values?.settings?.sync,
    (sync) => sync?.jira_project === data?.fields?.project?.id
  );

  const handlePlay = () => {
    if (activity?.active)
      return utility.setNotification(
        "Error",
        "Looks like you have another activity active",
        "error",
        "topRight"
      );

    const input = {
      created_by: user._id,
      title: summary,
      description,
      projectId: findSync?.windu_project,
      date_start: moment.utc(new Date()),
      jira_ticket: data?.key,
    };

    onCreate(input, data);
  };
  return (
    <div className={styles.jiraTicketWrapper}>
      <div className={styles.jiraTicketBody}>
        <div className={styles.verticalStick} />

        <div style={{ width: "100%" }} className={"px-3"}>
          <Link target="_blank" href={`${serverUrl}/browse/${data?.key}`}>
            <Text className={styles.ticketTitle}>{description} </Text>
            <Text className={styles.ticketDescription + " py-2"}>
              {summary}
            </Text>
          </Link>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img src={issueType?.iconUrl} />
              <img className="ml-2" src={priority?.iconUrl} />
            </div>
            <div className={styles.ticketOwnership}>
              <Text className={"px-2"} style={{ color: "#546178" }}>
                {data?.key}
              </Text>
              <Avatar src={assignnee?.avatarUrls["32x32"]} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.jiraTicketFooter}>
        <div style={{ width: "90%" }}>
          <Text>{status?.name}</Text>
        </div>
        <div>
          {!isEmpty(isInPaused) ? (
            <>
              <div>
                <div class="mr-5 sm:mr-2 flex justify-beetwen items-center">
                  <Tooltip placement="top" title="Continue this task">
                    <Popconfirm
                      placement="bottom"
                      title="Click yes to continue this activity"
                      onConfirm={() => handleContinueActivity(isInPaused[0])}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon
                        component={Icons.Continue}
                        className="icon-medium mr-4"
                      />
                    </Popconfirm>
                  </Tooltip>
                  <Tooltip placement="top" title="Finish this task">
                    <Popconfirm
                      placement="bottom"
                      title="Click yes to mark finished this activity"
                      onConfirm={() => stopContinueActivity(isInPaused, data)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon
                        component={Icons.CheckMark}
                        className="icon-medium mr-4"
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </div>
            </>
          ) : (
            <>
              {activity?.active && activity?.data?.meta === data?.key ? (
                <div
                  onClick={onPause}
                  className={cx(
                    styles.timer,
                    styles.timerPause,
                    "cursor-pointer"
                  )}
                >
                  <Icon
                    className={cx(styles.icon, "mt-2")}
                    component={Icons["Pause"]}
                  />
                </div>
              ) : (
                <div
                  onClick={handlePlay}
                  className={cx(
                    styles.timer,
                    styles.timerOff,
                    "cursor-pointer mr-2"
                  )}
                >
                  <Icon className={styles.icon} component={Icons["Play"]} />
                </div>
              )}
            </>
          )}
        </div>

        {activity?.active && activity?.data?.meta === data?.key && (
          <div
            onClick={() => onStop(data)}
            className={cx(styles.timer, styles.timerOn, "cursor-pointer ml-2 ")}
          >
            <Icon className={styles.icon} component={Icons["Stop"]} />
          </div>
        )}
      </div>
    </div>
  );
};

export default JiraTicket;
