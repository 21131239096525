import React from "react";
import moment_tz from "moment-timezone";
import { TIMELINE_CELL_WIDTH, TIMELINE_MINUTE } from "common/constants";
import Integration from "./Integration";

const ActivityBar = ({ start, end, active, column, timezone, type }) => {
  const isStartOfBar = column === moment_tz(start).tz(timezone).format("HH");
  const isEndOfBar = column === moment_tz(end).tz(timezone).format("HH");
  const startOffset = Number(
    moment_tz(start).tz(timezone).format("mm") * TIMELINE_MINUTE
  );

  const calculateMinus = (x, y) => {
    let a = Math.max(x, y);
    let b = Math.min(x, y);
    const totalMinutes = a - b;
    const minutesInPerc = totalMinutes * 100;

    return minutesInPerc / 60;
  };

  const calculateOffset = () => {
    if (isStartOfBar) return startOffset;

    return 0;
  };

  const calcWidth = (end, start) => {
    if (
      moment_tz(end).tz(timezone).format("HH") ===
      moment_tz(start).tz(timezone).format("HH")
    ) {
      return calculateMinus(
        moment_tz(end).tz(timezone).format("mm"),
        moment_tz(start).tz(timezone).format("mm")
      );
    } else if (isStartOfBar) {
      return calculateMinus(60, moment_tz(start).tz(timezone).format("mm"));
    } else if (isEndOfBar) {
      return calculateMinus(moment_tz(end).tz(timezone).format("mm"), 0);
    }

    return TIMELINE_CELL_WIDTH;
  };

  const width = calcWidth(end, start);

  return (
    <>
      <div
        style={{
          zIndex: 1,
          background: active ? "#4E35C2" : "#F5A623",
          color: "white",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${width}%`,
          marginLeft: calculateOffset(),
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      ></div>
    </>
  );
};
export default ActivityBar;
