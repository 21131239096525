import React, { useState, useEffect } from "react";
import Icon, { UsergroupAddOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import {
  useMutation,
  useLazyQuery,
  useSubscription,
  useApolloClient,
} from "@apollo/client";
import utility from "common/utility";
import { has, get, head, last } from "lodash";
import moment from "moment";
import cx from "classnames";
import { useRecoilState } from "recoil";
import Icons from "common/icons";
import { START_ACTIVITY } from "graphql/subscription/activeUsers/startActivity";
import { STOP_ACTIVITY as STOP_ACTIVITY_SUB } from "graphql/subscription/activeUsers/stopActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { userSession } from "recoil/atoms/User/UserSession";
import styles from "components/Timer/styles.module.css";
import Loader from "components/Loader";
import { useTimer } from "contexts/timer-context";
import useStopActivityHook from "common/useStopActivityHook";
import TimeCounter from "components/TimeCounter";
import ActivityHandoffModal from "components/Modals/ActivityHandoff";
import { HANDOFF_ACTIVITY } from "graphql/mutations/Activity/handoffActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";

const Timer = ({ time, renderHandoff, onExpand }) => {
  const client = useApolloClient();
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [_, setRefetchIndicator] = useRecoilState(refetchIndicator);
  const [user] = useRecoilState(userSession);
  const { resetHandler, startHandler } = useTimer(); // global timer from context
  const { handleStopTimer, isLoading } = useStopActivityHook();
  const [handoffModal, setHandoffModal] = useState(false);

  const handlingStartActivity = (activity) => {
    if (has(activity, "time.start")) {
      if (head(activity.time?.paused)?.continue !== undefined) {
        const continueAt = last(activity?.time.paused)?.continue;

        const start = moment(new Date(continueAt), "MM/DD/YY HH:mm:ss").format(
          "MM/DD/YY HH:mm:ss"
        );

        startHandler(start);
      } else {
        const start = activity?.time?.start;
        startHandler(start);
      }
      setActivity({
        active: true,
        data: activity,
      });
    }
  };

  const [getCurrentActivity, { loading, refetch }] = useLazyQuery(
    GET_CURRENT_ACTIVITY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getCurrentActivity }) =>
        handlingStartActivity(getCurrentActivity),
    }
  );

  const { data: startActivityData, error: startActivityError } =
    useSubscription(START_ACTIVITY);

  const { data: stopActivityData, error: stopActivityError } =
    useSubscription(STOP_ACTIVITY_SUB);

  useEffect(() => {
    if (startActivityError) console.warn(startActivityError);
    if (startActivityData) {
      const { startActivity } = startActivityData;
      const { created_by } = startActivity;

      if (created_by._id === user._id && !activity.active) {
        handlingStartActivity(startActivity);
        setActivity({
          active: true,
          data: startActivity,
        });
      }

      handleRefetchingViews();
    }
  }, [startActivityError, startActivityData]);

  useEffect(() => {
    if (stopActivityError) console.warn(stopActivityError);
    if (stopActivityData) {
      const { stopActivity } = stopActivityData;
      const { created_by, _id } = stopActivity;
      if (
        created_by._id === user._id &&
        activity.active &&
        _id === activity.data._id
      ) {
        resetHandler();
        setActivity({ data: null, active: false });
      }

      handleRefetchingViews();
    }
  }, [stopActivityError, stopActivityData]);

  useEffect(() => {
    getCurrentActivity();
  }, []);

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true, timeline: true };
    });
  };

  const [sendHandoffReq] = useMutation(HANDOFF_ACTIVITY, {
    onCompleted: () => {
      setHandoffModal(false);
    },
    onError: () => {},
  });

  const [pauseActivity, { loading: pausing }] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: async () => {
      resetHandler();

      await client.refetchQueries({
        include: [GET_PAUSED_ACTIVITY],
      });
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_TODAY_WIDGET_DATA],
  });

  const handleStopTimerFunction = () => {
    handleStopTimer({ activity: activity?.data, endTime: moment.utc() });
  };

  const handlePause = () => {
    const timeNow = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time: timeNow } },
    });
  };

  const handleHandOff = () => {
    onExpand(true);
    window.parent.postMessage(
      {
        pluginMessage: { expand: true, activity: { active: true } },
        pluginId: "*",
      },
      "*"
    );
    setHandoffModal(true);
  };

  const onSelectedHandOff = ({ selectedMember }) => {
    const timeNow = moment.utc();

    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time: timeNow } },
    }).then(() => {
      sendHandoffReq({
        variables: {
          input: {
            userId: selectedMember,
            activityId: activity?.data?._id,
            handoffTime: moment().format(),
          },
        },
      });
    });
  };
  const handleCancelModal = (v) => {
    setHandoffModal(v);
  };

  const { active } = activity;

  if (loading || pausing || isLoading)
    return (
      <div style={{ height: "65px" }} className="relative">
        <Loader />
      </div>
    );

  return (
    <>
      {active && (
        <div className="flex items-center">
          <Button
            style={{ height: 50, width: 50, borderRadius: 0 }}
            className={cx(styles.timer, styles.timerPause, "pt-3")}
            icon={<Icon className={styles.icon} component={Icons["Pause"]} />}
            onClick={() => handlePause()}
          />

          <Button
            style={{
              height: 50,
              width: 50,
              borderRadius: 0,
              overflow: "hidden",
            }}
            className={cx(styles.timer, styles.timerOn, "tutorial-timer pt-1")}
            icon={<Icon className={styles.icon} component={Icons["Stop"]} />}
            onClick={() => handleStopTimerFunction()}
          />
          {renderHandoff && (
            <Tooltip placement="right" title="Handoff">
              <Button
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 0,
                  overflow: "hidden",
                  background: "#3C2E94",
                  border: "#3C2E94",
                }}
                className={cx("tutorial-timer pt-1")}
                icon={
                  <UsergroupAddOutlined
                    style={{ fontSize: 20, color: "white" }}
                  />
                }
                onClick={handleHandOff}
              />
            </Tooltip>
          )}
        </div>
      )}
      <TimeCounter time={time} />
      {handoffModal && (
        <ActivityHandoffModal
          isVisible={handoffModal}
          setHandoffModal={handleCancelModal}
          selectedHandoffActivity={activity?.data}
          callback={handlePause}
          onSelection={onSelectedHandOff}
        />
      )}
    </>
  );
};

Timer.propTypes = {};

export default Timer;
