import React, { useState } from "react";
import { GET_CALENDAR_PROJECTS } from "graphql/queries/Google/getCalendarProjects";
import { GET_PUSHED_CALENDARS_EVENTS } from "graphql/queries/Google/getPushedCalendars";
import {
  compact,
  get,
  map,
  flatMap,
  find,
  sortBy,
  uniqBy,
  flatten,
  filter,
  includes,
  pickBy,
  groupBy,
  has,
  isEmpty,
  transform,
} from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { listCompletedCalendarEvents } from "api/Google";
import { useRecoilValue } from "recoil";
import {
  Typography,
  Collapse,
  Tag,
  Divider,
  List,
  Skeleton,
  Avatar,
  Button,
  Input,
} from "antd";
import { ClockCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import TimeField from "react-simple-timefield";
import utility from "common/utility";
import Loader from "components/Loader";
import moment from "moment";
import { GET_USERS_BY_EMAILS } from "graphql/queries/user/getUsersByEmails";
import { PUSH_GOOGLE_EVENT_ACTIVITY } from "graphql/mutations/Activity/createActivityFromGoogleEvent";
import { userSession } from "recoil/atoms/User/UserSession";
import UserAvatar from "components/UserAvatar";
import EmptyData from "../../EmptyData";
import { returnEvents, getHeader } from "./utils";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import styles from "./styles.module.css";
import { PUSH_ABSENT_MEETING } from "graphql/mutations/Google/pushAbsentMeeting";
import { GET_ABSENT_MEETINGS } from "graphql/queries/Google/getAbsentMeetings";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const Completed = ({ token, onError, id }) => {
  const [collapse, setCollapse] = useState(["google", "zoom"]);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [pushedEvents, setPushedEvents] = useState([]);
  const [absentMeetings, setabsentMeetings] = useState([]);
  const [editing, setEditing] = useState(null);
  const [users, setUsers] = useState([]);
  const user = useRecoilValue(userSession);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const completedEventIds = map(pushedEvents, "calendar");
  const rejectedEventsIds = map(absentMeetings, "calendar");

  const [getUsersByEmails, { loading: loadingUsersByEmails }] = useLazyQuery(
    GET_USERS_BY_EMAILS,
    {
      onCompleted: ({ getUsersByEmails }) => {
        setUsers(getUsersByEmails);
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const {
    getPushedGoogleCalendarsEvents,
    loading: getPushedGoogleEventsLoading,
    refetch,
  } = useQuery(GET_PUSHED_CALENDARS_EVENTS, {
    variables: { input: { workspace: id } },
    onCompleted: ({ getPushedGoogleCalendarsEvents }) => {
      setPushedEvents(
        compact(getPushedGoogleCalendarsEvents?.pushed_calendars)
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    notifyOnNetworkStatusChange: true,
  });

  const { loading: getAbsentMeetingLoading, refetch: absentMeetingRefetch } =
    useQuery(GET_ABSENT_MEETINGS, {
      variables: { input: { workspace: id } },
      onCompleted: ({ getAbsentMeetings }) => {
        setabsentMeetings(getAbsentMeetings);
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      notifyOnNetworkStatusChange: true,
    });

  const { getCalendarProjects, loading: loadingCalendarProjects } = useQuery(
    GET_CALENDAR_PROJECTS,
    {
      variables: { input: { workspace: id } },
      onCompleted: ({ getCalendarProjects }) => {
        const calendarIds = map(
          getCalendarProjects?.calendar_project_subscription,
          (e) => {
            return e?.calendar;
          }
        );
        setEventsLoading(true);

        Promise.all(
          map(calendarIds, (cal) => {
            return listCompletedCalendarEvents(cal, token)
              .then((response) => {
                const data = response.data.items;
                const filterMeetings = returnEvents({
                  data,
                  getCalendarProjects,
                  cal,
                });

                return filterMeetings;
              })
              .catch((error) => {
                const errorMessage = get(error, "message", "Internal Error");

                utility.setNotification(
                  "Something wrong happened",
                  errorMessage,
                  "error",
                  "topRight"
                );
                setEvents([]);
                onError();
              });
          })
        ).then((values) => {
          const sortFlattenedDates = sortBy(flatten(values), (e) => {
            return new Date(e?.start?.dateTime);
          });
          const groupedEvents = groupBy(sortFlattenedDates, (event) => {
            const hasZoomLink = event?.location
              ?.toLowerCase()
              ?.includes("zoom");
            const hasHangoutLink = has(event, "hangoutLink");
            const hasMicrosoftTeams = event?.location
              ?.toLowerCase()
              ?.includes("microsoft");

            if (hasZoomLink) return "zoom";
            if (hasHangoutLink) return "google";
            if (hasMicrosoftTeams) return "microsoft";
          });

          const nullCheck = pickBy(groupedEvents, (value) => {
            return !value.includes(undefined);
          });

          const eventTypes = Object.keys(groupedEvents);

          const flattenAllAttendees = uniqBy(
            flatMap(eventTypes, (type) => {
              return flatMap(values[type], (events) => {
                return flatMap(events, (event) => {
                  return event.attendees;
                });
              });
            }),
            function (e) {
              return e?.email;
            }
          );

          const mapEmailsFromAttendees = map(
            compact(flattenAllAttendees),
            (e) => {
              return e?.email;
            }
          );
          getUsersByEmails({
            variables: {
              emails: mapEmailsFromAttendees,
            },
          });

          setEventsLoading(false);

          const mutateEventsForTotalTime = transform(
            nullCheck,
            (result, item, key) => {
              (result[key] || (result[key] = [])).push(
                ...item?.map((e) => {
                  const start = moment(e?.start?.dateTime);
                  const end = moment(e?.end?.dateTime);
                  const total_time = utility.calculateTimeDifference([
                    start,
                    end,
                  ]);
                  return { ...e, total_time };
                })
              );
            },
            {}
          );
          setEvents(mutateEventsForTotalTime);
        });
      },

      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const [pushGoogleActivity] = useMutation(PUSH_GOOGLE_EVENT_ACTIVITY, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [rejectMeeting, { loading: pushAbsentLoading }] = useMutation(
    PUSH_ABSENT_MEETING,
    {
      onCompleted: () => {
        absentMeetingRefetch();
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  // TO DO - make findUsersFromtAtendees into utility
  const listAvatarAttendees = (item, users) =>
    map(item?.attendees, (e) => {
      const findUser = find(users, ["email", e?.email]);

      if (findUser) {
        return (
          <UserAvatar size={20} user={findUser} className="cursor-pointer" />
        );
      }
    });

  const handlePush = (e) => {
    //TO DO -  make findUsersFromtAtendees into utility
    const findUsersFromtAtendees = compact(
      map(e?.attendees, (e) => {
        const findUser = find(users, ["email", e?.email]);
        if (findUser) {
          return findUser?.email;
        }
      })
    );

    const payload = {
      workspaceId: id,
      users: findUsersFromtAtendees,
      projectId: e?.project?._id,
      calendar_ID: e?.id,
      calendar_title: e?.summary,
      calendar_start: e?.start?.dateTime,
      calendar_end: e?.end?.dateTime,
      calendar_time: e?.total_time,
    };

    pushGoogleActivity({ variables: { input: payload } });
  };

  const handleAbsent = (e) => {
    const payload = {
      workspaceId: id,
      projectId: e?.project?._id,
      calendar_ID: e?.id,
    };

    rejectMeeting({ variables: { input: payload } });
  };

  const handleChangeItemTime = (event, item) => {
    const value = event.target.value;
    const total_time = value.split("_").join("0");

    const startDate = item.start.dateTime;
    const endDate = utility.addTimeToDate(startDate, total_time);

    setEvents((prevState) => {
      // Object.assign would also work
      const found = find(prevState[item?.eventType], ["id", item?.id]);
      const updatedItem = {
        ...found,
        total_time,
        end: {
          ...found.end,
          dateTime: endDate,
        },
      };

      return {
        ...prevState,
        [item?.eventType]: map(prevState[item?.eventType], (e) => {
          if (e?.id === updatedItem?.id) {
            return updatedItem;
          }
          return e;
        }),
      };
    });

    setEditing(null);
  };

  const eventTypes = Object.keys(events);
  if (isEmpty(eventTypes)) return <EmptyData />;
  return (
    <Collapse
      expandIconPosition="right"
      defaultActiveKey={["google", "zoom"]}
      ///  onChange={(key) => setCollapse([...collapse, key])}
      bordered={false}
      ghost={true}
      className={styles.collapse}
    >
      {eventTypes?.map((eventType) => {
        return (
          events[eventType] && (
            <>
              <Panel
                showArrow={false}
                header={
                  <div
                    onClick={() =>
                      setCollapse((collapsedAlready) => {
                        if (collapsedAlready.includes(eventType)) {
                          const stillActive = filter(
                            collapsedAlready,
                            (e) => e !== eventType
                          );
                          return stillActive;
                        }

                        return [...collapsedAlready, eventType];
                      })
                    }
                    style={{
                      background: "#F7F7FC",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    className="p-5"
                  >
                    <div className="flex">
                      {getHeader(eventType)}
                      <Tag className="ml-3" color="purple">
                        {events[eventType]?.length}
                      </Tag>
                    </div>
                    {collapse.includes(eventType) ? (
                      <CaretUpOutlined style={{ color: "#7433FF" }} />
                    ) : (
                      <CaretDownOutlined style={{ color: "#7433FF" }} />
                    )}
                  </div>
                }
                key={eventType}
              >
                <List
                  className="demo-loadmore-list"
                  loading={{ spinning: eventsLoading, indicator: <Loader /> }}
                  itemLayout="horizontal"
                  dataSource={compact(events[eventType])}
                  renderItem={(item) => {
                    const hasPermissionToPush = includes(
                      [
                        item?.project?.created_by?._id,
                        item?.project?.manager_id?._id,
                      ],
                      user._id
                    );
                    const alreadyPushed = includes(completedEventIds, item?.id);
                    const absentMeetings = includes(
                      rejectedEventsIds,
                      item?.id
                    );
                    const isDisabled = alreadyPushed || absentMeetings;
                    return (
                      <List.Item>
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            avatar={
                              <>
                                <Text style={{ color: "#967DDE" }}>
                                  {moment(item.start.dateTime).format("MMMM")}
                                </Text>
                                <div>
                                  {moment(item.start.dateTime).format("DD")}
                                </div>
                              </>
                            }
                            title={
                              <Title
                                delete={isDisabled}
                                mark={isDisabled}
                                level={5}
                              >
                                {item.summary}
                              </Title>
                            }
                            description={
                              <Text delete={isDisabled} mark={isDisabled}>
                                {item?.project?.title}
                              </Text>
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            {
                              <Avatar.Group maxCount={3} className="pr-3">
                                {listAvatarAttendees(item, users)}
                              </Avatar.Group>
                            }
                            {!isDisabled && editing !== item.id && (
                              <Text
                                onClick={() => setEditing(item.id)}
                                style={{ color: "#008F50", paddingRight: 10 }}
                                className="cursor-pointer"
                              >
                                {item?.total_time}
                              </Text>
                            )}
                            {editing === item.id && (
                              <div className="w-1/4">
                                <TimeField
                                  value={item.total_time}
                                  colon=":"
                                  showSeconds
                                  input={
                                    <Input
                                      ref={inputRef}
                                      value={item.total_time}
                                      onPressEnter={(e) => {
                                        e.preventDefault();
                                        handleChangeItemTime(e, {
                                          ...item,
                                          eventType,
                                        });
                                      }}
                                      onBlur={(e) => {
                                        e.preventDefault();
                                        handleChangeItemTime(e, {
                                          ...item,
                                          eventType,
                                        });
                                      }}
                                    />
                                  }
                                />
                              </div>
                            )}
                            {!isDisabled && (
                              <>
                                <Button
                                  style={{ minWidth: 100 }}
                                  onClick={() => handlePush({ ...item })}
                                  type="secondary"
                                  icon={<ClockCircleOutlined />}
                                >
                                  Attended
                                </Button>
                                <Button
                                  icon={<CloseCircleOutlined />}
                                  className="ml-2"
                                  onClick={() => handleAbsent({ ...item })}
                                >
                                  Absent
                                </Button>
                              </>
                            )}
                          </div>
                        </Skeleton>
                      </List.Item>
                    );
                  }}
                />
              </Panel>
              <Divider />
            </>
          )
        );
      })}
    </Collapse>
  );
};

export default Completed;
