import gql from "graphql-tag";

export const UPDATE_MESSAGE = gql`
  mutation ($input: updateMessageInput) {
    updateMessage(input: $input) {
      _id
      content

      to
      from {
        email
        avatar
      }
      chatId
      created_at
    }
  }
`;
