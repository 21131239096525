import React, { useState, useEffect, useRef } from "react";
import styled from "./styles.module.css";

const CreditCardInput = ({ setData, error }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleContainerFocus = () => {
    setIsFocused(true);
  };

  const handleContainerBlur = () => {
    setIsFocused(false);
  };
  const [card, setCard] = useState({
    number: "",
    cvv: "",
    exp: "",
  });

  useEffect(() => {
    setData(card);
  }, [card]);

  const inputCard = useRef();
  const cardExp = useRef();
  const cvvRef = useRef();

  const handleChange = () => {
    if (inputCard.current.value?.length === 19) {
      cardExp.current.focus();
    }
    const cardValue = inputCard.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `${cardValue[1]}-${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, "");

    setCard({
      ...card,
      number: numbers,
    });
  };

  const handleExpChange = () => {
    if (cardExp.current.value?.length === 5) {
      cvvRef.current.focus();
    }
    const cardValue = cardExp.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,2})/);

    cardExp.current.value = !cardValue[2]
      ? cardValue[1]
      : `${cardValue[1]}/${cardValue[2]}`;
    const numbers = cardExp.current.value.replace(/(\D)/g, "");

    setCard({
      ...card,
      exp: numbers,
    });
  };

  return (
    <div
      className={`${styled.inputContainer} ${isFocused ? styled.focused : ""} ${
        error ? styled.ccError : ""
      } `}
      onFocus={handleContainerFocus}
      onBlur={handleContainerBlur}
    >
      <input
        className={styled.ccInput}
        type="text"
        ref={inputCard}
        onChange={handleChange}
        placeholder="XXXX-XXXX-XXXX-XXXX"
        style={{ width: "64%" }}
      />
      <input
        style={{ width: "18%" }}
        type="text"
        className={styled.ccInput}
        placeholder="MM/YY"
        onChange={handleExpChange}
        ref={cardExp}
      />
      <input
        style={{ width: "18%" }}
        type="number"
        className={styled.ccInput}
        placeholder="CVV"
        ref={cvvRef}
        onChange={(e) => {
          setCard({
            ...card,
            cvv: e.target.value,
          });
        }}
      />
    </div>
  );
};

export default CreditCardInput;
