import gql from "graphql-tag";

export const LEAVE_PROJECT = gql`
  mutation ($input: projectInput) {
    leaveProject(input: $input) {
      _id
      title
    }
  }
`;
