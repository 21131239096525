import { Button, Card, Descriptions, Typography } from "antd";
import EditProfile from "components/Modals/EditProfile";
import React, { useState } from "react";
import ChangePasswordModal from "components/Modals/ChangePassword";
import { EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import styles from "../../styles.module.css";

const { Text } = Typography;

const InfoCard = ({ user }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isChangeVisible, setIsChangeVisible] = useState(false);
  return (
    <>
      <Card
        title={
          <Text strong className="text-xl">
            Personal Information
          </Text>
        }
        extra={
          <>
            {!user.externalLogin && (
              <Button
                type="link"
                size="large"
                onClick={() => setIsChangeVisible(true)}
                className="mr-3"
              >
                Change password
              </Button>
            )}
            <Button
              type="link"
              size="large"
              onClick={() => setIsVisible(true)}
              className="mr-3"
            >
              <div
                className="flex items-center py-1 px-4 rounded-3xl"
                style={{ border: "1px solid #E0E0E0" }}
              >
                <Typography.Text style={{ color: "#3C2E94" }}>
                  Edit
                </Typography.Text>
                <EditOutlined style={{ color: "#3C2E94" }} className="ml-2" />
              </div>
            </Button>
          </>
        }
      >
        <Descriptions
          layout="vertical"
          column={{ xs: 1, sm: 1, md: 2 }}
          className={styles.descriptionCard}
        >
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Name
              </Text>
            }
          >
            <Text className="text-base">{user?.full_name}</Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Specialities
              </Text>
            }
          >
            <Text className="text-base">
              {<Text>{user?.onboarding_data?.expertise.join(", ")}</Text>}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Email
              </Text>
            }
          >
            <Text className="text-base">{user?.email}</Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Account timezone
              </Text>
            }
          >
            <Text className="text-base">{user?.timezone}</Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Phone number
              </Text>
            }
          >
            <Text className="text-base">
              {_.get(user, "phone_number.phone", "no specified")}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text className="text-base" style={{ color: "#6D6D6D" }}>
                Birthday
              </Text>
            }
          >
            <Text className="text-base">
              {user?.birthday
                ? moment(user?.birthday).format("MM-DD-YYYY")
                : "no specified"}
            </Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      {isVisible && (
        <EditProfile
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          user={user}
        />
      )}

      <ChangePasswordModal
        isVisible={isChangeVisible}
        onClose={() => setIsChangeVisible(false)}
        user={user}
      />
    </>
  );
};

export default InfoCard;
