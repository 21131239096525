import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Divider,
  Row,
  Segmented,
  Select,
  Switch,
  Typography,
} from "antd";
import Members from "./Cards/Members";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { useLazyQuery, useQuery } from "@apollo/client";
import { planInfoV2 } from "./planInfo";
import { LIST_PLANS } from "graphql/queries/plan/listPlans";
import Addons from "./Cards/Addons";
import Individual from "./Cards/Individual";
import Team from "./Cards/Team";
import _ from "lodash";
import CheckoutView from "./Cards/CheckoutView";
import WinduLogo from "common/assets/windu-filled.png";
import cc from "currency-codes";
import { getSpecificRate } from "api/ExchangeRates";
import getSymbolFromCurrency from "currency-symbol-map";
import utility from "common/utility";
import {
  ANNUAL_DISCOUNT_PERCENTAGE,
  INDIVIDUAL_PLAN,
  TEAM_PLAN,
} from "constants/plans";
import CancelSubscriptionModal from "components/Modals/CancelSubscriptionModal";
import { LIST_ADDONS } from "graphql/queries/Addons/getAddons";

const { Text } = Typography;

const currency = _.map(cc.codes(), (e) => {
  return (
    <Select.Option key={e} value={e}>
      {e}
    </Select.Option>
  );
});

const Subscriptions = ({ handleViewChange }) => {
  const [pickedCurrency, setPickedCurrency] = useState("USD");
  const [rate, setRate] = useState(1);
  const [plans, setPlans] = useState();
  const user = useRecoilValue(userSession);
  const [userCount, setUserCount] = useState(0);
  const [selectedAddons, setselectedAddons] = useState(user?.addons);
  const [selectedPlan, setSelectedPlan] = useState();
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [planPeriod, setPlanPeriod] = useState("Annual");

  const isAnnualPlan = planPeriod === "Annual";

  useEffect(() => {
    setUserCount(user?.settings?.uniqueProjectsUsers || 0);
    if (user?.plan?.period) {
      setPlanPeriod(user?.plan?.period);
    }
  }, [user]);

  const [listPlans, { loading, data: listPlansData }] = useLazyQuery(
    LIST_PLANS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: ({ listPlans }) => {
        setPlans(listPlans);
      },
    }
  );
  const { loading: loadingAddons, data: addonsData } = useQuery(LIST_ADDONS, {
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    listPlans();
  }, []);

  const getTotalAddonsCost = () => {
    const addonsCost = _.sum(_.map(selectedAddons, (item) => item.cost));
    if (isAnnualPlan) {
      const discountedAddonsCost =
        addonsCost - addonsCost * (ANNUAL_DISCOUNT_PERCENTAGE / 100);
      const totalAnnualCost = (discountedAddonsCost * rate) / 12;

      return totalAnnualCost.toFixed(2);
    } else {
      return (addonsCost * rate).toFixed(2);
    }
  };

  const handleSubscribe = (data, _, isTrial) => {
    if (
      data?.name === INDIVIDUAL_PLAN &&
      user?.extra?.uniqueProjectsParticipants.length != 0
    ) {
      utility.setNotification(
        "Something wrong happened",
        "Please remove all members before choosing the Individual plan.",
        "warning",
        "topRight"
      );
    } else {
      setSelectedPlan({ ...data, isTrial });
    }
  };

  const handleChange = async (v) => {
    const data = await getSpecificRate({ currency: v });
    setPickedCurrency(v);
    const pickedRate = data?.rates[v];
    const adjustPlans = _.map(listPlansData?.listPlans, (plan) => {
      return {
        ...plan,
        cost: (plan?.cost * pickedRate).toFixed(2),
        currency: v,
      };
    });
    setPlans(adjustPlans);
    setRate(pickedRate);
  };

  return (
    <>
      {_.isEmpty(selectedPlan) ? (
        <>
          <Row className="flex justify-between items-center">
            <Col span={14}>
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center ">
                  <img
                    src={WinduLogo}
                    alt="Windu Logo"
                    style={{ height: 50, width: 50 }}
                    className="rounded-full"
                  />
                  <div className="flex flex-col ml-3">
                    <Typography.Text className="text-lg" strong>
                      WINDU Addons
                    </Typography.Text>
                    <Typography.Text>
                      Power up your subscription
                    </Typography.Text>
                  </div>
                </div>
                <div
                  className="ml-8 flex items-center px-3 py-2 "
                  style={{ background: "#FAFAFA" }}
                >
                  <Typography.Text className="text-4xl" strong>
                    {getSymbolFromCurrency(pickedCurrency)}

                    {getTotalAddonsCost()}
                  </Typography.Text>
                  <Typography.Text className="text-base ml-2">
                    per month
                  </Typography.Text>
                </div>
              </div>
            </Col>

            <Col className="flex justify-end items-center">
              <div className="flex flex-col">
                <div className="flex items-end w-full justify-end">
                  <Text
                    className="rounded-xl px-2 "
                    style={{ background: "#00EF7C" }}
                  >
                    25% off
                  </Text>
                </div>
                <Segmented
                  options={["Monthly", "Annual"]}
                  value={planPeriod}
                  onChange={(v) => {
                    setPlanPeriod(v);

                    // replace the monthly addons to annual addons
                    const changedAddons = _.map(selectedAddons, (item) => {
                      return _.find(
                        addonsData?.getAddons,
                        (addon) =>
                          addon?.name === item?.name && addon?.period === v
                      );
                    });

                    setselectedAddons(changedAddons);
                  }}
                  size="large"
                  className="mt-1"
                />
              </div>
              <Select
                className="mt-4 ml-12"
                size="large"
                style={{ width: 80 }}
                defaultValue={pickedCurrency}
                showSearch
                onChange={handleChange}
              >
                {currency}
              </Select>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={16}>
              <Row>
                <Col span={12}>
                  <Individual
                    rate={rate}
                    currency={pickedCurrency}
                    plans={plans}
                    handleSubscribe={handleSubscribe}
                    loading={loading}
                    user={user}
                    isAnnualPlan={isAnnualPlan}
                  />
                </Col>
                <Col span={12}>
                  <Team
                    rate={rate}
                    currency={pickedCurrency}
                    plans={plans}
                    handleSubscribe={handleSubscribe}
                    loading={loading}
                    user={user}
                    setUserCount={setUserCount}
                    userCount={userCount}
                    isAnnualPlan={isAnnualPlan}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Members user={user} />
              <Addons
                selectedAddons={selectedAddons}
                setselectedAddons={setselectedAddons}
                isAnnualPlan={isAnnualPlan}
              />
              {!_.isEmpty(user?.plan?.name) && (
                <div className="flex justify-center">
                  <Button
                    type="text"
                    style={{ color: "red" }}
                    className=" text-base"
                    onClick={() => {
                      setCancelSubscriptionModal(true);
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={9}>
            {selectedPlan?.name === INDIVIDUAL_PLAN && (
              <Individual
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                selectedPlan={selectedPlan}
                isAnnualPlan={isAnnualPlan}
              />
            )}
            {selectedPlan?.name === TEAM_PLAN && (
              <Team
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                selectedPlan={selectedPlan}
                setUserCount={setUserCount}
                userCount={userCount}
                isAnnualPlan={isAnnualPlan}
              />
            )}
          </Col>

          <Col span={14} className="pl-6">
            <CheckoutView
              rate={rate}
              currency={pickedCurrency}
              selectedPlan={selectedPlan}
              selectedAddons={selectedAddons}
              setselectedAddons={setselectedAddons}
              setSelectedPlan={setSelectedPlan}
              userCount={userCount}
              handleViewChange={handleViewChange}
              isAnnualPlan={isAnnualPlan}
            />
          </Col>
        </Row>
      )}
      {cancelSubscriptionModal && (
        <CancelSubscriptionModal
          visible={cancelSubscriptionModal}
          onClose={() => setCancelSubscriptionModal(false)}
          user={user}
        />
      )}
    </>
  );
};

export default Subscriptions;
