import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FlagFilled,
  PlusOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Badge,
  Tag,
  Typography,
  Tooltip,
  Menu,
  Popconfirm,
  Dropdown,
} from "antd";
import styles from "./styles.module.css";
import utility from "common/utility";
import { UPDATE_BACKLOG_GROUP } from "graphql/mutations/Backlog/updateBacklogGroup";
import { useMutation } from "@apollo/client";
import { REMOVE_BACKLOG_GROUP } from "graphql/mutations/Backlog/removeBacklogGroup";
import { REMOVE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/removeBacklogActivity";
import { UPDATE_BACKLOG } from "graphql/mutations/Backlog/updateBacklog";
import { onDragEndTask } from "./onDragEndFun";
import { DotsThreeOutline, Lock } from "@phosphor-icons/react";
import moment from "moment";
import UserAvatar from "components/UserAvatar";
import useOpenActivityDrawer from "common/openActivityDrawer";
import { UPDATE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateBacklogActivity";
import AssigneSelectDropdown from "./AssigneSelectDropdown";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";

const { Text } = Typography;

const KanbanView = ({
  kanbanData,
  refetch,
  createActivityModal,
  setSelectedGroup,
  setcreateGroupModal,
  setEditingItem,
  filters,
}) => {
  const [kanbanKey, setkanbanKey] = useState(1);
  const isDragginAllowed = !filters?.isFiltering;
  const [internalData, setInternalData] = useState({
    columns: [],
  });
  const [subGroupExpanded, setSubGroupExpanded] = useState({});
  const [isSubGroupDragged, setIsSubGroupDragged] = useState(false);
  const [isItemDragged, setIsItemDragged] = useState(false);
  const { openActivityDrawer } = useOpenActivityDrawer();
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);
  const workspace = useRecoilValue(workspaceSelected);

  const getFilterData = (filter, item) => {
    let filterResult = true;
    const isUnassigned = _.includes(filter.assignee, "unassigned");

    if (!_.isEmpty(filter.assignee)) {
      if (isUnassigned) {
        filterResult =
          _.includes(filter.assignee, item?.assigned?._id) ||
          item?.assigned === null;
      } else {
        filterResult = _.includes(filter.assignee, item.assigned?._id);
      }
    }

    if (_.includes([0, 1, 2, 3], filter.priority)) {
      if (filter.priority === 0) {
        // If filter priority is 0, check for both 0 and null in the item
        filterResult =
          filterResult && (item?.priority === 0 || item?.priority === null);
      } else {
        // If filter priority is not 0, directly compare with item priority
        filterResult = filterResult && filter.priority === item?.priority;
      }
    }
    if (!_.isEmpty(filter.tags)) {
      filterResult =
        filterResult && !_.isEmpty(_.intersection(filter.tags, item?.tags));
    }

    if (!_.isEmpty(filter.keyword)) {
      filterResult =
        filterResult &&
        item?.title?.toLowerCase().includes(filter.keyword.toLowerCase());
    }
    return filterResult;
  };

  useEffect(() => {
    if (kanbanData?.getBacklog) {
      const backlogData = kanbanData?.getBacklog;

      const foramtForKanban = {
        columns: [
          {
            _id: "root",
            title: "Root",
            items: _.filter(
              backlogData?.items,
              (item) => item.type === "backlog_activity"
            ),
          },
          ..._.map(backlogData?.items, (item) => {
            if (item.type === "backlog_group") {
              const formatGroupData = _.map(item.items, (groupItem) => {
                if (groupItem?.type === "backlog_sub_group") {
                  return {
                    ...groupItem,
                    items: _.map(groupItem?.activities, (activity) => {
                      return {
                        ...activity,
                        type: "backlog_activity",
                      };
                    }),
                  };
                }
                return groupItem;
              });
              return {
                ...item,
                items: formatGroupData,
              };
            }
            return item;
          }).filter((item) => item.type === "backlog_group"),
        ],
      };

      const clonedForamtForKanban = _.cloneDeep(foramtForKanban);

      const filteredData = _.map(clonedForamtForKanban.columns, (column) => {
        const mappedItems = _.map(column.items, (item) => {
          if (item.type === "backlog_sub_group") {
            const subgroupsItems = _.filter(item.items, (subgroupItem) => {
              return getFilterData(filters, subgroupItem);
            });
            return {
              ...item,
              items: subgroupsItems,
            };
          } else {
            return item;
          }
        });

        const filteredRoot = _.filter(mappedItems, (item) => {
          if (item.type === "backlog_activity") {
            return getFilterData(filters, item);
          }
          return true; // or apply other filters if needed
        });

        return {
          ...column,
          items: filteredRoot,
        };
      });

      const initialState = {};
      clonedForamtForKanban.columns.forEach((column) => {
        column.items.forEach((item) => {
          if (item.type === "backlog_sub_group") {
            const isOpen = localStorage.getItem(item._id) === "true";
            initialState[item._id] = isOpen;
          }
        });
      });

      setSubGroupExpanded(initialState);

      if (filters.isFiltering) {
        setInternalData({
          columns: filteredData,
        });
      } else {
        setInternalData(clonedForamtForKanban);
      }
    }
  }, [kanbanData, filters]);

  React.useEffect(() => {
    const { manager, members, created_by } = workspace?.project;
    const allMembers = [...members, created_by];
    if (manager) {
      allMembers.push(manager);
    }
    const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");
    setFilterSearchUser(_.isEmpty(gatherMembers) ? [] : gatherMembers);
  }, [workspace]);

  const [updateBacklog] = useMutation(UPDATE_BACKLOG, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error backlog update`,
        "error",
        "topRight"
      );
    },
  });

  const [updateBacklogGroup] = useMutation(UPDATE_BACKLOG_GROUP, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error group update`,
        "error",
        "topRight"
      );
    },
  });

  const [removeBacklogActivity] = useMutation(REMOVE_BACKLOG_ACTIVITY, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        `Activity has been removed`,
        "success",
        "topRight"
      );
    },
    awaitRefetchQueries: true,
  });
  const [updateBacklogActivity] = useMutation(UPDATE_BACKLOG_ACTIVITY, {
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        `Activity updated successfully`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      utility.setNotification(
        "Error",
        `${toString(error.message)}`,
        "error",
        "topRight"
      );
    },
    awaitRefetchQueries: true,
  });

  const updateAssignee = (assignee, item) => {
    const payload = {
      activityId: item._id,
      assigned: assignee ? assignee?._id : null,
      backlogId: kanbanData?.getBacklog?._id,
      title: item?.title,
      details: item?.details,
    };

    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  const updateActivityPriority = ({ priority, item }) => {
    const payload = {
      activityId: item._id,
      priority: Number(priority),
      backlogId: kanbanData?.getBacklog?._id,
      title: item?.title,
      details: item?.details,
    };

    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  const selectPriority = (item) => {
    const currentPriority = item?.priority ? item?.priority : 0;
    return (
      <Menu
        onClick={(v) => updateActivityPriority({ priority: v.key, item })}
        selectedKeys={item?.priority}
      >
        <Menu.Item
          key={0}
          style={{ background: currentPriority === 0 && "#F5F4FA" }}
          disabled={currentPriority === 0}
        >
          <FlagFilled style={{ color: "lightgrey" }} className="icon-medium" />
          <Text className="ml-2">No Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={3}
          style={{ background: currentPriority === 3 && "#F5F4FA" }}
          disabled={currentPriority === 3}
        >
          <FlagFilled style={{ color: "red" }} className="icon-medium" />
          <Text className="ml-2">High Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={2}
          disabled={currentPriority === 2}
          style={{ background: currentPriority === 2 && "#F5F4FA" }}
        >
          <FlagFilled style={{ color: "orange" }} className="icon-medium" />
          <Text className="ml-2">Medium Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={1}
          style={{ background: currentPriority === 1 && "#F5F4FA" }}
          disabled={currentPriority === 1}
        >
          <FlagFilled style={{ color: "green" }} className="icon-medium" />
          <Text className="ml-2">Low Priority</Text>
        </Menu.Item>
      </Menu>
    );
  };

  const onActivityRemove = ({ activity, groupId }) => {
    const payload = {
      activityId: activity?._id,
      backlogId: kanbanData?.getBacklog?._id,
    };

    if (groupId !== "root") {
      // if belongs to a group or subgroup set the groupid
      payload.groupId = groupId;
    }

    removeBacklogActivity({ variables: { input: { ...payload } } });
  };

  const [removeBacklogGroup] = useMutation(REMOVE_BACKLOG_GROUP, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove group`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        `Group has been removed`,
        "success",
        "topRight"
      );
    },
  });

  const onGroupRemove = (item) => {
    const payload = {
      groupId: item._id,
      backlogId: kanbanData?.getBacklog?._id,
    };

    removeBacklogGroup({ variables: { input: { ...payload } } });
  };

  const toggleSubGroup = (subGroupId) => {
    setSubGroupExpanded((prevState) => ({
      ...prevState,
      [subGroupId]: !prevState[subGroupId],
    }));

    localStorage.setItem(subGroupId, !subGroupExpanded[subGroupId]);
  };

  const handleSetData = (newData) => {
    setInternalData(newData);
    setkanbanKey((prevState) => prevState + 1);
  };

  const handleAddActivity = (columnId) => {
    if (columnId === "root") {
      setSelectedGroup(null);
      createActivityModal(true);
    } else {
      setSelectedGroup(columnId);
      createActivityModal(true);
    }
  };
  const onBeforeCapture = (res) => {
    setIsItemDragged(true);
    const draggableId = res.draggableId;
    const isSubGroup = () => {
      return internalData.columns.some((column) =>
        column.items.some(
          (task) =>
            task._id === draggableId && task.type === "backlog_sub_group"
        )
      );
    };

    if (isSubGroup()) setIsSubGroupDragged(true);
  };

  const OptionDropdown = ({ item, groupId }) => {
    return (
      <Menu
        style={{ boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)" }}
        className="py-0"
      >
        <Menu.Item
          className="py-4 flex items-center"
          key="edit"
          icon={<EditOutlined className="icon-medium" />}
          onClick={() => {
            setEditingItem({
              data: {
                ...item,
                planned: item?.planned_date,
              },
            });
            createActivityModal(true);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          key="remove"
          className="py-4 flex items-center"
          icon={<DeleteOutlined className="icon-medium" />}
        >
          <Popconfirm
            placement="topLeft"
            title={"Are you sure to remove this activity?"}
            onConfirm={() => onActivityRemove({ activity: item, groupId })}
            okText="Yes"
            cancelText="No"
          >
            Remove Activity
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const OptionGroupDropdown = ({ item }) => {
    return (
      <Menu
        style={{ boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)" }}
        className="py-0"
      >
        <Menu.Item
          className="py-4 flex items-center"
          key="edit"
          onClick={() => {
            setEditingItem({ data: item, id: item._id });
            setcreateGroupModal(true);
          }}
          icon={<EditOutlined className="icon-medium" />}
        >
          Edit
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          key="remove"
          className="py-4 flex items-center"
          icon={<DeleteOutlined className="icon-medium" />}
        >
          <Popconfirm
            placement="topLeft"
            title={"Are you sure to remove this group?"}
            onConfirm={() => onGroupRemove(item)}
            okText="Yes"
            cancelText="No"
          >
            Remove Group
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const handleAddSubgroups = (group) => {
    setcreateGroupModal({ groupId: group?._id });
  };

  const createDropdown = (column) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => handleAddActivity(column?._id)}
          key="Activity"
        >
          Activity
        </Menu.Item>
        {column?._id !== "root" && (
          <Menu.Item onClick={() => handleAddSubgroups(column)} key="Group">
            Sub-Group
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const taskCard = (task, groupId) => {
    return (
      <div className="flex flex-col p-4 bg-white border border-gray-200 rounded-md shadow mb-3">
        <div className="flex items-center justify-between">
          <div>
            {task?.planned_date ? (
              <Text className="text-sm font-medium">
                {moment(task?.planned_date).format("MMM DD, YYYY")}
              </Text>
            ) : (
              <Text> </Text>
            )}
          </div>

          <div>
            <Dropdown
              placement="bottomLeft"
              overlay={<OptionDropdown item={task} groupId={groupId} />}
              trigger={["click"]}
            >
              <div className="cursor-pointer">
                <DotsThreeOutline
                  size={24}
                  weight="fill"
                  style={{ fill: "#848484" }}
                />
              </div>
            </Dropdown>
          </div>
        </div>

        {task?.tags && (
          <div className="flex flex-wrap mt-1">
            {task?.tags.map((tag) => (
              <Tag key={tag} color="blue" className="mb-2 mr-2">
                {tag}
              </Tag>
            ))}
          </div>
        )}
        <div
          className="flex flex-col cursor-pointer"
          onClick={() => openActivityDrawer(task, true, true)}
        >
          <Text className="text-base font-semibold mb-1">
            {_.truncate(task?.title, { length: 20 })}
          </Text>
          <Text className="text-gray-700 mb-2">
            {_.truncate(utility.getPlainText(task?.details), { length: 66 })}
          </Text>
        </div>

        <div className="flex items-center justify-between">
          <div>
            <Dropdown overlay={() => selectPriority(task)}>
              <div className="flex items-center">
                <FlagFilled
                  style={{
                    color: utility.getPriority(task?.priority).color,
                  }}
                  className="icon-medium"
                />
              </div>
            </Dropdown>
          </div>
          <div>
            <div>
              <Dropdown
                trigger={["click"]}
                overlay={() => (
                  <AssigneSelectDropdown
                    filterSearchUser={filterSearchUser}
                    setValue={(v) => {
                      if (v?._id !== task?.assigned?._id) {
                        updateAssignee(v, task);
                      }
                    }}
                    initialValue={filterSearchUser}
                    value={task?.assigned}
                  />
                )}
              >
                {task?.assigned ? (
                  <Tooltip title={task?.assigned?.email}>
                    <>
                      <UserAvatar
                        size={35}
                        user={task?.assigned}
                        className="cursor-pointer"
                      />
                    </>
                  </Tooltip>
                ) : (
                  <div className="flex items-center">
                    <UserOutlined />
                  </div>
                )}
              </Dropdown>
            </div>
            {/* {task?.assigned && (
              <Tooltip title={task?.assigned?.email}>
                <UserAvatar user={task?.assigned} />
              </Tooltip>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  const renderSubGroup = (task) => {
    return (
      <div
        style={{
          borderTop: "2px solid #B0A4EA",
          backgroundColor: "#D6D9DD",
          padding: 8,
          borderRadius: 8,
        }}
        className="my-2"
      >
        <div className="flex items-center">
          {
            <div
              className="mr-2 cursor-pointer"
              onClick={() => toggleSubGroup(task?._id)}
            >
              {subGroupExpanded[task?._id] ? (
                <DownOutlined />
              ) : (
                <RightOutlined />
              )}
            </div>
          }

          <div className="w-full">
            <div className="flex justify-between">
              <Text className="mr-2 text-base font-semibold">
                {_.truncate(task?.title, { length: 20 })}
              </Text>
              <div>
                <Dropdown
                  placement="bottomLeft"
                  overlay={<OptionGroupDropdown item={task} />}
                  trigger={["click"]}
                >
                  <div className="cursor-pointer">
                    <DotsThreeOutline
                      size={24}
                      weight="fill"
                      style={{ fill: "#848484" }}
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <div>
                {task?.start_date && task?.end_date && (
                  <Typography.Text>
                    {`${moment(task.start_date).format("MMM D")} - ${moment(
                      task.end_date
                    ).format("MMM D, YYYY")}`}
                  </Typography.Text>
                )}
              </div>
              <Badge
                count={task?.items?.length}
                style={{
                  backgroundColor: "#F0F0F0",
                  color: "rgba(0, 0, 0, 0.45)",
                  borderRadius: "50%",
                  marginLeft: "12px",
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Droppable
            droppableId={task._id}
            type="TASK"
            key={task._id}
            isDropDisabled={isSubGroupDragged}
          >
            {(provided) => (
              <div
                className="w-full"
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ minHeight: isSubGroupDragged ? 0 : 14 }}
              >
                {!isSubGroupDragged &&
                  subGroupExpanded[task._id] &&
                  task.items.map((subGroupTask, index) => (
                    <Draggable
                      key={subGroupTask._id}
                      draggableId={subGroupTask._id}
                      index={index}
                      isDragDisabled={!isDragginAllowed}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          {taskCard(subGroupTask, task?._id)}{" "}
                          {/* Pass true for sub-group items */}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  };

  const getColumnActivityCount = (column) => {
    let count = 0;
    if (column?.items) {
      column.items.forEach((task) => {
        if (task.type === "backlog_activity") {
          count++;
        }
        if (task.type === "backlog_sub_group") {
          count += getColumnActivityCount(task);
        }
      });
    }

    return count;
  };

  return (
    <div key={kanbanKey}>
      <DragDropContext
        onDragEnd={(res) => {
          onDragEndTask({
            result: res,
            incomingData: internalData,
            updateBacklog,
            updateBacklogGroup,
            handleSetData,
            backlogId: kanbanData?.getBacklog?._id,
          });
          setIsItemDragged(false);
          setIsSubGroupDragged(false);
        }}
        onBeforeCapture={onBeforeCapture}
      >
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="COLUMN"
        >
          {(provided) => (
            <div
              className={`${styles.kanbanContainer}`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {internalData.columns.map((column, columnIndex) => (
                <Draggable
                  key={column._id}
                  draggableId={column._id}
                  index={columnIndex}
                  isDragDisabled={column?._id === "root" || !isDragginAllowed}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${styles.columnWrapper}`}
                    >
                      <div
                        className="flex items-center flex-col p-3 rounded-md"
                        style={{
                          background: "#F8F8F8",
                          border: "1px solid #DDE2E4",
                        }}
                      >
                        <div className={styles.columnHeader}>
                          <div className="flex items-center">
                            <Text className="text-base">{column.title}</Text>
                            <Badge
                              count={getColumnActivityCount(column)}
                              style={{
                                backgroundColor: "#F0F0F0",
                                color: "rgba(0, 0, 0, 0.45)",
                                borderRadius: "50%",
                                marginLeft: "12px",
                              }}
                            />
                          </div>
                          <div>
                            {column?._id === "root" ? (
                              <Tooltip title="Column's position is fixed and groups cannot be moved into it.">
                                <Lock
                                  size={22}
                                  weight="fill"
                                  style={{ fill: "#848484" }}
                                />
                              </Tooltip>
                            ) : (
                              <Dropdown
                                placement="bottomLeft"
                                overlay={<OptionGroupDropdown item={column} />}
                                trigger={["click"]}
                              >
                                <div className="cursor-pointer">
                                  <DotsThreeOutline
                                    size={28}
                                    weight="fill"
                                    style={{ fill: "#848484" }}
                                  />
                                </div>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                        <div className={styles.columnItemContainer}>
                          <Droppable droppableId={column._id} type="TASK">
                            {(provided) => (
                              <div
                                style={{ height: "70vh" }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <div className={styles.addNewItemButton}>
                                  <Dropdown
                                    overlay={() => createDropdown(column)}
                                    placement="bottomRight"
                                  >
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      className="w-full bg-transparent"
                                    >
                                      Add
                                    </Button>
                                  </Dropdown>
                                </div>
                                {column.items.map((task, index) => (
                                  <Draggable
                                    key={task._id}
                                    draggableId={task._id}
                                    index={index}
                                    isDragDisabled={!isDragginAllowed}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        {task.type === "backlog_sub_group"
                                          ? renderSubGroup(task)
                                          : taskCard(task, column?._id)}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </div>
                      {columnIndex !== internalData.columns.length - 1 && (
                        <div style={{ width: "16px" }}></div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              <>
                {isSubGroupDragged && (
                  <Draggable
                    key={"new-group-column"}
                    draggableId={"new-group-column"}
                    index={internalData.columns.length + 1}
                    isDragDisabled={!isDragginAllowed}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${styles.columnWrapper}`}
                      >
                        <div
                          className="flex items-center flex-col p-3 rounded-md"
                          style={{ background: "#F8F8F8" }}
                        >
                          <div className={styles.columnHeader}>
                            <Text className="text-base">Create New Group</Text>
                          </div>

                          <div className={styles.columnItemContainer}>
                            <Droppable
                              droppableId="new-group-column"
                              type="TASK"
                            >
                              {(provided) => (
                                <div
                                  style={{ height: "70vh" }}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )}
              </>
              {!isSubGroupDragged && !isItemDragged && (
                <div>
                  <div className={`${styles.columnWrapper}`}>
                    <div
                      className="flex items-center flex-col p-3 rounded-md"
                      style={{ background: "#F8F8F8" }}
                    >
                      <div className={styles.addNewItemButton}>
                        <Button
                          type="text"
                          onClick={() => setcreateGroupModal(true)}
                          icon={<PlusOutlined />}
                          className="w-full bg-transparent"
                        >
                          Add Group
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default KanbanView;
