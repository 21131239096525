import gql from "graphql-tag";

export const GET_REQUEST = gql`
  query ($id: ID!) {
    getRequest(id: $id) {
      _id
      status
      title
      description
      type
      invitee
      newUser
      newClient
      inviter {
        _id
        email
      }
      project {
        created_by {
          email
          addons {
            _id
            name
            cost
            currency
          }
        }
      }
    }
  }
`;
