import gql from "graphql-tag";

export const GET_MONDAY = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getMonday(input: $input) {
      _id
      sync {
        monday_board
        monday_status_column_id
        windu_project
        status_pull
        status_push_finished
        status_push_start
      }
    }
  }
`;
