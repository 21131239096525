import gql from "graphql-tag";

export const GET_IMPORTS = gql`
  query {
    getImports {
      _id
      source
      totalItems
      totalImportedItems
      imported_at
      fileName
    }
  }
`;
