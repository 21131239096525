import React, { useState } from "react";
import {
  Badge,
  Dropdown,
  Typography,
  ConfigProvider,
  List,
  Tooltip,
} from "antd";
import _ from "lodash";
import { useQuery, useSubscription } from "@apollo/client";
import { MessageOutlined } from "@ant-design/icons";
import ClickOutside from "components/ClickOut";
import cx from "classnames";
import EmptyData from "../../EmptyData";
import { useRecoilState, useRecoilValue } from "recoil";
import { workspacesMessagesList } from "recoil/atoms/Messages/WorkspacesMessages";
import { messageSoundObj } from "recoil/atoms/Messages/MessageSounds";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import { NEW_MESSAGE } from "graphql/subscription/message/newMessage";
import { GET_WORKSPACES_MESSAGES } from "graphql/queries/message/getWorkspacesMessages";
import { userSession } from "recoil/atoms/User/UserSession";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import styles from "./styles.module.less";

const { Title, Text } = Typography;

const WorkspacesNotifications = () => {
  const location = useLocation();
  const history = useHistory();
  const chatSelected = useRecoilValue(chatSelectedState);
  const audio = useRecoilValue(messageSoundObj);
  const user = useRecoilValue(userSession);
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useRecoilState(workspacesMessagesList);
  const [isHidden, setIsHidden] = useState(false); // current tab visible state

  const { data: messageData, error: messageError } =
    useSubscription(NEW_MESSAGE);

  // when new messge is recived and is unread

  const { loading: messagesLoading, refetch } = useQuery(
    GET_WORKSPACES_MESSAGES,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getWorkspacesMessages }) => {
        const filtered = _.filter(
          getWorkspacesMessages,
          (item) => item.totalUnreadMessages > 0
        );
        setMessages(filtered);
      },
    }
  );

  React.useEffect(() => {
    if (messageError) console.warn(messageError);
    if (messageData) {
      const { newMessage } = messageData;

      if (user.user_type === "Client") {
        const { state } = location;
        if (state?.view !== newMessage.workspaceId) {
          audio.play();
          refetch();
          return;
        }
      }

      const { search } = location;
      const { id } = queryString.parse(search);
      const views = ["chats", "client"];

      if (
        id !== newMessage.workspaceId ||
        (id === newMessage.workspaceId && !views.includes(location.state?.view))
      ) {
        audio.play();
        refetch();
        return;
      }

      if (
        (newMessage.chatId !== chatSelected.chatId &&
          newMessage.type === "Individual") ||
        isHidden
      ) {
        audio.play();
        refetch();
      }
    }
  }, [messageError, messageData]);

  React.useEffect(() => {
    document.addEventListener("visibilitychange", function () {
      setIsHidden(document.hidden);
    });
  }, []);

  const loadWorkspace = ({ workspaceId, view }) => {
    if (user.user_type === "Client") {
      return;
    }

    const { search } = location;
    const { id } = queryString.parse(search);

    if (id !== workspaceId) {
      history.push({
        pathname: "/workspace",
        search: `?id=${workspaceId}`,
        state: { view },
      });
    } else {
      history.replace({
        pathname: "/workspace",
        search: `?id=${workspaceId}`,
        state: { view },
      });
    }
    setShowMessages(false);
  };

  const totalUnreadMessages = _.map(
    messages,
    (message) => message.totalUnreadMessages
  ).reduce((a, b) => a + b, 0);

  const messagesMenu = () => {
    return (
      <ClickOutside onClickOutside={() => setShowMessages(false)}>
        <div
          className={cx("bg-white", styles.listContainer)}
          style={{ width: "450px" }}
        >
          <div className="h-16 border-b-2 flex items-center justify-between	px-3">
            <Title level={5}>New Messages</Title>
          </div>

          <div style={{ maxHeight: "73vh" }} className="overflow-y-auto p-0">
            <ConfigProvider renderEmpty={() => <EmptyData />}>
              <List
                itemLayout="vertical"
                dataSource={messages}
                loading={messagesLoading}
                className=" p-0"
                renderItem={(message) => {
                  const teamUnreadMessages = _.find(
                    message.unreadMessages,
                    (unread) => unread.type === "Team"
                  );
                  const indiUnreadMessages = _.find(
                    message.unreadMessages,
                    (unread) => unread.type === "Individual"
                  );
                  const clientUnreadMessages = _.find(
                    message.unreadMessages,
                    (unread) => unread.type === "Client"
                  );

                  const unreadIndi = indiUnreadMessages
                    ? indiUnreadMessages.unread
                    : 0;
                  const unreadteam = teamUnreadMessages
                    ? teamUnreadMessages.unread
                    : 0;
                  const gatherTeamMessage = unreadIndi + unreadteam;

                  const unreadClient = clientUnreadMessages
                    ? clientUnreadMessages.unread
                    : 0;
                  return (
                    <>
                      <div className="w-full text-base uppercase font-semibold bg-gray-50 p-1 px-4 mt-4">
                        <Text>{message.workspaceName}</Text>
                      </div>
                      <List.Item
                        key={message._id}
                        className="p-5 cursor-pointer"
                      >
                        <List.Item.Meta
                          className="flex items-center"
                          title={
                            <>
                              {gatherTeamMessage > 0 && (
                                <div className="flex aligns-center mb-2">
                                  <Typography.Link
                                    onClick={() =>
                                      loadWorkspace({
                                        workspaceId: message._id,
                                        view: "chats",
                                      })
                                    }
                                  >
                                    Team Message
                                  </Typography.Link>
                                  <Badge
                                    count={gatherTeamMessage}
                                    className="ml-3"
                                  />
                                </div>
                              )}
                              {unreadClient > 0 && (
                                <div className="flex aligns-center">
                                  <Typography.Link
                                    onClick={() =>
                                      loadWorkspace({
                                        workspaceId: message._id,
                                        view: "client",
                                      })
                                    }
                                  >
                                    {user.user_type === "Client"
                                      ? "Message"
                                      : "Client Message"}
                                  </Typography.Link>
                                  <Badge
                                    count={unreadClient}
                                    className="ml-3"
                                  />
                                </div>
                              )}
                            </>
                          }
                        />
                      </List.Item>
                    </>
                  );
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </ClickOutside>
    );
  };

  return (
    <Dropdown
      className="tutorial-messages"
      placement="bottomRight"
      trigger={["click"]}
      overlay={messagesMenu}
      arrow
      visible={showMessages}
    >
      <Badge
        className="cursor-pointer"
        count={totalUnreadMessages}
        offset={50}
        onClick={() => setShowMessages(true)}
      >
        <Tooltip title="New Messages" placement="left">
          <MessageOutlined style={{ color: "#3C2E94" }} className="icon" />
        </Tooltip>
      </Badge>
    </Dropdown>
  );
};
export default WorkspacesNotifications;
