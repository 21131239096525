import { Table, Tag, Typography } from "antd";
import React from "react";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import { ArrowUp, CloudArrowDown } from "@phosphor-icons/react";

const NoImports = () => {
  return (
    <div className="w-full mt-5 h-full flex justify-between items-center flex-col">
      <CloudArrowDown size={124} color="#d1d1d1" weight="fill" />

      <div className="w-2/3 mt-4 flex flex-col justify-center text-center">
        <Typography.Text className="text-lg" strong>
          Get Started with Imports
        </Typography.Text>
        <Typography.Text className="mt-2 text-base">
          Start importing tasks from your existing project management tools.
          Export them as a CSV and seamlessly bring them into Windu.
        </Typography.Text>
      </div>
    </div>
  );
};

export default NoImports;
