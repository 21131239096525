import gql from "graphql-tag";

export const UPDATE_USER_PASSWORD = gql`
  mutation ($input: resetPasswordInput) {
    updateUserPassword(input: $input) {
      token
      user {
        _id
        email
        full_name
      }
    }
  }
`;
