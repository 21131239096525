import React from "react";
import ReactDOM from "react-dom";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { ApolloProvider as ApolloProviderClient } from "@apollo/client";

import apolloClient from "graphql/config/client";
import { RecoilRoot } from "recoil";

import "./index.css";
import "@fishyvisions/windu-uikit/lib/build.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Crisp } from "crisp-sdk-web";

const optimizelyReactInstance = createInstance({
  sdkKey:
    process.env.REACT_APP_BE === "be-dev.windu.io" ||
    process.env.REACT_APP_BE === "localhost:8081"
      ? process.env.REACT_APP_OPTIMIZELY_DEV
      : process.env.REACT_APP_OPTIMIZELY,
});

Crisp.configure(process.env.REACT_APP_CRISP_ID, {
  autoload: true,
});

ReactDOM.render(
  <RecoilRoot>
    <ApolloProviderClient client={apolloClient}>
      <OptimizelyProvider
        optimizely={optimizelyReactInstance}
        user={{
          id: "user123", //TBD
        }}
      >
        <App />
      </OptimizelyProvider>
    </ApolloProviderClient>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
