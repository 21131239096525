import gql from "graphql-tag";

export const UPDATE_MONDAY = gql`
  mutation ($input: updateMondayInput) {
    updateMonday(input: $input) {
      _id
      sync {
        monday_board
      }
    }
  }
`;
