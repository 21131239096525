import gql from "graphql-tag";

export const GET_INTEGRATIONS = gql`
  query {
    getIntegrations {
      _id
      name
      token
      user_name
      uniq_id
      avatar
    }
  }
`;
