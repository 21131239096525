import React from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form, Field } from "formik";
import cc from "currency-codes";
import _ from "lodash";
import { Button } from "antd";
import Modal from "../BaseModal/Modal";
import validationSchema from "./validationSchema";
import { UPDATE_WORKSPACE_RATE } from "graphql/mutations/workspace/updateWorkspaceRate";
import { AntInput, AntSelect } from "components/FormikCustomInputs";
import utility from "common/utility";

const currency = _.map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const AddRate = ({
  isVisible,
  onClose,
  onDone,
  workspace,
  userId = null,
  title = "",
}) => {
  const formRef = React.useRef();

  const [updateWorkspaceRate, { loading }] = useMutation(
    UPDATE_WORKSPACE_RATE,
    {
      onCompleted: () => {
        utility.setNotification(
          "Rate Saved",
          "This rate has been saved for this workspace",
          "success",
          "topRight"
        );
        onDone();
        onClose();
      },
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          toString(error),
          "error",
          "topRight"
        );
      },
    }
  );

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  const handleDone = (values) => {
    const { currency, rate } = values;
    const payload = {
      currency,
      rate,
      workspaceId: workspace,
    };

    if (userId) {
      payload.userId = userId;
    }

    updateWorkspaceRate({ variables: { input: payload } });
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose();
  };

  return (
    <Modal
      title={title ? title : `Set project rate`}
      visible={isVisible}
      onCancel={() => handleCancel()}
      width={500}
      footer={null}
      content={
        <Formik
          initialValues={{ currency: "USD" }}
          validationSchema={validationSchema}
          onSubmit={handleDone}
          innerRef={formRef}
        >
          {({ submitCount, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={AntSelect}
                  selectOptions={currency}
                  inputType="select"
                  name="currency"
                  label="Choose your currency"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                  showSearch
                  placeholder="Choose your currency"
                />
                <Field
                  component={AntInput}
                  name="rate"
                  label="Set hourly rate"
                  placeholder="Set hourly rate"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                  type="number"
                  min="0"
                />
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center">
          <Button className="w-32" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => onSubmit()}
            className="ml-3 w-32"
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default AddRate;
