import React from "react";
import { Formik, Form, Field } from "formik";
import { get } from "lodash";
import { Button, Typography } from "antd";
import { RESET_USER_PASSWORD } from "graphql/mutations/user/resetPassword";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import * as yup from "yup";
import FloaterInput from "components/FloaterInput";
import styles from "./styles.module.css";
import LockIcon from "common/assets/lockIcon.svg";
import { LeftOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const ForgotPassword = ({ setView }) => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("This field is required")
      .email("This is not a valid email"),
  });

  const formRef = React.useRef();
  const [resetUserPassword, { loading }] = useMutation(RESET_USER_PASSWORD, {
    onCompleted: ({ updateUser }) => {
      utility.setNotification(
        "Reset password invitation sent successfully",
        `invitation sent`,
        "success",
        "topRight"
      );
      handleCancel();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
  };

  const handleSubmit = (values) => {
    const { email } = values;
    resetUserPassword({
      variables: {
        email,
      },
    });
  };
  return (
    <>
      <div className="m-7">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setView("login")}
        >
          <LeftOutlined style={{ fontSize: 20 }} className="mr-2" />
          <Text className="text-base">Go Back</Text>
        </div>
      </div>
      <div
        className="px-12 text-center w-3/4 m-auto flex flex-col justify-between"
        style={{ marginTop: "20%", maxWidth: 500, height: "80vh" }}
      >
        <div>
          <div className="flex justify-center">
            <img src={LockIcon} alt="windu" />
          </div>
          <div className="mb-6">
            <Title level={2} style={{ marginBottom: 0 }}>
              Forgot Password?
            </Title>
            <Text className="text-base -mt-2 ">
              We'll provide you with instructions on how to reset your account.
            </Text>
          </div>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ submitCount, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <Field
                      component={FloaterInput}
                      name="email"
                      label="Work or SSO email"
                      placeholder="Work or SSO email"
                      submitCount={submitCount}
                      hasFeedback
                      required={true}
                      size="large"
                    />
                  </div>

                  <Button
                    size="large"
                    onClick={() => handleDone()}
                    key="submit"
                    type="primary"
                    loading={loading}
                    className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
                  >
                    Send link to email
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className="text-center">
          <Text>Copyright © Windu 2023 | All rights reserved</Text>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
