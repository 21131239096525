import gql from "graphql-tag";

export const GET_COMPLETED_PROJECT_ACTIVITIES = gql`
  query getCompletedProjectActivities($input: getActivitiesInfo) {
    getCompletedProjectActivities(input: $input) {
      data {
        _id
        title
        description
        created_at
        status
        handoff {
          _id
          handoff_at
          user {
            email
            full_name
            avatar
            occupation
            _id
            occupation
            rates {
              currency
              rate
            }
          }
          time {
            total_time
            start
            end
          }
        }
        time {
          total_time
          start
          end
          paused {
            time
            continue
          }
        }
        created_by {
          email
          full_name
          avatar
          occupation
          rates {
            currency
            rate
          }
        }
        project {
          _id
        }
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
