import gql from "graphql-tag";

export const GET_CLIENT_PROJECTS = gql`
  query ($input: getClientProjectsInput) {
    getClientProjects(input: $input) {
      data {
        _id
        created_at
        updated_at
        description
        title
        total_time
        total_activities
        total_members
        color
        status
        manager {
          _id
          email
          full_name
          avatar
        }
        created_by {
          _id
          email
          full_name
          avatar
        }
        members {
          _id
          email
          full_name
          avatar
        }
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
