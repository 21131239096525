import { Card, Input, Typography, Button, Divider, Select, Space } from "antd";
import React, { useRef, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import styles from "./styles.module.css";
import { EditOutlined, PushpinOutlined, PlusOutlined } from "@ant-design/icons";
import { CREATE_NOTE } from "graphql/mutations/clientPortal/Notes/createNote";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CLIENT } from "graphql/queries/client/getClient";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { Editor } from "react-draft-wysiwyg";

const { Title, Text } = Typography;

const toolbarOptions = {
  options: ["inline", "list"],
};

const NotesWidget = () => {
  const [editorState, setEditorState] = useState();
  const [noteTitle, setNoteTitle] = useState();
  const user = useRecoilValue(userSession);

  const editorStateHandler = (v) => {
    setEditorState(v);
  };

  const [selectedTags, setSelectedTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  useQuery(GET_CLIENT, {
    fetchPolicy: "cache-and-network",
    variables: { id: user._id },
    onCompleted: ({ getClient }) => {
      if (getClient?.tags) {
        setAllTags(getClient?.tags);
      }
    },
  });

  const [createNotes, { loading: createNoteLoading }] = useMutation(
    CREATE_NOTE,
    {
      onCompleted: () => {
        setNoteTitle("");
        setEditorState(EditorState.createEmpty());
        setSelectedTags([]);
      },
    }
  );

  const handleSave = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    createNotes({
      variables: {
        input: {
          title: noteTitle ? noteTitle : "New Note",
          content: JSON.stringify(rawContent),
          tags: selectedTags,
        },
      },
    });
    // Save the rawContent to your desired location or perform any other action
  };

  return (
    <Card
      bodyStyle={{ padding: 0, height: "100%" }}
      className="shadow-none border-none outline-none h-full"
    >
      <div
        className="flex justify-between items-center bg-purple-400"
        style={{ borderRadius: "8px 8px 0 0" }}
      >
        <div className="flex items-center justify-between w-full p-4">
          <div className="flex items-center ml-2">
            <Text style={{ color: "white" }} className="text-base" strong>
              Notes
            </Text>
            <PushpinOutlined style={{ color: "white" }} className="ml-2" />
          </div>
        </div>
      </div>
      {true ? (
        <div className=" h-full flex items-center justify-center">
          <Text className="text-lg -mt-12" strong>
            Coming Soon
          </Text>
        </div>
      ) : (
        <div
          className={`${styles.editorDiv} p-3 `}
          style={{ borderRadius: "0 0 8px 8px " }}
        >
          <div>
            <input
              className="outline-none bg-transparent border-none p-0 mb-1 w-full "
              placeholder="Title"
              value={noteTitle}
              onChange={(v) => setNoteTitle(v.currentTarget.value)}
            />
          </div>
          <Editor
            editorState={editorState}
            onEditorStateChange={(editorState) =>
              editorStateHandler(editorState)
            }
            wrapperClassName={`flex flex-col-reverse ${styles.mainEditorComponent}`}
            placeholder="What are you going to accomplish?"
            toolbar={toolbarOptions}
          />

          <div className="flex items-center justify-between mt-1 ">
            <div className="w-full mr-2">
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                value={selectedTags}
                placeholder="select your tags"
                onChange={(e) => setSelectedTags(e)}
                options={allTags.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
            <Button
              className={`${styles.buttonStyle} text-white  rounded-lg outline-none border-none flex items-center`}
              type="primary"
              onClick={handleSave}
              loading={createNoteLoading}
            >
              <Text className="text-white">Add note</Text>
              <EditOutlined className="ml-2" />
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default NotesWidget;
