import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Tag, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import styles from "../styles.module.css";
import _ from "lodash";
import SelectAddon from "components/Modals/SelectAddon";

const Addons = ({ selectedAddons, setselectedAddons, isAnnualPlan }) => {
  const [addonModal, setaddonModal] = useState(false);
  return (
    <div className={styles.memberContainer}>
      <Card
        title={
          <div className="flex items-center justify-between">
            <Typography.Text className="text-lg" strong>
              Add-Ons
            </Typography.Text>
            <Tooltip title="Customize your subscription with additional features">
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        }
      >
        <div>
          {_.isEmpty(selectedAddons) ? (
            <Typography.Text className="text-base">
              Customize your plans with additional features
            </Typography.Text>
          ) : (
            <div>
              {_.map(selectedAddons, (item) => (
                <Tag
                  closable
                  key={item._id}
                  onClose={() =>
                    setselectedAddons(
                      _.filter(
                        selectedAddons,
                        (selected) => !_.isEqual(selected, item)
                      )
                    )
                  }
                  className="px-2 py-1 text-base"
                >
                  {item.name}
                </Tag>
              ))}
            </div>
          )}
        </div>
        <Button
          size="large"
          className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none mt-4`}
          type="primary"
          onClick={() => setaddonModal(true)}
        >
          View
        </Button>
      </Card>

      {addonModal && (
        <SelectAddon
          visible={addonModal}
          setVisible={setaddonModal}
          setselectedAddons={setselectedAddons}
          selectedAddons={selectedAddons}
          isAnnualPlan={isAnnualPlan}
        />
      )}
    </div>
  );
};

export default Addons;
