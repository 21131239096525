import { Button, Input, Modal, Select, Typography } from "antd";
import React from "react";
import GiftIcon from "common/assets/inviteOther/gift.svg";
import RegistrationIcon from "common/assets/inviteOther/registrationIcon.svg";
import ChatIcon from "common/assets/inviteOther/chatIcon.svg";
import styles from "./styles.module.css";
import { AntInput } from "components/FormikCustomInputs";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { LinkOutlined } from "@ant-design/icons";
import { UI_URL } from "common/constants";
import { userSession } from "recoil/atoms/User/UserSession";
import utility from "common/utility";
import { useRecoilValue } from "recoil";
import { INVITE_NEW_USER } from "graphql/mutations/user/sendInvite";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { GET_USER } from "graphql/queries/getUser";

const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("This field is required"),
});

const { Text, Title } = Typography;
const InviteOtherModal = ({ isVisible, onClose, updateUserSetting }) => {
  const form = React.useRef();
  const user = useRecoilValue(userSession);

  const referralURL = `${UI_URL()}/referral-${user.settings?.referralId}`;

  const [sendInivte, { loading: sendInviteLoading }] = useMutation(
    INVITE_NEW_USER,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        onClose();
        if (updateUserSetting) updateUserSetting();
        utility.setNotification(
          "Invite Sent",
          "We have sent an invite to their email.",
          "success",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_USER,
        },
      ],
    }
  );

  const handleDone = (values) => {
    sendInivte({
      variables: {
        link: referralURL,
        email: values.email,
      },
    });
  };
  return (
    <Modal
      onCancel={onClose}
      visible={isVisible}
      title={
        <div className="flex flex-col">
          <Text className="text-lg" strong>
            Invite others
          </Text>
          <Text className="text-base">
            Invite friends to use Windu and get referral rewards!
          </Text>
        </div>
      }
      width={700}
      closable
      footer={null}
    >
      <div className="flex justify-between">
        <div className="flex flex-col justify-center text-center items-center">
          <img src={ChatIcon} alt="winduChaticon" width={80} />
          <Text className="text-lg" strong>
            Send Invites
          </Text>
          <Text className="text-base px-2">
            Add email addresses and help your friends register easily.
          </Text>
        </div>
        <div className="flex flex-col justify-center text-center items-center">
          <img src={RegistrationIcon} alt="winduChaticon" width={80} />
          <Text className="text-lg" strong>
            Registration
          </Text>
          <Text className="text-base px-2">
            Let them register to Windu using your referral link
          </Text>
        </div>
        <div className="flex flex-col justify-center text-center items-center">
          <img src={GiftIcon} alt="winduChaticon" width={80} />
          <Text className="text-lg" strong>
            Recieve Giftcards
          </Text>
          <Text className="text-base px-2">
            Earn gift card rewards using your unique link.
          </Text>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <Title style={{ marginBottom: 4 }} level={4}>
          Invite your friends*
        </Title>
        <Text className="text-base">
          Add emails and send them invitations to join Windu!
        </Text>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleDone(values)}
          innerRef={form}
        >
          {({ handleSubmit, submitCount }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="flex mt-4">
                  <div className="flex w-full items-center">
                    <Field
                      component={AntInput}
                      style={{
                        flex: 1,
                      }}
                      placeholder="Contact@example.com"
                      required="true"
                      name="email"
                      size="large"
                    />
                    <Button
                      type="primary"
                      size="large"
                      className={`ml-4 p-1 outline-none border-none flex items-center text ${styles.blackNextButton}`}
                      onClick={handleSubmit}
                      loading={sendInviteLoading}
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="my-4">
        <Title style={{ marginBottom: 4 }} level={4}>
          Referral link*
        </Title>
        <div className="flex items-center justify-between border-2 rounded p-2 mt-2 ">
          <Text className="text-base">{referralURL}</Text>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(referralURL).then(() => {
                utility.setNotification(
                  "Linked copied",
                  "Please share the copied link.",
                  "success",
                  "topRight"
                );
              });
            }}
          >
            <Text className="text-base"> Copy Link</Text>
            <LinkOutlined className="text-base ml-1" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteOtherModal;
