import gql from "graphql-tag";

export const GET_GITHUB_SYNC = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getGithubSync(input: $input) {
      repoName
      notification
    }
  }
`;
