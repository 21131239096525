import gql from "graphql-tag";

export const GET_GLOBAL_SEARCH = gql`
  query ($search: String) {
    globalSearch(search: $search) {
      _id
      title
      key
      details
      fileSources
      workspace {
        _id
      }
      initiated_by {
        _id
        full_name
        email
        avatar
      }
      created_by {
        _id
        full_name
        email
        avatar
      }

      assigned {
        _id
        email
        full_name
        avatar
      }
      priority
      tags
      status
    }
  }
`;
