import React, { useState } from "react";
import _ from "lodash";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import utility from "common/utility";
import Loader from "components/Loader";
import {
  Collapse,
  Typography,
  Tag,
  Button,
  Divider,
  Tooltip,
  Popconfirm,
} from "antd";
import cx from "classnames";
import styles from "./styles.module.css";

import Icons from "common/icons";
import Icon from "@ant-design/icons";
import moment from "moment";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { useRecoilState, useRecoilValue } from "recoil";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import EmptyData from "components/EmptyData";
import { GET_TODOIST_TASK } from "graphql/queries/Todoist/getTodoistTasks";
import { CREATE_TODOIST_ACTIVITY } from "graphql/mutations/Todoist/createTodoistActivity";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { STOP_TODOIST_ACTIVITY } from "graphql/mutations/Todoist/stopTodoistActivity";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { useTimer } from "contexts/timer-context";
import { userSession } from "recoil/atoms/User/UserSession";
import { workspacesList } from "recoil/atoms/Workspaces/index";
import { pausedActivities } from "recoil/atoms/PausedActivity";

const { Panel } = Collapse;

const Board = ({ workspaceId, setSelected }) => {
  const user = useRecoilValue(userSession);
  const workspaceData = useRecoilValue(workspacesList);
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [workspace] = useRecoilState(workspaceSelected);
  const [dueTask, setdueTask] = useState([]);
  const [pendingTask, setpendingTask] = useState([]);
  const { startHandler } = useTimer(); // global startHandler from context

  const [pausedActivity] = useRecoilState(pausedActivities);

  const { loading, refetch } = useQuery(GET_TODOIST_TASK, {
    variables: { input: { workspace: workspaceId } },
    fetchPolicy: "network-only",
    onCompleted: ({ getTodoistTasks }) => {
      const isDue = _.filter(getTodoistTasks, (item) => {
        return moment(item.due.date).isBefore(
          moment().format("YYYY-MM-DD"),
          "day"
        );
      });
      const future = _.filter(getTodoistTasks, (item) => {
        return !moment(item.due.date).isBefore(
          moment().format("YYYY-MM-DD"),
          "day"
        );
      });
      const sortByDate = _.sortBy(isDue, "due.date");
      setdueTask(sortByDate);

      const groupByDate = _.groupBy(future, "due.date");
      setpendingTask(groupByDate);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [createTodoistActivity] = useMutation(CREATE_TODOIST_ACTIVITY, {
    onCompleted: ({ createTodoistActivity }) => {
      utility.setNotification(
        `${createTodoistActivity?.title} started`,
        "You should be able to see your activity timer",
        "success",
        "topRight"
      );
      getCurrentActivity();
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const startTodoistActivity = (v) => {
    createTodoistActivity({
      variables: {
        input: {
          projectId: v.windu_project,
          title: v?.content,
          description: v?.description,
          date_start: moment.utc(new Date()),
          todoist_task_id: v?.id,
        },
      },
    });
  };

  const [stopTodoistActivity] = useMutation(STOP_TODOIST_ACTIVITY, {
    onCompleted: ({ stopTodoistActivity }) => {
      refetch();
      utility.setNotification(
        `${stopTodoistActivity?.title} stopped`,
        "You should be able to see your activity created",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PAUSED_ACTIVITY,
      },
    ],
  });

  const [pauseActivity] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: ({ pauseActivity }) => {
      utility.setNotification(
        `${pauseActivity?.title} has been paused`,
        "",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PAUSED_ACTIVITY,
      },
    ],
  });

  const handleStop = (v) => {
    stopTodoistActivity({
      variables: {
        input: {
          activity_id: activity?.data?._id,
          date_end: moment.utc(),
        },
      },
    });
  };

  const stopContinueActivity = (activity) => {
    const {
      _id,
      time: { paused },
    } = activity[0];
    stopTodoistActivity({
      variables: {
        input: {
          activity_id: _id,
          date_end: _.last(paused)?.time,
        },
      },
    });
  };
  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );
  const handleContinueActivity = (activity) => {
    const pausedCopy = _.cloneDeep(activity?.time?.paused);

    const lastPause = _.last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: { activity_id: activity._id, time: moment.utc(new Date()) },
      },
    });
  };

  const handlePause = () => {
    const time = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const [getCurrentActivity] = useLazyQuery(GET_CURRENT_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getCurrentActivity }) => {
      if (_.has(getCurrentActivity, "time.start"))
        setActivity({
          active: true,
          data: getCurrentActivity,
        });
    },
  });

  if (loading) return <Loader size="large" />;

  if (_.isEmpty(dueTask) && _.isEmpty(pendingTask))
    return (
      <div className="flex items-center flex-col h-3/5">
        <EmptyData />
        <div className="flex flex-col items-center">
          <Typography.Text>No item available</Typography.Text>
          <Button
            onClick={() => setSelected("settings")}
            className="mt-2"
            type="default"
            style={{ background: "#F5222D", color: "white" }}
          >
            Set another project
          </Button>
        </div>
      </div>
    );

  return (
    <div>
      {!_.isEmpty(dueTask) && (
        <Collapse
          expandIconPosition={"right"}
          bordered={false}
          defaultActiveKey="1"
          style={{ background: "#FFF1F0" }}
          className="mb-4"
        >
          <Panel
            header={
              <Tag color="red" style={{ color: "red" }} strong>
                Overdue {_.size(dueTask)}
              </Tag>
            }
            key="1"
          >
            {_.map(dueTask, (task) => {
              const daysDiff = moment(moment().format("MM-DD-YYYY")).diff(
                task.due.date,
                "days"
              );
              const isInPaused = _.filter(pausedActivity, (paused) => {
                return paused.meta === task?.id;
              });
              const projectDetails = _.filter(
                workspaceData,
                (item) => item.project._id === task?.windu_project
              );
              return (
                <div className="flex items-center mt-2 border-b-2 pb-3">
                  {_.isEmpty(isInPaused) ? (
                    <div>
                      <div className="flex flex-col items-center mr-2">
                        {activity?.active &&
                        activity?.data?.meta === task?.id ? (
                          <div
                            onClick={() => handlePause()}
                            className={cx(
                              styles.timer,
                              styles.timerPause,
                              "cursor-pointer"
                            )}
                          >
                            <Icon
                              className={cx(styles.icon, "mt-2")}
                              component={Icons["Pause"]}
                            />
                          </div>
                        ) : (
                          <div className={styles.playHover}>
                            <div
                              onClick={() => startTodoistActivity(task)}
                              className={cx(
                                styles.timer,
                                styles.timerOff,
                                "cursor-pointer mr-2"
                              )}
                            >
                              <Icon
                                className={styles.icon}
                                component={Icons["Play"]}
                              />
                            </div>
                          </div>
                        )}
                        {activity?.active &&
                          activity?.data?.meta === task?.id && (
                            <div
                              onClick={() => handleStop()}
                              className={cx(
                                styles.timer,
                                styles.timerOn,
                                "cursor-pointer mt-1"
                              )}
                            >
                              <Icon
                                className={styles.icon}
                                component={Icons["Stop"]}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    //  task is already started....
                    <div>
                      <div class="mr-5 sm:mr-2 flex justify-beetwen items-center">
                        <Tooltip placement="top" title="Continue this task">
                          <Popconfirm
                            placement="bottom"
                            title="Click yes to continue this activity"
                            onConfirm={() =>
                              handleContinueActivity(isInPaused[0])
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Icon
                              component={Icons.Continue}
                              className="icon-medium mr-4"
                            />
                          </Popconfirm>
                        </Tooltip>
                        <Tooltip placement="top" title="Finish this task">
                          <Popconfirm
                            placement="bottom"
                            title="Click yes to mark finished this activity"
                            onConfirm={() => stopContinueActivity(isInPaused)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Icon
                              component={Icons.CheckMark}
                              className="icon-medium mr-4"
                            />
                          </Popconfirm>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col">
                    <Typography.Text className="font-semibold">
                      {task.index}
                    </Typography.Text>
                    <Typography.Text className="font-semibold mb-1">
                      {task.content}
                    </Typography.Text>
                    <Typography.Text>
                      {_.truncate(task.description, { length: 250 })}
                    </Typography.Text>
                    <div className="mt-2">
                      <Typography.Text style={{ color: "red" }}>
                        {daysDiff} days ago
                      </Typography.Text>
                      <Tag className="ml-2" style={{ maxWidth: 200 }}>
                        {_.isEmpty(projectDetails)
                          ? workspace.project?.title
                          : projectDetails[0].project.title}
                      </Tag>
                    </div>
                  </div>
                </div>
              );
            })}
          </Panel>
        </Collapse>
      )}
      {_.map(
        _(pendingTask).toPairs().sortBy(0).fromPairs().value(),
        (item, index) => {
          let header = "";
          const daysDiff = moment(index).diff(
            moment().format("MM-DD-YYYY"),
            "days"
          );
          if (daysDiff === 0) header = "Today";
          if (daysDiff === 1) header = "Tomorrow";
          if (daysDiff > 1) header = daysDiff;

          return (
            <div className="mt-2">
              <Typography.Text className=" font-semibold text-base">
                {_.isNumber(header) ? `In ${header} days` : header}
              </Typography.Text>
              {_.map(item, (task) => {
                const isInPaused = _.filter(pausedActivity, (paused) => {
                  return paused.meta === task?.id;
                });
                const projectDetails = _.filter(
                  workspaceData,
                  (item) => item.project._id === task?.windu_project
                );

                return (
                  <div className="flex items-center mt-2">
                    <div>
                      {_.isEmpty(isInPaused) ? (
                        <div className="flex flex-col items-center mr-2">
                          {activity?.active &&
                          activity?.data?.meta === task?.id ? (
                            <div
                              onClick={() => handlePause()}
                              className={cx(
                                styles.timer,
                                styles.timerPause,
                                "cursor-pointer"
                              )}
                            >
                              <Icon
                                className={cx(styles.icon, "mt-2")}
                                component={Icons["Pause"]}
                              />
                            </div>
                          ) : (
                            <div className={styles.playHover}>
                              <div
                                onClick={() => startTodoistActivity(task)}
                                className={cx(
                                  styles.timer,
                                  styles.timerOff,
                                  "cursor-pointer mr-2"
                                )}
                              >
                                <Icon
                                  className={styles.icon}
                                  component={Icons["Play"]}
                                />
                              </div>
                            </div>
                          )}
                          {activity?.active &&
                            activity?.data?.meta === task?.id && (
                              <div
                                onClick={() => handleStop()}
                                className={cx(
                                  styles.timer,
                                  styles.timerOn,
                                  "cursor-pointer mt-1"
                                )}
                              >
                                <Icon
                                  className={styles.icon}
                                  component={Icons["Stop"]}
                                />
                              </div>
                            )}
                        </div>
                      ) : (
                        <div>
                          <div class="mr-5 sm:mr-2 flex justify-beetwen items-center">
                            <Tooltip placement="top" title="Continue this task">
                              <Popconfirm
                                placement="bottom"
                                title="Click yes to continue this activity"
                                onConfirm={() =>
                                  handleContinueActivity(isInPaused[0])
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Icon
                                  component={Icons.Continue}
                                  className="icon-medium mr-4"
                                />
                              </Popconfirm>
                            </Tooltip>
                            <Tooltip placement="top" title="Finish this task">
                              <Popconfirm
                                placement="bottom"
                                title="Click yes to mark finished this activity"
                                onConfirm={() =>
                                  stopContinueActivity(isInPaused)
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Icon
                                  component={Icons.CheckMark}
                                  className="icon-medium mr-4"
                                />
                              </Popconfirm>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <Typography.Text className="font-semibold">
                        {task.index}
                      </Typography.Text>
                      <Typography.Text className="font-semibold mb-1">
                        {task.content}
                      </Typography.Text>
                      <Typography.Text>
                        {_.truncate(task.description, { length: 250 })}
                      </Typography.Text>
                      <div className="mt-2">
                        <Tag style={{ maxWidth: 200 }}>
                          {_.isEmpty(projectDetails)
                            ? workspace.project?.title
                            : projectDetails[0].project.title}
                        </Tag>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Divider />
            </div>
          );
        }
      )}
    </div>
  );
};

export default Board;
