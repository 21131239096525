import gql from "graphql-tag";

export const CREATE_VISITOR_INVOICE = gql`
  mutation ($input: createVisitorInvoiceInput) {
    createVisitorInvoice(input: $input) {
      _id
      name
    }
  }
`;
