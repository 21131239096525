import gql from "graphql-tag";

export const GET_INVEST_OVERVIEW = gql`
  query {
    getInvestOverview {
      projectCount
      outstanding
      totalPayment
    }
  }
`;
