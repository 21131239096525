import gql from "graphql-tag";

export const GET_TODOIST_TASK = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTodoistTasks(input: $input) {
      id
      content
      description
      project
      projectId
      windu_project
      due {
        string
        date
      }
    }
  }
`;
