import gql from "graphql-tag";

export const HANDOFF_ACTIVITY = gql`
  mutation ($input: handoffActivityInput) {
    handoffActivity(input: $input) {
      _id
      title
    }
  }
`;
