import React from "react";
import { Card as ACard, Typography, Dropdown, Tooltip } from "antd";
import { EllipsisOutlined, ExpandOutlined } from "@ant-design/icons";
import DragHandle from "./DragHandle";
import styles from "./styles.module.css";
import { useRecoilValue } from "recoil";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";

const { Title } = Typography;

const Card = ({
  style,
  children,
  title,
  noHeader,
  menu,
  menuIcon,
  action,
  isEnlarge,
  enlargeAction,
  ...props
}) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const enlargeModal = useRecoilValue(enlargeState);
  return (
    <>
      <DragHandle />
      <ACard
        {...props}
        style={style}
        actions={action && action}
        className={enlargeModal.visible ? styles.enlargeActive : ""}
        title={
          !noHeader && (
            <div className="flex">
              <Title level={5}>{title}</Title>
            </div>
          )
        }
        extra={
          !noHeader && (
            <div className="flex items-center">
              {isEnlarge && (
                <div className="mr-6 cursor-pointer">
                  <Tooltip title="Enlarge">
                    <ExpandOutlined
                      style={{ fontSize: 26 }}
                      onClick={enlargeAction}
                    />
                  </Tooltip>
                </div>
              )}

              {menu && (
                <Dropdown
                  placement="bottomRight"
                  overlay={menu}
                  trigger={["click"]}
                  visible={menuVisible}
                  onVisibleChange={() => setMenuVisible(!menuVisible)}
                >
                  <div className="flex items-center cursor-pointer">
                    {menuIcon || (
                      <div
                        className={styles.options}
                        style={{
                          backgroundColor: menuVisible && "#f3a53675",
                        }}
                      >
                        <EllipsisOutlined
                          style={{
                            color: "#f3a536",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Dropdown>
              )}
            </div>
          )
        }
        size="small"
        bordered={true}
      >
        {children}
      </ACard>
    </>
  );
};

Card.defaultProps = {
  style: { height: "450px" },
};
export default Card;
