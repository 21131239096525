import gql from "graphql-tag";

export const HANDOFF_ACTIVITY_RESPONSE = gql`
  mutation ($input: handoffActivityResponseInput) {
    handoffActivityResponse(input: $input) {
      _id
      title
    }
  }
`;
