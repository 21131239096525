import React from "react";
import { Divider } from "antd";
import { Field } from "formik";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import FloaterInput from "components/FloaterInput";
import UserAvatar from "components/UserAvatar";
import RateInput from "components/RateInput";
import utility from "common/utility";

const UserInput = ({ setFieldValue, submitCount, email, values }) => {
  const [googleConnected, setGoogleConnected] = React.useState(false);

  const onGoogleLogout = () => {
    setFieldValue("name", "");
    setFieldValue("external", false);
    setGoogleConnected(false);
  };

  const handleGoogleSuccess = ({ profileObj }) => {
    const { email: googleEmail, name, imageUrl } = profileObj;

    if (email !== googleEmail) {
      utility.setNotification(
        "An error has occurred.",
        `It seems that you are attempting to connect with vjnieve.vn@gmail.com, but please note that the project invitation is only applicable to ${email}.`,
        "error",
        "topRight"
      );
      return;
    }
    setFieldValue("avatar", imageUrl);
    setFieldValue("email", googleEmail);
    setFieldValue("name", name);
    setFieldValue("external", true);
    setGoogleConnected(true);
  };

  return (
    <>
      {googleConnected ? (
        <div className="flex justify-center">
          <div className="text-center	">
            <UserAvatar user={values} size={100} className="mb-4" />
            <div>{values.name}</div>
            <div>{values.email}</div>
          </div>
        </div>
      ) : (
        <>
          <Field
            label="Name"
            required
            name="name"
            placeholder="Your name"
            component={FloaterInput}
            submitCount={submitCount}
            hasFeedback
            size="large"
          />
          <Field
            component={FloaterInput}
            name="password"
            label="Password"
            placeholder="Your secret password"
            submitCount={submitCount}
            hasFeedback
            required={true}
            size="large"
            inputType="password"
          />
        </>
      )}

      <RateInput submitCount={submitCount} />
      <Divider />
      {googleConnected ? (
        <GoogleLogout
          className={`m-auto w-full flex justify-center`}
          clientId={`${process.env.REACT_APP_GOOGLE_CLIENT}.apps.googleusercontent.com`}
          buttonText="Disconnect Google account"
          onLogoutSuccess={onGoogleLogout}
        />
      ) : (
        <GoogleLogin
          className={`m-auto w-full flex justify-center`}
          clientId={`${process.env.REACT_APP_GOOGLE_CLIENT}.apps.googleusercontent.com`}
          buttonText="Connect with Google account"
          onSuccess={handleGoogleSuccess}
          onFailure={() => console.log("failed")}
        />
      )}
    </>
  );
};

export default UserInput;
