import { useRecoilState } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { map, filter } from "lodash";

export const useLimitWithInvitee = () => {
  const [user] = useRecoilState(userSession);

  const setUserLimit = user?.settings?.uniqueProjectsUsers;
  const mapUniqueProjectsParticipants = map(
    user?.extra?.uniqueProjectsParticipants,
    (u) => u?.email
  );

  const pastLimit = (invitees = []) => {
    const fillerOutOwnerEmail = filter(
      invitees,
      (invitee) => invitee !== user.email
    );

    const filterWithPayload = filter(
      fillerOutOwnerEmail,
      (invitee) => !mapUniqueProjectsParticipants?.includes(invitee)
    );

    return (
      filterWithPayload?.length + mapUniqueProjectsParticipants?.length >
      setUserLimit
    );
  };

  return { pastLimit, setUserLimit };
};
