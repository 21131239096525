import React, { useState, useRef, useEffect } from "react";
import WinduLogo from "common/assets/mobile_icon.png";
import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Dropdown,
  Menu,
  Row,
  Typography,
} from "antd";
import moment from "moment-timezone";
import { LeftOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Field, Formik, Form } from "formik";

import _ from "lodash";
import { Icon } from "@fishyvisions/windu-uikit";
import cc from "currency-codes";
import {
  AntDatePicker,
  AntDateRangePicker,
  AntInput,
  AntSelect,
  AntTextArea,
} from "components/FormikCustomInputs";
import validationSchema from "./validationSchema";
import EditableTable from "components/Invoices/CreateInvoice/EditableTabel";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { CREATE_VISITOR_INVOICE } from "graphql/mutations/visitor.js/createVisitorInvoice";
import { useMutation } from "@apollo/client";
import utility from "common/utility";

const { Title, Text } = Typography;

const currency = _.map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const CreateInvoice = ({ setCreateInvoice }) => {
  const [dataSource, setDataSource] = useState([]);
  const [totalDue, setTotalDue] = useState(0);
  const user = useRecoilValue(userSession);
  const formRef = React.useRef();

  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const backHandler = () => {
    setCreateInvoice(false);
  };

  const [createInvoice, { loading: createInvoiceLoading }] = useMutation(
    CREATE_VISITOR_INVOICE,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Invoice created successfully.",
          `invoice has been sent to client.`,
          "success",
          "topRight"
        );
        setCreateInvoice(false);
      },
    }
  );

  useEffect(() => {
    let totalDue = 0;
    _.forEach(dataSource, (elem) => {
      const cost = _.isNaN(Number(elem.cost)) ? 0 : Number(elem.cost);
      totalDue = (Number(totalDue) + Number(cost))?.toFixed(2);
    });

    setTotalDue(totalDue);
  }, [dataSource]);

  const handleAdd = () => {
    const newData = {
      key: _.uniqueId("invoice_"),
      name: "Enter the description here",
      hours: "00:00:00",
      cost: 0,
      activitiesSelected: [],
    };
    setDataSource([...dataSource, newData]);
  };

  const handleDelete = (key) => {
    setDataSource(dataSource.filter((item) => item.key !== key));
  };

  const handleDublicate = (record) => {
    var dublicateRow = { ...record };
    dublicateRow.key = _.uniqueId("dublicate_");
    setDataSource([...dataSource, dublicateRow]);
  };

  const TableDropDown = ({ record }) => {
    return (
      <Menu className="py-0">
        <Menu.Item
          className="py-4 flex items-center"
          key="1"
          onClick={() => handleDelete(record.key)}
        >
          Delete Item
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          className="py-4 flex items-center"
          key="2"
          onClick={() => handleDublicate(record)}
        >
          Dublicate Item
        </Menu.Item>
      </Menu>
    );
  };
  const columnsData = [
    {
      title: "Description",
      dataIndex: "name",
      editable: true,
      width: "50%",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      editable: true,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      editable: true,
      render: (data) => {
        return Number(data)?.toFixed(2);
      },
    },
    {
      title: "Currency",
      editable: false,
      render: () => {
        return `${selectedCurrency}`;
      },
    },
    {
      title: "",
      dataIndex: "option dropdown",
      render: (_, record) => (
        <Dropdown
          placement="bottomRight"
          overlay={<TableDropDown record={record} />}
          trigger={["click"]}
        >
          <span onClick={(e) => e.preventDefault()} className="cursor-pointer">
            <Icon type="Meatball" size="small" />
          </span>
        </Dropdown>
      ),
    },
  ];
  const handleDone = () => {
    formRef.current.submitForm();
  };

  const submitForm = (values) => {
    const {
      currency,
      title,
      invoicePeriod,
      dueDate,
      issueDate,
      notes,
      address,
      company,
      client_name,
      email,
    } = values;
    const invoicePayload = {
      name: title,
      period: `${moment(invoicePeriod[0]).format("YYYY-MM-DD")} ${moment(
        invoicePeriod[1]
      ).format("YYYY-MM-DD")}`,
      payment_due: dueDate,
      total_due: String(totalDue),
      sub_total: String(totalDue),
      currency,
      sent_to: null,
      sent_at: null,
      status: "PENDING",
      issue_date: issueDate,
      created_by: user?._id,
      notes,
      items: _.map(dataSource, (item) => {
        return {
          description: item.name,
          hours: _.replace(item.hours, "__", "00"),
          cost: String(item?.cost),
        };
      }),
      client_name,
      client_email: email,
      company,
      address: address,
    };
    createInvoice({
      variables: {
        input: {
          ...invoicePayload,
        },
      },
    });
  };

  return (
    <div>
      <div className=" py-4 ml-2">
        <img src={WinduLogo} style={{ width: "5.5vw" }} />
      </div>
      <div className="w-5/6 m-auto ">
        <div className="flex items-center -mt-4 mb-4">
          <Button
            onClick={backHandler}
            type="text"
            style={{ color: "#3C2E94" }}
            className="text-base flex items-center mr-0 px-2"
          >
            <LeftOutlined /> Back
          </Button>
        </div>
        <Card>
          <Formik
            initialValues={{
              client_name: "",
              client_email: "",
              company: "",
              address: "",
              currency: selectedCurrency,
              title: "",
              issueDate: "",
              dueDate: "",
              invoicePeriod: "",
              notes: "",
            }}
            validationSchema={validationSchema}
            onSubmit={submitForm}
            innerRef={formRef}
            enableReinitialize={true}
          >
            {({ submitCount, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={4} className="mr-2">
                      <Field
                        component={AntInput}
                        name="client_name"
                        label="Client name"
                        placeholder="Full name"
                        submitCount={submitCount}
                        size="medium"
                      />
                    </Col>
                    <Col lg={6} className="mr-2">
                      <Field
                        component={AntInput}
                        name="email"
                        label="Email"
                        placeholder="Client email"
                        submitCount={submitCount}
                        type="email"
                        required={true}
                        size="medium"
                      />
                    </Col>

                    <Col lg={6} className="mr-2">
                      <Field
                        component={AntInput}
                        name="address"
                        label="Client address"
                        placeholder="Client address"
                        submitCount={submitCount}
                        size="medium"
                      />
                    </Col>
                    <Col lg={4} className="mr-2">
                      <Field
                        component={AntInput}
                        name="company"
                        label="Company name"
                        placeholder="Company name"
                        submitCount={submitCount}
                        size="medium"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mr-2">
                      <Field
                        component={AntInput}
                        name="title"
                        label="Title"
                        placeholder="Invoice - Project A"
                        submitCount={submitCount}
                        required={true}
                        size="medium"
                      />
                    </Col>
                    <Col lg={6} className="mr-2">
                      <Field
                        component={AntDateRangePicker}
                        name="invoicePeriod"
                        label="Invoice Period"
                        submitCount={submitCount}
                        required={true}
                        size="medium"
                        inputType="picker"
                        className="w-full"
                      />
                    </Col>
                    <Col lg={4} className="mr-2">
                      <Field
                        component={AntDatePicker}
                        name="issueDate"
                        label="Issue Date"
                        submitCount={submitCount}
                        required={true}
                        size="medium"
                        inputType="picker"
                        className="w-full"
                      />
                    </Col>
                    <Col lg={4} className="mr-2">
                      <Field
                        component={AntDatePicker}
                        name="dueDate"
                        label="Payment Due"
                        submitCount={submitCount}
                        required={true}
                        size="medium"
                        inputType="picker"
                        className="w-full"
                      />
                    </Col>
                    <Col lg={2}>
                      <Field
                        component={AntSelect}
                        name="currency"
                        label="Currency"
                        placeholder="Currency"
                        submitCount={submitCount}
                        selectOptions={currency}
                        inputType="select"
                        size="medium"
                        showSearch
                        onCallBackChange={(v) => {
                          //   convertCurrencyRate(selectedCurrency, v);
                          setselectedCurrency(v);
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="flex items-center mt-8">
                    <Title
                      level={5}
                      className="text-sm"
                      style={{ color: "#3C2E94" }}
                    >
                      PAYABLE ITEMS
                    </Title>
                  </div>

                  <ConfigProvider>
                    <EditableTable
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      columnsData={columnsData}
                      className="mt-3"
                      selectedCurrency={selectedCurrency}
                      pagination={false}
                    />
                  </ConfigProvider>
                  <div className="flex mt-6">
                    <span
                      onClick={handleAdd}
                      className="cursor-pointer flex items-center"
                      style={{ color: "#3C2E94" }}
                    >
                      <PlusSquareOutlined style={{ fontSize: "26px" }} />
                      <Text style={{ color: "#3C2E94" }} className="ml-1">
                        Add a blank item
                      </Text>
                    </span>
                  </div>
                  <Row className="mt-10">
                    <Col span={12}>
                      <Text className="font-semibold text-base">Notes</Text>
                      <Field
                        component={AntTextArea}
                        name="notes"
                        placeholder="Add note to you invoice"
                        submitCount={submitCount}
                        hasFeedback
                        size="medium"
                        className="mt-3.5"
                        style={{ minHeight: 100 }}
                      />
                    </Col>
                    <Col span={5} offset={7}>
                      <div className="w-full mt-4">
                        <Text className="font-semibold">Total Due </Text>
                        <Text className="ml-3 font-semibold">{`${selectedCurrency} ${totalDue}`}</Text>
                      </div>
                      {dataSource?.length > 0 && (
                        <div className="mt-14 flex">
                          <Button
                            onClick={() => {
                              handleDone();
                            }}
                            type="primary"
                            className="w-32 mb-3 ml-3"
                            loading={createInvoiceLoading}
                          >
                            Send
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

export default CreateInvoice;
