import React, { useState } from "react";
import { Alert } from "antd";

const Error = ({ onClose, message }) => {
  const trimmedMessage = message.includes("GraphQL")
    ? message.slice(14, message.length)
    : message;
  return (
    <Alert message={trimmedMessage} type="error" closable onClose={onClose} />
  );
};

export default Error;
