import { useMutation, useQuery } from "@apollo/client";
import { Button, DatePicker, Modal, Spin, Tag, Typography } from "antd";
import { GET_PROJECTS } from "graphql/queries/projects/getProjects";
import React, { useState } from "react";
import styles from "./styles.module.css";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { CREATE_REPORT } from "graphql/mutations/Activity/genrateReport";
import utility from "common/utility";
import { LoadingOutlined } from "@ant-design/icons";
import { AntInput } from "components/FormikCustomInputs";

const { CheckableTag } = Tag;

const sizePerPage = 20;
const initialOffset = 0;

const defaultProjectFilters = {
  status: "",
  sort: `{"updated_at": -1}`,
  relationship: "",
};

const initialFormValues = {
  selectedMembers: [],
  selectedProjects: [],
  start_date: new Date(moment().subtract(1, "month").toDate()),
  end_date: new Date(moment().toDate()),
};
const validationSchema = yup.object().shape({
  selectedMembers: yup.array().required("Please select at least one member"),
  selectedProjects: yup.array().required("Please select at least one project"),
  title: yup.string().trim().required("This field is required"),
});

const ReportModal = ({ visible, setVisible, callback }) => {
  const formRef = React.useRef();
  const user = useRecoilValue(userSession);
  // Project Query
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: "",
    filters: defaultProjectFilters,
  });
  const [projects, setProjects] = useState([]);
  const [members, setMembers] = useState([user]);

  useQuery(GET_PROJECTS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjects }) => {
      setProjects(
        _.uniqBy([...new Set([...projects, ...getProjects.data])], "_id")
      );
      const membersData = _.map(getProjects.data, ({ members }) => [
        ...members,
      ]);

      var merged = [].concat.apply([], membersData);
      const resultMember = _.uniqBy(
        [...new Set([...members, ...merged])],
        "_id"
      );
      setMembers(resultMember);
    },
  });

  const [selectedMemberTags, setSelectedMemberTags] = useState([]);
  const [selectedProjectTags, setSelectedProjectTags] = useState([]);
  const handleTeamChange = (tag, checked, setFieldValue) => {
    const nextSelectedTags = checked
      ? [...selectedMemberTags, tag]
      : selectedMemberTags.filter((t) => t !== tag);
    setSelectedMemberTags(nextSelectedTags);
    setFieldValue("selectedMembers", nextSelectedTags);
  };
  const handleProjectChange = (tag, checked, setFieldValue) => {
    const nextSelectedTags = checked
      ? [...selectedProjectTags, tag]
      : selectedProjectTags.filter((t) => t !== tag);
    setSelectedProjectTags(nextSelectedTags);
    const selectedProject = projects.filter((item) => {
      for (let x in nextSelectedTags) {
        if (nextSelectedTags[x] === String(item._id)) {
          return true;
        }
      }
    });
    const membersData = _.map(selectedProject, ({ members }) => [...members]);
    var merged = [].concat.apply([], membersData);
    const resultMember = _.uniqBy([...new Set([user, ...merged])], "_id");
    setMembers(resultMember);
    setFieldValue("selectedProjects", nextSelectedTags);
  };
  const handleSelectedDates = (data, setFieldValue) => {
    setFieldValue("start_date", data[0].format());
    setFieldValue("end_date", data[1].format());
  };

  const [createReport, { loading }] = useMutation(CREATE_REPORT, {
    onCompleted: ({ genrateReport }) => {
      utility.donwloadHanlder({ url: genrateReport });
      if (callback) callback();
      setVisible(false);
    },
  });
  const onSubmit = (v) => {
    createReport({
      variables: {
        input: {
          ...v,
        },
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={700}
      maskClosable={false}
      closable={false}
    >
      <Spin
        spinning={loading}
        size="large"
        indicator={<LoadingOutlined spin />}
      >
        <Typography.Title level={3}>Export as PDF</Typography.Title>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          innerRef={formRef}
          validationSchema={validationSchema}
        >
          {({ submitCount, handleSubmit, setFieldValue, values, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div>
                  <Typography.Text className="text-base font-semibold">
                    Title
                  </Typography.Text>
                  <Field
                    required
                    name="title"
                    component={AntInput}
                    placeholder="Report title"
                    hasFeedback
                    size="large"
                  />
                  {submitCount > 0 && (
                    <Typography.Text style={{ color: "red", marginLeft: 12 }}>
                      {errors?.title}
                    </Typography.Text>
                  )}
                </div>
                <div className="mt-4">
                  <Typography.Text className="text-base font-semibold">
                    Projects
                  </Typography.Text>
                  {submitCount > 0 && (
                    <Typography.Text style={{ color: "red", marginLeft: 12 }}>
                      {errors?.selectedProjects}
                    </Typography.Text>
                  )}
                  <div className={styles.projectMemberStyle}>
                    {_.map(projects, (item) => {
                      return (
                        <CheckableTag
                          key={item._id}
                          checked={selectedProjectTags?.indexOf(item._id) > -1}
                          onChange={(checked) =>
                            handleProjectChange(
                              item._id,
                              checked,
                              setFieldValue
                            )
                          }
                          className="px-5 text-sm py-1 m-1 pb-1.5"
                        >
                          {item.title}
                        </CheckableTag>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-4">
                  <Typography.Text className="text-base font-semibold">
                    Team Members
                  </Typography.Text>
                  {submitCount > 0 && (
                    <Typography.Text style={{ color: "red", marginLeft: 12 }}>
                      {errors?.selectedMembers}
                    </Typography.Text>
                  )}
                  <div className={styles.teamMemberStyle}>
                    {_.map(members, (item) => {
                      return (
                        <CheckableTag
                          key={item._id}
                          checked={selectedMemberTags?.indexOf(item._id) > -1}
                          onChange={(checked) =>
                            handleTeamChange(item._id, checked, setFieldValue)
                          }
                          className="px-5 text-sm py-1 m-1 pb-1.5"
                        >
                          <div className="flex items-center">
                            <UserAvatar user={item} height={18} />
                            <Typography.Text
                              style={{ marginLeft: 12, fontSize: 16 }}
                              className="font-semibold"
                            >
                              {item.full_name}
                            </Typography.Text>
                          </div>
                        </CheckableTag>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-4">
                  <Typography.Text className="text-base font-semibold">
                    Date
                  </Typography.Text>
                  {submitCount > 0 && (
                    <Typography.Text style={{ color: "red", marginLeft: 12 }}>
                      {errors?.selectedDates}
                    </Typography.Text>
                  )}
                  <div className="mt-2 w-80 z-50">
                    <DatePicker.RangePicker
                      onChange={(v) => handleSelectedDates(v, setFieldValue)}
                      style={{ zIndex: 1500 }}
                      dropdownClassName={styles.dropdownClassName}
                      defaultValue={[
                        moment(
                          moment().subtract(1, "month").toDate(),
                          "YYYY/MM/DD"
                        ),
                        moment(moment(), "YYYY/MM/DD"),
                      ]}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-2 items-center gap-3">
                  <Button onClick={() => setVisible(false)} disabled={loading}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Export
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Spin>
    </Modal>
  );
};

export default ReportModal;
