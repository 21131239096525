import React, { useState, useEffect } from "react";
import Auth from "./Auth";
import { Form, Formik } from "formik";
import { Button, Menu } from "antd";
import { get } from "lodash";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { CONNECT_GITHUB } from "graphql/mutations/Github/connectGithub";
import { UPDATE_GITHUB_SYNC } from "graphql/mutations/Github/updateGithubSync";
import { GET_GITHUB_SYNC } from "graphql/queries/Github/getGithubSync";
import utility from "common/utility";
import View from "./View";
import Card from "../Card";
import GitLogo from "../../../common/assets/gitLogo.png";
import { userSession } from "recoil/atoms/User/UserSession";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { useAuthWidgets } from "../common/useAuthWidgets";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";

const GitHub = ({ location }) => {
  const [user] = useRecoilState(userSession);

  const [authenticated, setAuthenticated] = useState(false);
  const [githubUser, setgithubUser] = useState();
  const [selected, setSelected] = useState("pull_requests");
  const [repos, setRepos] = useState([]);
  const { id } = queryString.parse(location.search);
  const query = { id };
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);
  const history = useHistory();
  const { createIntegration } = useCreateIntegrationHook();

  useAuthWidgets(authenticated, "GITHUB", id);

  const { loading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "github", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setAuthenticated(getWorkspaceIntegration?.access_token);
        setgithubUser(getWorkspaceIntegration?.user_name);
      }
    },
  });

  const [getGithubSync, { loading: loadingSync }] = useLazyQuery(
    GET_GITHUB_SYNC,
    {
      fetchPolicy: "cache-and-network",
      variables: { input: { workspace: id } },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ getGithubSync }) => {
        setRepos(getGithubSync);
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const [connectGithub] = useMutation(CONNECT_GITHUB, {
    onCompleted(data) {
      createIntegration({
        type: "github",
        refresh_token: data?.connectGithub?.refresh_token,
        access_token: data?.connectGithub?.access_token,
        workspace: id,
      });
      setAuthenticated(data?.connectGithub?.access_token);
      setgithubUser(data?.connectGithub?.login);
      window.history.pushState({ path: "" }, "", `/workspace?id=${id}`);
      setSelected("settings");
    },
    onError(err) {
      const errorMessage = get(err, "message", "Internal Error");
      // removeWorkspaceIntegration(id, "github");
      // here remove the integration todo:
      utility.setNotification("Error", errorMessage, "error", "topRight");
    },
  });

  const [updateGithubSync] = useMutation(UPDATE_GITHUB_SYNC, {
    onCompleted(data) {
      getGithubSync({ variables: { input: { workspace: id } } });
      setSelected("pull_requests");
    },
    onError(err) {
      const errorMessage = get(err, "message", "Internal Error");
      // removeWorkspaceIntegration(id, "github");
      // remove the integration here
      utility.setNotification("Error", errorMessage, "error", "topRight");
    },
  });

  useEffect(() => {
    const { integration, code } = queryString.parse(location.search);
    if (integration === "github" && code) {
      createGoogleEvent(user?._id, "CONNECT_GITHUB_WIDGET", "app_engagement");
      connectGithub({ variables: { input: { code } } });
    }
  }, [location]);

  useEffect(() => {
    if (authenticated) {
      getGithubSync({ variables: { input: { workspace: id } } });
    }
  }, [authenticated, selected, getGithubSync, id]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const onSubmit = (values) => {
    updateGithubSync({
      variables: {
        input: {
          repos: values.repos,
          workspace: id,
          token: authenticated,
          username: githubUser,
        },
      },
    });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="pull_requests">
        <a>Pull Requests</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7547207617559-GitHub"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );
  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "github" });
  };

  const bodyHeight = selected === "settings" ? "70%" : "83%";

  return authenticated ? (
    <Formik
      enableReinitialize={true}
      initialValues={{
        repos: [],
      }}
      validationSchema={null}
      onSubmit={onSubmit}
      render={({ values, handleSubmit }) => (
        <Form>
          <Card
            bodyStyle={{ height: bodyHeight, overflow: "auto" }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: 25 }}
                  className="mr-3"
                  src={GitLogo}
                  alt="github"
                />
                <h5>GitHub</h5>
              </div>
            }
            action={[
              selected === "settings" && (
                <div className="text-right pr-3">
                  <Button onClick={handleSubmit} type="default">
                    Save
                  </Button>
                </div>
              ),
            ]}
            menu={menu}
          >
            <View
              githubUser={githubUser}
              token={authenticated}
              repos={repos}
              view={selected}
            />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth loading={loading} setAuthenticated={setAuthenticated} />
  );
};

export default withRouter(GitHub);
