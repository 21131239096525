import axios from "axios";
import _ from "lodash";
import { api } from "common/constants";

export const uploadChatFiles = async ({ chatId, fileList, updateProgress }) => {
  let formData = new FormData();

  _.map(fileList, (file) => formData.append("files", file.originFileObj));
  formData.append("chat", chatId);

  let config = {
    header: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      console.log("completing date", percentComplete);
      updateProgress(percentComplete);
    },
  };

  const result = await axios.post(`${api}/chat/upload/files`, formData, config);

  const { data = {} } = result;

  return data;
};
