import gql from "graphql-tag";

export const ARCHIVE_PROJECT = gql`
  mutation($input: projectInput) {
    archiveProject(input: $input) {
      _id
      title
      description
      status
    }
  }
`;
