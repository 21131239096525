import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Popover, Typography, Tooltip } from "antd";
import utility from "common/utility";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { AntInput, AntSelect } from "components/FormikCustomInputs";
import UserAvatar from "components/UserAvatar";
import Loader from "components/Loader";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_WHOLE_TEAM } from "graphql/queries/member/getWholeTeam";
import GiftImage from "common/assets/giftLink.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { GET_TANGO_ACCOUNT } from "graphql/queries/Rewards/getTangoAccount";
import { SEND_GIFT_CARD } from "graphql/mutations/Rewards/sendGiftcard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const validationSchema = yup.object().shape({
  recipients: yup
    .string()
    .email("Invalid email")
    .required("This field is required"),
  amount: yup.number().required("This field is required"),
});

const initialFormValues = {
  recipients: "",
  amount: 5,
  message: null,
};

const SendGiftCard = ({ isVisible, setVisible }) => {
  const user = useRecoilValue(userSession);
  const [membersList, setMembersList] = useState([]);
  const history = useHistory();

  const { loading: tangoAccountLoading, data: tangoAccount } = useQuery(
    GET_TANGO_ACCOUNT,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getMembers, { loading, data }] = useLazyQuery(GET_WHOLE_TEAM, {
    variables: {
      input: {
        size: 10,
        offset: 0,
        search: "",
      },
    },
  });

  const [sendGiftCard, { loading: sendGiftCardLoading }] = useMutation(
    SEND_GIFT_CARD,
    {
      onCompleted: () => {
        utility.setNotification(
          "Gift card sent successfully",
          "Gift card sent successfully.",
          "success",
          "topRight"
        );
        setVisible(false);
      },
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          "Please try again.",
          "error",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    getMembers({
      variables: {
        input: {
          size: 10,
          offset: 0,
          search: "",
        },
      },
    });
  }, []);

  const delayedQuery = React.useCallback(
    _.debounce((query) => {
      handleSearch(query);
    }, 1000),
    []
  );

  const handleSearch = (v, values) => {
    getMembers({
      variables: {
        input: {
          size: 10,
          offset: 0,
          search: v,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.getWholeTeam) {
      const filterData = _.filter(
        data?.getWholeTeam?.data,
        (item) => item._id !== user._id
      );
      setMembersList(_.unionBy([], filterData, "_id"));
    }
  }, [data?.getWholeTeam]);

  const handleSubmit = (v) => {
    sendGiftCard({
      variables: {
        input: {
          recipients: v?.recipients,
          amount: Number(v?.amount),
          message: v?.message,
        },
      },
    });
  };
  return (
    <Modal
      visible={isVisible}
      title="Instant Giftcard"
      width={700}
      style={{ minHeight: 400 }}
      closable
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {tangoAccountLoading ? (
        <Loader size="small" />
      ) : (
        <div>
          {tangoAccount?.getActiveTangoAccount ? (
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ submitCount, handleSubmit, setFieldValue, values }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-center w-full justify-center">
                      <Typography.Text>Available Balance</Typography.Text>
                      <Typography.Text className="text-xl mt-2" strong>
                        $ {tangoAccount?.getActiveTangoAccount?.currentBalance}
                      </Typography.Text>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex " style={{ width: "100%" }}>
                        <Field
                          component={AntSelect}
                          name="recipients"
                          label="Select Recipients"
                          placeholder="Search by email"
                          required
                          selectOptions={_.map(membersList, (user) => {
                            return {
                              value: user?.email,
                              labelText: user?.email,
                              label: (
                                <div>
                                  <UserAvatar
                                    user={user}
                                    size={30}
                                    className="mr-2"
                                  />
                                  <Typography.Text>
                                    {user?.email}
                                  </Typography.Text>
                                </div>
                              ),
                            };
                          })}
                          optionLabelProp="label"
                          loading={loading}
                          inputType="select"
                          size="large"
                          showSearch
                          notFoundContent={
                            loading ? (
                              <Loader size="small" />
                            ) : (
                              <div className="flex justify-center">No Data</div>
                            )
                          }
                          allowClear
                          onSearch={(v) => delayedQuery(v, values)}
                        />

                        <Field
                          label="Amount"
                          required
                          name="amount"
                          placeholder="Enter amount"
                          component={AntInput}
                          submitCount={submitCount}
                          hasFeedback
                          type="number"
                          size="large"
                          min={2}
                        />
                      </div>
                    </div>

                    <Field
                      label="Message"
                      name="message"
                      placeholder="Write a message"
                      component={AntInput}
                      submitCount={submitCount}
                      hasFeedback
                      size="large"
                    />

                    <div className="flex flex-col mt-4">
                      <div>
                        <Typography.Text className="mr-2">
                          Reward Link Preferred
                        </Typography.Text>
                        <Popover
                          title={
                            <div className="max-w-xs">
                              <Typography.Text>
                                Reward Link Preferred is a user-friendly rewards
                                program by Tango Card. With it, you can choose
                                any gift card you like from a selection, making
                                it a personalized and hassle-free way to reward
                                and motivate people. It's all about giving
                                recipients the freedom to pick a gift card
                                they'll truly enjoy.
                              </Typography.Text>
                            </div>
                          }
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </div>

                      <img
                        className="mt-2"
                        src={GiftImage}
                        alt="Windu Gift lins"
                      />
                    </div>

                    <div className="flex items-center justify-end mt-4 ">
                      <Button onClick={() => setVisible(false)} size="large">
                        Cancel
                      </Button>
                      <Button
                        size="large"
                        type="primary"
                        className="ml-4"
                        onClick={handleSubmit}
                        loading={sendGiftCardLoading}
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="flex items-center justify-between h-32">
              <Typography.Text>
                Please setup your tango account in setting rewards.
              </Typography.Text>
              <Button onClick={() => history.push("/settings?view=rewards")}>
                Go to Settings
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default SendGiftCard;
