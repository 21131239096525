import React from "react";
import { useQuery } from "@apollo/client";
import { map, upperFirst } from "lodash";
import { Card, Row, Col, Typography } from "antd";
import { GET_OVERVIEW_WIDGET } from "graphql/queries/widget/getOverviewWidget";
import DragHandle from "../DragHandle";
import utility from "common/utility";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";

const { Title, Text } = Typography;

const Overview = () => {
  const user = useRecoilValue(userSession);
  const { loading, data } = useQuery(GET_OVERVIEW_WIDGET);
  const reformatresponse = map(data?.getOverviewWidget, (value, i) => {
    return { label: i, value };
  });

  const listOverview = () => {
    return reformatresponse.map((data) => {
      if (data.label === "clients") {
        if (!utility.haveInvoicingAccess(user)) {
          return null;
        }
      }
      return (
        <Col key={data.label} lg={8} md={8} sm={8} className="my-4">
          <div className="grid">
            <Text className="text-xl text-black mb-2">{data?.value}</Text>
            <Text className="text-xl text-black">
              {upperFirst(data?.label)}
            </Text>
          </div>
        </Col>
      );
    });
  };

  return (
    <>
      <DragHandle />
      <Card
        loading={loading}
        bodyStyle={{ padding: 20 }}
        className="shadow-none border-none outline-none h-full tour-overviewwidget"
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Title level={4}>Overview</Title>
            </div>
          </div>
        </div>
        <Row className="mt-3">{listOverview()}</Row>
      </Card>
    </>
  );
};

export default Overview;
