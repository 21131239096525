import gql from "graphql-tag";

export const GET_OVERVIEW_WIDGET = gql`
  query {
    getOverviewWidget {
      projects
      clients
      members
      managers
      activities
    }
  }
`;
