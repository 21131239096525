import React from "react";
import { onBoardingInfoV2 } from "../onBoardingData";
import { Typography, Tag } from "antd";
import { map } from "lodash";

import styles from "../styles.module.css";

const { Text } = Typography;

const Five = ({ values, setFieldValue, errors }) => {
  const hasErrors = errors?.tools;

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...values?.tools, tag]
      : values?.tools.filter((t) => t !== tag);
    setFieldValue("tools", nextSelectedTags);
  };
  return (
    <div className="mt-5 flex flex-col">
      <Text className=" text-xl font-semibold mt-2">
        {onBoardingInfoV2().Tools.question}
      </Text>
      <Text className=" mt-1 mb-3">Select all that apply:</Text>
      <div className={styles.radioButtonStyles}>
        {map(onBoardingInfoV2().Tools.options, (tag) => {
          return (
            <Tag.CheckableTag
              key={tag.value}
              checked={values?.tools.indexOf(tag.value) > -1}
              onChange={(checked) => handleChange(tag.value, checked)}
              className="text-base"
            >
              {tag.value}
            </Tag.CheckableTag>
          );
        })}
      </div>
      {hasErrors && (
        <div>
          <Text style={{ color: "#ff4d4f" }}>Please select atleast one</Text>
        </div>
      )}
    </div>
  );
};

export default Five;
