import { CaretDownOutlined, EllipsisOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  List,
  Menu,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import NoReportImage from "common/assets/report.svg";
import { Icon } from "@fishyvisions/windu-uikit";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import utility from "common/utility";
import { GET_REPORTS } from "graphql/queries/Report/getReport";
import ReportModal from "components/Modals/ReportModal";
import UserAvatar from "components/UserAvatar";
import { REMOVE_REPORT } from "graphql/mutations/Report/removeReport";
import Loader from "components/Loader";
import moment from "moment";

const { Text } = Typography;

const Report = () => {
  const [reportData, setReportData] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  const {
    loading: reportLoading,
    data,
    refetch,
  } = useQuery(GET_REPORTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: false,
    onError: () => {
      utility.setNotification(
        "Error",
        `Report Information`,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (data?.getReports) {
      setReportData(data?.getReports);
    }
  }, [data?.getReports]);

  const [removeReport, { loading: removing }] = useMutation(REMOVE_REPORT, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const menu = (
    <Menu className="py-0">
      <Menu.Item className="py-4" onClick={() => setExportModal(true)}>
        Create
      </Menu.Item>
    </Menu>
  );
  const listDropDown = (item) => {
    return (
      <Menu className="py-0">
        <Menu.Item
          key="download"
          className="py-4"
          onClick={() => utility.donwloadHanlder({ url: item.reportURL })}
        >
          Download
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          key="remove"
          className="py-4"
          onClick={() => removeReport({ variables: { reportId: item._id } })}
        >
          Remove
        </Menu.Item>
      </Menu>
    );
  };
  const NoReportData = () => {
    return (
      <div
        className=" flex items-center justify-center flex-col w-full"
        style={{ height: "70vh", background: "#D9DDE5" }}
      >
        <img src={NoReportImage} style={{ height: 350 }} />
        <Text className="mt-3 text-base">
          Looks like you don't have any reports!
        </Text>
      </div>
    );
  };

  const listLoading = {
    spinning: removing || reportLoading,
    indicator: <Loader />,
  };

  return (
    <div>
      <Row className="mb-6">
        <Col xs={20}>
          <div className="flex items-center">
            <Typography.Title level={4}>Report</Typography.Title>
            <Dropdown overlay={menu}>
              <EllipsisOutlined
                style={{ fontSize: 35 }}
                className="cursor-pointer ml-2"
              />
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        {_.isEmpty(reportData) && !reportLoading ? (
          <NoReportData />
        ) : (
          <div className="w-full">
            <List
              loading={listLoading}
              style={{ height: "78vh", overflowY: "auto" }}
            >
              {_.map(reportData, (item) => {
                return (
                  <List.Item
                    className="px-5 mb-2 bg-white"
                    actions={[
                      <Dropdown
                        placement="bottomLeft"
                        overlay={() => listDropDown(item)}
                        trigger={["click"]}
                      >
                        <div className="cursor-pointer">
                          <Icon size="small" type="Meatball" />
                        </div>
                      </Dropdown>,
                    ]}
                  >
                    <List.Item.Meta
                      className="items-center"
                      style={{ maxWidth: 350 }}
                      title={
                        <div className="flex items-center">
                          <Typography.Text strong>{item.title}</Typography.Text>
                        </div>
                      }
                    />
                    <Text>{`${moment(item?.start_date).format(
                      "MM/DD/YYYY"
                    )}-${moment(item?.end_date).format("MM/DD/YYYY")} `}</Text>
                    <div className="mx-5">
                      <Avatar.Group maxCount={3}>
                        {_.map(item.members, (member) => (
                          <Tooltip
                            title={member.email}
                            placement="top"
                            key={member._id}
                          >
                            <UserAvatar user={member} />
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </div>
                    <div style={{ width: "150px" }} className="mx-2">
                      {!_.isEmpty(item?.projects) &&
                      _.size(item?.projects) > 1 ? (
                        <Popover
                          placement="bottom"
                          content={
                            <div className="max-w-sm">
                              {_.map(item?.projects, (project) => (
                                <Tag
                                  color={
                                    _.isEmpty(project?.color) ||
                                    project?.color === "none"
                                      ? "#808080"
                                      : project?.color
                                  }
                                >
                                  {project?.title}
                                </Tag>
                              ))}
                            </div>
                          }
                        >
                          <div className="flex items-center">
                            <Tag
                              color={
                                _.isEmpty(item.projects[0]?.color) ||
                                item.projects[0]?.color === "none"
                                  ? "#808080"
                                  : item.projects[0]?.color
                              }
                              className="cursor-pointer mr-2"
                            >
                              {item.projects[0]?.title}
                            </Tag>
                            <CaretDownOutlined />
                          </div>
                        </Popover>
                      ) : (
                        !_.isEmpty(item.projects) && (
                          <div className="flex items-center">
                            <Tag
                              color={
                                _.isEmpty(item.projects[0]?.color) ||
                                item.projects[0]?.color === "none"
                                  ? "#808080"
                                  : item.projects[0]?.color
                              }
                              className="cursor-pointer mr-2"
                            >
                              {item.projects[0]?.title}
                            </Tag>
                          </div>
                        )
                      )}
                    </div>
                  </List.Item>
                );
              })}
            </List>
          </div>
        )}
      </Row>
      {exportModal && (
        <ReportModal
          callback={refetch}
          visible={exportModal}
          setVisible={setExportModal}
        />
      )}
    </div>
  );
};

export default Report;
