import React, { useEffect, useState } from "react";
import WinduLogo from "common/assets/mobile_icon.png";
import SandClock from "common/assets/sandClock.svg";
import UserAvatar from "components/UserAvatar";
import { Typography, Popover, Divider, Tag } from "antd";
import Timer from "components/Timer";
import { useTimer } from "contexts/timer-context";
import { useRecoilValue } from "recoil";
import { activeActivity } from "recoil/atoms/Timer/activity";
import TimeCounter from "components/TimeCounter";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { size, map } from "lodash";
import moment_tz from "moment-timezone";
import utility from "common/utility";

const { Text, Paragraph } = Typography;

const MaintenacePage = ({ user, showTimer }) => {
  const activity = useRecoilValue(activeActivity);
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const { time } = useTimer(); // global timer from context

  useEffect(() => {
    if (!activity.active) {
      document.title = `Windu`;
    } else {
      document.title = `${time} - Windu`;
    }
  }, [time]);

  const pauses = map(activity.data?.time?.paused, (pause) => pause?.total);
  return (
    <div>
      <div className="flex justify-between p-4 items-center">
        <img src={WinduLogo} alt="Windu" style={{ width: "5.5vw" }} />
        <UserAvatar user={user} size="large" />
      </div>
      <div className="flex flex-col items-center" style={{ marginTop: "20vh" }}>
        <img src={SandClock} alt="winduClock" />
        <Typography.Title level={5} className="mt-1.5">
          Hang on! We are under maintenance
        </Typography.Title>
        <Typography.Text>
          We shouldn't be too long.. Incase you need to track your time
        </Typography.Text>
        <div className="mt-2 flex items-center ">
          <Timer />
          {activity.active && (
            <>
              <Popover
                className="max-w-lg min-w-min"
                visible={showActivityDetails}
                title={
                  <div className="flex justify-between items-center">
                    <Text className="font-semibold text-base">
                      {activity.data?.title}
                    </Text>
                    <CloseOutlined
                      className="icon-small"
                      onClick={() => setShowActivityDetails(false)}
                    />
                  </div>
                }
                content={
                  <div
                    style={{ maxWidth: "500px", minWidth: "100px" }}
                    className="break-words"
                  >
                    <Paragraph className="text-justify">
                      {activity.data?.description}
                    </Paragraph>
                    <Tag
                      className="mt-2"
                      color={`${
                        activity.data.project.color &&
                        activity.data.project.color !== "none"
                          ? activity.data.project.color
                          : "#808080"
                      }`}
                    >
                      {activity?.data.project.title}
                    </Tag>
                    <Divider className="my-2" />
                    <div className="flex items-center">
                      {size(pauses) > 0 && (
                        <>
                          <Text>
                            {`Total time ${utility.calculateTimeTotal(pauses)}`}
                          </Text>
                          <Divider type="vertical" />
                        </>
                      )}

                      <Text>{`Session ${size(pauses) + 1} `}</Text>
                      <Divider type="vertical" />
                      <Text>
                        {`Started ${moment_tz(activity.data?.time.start)
                          .tz(user.timezone)
                          .fromNow()}`}
                      </Text>
                    </div>
                  </div>
                }
                trigger="hover"
                placement="rightBottom"
                arrowPointAtCenter
              >
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setShowActivityDetails(true)}
                >
                  <TimeCounter time={time} />
                  <InfoCircleOutlined className="icon-small ml-2" />
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenacePage;
