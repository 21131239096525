import React, { useState } from "react";
import {
  List,
  Dropdown,
  Menu,
  Popover,
  Tag,
  Typography,
  Tooltip,
  Badge,
  Popconfirm,
} from "antd";
import moment from "moment";
import styled from "styled-components";
import UserAvatar from "components/UserAvatar";
import { useMutation } from "@apollo/client";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  FlagFilled,
  UserOutlined,
  HolderOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { UPDATE_BACKLOG } from "graphql/mutations/Backlog/updateBacklog";
import { UPDATE_BACKLOG_GROUP } from "graphql/mutations/Backlog/updateBacklogGroup";
import { UPDATE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateBacklogActivity";
import { REMOVE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/removeBacklogActivity";
import { REMOVE_BACKLOG_GROUP } from "graphql/mutations/Backlog/removeBacklogGroup";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import CalendarPicker from "components/CalendarPicker";
import Tree, { mutateTree, moveItemOnTree } from "@atlaskit/tree";
import _ from "lodash";
import utility from "common/utility";
import useOpenActivityDrawer from "common/openActivityDrawer";
import styles from "./styles.module.css";
import EmptyBacklog from "common/assets/emptyBacklog.svg";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { useRecoilValue } from "recoil";
import AssigneSelectDropdown from "./AssigneSelectDropdown";

// This is the base value for all the spacing
// and the indentation of the nested items
const spacing = 8;

// The height is SUPER IMPORTANT!!!
// Without it you cannot drag items with children.
// The bottom padding is important for rearranging top-level items.
const TreeWrap = styled.div`
  height: calc(100vh - 235px);
  width: 100%;
  overflow: auto;
  padding: 16px;
`;

const { Text } = Typography;

const ListView = ({
  data,
  setData,
  setEditingItem,
  setcreateGroupModal,
  setcreateActivityModal,
  isFiltering,
  totalCount,
}) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [currentDraggingItem, setCurrentDraggingItem] = useState(null);
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);
  const workspace = useRecoilValue(workspaceSelected);

  const HeanderComponent = ({ paddingLeft }) => {
    return (
      <div style={{ paddingLeft: paddingLeft }} className={styles.headerDiv}>
        <List.Item
          className="px-2"
          style={{
            background: "#F8F8F8",
            border: "1px solid rgba(0, 0, 0, 0.06)",
          }}
          actions={[<div style={{ width: 20 }}></div>]}
        >
          <List.Item.Meta
            className="items-center"
            title={
              <div className="flex items-center ml-10">
                <Text className="text-base">Activity</Text>
              </div>
            }
          />
          <div
            className="w-3/5	flex justify-around items-center"
            style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <div style={{ width: "150px" }} className="mx-2">
              <Text className="text-base ">Assignee</Text>
            </div>

            <div style={{ width: "130px" }} className="mx-2">
              <Text className="text-base">Tags</Text>
            </div>
            <div style={{ width: "130px" }} className="mx-2">
              <Text className="text-base">Priority</Text>
            </div>
          </div>
        </List.Item>
      </div>
    );
  };

  const { openActivityDrawer } = useOpenActivityDrawer();

  const OptionDropdown = ({ item }) => {
    return (
      <Menu
        style={{ boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)" }}
        className="py-0"
      >
        <Menu.Item
          className="py-4 flex items-center"
          key="edit"
          icon={<EditOutlined className="icon-medium" />}
          onClick={() => {
            setEditingItem(item);
            setcreateActivityModal(true);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Popconfirm
          placement="topLeft"
          title={"Are you sure to remove this activity?"}
          onConfirm={() => onActivityRemove(item)}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item
            key="remove"
            className="py-4 flex items-center"
            icon={<DeleteOutlined className="icon-medium" />}
          >
            Remove Activity
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const OptionGroupDropdown = ({ item }) => {
    return (
      <Menu
        style={{ boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)" }}
        className="py-0"
      >
        <Menu.Item
          className="py-4 flex items-center"
          key="edit"
          onClick={() => {
            setEditingItem(item);
            setcreateGroupModal(true);
          }}
          icon={<EditOutlined className="icon-medium" />}
        >
          Edit
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Popconfirm
          placement="topLeft"
          title={"Are you sure to remove this group?"}
          onConfirm={() => onGroupRemove(item)}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item
            key="remove"
            className="py-4 flex items-center"
            icon={<DeleteOutlined className="icon-medium" />}
          >
            Remove Group
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const [updateBacklog] = useMutation(UPDATE_BACKLOG, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error backlog update`,
        "error",
        "topRight"
      );
    },
  });

  const [updateBacklogActivity] = useMutation(UPDATE_BACKLOG_ACTIVITY, {
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Activity updated successfully`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      utility.setNotification(
        "Error",
        `${toString(error.message)}`,
        "error",
        "topRight"
      );
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: id, view: "list" },
      },
    ],
  });

  const [removeBacklogActivity] = useMutation(REMOVE_BACKLOG_ACTIVITY, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Activity has been removed`,
        "success",
        "topRight"
      );
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: id, view: "list" },
      },
    ],
  });

  const [removeBacklogGroup] = useMutation(REMOVE_BACKLOG_GROUP, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove group`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Group has been removed`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: id, view: "list" },
      },
    ],
  });

  const [updateBacklogGroup] = useMutation(UPDATE_BACKLOG_GROUP, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error group update`,
        "error",
        "topRight"
      );
    },
  });

  const onActivityRemove = (item) => {
    const payload = {
      activityId: item.id,
      backlogId: data?.rootId,
    };

    const groups = _.filter(
      data.items,
      (item) => item.data.type === "backlog_group"
    );

    const subgroups = _.filter(
      data.items,
      (item) => item.data.type === "backlog_sub_group"
    );

    const belongsToGroup = _.find(groups, (group) =>
      _.includes(group.children, item.id)
    );

    const belongsToSubGroup = _.find(subgroups, (group) =>
      _.includes(group.children, item.id)
    );

    if (belongsToGroup || belongsToSubGroup) {
      // if belongs to a group or subgroup set the groupid
      payload.groupId = belongsToGroup?.id || belongsToSubGroup.id;
    }

    removeBacklogActivity({ variables: { input: { ...payload } } });
  };

  const onGroupRemove = (item) => {
    const payload = {
      groupId: item.id,
      backlogId: data?.rootId,
    };

    removeBacklogGroup({ variables: { input: { ...payload } } });
  };

  const updateActivityDate = ({ date, item }) => {
    const payload = {
      activityId: item.id,
      planned_date: moment.utc(date),
      backlogId: data?.rootId,
      title: item.data?.title,
      details: item.data?.details,
    };

    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  const updateActivityPriority = ({ priority, item }) => {
    const payload = {
      activityId: item.id,
      priority: Number(priority),
      backlogId: data?.rootId,
      title: item.data?.title,
      details: item.data?.details,
    };

    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  const updateAssignee = (assignee, item) => {
    const payload = {
      activityId: item.id,
      assigned: assignee ? assignee?._id : null,
      backlogId: data?.rootId,
      title: item.data?.title,
      details: item.data?.details,
    };

    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  function droppingAllowed(parentId) {
    const dropped = _.find(data.items, { id: parentId });
    const draggingItem = _.find(data.items, { id: currentDraggingItem });

    // skip dropping group into another subgroup
    if (
      dropped.data.type === "backlog_sub_group" &&
      draggingItem.data.type === "backlog_group"
    ) {
      utility.setNotification(
        "Warning",
        `Groups cannot be dropped inside of Subgroups`,
        "warning",
        "topRight"
      );
      return false;
    }

    // skip dropping sub group into another sub group
    if (
      dropped.data.type === "backlog_sub_group" &&
      draggingItem.data.type === "backlog_sub_group"
    ) {
      utility.setNotification(
        "Warning",
        `Subgroups cannot be dropped inside of Subgroups`,
        "warning",
        "topRight"
      );

      return false;
    }

    // skip dropping group with subgroups into another group
    if (
      dropped.data.type === "backlog_group" &&
      draggingItem.data.type === "backlog_group" &&
      _.size(draggingItem.children) > 0
    ) {
      // check if children from draggingItem

      const children = _.map(draggingItem.children, (child) =>
        _.find(data.items, { id: child })
      );

      // if the dragging element already have nested groups return false
      const hasGroups = _.filter(children, (child) =>
        ["backlog_group", "backlog_sub_group"].includes(child.data.type)
      );

      if (_.size(hasGroups) > 0) {
        utility.setNotification(
          "Warning",
          `Groups with nested Subgroups cannot be dropped inside of Subgroups`,
          "warning",
          "topRight"
        );
        return false;
      }
    }

    return dropped.container;
  }

  const selectPriority = (item) => {
    const currentPriority = item?.data?.priority ? item?.data?.priority : 0;
    return (
      <Menu
        onClick={(v) => updateActivityPriority({ priority: v.key, item })}
        selectedKeys={item?.data?.priority}
      >
        <Menu.Item
          key={0}
          style={{ background: currentPriority === 0 && "#F5F4FA" }}
          disabled={currentPriority === 0}
        >
          <FlagFilled style={{ color: "lightgrey" }} className="icon-medium" />
          <Text className="ml-2">No Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={3}
          style={{ background: currentPriority === 3 && "#F5F4FA" }}
          disabled={currentPriority === 3}
        >
          <FlagFilled style={{ color: "red" }} className="icon-medium" />
          <Text className="ml-2">High Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={2}
          disabled={currentPriority === 2}
          style={{ background: currentPriority === 2 && "#F5F4FA" }}
        >
          <FlagFilled style={{ color: "orange" }} className="icon-medium" />
          <Text className="ml-2">Medium Priority</Text>
        </Menu.Item>
        <Menu.Item
          key={1}
          style={{ background: currentPriority === 1 && "#F5F4FA" }}
          disabled={currentPriority === 1}
        >
          <FlagFilled style={{ color: "green" }} className="icon-medium" />
          <Text className="ml-2">Low Priority</Text>
        </Menu.Item>
      </Menu>
    );
  };

  React.useEffect(() => {
    const { manager, members, created_by } = workspace?.project;
    const allMembers = [...members, created_by];
    if (manager) {
      allMembers.push(manager);
    }
    const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");
    setFilterSearchUser(_.isEmpty(gatherMembers) ? [] : gatherMembers);
  }, [workspace]);

  const Activityitem = ({ item, provided }) => {
    return (
      <div className={styles.headerDiv}>
        <List.Item
          className="px-2 bg-white"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.06)",
          }}
          actions={[
            <Dropdown
              placement="bottomLeft"
              overlay={<OptionDropdown item={item} />}
              trigger={["click"]}
            >
              <div className="cursor-pointer">
                <MoreOutlined className="icon-medium" />
              </div>
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            className="items-center"
            avatar={
              //  manage the state of drag and drop
              <div {...provided.dragHandleProps}>
                {!isFiltering && <HolderOutlined className="icon-medium" />}
              </div>
            }
            title={
              <div className="flex items-center">
                <Typography.Text
                  onClick={() => openActivityDrawer(item?.data, true, true)}
                  className="cursor-pointer"
                >
                  {item?.data.title}
                </Typography.Text>
              </div>
            }
          />
          <div
            className="w-3/5 flex justify-around items-center"
            style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <div className="mx-2 w-1/4">
              <Dropdown
                trigger={["click"]}
                overlay={() => (
                  <AssigneSelectDropdown
                    filterSearchUser={filterSearchUser}
                    setValue={(v) => {
                      if (v?._id !== item?.data?.assigned?._id) {
                        updateAssignee(v, item);
                      }
                    }}
                    initialValue={filterSearchUser}
                    value={item.data.assigned}
                  />
                )}
              >
                {item.data.assigned ? (
                  <Tooltip title={item.data.assigned?.email}>
                    <>
                      <UserAvatar
                        size={35}
                        user={item.data.assigned}
                        className="cursor-pointer"
                      />
                      <Text className="ml-2">
                        {item.data.assigned?.full_name}
                      </Text>
                    </>
                  </Tooltip>
                ) : (
                  <div className="flex items-center">
                    <UserOutlined />
                    <Text className="ml-2">Unassigned</Text>
                  </div>
                )}
              </Dropdown>
            </div>

            <div style={{ width: "130px" }} className="mx-2">
              {!_.isEmpty(item?.data?.tags) && _.size(item?.data?.tags) > 1 ? (
                <Popover
                  placement="bottom"
                  content={
                    <div className="max-w-sm">
                      {_.map(item?.data?.tags, (tag) => (
                        <Tag color="purple">{tag}</Tag>
                      ))}
                    </div>
                  }
                >
                  <div className="flex items-center">
                    <Tag color="purple" className="cursor-pointer mr-2">
                      {item?.data?.tags[0]}
                    </Tag>
                    <CaretDownOutlined />
                  </div>
                </Popover>
              ) : (
                !_.isEmpty(item?.data?.tags) && (
                  <div className="flex items-center">
                    <Tag color="purple" className="cursor-pointer mr-2">
                      {item?.data?.tags[0]}
                    </Tag>
                  </div>
                )
              )}
            </div>
            <div style={{ width: "120px" }} className="mx-2">
              <Dropdown overlay={() => selectPriority(item)}>
                <div className="flex items-center">
                  <FlagFilled
                    style={{
                      color: utility.getPriority(item?.data?.priority).color,
                    }}
                    className="icon-medium"
                  />
                  <Text className="ml-2">
                    {utility.getPriority(item?.data?.priority).name}
                  </Text>
                  <DownOutlined className="ml-1" />
                </div>
              </Dropdown>
            </div>
          </div>
        </List.Item>
      </div>
    );
  };

  const GroupItem = ({ item, provided }) => {
    return (
      <>
        <List.Item
          style={{
            background: "#D8DDE6",
            border: "1px solid rgba(0, 0, 0, 0.06)",
          }}
          className="w-full px-2 bg-white"
          actions={[
            <Dropdown
              placement="bottomLeft"
              overlay={<OptionGroupDropdown item={item} />}
              trigger={["click"]}
            >
              <div className="cursor-pointer">
                <MoreOutlined className="icon-medium" />
              </div>
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            className="items-center"
            avatar={
              <div className="flex items-center">
                <div {...provided.dragHandleProps}>
                  {!isFiltering && <HolderOutlined className="icon-medium" />}
                </div>
              </div>
            }
            title={
              <div className="flex items-center">
                <Typography.Text className="mr-3">
                  {item?.data.title}
                </Typography.Text>
                {_.size(item.children) > 0 && (
                  <Badge
                    count={_.size(item.children)}
                    style={{ backgroundColor: "#f0f0f0", color: "black" }}
                  />
                )}

                {item.hasChildren && (
                  <div
                    className="ml-3 cursor-pointer flex items-center"
                    style={{ color: "#808080" }}
                    onClick={() =>
                      item.isExpanded ? onCollapse(item.id) : onExpand(item.id)
                    }
                  >
                    {item.isExpanded ? (
                      <CaretDownOutlined className="icon-medium" />
                    ) : (
                      <CaretRightOutlined className="icon-medium" />
                    )}
                  </div>
                )}
              </div>
            }
          />
          <div>
            {item.data?.start_date && (
              <Typography.Text className="ml-2">
                {moment(item.data?.start_date).format("MMM Do YY")}
              </Typography.Text>
            )}

            {item.data?.start_date && (
              <Typography.Text className="ml-2">
                -{""} {moment(item.data?.end_date).format("MMM Do YY")}
              </Typography.Text>
            )}
          </div>
        </List.Item>
        {item.isExpanded && <HeanderComponent paddingLeft={56} />}
      </>
    );
  };

  const renderItem = ({ item, provided }) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        {item.data.type === "backlog_activity" && (
          <Activityitem item={item} provided={provided} />
        )}
        {item.data.type === "backlog_group" && (
          <GroupItem item={item} provided={provided} />
        )}
        {item.data.type === "backlog_sub_group" && (
          <GroupItem item={item} provided={provided} />
        )}
        {provided.placeholder}
      </div>
    );
  };

  const onExpand = (itemId) => {
    localStorage.setItem(itemId, true);
    setData(mutateTree(data, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId) => {
    localStorage.setItem(itemId, false);
    setData(mutateTree(data, itemId, { isExpanded: false }));
  };

  const onDragStart = (itemId) => {
    setCurrentDraggingItem(itemId);
  };

  const parseData = ({ children }) => {
    const format = _.map(children, (element) => {
      const childInfo = _.find(data.items, (item) => item.id === element);
      return {
        itemId: childInfo.id,
        itemType:
          childInfo.data.type === "backlog_sub_group"
            ? "backlog_group"
            : childInfo.data.type, // keep the correct ref in the DB
      };
    });

    return format;
  };

  const onDragEnd = (source, destination) => {
    if (!destination) return;

    if (!droppingAllowed(destination.parentId)) {
      console.log("Dropping into this element is not allowed");
      return;
    }

    const newTree = moveItemOnTree(data, source, destination);

    const destinationInfo = _.find(
      newTree.items,
      (item) => item.id === destination.parentId
    );

    const sourceInfo = _.find(
      newTree.items,
      (item) => item.id === source.parentId
    );

    const selectedInfo = _.find(
      newTree.items,
      (item) => item.id === currentDraggingItem
    );

    // moving from group to subgroup
    if (
      destinationInfo.data.type === "backlog_sub_group" &&
      sourceInfo.data.type === "backlog_group"
    ) {
      // #1 call to add the activity to the new group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: destination.parentId, // destination group id
            backlogId: newTree.rootId,
            items: parseData({ children: destinationInfo?.children }),
          },
        },
      });

      // #2 call to remove the activity from old group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: source.parentId, // old group id
            backlogId: newTree.rootId,
            items: parseData({ children: sourceInfo?.children }),
          },
        },
      });

      setData(newTree);
      return;
    } else if (
      destinationInfo.data.type === "backlog_group" &&
      sourceInfo.data.type === "backlog_sub_group"
    ) {
      // #1 call to add the activity to the new group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: destination.parentId, // destination group id
            backlogId: newTree.rootId,
            items: parseData({ children: destinationInfo?.children }),
          },
        },
      });

      // #2 call to remove the activity from old group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: source.parentId, // old group id
            backlogId: newTree.rootId,
            items: parseData({ children: sourceInfo?.children }),
          },
        },
      });

      setData(newTree);
      return;
    }

    // moving between groups or subgroups
    if (
      (destinationInfo.data.type === "backlog_group" &&
        sourceInfo.data.type === "backlog_group") ||
      (destinationInfo.data.type === "backlog_sub_group" &&
        sourceInfo.data.type === "backlog_sub_group")
    ) {
      // #1 call to add the activity to the new group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: destination.parentId, // destination group id
            backlogId: newTree.rootId,
            items: parseData({ children: destinationInfo?.children }),
          },
        },
      });

      // #2 call to remove the activity from old group
      updateBacklogGroup({
        variables: {
          input: {
            groupId: source.parentId, // old group id
            backlogId: newTree.rootId,
            items: parseData({ children: sourceInfo?.children }),
          },
        },
      });

      setData(newTree);
      return;
    }

    if (
      ["backlog_group", "backlog_sub_group"].includes(
        destinationInfo.data.type
      ) &&
      sourceInfo.data.type === "root"
    ) {
      // dropping inside of the group or subgroup from root
      updateBacklogGroup({
        variables: {
          input: {
            groupId: destination.parentId,
            backlogId: newTree.rootId,
            items: parseData({ children: destinationInfo?.children }),
          },
        },
      });

      if (
        selectedInfo.data.type === "backlog_group" &&
        destinationInfo.data.type === "backlog_group"
      ) {
        selectedInfo.data.type = "backlog_sub_group";
        newTree.items[selectedInfo.id] = selectedInfo;
      }
    } else if (
      ["backlog_group", "backlog_sub_group"].includes(sourceInfo.data.type) &&
      destinationInfo.data.type === "root"
    ) {
      // dropping outside of the group or subgroup to root
      updateBacklogGroup({
        variables: {
          input: {
            groupId: source.parentId,
            backlogId: newTree.rootId,
            items: parseData({ children: sourceInfo?.children }),
          },
        },
      });

      if (
        selectedInfo.data.type === "backlog_sub_group" &&
        destinationInfo.data.type === "root"
      ) {
        selectedInfo.data.type = "backlog_group";
        newTree.items[selectedInfo.id] = selectedInfo;
      }
    }

    // prepare data to send new order to the backend
    const root = _.find(newTree.items, (item) => item.root);

    updateBacklog({
      variables: {
        input: {
          backlogId: newTree.rootId,
          items: parseData({ children: root?.children }),
          view: "list",
        },
      },
    });

    setData(newTree);
  };

  if (totalCount === 0) {
    return (
      <div className="w-full h-full flex justify-between items-center flex-col">
        <img src={EmptyBacklog} />
        <div className=" w-2/3 mt-8 flex flex-col justify-center text-center">
          <Text className="text-base" strong>
            Create your first backlog activity
          </Text>
          <Text className="mt-2">
            Streamline team collaboration and project management by creating
            your backlog activities. Assign tasks, track progress, and achieve
            your goals together!"
          </Text>
        </div>
      </div>
    );
  }

  return (
    <TreeWrap>
      <HeanderComponent />
      <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.06)" }}>
        <Tree
          tree={data}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          offsetPerLevel={spacing * 7}
          isDragEnabled
          isNestingEnabled
        />
      </div>
    </TreeWrap>
  );
};

export default ListView;
