import { Badge, Typography } from "antd";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import React from "react";
import _ from "lodash";
import styles from "./styles.module.css";

const getConnectionDescription = (name) => {
  switch (name) {
    case "meetings":
      return (
        <Typography.Text className=" text-base">
          Connect your google calendar and track all your meetings
        </Typography.Text>
      );
    case "todoist":
      return (
        <Typography.Text className=" text-base">
          Connect Todoist and start, pause and stop trackable tasks from your
          list.
        </Typography.Text>
      );
    case "jira cloud":
      return (
        <Typography.Text className=" text-base">
          Connect Jira and start, pause and stop trackable issues from your
          sprints.
        </Typography.Text>
      );
    case "monday":
      return (
        <Typography.Text className=" text-base">
          Connect Monday and start, pause and stop trackable tasks from your
          boards.
        </Typography.Text>
      );
    case "trello":
      return (
        <Typography.Text className=" text-base">
          Connect Trello and start, pause and stop trackable tasks from your
          boards.
        </Typography.Text>
      );
    case "github":
      return (
        <Typography.Text className=" text-base">
          Connect Github to monitor and track pull requests
        </Typography.Text>
      );
    case "miro":
      return (
        <Typography.Text className=" text-base">
          Connect and embed your board to Windu
        </Typography.Text>
      );
    default:
      break;
  }
};

const AppCard = ({ data, onClick }) => {
  return (
    <div
      className="bg-white rounded max-w-xs p-8 mr-10 mb-6 shadow cursor-pointer h-52 flex flex-col justify-between"
      onClick={onClick}
    >
      <div>
        <div className="flex items-center justify-between">
          <Typography.Text className="capitalize text-lg" strong>
            {data.label}
          </Typography.Text>
          <div className={styles.integrationLogo}>
            {getIntegrationLogo(data.label?.toLowerCase())}
          </div>
        </div>
        <div className="mt-2">
          {getConnectionDescription(data.label?.toLowerCase())}
        </div>
      </div>

      <div className="flex items-center mt-2">
        <Typography.Text>{`${data?.no_of_connection} Accounts`}</Typography.Text>
      </div>
    </div>
  );
};

export default AppCard;
