import gql from "graphql-tag";

export const WORKSPACE_CHANGE = gql`
  subscription workspaceChange {
    workspaceChange {
      workspaceId
      action
    }
  }
`;
