import gql from "graphql-tag";

export const READ_MESSAGE = gql`
  subscription markReadMessages {
    markReadMessages {
      chatId
      user
      markedAsRead
      workspaceId
    }
  }
`;
