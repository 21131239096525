import React from "react";
import { Avatar, Tooltip, Table, Typography, Tag } from "antd";
import AndIcon from "@ant-design/icons";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";
import Loader from "components/Loader";
import icons from "common/icons";
import useOpenActivityDrawer from "common/openActivityDrawer";
import utility from "common/utility";

const { Link, Text } = Typography;

const ActivityTable = ({
  activities,
  loading,
  rowSelectionIndicator,
  activitiesRemove,
  setActivitiesRemove,
}) => {
  const { openActivityDrawer } = useOpenActivityDrawer();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, row) => {
        return (
          <Link onClick={() => openActivityDrawer(row, true)}>{value}</Link>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: ({ status }) => {
        if (status === 1)
          return (
            <Tooltip title={"Running"}>
              <AndIcon className="icon" component={icons["Clock"]} />
            </Tooltip>
          );

        if (status === 2)
          return (
            <Tooltip title={"On pause"}>
              <AndIcon className="icon" component={icons["PauseColor"]} />
            </Tooltip>
          );

        if (status === 3 || status === 6)
          return (
            <Tooltip title={"Finished"}>
              <AndIcon className="icon" component={icons["CheckMark"]} />
            </Tooltip>
          );
        if (status === 4)
          return (
            <Tooltip title={"Pending handoff"}>
              <AndIcon className="icon" component={icons["PauseColor"]} />
            </Tooltip>
          );
      },
    },
    {
      title: "Contributors",
      key: "created_by",
      dataIndex: "created_by",
      render: (value, item) => {
        if (item.status === 6 || item.status === 5 || item.status === 4) {
          const users = _.uniqBy(
            _.map(item.handoff, (handoff) => handoff.user),
            "_id"
          );
          return (
            <Avatar.Group maxCount={3}>
              {_.map(users, (member) => (
                <Tooltip title={member.email} placement="top" key={member._id}>
                  <UserAvatar user={member} />
                </Tooltip>
              ))}
            </Avatar.Group>
          );
        } else {
          const created_by = {
            full_name: value?.full_name,
            email: value?.email,
            avatar: value?.avatar,
          };
          return (
            <Tooltip title={value?.email}>
              <UserAvatar user={created_by} />
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Total time",
      key: "time",
      render: (__, row) => {
        // check if total time field exists
        if (row?.status === 6) {
          return (
            <Text>
              {utility.calculateTimeTotal(
                row.handoff.map((handoff) => handoff.time?.total_time)
              )}
            </Text>
          );
        } else if (row?.time?.total_time) {
          return <Text>{row?.time?.total_time}</Text>;
        }
      },
    },
    {
      title: "Tags",
      key: "tags",
      width: 200,
      render: (__, row) => {
        // check if total time field exists
        return (
          <div>
            {_.map(row?.tags, (tag) => (
              <Tag color="purple" className="cursor-pointer mr-2">
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
    },
  ];

  const tableLoading = {
    spinning: loading,
    indicator: <Loader size="large" />,
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: activitiesRemove,
    onSelect: (__, ___, selectedRows) => {
      const ids = _.map(selectedRows, (item) => item._id);
      setActivitiesRemove(ids);
    },
    onSelectAll: (__, selectedRows) => {
      const ids = _.map(selectedRows, (item) => item._id);
      setActivitiesRemove(ids);
    },
  };

  const rowSelectionProps = rowSelectionIndicator
    ? {
        preserveSelectedRowKeys: true,
        type: "checkbox",
        ...rowSelection,
      }
    : null;

  return (
    <Table
      columns={columns}
      dataSource={activities}
      rowKey="_id"
      loading={tableLoading}
      pagination={false}
      rowSelection={rowSelectionProps}
    />
  );
};

export default ActivityTable;
