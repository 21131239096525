import React, { useState } from "react";
import { Typography, Button, Pagination, Select, DatePicker } from "antd";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useRecoilValue } from "recoil";
import queryString from "query-string";
import InvoiceList from "./InvoiceList";
import moment from "moment";
import InvoiceGrid from "./InvoiceGrid";
import { GET_INVOICES } from "graphql/queries/invoice/getInvoices";
import { PREVIEW_INVOICE } from "graphql/queries/invoice/previewInvoice";
import { DELETE_INVOICE } from "graphql/mutations/invoice/deleteInvoice";
import { UPDATE_INVOICE_STATUS } from "graphql/mutations/invoice/updateInvoiceStatus";
import { CREATE_INVOICE_COPY } from "graphql/mutations/invoice/createInvoiceCopy";
import { useHistory, useLocation } from "react-router";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import utility from "common/utility";
import Loader from "components/Loader";
import { LeftOutlined } from "@ant-design/icons";

const { Text } = Typography;

const InvoiceHistoryPage = () => {
  const [invoiceView, setinvoiceView] = useState("list");
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { invoiceId, query, id } = queryString.parse(location.search);
  const workspace = useRecoilValue(workspaceSelected);
  const hasClient = !_.isNil(workspace?.project?.client);
  const [queryParameters, setQueryParameters] = React.useState(
    JSON.parse(query)
  );

  const { loading, data } = useQuery(GET_INVOICES, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
  });

  const [previewInvoice, { loading: loadingPreview, data: html }] =
    useLazyQuery(PREVIEW_INVOICE, {
      fetchPolicy: "cache-and-network",
    });

  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Invoice deleted successfully",
        `Invoice has been deleted`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: { input: queryParameters },
      },
    ],
  });

  const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS, {
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Invoice updated successfully",
        `Invoice has been updated`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: { input: queryParameters },
      },
    ],
  });

  const [createInvoiceCopy] = useMutation(CREATE_INVOICE_COPY, {
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Invoice created successfully",
        `Invoice has been created`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: { input: queryParameters },
      },
    ],
  });

  const handleSelectInvoice = ({ invoiceId }) => {
    history.push({
      search: queryString.stringify({
        id,
        invoice: "history",
        invoiceId: invoiceId,
        query: JSON.stringify(queryParameters),
      }),
    });
  };

  const handleDeleteInvoice = ({ invoiceId }) => {
    deleteInvoice({
      variables: {
        invoiceId,
        workspaceId: workspace?._id,
      },
    });
  };

  const handleDownload = ({ invoiceURL }) => {
    utility.donwloadHanlder({ url: invoiceURL });
  };

  const handleUpdateInvoiceStatus = ({ status, invoiceId }) => {
    updateInvoiceStatus({
      variables: {
        input: {
          invoiceId,
          workspaceId: workspace?._id,
          status,
        },
      },
    });
  };

  const handleCreateCopy = ({ invoiceId }) => {
    createInvoiceCopy({
      variables: {
        invoiceId,
        workspaceId: workspace?._id,
      },
    });
  };

  const handleFilter = (value) => {
    setQueryParameters({
      ...queryParameters,
      status: value,
    });
  };

  const handleSelectedDates = (data) => {
    const startDate = data[0]?.format();
    const endDate = data[1]?.format();

    setQueryParameters({
      ...queryParameters,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const createInvoiceHandler = () => {
    history.push({
      search: queryString.stringify({
        id: id,
        invoice: "createinvoice",
      }),
    });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const listLoading = {
    spinning: loading,
    indicator: <Loader size="large" />,
  };

  React.useEffect(() => {
    if (invoiceId) {
      setInvoiceSelected(invoiceId);
      previewInvoice({
        variables: {
          invoiceId,
          workspaceId: workspace?._id,
        },
      });
    }
  }, [invoiceId]);

  const backHandler = () => {
    history.push({
      search: queryString.stringify({
        id,
      }),
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div className="flex justify-between items-center px-3">
        <div className="flex">
          <div className="flex items-center flex-col">
            <div className="flex items-center">
              <Button
                onClick={backHandler}
                type="text"
                style={{ color: "#3C2E94" }}
                className="text-base flex items-center mr-0 px-2"
              >
                <LeftOutlined /> Invoice
              </Button>
              /
              <Typography className="text-base mr-0 px-2">
                Invoice History
              </Typography>
            </div>
            <Text>{data?.getInvoices?.pagination?.total_data} documents</Text>
          </div>

          {/*           <div className="flex rounded-3xl p-1 px-1 bg-gray-100 ml-6">
            <AppstoreOutlined
              className={cx("p-2 pr-5", {
                "bg-white  rounded-3xl": invoiceView === "grid",
              })}
              style={{ fontSize: "20px" }}
              onClick={() => setinvoiceView("grid")}
            />
            <MenuOutlined
              className={cx("p-2 pl-5", {
                "bg-white rounded-3xl": invoiceView === "list",
              })}
              style={{ fontSize: "20px" }}
              onClick={() => setinvoiceView("list")}
            />
          </div> */}
        </div>
        <div className="flex items-center">
          <div className="flex items-start ml-16">
            <DatePicker.RangePicker
              size="medium"
              onChange={handleSelectedDates}
              defaultValue={[
                moment(moment().subtract(1, "month").toDate(), "YYYY/MM/DD"),
                moment(moment(), "YYYY/MM/DD"),
              ]}
              allowClear={false}
            />
          </div>
          <div className="mx-6 w-24">
            <Select
              placeholder="Filter By"
              value={queryParameters.status}
              onChange={handleFilter}
            >
              <Select.Option value="PAID">Paid</Select.Option>
              <Select.Option value="PENDING">Pending</Select.Option>
              <Select.Option value="DRAFT">Draft</Select.Option>
              <Select.Option value="">All Invoices</Select.Option>
            </Select>
          </div>
          <div className="pl-4">
            <Button
              type="primary"
              size="large"
              onClick={createInvoiceHandler}
              disabled={!hasClient}
            >
              Create invoice
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {invoiceView === "grid" ? (
          <InvoiceGrid
            data="invoice data"
            invoiceId={invoiceId}
            query={query}
          />
        ) : (
          <InvoiceList
            invoiceId={invoiceSelected}
            loading={listLoading}
            data={data}
            handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
            handleDeleteInvoice={handleDeleteInvoice}
            handleDownloadInvoice={handleDownload}
            handleCreateCopy={handleCreateCopy}
            handleSelectInvoice={handleSelectInvoice}
            invoiceHtml={html}
            loadingPreview={loadingPreview}
          />
        )}
        {!loading && (
          <div className="mt-5 flex justify-center">
            <Pagination
              className="my-4 text-center"
              current={data?.getInvoices?.pagination?.current_page}
              onChange={onPageChange}
              total={data?.getInvoices?.pagination?.total_data || 0}
              pageSize={queryParameters.size}
              showTotal={(total, range) =>
                `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceHistoryPage;
