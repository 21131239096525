import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Pagination,
  Result,
  Alert,
} from "antd";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { debounce, toString, get } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { useQuery, useMutation } from "@apollo/client";
import { instanceOf } from "prop-types";
import { GET_MEMBERS } from "graphql/queries/member/getMembers";
import { REMOVE_PROJECT_MEMBER } from "graphql/mutations/removeProjectMember";
import MemberTable from "components/MemberTable";
import AddMemberModal from "components/Modals/AddMember";
import ProjectSelect from "components/ProjectSelect";
import { userSession } from "recoil/atoms/User/UserSession";
import utility from "common/utility";

const { Title } = Typography;

const sizePerPage = 5;
const initialOffset = 0;

const Members = () => {
  const history = useHistory();
  const { projectId } = queryString.parse(history.location.search);
  const [user] = useRecoilState(userSession);
  const [projectSelected, setProjectSelected] = useState({});
  const [isInviteMemberVisible, setIsInviteMemberVisible] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: "",
    project: projectId || "",
  });

  const showInfo = sessionStorage.getItem("membersInfo");

  const { loading, data, error } = useQuery(GET_MEMBERS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
  });

  const [removeProjectMember] = useMutation(REMOVE_PROJECT_MEMBER, {
    onCompleted: ({ removeProjectMember }) => {
      utility.setNotification(
        "Member removed successfully",
        `Member of ${removeProjectMember.title} has been removed`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_MEMBERS,
        variables: { input: queryParameters },
      },
    ],
  });

  const onRemoveMember = (memberId) => {
    removeProjectMember({
      variables: {
        input: { projectId: projectSelected.value, member: memberId },
      },
    });
  };

  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const onClearUrl = () => {
    if (projectId) {
      history.push({
        pathname: "/people",
        search: queryString.stringify({}),
      });
    }
  };

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  if (error)
    return (
      <Result
        status="error"
        title="Fetching Failed"
        subTitle={toString(error)}
        extra={[<Button key="buy">Try Again</Button>]}
      />
    );

  const addMemberDisabled =
    projectSelected?.manager === user._id ||
    projectSelected?.owner === user._id;

  return (
    <>
      <Row className="mb-6">
        <Col xs={20}>
          <Title level={4}>Members List</Title>
        </Col>
        {queryParameters.project && projectSelected && (
          <Col xs={4} className="text-right">
            <Button
              size="large"
              onClick={() => setIsInviteMemberVisible(true)}
              key="submit"
              type="primary"
              disabled={!addMemberDisabled}
            >
              Add member
            </Button>
          </Col>
        )}
      </Row>
      <Row className="mb-6">
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Input.Search
            className="w-4/6 mr-3"
            placeholder="Search Members"
            size="large"
            allowClear
            onChange={({ target }) => delayedQuery(target.value)}
            onSearch={onSearch}
          />
        </Col>
        <Col
          xxl={{ span: 7, offset: 5 }}
          xl={{ span: 10, offset: 2 }}
          lg={{ span: 11, offset: 1 }}
          md={12}
          sm={12}
          className="flex items-center justify-end"
        >
          <Title level={5} className="mr-3">
            Filter by:
          </Title>
          <ProjectSelect
            onChange={(value, name) => {
              onClearUrl();
              setProjectSelected(name);
              setQueryParameters({ ...queryParameters, project: value });
            }}
            onClear={() =>
              setQueryParameters({ ...queryParameters, project: null })
            }
            onCurrentSelected={(value) => setProjectSelected(value)}
            value={queryParameters.project}
            placeholder={"Select a project"}
            initialSearch={projectId}
          />
        </Col>
      </Row>
      {showInfo !== "false" && (
        <Row>
          <Col xs={24}>
            <Alert
              className="mb-4"
              message="Important!"
              description="Projects filter can help you  to filter members by a specific project and add a new member or remove it from the project selected."
              type="info"
              closable
              showIcon
              onClose={() => sessionStorage.setItem("membersInfo", false)}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24}>
          <MemberTable
            members={data?.getMembers?.data}
            onRemove={onRemoveMember}
            loading={loading}
            projectSelected={projectSelected}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Pagination
            className="my-3 text-center"
            current={data?.getMembers?.pagination?.current_page}
            onChange={onPageChange}
            total={data?.getMembers?.pagination?.total_data || 0}
            pageSize={queryParameters.size}
            showSizeChanger
            pageSizeOptions={["5", "10", "20", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
            }
            showQuickJumper={true}
          />
        </Col>
      </Row>

      <AddMemberModal
        projectSelected={projectSelected}
        isVisible={isInviteMemberVisible}
        onClose={() => setIsInviteMemberVisible(false)}
      />
    </>
  );
};

Members.propTypes = {
  client: instanceOf(Object),
};

export default withApollo(Members);
