import React from "react";
import { Divider, Input, Button, Form } from "antd";
import * as yup from "yup";
import styles from "./styles.module.css";

const initialValues = {
  status: "",
  help: "",
  valid: true,
};

const CreateSelectOption = ({
  onClick = () => {},
  onChange = () => {},
  value = "",
  placeholder = "",
}) => {
  const [validation, setValidation] = React.useState(initialValues);

  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  React.useEffect(() => {
    schema
      .isValid({
        email: value,
      })
      .then((valid) => {
        if (valid) {
          setValidation(initialValues);
        } else {
          setValidation({
            status: "error",
            help: "Please enter a valid email",
            valid,
          });
        }
      });
  }, [value]);

  return (
    <>
      <Divider className={styles.divider} />
      <div className={styles.inputContainer}>
        <Form.Item
          help={validation.help}
          validateStatus={validation.status}
          className="w-full mb-0"
        >
          <Input
            placeholder={placeholder}
            onChange={({ target: { value } }) => onChange(value)}
            value={value}
          />
        </Form.Item>
        <Button
          className={styles.actionContainer}
          onClick={() => {
            if (validation.valid) onClick();
          }}
          type="primary"
        >
          add
        </Button>
      </div>
    </>
  );
};

export default CreateSelectOption;
