import gql from "graphql-tag";

export const STOP_TODOIST_ACTIVITY = gql`
  mutation ($input: stopTodoistActivityInput) {
    stopTodoistActivity(input: $input) {
      _id
      title
    }
  }
`;
