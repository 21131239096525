import gql from "graphql-tag";

export const UPDATE_JIRA = gql`
  mutation($input: updateJiraInput) {
    updateJira(input: $input) {
      user {
        email
      }
      server_url
      server_name
      sprint_field
      sync {
        jira_project
        windu_project
        column_pull
        column_push_start {
          status
          transition
        }
        column_push_finished
      }
    }
  }
`;
