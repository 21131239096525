import { Row, Button, Divider, Badge, Card, Typography } from "antd";
import SelectProjects from "components/Fields/SelectProjects";
import { AntSelect } from "components/FormikCustomInputs";
import React from "react";
import RemoveSync from "../common/RemoveSync";
import { Field, FieldArray, useFormikContext } from "formik";
import AddSync from "../common/AddSync";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_MONDAY } from "graphql/mutations/Monday/updateMonday";
import { GET_MONDAY_COLUMNS } from "graphql/queries/Monday/getMondayColumns";
import utility from "common/utility";
import Loader from "components/Loader";

const Setting = ({ mondayBoard, workspaceId, boardLoading }) => {
  const { values, setFieldValue } = useFormikContext();
  const [columnsData, setColumnsData] = React.useState();
  const [selectedColumn, setSelectedColumn] = React.useState([]);
  const { data: mondayColumnsData, refetch } = useQuery(GET_MONDAY_COLUMNS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: workspaceId } },
    onCompleted: ({ getMondayColumns }) => {
      const statusData = getMondayColumns.map((item) => {
        const columns = item.columns.filter((item) => item.type === "color");
        return {
          ...item,
          columns,
        };
      });

      const mondayColumns = _.map(statusData, (item) => {
        const parsedCoulmns = _.map(item.columns, (item) => {
          return {
            data: JSON.parse(item.settings_str),
            id: item.id,
            title: item.title,
          };
        });
        return {
          ...item,
          columns: parsedCoulmns,
        };
      });

      const columns = _.map(mondayColumns, (item) => {
        const result = _.map(item.columns, (item) => {
          const columnsName = _.map(item.data.labels, (item) => ({
            label: item,
            value: item,
          }));
          const columnsColor = _.map(item.data.labels_colors, (item) => ({
            color: item.color,
          }));

          return {
            id: item.id,
            title: item.title,
            columnsData: columnsName.map((item, i) =>
              Object.assign({}, item, columnsColor[i])
            ),
          };
        });
        return {
          ...item,
          columns: result,
        };
      });
      setColumnsData(columns);
    },
    onError: (error) => {
      console.log(error);
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [updateMonday, { loading }] = useMutation(UPDATE_MONDAY, {
    onCompleted: (data) => {
      refetch();
    },
  });

  React.useEffect(() => {
    updateMonday({
      variables: {
        input: {
          workspace: workspaceId,
          sync: values.settings.sync,
        },
      },
    });
  }, [values?.settings]);

  const handleChange = (v, index) => {
    setFieldValue(`settings.sync[${index}]`, {
      ...values?.settings?.sync[index],
      monday_board: v,
    });
  };

  return (
    <div>
      <FieldArray
        className="m-0"
        name="settings.sync"
        render={(arrayHelpers) => {
          const sync = values?.settings?.sync;
          const isGreaterThanZero = sync?.length > 0;
          return (
            <div>
              {sync && isGreaterThanZero ? (
                sync.map((e, index) => (
                  <div>
                    <div className="flex items-center">
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.monday_board`}
                        label="Select the board"
                        placeholder="Project A"
                        selectOptions={mondayBoard}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handleChange(v, index)}
                      />
                      <Divider
                        style={{
                          width: 15,
                          minWidth: 15,
                          borderColor: "#F5A623",
                          marginTop: 60,
                        }}
                        dashed
                      />

                      <SelectProjects
                        size={1000}
                        name={`settings.sync.${index}.windu_project`}
                        label="Select a Project"
                        placeholder="Project B"
                      />
                      <Button
                        style={{
                          fontSize: 22,
                          marginTop: "15px",
                          color: "#644ACB",
                        }}
                        icon={<RemoveSync />}
                        type="link"
                        onClick={() => arrayHelpers.remove(index)}
                      ></Button>
                    </div>
                    <Typography.Text strong>Select Column</Typography.Text>
                    <div className="flex overflow-x-auto">
                      {_.map(columnsData, (item) => {
                        if (
                          item.id === values.settings.sync[index]?.monday_board
                        ) {
                          return _.map(item.columns, (item) => {
                            return (
                              <Card
                                style={{
                                  boxShadow: "none",
                                  minWidth: 220,
                                  marginRight: 12,
                                  minHeight: 160,
                                  border:
                                    values.settings.sync[index]
                                      .monday_status_column_id === item.id &&
                                    "4px solid black",
                                }}
                                bodyStyle={{ padding: 12 }}
                                onClick={() => {
                                  const updatedColumns = [...selectedColumn];
                                  updatedColumns[index] = item;
                                  setSelectedColumn(updatedColumns);

                                  setFieldValue(
                                    `settings.sync.${index}.monday_status_column_id`,
                                    item.id
                                  );
                                }}
                              >
                                <Typography.Text strong>
                                  {item.title}
                                </Typography.Text>
                                <div>
                                  {_.map(item.columnsData, (item) => {
                                    return (
                                      <>
                                        {item.label && (
                                          <>
                                            <Badge
                                              color={item.color}
                                              style={{ marginRight: 10 }}
                                              text={item.label}
                                            />
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </Card>
                            );
                          });
                        }
                      })}
                    </div>

                    {!_.isEmpty(values?.settings?.sync[index]?.monday_board) &&
                      !_.isEmpty(
                        values?.settings?.sync[index]?.windu_project
                      ) && (
                        <>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.status_pull`}
                              label="Column to Pull Activities from"
                              placeholder="Select a Column"
                              selectOptions={selectedColumn[index]?.columnsData}
                              inputType="select"
                              hasFeedback
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                            />
                          </Row>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.status_push_start`}
                              label="Column to Push Started Activities"
                              placeholder="Select a Column"
                              selectOptions={selectedColumn[index]?.columnsData}
                              inputType="select"
                              hasFeedback
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                            />
                          </Row>
                          <Row>
                            <Field
                              className="m-0"
                              component={AntSelect}
                              name={`settings.sync.${index}.status_push_finished`}
                              label="Column to Push Finished Activities"
                              placeholder="Select a Column"
                              selectOptions={selectedColumn[index]?.columnsData}
                              inputType="select"
                              hasFeedback
                              required={true}
                              size="large"
                              filterOption={false}
                              getPopupContainer={(node) => node.parentNode}
                            />
                          </Row>
                        </>
                      )}
                  </div>
                ))
              ) : (
                <div>
                  <div className="flex items-center">
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].monday_board`}
                      label="Select the board"
                      placeholder="Project A"
                      selectOptions={mondayBoard}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handleChange(v, 0)}
                    />
                    <Divider
                      style={{
                        width: 15,
                        minWidth: 15,
                        borderColor: "#F5A623",
                        marginTop: 60,
                      }}
                      dashed
                    />

                    <SelectProjects
                      size={1000}
                      name={`settings.sync[0]?.windu_project`}
                      label="Select a Project"
                      placeholder="Project B"
                    />
                  </div>
                  <Typography.Text strong>Select Column</Typography.Text>
                  <div className="flex overflow-x-auto">
                    {_.map(columnsData, (item) => {
                      if (item.id === values.settings.sync[0]?.monday_board) {
                        return _.map(item.columns, (item) => {
                          return (
                            <Card
                              style={{
                                boxShadow: "none",
                                minWidth: 220,
                                marginRight: 12,
                                minHeight: 160,
                                border:
                                  values.settings.sync[0]
                                    .monday_status_column_id === item.id &&
                                  "4px solid black",
                              }}
                              bodyStyle={{ padding: 12 }}
                              onClick={() => {
                                const updatedColumns = [...selectedColumn];
                                updatedColumns[0] = item;
                                setSelectedColumn(updatedColumns);

                                setFieldValue(
                                  `settings.sync[0].monday_status_column_id`,
                                  item.id
                                );
                              }}
                            >
                              <Typography.Text strong>
                                {item.title}
                              </Typography.Text>
                              <div>
                                {_.map(item.columnsData, (item) => {
                                  return (
                                    <>
                                      {item.label && (
                                        <>
                                          <Badge
                                            color={item.color}
                                            style={{ marginRight: 10 }}
                                            text={item.label}
                                          />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </Card>
                          );
                        });
                      }
                    })}
                  </div>
                  {!_.isEmpty(values?.settings?.sync[0]?.monday_board) &&
                    !_.isEmpty(values?.settings?.sync[0]?.windu_project) && (
                      <>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`setting`}
                            label="Column to Pull Activities from"
                            placeholder="Select a Column"
                            selectOptions={selectedColumn[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            mode="multiple"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                          />
                        </Row>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`start.status`}
                            label="Column to Push Started Activities"
                            placeholder="Select a Column"
                            selectOptions={selectedColumn[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                          />
                        </Row>
                        <Row>
                          <Field
                            className="m-0"
                            component={AntSelect}
                            name={`coloum to push`}
                            label="Column to Push Finished Activities"
                            placeholder="Select a Column"
                            selectOptions={selectedColumn[0]?.columnsData}
                            inputType="select"
                            hasFeedback
                            required={true}
                            size="large"
                            filterOption={false}
                            getPopupContainer={(node) => node.parentNode}
                          />
                        </Row>
                      </>
                    )}
                </div>
              )}
              <Button
                className="mt-3"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    monday_board: "",
                    windu_project: "",
                    status_pull: "",
                    status_push_start: "",
                    status_push_finished: "",
                  })
                }
              >
                Add New Project
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Setting;
