import {
  InboxOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Row,
  Typography,
  Upload,
  Switch,
  Button,
  Spin,
  Popover,
} from "antd";
import UserAvatar from "components/UserAvatar";
import React from "react";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import styles from "../../styles.module.css";
import InfoCard from "./InfoCard";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import utility from "common/utility";
import { GET_USER } from "graphql/queries/getUser";
import _ from "lodash";
import { UPDATE_SETTINGS_BY_USERID } from "graphql/mutations/user/updateSettingByUserId";
import {
  ANNUAL_DISCOUNT_PERCENTAGE,
  INDIVIDUAL_PLAN,
  TEAM_PLAN,
} from "constants/plans";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const ProfileView = ({ handleViewChange }) => {
  const user = useRecoilValue(userSession);
  const mode = user?.settings?.mode;
  const [progress, setProgress] = React.useState(0);
  const userSettingCount = user?.settings?.uniqueProjectsUsers || 0;
  const userCount = `${user?.extra?.uniqueProjectsParticipants.length}/${
    _.isNil(userSettingCount) ? "?? (Change Plan to Set)" : userSettingCount
  }`;

  const [updateUser] = useMutation(UPDATE_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      utility.setNotification(
        "Avatar updated successfully",
        `information updated`,
        "success",
        "topRight"
      );
      setProgress(0);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_USER],
  });
  const [updateUserSetting] = useMutation(UPDATE_SETTINGS_BY_USERID, {
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_USER],
  });

  const props = {
    name: "file",
    multiple: false,
    accept: ".svg,.png,.jpg,.jpeg",
    showUploadList: false,

    customRequest: ({ onSuccess }) => {
      onSuccess("ok");
    },
    async onChange({ fileList }) {
      if (!props.uploading && fileList.length > 0) {
        props.uploading = true;
        await uploadActivityFiles({
          userId: user?._id,
          fileList: fileList,
          updateProgress: setProgress,
        }).then((data) => {
          updateUser({
            variables: {
              input: {
                avatar: data[0]?.Location,
              },
            },
          });
          props.uploading = false;
        });
      }
    },
  };

  const BasicInfoCard = () => {
    return (
      <div
        className="flex justify-between py-4 px-8 rounded-xl"
        style={{ border: "2px solid #E0E0E0" }}
      >
        <div>
          <Title level={4}>Avatar</Title>
          <Divider />
          <div className="flex">
            <UserAvatar user={user} style={{ height: 60, width: 60 }} />
            <div className="flex flex-col ml-4">
              <Text className=" text-lg" strong>
                {user.full_name}
              </Text>
              <Text>{user.email}</Text>
            </div>
          </div>
        </div>
        <div className="ml-4">
          <Spin spinning={progress > 0} indicator={<LoadingOutlined spin />}>
            <Dragger {...props} className="px-4">
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#344054" }} />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to update avatar.
              </p>
              <p className="ant-upload-hint">SVG, PNG or JPG.</p>
            </Dragger>
          </Spin>
        </div>
      </div>
    );
  };

  const handleModeSwitch = (mode) => {
    if (_.includes(user?.settings?.mode, mode)) {
      //  remove from list
      updateUserSetting({
        variables: {
          input: {
            mode: _.without(user?.settings?.mode, mode),
          },
        },
      });
    } else {
      // add
      updateUserSetting({
        variables: {
          input: {
            mode: _.concat(user?.settings?.mode, mode),
          },
        },
      });
    }
  };

  const getUserPlanCost = () => {
    const planCost = user?.plan?.cost;
    const discountPercentage = ANNUAL_DISCOUNT_PERCENTAGE;

    if (planCost) {
      if (user?.plan?.period === "Annual") {
        const discountedMonthlyCost =
          (planCost - planCost * (discountPercentage / 100)) / 12;
        return discountedMonthlyCost.toFixed(2);
      } else {
        return planCost.toFixed(2);
      }
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <BasicInfoCard />
        <div className="mt-4">
          <InfoCard user={user} />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Row gutter={[16, 16]} wrap={false}>
          <Col span={24}>
            <Card
              title={<Title level={4}>Default mode</Title>}
              extra={
                <Popover
                  content={
                    <div className=" w-96">
                      <Text>
                        This is your default mode you selected when joining
                        Windu. Windu modes are designed for certain use cases.
                        To learn more about these use cases
                        <br />
                        <Typography.Link
                          href="https://help.windu.io/en/article/windu-modes-sqifsu/?bust=1692285757512"
                          target="_blank"
                        >
                          Click here
                        </Typography.Link>
                      </Text>
                    </div>
                  }
                  title="Mode"
                >
                  <QuestionCircleOutlined style={{ fontSize: 18 }} />
                </Popover>
              }
              style={{
                width: 300,
              }}
            >
              <Text className="text-base">{_.first(mode)}</Text>
            </Card>
            <Card
              title={<Title level={4}>Modes</Title>}
              style={{
                width: 300,
              }}
              className="mt-5"
            >
              <div className="flex items-center justify-between mb-4">
                <Text className="text-base">Command</Text>
                <Switch
                  className="ml-2"
                  defaultChecked={_.includes(mode, "Command")}
                  disabled={_.first(mode) === "Command"}
                  onChange={(v) => handleModeSwitch("Command")}
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <Text className="text-base">Focus</Text>
                <Switch
                  className="ml-4"
                  defaultChecked={_.includes(mode, "Focus")}
                  disabled={_.first(mode) === "Focus"}
                  onChange={(v) => handleModeSwitch("Focus")}
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <Text className="text-base">Invest</Text>
                <Switch
                  className="ml-4"
                  defaultChecked={_.includes(mode, "Invest")}
                  disabled={_.first(mode) === "Invest"}
                  onChange={(v) => handleModeSwitch("Invest")}
                />
              </div>
            </Card>
            <Card
              title={
                <Title level={4}>
                  {user?.plan?.name || "No"} Plan{" "}
                  <Text className=" text-sm"> ({user?.plan?.period})</Text>
                </Title>
              }
              extra={
                user.plan?.name === TEAM_PLAN && (
                  <Text className="text-base" style={{ color: "#3C2E94" }}>
                    {userCount} users
                  </Text>
                )
              }
              style={{
                width: 300,
                background: "#F8F8F8",
              }}
              className="mt-5"
            >
              <div className="flex items-center mb-4">
                <Text style={{ fontSize: 25 }} strong>
                  $
                </Text>
                <Text style={{ fontSize: 45 }} strong>
                  {getUserPlanCost()}
                </Text>
                <div className="flex flex-col ml-2 mt-2">
                  <Text>per month</Text>
                  {user?.plan?.name === TEAM_PLAN && <Text>per user</Text>}
                </div>
              </div>
              <Button
                size="large"
                className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
                type="primary"
                onClick={() => {
                  handleViewChange("subscriptions");
                }}
              >
                Upgrade
              </Button>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProfileView;
