import React, { useState } from "react";
import { Dropdown, Menu, Popconfirm } from "antd";
import { Icon } from "@fishyvisions/windu-uikit";
import {
  PlusOutlined,
  DeleteOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  UserDeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { bool, func } from "prop-types";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { TEAM_PLAN } from "constants/plans";

const ProjectMenuAction = ({
  onRemove,
  onRemoveClient,
  onRemoveManager,
  onArchive,
  onMembersList,
  setProjectSelected,
  setIsInviteMemberVisible,
  setIsInviteManagerVisible,
  setIsAssignClientVisible,
  hasActivities,
  hasClient,
  hasManager,
  isOwner,
  isArchived,
  hasPermissions,
  title,
  onProjectLeave,
  isManager,
  isInWorkspace,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const confirmMessage = hasActivities
    ? `Would you like to archive ${title}`
    : `Would you like to delete ${title}`;
  const optionTitle = hasActivities ? "Archive Project" : "Remove Project";

  const user = useRecoilValue(userSession);

  const onTeamPlan = user?.plan?.name === TEAM_PLAN || (isManager && !isOwner);

  const haveClientPersmissions = () => {
    if (isOwner) {
      if (user?.plan && user?.addons) {
        const hasInvoicingAddon = user.addons.some(
          (addon) => addon.name === "Invoicing"
        );
        if (hasInvoicingAddon) {
          return true;
        }
      }
    }
    if (isManager) {
      if (
        user?.extra &&
        user.extra?.foreignPlan &&
        user.extra.foreignPlan?.addons
      ) {
        const hasInvoicingAddon = user.extra.foreignPlan.addons.some((addon) =>
          addon.addons.includes("Invoicing")
        );
        if (hasInvoicingAddon) {
          return true;
        }
      } else return false;
    }
  };

  const ProjectMenu = () => {
    return (
      <Menu className="py-0">
        {!isArchived && onTeamPlan && (
          <>
            <Menu.Item
              className="py-4 flex items-center"
              key="1"
              onClick={() => {
                setProjectSelected();
                setIsInviteMemberVisible((currentValue) => !currentValue);
              }}
              icon={<PlusOutlined className="icon-medium" />}
              disabled={!hasPermissions}
            >
              Add Member
            </Menu.Item>
          </>
        )}

        <Menu.Divider className="my-0" />
        <Menu.Item
          className="py-4 flex items-center"
          key="2"
          onClick={() => onMembersList()}
          icon={<UnorderedListOutlined className="icon-medium" />}
          disabled={!onTeamPlan}
        >
          See Members
        </Menu.Item>
        {!isArchived && isOwner && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              key="3"
              className="py-4 flex items-center"
              icon={
                hasActivities ? (
                  <FolderOutlined className="icon-medium" />
                ) : (
                  <DeleteOutlined className="icon-medium" />
                )
              }
              disabled={!hasPermissions}
            >
              <Popconfirm
                placement="topLeft"
                title={confirmMessage}
                onConfirm={hasActivities ? onArchive : onRemove}
                okText="Yes"
                cancelText="No"
                disabled={!hasPermissions}
              >
                {optionTitle}
              </Popconfirm>
            </Menu.Item>
          </>
        )}
        {!isArchived && haveClientPersmissions() && (
          <>
            {hasClient ? (
              <>
                <Menu.Divider className="my-0" />
                <Menu.Item
                  className="py-4 flex items-center"
                  key="4"
                  icon={<UserDeleteOutlined className="icon-medium" />}
                  disabled={!hasPermissions}
                >
                  <Popconfirm
                    placement="topLeft"
                    title={
                      "Are you sure to remove this client of this project?"
                    }
                    onConfirm={() => onRemoveClient()}
                    okText="Yes"
                    cancelText="No"
                    disabled={!hasPermissions}
                  >
                    Remove Client
                  </Popconfirm>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Divider className="my-0" />
                <Menu.Item
                  className="py-4 flex items-center"
                  key="4"
                  onClick={() => {
                    setProjectSelected();
                    setIsAssignClientVisible((currentValue) => !currentValue);
                  }}
                  icon={<UserAddOutlined className="icon-medium" />}
                  disabled={!hasPermissions || !isInWorkspace}
                >
                  Invite Client
                </Menu.Item>
              </>
            )}
          </>
        )}

        {isOwner && !isArchived && onTeamPlan && (
          <>
            {hasManager ? (
              <>
                <Menu.Divider className="my-0" />
                <Menu.Item
                  className="py-4 flex items-center"
                  key="5"
                  icon={<UserDeleteOutlined className="icon-medium" />}
                  disabled={!hasPermissions}
                >
                  <Popconfirm
                    placement="topLeft"
                    title={
                      "Are you sure to remove this manager of this project?"
                    }
                    onConfirm={() => onRemoveManager()}
                    okText="Yes"
                    cancelText="No"
                    disabled={!hasPermissions}
                  >
                    Remove Manager
                  </Popconfirm>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Divider className="my-0" />
                <Menu.Item
                  className="py-4 flex items-center"
                  key="5"
                  onClick={() => {
                    setProjectSelected();
                    setIsInviteManagerVisible((currentValue) => !currentValue);
                  }}
                  icon={<UserAddOutlined className="icon-medium" />}
                  disabled={!hasPermissions}
                >
                  Invite Manager
                </Menu.Item>
              </>
            )}
          </>
        )}
        {isArchived && isOwner && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex"
              key="6"
              onClick={() => onArchive()}
              icon={<FolderOpenOutlined className="icon-medium" />}
            >
              Unarchive
            </Menu.Item>
          </>
        )}
        {!isOwner && (
          <>
            {!isOwner && (
              <>
                <Menu.Divider className="my-0" />
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to leave this project"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={isManager ? onRemoveManager : onProjectLeave}
                >
                  <Menu.Item
                    key="7"
                    className="py-4 flex items-center"
                    icon={<ImportOutlined className="icon-medium" />}
                  >
                    Leave Project
                  </Menu.Item>
                </Popconfirm>
              </>
            )}
          </>
        )}
      </Menu>
    );
  };

  return (
    <Dropdown
      placement="bottomRight"
      overlay={ProjectMenu}
      onVisibleChange={() => setIsVisible(!isVisible)}
      visible={isVisible}
      trigger={["click"]}
    >
      <span onClick={(e) => e.preventDefault()} className="cursor-pointer">
        <Icon type="Meatball" size="small" />
      </span>
    </Dropdown>
  );
};

ProjectMenuAction.propTypes = {
  onRemove: func,
  onArchive: func,
  setProjectId: func,
  onMembersList: func,
  setIsInviteMemberVisible: func,
  hasActivities: bool,
  hasPermissions: bool,
};

export default ProjectMenuAction;
