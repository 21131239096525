import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation ($input: userInput) {
    updateUser(input: $input) {
      user_type
      _id
      email
      full_name
      avatar
      timezone
      nro_projects
      unreadMessages
      subscribe_client_page_release
      stripe
      birthday
      created_by_invitation
      addons {
        _id
        name
        cost
        currency
      }
      phone_number {
        short
        code
        phone
      }
      onboarding_data {
        finished
        defaultRole
        teamMembers
        companyWorkers
        tools
        weeklyWorkHours
        expertise
      }
      plan {
        _id
        name
        cost
        currency
        permissions {
          value
          role {
            key
          }
        }
      }
      extra {
        nroWorkspacesCreated
        nroProjectsCreated
        uniqueProjectsParticipants {
          _id
          full_name
          email
          avatar
        }
        foreignPlan {
          addons {
            owner
            addons
          }
          plan
        }
      }
      settings {
        _id
        uniqueProjectsUsers
        payment_failed
        referralId
        mode
        quickStart {
          setReminder
          exampleProject
          firstProject
          inviteOther
          heardAboutPrompt
        }
        trial {
          _id
          isActive
          trialStart
          trialEnd
        }
      }
      workspaces {
        _id
      }
    }
  }
`;
