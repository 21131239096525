import React, { useState } from "react";
import { Row, Col } from "antd";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import _ from "lodash";
import { GET_ACTIVITY } from "graphql/queries/Activity/getActivity";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ActivityFeed from "components/ActivityFeed";
import TimeLineFilters from "./TimeLineFilters";
import { useRecoilState, useRecoilValue } from "recoil";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { userSession } from "recoil/atoms/User/UserSession";

import moment from "moment";
import utility from "common/utility";

const ActivitiesList = ({ location, hideEmptyDays }) => {
  const [refetching, setRefetchIndicator] = useRecoilState(refetchIndicator);
  const user = useRecoilValue(userSession);

  const { start_date, end_date, projectId, memberId } = queryString.parse(
    location.search
  );

  const [timeline, setTimeline] = useState({ data: [], meta: {} });

  const { loading, refetch } = useQuery(GET_ACTIVITY, {
    variables: {
      start_date: moment(start_date).format(),
      end_date: moment(end_date).format(),
      projectId,
      memberId: memberId ? memberId : [user?._id],
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (res) => {
      const {
        getActivity: { data, meta },
      } = res;

      const startDate = moment(start_date).tz(user.timezone).format("MM/DD/YY");
      const endDate = moment(end_date).tz(user.timezone).format("MM/DD/YY");
      const allDays = utility.getDatesInRange(startDate, endDate);
      const mappedData = _.map(allDays, (day) => {
        const haveActivty = _.filter(
          data,
          (item) =>
            moment(item.date).format("MM/DD/YYYY") ===
            moment(day).format("MM/DD/YYYY")
        );
        if (_.isEmpty(haveActivty))
          return {
            children: [],
            date: moment(day).format("MM/DD/YYYY"),
            total_time: 0,
          };
        return haveActivty[0];
      });
      const skipWeekends = _.filter(_.compact(mappedData), (item) => {
        if (
          (moment(item.date).day() === 6 || moment(item.date).day() === 0) &&
          _.isEmpty(item.children)
        ) {
          return false;
        } else {
          return true;
        }
      });

      // const groupByMonth = _.groupBy(skipWeekends, (item) =>
      //   moment(item.date).startOf("month").format("YYYY/MMMM")
      // );
      setTimeline({ data: skipWeekends, meta });
    },
  });

  React.useEffect(() => {
    if (refetching.timeline && loading === false) {
      setRefetchIndicator((current) => {
        return { ...current, timeline: false };
      });

      refetch();
    }
  }, [refetching]);

  const activityData = get(timeline, "data", []);
  const withOutDays = _.filter(activityData, function (o) {
    return !_.isEmpty(o.children);
  });

  return (
    <>
      <Row className="mb-6">
        <Col xs={24}>
          <ActivityFeed
            loading={loading}
            data={hideEmptyDays ? withOutDays : activityData}
            meta={timeline?.meta}
          />
        </Col>
      </Row>
      <TimeLineFilters />
    </>
  );
};
export default withRouter(ActivitiesList);
