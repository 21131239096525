import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Layout } from "antd";
import { useRecoilValueLoadable, useRecoilValue, useRecoilState } from "recoil";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { find, isEmpty } from "lodash";
import { workspacesList, workspaceSelected } from "recoil/atoms/Workspaces";
import { userSession } from "recoil/atoms/User/UserSession";
import Invoices from "components/Invoices";
import CreateInvoice from "components/Invoices/CreateInvoice";
import Settings from "./Settings";
import _ from "lodash";
import Activities from "./Activities";
import Dashboard from "./Dashboard";
import InvoiceHistoryPage from "components/Invoices/History";
import NotFound from "views/Provisional/404";
import styles from "./styles.module.css";
import WorkspaceMessages from "components/WorkspaceMessages";
import Backlog from "./Backlog";
import Rally from "components/Rally";
import Cookies from "js-cookie";

const { TabPane } = Tabs;

const Workspace = ({ flags }) => {
  const { showBacklog = true, showRally } = flags;
  const location = useLocation();

  const { id, invoice, view } = queryString.parse(location.search);

  const user = useRecoilValue(userSession);
  const [workspace, setWorkspace] = useRecoilState(workspaceSelected);
  const [currentWorkspace, setCurrenWorkspace] = useState(id);
  const [isOwner, setIsOwner] = React.useState(true);
  const [ownerAddons, setOwnerAddons] = React.useState([]);
  const [isManager, setIsManager] = React.useState(true);
  const workspacesLoadable = useRecoilValueLoadable(workspacesList);
  const history = useHistory();

  const [tab, setTab] = useState(null);
  useEffect(() => {
    const { state, code, integration } = queryString.parse(location.search);
    try {
      const newState = _.split(state, "?");
      const integrationType = _.split(newState[1], "=")[1];
      if (integrationType === "todoist" && _.isEmpty(id)) {
        history.push(
          `/workspace?id=${newState[0]}&code=${code}&integration=todoist`
        );
      }
      if (integration?.includes("trello") && _.isEmpty(id)) {
        const { token } = queryString.parse(location.hash);
        const newArray = integration?.split("?");
        const state = newArray[1]?.split("=");
        history.push(
          `/workspace?id=${state[1]}&code=${token}&integration=trello`
        );
      }

      if (state && integrationType !== "todoist") {
        if (integration?.includes("monday")) {
          const newArray = integration?.split("?");
          const code = newArray[1]?.split("=");
          history.push(
            `/workspace?id=${state}&code=${code[1]}&integration=${newArray[0]}`
          );
        } else {
          history.push(
            `/workspace?id=${state}&code=${code}&integration=${integration}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [location]);

  const setWorkspaceTab = (id) => {
    const storedTab = Cookies.get(`workspace-${id}`);
    if (storedTab) {
      setTab(JSON.parse(storedTab)?.tab);
    } else {
      setTab("dashboard");
    }
  };
  useEffect(() => {
    if (id) {
      setWorkspaceTab(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && workspacesLoadable.contents) {
      const view = location?.state?.view || "dashboard";
      if (view === "dashboard") {
        setWorkspaceTab(id);
      }
      const workspace = find(
        workspacesLoadable.contents,
        (workspace) => workspace._id === id
      );
      const isOwner = workspace?.project?.created_by?._id === user._id;
      const isManager = workspace?.project?.manager?._id === user._id;

      if (isOwner) {
        // if the user is the owner of the workspace
        // check the addons associated to the user session
        const { addonsReferences = [] } = user;
        setOwnerAddons(addonsReferences);
      } else {
        // if the user is not the owner of the workspace
        // check the addons associated to the owner of the project
        const owner = _.get(workspace, "project.created_by", {});
        const { addons = [] } = owner;
        setOwnerAddons(_.map(addons, (item) => item.name));
      }

      setIsOwner(isOwner);
      setIsManager(isManager);
      setWorkspace(workspace);
    } else {
      const workspaceChanged = currentWorkspace !== id;
      if (workspaceChanged) {
        setCurrenWorkspace(id);
        setWorkspaceTab(id);
      }
    }
  }, [workspacesLoadable.contents, id]);

  React.useEffect(() => {
    if (location?.state?.view) {
      const view = location.state.view;
      if (view === "dashboard") {
        setWorkspaceTab(id);
      }
    }
  }, [location?.state?.view]); // when workspace change it will trigger the workspace recoil update

  const workspaceSettings = (
    <Settings isManager={isManager} isOwner={isOwner} invoice={invoice} />
  );

  const getInvoiceContent = () => {
    // return invoices history view if in path
    if (invoice === "history") {
      return <InvoiceHistoryPage />;
    }

    // return invoices create/edit view if in path
    if (invoice === "createinvoice" || invoice === "editinvoice") {
      return (
        <CreateInvoice editMode={invoice === "editinvoice" ? true : false} />
      );
    }

    // return invoices view by default
    return <Invoices workspace={workspace} />;
  };

  const getBacklogContent = () => {
    // return rally view
    if (view === "rally") {
      return <Rally />;
    }

    if (tab === "backlog") {
      return <Backlog showRally={showRally} />;
    }
  };

  if (!isEmpty(workspacesLoadable.contents) && isEmpty(workspace)) {
    return <NotFound />;
  }

  return (
    <Layout className="h-full">
      <Layout.Content>
        <Row className="h-full">
          <Col xs={24}>
            <div
              style={{
                height: "100%",
                overflowX: "hidden",
                width: "100%",
              }}
              className="bg-white mr-3"
            >
              <Tabs
                className={styles.tabsHeight}
                defaultActiveKey={tab}
                onChange={(v) => {
                  history.replace({
                    pathname: "/workspace",
                    search: `?id=${id}`, // workspace id from the url
                    state: { view: v },
                  });
                  setTab(v);
                  const storedTab = Cookies.get(`workspace-${id}`);

                  Cookies.set(
                    `workspace-${id}`,
                    JSON.stringify({
                      view: storedTab ? JSON.parse(storedTab)?.view : "",
                      tab: v,
                    }),
                    {
                      expires: 365,
                    }
                  );
                }}
                activeKey={tab}
                tabBarStyle={{
                  marginBottom: 0,
                  padding: "10px 18px",
                  paddingBottom: 0,
                }}
                moreIcon={null}
                size={"large"}
                tabBarExtraContent={workspaceSettings}
              >
                {ownerAddons.includes("Integration") && (
                  <TabPane className="h-full" tab="Dashboard" key="dashboard">
                    <Dashboard isCollapsed={true} />
                  </TabPane>
                )}

                {showBacklog && (
                  <TabPane className="h-full" tab="Backlog" key="backlog">
                    {getBacklogContent()}
                  </TabPane>
                )}

                <TabPane className="h-full" tab="Activities" key="activities">
                  {workspace && <Activities />}
                </TabPane>

                {/*       {ownerAddons.includes("Invoicing") &&
                  (isManager || isOwner) && (
                    <TabPane tab="Client" key="client">
                      <Client />
                    </TabPane>
                  )} */}
                {ownerAddons.includes("Invoicing") &&
                  (isManager || isOwner) && (
                    <TabPane tab="Invoices" key="invoices">
                      {getInvoiceContent()}
                    </TabPane>
                  )}

                {ownerAddons.includes("Chat") && (
                  <TabPane
                    tab="Chats"
                    key="chats"
                    style={{
                      height: "100%",
                      background: "#C7C1EC",
                    }}
                  >
                    {tab === "chats" && <WorkspaceMessages />}
                  </TabPane>
                )}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default Workspace;
