import React from "react";
import { Typography } from "antd";
import cx from "classnames";
import styles from "./styles.module.css";
import Linkify from "react-linkify";

const MessageContent = ({
  showEdited,
  message,
  editing,
  setEditing,
  type,
  handleUpdateMessage,
  withAvatar = false,
}) => {
  const componentDecorator = (href, text, key) => (
    <div style={{ width: "100%" }}>
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    </div>
  );

  return (
    <div
      style={{ width: "100%" }}
      className={cx("mb-1 break-words text-justify", {
        "pl-12": !withAvatar,
      })}
    >
      <Linkify componentDecorator={componentDecorator}>
        <Typography.Text
          editable={{
            autoSize: true,
            onCancel: () => setEditing(null),
            onChange: (value) => handleUpdateMessage(value),
            editing: editing === message.key ? true : false,
          }}
          className={`${
            type === "client"
              ? styles.editableContainerSmall
              : styles.editableContainer
          } text-base`}
        >
          {message.content}
        </Typography.Text>
      </Linkify>
      {showEdited && (
        <Typography.Text className="ml-2 text-gray-300">
          (edited)
        </Typography.Text>
      )}
    </div>
  );
};

export default MessageContent;
