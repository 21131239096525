import gql from "graphql-tag";

export const REMOVE_PROJECT_MEMBER = gql`
  mutation($input: removeProjectMemberInput) {
    removeProjectMember(input: $input) {
      _id
      title
      description
      members {
        _id
      }
    }
  }
`;
