import React, { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Row, Col, Tooltip, Button, Dropdown, Menu } from "antd";
import { useRecoilState } from "recoil";
import { widgetsState } from "recoil/atoms/Dashboard/widgets";
import { useLazyQuery, useMutation } from "@apollo/client";
import { filter, get, isNil, compact } from "lodash";
import { GET_WORKSPACE_WIDGETS } from "graphql/queries/workpace/getWorkspaceWidgets";
import { WIDGETS } from "components/Widgets";
import utility from "common/utility";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AppstoreAddOutlined, EllipsisOutlined } from "@ant-design/icons";
import WidgetToggle from "components/Modals/WidgetToggle";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import EnlargeWidget from "components/Modals/Enlarge";
import { customizeState } from "recoil/atoms/Dashboard/customize";
import cx from "classnames";

import _ from "lodash";
import { UPDATE_WIDGETS_LAYOUT } from "graphql/mutations/widget/updateWidgetLayout";
import Icon from "@ant-design/icons";
import Icons from "common/icons";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ isCollapsed }) => {
  const location = useLocation();
  const { id, enlarge } = queryString.parse(location.search);
  const query = { id };
  const [widgets, setWidgets] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(id);
  const [validWidgets, setValidWidgets] = useRecoilState(widgetsState);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);
  const [widgetModal, setWidgetModal] = useState(false);

  const [customize, setCustomize] = useRecoilState(customizeState);
  const [layoutState, setLayoutState] = useState({});

  const [updateWidgetLayout] = useMutation(UPDATE_WIDGETS_LAYOUT, {
    onCompleted: () => {
      return utility.setNotification(
        "Success",
        `Layout Saved`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const handleCustomize = () => {
    if (customize && !_.isEmpty(layoutState)) {
      const reformatPayload = _.map(layoutState, (layout) => {
        const foundLayout = _.find(widgets, {
          widget: { name: layout.i },
        });

        return {
          _id: foundLayout?._id,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        };
      });

      updateWidgetLayout({ variables: { input: [...reformatPayload] } });
    }
    setCustomize(!customize);
  };

  const toggleStyles = cx({
    "cursor-pointer": true,
    "bg-gray-300": customize,
    "flex p-2 items-center content-center pr-4": true,
  });
  const [getWorkspaceWidgets, { refetch }] = useLazyQuery(
    GET_WORKSPACE_WIDGETS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { input: { workspace: id } },
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getWorkspaceWidgets }) => {
        const toggledWidget = filter(
          getWorkspaceWidgets,
          (widget) => widget.toggle
        );
        const validData = filter(toggledWidget, function (data) {
          return WIDGETS[data?.widget?.name];
        });
        const alreadyValidatedWidgets = validWidgets?.workspace[id];
        setValidWidgets((widgets) => {
          return {
            ...widgets,
            workspace: {
              [id]: alreadyValidatedWidgets
                ? alreadyValidatedWidgets
                : validData,
            },
          };
        });
        setWidgets(validData);
        setSelectedWorkspace(id);
      },

      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    if (!isNil(id)) {
      getWorkspaceWidgets({ variables: { input: { workspace: id } } });
    }
  }, [id, getWorkspaceWidgets]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  }, [isCollapsed]);

  const handleWidgetToggleRefetch = (newWidget) => {
    const validateExistingWidgets = validWidgets?.workspace[id]?.map((w) => {
      if (w.widget.name === newWidget?.widget?.name) {
        return undefined;
      }
      return w;
    });
    const widgets = [...compact(validateExistingWidgets), newWidget];
    const toggledWidgets = filter(widgets, (widget) => widget.toggle);
    setValidWidgets((widgets) => {
      return {
        ...widgets,
        workspace: { [id]: toggledWidgets },
      };
    });
  };
  const menu = (
    <Menu className="py-0 pt-1">
      <Menu.Item
        className="py-4 flex items-center"
        onClick={() => setCustomize(!customize)}
        icon={<Icon component={Icons.Toggle} />}
      >
        Rearrange Widgets
      </Menu.Item>
    </Menu>
  );
  const listWidgets = () => {
    return validWidgets?.workspace[id]?.map((data) => {
      const { x, y, w, h, _id, i } = data;
      return (
        <div key={i} data-grid={{ i, x, y, w, h, isResizable: false, _id }}>
          {WIDGETS[data?.widget?.name]}
        </div>
      );
    });
  };
  return (
    <>
      <Row className="flex justify-between px-1">
        <Col className="mt-3 flex items-center">
          <Tooltip
            placement="rightBottom"
            title="Customize your dashboard by adding widget"
          >
            <Button
              type="text"
              className=" flex justify-center items-center"
              style={{ color: "#F5A623" }}
              onClick={() => setWidgetModal(true)}
              icon={
                <AppstoreAddOutlined size="large" style={{ fontSize: 24 }} />
              }
            >
              Manage my widgets
            </Button>
          </Tooltip>
          <div className="flex items-center justify-start mt-1">
            <div className="flex ml-4 mb-2">
              <Tooltip title="Rearrange Widgets">
                <Icon
                  component={Icons.Toggle}
                  onClick={() => setCustomize(!customize)}
                />
              </Tooltip>
            </div>

            {customize && (
              <>
                <Button
                  className="ml-3"
                  onClick={handleCustomize}
                  type="primary"
                >
                  Save
                </Button>
                <Button
                  className="ml-3"
                  onClick={() => setCustomize(false)}
                  type="secondary"
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
      <ResponsiveGridLayout
        draggableHandle=".drag-handle"
        onDragStop={(v) => setLayoutState(v)}
        className="layout pt-5"
        margin={[25, 10]}
        rowHeight={150}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
        autoSize={false}
        layouts={{ lg: validWidgets?.workspace[id] }}
        isResizable={false}
        width="100%"
      >
        {listWidgets()}
      </ResponsiveGridLayout>
      {enlargeModal.visible && <EnlargeWidget />}
      {widgetModal && (
        <WidgetToggle
          visible={widgetModal}
          setVisible={setWidgetModal}
          widgetRefetch={handleWidgetToggleRefetch}
        />
      )}
    </>
  );
};

export default Dashboard;
