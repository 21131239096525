import gql from "graphql-tag";

export const GET_USER = gql`
  query {
    getUser {
      user_type
      _id
      email
      full_name
      avatar
      timezone
      nro_projects
      client_in_projects
      unreadMessages
      subscribe_client_page_release
      stripe
      birthday
      created_by_invitation
      addons {
        _id
        name
        currency
        cost
        period
      }
      phone_number {
        short
        code
        phone
      }
      onboarding_data {
        finished
        defaultRole
        teamMembers
        companyWorkers
        tools
        weeklyWorkHours
        expertise
      }
      plan {
        _id
        name
        cost
        currency
        period
        permissions {
          value
          role {
            key
          }
        }
      }
      extra {
        nroWorkspacesCreated
        nroProjectsCreated
        uniqueProjectsParticipants {
          _id
          full_name
          email
          avatar
        }
        foreignPlan {
          addons {
            owner
            addons
          }
          plan
        }
      }
      settings {
        _id
        uniqueProjectsUsers
        payment_failed
        referralId
        mode
        quickStart {
          exampleProject
          firstProject
          inviteOther
          heardAboutPrompt
          defaultModeSet
        }
        trial {
          _id
          isActive
          trialStart
          trialEnd
        }
        slack {
          access_token
          app_id
          scope
          token_type
        }
      }
      workspaces {
        _id
      }
    }
  }
`;
