import React from "react";
import { Empty, Typography } from "antd";
import NoDataImage from "./empty";

import styles from "./styles.module.css";

const EmptyData = ({ marginTop, description }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "90%",
        marginTop: marginTop ? "100px" : "",
      }}
    >
      <Empty
        className={styles.empty}
        image={<NoDataImage />}
        description={
          description ? (
            <Typography.Text style={{ color: "rgb(209 201 245)" }} >
              {description}
            </Typography.Text>
          ) : (
            <Typography.Text
              style={{ color: "rgb(209 201 245)" }}
            >
              No Data
            </Typography.Text>
          )
        }
        imageStyle={{
          height: 60,
        }}
      />
    </div>
  );
};

export default EmptyData;
