import { useEffect } from "react";

import { widgetsState } from "recoil/atoms/Dashboard/widgets";
import { useRecoilState } from "recoil";

export const useAuthWidgets = (isAuthenticated, widget, id) => {
  const [validWidgets, setValidWidgets] = useRecoilState(widgetsState);

  useEffect(() => {
    if (!isAuthenticated) {
      setValidWidgets((widgets) => {
        const unAuthenticatedWidget = widgets?.workspace[id]?.map((w) => {
          if (w.widget.name === widget) {
            return { i: widget, ...w, h: 1 };
          }
          return w;
        });

        return {
          ...widgets,
          workspace: { [id]: unAuthenticatedWidget },
        };
      });
    } else {
      setValidWidgets((widgets) => {
        const authenticatedHeight = widgets?.workspace[id]?.map((w) => {
          if (w.widget.name === widget) {
            return { i: widget, ...w, h: 3 };
          }
          return w;
        });
        return {
          ...widgets,
          workspace: { [id]: authenticatedHeight },
        };
      });
    }
  }, [isAuthenticated]);
};
