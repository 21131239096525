import React, { useState, useEffect } from "react";
import {
  LeftOutlined,
  LoadingOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Typography,
  Collapse,
  Menu,
  Row,
  Col,
  ConfigProvider,
  Checkbox,
  Spin,
  Tooltip,
} from "antd";
import { Icon } from "@fishyvisions/windu-uikit";
import { useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment-timezone";
import queryString from "query-string";
import { CREATE_INVOICE } from "graphql/mutations/invoice/createInvoice";
import { UPDATE_INVOICE } from "graphql/mutations/invoice/updateInvoice";
import { GET_INVOICE } from "graphql/queries/invoice/getInvoice";
import ChooseActivityModal from "./ChooseActivityModal";
import EditableTable from "./EditableTabel";
import _ from "lodash";
import cc from "currency-codes";
import { useHistory, useLocation } from "react-router";
import validationSchema from "./validationSchema";
import { useRecoilValue } from "recoil";
import { Field, Formik, Form } from "formik";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { userSession } from "recoil/atoms/User/UserSession";
import {
  AntInput,
  AntTextArea,
  AntSelect,
  AntDateRangePicker,
  AntDatePicker,
} from "components/FormikCustomInputs";
import { getLatestRate } from "api/ExchangeRates";
import utility from "common/utility";
import EmptyData from "components/EmptyData";
import DateRangePickerInvoice from "../InvoiceCalender";
import { map, size, debounce, trim, uniqBy } from "lodash";
import Loader from "components/Loader";
import CreateSelectOption from "components/CreateSelectOption";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { SEND_INVOICE } from "graphql/mutations/invoice/sendInvoiceEmail";
import { GET_INVOICES } from "graphql/queries/invoice/getInvoices";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const currency = _.map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const sizePerPage = 5;
const initialOffset = 0;
const initialSearch = "";

const CreateInvoice = ({ editMode }) => {
  const user = useRecoilValue(userSession);
  const workspace = useRecoilValue(workspaceSelected);
  const history = useHistory();
  const location = useLocation();
  const { invoiceId } = queryString.parse(location.search);
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [totalDue, setTotalDue] = useState(0);
  const [isChooseModalVisible, setIsChooseModalVisible] = useState(false);
  const [sendInvoice, setSendInvoice] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const formRef = React.useRef();
  const { id } = queryString.parse(location.search);
  const [showInvoiceBreakdown, setshowInvoiceBreakdown] = useState(false);
  const query = { id };

  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
  });

  const delayedQuery = React.useCallback(
    debounce((query) => {
      handleSearch(query);
    }, 1000),
    []
  );
  const handleSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const [getInvoice, { loading, data }] = useLazyQuery(GET_INVOICE, {
    variables: { invoiceId, workspaceId: id },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getInvoice }) => {
      setDataSource(
        _.map(getInvoice.items, (item) => {
          const handOffActivities = [];
          _.forEach(
            _.filter(item.activities, (item) => item.status === 6),
            (activity) => {
              _.forEach(activity.handoff, (item) => {
                const payload = {
                  ...activity,
                  created_at: item.time.start,
                  created_by: item.user,
                  time: item.time,
                  handoff: true,
                };
                handOffActivities.push(payload);
              });
            }
          );
          const activities = [
            ..._.filter(item.activities, (item) => item.status === 3),
            ...handOffActivities,
          ];
          return {
            key: _.uniqueId("invoice_"),
            name: item.description,
            hours: item.hours,
            cost: Number(item?.cost)?.toFixed(2),
            activities: _.size(item.activities),
            handoff: item.handoff,
            activitiesSelected: _.map(
              // todo set backend for all these data
              activities,
              (activity) => ({
                activityId: activity._id,
                created_by: activity.created_by,
                time: activity?.time,
                status: activity?.status,
              })
            ),
          };
        })
      );

      setshowInvoiceBreakdown(getInvoice?.showInvoiceBreakdown);
      setIsRowExpanded(getInvoice?.showInvoiceBreakdown);
    },
  });

  const [updateInvoice, { loading: invoiceUpdateLoading }] = useMutation(
    UPDATE_INVOICE,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: ({ updateInvoice }) => {
        if (sendInvoice) {
          sendInvoiceEmail({
            variables: {
              input: {
                projectId: workspace?.project._id,
                invoiceId: updateInvoice._id,
              },
            },
          });
        } else {
          utility.setNotification(
            "Invoice updated successfully",
            `Invoice has been updated`,
            "success",
            "topRight"
          );
          history.goBack();
        }
      },
    }
  );

  const [createInvoice, { loading: createInvoiceLoading }] = useMutation(
    CREATE_INVOICE,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: ({ createInvoice }) => {
        createGoogleEvent(user?._id, "CREATE_INVOICE", "app_engagement");
        if (sendInvoice) {
          sendInvoiceEmail({
            variables: {
              input: {
                projectId: workspace?.project._id,
                invoiceId: createInvoice._id,
              },
            },
          });
        } else {
          utility.setNotification(
            "Invoice saved successfully",
            `invoice has been save as Draft`,
            "success",
            "topRight"
          );
          history.goBack();
        }
      },
    }
  );

  const [sendInvoiceEmail, { loading: sendInvoiceLoading }] = useMutation(
    SEND_INVOICE,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        history.goBack();
        utility.setNotification(
          "Invoice has been sent",
          `Invoice sent successfully to the client email.`,
          "success",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    if (editMode) {
      getInvoice();
    }
  }, [editMode]);

  useEffect(() => {
    let totalDue = 0;

    _.forEach(dataSource, (elem) => {
      const cost = _.isNaN(Number(elem.cost)) ? 0 : Number(elem.cost);
      totalDue = (Number(totalDue) + Number(cost))?.toFixed(2);
    });

    setTotalDue(totalDue);
  }, [dataSource]);

  React.useEffect(() => {
    if (workspace) {
      const client = _.get(workspace, "project.client", {});
      setClientInfo(client);
      if (client?.currency) setselectedCurrency(client?.currency);
    }
  }, [workspace]);

  React.useEffect(() => {
    setDataSource([]);
  }, [clientInfo?.currency]);

  const columnsData = [
    {
      title: "Description",
      dataIndex: "name",
      editable: true,
      width: "50%",
    },
    {
      title: "No. Activities",
      dataIndex: "activities",
      editable: true,
      render: (data, item) => {
        return _.size(item?.activitiesSelected);
      },
    },
    {
      title: "Hours",
      dataIndex: "hours",
      editable: true,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      editable: true,
      render: (data) => {
        return Number(data)?.toFixed(2);
      },
    },
    {
      title: "Currency",
      editable: false,
      render: () => {
        return `${selectedCurrency}`;
      },
    },
    {
      title: "",
      dataIndex: "option dropdown",
      render: (_, record) => (
        <Dropdown
          placement="bottomRight"
          overlay={<TableDropDown record={record} />}
          trigger={["click"]}
        >
          <span onClick={(e) => e.preventDefault()} className="cursor-pointer">
            <Icon type="Meatball" size="small" />
          </span>
        </Dropdown>
      ),
    },
  ];

  const handleDelete = (key) => {
    setDataSource(dataSource.filter((item) => item.key !== key));
  };

  const handleDuplicate = (record) => {
    var duplicateRow = { ...record };
    duplicateRow.key = _.uniqueId("duplicate_");
    setDataSource([...dataSource, duplicateRow]);
  };

  const calculateCost = async (activitySelected, dateRange) => {
    let totalCost = 0;
    let rateConvertion = null;
    const handOffActivities = [];
    _.forEach(
      _.filter(activitySelected, (item) => item.status === 6),
      (activity) => {
        _.forEach(activity.handoff, (item) => {
          const payload = {
            ...activity,
            created_at: item.time.start,
            created_by: item.user,
            time: item.time,
            handoff: true,
          };
          handOffActivities.push(payload);
        });
      }
    );
    const activities = [
      ..._.filter(activitySelected, (item) => item.status === 3),
      ...handOffActivities,
    ];
    const groupByMembers = _.groupBy(activities, "created_by.email");

    const promise = _.map(groupByMembers, async (item, index) => {
      const listTime = _.map(item, (member) => member.time.total_time);
      const gatherTime = utility.calculateTimeTotal(listTime);

      const memberRate = _.get(item[0], "created_by.rates.rate", "0");
      const memberCurrency = _.get(item[0], "created_by.rates.currency", "USD");
      const clientCurrency = _.get(clientInfo, "currency", "");
      if (memberCurrency !== (selectedCurrency || clientCurrency)) {
        rateConvertion = await getLatestRate({
          clientCurrency:
            selectedCurrency || _.get(clientInfo, "currency", "USD"),
          memberCurrency: memberCurrency,
        });
      }

      const totalTimeSplited = _.split(gatherTime, ":");
      const pricePerHour = Number(totalTimeSplited[0]) * Number(memberRate);
      const pricePerMin =
        (Number(memberRate) / 60) * Number(totalTimeSplited[1]);

      const total = pricePerHour + pricePerMin;

      return {
        email: index,
        totalTime: gatherTime,
        totalCost: !_.isNumber(rateConvertion) ? total : total * rateConvertion,
      };
    });

    const gatherHoursPerMember = await Promise.all(promise);

    _.forEach(gatherHoursPerMember, (elem) => {
      totalCost = totalCost + elem.totalCost;
    });

    const gatherHours = _.map(gatherHoursPerMember, (elem) => elem.totalTime);
    const totalHours = utility.calculateTimeTotal(gatherHours);
    const newItem = {
      key: _.uniqueId("invoice_"),
      name: dateRange,
      hours: totalHours,
      activities: _.size(activitySelected),
      cost: totalCost,
      activitiesSelected: _.map(activities, (activity) => ({
        activityId: activity._id,
        created_by: activity.created_by,
        time: activity?.time,
        status: activity?.status,
      })),
    };

    setDataSource([...dataSource, newItem]);
  };

  const convertCurrencyRate = async (from, to) => {
    if (totalDue > 0) {
      let rateConvertion = null;
      if (from !== to) {
        rateConvertion = await getLatestRate({
          clientCurrency: to,
          memberCurrency: from || clientInfo?.currency || "USD",
        });
      }
      setTotalDue(Number(totalDue * rateConvertion).toFixed(2));

      let dataSourceCopy = dataSource;

      const newConvertedDataSource = dataSourceCopy.map((item) => {
        return {
          ...item,
          cost: item.cost * rateConvertion,
        };
      });
      setDataSource(newConvertedDataSource);
    }
  };

  const TableDropDown = ({ record }) => {
    return (
      <Menu className="py-0">
        <Menu.Item
          className="py-4 flex items-center"
          key="1"
          onClick={() => handleDelete(record.key)}
        >
          Delete Item
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          className="py-4 flex items-center"
          key="2"
          onClick={() => handleDuplicate(record)}
        >
          Duplicate Item
        </Menu.Item>
      </Menu>
    );
  };

  const handleAdd = () => {
    const newData = {
      key: _.uniqueId("invoice_"),
      name: "Enter the description here",
      activities: 0,
      hours: "00:00:00",
      cost: 0,
      activitiesSelected: [],
    };
    setDataSource([...dataSource, newData]);
  };

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const submitForm = (values) => {
    const { currency, title, invoicePeriod, dueDate, issueDate, notes } =
      values;

    const invoicePayload = {
      workspace: workspace?._id,
      code: null,
      name: title,
      period: `${moment(invoicePeriod[0]).format("YYYY-MM-DD")} ${moment(
        invoicePeriod[1]
      ).format("YYYY-MM-DD")}`,
      payment_due: dueDate,
      total_due: String(totalDue),
      sub_total: String(totalDue),
      currency,
      sent_to: null,
      sent_at: null,
      status: "DRAFT",
      showInvoiceBreakdown,
      issue_date: issueDate,
      created_by: user?.email,
      notes,
      items: _.map(dataSource, (item) => {
        const activitiesId = _.uniq(
          _.map(item.activitiesSelected, (item) => item?.activityId)
        );
        return {
          description: item.name,
          hours: _.replace(item.hours, "__", "00"),
          cost: String(item?.cost),
          activities: activitiesId,
        };
      }),
    };

    if (editMode) {
      // const parsed = _.omit(invoicePayload, ["workspace"]);
      updateInvoice({
        variables: {
          input: {
            invoiceId,
            ...invoicePayload,
          },
        },
      });
    } else {
      createInvoice({
        variables: {
          input: invoicePayload,
        },
      });
    }
  };

  const getInvoicePeriod = () => {
    if (data?.getInvoice?.period) {
      const period = _.split(data?.getInvoice?.period, " ");
      return [moment(period[0]), moment(period[1])];
    }

    return [];
  };
  const handleInvoiceBreakdownCheckbox = (e) => {
    setIsRowExpanded(e.target?.checked);
    setshowInvoiceBreakdown(e.target?.checked);
  };

  if (loading) return <div>Loading...</div>;
  const clientExist = !_.isNil(clientInfo);

  const backHandler = () => {
    history.push({
      search: queryString.stringify({
        id,
      }),
    });
  };

  return (
    <div className="p-6">
      <Spin
        spinning={
          createInvoiceLoading || invoiceUpdateLoading || sendInvoiceLoading
        }
        size="large"
        indicator={<LoadingOutlined spin />}
      >
        <div className="flex items-center mb-2">
          <Button
            onClick={backHandler}
            type="text"
            style={{ color: "#3C2E94" }}
            className="text-base flex items-center mr-0 px-2"
          >
            <LeftOutlined /> Invoice
          </Button>
          /
          <Typography className="text-base mr-0 px-2">
            Create Invoice
          </Typography>
        </div>
        <Formik
          initialValues={{
            name: clientInfo?.full_name,
            email: clientInfo?.email,
            company: clientInfo?.company,
            address: clientInfo?.address,
            currency: selectedCurrency,
            title: data?.getInvoice?.name || "",
            issueDate: data?.getInvoice?.issue_date
              ? moment(data?.getInvoice?.issue_date)
              : "",
            dueDate: data?.getInvoice?.payment_due
              ? moment(data?.getInvoice?.payment_due)
              : "",
            invoicePeriod: getInvoicePeriod(),
            notes: data?.getInvoice?.notes || "",
          }}
          validationSchema={validationSchema}
          onSubmit={submitForm}
          innerRef={formRef}
          enableReinitialize={true}
        >
          {({ submitCount, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6} className="mr-2">
                    <Field
                      component={AntInput}
                      name="title"
                      label="Title"
                      placeholder="Invoice - Project A"
                      submitCount={submitCount}
                      required={true}
                      size="medium"
                    />
                  </Col>
                  <Col lg={6} className="mr-2">
                    <Field
                      component={AntDateRangePicker}
                      name="invoicePeriod"
                      label="Invoice Period"
                      submitCount={submitCount}
                      required={true}
                      size="medium"
                      inputType="picker"
                      className="w-full"
                    />
                  </Col>
                  <Col lg={4} className="mr-2">
                    <Field
                      component={AntDatePicker}
                      name="issueDate"
                      label="Issue Date"
                      submitCount={submitCount}
                      required={true}
                      size="medium"
                      inputType="picker"
                      className="w-full"
                    />
                  </Col>
                  <Col lg={4} className="mr-2">
                    <Field
                      component={AntDatePicker}
                      name="dueDate"
                      label="Payment Due"
                      submitCount={submitCount}
                      required={true}
                      size="medium"
                      inputType="picker"
                      className="w-full"
                    />
                  </Col>
                  <Col lg={2}>
                    <Field
                      component={AntSelect}
                      name="currency"
                      label="Currency"
                      placeholder="Currency"
                      submitCount={submitCount}
                      selectOptions={currency}
                      inputType="select"
                      size="medium"
                      showSearch
                      onCallBackChange={(v) => {
                        convertCurrencyRate(selectedCurrency, v);
                        setselectedCurrency(v);
                      }}
                    />
                  </Col>
                </Row>
                <div className="flex items-center mt-8">
                  <Title
                    level={5}
                    className="text-sm"
                    style={{ color: "#3C2E94" }}
                  >
                    PAYABLE ITEMS
                  </Title>
                  <div className="ml-4">
                    <Checkbox
                      onChange={(e) => handleInvoiceBreakdownCheckbox(e)}
                      checked={showInvoiceBreakdown}
                    >
                      Show contribution breakdown
                      <Tooltip
                        overlay={
                          <Text className=" text-white ">
                            See a breakdown of the team members for the chosen
                            activities from the picker.
                          </Text>
                        }
                      >
                        <QuestionCircleOutlined className="ml-1" />
                      </Tooltip>
                    </Checkbox>
                  </div>
                </div>

                <ConfigProvider renderEmpty={() => <EmptyData />}>
                  <EditableTable
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    columnsData={columnsData}
                    className="mt-3"
                    isRowExpanded={isRowExpanded}
                    clientInfo={clientInfo}
                    selectedCurrency={selectedCurrency}
                  />
                </ConfigProvider>
                <div className="flex mt-6">
                  <Dropdown
                    placement="bottomLeft"
                    overlay={
                      <Menu className="py-0">
                        <Menu.Item
                          className="py-4 flex items-center"
                          key="1"
                          onClick={() => setIsChooseModalVisible(true)}
                        >
                          Select from Activity
                        </Menu.Item>
                        <Menu.Divider className="my-0" />
                        <Menu.Item
                          className="py-4 flex items-center"
                          key="2"
                          onClick={handleAdd}
                        >
                          Add a blank item
                        </Menu.Item>
                      </Menu>
                    }
                    onVisibleChange={() => setIsVisible(!isVisible)}
                    visible={isVisible}
                    trigger={["click"]}
                  >
                    <span
                      onClick={(e) => e.preventDefault()}
                      className="cursor-pointer flex items-center"
                      style={{ color: "#3C2E94" }}
                    >
                      <PlusSquareOutlined style={{ fontSize: "26px" }} />
                      <Text style={{ color: "#3C2E94" }} className="ml-1">
                        Add Items
                      </Text>
                    </span>
                  </Dropdown>
                </div>
                <Row className="mt-10">
                  <Col span={12}>
                    <Text className="font-semibold text-base">Notes</Text>
                    <Field
                      component={AntTextArea}
                      name="notes"
                      placeholder="Add note to you invoice"
                      submitCount={submitCount}
                      hasFeedback
                      size="medium"
                      className="mt-3.5"
                      style={{ minHeight: 100 }}
                    />
                  </Col>
                  <Col span={5} offset={7}>
                    <div className="w-full mt-4">
                      <Text className="font-semibold">Total Due </Text>
                      <Text className="ml-3 font-semibold">{`${selectedCurrency} ${totalDue}`}</Text>
                    </div>
                    {dataSource?.length > 0 && (
                      <div className="mt-14 flex">
                        <Button
                          onClick={() => handleDone()}
                          type="dashed"
                          className="w-32 mb-3"
                          loading={
                            createInvoiceLoading ||
                            invoiceUpdateLoading ||
                            sendInvoiceLoading
                          }
                        >
                          Save draft
                        </Button>
                        <Button
                          onClick={() => {
                            handleDone();
                            setSendInvoice(true);
                          }}
                          type="primary"
                          className="w-32 mb-3 ml-3"
                          loading={
                            createInvoiceLoading ||
                            invoiceUpdateLoading ||
                            sendInvoiceLoading
                          }
                        >
                          Send invoice
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
        {isChooseModalVisible && (
          <ChooseActivityModal
            isVisible={isChooseModalVisible}
            onSubmit={(value, dateRange) => {
              calculateCost(value, dateRange);
            }}
            dataSource={dataSource}
            onClose={() => setIsChooseModalVisible(false)}
          />
        )}
      </Spin>
    </div>
  );
};

export default CreateInvoice;
