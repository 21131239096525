import { useFormikContext } from "formik";
import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Settings from "./Settings";
import { GET_TODOIST } from "graphql/queries/Todoist/getTodoist";
import { useQuery } from "@apollo/client";
import utility from "common/utility";
import _ from "lodash";
import Board from "./Board";
import NewTask from "./NewTask";

const View = ({ view, setSelected, token }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const { values, setFieldValue, submitCount } = useFormikContext();

  const { loading } = useQuery(GET_TODOIST, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    onCompleted: ({ getTodoist }) => {
      setFieldValue("settings", {
        ...getTodoist,
      });
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  return (
    <>
      {view === "settings" && <Settings workspaceId={id} />}
      {view === "board" && <Board workspaceId={id} setSelected={setSelected} />}
      {view === "newtask" && (
        <NewTask workspaceId={id} setSelected={setSelected} token={token} />
      )}
    </>
  );
};

export default View;
