import * as yup from "yup";

const validationSchema = yup.object().shape({
  client_name: yup.string(),
  email: yup.string().email().trim().required("This field is required"),
  company: yup.string().trim(),
  address: yup.string().trim(),
  title: yup.string().trim().required("This field is required"),
  issueDate: yup.date().required("This field is required").nullable(),
  dueDate: yup.date().required("This field is required").nullable(),
  invoicePeriod: yup.string().trim().required("This field is required"),
});

export default validationSchema;
