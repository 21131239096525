import gql from "graphql-tag";

export const RELATED_USER_SEARCH = gql`
  query($input: relatedUserSearchInput) {
    relatedUserSearch(input:$input) {
      _id
      email
      full_name
      occupation
      avatar
      isOnline
    }
  }
`;