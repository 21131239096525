import React from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { useRecoilValue, useRecoilState } from "recoil";
import { Typography, Menu, Dropdown } from "antd";
import {
  CaretDown,
  FlagBanner,
  Crosshair,
  Bank,
  Question,
} from "@phosphor-icons/react";
import { userSession } from "recoil/atoms/User/UserSession";
import { useMutation, useApolloClient } from "@apollo/client";
import { LOGOUT } from "graphql/mutations/user/logout";
import Cookies from "js-cookie";
import { ADDON_INTEGRATIONS } from "constants/plans";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import utility from "common/utility";
import {
  LogoutOutlined,
  MobileOutlined,
  ChromeOutlined,
  AppstoreAddOutlined,
  BulbOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import figmaLogo from "common/assets/figma.svg";
import { Crisp } from "crisp-sdk-web";

const { Text } = Typography;

const modeIcons = {
  command: {
    icon: <FlagBanner style={{ fontSize: "20px", marginLeft: "5px" }} />,
    description: "Lead and Manage",
  },
  focus: {
    icon: <Crosshair style={{ fontSize: "20px", marginLeft: "5px" }} />,
    description: "Create better work habits",
  },
  invest: {
    icon: (
      <Bank
        style={{
          fontSize: "20px",
          marginLeft: "5px",
        }}
      />
    ),
    description: "Fund and analyze project performance",
  },
};

const NavFooter = ({ history, collapsed }) => {
  const client = useApolloClient();
  const user = useRecoilValue(userSession);
  const [selectedModeValue, setMode] = useRecoilState(selectedMode);

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      // clear apollo cache when loging out
      client.clearStore();
      Cookies.remove("token");
      window.location.href = "/login";
    },
  });

  const modes = _.map(
    _.map(user?.settings?.mode, (item) => {
      return {
        label: item,
        value: _.lowerCase(item),
      };
    }),
    (mode) => {
      return (
        <>
          <Menu.Item
            className={`py-3 flex justify-center ${
              mode.value === selectedModeValue && "active"
            }`}
            key={mode.value}
            icon={modeIcons[mode.value].icon}
            onClick={() => {
              setMode(mode.value);
              Cookies.set("mode", mode.value);
            }}
          >
            <div>
              <Text>{mode?.value}</Text>
            </div>
            <Text style={{ color: "#808080" }}>
              {modeIcons[mode.value].description}
            </Text>
          </Menu.Item>

          <Menu.Divider className="my-0" />
        </>
      );
    }
  );

  const userMenu = (
    <Menu className="py-0">
      <Menu.Item
        className="py-3 flex"
        key="quickstart"
        icon={<BulbOutlined className="icon-medium" />}
        title="Quick Start"
        onClick={() => history.push("/quickstart")}
      >
        <Text>Quick Start</Text>
      </Menu.Item>

      <Menu.Divider className="my-0" />
      <Menu.Item
        className="py-3 flex"
        key="help"
        icon={<QuestionCircleOutlined className="icon-medium" />}
        title="Quick Start"
        onClick={() => {
          Crisp.chat.show();
          Crisp.chat.open();
        }}
      >
        <Text>Help Desk</Text>
      </Menu.Item>

      <Menu.Divider className="my-0" />
      {utility.useAddonChecker(ADDON_INTEGRATIONS) && (
        <>
          <Menu.Item
            className="py-3 flex"
            key="integration"
            icon={<AppstoreAddOutlined className="icon-medium" />}
            title="Integration"
            onClick={() => history.push("/integration")}
          >
            <Text> Integration</Text>
          </Menu.Item>
          <Menu.Divider className="my-0" />
        </>
      )}

      <Menu.Item
        className="py-3 flex"
        key="app"
        icon={<MobileOutlined className="icon-medium" />}
        title="App"
      >
        <a
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=fishyvisions.windu.dev"
          target="_blank"
        >
          Mobile App
        </a>
      </Menu.Item>
      <Menu.Divider className="my-0" />

      <Menu.Item
        className="py-3 flex"
        key="extension"
        icon={<ChromeOutlined className="icon-medium" />}
        title="Web Extension"
      >
        <a
          rel="noreferrer"
          href="https://chrome.google.com/webstore/detail/windu-extension/ifpdbifaphmdcmdepoiflbphjbjjnfgl"
          target="_blank"
        >
          Web Extension
        </a>
      </Menu.Item>
      <Menu.Divider className="my-0" />
      <Menu.Item
        className="py-3 flex"
        key="figma"
        icon={<img src={figmaLogo} width={14} />}
        title="Figma Plugin"
      >
        <a
          rel="noreferrer"
          href="https://www.figma.com/community/plugin/1212982774575107679/Windu"
          target="_blank"
        >
          Figma Plugin
        </a>
      </Menu.Item>
      <Menu.Divider className="my-0" />
      <Menu.Item
        className="py-3 flex"
        key="logout"
        onClick={() => logout()}
        icon={<LogoutOutlined className="icon-medium" />}
        title="Logout"
      >
        <Text>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  const modeMenu = <Menu className="py-0">{modes}</Menu>;

  if (collapsed)
    return (
      <>
        <div
          className="mb-3"
          style={{
            cursor: "pointer",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Dropdown
            trigger={["click"]}
            dropdownRender={() => modeMenu}
            placement="top"
          >
            <div className="text-white">
              {modeIcons[selectedModeValue].icon}
            </div>
          </Dropdown>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: 15,
            cursor: "pointer",
          }}
        >
          <Dropdown
            trigger={["click"]}
            dropdownRender={() => userMenu}
            className="tutorial-user"
            placement="top"
          >
            {utility.getAvatar(user)}
          </Dropdown>
        </div>
      </>
    );

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        marginBottom: 15,
      }}
    >
      {utility.getAvatar(user)}
      <div style={{ width: 125 }}>
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => userMenu}
          className="tutorial-user"
          arrow
          placement="top"
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            <Text className="text-white" style={{ fontSize: 17 }}>
              {selectedModeValue}
            </Text>
            {modeIcons[selectedModeValue].icon}
            <CaretDown className="text-white" />
          </div>
        </Dropdown>
        <Dropdown
          trigger={["click"]}
          dropdownRender={() => modeMenu}
          arrow
          placement="top"
        >
          <Text underline style={{ color: "#808080", cursor: "pointer" }}>
            switch modes
          </Text>
        </Dropdown>
      </div>
    </div>
  );
};
export default withRouter(NavFooter);
