import gql from "graphql-tag";

export const GET_TRELLO_BOARD = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTrelloBoards(input: $input) {
      id
      name
    }
  }
`;
