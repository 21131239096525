import React, { useState } from "react";
import {
  Badge,
  Dropdown,
  Typography,
  ConfigProvider,
  List,
  Tooltip,
} from "antd";
import { size, filter, get } from "lodash";
import { useMutation } from "@apollo/client";
import { MailOutlined } from "@ant-design/icons";
import InvitationCard from "./InvitationCard";
import cx from "classnames";
import { REMOVE_REQUEST } from "graphql/mutations/request/removeRequest";
import { RESEND_REQUEST } from "graphql/mutations/request/resendRequest";
import EmptyData from "components/EmptyData";
import Loader from "components/Loader";
import { useRecoilState } from "recoil";
import { requestList } from "recoil/atoms/Requests";
import styles from "./styles.module.less";
import utility from "common/utility";

const { Title } = Typography;

const Invitations = () => {
  const [requests, setRequests] = useRecoilState(requestList);
  const [invitationsDropdownVisible, setInvitationsDropdownVisible] =
    useState(false);

  const [removeRequest, { loading: removing }] = useMutation(REMOVE_REQUEST, {
    onCompleted: ({ removeRequest }) => {
      const filtered = filter(
        requests,
        (request) => request._id !== removeRequest._id
      );
      setRequests(filtered);
      return;
    },
  });

  const [resendRequest, { loading: sending }] = useMutation(RESEND_REQUEST, {
    onCompleted: ({ resendRequest }) => {
      const requestsClone = [...requests];
      const index = requests.findIndex((e) => e._id === resendRequest._id);

      if (index >= 0) {
        requestsClone[index] = resendRequest;
        setRequests(requestsClone);
      }
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const loading = {
    spinning: removing || sending,
    indicator: <Loader size="large" />,
  };

  const InvitationsMenu = () => {
    return (
      <div
        className={cx("bg-white", styles.listContainer)}
        style={{ width: "450px" }}
      >
        <div className="h-16 border-b-2 flex items-center justify-between px-3">
          <Title level={5}>Invitations</Title>
        </div>
        <div style={{ maxHeight: "73vh" }} className="overflow-y-auto p-0">
          <ConfigProvider renderEmpty={() => <EmptyData />}>
            <List
              loading={loading}
              itemLayout="vertical"
              dataSource={requests}
              className=" p-0"
              renderItem={(request) => {
                return (
                  <InvitationCard
                    key={request._id}
                    onRemove={(id) => removeRequest({ variables: { id } })}
                    onResend={(id) => resendRequest({ variables: { id } })}
                    {...request}
                  />
                );
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    );
  };

  const pendingRequests = filter(
    requests,
    (request) => request.status === "Pending"
  );

  return (
    <Dropdown
      className="mr-12 tutorial-invitations"
      placement="bottomRight"
      trigger={["click"]}
      overlay={InvitationsMenu}
      arrow
      visible={invitationsDropdownVisible}
      onOpenChange={setInvitationsDropdownVisible}
    >
      <Badge
        className="cursor-pointer"
        count={size(pendingRequests)}
        offset={50}
        onClick={(e) => e.preventDefault()}
      >
        <Tooltip title="Invitations">
          <MailOutlined style={{ color: "#3C2E94" }} className="icon" />
        </Tooltip>
      </Badge>
    </Dropdown>
  );
};
export default Invitations;
