import gql from "graphql-tag";

export const DISMISS_NOTIFICATION = gql`
  mutation($id: String!) {
    dismissNotification(id: $id) {
      _id
      title
      subject
    }
  }
`;
