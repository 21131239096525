import React, { useState, useCallback, useEffect } from "react";
import Joyride from "react-joyride";
import { steps } from "./steps";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import { useRecoilState } from "recoil";

const Tour = ({ isOpen, onClose }) => {
  const [run, setRun] = useState(isOpen);
  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);
  const [key, setKey] = useState(0);

  const handleJoyrideCallback = (data) => {
    const isLastStep = data?.index === steps.length - 1;
    if (isLastStep) {
      if (data.action === "next" && data.lifecycle === "complete") {
        setRun(false);
        setStepIndex({ step: 0, isAcitve: false });
        onClose();
      } else if (data.action === "prev" && data.lifecycle === "complete") {
        setStepIndex({ step: data.index - 1, isAcitve: true });
      }
    } else if (data.action === "close" || data.type === "finished") {
      setRun(false);
      setStepIndex({ step: 0, isAcitve: false });
      onClose();
    } else if (data.action === "skip") {
      setRun(false);
      setStepIndex({ step: 0, isAcitve: false });
    } else if (data.action === "next" && data.lifecycle === "complete") {
      setStepIndex({ step: data.index + 1, isAcitve: true });
    } else if (data.action === "prev" && data.lifecycle === "complete") {
      setStepIndex({ step: data.index - 1, isAcitve: true });
    }
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [stepIndex]);

  return (
    <Joyride
      key={key}
      run={run}
      stepIndex={stepIndex.step}
      steps={steps}
      styles={{
        options: {
          zIndex: 99999999999,
        },
        buttonBack: {
          color: "black",
        },
        buttonNext: {
          backgroundColor: "#F5A623",
        },
      }}
      callback={handleJoyrideCallback}
      continuous
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      spotlightClicks={true}
      hideCloseButton={true}
      locale={{ last: "Finish" }}
    />
  );
};

export default Tour;
