import { Modal, Radio, Tag } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import _ from "lodash";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import ReactPlayer from "react-player";

const list = [
  "Website",
  "LinkedIn",
  "Twitter",
  "Facebook",
  "Instagram",
  "Product Hunt",
  "Internet Search",
  "Word of Mouth",
  "Others",
];

const HearAboutUs = ({ isVisible, onClose, user }) => {
  const [value, setValue] = useState(null);
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        onClose();
      },
    }
  );
  const handleSubmit = () => {
    updateUser({
      variables: {
        input: {
          onboarding_data: {
            ...user.onboarding_data,
            redirected: value,
          },
        },
      },
    });
  };
  return (
    <Modal
      visible={isVisible}
      title="Hey 👋 How did your hear about Windu?"
      okText="Submit"
      okButtonProps={{
        className: `outline-none border-none ${styles.blackNextButton}`,
        loading: updateUserLoading,
      }}
      onCancel={onClose}
      onOk={handleSubmit}
    >
      <ReactPlayer
        controls={true}
        playing={true}
        width="100%"
        height="100%"
        url="https://windu.s3.us-east-2.amazonaws.com/assets/evanIntro.mp4"
      />

      <div className="flex mt-8">
        <Radio.Group value={value} onChange={(v) => setValue(v.target.value)}>
          {_.map(list, (item) => (
            <Radio.Button className="ml-2 mb-2" value={item} key={item}>
              {item}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </Modal>
  );
};

export default HearAboutUs;
