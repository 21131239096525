import { atom } from "recoil";

export const refetchIndicator = atom({
  key: "refetchIndicator",
  default: {
    dashboard: false,
    projects: false,
    timeline: false,
    clients: false,
    members: false,
    managers: false,
  },
});
