import {
  FolderOpenOutlined,
  LikeOutlined,
  NotificationOutlined,
  RocketOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Button, Card, Popconfirm, Typography } from "antd";
import Reminder from "components/Reminder";
import { UPDATE_SETTINGS_BY_USERID } from "graphql/mutations/user/updateSettingByUserId";
import { GET_USER } from "graphql/queries/getUser";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import utility from "common/utility";
import { CREATE_EXAMPLE_PROJECT } from "graphql/mutations/projects/createExampleProject";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateProjectModal from "components/Modals/CreateProjectModal";
import TourSuccess from "components/Modals/TourSuccess";
import queryString from "query-string";
import InviteOtherModal from "components/Modals/InviteOther";
import { ToggleLeft, UserSwitch } from "@phosphor-icons/react";
import SelectModeModal from "components/Modals/SelectMode";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { Crisp } from "crisp-sdk-web";

const { Text } = Typography;

const ProgressSteps = ({ stepCovered }) => {
  const history = useHistory();
  const location = useLocation();
  const tour = queryString.parse(location?.search).tour;
  const [tourModal, setTourModal] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [exampleProjectCreated, setexampleProjectCreated] = useState(false);
  const [inviteOtherModal, setInviteOtherModal] = useState(false);
  const [selectModeModal, setselectModeModal] = useState(false);
  const user = useRecoilValue(userSession);

  const isDefaultModeSelected = !_.isEmpty(user?.settings?.mode);

  const [updateUserSetting, { loading: updateUserSettingLoading }] =
    useMutation(UPDATE_SETTINGS_BY_USERID, {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        if (exampleProjectCreated) {
          setTimeout(() => {
            history.push("/?withTour=true");
          }, 1000);
        }
      },
      refetchQueries: [GET_USER],
    });

  const [createExampleProject, { loading: projectLoading }] = useMutation(
    CREATE_EXAMPLE_PROJECT,
    {
      onCompleted: () => {
        setexampleProjectCreated(true);
        updateUserSetting({
          variables: {
            input: {
              quickStart: { ...stepCovered, exampleProject: true },
            },
          },
        });
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_USER],
    }
  );

  useEffect(() => {
    if (tour == "completed") {
      setTourModal(true);
    }
  }, [tour]);

  return (
    <div>
      {/*<Card className="mb-4 rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={styles.iconDiv}>
              <NotificationOutlined
                style={{ color: "#F39A31", fontSize: 34 }}
              />
            </div>
            <Text className="text-base ml-2">
              Set how long you want to quick start
            </Text>
          </div>
          {stepCovered?.setReminder ? (
            <Button type="default" disabled icon={<LikeOutlined />}>
              Done
            </Button>
          ) : (
            <Reminder
              fromQuickStart={true}
              callback={() => {
                getReminder();
                updateUserSetting({
                  variables: {
                    input: {
                      quickStart: { ...stepCovered, setReminder: true },
                    },
                  },
                });
              }}
            />
          )}
        </div>
      </Card>*/}

      <Card className="mb-4 rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={styles.iconDiv}>
              <ToggleLeft size={24} color="#F39A31" weight="bold" />
            </div>
            <Text className="text-base ml-2">Set your default mode.</Text>
          </div>

          {isDefaultModeSelected ? (
            <Button type="default" disabled icon={<LikeOutlined />}>
              Done
            </Button>
          ) : (
            <Button
              style={{ width: 130 }}
              type="newUser"
              onClick={() => {
                setselectModeModal(true);
                Crisp.session.pushEvent("newUser", {
                  plan: user?.plan?.name,
                  userId: user?._id,
                });
              }}
            >
              Select mode
            </Button>
          )}
        </div>
      </Card>

      <Card className="mb-4 rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={styles.iconDiv}>
              <RocketOutlined style={{ color: "#F39A31", fontSize: 34 }} />
            </div>
            <Text className="text-base ml-2">
              Jumpstart with a tour of an Example project
            </Text>
          </div>

          {stepCovered?.exampleProject ? (
            <Button type="default" disabled icon={<LikeOutlined />}>
              Done
            </Button>
          ) : (
            <Popconfirm
              title="Generating this example project will start an interactive tour."
              onConfirm={() => createExampleProject()}
              okText="Confirm"
              disabled={!isDefaultModeSelected}
            >
              <Button
                style={{ width: 130 }}
                loading={projectLoading}
                type="default"
                disabled={!isDefaultModeSelected}
              >
                Generate
              </Button>
            </Popconfirm>
          )}
        </div>
      </Card>

      <Card className="mb-4 rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={styles.iconDiv}>
              <FolderOpenOutlined style={{ color: "#F39A31", fontSize: 34 }} />
            </div>
            <Text className="text-base ml-2">Create your first project</Text>
          </div>
          {stepCovered?.firstProject ? (
            <Button type="default" disabled icon={<LikeOutlined />}>
              Done
            </Button>
          ) : (
            <Button
              onClick={() => setCreateProjectModal(true)}
              style={{ width: 130 }}
              type="default"
              disabled={!isDefaultModeSelected}
            >
              Get started
            </Button>
          )}
        </div>
      </Card>
      <Card className="mb-4 rounded-xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className={styles.iconDiv}>
              <UserAddOutlined style={{ color: "#F39A31", fontSize: 34 }} />
            </div>
            <Text className="text-base ml-2">Invite others</Text>
          </div>

          <Button
            style={{ width: 130 }}
            type="default"
            onClick={() => setInviteOtherModal(true)}
            disabled={!isDefaultModeSelected}
          >
            Invite
          </Button>
        </div>
      </Card>

      {tourModal && (
        <TourSuccess
          isVisible={tourModal}
          onClose={() => {
            setTourModal(false);
            history.push("/quickstart");
          }}
        />
      )}
      {createProjectModal && (
        <CreateProjectModal
          callback={() => {
            updateUserSetting({
              variables: {
                input: {
                  quickStart: { ...stepCovered, firstProject: true },
                },
              },
            });
          }}
          isVisible={createProjectModal}
          onClose={() => setCreateProjectModal(false)}
        />
      )}

      {inviteOtherModal && (
        <InviteOtherModal
          isVisible={inviteOtherModal}
          updateUserSetting={() => {
            if (!stepCovered.inviteOther) {
              updateUserSetting({
                variables: {
                  input: {
                    quickStart: { ...stepCovered, inviteOther: true },
                  },
                },
              });
            }
          }}
          onClose={() => {
            setInviteOtherModal(false);
          }}
        />
      )}
      {selectModeModal && (
        <SelectModeModal
          isVisible={selectModeModal}
          user={user}
          setVisible={setselectModeModal}
          callback={() => {
            updateUserSetting({
              variables: {
                input: {
                  quickStart: { ...stepCovered, defaultModeSet: true },
                },
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default ProgressSteps;
