import { Input, Menu, Typography } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";
import { CloseOutlined } from "@ant-design/icons";

const AssigneSelectDropdown = ({
  handleSearch,
  values,
  filterSearchUser,
  setValue,
  value,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [editMode, setEditMode] = useState(false); // Add editMode state

  useEffect(() => {
    handleSearch(searchValue, values);
  }, [searchValue]);

  const handleClick = () => {
    setEditMode(true); // Enable edit mode when the selected item is clicked
    setValue(null);
  };

  const handleInputBlur = () => {
    setEditMode(false); // Disable edit mode when input loses focus
  };

  return (
    <div className="bg-white rounded p-2">
      {editMode ? ( // Render the input field when in edit mode
        <Input
          value={searchValue}
          onChange={(v) => setSearchValue(v.target.value)}
          onBlur={handleInputBlur}
          autoFocus // Focus the input field on edit mode
        />
      ) : (
        <div onClick={handleClick}>
          {value ? (
            <div
              className="flex items-center justify-between p-1 rounded"
              style={{ border: "1px solid #d9d9d9" }}
            >
              <div>
                <UserAvatar user={value} size={24} className="mr-2" />
                <Typography.Text>{value?.email}</Typography.Text>
              </div>
              <CloseOutlined className="ml-2 text-xs" />
            </div>
          ) : (
            <Input
              value={searchValue}
              onChange={(v) => setSearchValue(v.target.value)}
            />
          )}
        </div>
      )}

      <Menu className="max-h-48 overflow-y-auto bg-white shadow-none">
        {_.map(filterSearchUser, (user) => (
          <Menu.Item
            style={{ background: user === value && "#F39A31" }}
            key={user?._id}
            onClick={() => setValue(user)}
          >
            <UserAvatar user={user} size={24} className="mr-2" />
            <Typography.Text>{user?.email}</Typography.Text>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default AssigneSelectDropdown;
