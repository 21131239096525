import React, { useEffect, useState } from "react";
import utility from "common/utility";
import _ from "lodash";
import { CREATE_INTEGRATION } from "graphql/mutations/Integrations/createIntegration";
import { useMutation } from "@apollo/client";

const useCreateIntegrationHook = () => {
  const [createIntegrationMutation, { loading: stopping }] = useMutation(
    CREATE_INTEGRATION,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );
  const createIntegration = ({
    type,
    token,
    workspace,
    access_token,
    refresh_token,
    setAuthenticated,
    callback,
  }) => {
    createIntegrationMutation({
      variables: {
        input: { type, token, workspace, access_token, refresh_token },
      },
    }).then(({ data: { createIntegration } }) => {
      if (callback) callback();
      if (setAuthenticated) setAuthenticated(createIntegration?.access_token);
    });
  };

  return { createIntegration, loading: stopping };
};

export default useCreateIntegrationHook;
