import React from "react";
import { Typography, Radio } from "antd";
import { map } from "lodash";
import { onBoardingInfoV2 } from "../onBoardingData";

const { Text } = Typography;

const Second = ({ values, setFieldValue, errors }) => {
  const hasErrors = errors?.weeklyWorkHours;

  return (
    <div className=" mt-12">
      <Text className="text-lg">
        {onBoardingInfoV2().WeeklyWorkHours.question}
      </Text>
      <div>
        <Text className=" mt-1 mb-3">
          Select the hours you currently work or a range that you want to work
          towards.
        </Text>
      </div>
      {hasErrors && (
        <div>
          <Text style={{ color: "#ff4d4f" }}>Please select atleast one</Text>
        </div>
      )}
      <div className="flex mt-8">
        <Radio.Group
          onChange={(v) => setFieldValue("weeklyWorkHours", v?.target.value)}
          defaultValue={values.weeklyWorkHours}
        >
          {map(onBoardingInfoV2().WeeklyWorkHours.options, (item) => (
            <Radio.Button className="ml-2" value={item.value} key={item.key}>
              {item.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default Second;
