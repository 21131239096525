import gql from "graphql-tag";

export const GET_USER_BY_TOKEN = gql`
  query($token: String!) {
    getUserByToken(token: $token) {
      _id
      email
      full_name
    }
  }
`;
