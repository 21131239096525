import gql from "graphql-tag";

export const GET_VISITOR_INVOICES = gql`
  query ($input: getVisitorInvoicesInput) {
    getVisitorInvoices(input: $input) {
      data {
        _id
        name
        status
        invoiceURL
        created_at
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
