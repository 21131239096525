import React, { useState, useEffect } from "react";
import Auth from "./Auth";
import { Form, Formik } from "formik";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import TodoistLogo from "../../../common/assets/todoist.png";
import { Menu, Button } from "antd";
import Card from "../Card";
import View from "./View";

import _ from "lodash";
import utility from "common/utility";
import { CONNECT_TODOIST } from "graphql/mutations/Todoist/connectTodoist";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TODOIST } from "graphql/mutations/Todoist/updateTodoist";
import { GET_TODOIST_TASK } from "graphql/queries/Todoist/getTodoistTasks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";

const Todoist = ({ location }) => {
  const [selected, setSelected] = useState("board");
  const [authenticated, setAuthenticated] = useState(false);
  const { id } = queryString.parse(location.search);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  const { createIntegration } = useCreateIntegrationHook();

  const { loading: widgetLoading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "todoist", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setAuthenticated(getWorkspaceIntegration?.access_token);
      }
    },
  });

  const [getAccessToken, { loading }] = useMutation(CONNECT_TODOIST, {
    onCompleted: ({ connectTodoist }) => {
      createIntegration({
        type: "todoist",
        access_token: connectTodoist.access_token,
        workspace: id,
      });

      setSelected("board");

      setAuthenticated(connectTodoist.access_token);
      window.history.pushState({ path: `` }, "", `/workspace?id=${id}`);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });
  const [updateTodoist, { loading: updateLoading }] = useMutation(
    UPDATE_TODOIST,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_TODOIST_TASK,
          variables: { input: { workspace: id } },
        },
      ],
    }
  );

  useEffect(() => {
    const { integration, code, id } = queryString.parse(location.search);
    //  handle auth
    if (integration === "todoist" && code) {
      getAccessToken({ variables: { input: { code, workspace: id } } });
    }
  }, [location]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const saveSync = (values) => {
    updateTodoist({
      variables: {
        input: {
          workspace: id,
          sync: _.map(values?.settings?.sync, (item) => {
            return {
              todoist_project: item.todoist_project,
              windu_project: item.windu_project,
              todoist_project_label: item.todoist_project_label,
            };
          }),
        },
      },
    });
    setSelected("board");
  };

  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "todoist" });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="board">
        <a>View</a>
      </Menu.Item>
      <Menu.Item key="newtask">
        <a>New task</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7546468817047-Todoist"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );
  const bodyHeight = selected === "settings" ? "70%" : "83%";
  return authenticated ? (
    <Formik
      enableReinitialize={true}
      validationSchema={null}
      initialValues={{
        settings: {
          sync: [],
        },
      }}
      render={({ values, handleSubmit }) => (
        <Form>
          <Card
            bodyStyle={{
              height: enlargeModal.visible ? " 90% " : bodyHeight,
              overflow: "auto",
            }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: 25 }}
                  className="mr-3"
                  src={TodoistLogo}
                  alt="Todoist"
                />
                <h5>Todoist</h5>
              </div>
            }
            action={[
              selected === "settings" && (
                <div className="text-right pr-3">
                  <Button onClick={() => saveSync(values)} type="default">
                    Save
                  </Button>
                </div>
              ),
            ]}
            menu={menu}
          >
            <View
              view={selected}
              setSelected={setSelected}
              token={authenticated}
            />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth
      loading={widgetLoading}
      setAuthenticated={setAuthenticated}
      isAuthenticated={authenticated}
      updateTodoist={updateTodoist}
    />
  );
};

export default withRouter(Todoist);
