import gql from "graphql-tag";

export const NEW_NOTIFICATION = gql`
  subscription newNotification {
    newNotification {
      _id
      title
      subject
      type
      status
      url
      meta {
        key
        value
      }
      created_at
      updated_at
    }
  }
`;
