import gql from "graphql-tag";

export const CREATE_BACKLOG_GROUP = gql`
  mutation createBacklogGroup($input: createBacklogGroupInput) {
    createBacklogGroup(input: $input) {
      _id
      title
    }
  }
`;
