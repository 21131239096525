import React from "react";

const Board = ({ id }) => {
  return (
    <iframe
      width="100%"
      height="100%"
      src={`https://miro.com/app/live-embed/${id}`}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
    ></iframe>
  );
};

export default Board;
