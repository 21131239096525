import React, { useEffect, useState } from "react";
import { CREATE_BILLING_ACCOUNT } from "graphql/mutations/Stripe/createBillingAccount";
import { GET_ACCOUNTS_BY_USER } from "graphql/queries/user/getAccountsByUser";
import { map } from "lodash";
import { getAccountInfo } from "api/Stripe";

import { useMutation, useQuery } from "@apollo/client";

export const useCreateBillingAccount = () => {
  const [createBillingAccount, { loading }] = useMutation(
    CREATE_BILLING_ACCOUNT,
    {
      onCompleted: ({ createBillingAccount }) => {
        window.location.href = createBillingAccount;
      },
    }
  );

  const createAccount = ({ workspace }) => {
    // createAccount
    createBillingAccount({
      variables: {
        input: {
          projectId: workspace?.project?._id,
          workspaceId: workspace?._id,
          return_url: window.location.href, /// Inject URL
        },
      },
    });
  };

  return { createAccount };
};
