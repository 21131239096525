import React from "react";
import Settings from "./Settings";
import OrganizationView from "./OrganizationView";

const View = ({ token, view, repos, githubUser }) => {
  return (
    <>
      {view === "settings" && (
        <Settings githubUser={githubUser} token={token} repos={repos} />
      )}
      {view === "pull_requests" && (
        <OrganizationView token={token} repos={repos} />
      )}
    </>
  );
};
export default View;
