import gql from "graphql-tag";

export const PAUSE_ACTIVITY = gql`
  mutation ($input: activityInput) {
    pauseActivity(input: $input) {
      _id
      title
      description
      meta
      status
      activity_type
      project {
        _id
        title
        color
      }
      time {
        start
        total_time
        end
        created_at
        paused {
          total
          created_at
          time
          continue
        }
      }
      created_by {
        email
        full_name
        avatar
      }
      created_at
    }
  }
`;
