import React, { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Button } from "antd";
import { Formik, Form } from "formik";
import { get, trim, isNil } from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import Modal from "../BaseModal/Modal";
import CreateProjectInfo from "./CreateProjectInfo";
import { GET_PROJECTS } from "graphql/queries/projects/getProjects";
import { GET_REQUESTS } from "graphql/queries/request/getRequests";
import { GET_USER } from "graphql/queries/getUser";
import { CREATE_PROJECT } from "graphql/mutations/projects/createProject";
import validationSchema from "./validationSchema";
import { bool, func, number } from "prop-types";
import utility from "common/utility";
import {
  updateProjectOverview,
  updateProjectPerformance,
} from "common/cacheUtilities";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { useLimitWithInvitee } from "hooks/payPerUser";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import { useSetRecoilState } from "recoil";
import { workspacesList } from "recoil/atoms/Workspaces";
import { INDIVIDUAL_PLAN, TEAM_PLAN } from "constants/plans";

const initialFormValues = {
  title: "",
  description: "",
  selectManager: undefined,
  selectMembers: [],
};

const CreateProjectModal = ({
  isVisible,
  onClose,
  queryParameters,
  callback,
}) => {
  const user = useRecoilValue(userSession);
  const { pastLimit, setUserLimit } = useLimitWithInvitee();
  const [promptSetLimit, setPromptSetLimit] = useState(false);
  const [projectColor, setProjectColor] = useState("#808080");
  const formRef = React.useRef();
  const location = useLocation();
  const history = useHistory();
  const isIndividualPlan =
    user?.plan?.name === INDIVIDUAL_PLAN || isNil(user?.plan);
  const setWorkspaces = useSetRecoilState(workspacesList);

  const [getWorkspaces, { _, loading: gettingWorkspaceLoading }] = useLazyQuery(
    GET_WORKSPACES,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getWorkspaces }) => {
        setWorkspaces(getWorkspaces);
        handleCancel();
      },
    }
  );

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    onCompleted: ({ createProject }) => {
      if (callback) callback();
      utility.setNotification(
        "Project created successfully",
        `${createProject.title} has been created`,
        "success",
        "topRight"
      );
      createGoogleEvent(user?._id, "PROJECT_CREATED", "app_engagement");

      const { pathname } = location;
      if (pathname === "/") history.push("/projects");
      setProjectColor("none");
      getWorkspaces();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PROJECTS,
        variables: { input: queryParameters },
      },
      {
        query: GET_REQUESTS,
      },
      {
        query: GET_USER,
      },
    ],
    update: (cache, { data: { createProject } }) => {
      updateProjectOverview(cache, "add");
      updateProjectPerformance(cache, createProject, "add");
    },
  });

  const onSubmit = (values) => {
    const { title, description, selectManager, selectMembers } = values;
    const isPastLimit = pastLimit([...selectMembers, selectManager]);

    if (isPastLimit && user.plan.name === TEAM_PLAN) {
      if (isNil(setUserLimit)) {
        setPromptSetLimit(true);
        // lets prompt them them to set
      } else {
        // if already set lets prompt them that they are alreadty past limit and to change in settings
        setPromptSetLimit(true);
      }
    } else {
      let payload = {
        title: trim(title),
        description,
        manager: user.email,
        members: [],
        color: `${projectColor}`,
      };

      if (!isIndividualPlan) {
        payload.manager = selectManager || user.email;
        payload.members = selectMembers;
      }

      createProject({
        variables: {
          input: payload,
        },
      });
    }
  };

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose();
  };

  if (promptSetLimit) {
    utility.setNotification(
      "User limit exceed.",
      "In order to select more users please set more users to your current plan",
      "error",
      "topRight"
    );
  }

  return (
    <Modal
      title="New Project"
      width={500}
      footer={null}
      visible={isVisible}
      onClose={handleCancel}
      content={
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ submitCount, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <CreateProjectInfo
                  setFieldValue={setFieldValue}
                  submitCount={submitCount}
                  isIndividualPlan={isIndividualPlan}
                  setProjectColor={setProjectColor}
                />
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center">
          <Button onClick={() => handleCancel()} className="w-32">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => handleDone()}
            className="ml-3 w-32"
            loading={loading || gettingWorkspaceLoading}
          >
            Done
          </Button>
        </div>
      }
    />
  );
};

CreateProjectModal.propTypes = {
  isVisible: bool,
  onClose: func,
  size: number,
  offset: number,
};

export default CreateProjectModal;
