import React from "react";
import { DownSquareFilled, UpSquareFilled } from "@ant-design/icons";
import { Button, Input, List, Popconfirm, Typography } from "antd";
import { planInfoV2 } from "../planInfo";
import styles from "../styles.module.css";
import getSymbolFromCurrency from "currency-symbol-map";
import _ from "lodash";
import {
  ANNUAL_DISCOUNT_PERCENTAGE,
  TEAM_PLAN,
  TEAM_PLAN_AMOUNT,
} from "constants/plans";

const Team = ({
  currency,
  rate,
  plans,
  handleSubscribe,
  user,
  selectedPlan,
  userCount,
  setUserCount,
  isTrialUser,
  isAnnualPlan,
}) => {
  const currentPlan = user?.plan?.name === TEAM_PLAN;
  const showTrialButton =
    _.isEmpty(user?.settings?.trial) &&
    _.isEmpty(user?.plan) &&
    _.isEmpty(selectedPlan) &&
    !isTrialUser;

  const planRate = (TEAM_PLAN_AMOUNT * rate).toFixed(2);
  const discountPercentage = ANNUAL_DISCOUNT_PERCENTAGE;

  // Calculate discounted value
  const discountedValue = planRate - planRate * (discountPercentage / 100);

  // Format the result
  const formattedDiscountedValue = discountedValue.toFixed(2);

  return (
    <div className={styles.proContainer}>
      <div className={styles.proRight}></div>
      <div className={styles.proTop}></div>
      <div className={styles.proPlanDetails}>
        <div className="m-8">
          <div className="flex items-center">
            <Typography.Title level={4}>Team</Typography.Title>
            {currentPlan && (
              <>
                <Typography.Text className="ml-2">
                  ( current plan )
                </Typography.Text>
              </>
            )}
          </div>
          {_.isEmpty(selectedPlan) && (
            <div className="flex mt-1 items-center">
              <div>
                {isAnnualPlan ? (
                  <div className="flex items-center">
                    <Typography.Text className="text-xl text-gray-700 line-through">
                      {getSymbolFromCurrency(currency)}
                      {planRate}
                    </Typography.Text>

                    <Typography.Text className="text-2xl ml-2" strong>
                      {getSymbolFromCurrency(currency)}
                      {formattedDiscountedValue}
                    </Typography.Text>
                  </div>
                ) : (
                  <Typography.Text className="text-2xl" strong>
                    {getSymbolFromCurrency(currency)}
                    {planRate}
                  </Typography.Text>
                )}
              </div>
              <div className="flex flex-col ml-1">
                <Typography.Text className="mt-0">per user</Typography.Text>
                <Typography.Text className="mt-0">per month</Typography.Text>
              </div>
            </div>
          )}

          {!_.isEmpty(selectedPlan) && (
            <>
              <Typography.Text className="mt-2" strong>
                How many users do you want on your team?
              </Typography.Text>
              <div className="flex items-center mt-3 w-full">
                <Input
                  style={{ background: "#F3A536" }}
                  className="h-12 w-full outline-none border-none text-2xl text-white ant-input-number-input"
                  value={userCount}
                  onChange={(v) => setUserCount(v?.currentTarget?.value)}
                  type="number"
                  min={Number(user?.extra?.uniqueProjectsParticipants.length)}
                />
                <div className="flex flex-col">
                  <UpSquareFilled
                    style={{ fontSize: 24 }}
                    className="cursor-pointer"
                    onClick={() => setUserCount(Number(userCount) + 1)}
                  />
                  <DownSquareFilled
                    style={{ fontSize: 24 }}
                    className="cursor-pointer"
                    onClick={() =>
                      userCount >
                        Number(
                          user?.extra?.uniqueProjectsParticipants.length
                        ) && setUserCount(userCount - 1)
                    }
                  />
                </div>
              </div>
            </>
          )}

          {_.isEmpty(selectedPlan) && (
            <Button
              className="w-full mt-8 mb-1"
              size="large"
              type="primary"
              onClick={() =>
                handleSubscribe(
                  _.find(
                    plans,
                    (item) =>
                      item?.name === TEAM_PLAN &&
                      item?.period === (isAnnualPlan ? "Annual" : "Monthly")
                  ),
                  userCount,
                  false
                )
              }
            >
              {user?.plan?.name === TEAM_PLAN ? "Update " : "Choose "}
              Plan
            </Button>
          )}
          {showTrialButton && (
            <div className="text-center mt-1">
              <Button
                className="w-full my-1 bg-transparent text-black border-black"
                size="large"
                type="default"
                onClick={() => {
                  handleSubscribe(
                    _.find(
                      plans,
                      (item) =>
                        item?.name === TEAM_PLAN &&
                        item?.period === (isAnnualPlan ? "Annual" : "Monthly")
                    ),
                    userCount,
                    true
                  );
                }}
              >
                Start Free Trial
              </Button>
            </div>
          )}

          <div>
            <List
              className="mt-5 ml-2"
              dataSource={planInfoV2({ currency, rate })[TEAM_PLAN]?.details}
              renderItem={(item) => (
                <List.Item style={{ border: "none", padding: "5px 0" }}>
                  <div className="flex items-center">
                    {item?.icon}
                    <div className="flex items-center">
                      <Typography.Text>{item.text}</Typography.Text>
                      {item?.info}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
