import gql from "graphql-tag";

export const GET_JIRA = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getJira(input: $input) {
      server_url
      server_name
      sprint_field
      sync {
        jira_project
        windu_project
        column_pull
        column_push_start {
          status
          transition
        }
        column_push_finished
      }
    }
  }
`;
