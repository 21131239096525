import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import styles from "./styles.module.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import ProfileView from "./View/Profile";
import SubscribedConfirmedModal from "components/Modals/SubscribedConfirmedModal";
import Invites from "./View/Invites";
import Billings from "./View/Billings";
import Subscriptions from "./View/Subscriptions";
import { REMOVE_USER } from "graphql/mutations/Admin/removeUser";
import { useMutation } from "@apollo/client";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import utility from "common/utility";
import { LogoutOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import Rewards from "./View/Rewards";

const { Text, Title } = Typography;

const settingData = ["profile", "billing", "subscriptions", "referrals"];

const Setting = ({ forceView }) => {
  const [selectedView, setSelectedView] = useState("profile");
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const { view, subscribedWithPlan, plan, session_id } = query;
  const [isVisible, setIsVisible] = useState(false);
  const user = useRecoilValue(userSession);
  const [settingNav, setSettingNav] = useState(settingData);

  const [removeUser] = useMutation(REMOVE_USER, {
    onCompleted: () => {
      history.push("/login");
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });
  useEffect(() => {
    const { plan, extra } = user;
    const {
      foreignPlan: { plan: foreignPlan, addons: foreignAddons },
    } = extra;

    const userPlan = foreignPlan || plan?.name;
    if (userPlan === "Team") {
      setSettingNav([
        "profile",
        "billing",
        "subscriptions",
        "referrals",
        "rewards",
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (subscribedWithPlan) {
      setIsVisible(true);
    }
  }, [subscribedWithPlan]);

  const handleViewChange = (view) => {
    setSelectedView(view);
    history.push(`settings?view=${view}`);
  };

  useEffect(() => {
    if (_.isEmpty(view)) {
      history.push("settings?view=profile");
    } else {
      setSelectedView(view);
    }
    if (forceView && !subscribedWithPlan) {
      handleViewChange(forceView);
    }
  }, []);

  const getView = () => {
    const views = {
      profile: <ProfileView handleViewChange={handleViewChange} />,
      referrals: <Invites />,
      billing: <Billings handleViewChange={handleViewChange} />,
      subscriptions: <Subscriptions handleViewChange={handleViewChange} />,
      rewards: <Rewards handleViewChange={handleViewChange} />,
    };

    return views[view] || <ProfileView handleViewChange={handleViewChange} />;
  };

  return (
    <div className="bg-white rounded-md p-8  min-h-full">
      <Row className="flex flex-col">
        <Title level={3}>My Profile</Title>
        <Text className="text-base">Manage your profile settings</Text>
      </Row>
      <Row className="mt-8">
        <Col
          className="pr-8 pt-4"
          style={{ borderRight: "2px solid #E0E0E0", width: "15%" }}
        >
          <div className="flex flex-col">
            {_.map(settingNav, (item) => {
              return (
                <div
                  key={item}
                  className={`px-4 py-2 mb-2 text-base capitalize cursor-pointer ${
                    item == selectedView && styles.selectedView
                  }`}
                  onClick={() => handleViewChange(item)}
                >
                  {item}
                </div>
              );
            })}
            {/*  <Popconfirm
              title="Are you sure to delete the account ?"
              onConfirm={() => removeUser({ variables: { id: user?._id } })}
            >
              <Button
                type="text"
                style={{ color: "red" }}
                className="mt-12 text-base"
              >
                Delete Account
              </Button>
          </Popconfirm>*/}
            {forceView && (
              <Button
                type="text"
                style={{ color: "red" }}
                className="mt-12 text-base"
                icon={<LogoutOutlined className="icon-medium" />}
                onClick={() => {
                  Cookies.remove("token");
                  window.location.href = "/login";
                }}
              >
                Logout
              </Button>
            )}
          </div>
        </Col>
        <Col style={{ width: "83%" }} className="ml-4 pt-4">
          {getView()}
        </Col>
      </Row>
      {isVisible && (
        <SubscribedConfirmedModal
          plan={plan}
          session_id={session_id}
          onClose={() => setIsVisible(false)}
          isVisible={isVisible}
        />
      )}
    </div>
  );
};

export default Setting;
