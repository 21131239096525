import axios from "axios";
import _ from "lodash";
import { api } from "common/constants";

export const uploadActivityFiles = async ({
  userId,
  fileList,
  updateProgress,
}) => {
  let formData = new FormData();
  _.map(fileList, (file) => formData.append("files", file.originFileObj));
  formData.append("userId", userId);

  let config = {
    header: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      updateProgress(percentComplete);
    },
  };

  const result = await axios.post(
    `${api}/activity/upload/files`,
    formData,
    config
  );

  const { data = {} } = result;

  return data;
};
