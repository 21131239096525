import React from "react";
import { Typography, Card, Pagination, Alert } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { useLazyQuery } from "@apollo/client";
import { map, truncate, isEmpty } from "lodash";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { GET_MEMBERS } from "graphql/queries/member/getMembers";
import UserAvatar from "components/UserAvatar";
import AddRate from "components/Modals/MemberRate";
import Loader from "components/Loader";
import styles from "./styles.module.css";

const { Text, Title } = Typography;

const sizePerPage = 5;
const initialOffset = 0;

const TeamMembers = ({ missingRateWarning }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const workspace = useRecoilValue(workspaceSelected);
  const [members, setMembers] = React.useState([]);
  const [memberSelected, setMemberSelected] = React.useState(null);

  const [getMembers, { loading: loadingMembers, refetch }] = useLazyQuery(
    GET_MEMBERS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getMembers }) => setMembers(getMembers),
    }
  );

  React.useEffect(() => {
    if (workspace) {
      getMembers({
        variables: {
          input: {
            size: sizePerPage,
            offset: initialOffset,
            search: "",
            project: workspace?.project?._id,
          },
        },
      });
    }
  }, [workspace]);

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;

    getMembers({
      variables: {
        input: {
          offset: newOffset,
          size: pageSize,
          search: "",
          project: workspace?.project?._id,
        },
      },
    });
  };

  const handleEditRate = (userId, memberName) => {
    setMemberSelected({ userId, memberName });
    setIsVisible(true);
  };

  return (
    <div style={{ width: "42%" }} className="p-5">
      {missingRateWarning && (
        <Alert
          message="Member Rates Missing"
          description="Activities completed by team members without their rates set will not show up when creating an invoice."
          type="error"
          closable
          showIcon
          className="m-4"
        />
      )}
      <div>
        <div className="flex justify-between px-8">
          <Title style={{ fontSize: "16px", color: "#665AAA" }} level={3}>
            MY TEAM
          </Title>
          {!isEmpty(members?.data) && (
            <Text>{members?.pagination?.total_data || 0} members</Text>
          )}
        </div>
        <div className="mt-5 rounded-lg">
          {isEmpty(members?.data) && !loadingMembers && (
            <div className="flex justify-center items-center">
              <Title style={{ fontSize: "12px", color: "#665AAA" }} level={5}>
                NO MEMBERS
              </Title>
            </div>
          )}

          <>
            <div
              style={{ position: "relative" }}
              className="flex flex-wrap justify-evenly pb-4 mt-3"
            >
              {loadingMembers ? (
                <Loader size="large" />
              ) : (
                map(members.data, (member, index) => {
                  return (
                    <Card
                      key={index}
                      title={
                        <div className="flex justify-around items-center cursor-pointer">
                          <UserAvatar user={member} />

                          <Text className="font-base" style={{ width: "70%" }}>
                            {truncate(member.full_name, {
                              length: 18,
                            })}
                          </Text>
                        </div>
                      }
                      actions={[
                        <EditOutlined
                          onClick={() =>
                            handleEditRate(member._id, member.full_name)
                          }
                        />,
                      ]}
                      style={{ marginTop: 16, boxShadow: "none" }}
                      bodyStyle={{ padding: " 26px 16px" }}
                      className={`${styles.team_cardStyle} rounded overflow-hidden`}
                    >
                      <div className="flex justify-around">
                        <div>
                          <p>Currency</p>
                          <p className="text-xl font-semibold">
                            {member?.rates?.currency
                              ? member.rates.currency
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <p>Rate</p>
                          <p className="text-xl font-semibold">
                            {member?.rates?.rate ? member.rates.rate : "-"}
                          </p>
                        </div>
                      </div>
                    </Card>
                  );
                })
              )}
            </div>
            {!isEmpty(members?.data) && (
              <div className="mt-5 mb-3 flex justify-center">
                <Pagination
                  className="my-4 text-center"
                  current={members?.pagination?.current_page}
                  onChange={onPageChange}
                  total={members?.pagination?.total_data || 0}
                  pageSize={sizePerPage}
                  showTotal={(total, range) =>
                    `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
                  }
                />
              </div>
            )}
          </>
        </div>
      </div>
      <AddRate
        isVisible={isVisible}
        workspace={workspace._id}
        onClose={() => setIsVisible(false)}
        onDone={() => {
          setMemberSelected(null);
          refetch();
        }}
        userId={memberSelected?.userId}
        title={`Set ${memberSelected?.memberName} project rate`}
      />
    </div>
  );
};

export default TeamMembers;
