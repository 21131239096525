import gql from "graphql-tag";

export const UPDATE_SELECTED_BACKLOG_ACTIVITY = gql`
  mutation ($input: updateSelectedBacklogActivityInput) {
    updateSelectedBacklogActivity(input: $input) {
      _id
      tasks {
        _id
        description
        assigned {
          _id
          full_name
          email
          avatar
        }
        planned
        completed
      }
    }
  }
`;
