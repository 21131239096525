import gql from "graphql-tag";

export const RESEND_REQUEST = gql`
  mutation ($id: ID!) {
    resendRequest(id: $id) {
      _id
      status
      title
      description
      invitee
      created_at
      updated_at
      type
      sentAt
      project {
        title
      }
      inviter {
        _id
        email
      }
    }
  }
`;
