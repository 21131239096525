import {
  Badge,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  Image,
  Input,
  Menu,
  Popconfirm,
  Progress,
  Row,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedActivity } from "recoil/atoms/Activity/selectedActivity";
import _ from "lodash";
import {
  PullRequestOutlined,
  RightSquareOutlined,
  LoadingOutlined,
  CheckOutlined,
  CopyOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  SlackOutlined,
  LinkOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import Icon from "@ant-design/icons";
import {
  fileIcons,
  fileName,
  extension,
  isImgUrl,
} from "components/ChatContent/common";
import UserAvatar from "components/UserAvatar";
import utility from "common/utility";
import EmptyData from "components/EmptyData";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SELECTED_ACTIVITY } from "graphql/queries/Activity/getSelectedActivity";
import Loader from "components/Loader";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { GET_SELECTED_BACKLOG_ACTIVITY } from "graphql/queries/Backlog/getSelectedBacklogActivity";
import CommentTab from "./CommentTab";
import moment from "moment";
import styles from "./styles.module.css";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import CalendarPicker from "components/CalendarPicker";
import { UPDATE_ACTIVITY } from "graphql/mutations/Activity/updateActivity";
import AssigneSelectDropdown from "components/Modals/NewBacklogActivity/AssigneSelectDropdown";
import { UPDATE_SELECTED_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateSelectedBacklogActivity";
import SlackLogo from "common/assets/slack.png";
import {
  CaretDoubleRight,
  DotsThreeVertical,
  Strategy,
} from "@phosphor-icons/react";
import PlaybookDrawer from "./PlaybookDrawer";

const { Text } = Typography;
const { Panel } = Collapse;

const FileSourceData = ({ activity }) => {
  const [attachedData, setAttachedData] = useState([]);
  const [imageLoading, setimageLoading] = useState(true);

  const fetehcData = async () => {
    const mappedData = _.map(activity.data?.fileSources, async (item) => {
      return {
        data: item,
        isImg: await isImgUrl(item),
      };
    });

    const data = await Promise.all(mappedData).then((res) => {
      return res;
    });
    setAttachedData(data);
    setimageLoading(false);
  };

  useEffect(() => {
    fetehcData();
  }, [activity.data?.fileSources]);

  if (imageLoading) return <Spin indicator={<LoadingOutlined spin />} />;

  return _.map(attachedData, (item) => {
    if (item.isImg) {
      return <Image src={item.data} />;
    } else {
      return (
        <Card
          size="small"
          style={{ boxShadow: "none" }}
          className="bg-gray-100 mb-2"
        >
          <Typography.Link
            className="flex items-center cursor-pointer"
            href={item.data}
            target="_blank"
          >
            <Icon
              component={fileIcons[extension(item.data)]}
              className="icon-large mr-2"
            />
            <p>{_.truncate(fileName(item.data), { length: 20 })}</p>
          </Typography.Link>
        </Card>
      );
    }
  });
};

const ActivityDrawer = ({ setHandoffModal }) => {
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { backlog } = query;
  const [activity, setActivity] = useRecoilState(selectedActivity);
  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);
  const hasStartedActivity = !_.isEmpty(activity?.data?.time?.start);
  const hasActivityDetails = !_.isEmpty(activity?.data?.details);
  const hasActivityAttachments = !_.isEmpty(activity.data?.fileSources);
  const defaultPanels = _.compact([
    hasStartedActivity && "Activity",
    hasActivityDetails && "Details",
    hasActivityAttachments && "Attachments",
  ]);
  const [panels, setPanels] = useState([defaultPanels]);
  const [newTask, setNewTask] = useState({
    _id: _.uniqueId("newtask-"),
    description: null,
    planned: null,
    assigned: null,
    completed: false,
  });

  const [playbookDrawer, setPlaybookDrawer] = useState(false);

  const getDefaultUsers = () => {
    if (_.isEmpty(activity?.data?.project?.created_by)) return [];
    else {
      const { manager_id, members, created_by } = activity.data?.project;
      const allMembers = _.cloneDeep(members);
      allMembers.push(created_by);
      if (manager_id) {
        allMembers.push(manager_id);
      }

      const gatherMembers = _.uniqBy([...new Set([...allMembers])], "email");

      if (gatherMembers) return gatherMembers;

      return [];
    }
  };
  const [filterSearchUser, setFilterSearchUser] = React.useState([]);

  useEffect(() => {
    setPanels(defaultPanels);
  }, [hasStartedActivity, hasActivityDetails]);

  const onClose = () => {
    setActivity({
      toggle: false,
      data: undefined,
      fromUrl: false,
    });
    delete query.activityId;
    delete query.backlog;
    history.push({
      search: queryString.stringify({
        ...query,
      }),
    });
  };

  const [getActivity, { loading }] = useLazyQuery(GET_SELECTED_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getSelectedActivity }) => {
      if (getSelectedActivity) {
        setActivity({ toggle: true, data: getSelectedActivity });
      }
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      onClose();
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [updateActivity, { loading: updating }] = useMutation(UPDATE_ACTIVITY, {
    onCompleted: () => {
      setNewTask({
        _id: _.uniqueId("newtask-"),
        description: null,
        planned: null,
        assigned: null,
        completed: false,
      });
    },
  });

  const [getBackloadActivity, { loading: backlogLoading }] = useLazyQuery(
    GET_SELECTED_BACKLOG_ACTIVITY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getSelectedBacklogActivity }) => {
        if (getSelectedBacklogActivity) {
          const data = {
            ...getSelectedBacklogActivity,
            project: getSelectedBacklogActivity?.workspace.project,
            handoff: [],
          };
          setActivity({ toggle: true, data: data });
        }
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        onClose();
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );
  const [updateBacklogActivity, { loading: updatingBacklogActivity }] =
    useMutation(UPDATE_SELECTED_BACKLOG_ACTIVITY, {
      onCompleted: () => {
        setNewTask({
          _id: _.uniqueId("newtask-"),
          description: null,
          planned: null,
          assigned: null,
          completed: false,
        });
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  useEffect(() => {
    if (activity.fromUrl) {
      if (backlog == "true") {
        getBackloadActivity({ variables: { activityId: activity.data?._id } });
      } else {
        getActivity({ variables: { activityId: activity.data?._id } });
      }
    }
  }, []);

  useEffect(() => {
    if (stepIndex.step == 13) {
      onClose();
    }
  }, [stepIndex]);

  useEffect(() => {
    if (activity?.data) {
      setFilterSearchUser(getDefaultUsers());
    }
  }, [activity?.data]);

  const convertToMins = (time) => {
    const hours = Number(time?.split(":")[0]);
    const mins = Number(time?.split(":")[1]);
    const totalMins = hours * 60 + mins;
    return totalMins;
  };

  const editorData =
    activity.data?.details &&
    EditorState.createWithContent(
      convertFromRaw(JSON.parse(activity.data?.details))
    );
  const cardTitle = (
    <div className="flex justify-between">
      <div className="flex flex-col">
        <Text strong className="text-base" style={{ color: "#868686" }}>
          TITLE
        </Text>
        <Text>{activity.data?.title}</Text>
      </div>

      <div className="flex flex-col">
        <Tag
          color={
            _.isEmpty(activity.data?.project?.color) ||
            activity.data?.project?.color === "none"
              ? "#808080"
              : activity.data?.project?.color
          }
        >
          {activity.data?.project?.title}
        </Tag>
        {backlog != "true" && _.isEmpty(activity.data?.todoist_project) && (
          <Button
            onClick={() => {
              setHandoffModal(activity);
              onClose();
            }}
            size="small"
            className="mt-1"
            type="text"
          >
            <div className="flex items-center">
              <UsergroupAddOutlined
                style={{ color: "#E9A13B", fontSize: 20 }}
                className="mr-1"
              />
              <Text>HandOff</Text>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
  const ActivitySection = () => {
    let activityData = activity?.data;
    const activityStatus = activityData.status;

    if (_.isEmpty(activity?.data.handoff)) {
      const totalTimeBoxArray = [];
      let totalTime;
      let activityTotalTime;
      if (activityStatus == 2) {
        const {
          time: { paused },
        } = activityData;
        const totalPauses = _.map(paused, (pauses) => pauses.total);
        activityTotalTime = utility.calculateTimeTotal(totalPauses);
        totalTime = convertToMins(activityTotalTime);
      } else {
        totalTime = convertToMins(activityData?.time?.total_time);
        activityTotalTime = activityData?.time?.total_time;
      }
      _.forEach(activityData.time?.paused, (item) => {
        if (item?.timebox) {
          totalTimeBoxArray.push(item.timebox);
        }
      });
      if (activityData?.timebox) {
        totalTimeBoxArray.push(activityData?.timebox);
      }

      const timeboxSum = utility.calculateTimeTotal(totalTimeBoxArray);
      const timeBoxTime = convertToMins(timeboxSum);
      const progressPercentage = (totalTime / timeBoxTime) * 100;
      return (
        <div className="w-full">
          <div className="flex justify-end">
            <div className="flex flex-col items-center ml-4 mb-4">
              <Text strong style={{ color: "#868686" }}>
                STARTED AT
              </Text>
              <Text className="mt-2">
                {moment(activityData.time?.start).format("MMM DD, YYYY HH:mm")}
              </Text>
            </div>
            {activityData.time?.end && (
              <div className="flex flex-col items-center ml-4">
                <Text strong style={{ color: "#868686" }}>
                  ENDED AT
                </Text>
                <Text className="mt-2">
                  {moment(activityData.time?.end).format("MMM DD, YYYY HH:mm")}
                </Text>
              </div>
            )}
          </div>

          <div className="flex justify-between text-center">
            <Tooltip title={activityData?.created_by?.email} placement="top">
              <UserAvatar
                user={activityData?.created_by}
                size="large"
                className="mt-2"
              />
            </Tooltip>

            <div className="flex flex-col mb-2">
              <Text strong style={{ color: "#868686" }}>
                TIME SPENT
              </Text>
              <Text strong className="mt-1.5 text-lg">
                {activityTotalTime} / {timeboxSum}
              </Text>
            </div>
          </div>

          <Progress
            percent={_.isNaN(progressPercentage) ? 0 : progressPercentage}
            showInfo={false}
            strokeColor={progressPercentage > 100 ? "red" : "#3C2E94"}
            trailColor="#967DDE"
            size="default"
          />
        </div>
      );
    }
    const handoffPersons = _.groupBy(activity.data.handoff, "user.email");

    const PausedHandoff = () => {
      const {
        time: { paused },
      } = activityData;
      const totalPauses = _.map(paused, (pauses) => pauses.total);
      const totalPausedTime = utility.calculateTimeTotal(totalPauses);
      const totalPauseTimeInMins = convertToMins(totalPausedTime);

      const totalPausedTimeBoxArray = [];
      _.forEach(paused, (item) => {
        if (item?.timebox) {
          totalPausedTimeBoxArray.push(item.timebox);
        }
      });
      if (activityData?.timebox) {
        totalPausedTimeBoxArray.push(activityData?.timebox);
      }

      const timeboxPausedSum = utility.calculateTimeTotal(
        totalPausedTimeBoxArray
      );
      const timeBoxTime = convertToMins(timeboxPausedSum);
      const pausedProgressPercentage =
        (totalPauseTimeInMins / timeBoxTime) * 100;

      return (
        <div>
          <div className="flex justify-between">
            <UserAvatar
              user={activityData.created_by}
              size="large"
              className="mt-2"
            />
            <div className="flex flex-col mb-2">
              <Text strong>Time Spent</Text>
              <Text className="mt-1.5">
                {totalPausedTime} / {timeboxPausedSum}
              </Text>
            </div>
          </div>
          <Progress
            percent={
              _.isNaN(pausedProgressPercentage) ? 0 : pausedProgressPercentage
            }
            showInfo={false}
            strokeColor={pausedProgressPercentage > 100 ? "red" : "#3C2E94"}
            trailColor="#967DDE"
            size="default"
          />
        </div>
      );
    };

    return (
      <div className="w-3/4">
        {_.map(handoffPersons, (handOff) => {
          const totalTimeBoxArray = [];
          _.forEach(handOff, (item) => {
            if (item?.timebox) {
              totalTimeBoxArray.push(item.timebox);
            }
            _.forEach(item.time?.paused, (item) => {
              if (item?.timebox) {
                totalTimeBoxArray.push(item.timebox);
              }
            });
          });
          const timeboxSum = utility.calculateTimeTotal(totalTimeBoxArray);
          const activityTotalTime = utility.calculateTimeTotal(
            _.map(handOff, (item) => item.time?.total_time)
          );
          const totalTime = convertToMins(activityTotalTime);
          const timeBoxTime = convertToMins(timeboxSum);
          const progressPercentage = (totalTime / timeBoxTime) * 100;
          return (
            <div className="mb-3">
              <div className="flex justify-between">
                <Tooltip title={handOff[0]?.user?.email} placement="top">
                  <UserAvatar
                    user={handOff[0]?.user}
                    size="large"
                    className="mt-2"
                  />
                </Tooltip>

                <div className="flex flex-col mb-2">
                  <Text strong>Time Spent</Text>
                  <Text className="mt-1.5">
                    {activityTotalTime} / {timeboxSum}
                  </Text>
                </div>
              </div>
              <Progress
                percent={_.isNaN(progressPercentage) ? 0 : progressPercentage}
                showInfo={false}
                strokeColor={progressPercentage > 100 ? "red" : "#3C2E94"}
                trailColor="#967DDE"
                size="default"
              />
            </div>
          );
        })}
        {activityData?.status === 2 && <PausedHandoff />}
      </div>
    );
  };

  const handleSearch = (v) => {
    const { manager_id, members, created_by } = activity.data?.project;

    const allMembers = [...members, created_by];

    if (manager_id) {
      allMembers.push(manager_id);
    }

    const gatherMembers = _.uniqBy([...new Set(allMembers)], "email");

    const matches = gatherMembers.filter(
      (member) =>
        new RegExp(v, "i").test(member.full_name) ||
        new RegExp(v, "i").test(member.email)
    );

    setFilterSearchUser(_.isEmpty(v) ? gatherMembers : matches);
  };

  const repoColor = (status) => {
    const colors = {
      opened: "#55acee",
      merged: "#52f303",
      closed: "#cd201f",
    };
    return colors[status];
  };

  const handleChange = (e) => {
    setPanels(e);
  };
  const handleAddNewTask = () => {
    const filteredData = [
      ...activity.data?.tasks,
      {
        ...newTask,
      },
    ].map((task) => {
      return {
        ..._.omit(task, "_id"),
        assigned: task.assigned?._id,
      };
    });

    if (backlog == "true") {
      updateBacklogActivity({
        variables: {
          input: {
            activity: activity.data?._id,
            tasks: filteredData,
          },
        },
      }).then(() => {
        getBackloadActivity({ variables: { activityId: activity.data?._id } });
      });
    } else {
      updateActivity({
        variables: {
          input: {
            activity: activity.data?._id,
            tasks: filteredData,
          },
        },
      }).then(() => {
        getActivity({ variables: { activityId: activity.data?._id } });
      });
    }
  };

  const handleUpdateTask = (updatedTasks) => {
    if (backlog == "true") {
      updateBacklogActivity({
        variables: {
          input: {
            activity: activity.data?._id,
            tasks: _.map(updatedTasks, (task) => {
              return {
                ..._.omit(task, "_id"),
                assigned: task.assigned?._id,
              };
            }),
          },
        },
      });
    } else {
      updateActivity({
        variables: {
          input: {
            activity: activity.data?._id,
            tasks: _.map(updatedTasks, (task) => {
              return {
                ..._.omit(task, "_id"),
                assigned: task.assigned?._id,
              };
            }),
          },
        },
      });
    }
  };

  const handlecompleteAction = () => {
    // check if the activity have playbook ,  before complete make it's child activity all mark as complete
    let allActivitiesAreCompleted = true;

    if (!_.isEmpty(activity.data?.playbook)) {
      _.forEach(activity.data?.playbook, (goal) => {
        _.forEach(goal?.activities, (goalActivity) => {
          if (goalActivity?.isCompleted === false) {
            allActivitiesAreCompleted = false;
          }
        });
      });
    }

    if (allActivitiesAreCompleted) {
      if (backlog == "true") {
        updateBacklogActivity({
          variables: {
            input: { activity: activity.data?._id, isCompleted: true },
          },
        });
      } else {
        updateActivity({
          variables: {
            input: { activity: activity.data?._id, isCompleted: true },
          },
        });
      }
    } else {
      utility.setNotification(
        "Can't mark as complete",
        "Please mark all play activities as complete before marking as complete",
        "warning",
        "topRight"
      );
    }
  };

  const activityMenu = () => {
    return (
      <Menu>
        <Menu.Item key="1">
          <Text
            copyable={{
              icon: [
                <CopyOutlined
                  key="copy-icon"
                  style={{ fontSize: 12, color: "#868686" }}
                />,
                <CheckOutlined
                  key="copied-icon"
                  style={{ fontSize: 12, color: "#868686" }}
                />,
              ],
            }}
          >
            {activity.data?.key}
          </Text>
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => handlecompleteAction()}
          disabled={activity.data?.isCompleted}
        >
          Mark as complete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={activity.toggle}
      closeIcon={null}
      bodyStyle={{ paddingTop: 0, background: "#F6F7F9" }}
      width={500}
      title={false}
      headerStyle={{ display: "none" }}
      className={`${styles.activtyDrawer}`}
      style={{ zIndex: "999999999999 !important" }}
    >
      {loading || backlogLoading ? (
        <Loader size="large" />
      ) : (
        <>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <div className="flex items-center gap-2">
                <Button
                  icon={<LinkOutlined />}
                  onClick={() => {
                    // copy page url into clipbord
                    const currentURL = window.location.href;
                    navigator.clipboard.writeText(currentURL).then(() => {
                      utility.setNotification(
                        "Copied",
                        "Page URL copied to clipboard",
                        "success",
                        "topRight"
                      );
                    });
                  }}
                />

                <Dropdown overlay={activityMenu()}>
                  <Button icon={<MoreOutlined />} />
                </Dropdown>
              </div>
            }
          >
            <Tabs.TabPane tab="General" key="1">
              <>
                {cardTitle}
                <Divider className=" my-4 " />

                {!_.isEmpty(activity.data?.tags) && (
                  <Row className="flex flex-col">
                    <Text strong style={{ color: "#868686" }}>
                      TAGS
                    </Text>
                    <div className="flex items-center mt-2">
                      {_.map(activity.data?.tags, (tag) => (
                        <Tag className="mr-2">{tag}</Tag>
                      ))}
                    </div>
                    <Divider className="my-4" />
                  </Row>
                )}

                <Row className="flex">
                  <div className="flex flex-col items-center">
                    <Text strong style={{ color: "#868686" }}>
                      CREATED BY
                    </Text>

                    <Tooltip
                      title={
                        <div>
                          {activity.data?.activity_persona?.slackMessageURL ? (
                            <Text>
                              <Typography.Link
                                href={
                                  activity.data?.activity_persona
                                    ?.slackMessageURL
                                }
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Go to message
                              </Typography.Link>
                            </Text>
                          ) : (
                            <Text style={{ color: "white" }}>
                              {activity.data?.initiated_by
                                ? activity.data?.initiated_by?.email
                                : activity.data?.created_by?.email}
                            </Text>
                          )}
                        </div>
                      }
                      placement="top"
                    >
                      <div className="flex items-center">
                        <UserAvatar
                          user={
                            activity.data?.initiated_by
                              ? activity.data?.initiated_by
                              : activity.data?.created_by
                          }
                          size="large"
                          className="mt-2"
                        />
                        {activity.data?.activity_persona?.slackMessageURL && (
                          <div className="absolute ml-8 mb-4">
                            <img
                              src={SlackLogo}
                              style={{ width: 20, height: 20 }}
                            />
                          </div>
                        )}
                      </div>
                    </Tooltip>
                  </div>

                  <div className="flex flex-col items-center ml-4">
                    <Text strong style={{ color: "#868686" }}>
                      CREATED AT
                    </Text>
                    <Text className="mt-2">
                      {activity.data?.initiated_at
                        ? moment(activity.data?.initiated_at).format(
                            "MMM DD, YYYY"
                          )
                        : moment(activity.data?.created_at).format(
                            "MMM DD, YYYY"
                          )}
                    </Text>
                  </div>
                </Row>
                <Divider className=" my-4 " />
                {(!_.isEmpty(activity.data?.playbookReference) ||
                  !_.isEmpty(activity.data?.playbook)) && (
                  <div>
                    <Text
                      strong
                      className="uppercase"
                      style={{ color: "#868686" }}
                    >
                      Playbook
                    </Text>
                    <div
                      className="flex items-center p-3 mt-2 mb-4"
                      style={{ background: "#d1d6df" }}
                    >
                      <Strategy size={24} color="#808080;" weight="bold" />

                      <div
                        className="flex items-center justify-between ml-2 flex-1 cursor-pointer"
                        onClick={() => setPlaybookDrawer(true)}
                      >
                        <div className="flex items-center">
                          <Text strong style={{ color: "#F5A925" }}>
                            {_.isEmpty(activity.data?.playbook)
                              ? activity.data?.playbookReference?.key
                              : activity.data?.key}{" "}
                            -{" "}
                          </Text>
                          <Text>
                            {_.truncate(
                              _.isEmpty(activity.data?.playbook)
                                ? activity.data?.playbookReference?.title
                                : activity.data?.title,
                              {
                                length: 22,
                                separator: "...",
                              }
                            )}
                          </Text>
                        </div>

                        <CaretDoubleRight
                          size={20}
                          color="#808080;"
                          weight="bold"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {_.isEmpty(activity.data?.todoist_project) && (
                  <Collapse
                    activeKey={panels}
                    onChange={handleChange}
                    expandIconPosition="left"
                    expandIcon={({ isActive }) => (
                      <RightSquareOutlined
                        style={{ fontSize: 24 }}
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                    ghost
                    className="activity-collapse-panel-custom"
                  >
                    {activity.data?.details && (
                      <Panel header="Details" key="Details">
                        {activity.data?.details ? (
                          <Editor
                            editorState={editorData}
                            toolbarHidden={true}
                            wrapperClassName="h-full"
                            placeholder="No data"
                            readOnly={true}
                          />
                        ) : (
                          <EmptyData />
                        )}
                      </Panel>
                    )}

                    {!_.isEmpty(activity.data?.fileSources) && (
                      <Panel
                        className="mt-3"
                        header="Attachments"
                        key="Attachments"
                      >
                        <div className="w-11/12 m-auto ">
                          {<FileSourceData activity={activity} />}
                        </div>
                      </Panel>
                    )}

                    {backlog != "true" && (
                      <Panel className="mt-3" header="Activity" key="Activity">
                        <ActivitySection />
                      </Panel>
                    )}
                    {!_.isEmpty(activity.data?.activity_persona?.github) && (
                      <Panel
                        className="mt-3"
                        header="Development"
                        key="Development"
                      >
                        <>
                          {!_.isEmpty(
                            activity.data?.activity_persona?.github
                          ) && (
                            <>
                              <Text strong>PULL REQUESTS</Text>
                              <div className="mt-4">
                                {_.map(
                                  activity.data?.activity_persona?.github,
                                  (item) => {
                                    return (
                                      <div className="mb-2">
                                        <Tag
                                          icon={
                                            <PullRequestOutlined
                                              style={{
                                                color: repoColor(item.status),
                                                fontSize: 14,
                                              }}
                                            />
                                          }
                                        >
                                          <Typography.Link
                                            href={item.link}
                                            target="_blank"
                                            className="text-base text-white"
                                          >
                                            {item.title}
                                          </Typography.Link>
                                        </Tag>
                                        <Tag
                                          color={repoColor(item.status)}
                                          className="ml-2 uppercase"
                                        >
                                          {item.status}
                                        </Tag>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </>
                      </Panel>
                    )}

                    {_.isEmpty(activity.data?.playbook) && (
                      <Panel className="mt-3" header="Tasks" key="Tasks">
                        {_.map(activity.data?.tasks, (task) => {
                          return (
                            <div className={styles.newTaskDiv}>
                              <div>
                                <div className="flex items-start">
                                  <Checkbox
                                    defaultChecked={task.completed}
                                    onChange={(event) => {
                                      const { checked } = event.target;

                                      const updatedTasks =
                                        activity.data.tasks.map((taskItem) =>
                                          taskItem._id === task._id
                                            ? {
                                                ...taskItem,
                                                completed: checked,
                                                completed_at: checked
                                                  ? moment().format()
                                                  : null,
                                              }
                                            : taskItem
                                        );

                                      setActivity((prevActivity) => ({
                                        ...prevActivity,
                                        data: {
                                          ...prevActivity.data,
                                          tasks: updatedTasks,
                                        },
                                      }));

                                      handleUpdateTask(updatedTasks);
                                    }}
                                  />
                                  <div className="ml-3 flex-1">
                                    <div>
                                      <Text
                                        delete={task.completed}
                                        mark={task.completed}
                                        editable={{
                                          onChange: (newDescription) => {
                                            const updatedTasks =
                                              activity.data.tasks.map((t) =>
                                                t._id === task._id
                                                  ? {
                                                      ...t,
                                                      description:
                                                        newDescription,
                                                    }
                                                  : t
                                              );
                                            setActivity({
                                              ...activity,
                                              data: {
                                                ...activity.data,
                                                tasks: updatedTasks,
                                              },
                                            });
                                            handleUpdateTask(updatedTasks);
                                          },
                                          icon: (
                                            <EditOutlined
                                              style={{ color: "#A5ACB8" }}
                                            />
                                          ),
                                        }}
                                      >
                                        {task?.description}
                                      </Text>

                                      <Popconfirm
                                        placement="topRight"
                                        title="Are you sure to delete this task?"
                                        onConfirm={() => {
                                          const updatedTasks =
                                            activity.data.tasks.filter(
                                              (t) => t._id !== task._id
                                            );
                                          setActivity({
                                            ...activity,
                                            data: {
                                              ...activity.data,
                                              tasks: updatedTasks,
                                            },
                                          });
                                          handleUpdateTask(updatedTasks);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Tooltip title="Remove task">
                                          <DeleteOutlined
                                            style={{ color: "#A5ACB8" }}
                                            className="ml-2"
                                          />
                                        </Tooltip>
                                      </Popconfirm>
                                    </div>

                                    <div className="flex items-center mt-1 mr-1 justify-end ">
                                      <div className="flex items-center mr-2">
                                        <CalendarPicker
                                          initialDate={
                                            task?.planned
                                              ? task.planned
                                              : moment()
                                          }
                                          onDateChange={(date) => {
                                            const updatedTasks =
                                              activity.data.tasks.map((t) =>
                                                t._id === task._id
                                                  ? { ...t, planned: date }
                                                  : t
                                              );
                                            setActivity((prevActivity) => ({
                                              ...prevActivity,
                                              data: {
                                                ...prevActivity.data,
                                                tasks: updatedTasks,
                                              },
                                            }));

                                            handleUpdateTask(updatedTasks);
                                          }}
                                          label={
                                            <Text className=" text-xs underline">
                                              {task?.planned
                                                ? moment(task?.planned).format(
                                                    "dddd, MMMM D"
                                                  )
                                                : "Schedule"}
                                            </Text>
                                          }
                                        />
                                      </div>
                                      <Dropdown
                                        trigger={["click"]}
                                        overlay={() => (
                                          <AssigneSelectDropdown
                                            handleSearch={handleSearch}
                                            filterSearchUser={filterSearchUser}
                                            setValue={(v) => {
                                              const updatedTasks =
                                                activity.data.tasks.map((t) =>
                                                  t._id === task._id
                                                    ? { ...t, assigned: v }
                                                    : t
                                                );
                                              setActivity((prevActivity) => ({
                                                ...prevActivity,
                                                data: {
                                                  ...prevActivity.data,
                                                  tasks: updatedTasks,
                                                },
                                              }));

                                              handleUpdateTask(updatedTasks);
                                            }}
                                            value={task?.assigned}
                                          />
                                        )}
                                      >
                                        {task?.assigned ? (
                                          <div className="cursor-pointer">
                                            <UserAvatar
                                              size={24}
                                              user={task?.assigned}
                                            />
                                            <Text className="ml-2 text-xs underline">
                                              {task?.assigned?.full_name}
                                            </Text>
                                          </div>
                                        ) : (
                                          <div className="flex items-center cursor-pointer">
                                            <UserAddOutlined />
                                            <Text className="text-xs ml-1 underline">
                                              Assignee
                                            </Text>
                                          </div>
                                        )}
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* new task  */}
                        {activity?.data?.status !== 3 &&
                          activity?.data?.status !== 6 && (
                            <div className={styles.newTaskDiv}>
                              <div>
                                <div className="flex items-start">
                                  <Checkbox
                                    defaultChecked={newTask.completed}
                                    checked={newTask.completed}
                                    onChange={(v) =>
                                      setNewTask({
                                        ...newTask,
                                        completed: v.target.checked,
                                      })
                                    }
                                  />
                                  <div className="ml-3 flex-1">
                                    <Input
                                      value={newTask.description}
                                      onChange={(v) => {
                                        setNewTask({
                                          ...newTask,
                                          description: v.target?.value,
                                        });
                                      }}
                                    />
                                    <div className="flex justify-between mt-1 ml-1 ">
                                      <div className="flex items-center ">
                                        <Dropdown
                                          trigger={["click"]}
                                          overlay={() => (
                                            <AssigneSelectDropdown
                                              handleSearch={handleSearch}
                                              filterSearchUser={
                                                filterSearchUser
                                              }
                                              setValue={(v) =>
                                                setNewTask({
                                                  ...newTask,
                                                  assigned: v,
                                                })
                                              }
                                              value={newTask?.assigned}
                                            />
                                          )}
                                        >
                                          {newTask?.assigned ? (
                                            <div className="cursor-pointer">
                                              <UserAvatar
                                                size={24}
                                                user={newTask?.assigned}
                                              />
                                              <Text
                                                className="ml-2 text-xs underline"
                                                style={{ color: "#A5ACB8" }}
                                              >
                                                {newTask?.assigned?.full_name}
                                              </Text>
                                            </div>
                                          ) : (
                                            <div className="flex items-center cursor-pointer">
                                              <UserAddOutlined
                                                style={{ color: "#A5ACB8" }}
                                              />
                                              <Text
                                                style={{ color: "#A5ACB8" }}
                                                className="text-xs ml-1 underline"
                                              >
                                                Assignee
                                              </Text>
                                            </div>
                                          )}
                                        </Dropdown>
                                        <div
                                          className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                                        >
                                          <CalendarPicker
                                            initialDate={moment()}
                                            onDateChange={(date) =>
                                              setNewTask({
                                                ...newTask,
                                                planned: date,
                                              })
                                            }
                                            label={
                                              <Text
                                                className="text-xs underline"
                                                style={{ color: "#A5ACB8" }}
                                              >
                                                {newTask?.planned
                                                  ? moment(
                                                      newTask?.planned
                                                    ).format("dddd, MMMM D")
                                                  : "Schedule"}
                                              </Text>
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <Button
                                          icon={<CheckOutlined />}
                                          type="primary"
                                          size="small"
                                          onClick={handleAddNewTask}
                                          loading={
                                            updating || updatingBacklogActivity
                                          }
                                        >
                                          Add
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </Panel>
                    )}
                  </Collapse>
                )}
              </>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div className="flex items-center">
                  <Text>Comments</Text>
                  {activity?.data?.commentCounts > 0 && (
                    <Badge
                      color="#faad14"
                      className="ml-1"
                      count={activity?.data?.commentCounts}
                    />
                  )}
                </div>
              }
              key="2"
            >
              <CommentTab
                activity={activity.data}
                fromBacklog={backlog == "true"}
              />
            </Tabs.TabPane>
          </Tabs>

          {playbookDrawer && (
            <PlaybookDrawer
              onClose={() => setPlaybookDrawer(false)}
              data={
                !_.isEmpty(activity?.data?.playbook)
                  ? activity.data
                  : activity.data?.playbookReference
              }
              visible={playbookDrawer}
              handleSearch={handleSearch}
              filterSearchUser={filterSearchUser}
              setActivity={setActivity}
              selectedActivityData={activity.data}
              getActivity={() => {
                if (backlog == "true") {
                  getBackloadActivity({
                    variables: { activityId: activity.data?._id },
                  });
                } else {
                  getActivity({
                    variables: { activityId: activity.data?._id },
                  });
                }
              }}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default ActivityDrawer;
