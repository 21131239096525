import { Button, Spin, Typography, Input } from "antd";
import utility from "common/utility";
import moment from "moment";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { activeActivity } from "recoil/atoms/Timer/activity";
import TimeField from "react-simple-timefield";
import { Formik } from "formik";
import _ from "lodash";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { useMutation } from "@apollo/client";
import { useTimer } from "contexts/timer-context";
import {
  updateRecentActivities,
  updateContinueActivityList,
} from "common/cacheUtilities";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useStopActivityHook from "common/useStopActivityHook";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";

const EditActivityPopover = ({ setShowEditActivity }) => {
  const [activity, setActivity] = useRecoilState(activeActivity);
  const setRefetchIndicator = useSetRecoilState(refetchIndicator);
  const { resetHandler } = useTimer(); // global timer from context
  const { handleStopTimer, isLoading } = useStopActivityHook();

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true };
    });
  };

  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: () => {
        handleRefetchingViews();
        setShowEditActivity(false);
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      update: (cache, { data: { continueActivity } }) => {
        updateContinueActivityList(cache, continueActivity, "continue");
      },
    }
  );

  const [pauseActivity, { loading: pausing }] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: ({ pauseActivity }) => {
      utility.setNotification(
        `${pauseActivity?.title} has been paused`,
        "",
        "success",
        "topRight"
      );
      resetHandler();
      continueActivity({
        variables: {
          input: {
            activity_id: activity?.data?._id,
            time: moment.utc(new Date()),
          },
        },
      });
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_TODAY_WIDGET_DATA],
    update: (cache, { data: { pauseActivity } }) => {
      updateRecentActivities(cache, pauseActivity, "paused");
      updateContinueActivityList(cache, pauseActivity, "paused");
    },
  });

  const stopSaveHandler = (values) => {
    const total_time = values.totalTime.split("_").join("0");
    const startDate = _.isEmpty(activity.data.time.paused)
      ? activity.data.time.start
      : _.last(activity.data.time.paused).continue;
    const endTime = utility.addTimeToDate(startDate, total_time);
    if (moment(endTime).isBefore(moment().format("MM/DD/YY HH:mm:ss"))) {
      const callback = () => {
        setShowEditActivity(false);
      };
      handleStopTimer({ activity: activity.data, endTime, callback });
    } else {
      utility.setNotification(
        "Something wrong happened",
        "Set time can't be more than the active timer",
        "error",
        "topRight"
      );
    }
  };

  const handlePause = ({ endTime }) => {
    const time = endTime;
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const continueSaveHandler = (values) => {
    const total_time = values.totalTime.split("_").join("0");
    const startDate = _.isEmpty(activity.data.time.paused)
      ? activity.data.time.start
      : _.last(activity.data.time.paused).continue;
    const endTime = utility.addTimeToDate(startDate, total_time);
    if (moment(endTime).isBefore(moment().format("MM/DD/YY HH:mm:ss"))) {
      handlePause({ endTime });
    } else {
      utility.setNotification(
        "Something wrong happened",
        "Set time can't be more than the active timer",
        "error",
        "topRight"
      );
    }
  };

  return (
    <div className="flex flex-col" style={{ minWidth: 420 }}>
      <Formik
        initialValues={{
          totalTime: _.isEmpty(activity.data.time.paused)
            ? utility.calculateTimeDifferenceV2(
                moment(activity.data.time?.start).format("MM/DD/YY HH:mm:ss"),
                moment().format("MM/DD/YY HH:mm:ss")
              )
            : utility.calculateTimeDifferenceV2(
                moment(_.last(activity.data.time.paused).continue).format(
                  "MM/DD/YY HH:mm:ss"
                ),
                moment().format("MM/DD/YY HH:mm:ss")
              ),
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <div>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <Typography.Text className="text-base font-semibold">
                    <CalendarOutlined className="mr-2" />
                    Started
                  </Typography.Text>
                  <Typography.Text className="text-base my-3">
                    {moment(
                      _.isEmpty(activity.data.time.paused)
                        ? activity.data.time?.start
                        : _.last(activity.data.time.paused).continue
                    ).format("MM/DD/YYYY hh:mm A")}
                  </Typography.Text>
                </div>
                <div className="flex flex-col pl-2">
                  <Typography.Text className="text-base font-semibold">
                    Total Time
                  </Typography.Text>

                  <TimeField
                    value={values?.totalTime}
                    colon=":"
                    showSeconds
                    input={
                      <Input
                        defaultValue={values?.totalTime}
                        placeholder={"Enter correct"}
                        value={values?.totalTime}
                        suffix={<ClockCircleOutlined />}
                        onPressEnter={(e) => {
                          setFieldValue("totalTime", e.target.value);
                        }}
                        onBlur={(e) => {
                          setFieldValue("totalTime", e.target.value);
                        }}
                        className=" w-5/6 my-2 text-base"
                        size="large"
                      />
                    }
                  />
                </div>
              </div>
              <Spin
                spinning={isLoading || pausing || continuing}
                size="large"
                indicator={<LoadingOutlined spin />}
              >
                <div className="flex items-center justify-evenly w-11/12 mt-4 mb-2">
                  <Button
                    size="large"
                    className=" bg-red-500 text-white"
                    onClick={() => stopSaveHandler(values)}
                    type="default"
                  >
                    Stop and Save
                  </Button>
                  <Button
                    size="large"
                    className=" text-white bg-purple-500"
                    onClick={() => continueSaveHandler(values)}
                    type="text"
                  >
                    Continue and save
                  </Button>
                </div>
              </Spin>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditActivityPopover;
