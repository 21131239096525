import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loader from "components/Loader";
import { GET_MONDAY_ITEMS } from "graphql/queries/Monday/getMondayItems";
import React from "react";
import _ from "lodash";
import {
  Button,
  Collapse,
  Popconfirm,
  Popover,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import Icons from "common/icons";
import Icon, { AlignLeftOutlined, DownOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import cx from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { CREATE_MONDAY_ACTIVITY } from "graphql/mutations/Monday/createMondayActivity";
import moment from "moment";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import { STOP_MONDAY_ACTIVITY } from "graphql/mutations/Monday/stopMondayActivity";
import utility from "common/utility";
import {
  updateContinueActivityList,
  updateRecentActivities,
} from "common/cacheUtilities";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import EmptyData from "components/EmptyData";
import { pausedActivities } from "recoil/atoms/PausedActivity";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { userSession } from "recoil/atoms/User/UserSession";
import { useTimer } from "contexts/timer-context";

const { Panel } = Collapse;

const Board = ({ workspaceId, setSelected }) => {
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [boardData, setBoardData] = React.useState([]);
  const [pausedActivity] = useRecoilState(pausedActivities);
  const user = useRecoilValue(userSession);
  const { startHandler } = useTimer(); // global startHandler from context
  const [getCurrentActivity, { loading: getCurrentActivityLoading }] =
    useLazyQuery(GET_CURRENT_ACTIVITY, {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getCurrentActivity }) => {
        if (_.has(getCurrentActivity, "time.start"))
          setActivity({
            active: true,
            data: getCurrentActivity,
          });
      },
    });
  const { loading, refetch } = useQuery(GET_MONDAY_ITEMS, {
    variables: { input: { workspace: workspaceId } },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const groupByGroups = _.groupBy(data.getMondayItems, "group.title");
      setBoardData(groupByGroups);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });
  const [startMondayTimer, { loading: startTimerLoading }] = useMutation(
    CREATE_MONDAY_ACTIVITY,
    {
      onCompleted: ({ createMondayActivity }) => {
        utility.setNotification(
          `${createMondayActivity?.title} started`,
          "You should be able to see your activity timer",
          "success",
          "topRight"
        );
        getCurrentActivity();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );
  const updatesDropdown = (data) => {
    if (_.isEmpty(data)) {
      return <Typography.Text>No updates available</Typography.Text>;
    }
    return (
      <div style={{ maxWidth: 500, maxHeight: 350 }} className="overflow-auto">
        {_.map(data, (item) => {
          return (
            <div className="mb-2 p-1" style={{ background: "#33364B" }}>
              <Typography.Text style={{ color: "white" }}>
                {item.text_body}
              </Typography.Text>
            </div>
          );
        })}
      </div>
    );
  };

  const [stopMondayActivity, { loading: stopMondayActiviy }] = useMutation(
    STOP_MONDAY_ACTIVITY,
    {
      onCompleted: ({ stopMondayActivity }) => {
        utility.setNotification(
          `${stopMondayActivity?.title} stopped`,
          "You should be able to see your activity created",
          "success",
          "topRight"
        );
        refetch();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
        {
          query: GET_MONDAY_ITEMS,

          variables: { input: { workspace: workspaceId } },
        },
      ],
    }
  );

  const [pauseActivity, { loading: pauseLoading }] = useMutation(
    PAUSE_ACTIVITY,
    {
      onCompleted: ({ pauseActivity }) => {
        utility.setNotification(
          `${pauseActivity?.title} has been paused`,
          "",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      update: (cache, { data: { pauseActivity } }) => {
        updateRecentActivities(cache, pauseActivity, "paused");
        updateContinueActivityList(cache, pauseActivity, "paused");
      },
    }
  );

  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );

  const handleStop = (data) => {
    stopMondayActivity({
      variables: {
        input: {
          activity_id: activity?.data?._id,
          date_end: moment.utc(),
          board_id: data.board.id,
          status_id: data.status_id,
        },
      },
    });
  };

  const handlePause = () => {
    const time = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const startMondayActivity = (value) => {
    const ticketDescription = _.filter(value.column_values, (item) => {
      return item.id === "text" || item.id.includes("text");
    });

    startMondayTimer({
      variables: {
        input: {
          workspace: workspaceId,
          title: value.name,
          description: ticketDescription[0]?.text || value.name,
          monday_item_id: value.id,
          board_id: value.board.id,
          date_start: moment.utc(new Date()),
          status_id: value.status_id,
        },
      },
    });
  };
  const startMondaySubitemActivity = (value, subitem) => {
    const ticketDescription = _.filter(value.column_values, (item) => {
      return item.id === "text" || item.id.includes("text");
    });

    startMondayTimer({
      variables: {
        input: {
          workspace: workspaceId,
          title: subitem.name,
          description: ticketDescription[0]?.text || subitem.name,
          monday_item_id: subitem.id,
          board_id: subitem.board.id,
          date_start: moment.utc(new Date()),
        },
      },
    });
  };

  const handleContinueActivity = (activity) => {
    const pausedCopy = _.cloneDeep(activity?.time?.paused);

    const lastPause = _.last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: { activity_id: activity._id, time: moment.utc(new Date()) },
      },
    });
  };

  const stopContinueActivity = (activity) => {
    const {
      _id,
      time: { paused },
      meta,
    } = activity[0];

    stopMondayActivity({
      variables: {
        input: {
          activity_id: _id,
          date_end: _.last(paused)?.time,
          board_id: utility.IsJsonString(meta) && JSON.parse(meta)?.board_id,
          status_id: utility.IsJsonString(meta) && JSON.parse(meta)?.status_id,
        },
      },
    });
  };

  if (loading) return <Loader size="large" />;
  if (_.isEmpty(boardData))
    return (
      <div className="flex items-center flex-col h-3/5">
        <EmptyData />
        <div className="flex flex-col items-center">
          <Typography.Text>No item available</Typography.Text>
          <Button
            onClick={() => setSelected("settings")}
            className="mt-2"
            type="primary"
          >
            Set another board
          </Button>
        </div>
      </div>
    );

  return (
    <div className={styles.ticketsStyle}>
      <Collapse expandIconPosition={"right"} bordered={false}>
        {_.map(boardData, (group, index) => (
          <Panel
            header={
              <Typography.Text
                strong
                style={{ color: group[0]?.group?.color, fontSize: 16 }}
              >
                {index}
              </Typography.Text>
            }
            key={group.id}
            style={{
              borderTop: `2px solid ${group[0]?.group?.color}`,
            }}
          >
            {_.map(group, (item) => {
              // For Sub task Currently we decided not to show the arrow to expand the subtasks. WIN-1122
              // if (!_.isEmpty(item.subitems)) {
              //   return (
              //     <Tree
              //       showLine={{ showLeafIcon: false }}
              //       defaultExpandedKeys={["0-0-0"]}
              //       treeData={[
              //         {
              //           title: (
              //             <div
              //               className="flex justify-between py-2 pl-1 items-center mt-3"
              //               style={{
              //                 background: "#33364B",
              //               }}
              //             >
              //               <Typography.Text
              //                 style={{ color: "white", fontSize: 15 }}
              //               >
              //                 {item.name}
              //               </Typography.Text>
              //               <div className="flex items-center">
              //                 <div>
              //                   <Popover
              //                     content={() => updatesDropdown(item.updates)}
              //                     title={
              //                       <Typography.Text strong>
              //                         Updates
              //                       </Typography.Text>
              //                     }
              //                   >
              //                     <AlignLeftOutlined
              //                       size="large"
              //                       style={{
              //                         color: "white",
              //                         fontSize: 25,
              //                         marginRight: 8,
              //                       }}
              //                     />
              //                   </Popover>
              //                 </div>
              //                 {activity?.active &&
              //                 JSON.parse(activity?.data?.meta)?.item_id ===
              //                   item?.id ? (
              //                   <div
              //                     className={cx(
              //                       styles.timer,
              //                       styles.timerPause,
              //                       "cursor-pointer"
              //                     )}
              //                     onClick={handlePause}
              //                   >
              //                     <Icon
              //                       className={cx(styles.icon, "mt-2")}
              //                       component={Icons["Pause"]}
              //                     />
              //                   </div>
              //                 ) : (
              //                   <div
              //                     className={cx(
              //                       styles.timer,
              //                       styles.timerOff,
              //                       "cursor-pointer mr-2"
              //                     )}
              //                   >
              //                     <Icon
              //                       className={styles.icon}
              //                       component={Icons["Play"]}
              //                       onClick={() => startMondayActivity(item)}
              //                     />
              //                   </div>
              //                 )}
              //                 {activity?.active &&
              //                   JSON.parse(activity?.data?.meta)?.item_id ===
              //                     item?.id && (
              //                     <div
              //                       onClick={() => handleStop(item)}
              //                       className={cx(
              //                         styles.timer,
              //                         styles.timerOn,
              //                         "cursor-pointer ml-2 mr-2"
              //                       )}
              //                     >
              //                       <Icon
              //                         className={styles.icon}
              //                         component={Icons["Stop"]}
              //                       />
              //                     </div>
              //                   )}
              //               </div>
              //             </div>
              //           ),
              //           switcherIcon: (
              //             <DownOutlined
              //               style={{
              //                 display: "flex",
              //                 alignItems: "center",
              //                 height: "100%",
              //               }}
              //             />
              //           ),
              //           style: {
              //             background: "#33364B",
              //             width: "100%",
              //             borderLeft: `4px solid ${group[0]?.group?.color}`,
              //           },
              //           key: "0-0",
              //           children: _.map(item.subitems, (subitems) => {
              //             return {
              //               title: (
              //                 <div
              //                   className="flex justify-between py-2 pl-1 items-center"
              //                   style={{
              //                     marginTop: 8,
              //                     marginBottom: 8,
              //                     borderLeft: `4px solid ${group[0]?.group?.color}`,
              //                     background: "#33364B",
              //                   }}
              //                 >
              //                   <Typography.Text
              //                     style={{ color: "white", fontSize: 15 }}
              //                   >
              //                     {subitems.name}
              //                   </Typography.Text>
              //                   <div className="flex items-center">
              //                     <div>
              //                       <Popover
              //                         content={() =>
              //                           updatesDropdown(subitems.updates)
              //                         }
              //                         title={
              //                           <Typography.Text strong>
              //                             Updates
              //                           </Typography.Text>
              //                         }
              //                       >
              //                         <AlignLeftOutlined
              //                           size="large"
              //                           style={{
              //                             color: "white",
              //                             fontSize: 25,
              //                             marginRight: 8,
              //                           }}
              //                         />
              //                       </Popover>
              //                     </div>
              //                     {/* {activity?.active &&
              //                     JSON.parse(activity?.data?.meta)?.item_id ===
              //                       subitems?.id ? (
              //                       <div
              //                         className={cx(
              //                           styles.timer,
              //                           styles.timerPause,
              //                           "cursor-pointer"
              //                         )}
              //                         onClick={handlePause}
              //                       >
              //                         <Icon
              //                           className={cx(styles.icon, "mt-2")}
              //                           component={Icons["Pause"]}
              //                         />
              //                       </div>
              //                     ) : (
              //                       <div
              //                         className={cx(
              //                           styles.timer,
              //                           styles.timerOff,
              //                           "cursor-pointer mr-2"
              //                         )}
              //                       >
              //                         <Icon
              //                           className={styles.icon}
              //                           component={Icons["Play"]}
              //                           onClick={() =>
              //                             startMondaySubitemActivity(
              //                               item,
              //                               subitems
              //                             )
              //                           }
              //                         />
              //                       </div>
              //                     )}
              //                     {activity?.active &&
              //                       JSON.parse(activity?.data?.meta)
              //                         ?.item_id === subitems?.id && (
              //                         <div
              //                           onClick={() => handleStop(subitems)}
              //                           className={cx(
              //                             styles.timer,
              //                             styles.timerOn,
              //                             "cursor-pointer ml-2 mr-2"
              //                           )}
              //                         >
              //                           <Icon
              //                             className={styles.icon}
              //                             component={Icons["Stop"]}
              //                           />
              //                         </div>
              //                       )} */}
              //                   </div>
              //                 </div>
              //               ),
              //               key: subitems.id,
              //               style: { width: "100%" },
              //             };
              //           }),
              //         },
              //       ]}
              //     />
              //   );
              // }
              const isInPaused = _.filter(pausedActivity, (paused) => {
                return (
                  utility.IsJsonString(paused.meta) &&
                  JSON.parse(paused.meta)?.item_id === item?.id
                );
              });

              return (
                <div
                  className="flex justify-between py-2 pl-1 items-center"
                  style={{
                    background: "#33364B",
                    marginTop: 8,
                    marginBottom: 8,
                    borderLeft: `4px solid ${group[0]?.group?.color}`,
                  }}
                >
                  <Typography.Text style={{ color: "white", fontSize: 15 }}>
                    {item.name}
                  </Typography.Text>
                  <div className="flex items-center">
                    <div>
                      <Popover
                        content={() => updatesDropdown(item.updates)}
                        title={
                          <Typography.Text strong>Updates</Typography.Text>
                        }
                      >
                        <AlignLeftOutlined
                          size="large"
                          style={{
                            color: "white",
                            fontSize: 25,
                            marginRight: 8,
                          }}
                        />
                      </Popover>
                    </div>

                    {_.isEmpty(isInPaused) ? (
                      <>
                        {activity?.active &&
                        utility.IsJsonString(activity?.data?.meta) &&
                        JSON.parse(activity?.data?.meta)?.item_id ===
                          item?.id ? (
                          <div
                            className={cx(
                              styles.timer,
                              styles.timerPause,
                              "cursor-pointer"
                            )}
                            onClick={handlePause}
                          >
                            <Icon
                              className={cx(styles.icon, "mt-2")}
                              component={Icons["Pause"]}
                            />
                          </div>
                        ) : (
                          <div
                            className={cx(
                              styles.timer,
                              styles.timerOff,
                              "cursor-pointer mr-2"
                            )}
                          >
                            <Icon
                              className={styles.icon}
                              component={Icons["Play"]}
                              onClick={() => startMondayActivity(item)}
                            />
                          </div>
                        )}
                        {activity?.active &&
                          utility.IsJsonString(activity?.data?.meta) &&
                          JSON.parse(activity?.data?.meta)?.item_id ===
                            item?.id && (
                            <div
                              onClick={() => handleStop(item)}
                              className={cx(
                                styles.timer,
                                styles.timerOn,
                                "cursor-pointer ml-2 mr-2"
                              )}
                            >
                              <Icon
                                className={styles.icon}
                                component={Icons["Stop"]}
                              />
                            </div>
                          )}
                      </>
                    ) : (
                      <div>
                        <div class="mr-5 sm:mr-2 flex justify-beetwen items-center">
                          <Tooltip placement="top" title="Continue this task">
                            <Popconfirm
                              placement="bottom"
                              title="Click yes to continue this activity"
                              onConfirm={() =>
                                handleContinueActivity(isInPaused[0])
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Icon
                                component={Icons.Continue}
                                className="icon-medium mr-4"
                              />
                            </Popconfirm>
                          </Tooltip>
                          <Tooltip placement="top" title="Finish this task">
                            <Popconfirm
                              placement="bottom"
                              title="Click yes to mark finished this activity"
                              onConfirm={() => stopContinueActivity(isInPaused)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Icon
                                component={Icons.CheckMark}
                                className="icon-medium mr-4"
                              />
                            </Popconfirm>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Board;
