import React from "react";
import Overview from "./Overview";
import RecentActivity from "./RecentActivity";
import ContinueActivity from "./ContinueActivity";
import ProjectPerformance from "./ProjectPerformance";
import ActivityCount from "./ActivityCount";
import GoogleMeet from "./Meetings";
import Jira from "./Jira";
import GitHub from "./GitHub";
import Monday from "./Monday";
import Trello from "./Trello";
import Miro from "./Miro";
import Todoist from "./Todoist";

import Invest_Overview from "./Invest_Overview";
import NotesWidget from "./Notes";
import Invoices from "./Invoices";
import TodayWidget from "./Today";
import PlannedWidget from "./Planned/PlannedWidget";

export const WIDGETS = {
  OVERVIEW: <Overview />,
  RECENT_ACTIVITY: <RecentActivity />,
  CONTINUE_ACTIVITY: <ContinueActivity />,
  PROJECT_PERFORMANCE: <ProjectPerformance />,
  ACTIVITY_COUNT: <ActivityCount />,
  PLANNED: <PlannedWidget />,
  GOOGLE_MEET: <GoogleMeet />, //Now is generic Meetings (TODO - Rename)
  JIRA: <Jira />,
  GITHUB: <GitHub />,
  MONDAY: <Monday />,
  TRELLO: <Trello />,
  MIRO: <Miro />,
  TODOIST: <Todoist />,
  INVEST_OVERVIEW: <Invest_Overview />,
  NOTES: <NotesWidget />,
  INVOICE: <Invoices />,
  TODAY: <TodayWidget />,
  /*TODO: Added zoom in key of JIRA as there is no key for ZOOM.. */
};
