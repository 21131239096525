import * as yup from "yup";

const validationSchema = yup.object().shape({
  // name: yup.string().trim().required("This field is required"),
  // email: yup.string().trim().required("This field is required"),
  // company: yup.string().trim().required("This field is required"),
  // address: yup.string().trim().required("This field is required"),
  title: yup.string().trim().required("This field is required"),
  issueDate: yup.date().required("This field is required").nullable(),
  dueDate: yup.date().required("This field is required").nullable(),
  invoicePeriod: yup.string().trim().required("This field is required"),
});

export default validationSchema;
