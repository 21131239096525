import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Dropdown,
  Menu,
  Popover,
  Tag,
  Tooltip,
  Typography,
  List,
  Popconfirm,
} from "antd";
import styles from "./styles.module.css";
import { Icon } from "@fishyvisions/windu-uikit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  CaretDownOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import CalendarPicker from "components/CalendarPicker";
import cx from "classnames";
import UserAvatar from "components/UserAvatar";
import StartAction from "./StartAction";
import utility from "common/utility";
import { UPDATE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/updateBacklogActivity";
import { useMutation } from "@apollo/client";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { REMOVE_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/removeBacklogActivity";
import useOpenActivityDrawer from "common/openActivityDrawer";
import EmptyBacklog from "common/assets/emptyBacklog.svg";

const { Text } = Typography;
const Upcoming = ({
  data: incomingdata,
  inboxData,
  backlogId,
  setEditingItem,
  setcreateActivityModal,
  refetch,
  totalCount,
}) => {
  const [data, setData] = useState(incomingdata);
  const user = useRecoilValue(userSession);
  const [isFiltering, setisFiltering] = useState(false);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [sortedData, setSortedData] = useState({});

  const { openActivityDrawer } = useOpenActivityDrawer();

  const OptionDropdown = ({ item }) => {
    return (
      <Menu
        style={{ boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)" }}
        className="py-0"
      >
        <Menu.Item
          className="py-4 flex items-center"
          key="edit"
          icon={<EditOutlined className="icon-medium" />}
          onClick={() => {
            setEditingItem({
              data: { ...item, planned: item.planned_date },
              id: item._id,
            });
            setcreateActivityModal(true);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          key="remove"
          className="py-4 flex items-center"
          icon={<DeleteOutlined className="icon-medium" />}
        >
          <Popconfirm
            placement="topLeft"
            title={"Are you sure to remove this activity?"}
            onConfirm={() => onActivityRemove(item)}
            okText="Yes"
            cancelText="No"
          >
            Remove Activity
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  const sortData = () => {
    const orderedDates = {};
    const newData = [...data];

    const groupByDate = _.groupBy(newData, (item) =>
      moment(item.planned_date).tz(user?.timezone).format("MM/DD/YYYY")
    );

    const objKeys = Object.keys(groupByDate);
    objKeys.sort((a, b) => new Date(b) - new Date(a));

    _.map(objKeys, (item) => {
      return (orderedDates[item] = groupByDate[item]);
    });

    setSortedData(orderedDates);
  };

  useEffect(() => {
    sortData();
  }, [data]);

  const [removeBacklogActivity] = useMutation(REMOVE_BACKLOG_ACTIVITY, {
    onError: () => {
      utility.setNotification(
        "Error",
        `Error remove activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        `Activity has been removed`,
        "success",
        "topRight"
      );
    },
  });

  const onActivityRemove = (item) => {
    const payload = {
      activityId: item._id,
      backlogId: backlogId,
    };

    if (!_.isEmpty(item.grp_id)) {
      // if belongs to a group set the groupid
      payload.groupId = item.grp_id;
    }

    removeBacklogActivity({ variables: { input: { ...payload } } });
  };

  const [updateBacklogActivity] = useMutation(UPDATE_BACKLOG_ACTIVITY, {
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        `Activity updated successfully`,
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      sortData();
      utility.setNotification(
        "Error",
        `${toString(error.message)}`,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: id },
      },
    ],
  });

  const updateActivityDate = ({ date, item }) => {
    const planDate = `${moment(date).format("YYYY-MM-DDT")}${
      moment.utc().format().split("T")[1]
    }`;

    const payload = {
      activityId: item._id,
      planned_date: planDate,
      backlogId: backlogId,
      title: item?.title,
    };
    updateBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  const onDragEnd = (result) => {
    if (
      moment(result?.destination?.droppableId).isBefore(moment()) &&
      !moment(result?.destination?.droppableId).isSame(moment(), "day")
    ) {
      utility.setNotification(
        "Error",
        `${result?.destination?.droppableId} is in the past`,
        "error",
        "topRight"
      );
    } else {
      const getSelected = _.filter(
        data,
        (item) => item._id === result.draggableId
      );
      const newData = _.filter(data, (item) => item._id !== result.draggableId);

      setData([
        ...newData,
        { ...getSelected[0], planned_date: result?.destination?.droppableId },
      ]);

      updateActivityDate({
        date: result?.destination?.droppableId,
        item: getSelected[0],
      });
    }
  };

  const calenderPickerUpdate = ({ date, item }) => {
    if (
      moment(date).tz(user?.timezone).isBefore(moment()) &&
      !moment(date).tz(user?.timezone).isSame(moment(), "day")
    ) {
      utility.setNotification(
        "Error",
        `${moment(date).format("MMM DD YYYY")} is in the past`,
        "error",
        "topRight"
      );
    } else {
      updateActivityDate({ date, item });
      const newData = _.filter(data, (selected) => selected._id !== item._id);
      setData([...newData, { ...item, planned_date: date }]);
    }
  };

  const Activityitem = ({ item, provided }) => {
    return (
      <List.Item
        className="px-2 mb-2"
        style={{ background: "rgb(238 239 242)" }}
        actions={[
          <Dropdown
            placement="bottomLeft"
            overlay={<OptionDropdown item={item} />}
            trigger={["click"]}
          >
            <div className="cursor-pointer">
              <Icon size="small" type="Meatball" />
            </div>
          </Dropdown>,
        ]}
      >
        <List.Item.Meta
          className="items-center"
          avatar={
            //  manage the state of drag and drop
            <div {...provided.dragHandleProps}>
              {!isFiltering && <Icon type="Hamburger" />}
            </div>
          }
          title={
            <Typography.Text
              onClick={() => openActivityDrawer(item, true, true)}
              className="cursor-pointer"
              strong
            >
              {item?.title}
            </Typography.Text>
          }
        />
        <div className="w-2/5	flex justify-around items-center">
          <div style={{ width: "40px" }} className="mx-2">
            {item.assigned && (
              <Tooltip title={item?.assigned?.email}>
                <UserAvatar
                  size={35}
                  user={item.assigned}
                  className="cursor-pointer"
                />
              </Tooltip>
            )}
          </div>
          <div style={{ width: "120px" }} className="mx-2">
            {item?.planned_date && (
              <div className="flex items-center justify-between">
                <CalendarPicker
                  initialDate={item?.planned_date}
                  onDateChange={(date) => calenderPickerUpdate({ date, item })}
                />
                <Typography.Text className="ml-2">
                  {moment(item?.planned_date).format("MMM Do YY")}
                </Typography.Text>
              </div>
            )}
          </div>
          <div style={{ width: "30px" }} className="mx-2">
            <StartAction
              item={{ data: { ...item }, id: item._id }}
              data={inboxData}
            />
          </div>
          <div style={{ width: "150px" }} className="mx-2">
            {!_.isEmpty(item?.tags) && (
              <Popover
                placement="bottom"
                content={
                  <div className="max-w-sm">
                    {_.map(item?.tags, (tag) => (
                      <Tag color="purple">{tag}</Tag>
                    ))}
                  </div>
                }
              >
                <div className="flex items-center">
                  <Tag color="purple" className="cursor-pointer mr-2">
                    {item?.tags[0]}
                  </Tag>
                  <CaretDownOutlined />
                </div>
              </Popover>
            )}
          </div>
        </div>
      </List.Item>
    );
  };

  if (totalCount === 0) {
    return (
      <div className="w-full h-full flex justify-between items-center flex-col">
        <img src={EmptyBacklog} />
        <div className=" w-2/3 mt-8 flex flex-col justify-center text-center">
          <Text className="text-base" strong>
            Create your first backlog activity
          </Text>
          <Text className="mt-2">
            Streamline team collaboration and project management by creating
            your backlog activities. Assign tasks, track progress, and achieve
            your goals together!"
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.inboxStyle}>
      <DragDropContext onDragEnd={onDragEnd}>
        {_.map(sortedData, (item) => {
          const date = moment(item[0].planned_date).format("MM/DD/YYYY");
          return (
            <Droppable key={date} droppableId={date}>
              {(provided, snapshot) => {
                const isOutDated =
                  moment(date).tz(user?.timezone).isBefore(moment()) &&
                  !moment(date).tz(user?.timezone).isSame(moment(), "day");
                const titleClass = cx({
                  "font-normal": true,
                  "text-red-500": isOutDated,
                });
                return (
                  <div
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <Text
                      style={{ fontSize: 20, fontWeight: 600 }}
                      className={titleClass}
                    >
                      {moment(date).format("MMMM DD")}
                    </Text>
                    {item.map((item, index) => {
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                isDragging={snapshot.isDragging}
                              >
                                <Activityitem item={item} provided={provided} />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default Upcoming;
