import { atom } from "recoil";

export const workspacesList = atom({
  key: "workspacesList",
});

export const workspaceSelected = atom({
  key: "workspaceSelected",
  default: null,
});
