import gql from "graphql-tag";

export const CREATE_TANGO_ACCOUNT = gql`
  mutation {
    createTangoAccount {
      accountIdentifier
      accountNumber
      displayName
      currentBalance
      currencyCode
    }
  }
`;
