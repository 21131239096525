import gql from "graphql-tag";

export const GET_TANGO_ACCOUNT = gql`
  query {
    getActiveTangoAccount {
      accountIdentifier
      accountNumber
      displayName
      createdAt
      currentBalance
      currencyCode
      ccDetails {
        token
        label
        lastFourDigits
        customerIdentifier
        accountIdentifier
        accountNumber
      }
    }
  }
`;
