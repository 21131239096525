import gql from "graphql-tag";

export const GET_JIRA_RESOURCES = gql`
  query($input: getWorkspacesWidgetsInput) {
    getJiraResources(input: $input) {
      name
      id
      url
    }
  }
`;
