import gql from "graphql-tag";

export const CHANGE_DATE_PLAN_ACTIVITY = gql`
  mutation ($input: activityDateChangeInput) {
    plannedActivityDateChange(input: $input) {
      _id
      title
      description
      planned_date
      activity_type
      status
      content
      tags
      key
      timebox
      fileSources
      project {
        _id
        title
        color
      }
    }
  }
`;
