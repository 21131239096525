import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
const { useSetRecoilState } = require("recoil");
const { selectedActivity } = require("recoil/atoms/Activity/selectedActivity");

const useOpenActivityDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const setActivity = useSetRecoilState(selectedActivity);

  const openActivityDrawer = (activity, fromUrl, backlog) => {
    setActivity({ toggle: true, data: activity, fromUrl });
    history.push({
      search: queryString.stringify({
        ...query,
        activityId: activity?._id,
        backlog,
      }),
    });
  };

  return { openActivityDrawer };
};

export default useOpenActivityDrawer;
