import {
  CloseOutlined,
  NotificationFilled,
  NotificationOutlined,
} from "@ant-design/icons";
import { Button, Popover, Tooltip, Typography } from "antd";
import React, { useState, useRef, useEffect } from "react";
import DropdownContent from "./DropdownContent";
import { useQuery, useMutation } from "@apollo/client";
import { GET_REMINDER } from "graphql/queries/reminder/getReminder";
import moment from "moment";
import { get } from "lodash";
import utility from "common/utility";
import { REMOVE_REMINDER } from "graphql/mutations/reminder/removeReminder";
import RemainingTime from "./RemainingTime";
import * as workerTimers from "worker-timers";

const { Text } = Typography;

const Reminder = ({ fromQuickStart, callback }) => {
  const intervalHandle = useRef({ timer: null });

  const updateTimer = () => {
    const isValid = moment(moment().format("MM/DD/YY HH:mm:ss")).isBefore(
      moment(data?.getReminder?.time, "MM/DD/YY HH:mm:ss")
    );
    if (isValid) {
      const diff = utility.calculateTimeDifferenceV2(
        moment().format("MM/DD/YY HH:mm:ss"),
        data?.getReminder?.time
      );

      setRemainingTime(diff);
    } else {
      workerTimers.clearInterval(intervalHandle.current.timer);
      setactiveReminder(false);
    }
  };

  const { loading, data, refetch } = useQuery(GET_REMINDER, {
    fetchPolicy: "network-only",
  });

  const [showReminderDropdown, setshowReminderDropdown] = useState(false);
  const [activeReminder, setactiveReminder] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);

  const [removeReminder] = useMutation(REMOVE_REMINDER, {
    onCompleted: () => {
      utility.setNotification(
        `Success`,
        "Reminder Removed",
        "success",
        "topRight"
      );
      refetch();
      setactiveReminder(false);
      setRemainingTime(null);
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (!loading) {
      const isValid = moment(moment().format("MM/DD/YY HH:mm:ss")).isBefore(
        moment(data?.getReminder?.time, "MM/DD/YY HH:mm:ss")
      );
      if (isValid) {
        intervalHandle.current.timer = workerTimers.setInterval(
          updateTimer,
          1000
        );
        setactiveReminder(true);
      } else {
        if (intervalHandle?.current?.timer)
          workerTimers.clearInterval(intervalHandle?.current?.timer);

        setactiveReminder(false);
      }
    }
  }, [loading, data]);

  const handleExtend = () => {
    setactiveReminder(false);
  };
  const handleRemove = () => {
    removeReminder({ variables: { id: data?.getReminder?._id } });
  };

  return (
    <div className="flex justify-center items-center">
      <Popover
        placement="topLeft"
        content={
          <DropdownContent
            onCreate={() => refetch()}
            activeReminder={activeReminder}
            setactiveReminder={setactiveReminder}
            onRemove={handleRemove}
            onExtend={handleExtend}
            setshowReminderDropdown={setshowReminderDropdown}
            callback={callback}
          />
        }
        trigger="click"
        onVisibleChange={(visible) => setshowReminderDropdown(visible)}
        visible={showReminderDropdown}
        title={
          <div className="flex justify-between items-center w-48">
            {activeReminder && (
              <RemainingTime data={remainingTime} visible={activeReminder} />
            )}
            {!activeReminder && (
              <div className="text-center w-full p-2">
                <Text className="font-semibold text-base ">Set a Reminder</Text>
              </div>
            )}
            <CloseOutlined
              className="icon-small"
              onClick={() => setshowReminderDropdown(false)}
            />
          </div>
        }
      >
        {fromQuickStart ? (
          <Button style={{ width: 130 }} type="default">
            Set Reminder
          </Button>
        ) : (
          <div className="flex">
            {activeReminder ? (
              <Tooltip title="Reminder">
                <NotificationFilled
                  style={{ fontSize: 30, color: "#FAAD14" }}
                  className="ml-6"
                  onClick={() => setshowReminderDropdown(true)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Reminder">
                <NotificationOutlined
                  style={{ fontSize: 30 }}
                  className="ml-6"
                  onClick={() => setshowReminderDropdown(true)}
                />
              </Tooltip>
            )}
          </div>
        )}
      </Popover>
    </div>
  );
};

export default Reminder;
