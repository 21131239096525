import gql from "graphql-tag";

export const GET_TODOIST = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTodoist(input: $input) {
      _id
      access_token
      sync {
        todoist_project
        windu_project
        todoist_project_label
      }
    }
  }
`;
