import gql from "graphql-tag";

export const STOP_MONDAY_ACTIVITY = gql`
  mutation ($input: stopMondayActivityInput) {
    stopMondayActivity(input: $input) {
      _id
      title
    }
  }
`;
