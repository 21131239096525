import React, { useState } from "react";
import {
  Pagination,
  Typography,
  Col,
  Row,
  Input,
  Dropdown,
  Menu,
  Popover,
  Table,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { Icon } from "@fishyvisions/windu-uikit";
import { GET_CLIENTS } from "graphql/queries/client/getClients";
import { array, func, bool } from "prop-types";
import { map, size, debounce } from "lodash";
import moment from "moment";
import Loader from "components/Loader";
import EmptyData from "../../components/EmptyData";

const { Title } = Typography;
const sizePerPage = 5;
const initialOffset = 0;
const initialSearch = "";

const Clients = () => {

  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
  });

  const { loading: clientsLoading, data } = useQuery(GET_CLIENTS, {
    fetchPolicy: "cache-and-network",
    variables: { input: queryParameters },
  });


  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact name",
      key: "contact",
      dataIndex: "full_name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "created_at",
    },
    {
      title: "Total Projects",
      key: "total_projects",
      render: ({ total_projects, projects }) => {
        return (
          <div className="flex items-center">
            <Title level={5} className="mr-3">
              {total_projects}
            </Title>
            <Popover
              style={{ width: 500 }}
              content={map(projects, (project) => (
                <Title level={5}>{project.title}</Title>
              ))}
              placement="right"
              title="Projects related"
              trigger="hover"
              className="cursor-pointer"
            >
              <InfoCircleOutlined
                className="text-md"
                style={{ color: "#f5a623" }}
              />
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (value) => moment(value).format("MM/DD/YYYY"),
    },
    {
      title: "Last Update",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 150,
      render: (value) => moment(value).format("MM/DD/YYYY"),
    },
    
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        children: [
          { title: "Title", dataIndex: "title", key: "title" },
          { title: "Description", dataIndex: "description", key: "decription" },
          {
            title: "Total Time",
            dataIndex: "total_time",
            key: "total_time",
          },
          {
            title: "Total Activities",
            dataIndex: "activities",
            key: "activities",
            render: (activities) => size(activities),
          },
          {
            title: "Total Members",
            dataIndex: "members",
            key: "members",
            render: (members) => size(members),
          },
        ],
      },
    ];
    return (
      <Table
        locale={{
          emptyText: <EmptyData />,
        }}
        rowSelection={{
          columnTitle: "Projects",
          renderCell: () => <div></div>,
        }}
        columns={columns}
        dataSource={record?.projects}
        pagination={false}
        nestedTable={true}
      />
    );
  };

  const delayedQuery = React.useCallback(
    debounce((query) => {
      onSearch(query);
    }, 1000),
    []
  );

  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const tableLoading = {
    spinning: clientsLoading,
    indicator: <Loader />,
  };

  return (
    <>
      <Row className="mb-6">
        <Col xs={20}>
          <Title level={4}>Clients List</Title>
        </Col>
      </Row>
      <Row className="mb-6">
        <Col lg={12} xs={12}>
          <Input.Search
            className="w-4/6"
            placeholder="Search by company name or email"
            size="large"
            allowClear
            onChange={({ target }) => delayedQuery(target.value)}
            onSearch={(value) => onSearch(value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            locale={{
              emptyText: <EmptyData />,
            }}
            columns={columns}
            dataSource={map(data?.getClients?.data, (client) => ({
              ...client,
              key: client?._id,
            }))}
            pagination={false}
            loading={tableLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {!clientsLoading && (
            <Pagination
              className="mt-3 text-center"
              current={data?.getClients?.pagination?.current_page}
              onChange={onPageChange}
              total={data?.getClients?.pagination?.total_data || 0}
              pageSize={queryParameters.size}
              showSizeChanger
              pageSizeOptions={["5", "10", "20", "50", "100"]}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};
Clients.propTypes = {
  projects: array,
  setProjectId: func,
  onRemove: func,
  setIsInviteMemberVisible: func,
  loading: bool,
};
export default Clients;
