import gql from "graphql-tag";

export const REMOVE_MANAGER = gql`
  mutation ($input: removeManagerInput) {
    removeManager(input: $input) {
      _id
      email
    }
  }
`;
