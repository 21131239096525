import * as yup from "yup";

const validationSchema = yup.object().shape({
  settings: yup.object().shape({
    server_name: yup.string().required("This field is required").nullable(),
    server_url: yup.string().required("This field is required").nullable(),
  }),
});

export default validationSchema;
