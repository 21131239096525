import React from "react";
import { GoogleLogin } from "react-google-login";
import { func, string } from "prop-types";

const GoogleLoginBtn = ({
  responseType,
  accessType,
  onSuccess,
  onFailure,
  scope,
  text,
  prompt,
  figma,
}) => {
  return (
    <GoogleLogin
      redirectUri="http://localhost:8081/google"
      responseType={responseType}
      accessType={accessType}
      className={`m-auto w-full flex justify-center`}
      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT}.apps.googleusercontent.com`}
      buttonText={text}
      onSuccess={onSuccess}
      onFailure={onFailure}
      scope={scope}
      prompt={prompt}
    />
  );
};

GoogleLoginBtn.propTypes = {
  onSuccess: func,
  onFailure: func,
  scope: string,
  text: string,
};

GoogleLoginBtn.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  scope: null,
  text: "Login using Google",
};

export default GoogleLoginBtn;
