import { Modal } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import _ from "lodash";
import { convertToRaw } from "draft-js";
import { Formik } from "formik";
import * as yup from "yup";

import DuplicateActivites from "./DuplicateActivites";
import NewActivityContent from "./PlanActivity";

import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { map, upperFirst, isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import utility from "common/utility";

import { uploadActivityFiles } from "api/UploadActivityFiles";

import { PLAN_ACTIVITY } from "graphql/mutations/Activity/planActivity";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  details: yup.string().required("This field is required"),
  project: yup.string().required("This field is required"),
});

const PlanActivityModal = ({
  selectedDate,
  isVisible,
  onClose,
  defaultValue,
  title,
}) => {
  const [visibleView, setVisibleView] = useState("new");
  const user = useRecoilValue(userSession);
  const [progress, setProgress] = React.useState(0);
  const [duplicateFile, setduplicateFile] = useState(null);
  const form = React.useRef();
  const handleOnClose = () => {
    onClose();
  };

  const initialFormValues = {
    title: defaultValue ? defaultValue.title : "",
    details: defaultValue ? defaultValue?.details : "",
    project: [],
    date: moment(selectedDate),
    tasks: [],
  };

  const [planActivity, { loading: planning }] = useMutation(PLAN_ACTIVITY, {
    onCompleted: () => {
      utility.setNotification(
        "Success",
        `Your activity was planned!`,
        "success",
        "topRight"
      );
      onClose();
    },
    onError: (error) => {
      utility.setNotification(
        "Error",
        `${toString(error.message)}`,
        "error",
        "topRight"
      );
    },
    refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
  });

  const handleDone = async (values) => {
    const {
      title,
      details,
      project,
      timebox,
      files,
      selectedTags,
      date,
      tasks,
    } = values;
    const contentData = !isEmpty(details)
      ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
      : "";
    let filesObj = [];
    if (files.length > 0) {
      filesObj = await uploadActivityFiles({
        userId: user?._id,
        fileList: files,
        updateProgress: setProgress,
      });
      setProgress(0);
    }
    const payload = {
      planned_date: moment.utc(date),
      projectId: project,
      details: contentData,
      title: upperFirst(title),
      created_by: user?._id,
      tags: selectedTags,
      timebox,
      timebox,
      fileSources: map(filesObj, (file) => file.Location),
      tasks: _.map(tasks, (task) => {
        const newData = _.omit(task, ["_id"]);
        return {
          ...newData,
          assigned: task?.assigned?._id,
        };
      }),
    };

    planActivity({ variables: { input: { ...payload } } });
  };

  return (
    <Modal
      closable={false}
      onCancel={handleOnClose}
      visible={isVisible}
      width={900}
      footer={null}
      style={{ background: "transparent", boxShadow: "none", border: "none" }}
      bodyStyle={{
        background: "transparent",
        boxShadow: "none",
        border: "none",
        padding: 0,
      }}
      className={styles.activityModalClass}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleDone(values)}
        innerRef={form}
      >
        {() => {
          return (
            <>
              {duplicateFile && (
                <DuplicateActivites
                  setVisibleView={setVisibleView}
                  visibleView={visibleView}
                  onClose={onClose}
                />
              )}

              <NewActivityContent
                setVisibleView={setVisibleView}
                visibleView={visibleView}
                form={form}
                isVisible={isVisible}
                onClose={onClose}
                progress={progress}
                creating={planning}
                title={title ? title : "Plan an activity"}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PlanActivityModal;
