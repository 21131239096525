import gql from "graphql-tag";

export const TOGGLE_WORKSPACE_WIDGET = gql`
  mutation ($input: toggleWidgetInput) {
    toggleWorkspaceWidget(input: $input) {
      widget {
        _id
        name
      }
      h
      i
      w
      x
      y
      toggle
    }
  }
`;
