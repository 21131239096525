import gql from "graphql-tag";

export const GET_CONTRIBUTORS = gql`
  query ($input: getProjectContributorsInput) {
    getContributors(input: $input) {
      _id
      email
      full_name
      avatar
    }
  }
`;
