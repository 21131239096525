import gql from "graphql-tag";

export const GET_PUSHED_CALENDARS_EVENTS = gql`
  query($input: getWorkspacesWidgetsInput) {
    getPushedGoogleCalendarsEvents(input: $input) {
      pushed_calendars {
        calendar
        project {
          manager_id {
            _id
          }
          created_by {
            _id
          }
          title
        }
      }
    }
  }
`;
