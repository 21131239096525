import React, { useState } from "react";
import { Typography, Button } from "antd";
import { useRecoilState } from "recoil";

import Card from "../Card";
import MondayLogo from "../../../common/assets/mondayLogo.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { widgetsState } from "recoil/atoms/Dashboard/widgets";

import { Formik, Form, Field } from "formik";
import { AntInput } from "components/FormikCustomInputs";
import _ from "lodash";
import * as yup from "yup";
import CommonAuth from "../common/Auth";
import useCreateIntegrationHook from "common/createIntegration";
import ConnectModal from "components/Modals/ConnectModal";

const validationSchema = yup.object().shape({
  token: yup.string().trim().required("This field is required"),
});

const Auth = ({ setAuthenticated, loading }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [validWidgets, setValidWidgets] = useRecoilState(widgetsState);

  const [tokenView, setTokenView] = React.useState(false);
  const formRef = React.useRef();

  const { createIntegration, loading: integrationLoading } =
    useCreateIntegrationHook();
  const [connectModal, setConnectModal] = useState(false);

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    setValidWidgets((widgets) => {
      const expandMonday = widgets?.workspace[id]?.map((w) => {
        if (w.widget.name === "MONDAY") {
          return { i: "MONDAY", ...w, h: 1 };
        }
        return w;
      });

      return {
        ...widgets,
        workspace: { [id]: expandMonday },
      };
    });
    formRef.current.resetForm();
    setTokenView(false);
  };

  const handleSubmit = (values) => {
    const { token } = values;
    createIntegration({
      type: "monday",
      access_token: token,
      workspace: id,
      setAuthenticated,
    });
  };

  const handleConnect = () => {
    setValidWidgets((widgets) => {
      const expandMonday = widgets?.workspace[id]?.map((w) => {
        if (w.widget.name === "MONDAY") {
          return { i: "MONDAY", ...w, h: 2 };
        }
        return w;
      });

      return {
        ...widgets,
        workspace: { [id]: expandMonday },
      };
    });
    setTokenView(true);
  };

  return (
    <Card title="Monday" style={{ height: !tokenView && "140px" }}>
      {!tokenView ? (
        <CommonAuth
          onConnect={() => setConnectModal({ label: "Monday", workspace: id })}
          loading={loading}
          logo={MondayLogo}
          text="Connect Monday and start, pause and stop trackable tasks from
                your boards."
        />
      ) : (
        <div className="p-4">
          <Formik
            onSubmit={(values) => handleSubmit(values)}
            initialValues={{ token: "" }}
            innerRef={formRef}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Typography.Text className=" text-base">
                      In order to get you started with Monday, you first have to
                      visit your Monday account and copy the token.
                    </Typography.Text>
                    <Typography.Link
                      href="https://developer.monday.com/api-reference/docs/authentication#:~:text=their%20API%20tokens.-,Accessing%20API%20Tokens,-Admin%20Tab"
                      target="_blank"
                      className="ml-2 text-base"
                    >
                      Learn more
                    </Typography.Link>
                  </div>
                  <Field
                    required={true}
                    placeholder="Paste your access token here"
                    label="Monday access token"
                    name="token"
                    size="large"
                    component={AntInput}
                  />
                </Form>
              );
            }}
          </Formik>

          <div className="flex items-center justify-evenly">
            <Button
              type="primary"
              className="w-32"
              size="large"
              onClick={handleDone}
              loading={integrationLoading}
            >
              Submit
            </Button>
            <Button className="w-32" size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {connectModal && (
        <ConnectModal
          visible={connectModal}
          setVisible={setConnectModal}
          callback={handleConnect}
          setAuthenticated={setAuthenticated}
        />
      )}
    </Card>
  );
};

export default Auth;
