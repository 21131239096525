import { Avatar, Button, Divider, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SELECTED_INTEGRATION } from "graphql/queries/Integrations/getSelectedIntegration";
import Loader from "components/Loader";
import useCreateIntegrationHook from "common/createIntegration";
import EmptyData from "components/EmptyData";
import CheckToken from "./CheckToken";
import { parseToken } from "components/Widgets/utils";

const ConnectModal = ({
  visible,
  setVisible,
  callback,
  setAuthenticated,
  GoogleButton,
}) => {
  const [integrationData, setintegrationData] = useState([]);
  const { createIntegration } = useCreateIntegrationHook();

  const [getSelectedIntegration, { loading: integrationsLoading }] =
    useLazyQuery(GET_SELECTED_INTEGRATION, {
      variables: {
        name:
          _.lowerCase(visible.label) === "meetings"
            ? "google"
            : _.lowerCase(visible.label) === "jira cloud"
            ? "Jira Cloud"
            : _.lowerCase(visible.label),
      },
      onCompleted: ({ getSelectedIntegration }) => {
        setintegrationData(getSelectedIntegration);
        if (
          _.isEmpty(getSelectedIntegration) &&
          _.lowerCase(visible.label) != "google"
        ) {
          if (callback) callback();
          setVisible(false);
        }
      },
    });

  useEffect(() => {
    getSelectedIntegration();
  }, []);

  const handleCancel = () => {
    setVisible(false);
    callback();
  };

  const handleUseAction = (item) => {
    createIntegration({
      type: item.name,
      access_token: item.access_token,
      refresh_token: item.refresh_token,
      workspace: visible.workspace,
    });
    if (setAuthenticated) setAuthenticated(item.access_token);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      closable={false}
      footer={null}
      width={500}
      title={
        <div className="flex items-center">
          {getIntegrationLogo(_.lowerCase(visible.label))}
          <Typography.Text className="ml-2 capitalize" strong>
            {visible.label}
          </Typography.Text>
        </div>
      }
    >
      {integrationsLoading && <Loader />}
      {_.isEmpty(integrationData) && !integrationsLoading && <EmptyData />}
      {_.map(integrationData, (item) => {
        return (
          <>
            <div className="flex items-center justify-between" key={item?._id}>
              <div className="flex items-center">
                <Avatar src={item?.avatar} size={"large"} />
                <Typography.Text className="ml-2 text-base" strong>
                  {item?.user_name}
                </Typography.Text>
              </div>
              <div>
                <CheckToken
                  token={item?.access_token}
                  name={visible.label}
                  handleUseAction={() => handleUseAction(item)}
                />
              </div>
            </div>
            <Divider />
          </>
        );
      })}

      {_.lowerCase(visible.label) === "google" ? (
        <GoogleButton />
      ) : (
        <div className="flex items-center w-full justify-center">
          <Button
            style={{ background: "#1FAD08" }}
            size="middle"
            className=" text-white px-5"
            onClick={handleCancel}
          >
            Keep Connecting
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ConnectModal;
