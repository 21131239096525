import gql from "graphql-tag";

export const NEW_COMMENT = gql`
  subscription {
    newComment {
      _id
      content
      created_at
      edited
      created_by {
        _id
        full_name
        email
        avatar
      }
    }
  }
`;
