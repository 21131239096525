export const privateRoutes = [
  "/messages",
  "/people",
  "/invoices",
  "/clients",
  "/clients/:page",
  "/projects",
  "/projects/:id",
  "/settings",
  "/timeline",
  "/workspace",
  "/integration",
  "/",
  "/import",
  "/plans",
  "/authorize",
  "/report",
  "/quickstart",
  "/search",
  "/calendar",
];
