import React from "react";
import { map, size } from "lodash";
import moment from "moment";
import utility from "common/utility";
import ActivityBar from "components/ActivityBar";

import styles from "./styles.module.css";

const RenderActivityBar = ({ data, row, column, timezone }) => {
  const showActivityDateOverview = data.children;

  if (showActivityDateOverview) {
    // Start map of activities inside a date
    return map(data.children, (activity, index) => {
      const activitiesBars = utility.getActivitiesBars(
        activity,
        column,
        row,
        timezone
      );

      if (size(activitiesBars) > 0) {
        return (
          <>
            {map(activitiesBars, (time) => {
              return (
                <ActivityBar
                  column={column}
                  start={time.start}
                  end={time.end}
                  active={time.active}
                  timezone={timezone}
                  type={activity?.activity_type}
                />
              );
            })}
            {index === 0 && <div className={styles.line}></div>}
          </>
        );
      }
      return index === 0 && <div className={styles.line}></div>;
    });
  }

  /// ------------- Activities that are collapse
  const collapseRow = moment(data.time.end).format("MM/DD/YY");
  const activitiesBars = utility.getActivitiesBars(
    data,
    column,
    collapseRow,
    timezone
  );

  if (size(activitiesBars) > 0) {
    return (
      <>
        {map(activitiesBars, (time) => {
          return (
            <ActivityBar
              column={column}
              start={time.start}
              end={time.end}
              active={time.active}
              timezone={timezone}
              type={data?.activity_type}
            />
          );
        })}
        <div className={styles.line}></div>
      </>
    );
  }
  return <div className={styles.line}></div>;
};

export default RenderActivityBar;
