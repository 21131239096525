import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  Typography,
  Button,
  Row,
  Col,
  Layout,
  AutoComplete,
  Form as AntForm,
} from "antd";
import { Formik, Form, Field } from "formik";
import _ from "lodash";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import queryString from "query-string";
import { isEmpty, debounce, toString } from "lodash";
import { GET_REQUEST } from "graphql/queries/request/getRequest";
import { ACCEPT_INVITE } from "graphql/mutations/client/acceptClientInvite";
import { REGISTER_CLIENT } from "graphql/mutations/client/registerClient";
import Loader from "components/Loader";
import NotFound from "views/Provisional/404";
import cc from "currency-codes";
import utility from "common/utility";
import FloaterInput from "components/FloaterInput";
import OnboardingSvg from "common/assets/onboard.svg";
import * as yup from "yup";
import styles from "./styles.module.css";
import WinduLogo from "common/assets/WinduLogo.svg";

const { Option } = AutoComplete;
const FormItem = AntForm.Item;

const currency = _.map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const validationSchema = yup.object().shape({
  country: yup.string().required(),
  company: yup.string().required(),
  zip: yup.string().required(),
  currency: yup.string().required(),
  state: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
});

const { Title, Text } = Typography;
const ClientInviteAccept = ({ location, history }) => {
  const formRef = React.useRef();
  const [request, setRequest] = React.useState({});
  const [address, setAddress] = React.useState("");
  const requestId = queryString.parse(location?.search).request;
  const projectId = queryString.parse(location?.search).project;

  const { loading, error, data } = useQuery(GET_REQUEST, {
    fetchPolicy: "cache-and-network",
    variables: { id: requestId },
    onCompleted: (data) => {
      if (data) {
        const { getRequest } = data;
        setRequest(getRequest);
      }
    },
  });

  const [acceptClientInvite, { loading: accepting }] = useMutation(
    ACCEPT_INVITE,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happens",
          `${toString(error)}`,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        debounceNavigate();
      },
    }
  );

  const [registerClient, { loading: registerClientLoading }] = useMutation(
    REGISTER_CLIENT,
    {
      onError(error) {
        utility.setNotification(
          "Error",
          `${toString(error)}`,
          "error",
          "topRight"
        );
      },
    }
  );

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  const debounceNavigate = debounce(() => history.push("/"), 500);

  const handleDone = (values = {}) => {
    if (data?.getRequest.newClient) {
      const { company, address, city, state, country, zip, currency } = values;

      registerClient({
        variables: {
          input: {
            projectId,
            company,
            address,
            city,
            state,
            country,
            currency,
            zip,
            email: data?.getRequest?.invitee,
          },
        },
      });
    }
    const payload = {
      ...values,
      email: data?.getRequest?.invitee,
      status: "Accepted",
      requestId,
      projectId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    acceptClientInvite({ variables: { input: payload } });
    utility.setNotification(
      "Success",
      `Invitation Accepted`,
      "success",
      "topRight"
    );
  };

  const setAddressField = async (v, item, setFieldValue) => {
    const fullAddress = v.split(",");

    const country = fullAddress[fullAddress.length - 1];
    const state = fullAddress[fullAddress.length - 2];
    const city = fullAddress[fullAddress.length - 3];

    const street = fullAddress
      .slice(0, fullAddress.length - 3 === 0 ? 1 : fullAddress.length - 3)
      .join();

    const placeId = item.data.placeId;

    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};

    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("country", country);
    setFieldValue("address", street);
    setFieldValue("zip", postalCode);
  };

  if (loading) return <Loader size="large" />;
  if (isEmpty(data.getRequest) || error) return <NotFound />;
  if (data?.getRequest.status !== "Pending") return <NotFound />;

  const initialFormValues = {
    company: "",
    address: "",
    city: "",
    state: "",
    currency: "USD",
    country: "",
    zip: "",
  };

  const subNew = `Please fill out some information to setup your client account`;

  const renderSetup = () => {
    if (data?.getRequest.newClient) {
      return (
        <Col xs={24} sm={24} md={12} className="bg-white flex">
          <>
            <div
              className="px-12 w-3/4 m-auto flex flex-col justify-between"
              style={{ maxWidth: 500, height: "80vh" }}
            >
              <div className="mb-6 text-center">
                <Title level={2} style={{ marginBottom: 0 }}>
                  Set client profile
                </Title>
                <Text className="text-base -mt-2 ">{subNew}</Text>
              </div>
              <Formik
                initialValues={initialFormValues}
                onSubmit={(values) => handleDone(values)}
                validationSchema={validationSchema}
                innerRef={formRef}
              >
                {({
                  handleSubmit,
                  submitCount,
                  errors,
                  touched,
                  values,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Field
                        label="Company name"
                        required
                        name="company"
                        component={FloaterInput}
                        placeholder="Company name"
                        submitCount={submitCount}
                        hasFeedback
                        size="large"
                      />
                      <PlacesAutocomplete value={address} onChange={setAddress}>
                        {({ getInputProps, suggestions, loading }) => {
                          const haveTouched = touched["address"];
                          const submitted = submitCount > 0;
                          const hasError = errors["address"];
                          const submittedError = hasError && submitted;
                          const touchedError = hasError && haveTouched;

                          return (
                            <div className="field-container w-full">
                              <FormItem
                                className="mb-2 mt-5"
                                help={
                                  submittedError || touchedError
                                    ? hasError
                                    : false
                                }
                                hasFeedback={
                                  submitted || haveTouched ? true : false
                                }
                                validateStatus={
                                  submittedError || touchedError
                                    ? "error"
                                    : "success"
                                }
                                required
                              >
                                <AutoComplete
                                  onSearch={(v) =>
                                    getInputProps().onChange({
                                      target: { value: v },
                                    })
                                  }
                                  required
                                  name="address"
                                  value={values.address}
                                  onChange={(v) => setFieldValue("address", v)}
                                  onSelect={(v, item) =>
                                    setAddressField(v, item, setFieldValue)
                                  }
                                  size="large"
                                  placeholder="Address"
                                  className="text-left"
                                >
                                  {!loading &&
                                    suggestions.map((suggestion) => (
                                      <Option
                                        key={suggestion.description}
                                        value={suggestion.description}
                                        data={suggestion}
                                      >
                                        {suggestion.description}
                                      </Option>
                                    ))}
                                </AutoComplete>
                              </FormItem>
                            </div>
                          );
                        }}
                      </PlacesAutocomplete>

                      <Field
                        component={FloaterInput}
                        label="City"
                        name="city"
                        size="large"
                        placeholder="City"
                        required
                        submitCount={submitCount}
                        hasFeedback
                        className="mr-5"
                      />
                      <Field
                        component={FloaterInput}
                        label="State"
                        name="state"
                        size="large"
                        placeholder="State"
                        required
                        submitCount={submitCount}
                        hasFeedback
                        className="mr-1"
                      />
                      <Field
                        component={FloaterInput}
                        label="Country"
                        name="country"
                        required
                        size="large"
                        submitCount={submitCount}
                        hasFeedback
                        placeholder="Country"
                      />
                      <Field
                        label="Zip"
                        required
                        name="zip"
                        component={FloaterInput}
                        placeholder="Zip Code"
                        submitCount={submitCount}
                        hasFeedback
                        size="large"
                      />
                      <Field
                        component={FloaterInput}
                        name="currency"
                        label="Currency"
                        placeholder="Currency"
                        submitCount={submitCount}
                        options={currency}
                        loading={false}
                        inputType="select"
                        size="large"
                        required
                        hasFeedback
                      />
                    </Form>
                  );
                }}
              </Formik>
              <div className="flex justify-between items-center">
                <Button
                  size="large"
                  onClick={() => onSubmit()}
                  key="submit"
                  type="primary"
                  loading={accepting || registerClientLoading}
                  className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
                >
                  Accept
                </Button>
              </div>
              <div className="text-center">
                <Text>Copyright © Windu 2023 | All rights reserved</Text>
              </div>
            </div>
          </>
        </Col>
      );
    }

    return (
      <Col xs={24} sm={24} md={12} className="bg-white flex">
        <>
          <div
            className="px-12 text-center w-3/4 m-auto flex flex-col justify-between"
            style={{ marginTop: "20%", maxWidth: 500, height: "80vh" }}
          >
            <div className="flex flex-col justify-center items-center">
              <div className="flex items-baseline justify-center">
                <img src={WinduLogo} alt="windu" style={{ height: 30 }} />
                <Title level={2} className="ml-2">
                  Windu's invite
                </Title>
              </div>

              <Text className="text-base -mt-2 ">
                Click on the link below to complete the invite process
              </Text>

              <div className="flex justify-center items-center w-full">
                <Button
                  size="large"
                  onClick={() => handleDone()}
                  key="submit"
                  type="primary"
                  loading={accepting}
                  className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
                >
                  Accept
                </Button>
              </div>
            </div>
            <div className="text-center">
              <Text>Copyright © Windu 2023 | All rights reserved</Text>
            </div>
          </div>
        </>
      </Col>
    );
  };

  return (
    <Layout className="flex justify-center min-h-screen">
      <Row style={{ height: "100vh" }} className="bg-white">
        {renderSetup()}
        <Col xs={0} sm={0} md={12} className="m-auto p-5">
          <div
            className=" rounded-xl"
            style={{ background: "#B1A5EB", height: "92vh" }}
          >
            <img
              src={OnboardingSvg}
              alt="windu-onboarding"
              style={{ minHeight: "80vh" }}
              className="p-5"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default withRouter(ClientInviteAccept);
