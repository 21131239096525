import gql from "graphql-tag";

export const RESET_USER_PASSWORD = gql`
  mutation($email: String!) {
    resetUserPassword(email: $email) {
      token
      user {
        _id
        email
        full_name
      }
    }
  }
`;
