import {
  CaretLeft,
  Checks,
  DotsThree,
  DotsThreeVertical,
  Strategy,
  X,
} from "@phosphor-icons/react";
import {
  Button,
  Card,
  Divider,
  Typography,
  Form as AntForm,
  Upload,
  Progress,
  Checkbox,
  Input,
  Dropdown,
  Popconfirm,
  Tooltip,
} from "antd";
import React, { useRef, useState } from "react";
import styles from "../styles.module.css";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FlagOutlined,
  PlusOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { AntInput, AntSelect } from "components/FormikCustomInputs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import _ from "lodash";
import Loader from "components/Loader";
import utility from "common/utility";
import moment from "moment";
import AssigneSelectDropdown from "../AssigneSelectDropdown";
import UserAvatar from "components/UserAvatar";
import CalendarPicker from "components/CalendarPicker";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { CREATE_PLAYBOOK_ACTIVITY } from "graphql/mutations/Playbook/createPlaybookActivity";
import { useMutation } from "@apollo/client";
import { UPDATE_PLAYBOOK_ACTIVITY } from "graphql/mutations/Playbook/updatePlaybookActivity";

const { Text } = Typography;

const newActivityValidationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  details: yup.string().trim().required("This field is required"),
});

const toolbarOptions = {
  options: ["inline", "list"],
};

const NewActivitySlideDrawer = ({
  setIsSlideDrawerOpen,
  filterSearchUser,
  setFilterSearchUser,
  handleSearch,
  projectTags,
  mainFormValues,
  mainActivityValues,
  backlogId,
  selectedGoal,
  setMainActivityData,
  editingPlaybookActivity,
  setMainFormValues,
  workspace,
  setEditingPlaybookActivity,
  backlogRefetch,
}) => {
  const newActivityFormRef = useRef(null);
  const [files, setFiles] = React.useState([]);
  const [fileSources, setFileSources] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const user = useRecoilValue(userSession);

  const [newTask, setNewTask] = useState({
    _id: _.uniqueId("newtask-"),
    description: null,
    planned: null,
    assigned: null,
    completed: false,
  });

  const initialPlaybookActivity = {
    title: editingPlaybookActivity ? editingPlaybookActivity?.title : "",
    details:
      editingPlaybookActivity && editingPlaybookActivity?.details
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(editingPlaybookActivity?.details))
          )
        : "",
    assigned: editingPlaybookActivity
      ? editingPlaybookActivity?.created_by?._id
      : null,
    priority:
      editingPlaybookActivity && editingPlaybookActivity?.priority
        ? editingPlaybookActivity?.priority
        : null,
    selectedTags:
      editingPlaybookActivity && editingPlaybookActivity?.tags
        ? editingPlaybookActivity?.tags
        : [],
    tasks:
      editingPlaybookActivity && editingPlaybookActivity?.tasks
        ? editingPlaybookActivity?.tasks
        : [],
  };

  const getStatusClassNames = (status) => {
    switch (status) {
      case 1:
        // low
        return styles.lowPrioritySelect;
      case 2:
        // mid
        return styles.midPrioritySelect;
      case 3:
        // high
        return styles.highPrioritySelect;

      default:
        return styles.prioritySelect;
    }
  };

  const props = {
    name: "file",
    multiple: true,
    accept: ".txt, .doc, .docx, .jpg, .jpeg, .png, .pdf",
    customRequest: ({ onSuccess }) => {
      onSuccess("ok");
    },
    onChange({ fileList }) {
      setFiles((prevFiles) => _.uniqBy([...prevFiles, ...fileList], "uid"));
    },
  };

  const editorStateHandler = (v, setFieldValue) => {
    // seteditorState(v);
    setFieldValue("details", v);
  };

  const handleAddNewTask = (values, setFieldValue) => {
    setFieldValue("tasks", [...values?.tasks, newTask]);
    setNewTask({
      _id: _.uniqueId("newtask-"),
      description: null,
      planned: null,
      assigned: null,
      completed: false,
    });
  };

  const [createPlaybookActivity, { loading: creatingPlaybookActivity }] =
    useMutation(CREATE_PLAYBOOK_ACTIVITY, {
      onCompleted: ({ createPlaybookActivity }) => {
        setMainActivityData({ data: createPlaybookActivity });
        newActivityFormRef.current.resetForm();
        backlogRefetch();
        setIsSlideDrawerOpen(false);
        setEditingPlaybookActivity(null);
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  const [updatePlaybookActivity, { loading: updatingPlaybookActivity }] =
    useMutation(UPDATE_PLAYBOOK_ACTIVITY, {
      onCompleted: ({ updatePlaybookActivity }) => {
        setMainActivityData({ data: updatePlaybookActivity });
        backlogRefetch();
        newActivityFormRef.current.resetForm();
        setIsSlideDrawerOpen(false);
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    });

  const handleDone = async (values) => {
    const { details, title, assigned, files, selectedTags, priority, tasks } =
      values;
    const contentData = !_.isEmpty(details)
      ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
      : "";

    let filesObj = [];
    if (files?.length > 0) {
      filesObj = await uploadActivityFiles({
        userId: user?._id,
        fileList: files,
        updateProgress: setProgress,
      });
      setProgress(0);
    }

    const payload = {
      title: _.upperFirst(title),
      details: contentData,
      assigned: assigned ?? null,
      created_by: user._id,
      tags: selectedTags,
      priority,
      tasks: _.map(tasks, (task) => {
        const newData = _.omit(task, ["_id"]);
        return {
          ...newData,
          assigned: task?.assigned?._id,
        };
      }),
    };

    // create in graphql mutation and also make sure to return upadted activity so it will be updated in editing varible

    if (editingPlaybookActivity) {
      updatePlaybookActivity({
        variables: {
          input: {
            ..._.omit(payload, ["created_by"]),
            projectId: workspace?.project?._id,
            activityId: editingPlaybookActivity._id,
            backlogActivityId: mainActivityValues._id,
          },
        },
      });
    } else {
      if (_.isEmpty(mainActivityValues)) {
        // completly new activity mock the data
        const updatedPlaybookData = _.map(mainFormValues?.playbook, (goal) => {
          if (goal._id === selectedGoal) {
            const updatedGoal = {
              ...goal,
              activities: [...goal.activities, { ...payload }],
            };

            return updatedGoal;
          } else return goal;
        });
        setMainFormValues("playbook", updatedPlaybookData);
        setIsSlideDrawerOpen(false);
        newActivityFormRef.current.resetForm();
      } else {
        // create a new playbookActivity into db and update the editing with refetch
        createPlaybookActivity({
          variables: {
            input: {
              ...payload,
              fileSources: _.map(filesObj, (file) => file?.Location),
              activityId: mainActivityValues?._id,
              backlogId,
              goalId: selectedGoal,
            },
          },
        });
      }
    }
  };

  return (
    <div className="p-2">
      <div
        className="flex items-center justify-between px-2.5 pt-1 pb-4 "
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
      >
        <Text className="text-base " strong style={{ color: "#808080" }}>
          Create a New Activity
        </Text>
        <X
          color="#0d0d0d"
          className="cursor-pointer"
          onClick={() => {
            setIsSlideDrawerOpen(false);
            setEditingPlaybookActivity(null);
          }}
          size={18}
        />
      </div>
      <div className="mt-2">
        <Formik
          initialValues={initialPlaybookActivity}
          validationSchema={newActivityValidationSchema}
          onSubmit={(values) => handleDone(values)}
          innerRef={newActivityFormRef}
        >
          {({ values, setFieldValue, submitCount, errors, handleSubmit }) => {
            return (
              <div>
                <Form onSubmit={handleSubmit} className={`${styles.mainFrom}`}>
                  <div
                    className=" flex flex-col overflow-x-hidden overflow-y-scroll p-2"
                    style={{ height: 480 }}
                  >
                    <Field
                      placeholder="Marketing goals"
                      required={true}
                      label="Title"
                      name="title"
                      hasFeedback={false}
                      component={AntInput}
                      spellCheck="true"
                      help={false}
                      style={{ marginBottom: "0px" }}
                    />
                    <div
                      className={`${styles.drawerEditorDiv} ${
                        submitCount > 0 &&
                        errors.details &&
                        styles.editorErrorDiv
                      } `}
                    >
                      <Field
                        placeholder="What are you going to accomplish?"
                        required
                        render={({ field }) => (
                          <AntForm.Item
                            required
                            labelCol={{ span: 24 }}
                            label="Details"
                            validateStatus={
                              submitCount > 0 && errors.details ? "error" : ""
                            }
                            help={
                              submitCount > 0 && errors.details
                                ? errors.details
                                : ""
                            }
                          >
                            <Editor
                              editorState={values?.details}
                              onEditorStateChange={(editorState) =>
                                editorStateHandler(editorState, setFieldValue)
                              }
                              wrapperClassName="flex flex-col-reverse"
                              placeholder="What are you going to accomplish?"
                              toolbar={toolbarOptions}
                              spellCheck="true"
                            />
                          </AntForm.Item>
                        )}
                      />
                    </div>

                    <Field
                      mode="tags"
                      placeholder="Select tags"
                      inputType="select"
                      name="selectedTags"
                      component={AntSelect}
                      options={_.map(projectTags, (item) => {
                        return { value: item, label: item };
                      })}
                    />
                    <div className={styles.projectSelect}>
                      <Field
                        component={AntSelect}
                        name="assigned"
                        label="Assignee"
                        placeholder="Search by email"
                        className="my-1"
                        selectOptions={_.map(filterSearchUser, (user) => {
                          return {
                            value: user._id,
                            labelText: user?.email,
                            label: (
                              <div>
                                <UserAvatar
                                  user={user}
                                  size={30}
                                  className="mr-2"
                                />
                                <Typography.Text>{user?.email}</Typography.Text>
                              </div>
                            ),
                          };
                        })}
                        optionLabelProp="label"
                        inputType="select"
                        hasFeedback
                        labelColNotReq={true}
                        showSearch
                        notFoundContent={
                          <div className="flex justify-center">No Data</div>
                        }
                        filterOption={false}
                        allowClear
                        onSearch={(v) => handleSearch(v, values)}
                        getPopupContainer={(node) => node.parentNode}
                        dropdownRender={(menu) => {
                          return <div>{menu}</div>;
                        }}
                      />
                    </div>

                    <div className={getStatusClassNames(values?.priority)}>
                      <Field
                        placeholder="Not set"
                        name="priority"
                        inputType="select"
                        className="my-1"
                        labelColNotReq={true}
                        label="Priority"
                        component={AntSelect}
                        options={[
                          { value: 0, label: "Not set" },
                          { value: 1, label: "Low" },
                          { value: 2, label: "Medium" },
                          { value: 3, label: "High" },
                        ]}
                        suffixIcon={<FlagOutlined />}
                      />
                    </div>

                    <div>
                      <div className="flex items-center justify-between my-3 ml-1.5">
                        <Typography.Text>Attachment</Typography.Text>
                        <Upload
                          {...props}
                          fileList={files}
                          showUploadList={false}
                        >
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </div>
                      <div className="mt-2 max-h-44 overflow-y-auto">
                        {files.map((file) => (
                          <div
                            className="flex items-center justify-between mt-3 py-1 px-2"
                            key={file.uid}
                            style={{ background: "#FAFAFA" }}
                          >
                            <Text style={{ color: "#1890FF" }}>
                              {utility.truncateFileName(file?.name)}
                            </Text>

                            <DeleteOutlined
                              className=" cursor-pointer"
                              onClick={() => {
                                setFiles((prevFiles) =>
                                  prevFiles.filter((f) => f.uid !== file.uid)
                                );
                              }}
                            />
                          </div>
                        ))}

                        {fileSources.map((file) => (
                          <div
                            className="flex items-center justify-between mt-3 py-1 px-2"
                            key={file}
                            style={{ background: "#FAFAFA" }}
                          >
                            <Text style={{ color: "#1890FF" }}>
                              {utility.truncateFileName(file)}
                            </Text>

                            <DeleteOutlined
                              className=" cursor-pointer"
                              onClick={() => {
                                setFileSources((prevFiles) =>
                                  prevFiles.filter((f) => f !== file)
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {progress > 0 && (
                        <Progress percent={progress} showInfo={false} />
                      )}
                    </div>

                    <>
                      <div className="flex items-center mb-2">
                        <Checks size={26} style={{ color: "#7CBF7E" }} />
                        <Text className="ml-2">Tasks</Text>
                      </div>
                      {/* new task */}
                      <div className={styles.newTaskDiv}>
                        <div>
                          <div className="flex items-start">
                            <Checkbox
                              defaultChecked={newTask.completed}
                              checked={newTask.completed}
                              onChange={(v) =>
                                setNewTask({
                                  ...newTask,
                                  completed: v.target.checked,
                                })
                              }
                            />
                            <div className="ml-3 flex-1">
                              <Input
                                value={newTask.description}
                                spellCheck={true}
                                onChange={(v) => {
                                  setNewTask({
                                    ...newTask,
                                    description: v.target?.value,
                                  });
                                }}
                              />
                              <div className="flex justify-between mt-1 ml-1 ">
                                <div className="flex items-center ">
                                  <Dropdown
                                    trigger={["click"]}
                                    overlay={() => (
                                      <AssigneSelectDropdown
                                        handleSearch={handleSearch}
                                        values={values}
                                        filterSearchUser={filterSearchUser}
                                        setValue={(v) =>
                                          setNewTask({
                                            ...newTask,
                                            assigned: v,
                                          })
                                        }
                                        value={newTask?.assigned}
                                      />
                                    )}
                                  >
                                    {newTask?.assigned ? (
                                      <div className="cursor-pointer">
                                        <UserAvatar
                                          size={24}
                                          user={newTask?.assigned}
                                        />
                                        <Text
                                          className="ml-2 text-xs underline"
                                          style={{ color: "#A5ACB8" }}
                                        >
                                          {newTask?.assigned?.full_name}
                                        </Text>
                                      </div>
                                    ) : (
                                      <div className="flex items-center cursor-pointer">
                                        <UserAddOutlined
                                          style={{ color: "#A5ACB8" }}
                                        />
                                        <Text
                                          style={{ color: "#A5ACB8" }}
                                          className="text-xs ml-1 underline"
                                        >
                                          Assignee
                                        </Text>
                                      </div>
                                    )}
                                  </Dropdown>
                                  <div
                                    className={`flex items-center ml-3 ${styles.calenderIconClass} `}
                                  >
                                    <CalendarPicker
                                      initialDate={moment()}
                                      onDateChange={(date) =>
                                        setNewTask({
                                          ...newTask,
                                          planned: date,
                                        })
                                      }
                                      label={
                                        <Text
                                          className="text-xs underline"
                                          style={{ color: "#A5ACB8" }}
                                        >
                                          {newTask?.planned
                                            ? moment(newTask?.planned).format(
                                                "dddd, MMMM D"
                                              )
                                            : "Schedule"}
                                        </Text>
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <Button
                                    icon={<CheckOutlined />}
                                    type="primary"
                                    size="small"
                                    onClick={() =>
                                      handleAddNewTask(values, setFieldValue)
                                    }
                                  >
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* display the tasks */}
                      {_.map(values?.tasks, (task) => {
                        return (
                          <div className={styles.newTaskDiv}>
                            <div>
                              <div className="flex items-start">
                                <Checkbox
                                  defaultChecked={task.completed}
                                  onChange={(v) => {
                                    const updatedTasks = values.tasks.map((t) =>
                                      t._id === task._id
                                        ? { ...t, completed: v.target.checked }
                                        : t
                                    );
                                    setFieldValue("tasks", updatedTasks);
                                  }}
                                />
                                <div className="ml-3 flex-1">
                                  <div>
                                    <Text
                                      editable={{
                                        onChange: (newDescription) => {
                                          const updatedTasks = values.tasks.map(
                                            (t) =>
                                              t._id === task._id
                                                ? {
                                                    ...t,
                                                    description: newDescription,
                                                  }
                                                : t
                                          );
                                          setFieldValue("tasks", updatedTasks);
                                        },
                                        icon: (
                                          <EditOutlined
                                            style={{ color: "#A5ACB8" }}
                                          />
                                        ),
                                      }}
                                    >
                                      {task?.description}
                                    </Text>

                                    <Popconfirm
                                      placement="topRight"
                                      title="Are you sure to delete this task?"
                                      onConfirm={() => {
                                        const updatedTasks =
                                          values.tasks.filter(
                                            (t) => t._id !== task._id
                                          );
                                        setFieldValue("tasks", updatedTasks);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip title="Remove task">
                                        <DeleteOutlined
                                          style={{ color: "#A5ACB8" }}
                                          className="ml-2"
                                        />
                                      </Tooltip>
                                    </Popconfirm>
                                  </div>

                                  <div className="flex items-center mt-1 mr-1 justify-end ">
                                    <div className="flex items-center mr-2">
                                      <CalendarPicker
                                        initialDate={
                                          task?.planned
                                            ? task.planned
                                            : moment()
                                        }
                                        onDateChange={(date) => {
                                          const updatedTasks = values.tasks.map(
                                            (t) =>
                                              t._id === task._id
                                                ? { ...t, planned: date }
                                                : t
                                          );
                                          setFieldValue("tasks", updatedTasks);
                                        }}
                                        label={
                                          <Text className="text-xs underline">
                                            {task?.planned
                                              ? moment(task?.planned).format(
                                                  "dddd, MMMM D"
                                                )
                                              : "Schedule"}
                                          </Text>
                                        }
                                      />
                                    </div>
                                    <Dropdown
                                      trigger={["click"]}
                                      overlay={() => (
                                        <AssigneSelectDropdown
                                          handleSearch={handleSearch}
                                          values={values}
                                          filterSearchUser={filterSearchUser}
                                          setValue={(v) => {
                                            const updatedTasks =
                                              values.tasks.map((t) =>
                                                t._id === task._id
                                                  ? { ...t, assigned: v }
                                                  : t
                                              );
                                            setFieldValue(
                                              "tasks",
                                              updatedTasks
                                            );
                                          }}
                                          value={task?.assigned}
                                        />
                                      )}
                                    >
                                      {task?.assigned ? (
                                        <div className="cursor-pointer">
                                          <UserAvatar
                                            size={24}
                                            user={task?.assigned}
                                          />
                                          <Text className="ml-2 text-xs underline">
                                            {task?.assigned?.full_name}
                                          </Text>
                                        </div>
                                      ) : (
                                        <div className="flex items-center cursor-pointer">
                                          <UserAddOutlined />
                                          <Text className="text-xs ml-1 underline">
                                            Assignee
                                          </Text>
                                        </div>
                                      )}
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>

                  <div className="p-4 flex gap-1 items-center justify-end">
                    <Button
                      onClick={() => {
                        setIsSlideDrawerOpen(false);
                        setEditingPlaybookActivity(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={`${styles.blackButton} outline-none border-none ml-2`}
                      type="primary"
                      onClick={handleSubmit}
                      icon={<PlusOutlined />}
                      loading={
                        creatingPlaybookActivity || updatingPlaybookActivity
                      }
                    >
                      {_.isEmpty(editingPlaybookActivity)
                        ? " Create Activity"
                        : "Update Activity"}
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default NewActivitySlideDrawer;
