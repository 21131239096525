import React, { useEffect } from "react";
import Modal from "../BaseModal/Modal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { RETRIEVE_CHECKOUT } from "graphql/queries/Stripe/retrieveCheckout";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import { GET_USER } from "graphql/queries/getUser";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import { Typography } from "antd";

const SubscribedConfirmedModal = ({
  isVisible,
  onClose,
  plan,
  session_id,
  text,
}) => {
  const handleCloseAfterSubscription = () => {
    setTimeout(() => {
      onClose();
      window.history.pushState({ path: `` }, "", `/settings?view=profile`);
    }, 2000);
  };

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {},

    refetchQueries: [
      {
        query: GET_USER,
      },
      {
        query: GET_WORKSPACES,
      },
    ],
  });

  const [retrieveCheckout, { loading: retrieveLoading, refetch }] =
    useLazyQuery(RETRIEVE_CHECKOUT, {
      onCompleted: ({ retrieveCheckout }) => {
        updateUser({ variables: { input: { plan } } });
        handleCloseAfterSubscription();
      },
    });

  useEffect(() => {
    if (plan && session_id) {
      retrieveCheckout({ variables: { input: { id: session_id } } });
    }
  }, []);

  const content = (
    <div className="flex flex-col justify-center">
      <img src="https://windu.s3.us-east-2.amazonaws.com/assets/windu_illustrations_free_subscription100.jpg" />
      {text && <Typography.Text className="text-base">{text}</Typography.Text>}
    </div>
  );

  return (
    <Modal
      title="You are now Subscribed!"
      isVisible={isVisible}
      content={content}
      onClose={onClose}
      footer={null}
    />
  );
};

export default SubscribedConfirmedModal;
