import gql from "graphql-tag";

export const CONNECT_GITHUB = gql`
  mutation($input: connectGithubInput) {
    connectGithub(input: $input) {
      name
      url
      repos_url
      access_token
      organizations_url
      login
    }
  }
`;
