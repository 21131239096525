import gql from "graphql-tag";

export const GET_WORKSPACES_MESSAGES = gql`
  query {
    getWorkspacesMessages {
      _id
      workspaceName
      unreadMessages {
        type
        unread
      }
      totalUnreadMessages
    }
  }
`;
