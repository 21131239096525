import { CloudDownloadOutlined } from "@ant-design/icons";
import { FileCsv } from "@phosphor-icons/react";
import { Button, Col, Divider, Pagination, Row, Tag, Typography } from "antd";
import CsvImportModal from "components/Modals/CsvImport";
import React, { useState } from "react";
import _ from "lodash";
import PreviewItems from "./PreviewItems";
import { GET_IMPORTS } from "graphql/queries/Import/getImports";
import { useQuery } from "@apollo/client";
import utility from "common/utility";
import moment from "moment";
import Loader from "components/Loader";
import NoImports from "./NoImports";

const { Title, Text } = Typography;

const defualtImportData = {
  fields: {
    title: null,
    details: null,
    tags: null,
    priority: null,
    tasks: null,
  },
  data: [],
  preview: false,
  fileName: "",
};

const Import = () => {
  const [csvImportModal, setCsvImportModal] = useState(false);

  const [importedData, setImportedData] = useState(defualtImportData);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const {
    data: getImportData,
    loading: importLoading,
    refetch,
  } = useQuery(GET_IMPORTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedImports = getImportData?.getImports.slice(
    startIndex,
    endIndex
  );

  if (importLoading) return <Loader />;
  return (
    <div>
      {importedData.preview ? (
        <PreviewItems
          importedData={importedData}
          defualtImportData={defualtImportData}
          setImportedData={setImportedData}
          refetch={refetch}
        />
      ) : (
        <div>
          <Row className="mb-8">
            <Col xs={20}>
              <Title level={4}>Import</Title>
              <Text>
                Seamless Data Migration: Bring Your Projects Together
                Effortlessly.{" "}
                <Typography.Link
                  href="https://help.windu.io/en/article/jumpstart-your-project-by-importing-eljmuv/?bust=1705330688378"
                  target="_blank"
                  className="ml-2"
                >
                  Learn more
                </Typography.Link>
              </Text>
            </Col>
            <Col xs={4} className="flex justify-end pr-3">
              <Button
                key="submit"
                type="primary"
                onClick={() => setCsvImportModal(true)}
                icon={<CloudDownloadOutlined />}
                size="large"
              >
                New Import
              </Button>
            </Col>
          </Row>
          <div>
            {_.isEmpty(paginatedImports) ? (
              <NoImports />
            ) : (
              <>
                {_.map(paginatedImports, (item) => {
                  return (
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between">
                          <div
                            className=" h-20 w-20 flex items-center justify-center rounded-md"
                            style={{ background: "rgb(223 225 227)" }}
                          >
                            <FileCsv size={38} color="#8f8f8f" weight="fill" />
                          </div>
                          <div className="flex flex-col ml-5">
                            <Text className="text-base" strong>
                              {item?.fileName}
                            </Text>
                            <Text style={{ color: "#A9ABAE" }}>
                              Imported on{" "}
                              {moment(item?.imported_at).format(
                                "MMM DD, YYYY  hh:mm A"
                              )}
                            </Text>
                            <Text style={{ color: "#A9ABAE" }}>
                              {item?.totalImportedItems} of {item?.totalItems}{" "}
                              activities imported.
                            </Text>
                          </div>
                        </div>

                        <div>
                          <Tag color="#87d068">Completed</Tag>
                        </div>
                      </div>

                      <Divider />
                    </div>
                  );
                })}
                <div className="flex justify-center">
                  <Pagination
                    total={getImportData?.getImports.length}
                    pageSize={itemsPerPage}
                    current={currentPage}
                    onChange={(page) => setCurrentPage(page)}
                    showTotal={(total) => `Total ${total} items`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {csvImportModal && (
        <CsvImportModal
          isVisible={csvImportModal}
          setIsVisible={setCsvImportModal}
          setImportedData={setImportedData}
        />
      )}
    </div>
  );
};

export default Import;
