import gql from "graphql-tag";

export const GET_SELECTED_INTEGRATION = gql`
  query ($name: String!) {
    getSelectedIntegration(name: $name) {
      _id
      name
      user_name
      access_token
      refresh_token
      uniq_id
      avatar
    }
  }
`;
