import React, { useState } from "react";
import {
  Button,
  Modal,
  Card,
  Select,
  Table,
  Pagination,
  ConfigProvider,
  Tooltip,
  Avatar,
  DatePicker,
} from "antd";
import { useLazyQuery } from "@apollo/client";
import moment_tz from "moment-timezone";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { userSession } from "recoil/atoms/User/UserSession";
import Loader from "components/Loader";
import _ from "lodash";
import EmptyData from "components/EmptyData";
import { GET_COMPLETED_PROJECT_ACTIVITIES } from "graphql/queries/projects/getCompletedProjectActivities";
import UserAvatar from "components/UserAvatar";
import utility from "common/utility";

const defaultFilters = {
  sort: "asc",
  start: moment.utc(new Date()).subtract(1, "months"),
  end: moment.utc(new Date()),
  onlyRates: true,
};

const ChooseActivityModal = ({ isVisible, onClose, onSubmit, dataSource }) => {
  const workspace = useRecoilValue(workspaceSelected);
  const user = useRecoilValue(userSession);
  const [activitySelected, setactivitySelected] = useState([]);
  const [projectActivity, setProjectActivity] = useState([]);
  const [queryParameters, setQueryParameters] = useState({
    size: 10,
    offset: 0,
    project: workspace?.project._id,
    filters: {
      ...defaultFilters,
    },
  });

  const [getProjectActivities, { loading: loadingActivities, data }] =
    useLazyQuery(GET_COMPLETED_PROJECT_ACTIVITIES, {
      variables: { input: queryParameters },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setProjectActivity(
          _.map(data?.getCompletedProjectActivities?.data, (activity) => ({
            ...activity,
            key: activity._id,
          }))
        );
      },
    });

  const onSortChange = (value) => {
    setQueryParameters({
      ...queryParameters,
      filters: {
        ...queryParameters.filters,
        sort: value,
      },
    });
  };

  const onRangeChange = (dates) => {
    const startDate = dates[0]?.format();
    const endDate = dates[1]?.format();

    setQueryParameters({
      ...queryParameters,
      filters: {
        ...queryParameters.filters,
        start: moment.utc(new Date(startDate)),
        end: moment.utc(new Date(endDate)),
      },
    });
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setactivitySelected(selectedRows);
    },
    getCheckboxProps: (record) => {
      const isInList = dataSource.map((activity) =>
        activity.activitiesSelected.map(
          (item) => item.activityId === record._id
        )
      );
      const isAlreadySlected = _.filter(isInList, (item) => {
        return item.includes(true);
      });

      return {
        disabled: isAlreadySlected[0],
        // Column configuration not to be checked
        name: record.name,
      };
    },
  };

  const handleCreateModal = async () => {
    const activitiesByDates = activitySelected.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateA - dateB;
    });

    const endDate =
      activitiesByDates[
        Object.keys(activitiesByDates)[
          Object.keys(activitiesByDates).length - 1
        ]
      ];

    let dateRange;
    if (activitySelected.length === 1) {
      dateRange = `${moment(activitiesByDates[0]?.created_at).format(
        "MM/DD/YYYY"
      )}`;
    } else {
      dateRange = `${moment(activitiesByDates[0]?.created_at).format(
        "MM/DD/YYYY"
      )}-${moment(endDate?.created_at).format("MM/DD/YYYY")}`;
    }

    onSubmit(activitySelected, dateRange);
    onClose();
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  React.useEffect(() => {
    if (isVisible && workspace) {
      setactivitySelected([]);
      getProjectActivities();
    }
  }, [isVisible, workspace]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Started",
      key: "started",
      dataIndex: "time",
      render: (value, item) => {
        if (item?.status === 6) {
          const startDate = _.first(item?.handoff)?.time?.start;
          return moment_tz(startDate)
            .tz(user.timezone)
            .format("MM/DD/YYYY, h:mm:ss a");
        } else if (value?.start)
          return moment_tz(value.start)
            .tz(user.timezone)
            .format("MM/DD/YYYY, h:mm:ss a");
      },
    },
    {
      title: "Ended",
      key: "ended",
      dataIndex: "time",
      render: (value, item) => {
        if (item.status === 6) {
          const endDate = _.last(item?.handoff)?.time?.end;
          return moment_tz(endDate)
            .tz(user.timezone)
            .format("MM/DD/YYYY, h:mm:ss a");
        } else {
          if (value?.end)
            return moment_tz(value?.end)
              .tz(user.timezone)
              .format("MM/DD/YYYY, h:mm:ss a");
        }
      },
    },
    {
      title: "Total Time",
      key: "total_time",
      dataIndex: "time",
      render: (value, item) => {
        if (item.status == 6) {
          // calculate the totalTime of allUser
          const totalTimeArray = _.map(
            item.handoff,
            (handoff) => handoff.time.total_time
          );
          const totalTime = utility.calculateTimeTotal(totalTimeArray);
          return totalTime;
        } else return value?.total_time;
      },
    },
    {
      title: "Team Members(s)",
      key: "team_member",
      dataIndex: "created_by",
      render: (value, item) => {
        if (item.status === 6) {
          const users = _.uniqBy(
            _.map(item.handoff, (handoff) => handoff.user),
            "_id"
          );
          return (
            <Avatar.Group maxCount={3}>
              {_.map(users, (member) => (
                <Tooltip title={member.email} placement="top" key={member._id}>
                  <UserAvatar user={member} />
                </Tooltip>
              ))}
            </Avatar.Group>
          );
        } else
          return (
            <Tooltip
              title={value.email}
              placement="top"
              style={{ background: "red", zIndex: "99999999999 !important" }}
            >
              <UserAvatar user={value} />
            </Tooltip>
          );
      },
    },
  ];

  const tableLoading = {
    spinning: loadingActivities,
    indicator: <Loader size="large" />,
  };

  return (
    <Modal
      title="Create Items"
      visible={isVisible}
      width="70vw"
      onCancel={() => {
        setactivitySelected([]);
        onClose();
      }}
      footer={[
        <Button
          key="submit"
          size="large"
          type="primary"
          className="px-10"
          onClick={handleCreateModal}
        >
          Create
        </Button>,
        <Button
          key="back"
          size="large"
          className="px-10"
          onClick={() => onClose()}
        >
          Cancel
        </Button>,
      ]}
    >
      <Card title="Choose activities" style={{ boxShadow: "none" }}>
        <div className="flex items-center mb-4">
          <div className="w-1/4">
            <DatePicker.RangePicker
              size="large"
              onChange={onRangeChange}
              defaultValue={[
                moment(moment().subtract(1, "month").toDate(), "YYYY/MM/DD"),
                moment(moment(), "YYYY/MM/DD"),
              ]}
              className="w-full"
            />
          </div>
          <Select
            placeholder="Sort By"
            className="ml-6 w-36"
            defaultValue={defaultFilters.sort}
            onChange={onSortChange}
          >
            <Select.Option value="asc">Date Ascending</Select.Option>
            <Select.Option value="desc">Date Descending</Select.Option>
            <Select.Option value="title">Title</Select.Option>
          </Select>
        </div>
        <ConfigProvider renderEmpty={() => <EmptyData />}>
          <Table
            rowSelection={{
              preserveSelectedRowKeys: true,

              type: "checkbox",
              ...rowSelection,
            }}
            loading={tableLoading}
            pagination={false}
            columns={columns}
            dataSource={projectActivity}
          />
        </ConfigProvider>
        {!loadingActivities && (
          <Pagination
            className="my-4 text-center"
            current={
              data?.getCompletedProjectActivities?.pagination?.current_page
            }
            onChange={onPageChange}
            total={
              data?.getCompletedProjectActivities?.pagination?.total_data || 0
            }
            pageSize={queryParameters.size}
            showSizeChanger
            pageSizeOptions={["5", "10", "20", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
            }
          />
        )}
      </Card>
    </Modal>
  );
};

export default ChooseActivityModal;
