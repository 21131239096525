import React from "react";
import { Button } from "antd";
import { useMutation } from "@apollo/client";
import Modal from "components/Modals/BaseModal/Modal";
import { Formik, Form, Field } from "formik";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CREATE_BACKLOG_GROUP } from "graphql/mutations/Backlog/createBacklogGroup";
import { UPDATE_BACKLOG_GROUP } from "graphql/mutations/Backlog/updateBacklogGroup";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import * as yup from "yup";
import {
  AntDatePicker,
  AntDateRangePicker,
  AntInput,
} from "components/FormikCustomInputs";
import utility from "common/utility";
import moment from "moment";

const CreateGroupModal = ({
  isVisible,
  onClose,
  backlogId,
  title,
  editingItem,
  backlogRefetch,
}) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const initialFormValues = {
    title: editingItem ? editingItem.data?.title : "",
    date: editingItem?.data?.start_date
      ? [
          moment(editingItem.data?.start_date),
          moment(editingItem.data?.end_date),
        ]
      : "",
  };
  const form = React.useRef();

  const [createBacklogGroup, { loading: creating }] = useMutation(
    CREATE_BACKLOG_GROUP,
    {
      onCompleted: () => {
        if (backlogRefetch) backlogRefetch();
        utility.setNotification(
          "Success",
          `Backlog group created successfully`,
          "success",
          "topRight"
        );
        onClose();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
      awaitRefetchQueries: true,
    }
  );

  const [updateBacklogGroup, { loading: updating }] = useMutation(
    UPDATE_BACKLOG_GROUP,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          `Group updated successfully`,
          "success",
          "topRight"
        );
        form.current.resetForm();
        onClose();
        if (backlogRefetch) backlogRefetch();
      },
      onError: (error) => {
        utility.setNotification(
          "Error",
          `${toString(error.message)}`,
          "error",
          "topRight"
        );
      },
    }
  );

  const onSubmit = () => form.current.submitForm();

  const handleDone = (values) => {
    const { title, date } = values;

    if (editingItem) {
      // update group
      const payload = {
        groupId: editingItem.id,
        backlogId,
        title: title,
        start_date: date && date[0],
        end_date: date && date[1],
      };

      updateBacklogGroup({
        variables: {
          input: {
            ...payload,
          },
        },
      });
    } else {
      if (isVisible?.groupId) {
        const payload = {
          backlogId,
          title,
          start_date: date[0],
          end_date: date[1],
          groupId: isVisible?.groupId,
        };
        createBacklogGroup({ variables: { input: { ...payload } } });
      } else {
        const payload = {
          backlogId,
          title,
          start_date: date[0],
          end_date: date[1],
        };
        createBacklogGroup({ variables: { input: { ...payload } } });
      }
    }
  };

  const handleOnClose = () => {
    form.current.resetForm();
    onClose();
  };

  const validationSchema = yup.object().shape({
    title: yup.string().trim().required("This field is required"),
  });

  return (
    <Modal
      title={title}
      onCancel={handleOnClose}
      isVisible={isVisible}
      width={500}
      maskStyle={{ backgroundColor: "#62C376", opacity: 0.8 }}
      footer={null}
      content={
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleDone(values)}
          innerRef={form}
        >
          {({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  required={true}
                  placeholder="Group title"
                  label="Title"
                  name="title"
                  size="large"
                  component={AntInput}
                />
                <Field
                  component={AntDateRangePicker}
                  name="date"
                  label="Date"
                  size="large"
                  inputType="picker"
                  className="w-full"
                  disabledDate={(current) => {
                    return moment(current).isBefore(
                      moment(new Date()).subtract(1, "days")
                    );
                  }}
                  getPopupContainer={(node) => node.parentNode}
                />
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <>
          <div className="text-center">
            <Button
              loading={creating || updating}
              type="primary"
              className="w-32"
              size="large"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      }
    />
  );
};

export default CreateGroupModal;
