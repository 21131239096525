import gql from "graphql-tag";

export const REMOVE_MEMBER_ALL_PROJECT = gql`
  mutation ($memberId: ID!) {
    removeMember(memberId: $memberId) {
      _id
      full_name
    }
  }
`;
