import React from "react";
import { size, isEmpty } from "lodash";
import { Typography } from "antd";
import moment from "moment";
import SlothImage from "common/assets/sloth.svg";

const { Text, Title } = Typography;

const ActivityDateCard = ({ data, monthName }) => {
  const month = moment(new Date(data.date)).format("MMMM");
  const numberDay = moment(new Date(data.date)).format("DD");

  return (
    <div style={{ zIndex: 100 }}>
      {monthName && (
        <div
          style={{ background: "#F7F7FC" }}
          className="w-full h-12 absolute left-0 top-0 py-2.5 pl-5 "
        >
          <Typography.Text className=" text-lg font-semibold ">
            {monthName}
          </Typography.Text>
        </div>
      )}

      {isEmpty(data.children) ? (
        <div style={{ paddingTop: monthName && "52px" }}>
          <div className="flex items-center">
            <Title className="m-0 pr-3" style={{ color: "#F5A623" }} level={2}>
              {numberDay}
            </Title>
            <Text>{month}</Text>
          </div>
          <div className="flex items-center">
            <Typography.Text>No activities today</Typography.Text>
            <img
              src={SlothImage}
              className="absolute"
              style={{
                marginBottom: "30px",
                marginLeft: "140px",
                height: 86,
                zIndex: 999999,
              }}
            />
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: monthName && "52px" }}>
          <div className="flex items-center">
            <Title className="m-0 pr-3" style={{ color: "#F5A623" }} level={2}>
              {numberDay}
            </Title>
            <Text>{month}</Text>
          </div>
          <div className="flex items-center">
            <Text>{`Total Activities: ${size(data?.children)}`}</Text>
          </div>
          <div className="flex items-center">
            <Text>{`Total Time: ${data?.total_time}`}</Text>
          </div>
        </div>
      )}
    </div>
  );
};
export default ActivityDateCard;
