import React from "react";
import { withRouter } from "react-router-dom";
import { bool, func, instanceOf, string } from "prop-types";
import NavMenu from "./NavMenu";
import NavHead from "./NavHead";

const NavBar = ({
  collapsed = true,
  history,
  currentView,
  query = null,
  user,
}) => {
  const paddingCollapsed = collapsed ? 0 : 25;
  return (
    <>
      <div
        style={{
          paddingLeft: paddingCollapsed,
          paddingRight: paddingCollapsed,
          margin: "10px!important",
        }}
      >
        <NavHead collapsed={collapsed} />

        <NavMenu
          user={user}
          history={history}
          currentView={currentView}
          query={query}
          collapsed={collapsed}
        />
      </div>
    </>
  );
};

NavBar.propTypes = {
  showSettings: func,
  collapsed: bool,
  history: instanceOf(Object),
  currentView: string,
  user: instanceOf(Object),
  setCollapsed: func,
  onCollapse: bool,
};

export default withRouter(NavBar);
