import React from "react";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Cookies from "js-cookie";
import { Typography } from "antd";
import { get } from "lodash";
import { CONFIRM_EMAIL } from "graphql/mutations/user/confirmEmail";
import utility from "common/utility";
import Loader from "components/Loader";

const { Title } = Typography;

const ConfirmEmail = ({ location, history }) => {
  const { user, trial } = queryString.parse(location?.search);

  const [confirmEmail, { loading }] = useMutation(CONFIRM_EMAIL, {
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    onCompleted: (data) => {
      Cookies.set("token", data.confirmEmail.token, { expires: 7 });
      history.push(`/?withTour=true&trial=${trial}`);
    },
  });

  React.useEffect(() => {
    confirmEmail({ variables: { userId: user } });
  }, []);

  return (
    <div className="text-center">
      <Title level={4}>
        Please wait for a second while the email confirmation finished
      </Title>
      {loading && <Loader size="large" />}
    </div>
  );
};

export default withRouter(ConfirmEmail);
