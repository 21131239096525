import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styles from "../../styles.module.css";
import {
  CalendarOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_BILLINGS } from "graphql/queries/Stripe/getBiilings";

const { Text, Title } = Typography;

const Billings = ({ handleViewChange }) => {
  const [data, setData] = useState([]);
  const [queryParameter, setqueryParameter] = useState({ offset: 0, size: 5 });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [lastPaymentDate, setLastPaymentDate] = useState(null);
  const user = useRecoilValue(userSession);
  const userSettingCount = user?.settings?.uniqueProjectsUsers || 0;
  const { loading, data: getData } = useQuery(GET_BILLINGS, {
    variables: { input: { ...queryParameter } },
    onCompleted: ({ getBillings }) => {
      setData(getBillings.data);
      if (_.isEmpty(setLastPaymentDate) && getBillings.data) {
        setLastPaymentDate(getBillings.data[0]?.date);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns = [
    {
      title: "Invoice",
      dataIndex: "name",
      key: "name",
      render: (name, item, index) => {
        const { offset, size } = queryParameter;
        const totalCount = getData?.getBillings?.totalCount || 0;
        const invoiceNumber = totalCount - (offset + index);
        return (
          <Text className="ml-3 text-base capitalize">
            Invoice #{totalCount != 0 && invoiceNumber} - {item?.status}
          </Text>
        );
      },
    },
    {
      title: "Billing date",
      dataIndex: "date",
      key: "date",
      render: (item) => <Text>{moment(item).format("DD MMM, YYYY")}</Text>,
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "amount",
      render: (item) => {
        if (item) return `USD $${item}`;
      },
    },
    {
      dataIndex: "pdfLink",
      key: "pdfLink",
      render: (item) => {
        return (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => window.open(item, "_blank")}
          >
            <FilePdfOutlined />
            <Text className="ml-1 underline" style={{ color: "#3C2E94" }}>
              Download
            </Text>
          </div>
        );
      },
    },
  ];

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    const offset = (current - 1) * pageSize;
    setqueryParameter({ ...queryParameter, offset, size: pageSize });
  };

  const handleDownloadSelected = () => {
    selectedRowKeys.forEach((item) => {
      const pdfLink = item;
      window.open(pdfLink, "_blank");
    });
  };

  return (
    <div>
      <Row>
        <Col span={11}>
          <Card
            className="rounded-lg"
            title={
              <Title level={4}>
                {user?.plan?.name} Plan
                <Tag
                  color="rgba(98, 195, 118, 0.2)"
                  style={{ borderRadius: 8, marginLeft: 4, color: "#0CA051" }}
                >
                  Active
                </Tag>
              </Title>
            }
            extra={
              <Button
                size="large"
                className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
                type="primary"
                onClick={() => handleViewChange("subscriptions")}
              >
                Upgrade
              </Button>
            }
          >
            <div className="flex justify-between">
              {!_.isEmpty(user?.addons) && (
                <div className="flex flex-col">
                  {_.map(user?.addons, (item) => (
                    <div className="flex items-center">
                      <CheckCircleFilled style={{ color: "#52C41A" }} />
                      <Text className="ml-1 text-base">{item?.name}</Text>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex items-center">
                <Text style={{ fontSize: 25 }} strong>
                  $
                </Text>
                <Text style={{ fontSize: 40 }} strong>
                  {Number(
                    _.sum([
                      ..._.map(user?.addons, (item) => item.cost),
                      user?.plan?.cost * (userSettingCount + 1),
                    ])
                  ).toFixed(2)}
                </Text>
                <Text className="ml-2 mt-2">per month</Text>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={11} className="ml-4">
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Card
              title={<Title level={4}>Upcoming Payment</Title>}
              className="rounded-lg flex-grow"
              extra={
                <Text style={{ color: "#3C2E94" }} className="text-base">
                  {lastPaymentDate &&
                    moment(lastPaymentDate)
                      .add(1, "month")
                      .add(1, "day")
                      .format("DD MMM, YYYY")}
                </Text>
              }
            >
              <div
                style={{ background: "#F8F8F8" }}
                className="flex items-center p-3 rounded-lg w-11/12 m-auto"
              >
                <CalendarOutlined />
                <Text className="text-base ml-2" style={{ color: "#475467" }}>
                  Current payment expires on
                  <Text className="text-base ml-1" style={{ color: "#475467" }}>
                    {lastPaymentDate &&
                      moment(lastPaymentDate)
                        .add(1, "month")
                        .format("DD MMM, YYYY")}
                  </Text>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      <Row className="mt-6">
        <Card
          className="w-full rounded-lg"
          title={
            <div className="flex flex-col">
              <Text className="text-lg" strong>
                Billing history
              </Text>
              <Text className="text-base">
                Download your previous plan reciepts and subscription details!
              </Text>
            </div>
          }
          extra={
            <Button
              size="large"
              className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
              type="primary"
              disabled={!hasSelected}
              onClick={handleDownloadSelected}
            >
              <div className="flex items-center">
                <DownloadOutlined />
                <Text className="text-base text-white ml-2">
                  Download Selected
                </Text>
              </div>
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              current: queryParameter.offset / queryParameter.size + 1,
              pageSize: queryParameter.size,
              total: getData?.getBillings?.totalCount || 0,
            }}
            onChange={handleTableChange}
            rowSelection={rowSelection}
            rowKey="pdfLink"
          />
        </Card>
      </Row>
    </div>
  );
};

export default Billings;
