import React from "react";
import { withRouter } from "react-router-dom";
import { Menu, Badge, Button, Typography, Tooltip } from "antd";
import cx from "classnames";
import { useQuery } from "@apollo/client";
import Icon, { SearchOutlined } from "@ant-design/icons";
import { useSetRecoilState, useRecoilValue } from "recoil";
import Icons from "common/icons";
import { map, filter, isEmpty, isNil, includes, uniq, truncate } from "lodash";
import { workspacesList } from "recoil/atoms/Workspaces";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import utility from "common/utility";

import styles from "./styles.module.less";
import { routing } from "constants/routing";
import { pausedActivities } from "recoil/atoms/PausedActivity";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { useFeature } from "@optimizely/react-sdk";

const NavMenu = ({ history, currentView, query = null, user, collapsed }) => {
  const setWorkspaces = useSetRecoilState(workspacesList);
  const workspaces = useRecoilValue(workspacesList);

  const setPausedActivities = useSetRecoilState(pausedActivities);
  const selectedModeValue = useRecoilValue(selectedMode);
  const { plan, extra } = user;

  const [a, importVariables] = useFeature("import");
  const [b, reportVariables] = useFeature("reports");

  const handleFlag = (flag) => {
    if (flag === "import") {
      return importVariables?.showImport;
    }

    if (flag === "report") {
      return reportVariables?.reportEnabled;
    }

    return true;
  };

  const navRouters = filter(routing, (router) => {
    const viewMode = router.inNav && router.modes.includes(selectedModeValue);

    const {
      foreignPlan: { plan: foreignPlan, addons: foreignAddons },
    } = extra;

    const userPlan = foreignPlan || plan?.name;
    const gatherAddons = [...user.addonsReferences, ...foreignAddons];

    const viewUserSubscription =
      router.inNav &&
      (includes(router.plan, userPlan) || includes(gatherAddons, router.addon));

    return handleFlag(router?.flag) && viewMode && viewUserSubscription;
  });

  useQuery(GET_WORKSPACES, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getWorkspaces }) => {
      setWorkspaces(getWorkspaces);
    },
  });

  useQuery(GET_PAUSED_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setPausedActivities(data?.getPausedActivity);
    },
  });

  const handleWorkspace = (id) => {
    history.push({
      pathname: `/workspace`,
      search: `?id=${id}`,
    });

    const wstabs = utility.IsJsonString(
      isNil(localStorage.getItem("wstabs"))
        ? undefined
        : localStorage.getItem("wstabs")
    );
    if (isNil(localStorage.getItem("wstabs"))) {
      //set for first time
      const payload = JSON.stringify([id]);
      localStorage.setItem("wstabs", payload);
    } else {
      const data = JSON.parse(localStorage.getItem("wstabs"));
      const payload = JSON.stringify(uniq([...data, id]));
      localStorage.setItem("wstabs", payload);
    }
  };
  return (
    <>
      <div>
        <>
          {collapsed ? (
            <div className="mt-5 flex justify-center">
              <Tooltip title="Search" placement="right">
                <Button
                  shape="circle"
                  icon={<SearchOutlined style={{ color: "#667085" }} />}
                  onClick={() => history.push("/search")}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="mt-5">
              <Button
                className="w-full rounded-lg"
                size="large"
                onClick={() => history.push("/search")}
              >
                <div className="flex items-center">
                  <SearchOutlined style={{ color: "#667085", fontSize: 20 }} />
                  <Typography.Text
                    style={{ color: "#667085" }}
                    className="ml-2"
                  >
                    Search
                  </Typography.Text>
                </div>
              </Button>
            </div>
          )}
        </>

        <Menu
          style={{ background: "#130935", color: "white" }}
          mode="vertical"
          className="mt-3"
          selectedKeys={[currentView]}
        >
          {/*For controlling the only nav item with asub  */}
          {map(navRouters, (router) => {
            if (router?.label === "Workspaces") {
              if (!isEmpty(workspaces))
                return (
                  <Menu.SubMenu
                    key={router?.label}
                    title={!collapsed && router.label}
                    className={`${collapsed && styles.collapsedMenuItem} ${
                      styles.workspaceSubMenuClass
                    } `}
                    icon={
                      <Icon
                        style={{
                          width: collapsed ? "100%" : "initial",
                          display: collapsed ? "flex" : "initial",
                          justifyContent: collapsed ? "center" : "initial",
                        }}
                        className={cx(
                          styles.icon,
                          router.path === currentView && styles.iconSelected,
                          "ml-2"
                        )}
                        component={Icons[router.label]}
                      />
                    }
                    popupClassName={styles.workspaceSubMenuPopup}
                  >
                    {map(workspaces, (space) => {
                      const color = space?.project?.color;
                      return (
                        <Menu.Item
                          onClick={() => handleWorkspace(space?._id)}
                          key={space._id}
                          className={`pr-4 py-2 flex items-center cursor-pointer text-white  pl-4 justify-start 

                            ${
                              space._id === query.id
                                ? styles.workspaceActive
                                : styles.workspaceInactive
                            }
                            `}
                        >
                          <Badge
                            className={styles.projectDot}
                            color={`${
                              isEmpty(color) || color === "none"
                                ? "#808080"
                                : color
                            }`}
                          />
                          {truncate(space.project?.title, {
                            length: 28,
                          })}
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              else {
                return <></>;
              }
            }
            return (
              <>
                <Menu.Item
                  key={router.path}
                  title={router?.label}
                  onClick={() => {
                    history.push({
                      pathname: router.path,
                      search: router?.search,
                    });
                  }}
                  className={cx(
                    "nav-item-windu",
                    styles.menuItem,
                    `tutorial-${router.label.toLowerCase()}`,
                    collapsed && styles.collapsedMenuItem,
                    router.path === currentView && styles.selectedMenuItem
                  )}
                  icon={
                    <Icon
                      style={{
                        width: collapsed ? "100%" : "initial",
                        display: collapsed ? "flex" : "initial",
                        justifyContent: collapsed ? "center" : "initial",
                      }}
                      className={cx(
                        styles.icon,
                        router.path === currentView && styles.iconSelected,
                        "ml-2"
                      )}
                      component={Icons[router.label]}
                    />
                  }
                >
                  {!collapsed && router.label}
                </Menu.Item>
              </>
            );
          })}
        </Menu>
      </div>
    </>
  );
};

export default withRouter(NavMenu);
