import gql from "graphql-tag";

export const LIST_ADDONS = gql`
  query {
    getAddons {
      _id
      name
      currency
      cost
      period
    }
  }
`;
