import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { groupBy, map, flatMap, has, get, find, isEmpty, filter } from "lodash";
import { Typography, Collapse, Tag, Row, Col } from "antd";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import { searchIssues } from "api/Jira";
import styles from "../styles.module.css";
import JiraTicket from "./JiraTicket";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { GET_JIRA_ACTIVITY } from "graphql/queries/Jira/getJiraActivity";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import {
  updateRecentActivities,
  updateContinueActivityList,
} from "common/cacheUtilities";
import { CREATE_JIRA_ACTIVITY } from "graphql/mutations/Jira/createJiraActivity";
import { transitionJiraActivity } from "api/Jira";
import { STOP_ACTIVITY } from "graphql/mutations/Activity/stopActivity";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { parseWorkspaceIntegration } from "../utils";
import utility from "common/utility";
import Loader from "components/Loader";
import _ from "lodash";
import EmptyData from "components/EmptyData";
import { useTimer } from "contexts/timer-context";
import { pausedActivities } from "recoil/atoms/PausedActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
const { Panel } = Collapse;
const { Text } = Typography;

const ServerSettingView = ({ token, onRejected, setSelected }) => {
  const { values, setFieldValue, submitCount } = useFormikContext();
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([[], []]);
  const [activeCollapse, setActiveCollapse] = useState("0");
  const [user] = useRecoilState(userSession);
  const [activity, setActivity] = useRecoilState(activeActivity);
  const resource = values?.settings?.server_name;
  const [pausedActivity] = useRecoilState(pausedActivities);
  const { startHandler } = useTimer(); // global startHandler from context
  const location = useLocation();
  const enlargeModal = useRecoilValue(enlargeState);

  const { id } = queryString.parse(location.search);

  const [createJiraActivity] = useMutation(CREATE_JIRA_ACTIVITY, {
    onCompleted: ({ createJiraActivity }) => {
      utility.setNotification(
        `${createJiraActivity?.title} Started`,
        "You should be able to see your activity timer",
        "success",
        "topRight"
      );

      getCurrentActivity();
    },
    onError: (error) => {
      onRejected();
    },
  });

  const [pauseActivity, { loading: pausing }] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: ({ pauseActivity }) => {
      utility.setNotification(
        `${pauseActivity?.title} has been paused`,
        "",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    update: (cache, { data: { pauseActivity } }) => {
      updateRecentActivities(cache, pauseActivity, "paused");
      updateContinueActivityList(cache, pauseActivity, "paused");
    },
  });

  const [stopActivity, { loading: stopping }] = useMutation(STOP_ACTIVITY, {
    onCompleted: ({ stopActivity }) => {
      utility.setNotification(
        `${stopActivity?.title} stopped`,
        "You should be able to see your activity created",
        "success",
        "topRight"
      );

      /// TO DO UPDATE JIRA TRANSITION
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_PAUSED_ACTIVITY,
      },
    ],

    update: (cache, { data: { stopActivity } }) => {
      updateRecentActivities(cache, stopActivity, "stop");
    },
  });

  const [getCurrentActivity, { loading: getCurrentActivityLoading }] =
    useLazyQuery(GET_CURRENT_ACTIVITY, {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getCurrentActivity }) => {
        if (has(getCurrentActivity, "time.start"))
          setActivity({
            active: true,
            data: getCurrentActivity,
          });
      },
    });

  const [continueActivity, { loading: continuing }] = useMutation(
    CONTINUE_ACTIVITY,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success",
          "Activity has been continued",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        if (
          errorMessage !==
          "Error: There is another activity running. Please stop it before starting a new one"
        )
          setActivity({
            active: false,
            data: null,
          });

        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        {
          query: GET_PAUSED_ACTIVITY,
        },
      ],
    }
  );

  const fetchIssue = () => {
    const data = Promise.all(
      map(values?.settings?.sync, (sync) => {
        return new Promise((resolve, reject) => {
          searchIssues(
            values?.settings?.server_name,
            sync?.jira_project,
            `${sync.column_pull},${sync.column_push_start?.status}`,
            token
          )
            .then((r) => {
              const sprintKey = values?.settings?.sprint_field;
              const groupUp = groupBy(
                r?.data?.issues,
                (e) => e?.fields[sprintKey]?.[0]?.name
              );
              resolve(groupUp);
              // return groupUp;
            })
            .catch((e) => {
              const isJQLError =
                e?.response?.data?.errorMessages &&
                _.includes(
                  e?.response?.data?.errorMessages[0],
                  "Error in JQL Query"
                );
              if (isJQLError) {
                utility.setNotification(
                  "Missing data",
                  "Looks like you have some missing columns.",
                  "error",
                  "topRight"
                );

                setSelected("sync");
              } else {
                return reject();
              }
            });
        });
      })
    );

    data
      .then((e) => {
        const reFormat = flatMap(e, function (value, key) {
          return map(value, (e, k, i) => {
            return e;
          });
        });
        setIssues(reFormat);
        setLoading(false);
      })
      .catch((e) => {
        onRejected();
      });
  };

  useEffect(() => {
    fetchIssue();
  }, [values?.settings, id, searchIssues]);

  const handleCreate = (input, data) => {
    createJiraActivity({ variables: { input } }).then(() => {
      const findSync = find(
        values?.settings?.sync,
        (sync) => sync?.jira_project === data?.fields?.project?.id
      );

      transitionJiraActivity(
        resource,
        input?.jira_ticket,
        findSync?.column_push_start?.transition,
        token
      );
    });
  };

  const handlePause = () => {
    const time = moment.utc();

    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const handleStop = (data) => {
    stopActivity({
      variables: {
        input: {
          activity_id: activity?.data?._id,
          date_end: moment.utc(),
        },
      },
    }).then(() => {
      setActivity((currentActivity) => {
        return { ...currentActivity, active: false };
      });

      const findSync = find(
        values?.settings?.sync,
        (sync) => sync?.jira_project === data?.fields?.project?.id
      );
      transitionJiraActivity(
        resource,
        data?.key,
        findSync?.column_push_finished,
        token
      ).then(() => {
        fetchIssue();
      });
    });
  };

  const handleContinueActivity = (activity) => {
    const pausedCopy = _.cloneDeep(activity?.time?.paused);

    const lastPause = _.last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: { activity_id: activity._id, time: moment.utc(new Date()) },
      },
    });
  };

  const stopContinueActivity = (activity, data) => {
    const {
      _id,
      time: { paused },
    } = activity[0];
    stopActivity({
      variables: {
        input: {
          activity_id: _id,
          date_end: _.last(paused)?.time,
        },
      },
    }).then(() => {
      setActivity((currentActivity) => {
        return { ...currentActivity, active: false };
      });

      const findSync = find(
        values?.settings?.sync,
        (sync) => sync?.jira_project === data?.fields?.project?.id
      );
      transitionJiraActivity(
        resource,
        data?.key,
        findSync?.column_push_finished,
        token
      ).then(() => {
        fetchIssue();
      });
    });
  };

  if (loading) return <Loader size="large" />;
  if (isEmpty(issues)) return <EmptyData />;
  return (
    <section
      style={{
        maxHeight: enlargeModal.visible ? "auto" : "375px",
        overflow: "auto",
      }}
      className={styles.hiddenScroll}
    >
      <Collapse
        defaultActiveKey={activeCollapse}
        expandIconPosition={"right"}
        onChange={(e) => setActiveCollapse(e)}
        bordered={false}
      >
        {issues?.map((sprintIssues, i) => {
          const sprintInfo =
            sprintIssues[0]?.fields[values?.settings?.sprint_field][0];
          const project = sprintIssues[0]?.fields?.project;
          const status = sprintIssues[0]?.fields?.status;
          return (
            <Panel
              key={i}
              header={
                <>
                  <div
                    style={{ flexDirection: "column-reverse" }}
                    className="flex items-center space-between"
                  >
                    <div
                      style={{ width: "100%" }}
                      className="flex items-center"
                    >
                      <h4 className={styles.projectTitle}>
                        {sprintInfo?.name}
                      </h4>
                      <Tag className="ml-3" color="default">
                        <Text className={styles.accordionSprint}>
                          {project?.name}
                        </Text>
                      </Tag>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Text
                        className={styles.daysLeft}
                        style={{
                          color: sprintInfo?.daysLeft > 3 ? "" : "#FD4A31",
                        }}
                      >
                        {moment(sprintInfo?.endDate).diff(moment(), "days")}{" "}
                        Days Left
                      </Text>
                    </div>
                  </div>
                </>
              }
            >
              <Row gutter={[12, 12]}>
                {sprintIssues?.map((ticket, i) => {
                  const isInPaused = filter(pausedActivity, (paused) => {
                    return paused.meta === ticket?.key;
                  });
                  return (
                    <Col className="gutter-row my-2" span={12} key={i}>
                      <JiraTicket
                        onStop={handleStop}
                        onPause={handlePause}
                        onCreate={handleCreate}
                        user={user}
                        onRejected={onRejected}
                        data={ticket}
                        activity={activity}
                        serverUrl={values?.settings?.server_url}
                        isInPaused={isInPaused}
                        handleContinueActivity={handleContinueActivity}
                        stopContinueActivity={stopContinueActivity}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Panel>
          );
        })}
      </Collapse>
    </section>
  );
};
export default ServerSettingView;
