import gql from "graphql-tag";

export const UPDATE_MIRO = gql`
  mutation ($input: updateMiroInput) {
    updateMiro(input: $input) {
      user {
        email
      }
      url
    }
  }
`;
