import { CloudDownloadOutlined } from "@ant-design/icons";
import { CaretRight, MicrosoftExcelLogo } from "@phosphor-icons/react";
import { Button, Col, Row, Select, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import _ from "lodash";

import WinduLogo from "common/assets/W.svg";
import ExcelLogo from "common/assets/excel.svg";
import { useRecoilValue } from "recoil";
import { workspacesList } from "recoil/atoms/Workspaces";
import styles from "./styles.module.css";
import { SEND_MULTIPLE_TO_BACKLOG } from "graphql/mutations/Import/sendMultipleToBacklog";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import CreateProjectModal from "components/Modals/CreateProjectModal";

const { Title, Text } = Typography;

const getDetails = (details) => {
  const content = {
    blocks: [
      {
        key: "637gr",
        text: details,
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };
  return JSON.stringify(content);
};

const getPriority = (priority) => {
  const lowerCasePriority = priority.toLowerCase();

  if (["very low", "low", "less"].includes(lowerCasePriority)) {
    return 1;
  } else if (
    ["med", "medium", "moderate", "normal"].includes(lowerCasePriority)
  ) {
    return 2;
  } else if (["high", "very high", "urgent"].includes(lowerCasePriority)) {
    return 3;
  } else {
    return 0;
  }
};
const PreviewItems = ({
  importedData,
  defualtImportData,
  setImportedData,
  refetch,
}) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const workspaceData = useRecoilValue(workspacesList);
  const [sendToBacklogMutation, { loading: sendToBacklogLoading }] =
    useMutation(SEND_MULTIPLE_TO_BACKLOG, {
      onCompleted: () => {
        refetch();
        setImportedData(defualtImportData);
        utility.setNotification(
          "Success",
          `Activities imported successfully.`,
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    });

  const columns = Object.keys(importedData.fields).map((field) => ({
    title: _.capitalize(field),
    dataIndex: field,
    key: field,
    width: 150,
    render: (text) => {
      if (field === "tags") {
        return _.map(text, (tag) => <Tag>{tag}</Tag>);
      } else {
        return _.truncate(text, {
          length: 80,
          omission: "...",
        });
      }
    },
  }));

  const mappedData = [];
  const brokenData = [];

  importedData.data.forEach((rowData) => {
    const mappedRow = {};
    let isValid = true;

    Object.keys(importedData.fields).forEach((field) => {
      if (Array.isArray(importedData.fields[field])) {
        // For fields with array values (e.g., tags, tasks)
        const subFieldValues = importedData.fields[field].map(
          (subField) => rowData[subField]
        );
        const nonEmptySubFieldValues = subFieldValues.filter(Boolean);
        if (nonEmptySubFieldValues.length > 0) {
          mappedRow[field] = nonEmptySubFieldValues;
        }
      } else {
        const fieldValue = rowData[importedData.fields[field]];
        if (fieldValue !== undefined && fieldValue !== null) {
          mappedRow[field] = fieldValue;
        } else {
          // If title or details is null or empty, mark the data as invalid
          if (field === "title" || field === "details") {
            isValid = false;
          }
        }
      }
    });

    if (isValid && mappedRow.title && mappedRow.details) {
      mappedData.push(mappedRow);
    } else {
      brokenData.push(rowData);
    }
  });

  const handleImporting = () => {
    const getFormattedData = _.map(mappedData, (item) => {
      return {
        title: item.title,
        details: getDetails(item.details),
        tags: item.tags,
        priority: item.priority ? getPriority(item.priority) : 0,
        tasks: item.tasks,
      };
    });
    const payload = {
      items: getFormattedData,
      workspace: selectedWorkspace,
      fileName: importedData.fileName,
      source: "excel",
      totalItems: importedData.data.length,
      totalImportedItems: getFormattedData.length,
    };
    sendToBacklogMutation({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };
  return (
    <div>
      <div>
        <div className="w-1/2 ml-auto flex items-center justify-between ">
          <div className="flex items-center gap-1 justify-center">
            <img src={ExcelLogo} alt="Windu Import" />
            <CaretRight size={24} color="#1a1a1a" />
            <img src={WinduLogo} alt="Windu logo" />
          </div>

          <div>
            <Button
              size="large"
              type="primary"
              onClick={() => setCreateProjectModal(true)}
            >
              New Project
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-1 mt-1 items-center">
          <Text className="text-lg" strong>
            Here's a preview of your {importedData.fileName}
          </Text>
          <Text className="text-base">
            We'll create an activity in your Windu project for each row below
          </Text>

          <div className="flex items-center mt-2">
            <Select
              className="w-full"
              size="large"
              placeholder="Select Backlog"
              showSearch
              onSelect={(v) => setSelectedWorkspace(v)}
              options={_.map(workspaceData, (item) => {
                return {
                  label: item?.project?.title,
                  value: item?._id,
                };
              })}
            />
            <div className="flex items-center ml-2 ">
              <Button
                type="primary"
                size="large"
                icon={<CloudDownloadOutlined />}
                className={`${styles.blackButton} text-white rounded-lg outline-none border-none w-44`}
                onClick={handleImporting}
                disabled={_.isEmpty(selectedWorkspace)}
                style={{ color: "white" }}
                loading={sendToBacklogLoading}
              >
                Start Importing
              </Button>
              <Button
                danger
                size="large"
                className="ml-2 rounded-lg"
                onClick={() => setImportedData(defualtImportData)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Table
          dataSource={mappedData.slice(0, 8)}
          columns={columns}
          pagination={false}
        />
      </div>

      {createProjectModal && (
        <CreateProjectModal
          isVisible={createProjectModal}
          onClose={() => setCreateProjectModal(false)}
        />
      )}
    </div>
  );
};

export default PreviewItems;
