import React, { useState, cloneElement, Children, useRef } from "react";
import { useSubscription, useApolloClient } from "@apollo/client";
import { ConfigProvider, Layout } from "antd";
import { withRouter } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import queryString from "query-string";
import TopBar from "components/TopBar";
import { instanceOf } from "prop-types";
import { userSession } from "recoil/atoms/User/UserSession";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import { WORKSPACE_CHANGE } from "graphql/subscription/workspace/workspaceChange";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";
import EmptyData from "components/EmptyData";
import MaintenacePage from "views/maintenance";
import { useFeature } from "@optimizely/react-sdk";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import WorkspaceTabs from "components/WorkspaceTabs";

const { Content } = Layout;

const MainLayout = ({ children, location, history }) => {
  const [collapsed, setCollapsed] = useState(false);
  const user = useRecoilValue(userSession);
  const setChatSelected = useSetRecoilState(chatSelectedState);
  const client = useApolloClient();
  const widgetContainerDivRef = useRef(null);
  const stepIndex = useRecoilValue(tourSteps);
  const { data: workspaceData, error: workspaceError } =
    useSubscription(WORKSPACE_CHANGE);

  const { pathname, search } = location;
  const queryParams = queryString.parse(search);

  const hasPadding = ["/", "/workspace"].includes(pathname) ? "p-0" : "p-4";

  const updateWorkspaceList = async () => {
    const { workspaceChange } = workspaceData;

    const { action, workspaceId } = workspaceChange;

    if (action === "Remove") {
      const { id = "" } = queryParams;
      if (pathname === "/workspace" && id === workspaceId) {
        history.push("/");
      }
    }

    await client.refetchQueries({
      include: [GET_WORKSPACES],
    });
  };

  const handleScrollTop = () => {
    widgetContainerDivRef.current.scrollTop = 0;
  };

  const handleScrollBottom = () => {
    widgetContainerDivRef.current.scrollTop =
      widgetContainerDivRef.current.scrollHeight;
  };

  React.useEffect(() => {
    if (stepIndex.step == 13) {
      setTimeout(() => {
        handleScrollTop();
      }, 500);
    }
    if (stepIndex.step == 9) {
      setTimeout(() => {
        handleScrollBottom();
      }, 500);
    }
  }, [stepIndex.step]);

  React.useEffect(() => {
    if (workspaceError) console.warn(workspaceError);
    if (workspaceData) updateWorkspaceList();
  }, [workspaceData, workspaceError]);

  React.useEffect(() => {
    if (pathname !== "/messages") setChatSelected({});
  }, [pathname]);

  const [isEnabled, maintenance] = useFeature(
    "maintenance",
    { autoUpdate: true },
    {
      /* (Optional) User overrides */
    }
  );
  const isNotAuthorizePath = pathname !== "/authorize";

  return (
    <ConfigProvider renderEmpty={() => <EmptyData />}>
      {!maintenance?.on ? (
        <Layout className="h-screen">
          {isNotAuthorizePath && !["/quickstart"].includes(pathname) && (
            <>
              <TopBar user={user} currentView={pathname} query={queryParams} />
              <WorkspaceTabs />
            </>
          )}
          <Content
            className={`${hasPadding} overflow-x-hidden overflow-y-auto`}
            ref={widgetContainerDivRef}
          >
            <div className="h-full site-layout-background">
              {Children.map(children, (child) => {
                return cloneElement(child, { user });
              })}
            </div>
          </Content>
        </Layout>
      ) : (
        <MaintenacePage user={user} />
      )}
    </ConfigProvider>
  );
};

MainLayout.propTypes = {
  children: instanceOf(Object),
  location: instanceOf(Object),
};

export default withRouter(MainLayout);
