import React, { useState } from "react";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Alert } from "antd";
import queryString from "query-string";
import GoogleLoginBtn from "./GoogleLogin";
import { withApollo } from "@apollo/client/react/hoc";
import { useMutation } from "@apollo/client";
import { LOGIN_WITH_GOOGLE } from "graphql/mutations/user/loginWithGoogle";
import { Typography, Divider } from "antd";
import { Formik, Field, Form } from "formik";
import { LOGIN } from "graphql/mutations/user/login";
import { validationLoginSchema } from "./validationSchema";
import { RESEND_VERIFICATION_MAIL } from "graphql/mutations/user/resendVerificationMail";
import utility from "common/utility";
import { get } from "lodash";
import { createGoogleEvent } from "api/GoogleAnalytics";
import FloaterInput from "components/FloaterInput";
import styles from "./styles.module.css";
import Error from "components/Error";

const { Text, Title } = Typography;

const initialFormValues = {
  email: "",
  password: "",
};
const Login = ({ figma, setView }) => {
  const location = useLocation();
  const history = useHistory();
  const { redir, app, state, code } = queryString.parse(location.search);
  const [isMailSent, setisMailSent] = useState(false);
  const confirm = queryString.parse(location?.search).confirm;
  const [isVerificationPending, setisVerificationPending] = useState(false);
  const [resendMail, setResendMail] = useState();
  const [error, setError] = useState({ show: false, message: "" });

  const [info] = useState({
    show: confirm,
    message:
      "You have an email confirmation pending. Please confirm to be part of Windu!",
  });
  const form = React.useRef();

  const handleError = (error) => {
    const { message } = error;
    setError({ show: true, message });
    utility.setNotification(
      "Something wrong happened",
      message,
      "error",
      "topRight"
    );
  };

  const [googleLogin, { loading: loadingGoogle }] = useMutation(
    LOGIN_WITH_GOOGLE,
    {
      onError: (error) => handleError(error),
      onCompleted: ({ googleLogin }) => {
        Cookies.set("token", googleLogin.token, { expires: 7 }); // expires 7 days from now

        if (googleLogin?.new_user) {
          createGoogleEvent(null, "REGISTER_USER", "app_engagement");
          history.replace("/?withTour=true");
        } else {
          createGoogleEvent(
            googleLogin.user?._id,
            "LOGIN_USER",
            "app_engagement"
          );

          if (redir) {
            history.push(`${redir}?app=${app}&state=${state}&code=${code}`);
          } else {
            history.push("/");
          }
        }
      },
    }
  );
  const [login, { loading }] = useMutation(LOGIN, {
    onError: (error) => {
      if (error?.message === "You have an email confirmation pending.") {
        setisVerificationPending(true);
      } else {
        setisVerificationPending(false);
      }
      handleError(error);
    },
    onCompleted: (data) => {
      const {
        login: { token, user },
      } = data;

      Cookies.set("token", token, { expires: 1 }); // expires 7 days from now

      createGoogleEvent(user?._id, "LOGIN_USER", "app_engagement");

      history.replace("/");
    },
  });

  const onSubmit = () => {
    form.current.submitForm();
  };

  const onLogin = (values) => {
    const { email, password } = values;
    setResendMail(email);
    login({
      variables: {
        input: {
          email,
          password,
          platform: "web",
        },
      },
    });
  };

  const handleGoogleLogin = ({ tokenObj, profileObj }) => {
    const { email, name, imageUrl } = profileObj;
    googleLogin({
      variables: {
        input: {
          email,
          name,
          avatar: imageUrl,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          platform: "web",
        },
      },
    });
  };

  const [resendVerificationMail, { loading: resendMailLoading }] = useMutation(
    RESEND_VERIFICATION_MAIL,
    {
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: (data) => {
        setisMailSent(true);
        setTimeout(() => {
          setisMailSent(false);
        }, 30000);
        utility.setNotification(
          "Verification email sent successfully",
          "Please check your email",
          "success",
          "topRight"
        );
      },
    }
  );

  const resendMailHandler = () => {
    resendVerificationMail({ variables: { email: resendMail } });
  };

  return (
    <>
      <div
        className="px-12 text-center w-3/4 m-auto flex flex-col justify-between"
        style={{ marginTop: "20%", maxWidth: 500, height: "80vh" }}
      >
        <div>
          <div className="mb-6">
            <Title level={2} style={{ marginBottom: 0 }}>
              Welcome Back
            </Title>
            <Text className="text-base -mt-2 ">Login!</Text>
          </div>
          {info.show && (
            <Alert showIcon message={info.message} type="info" closable />
          )}

          {error.show && (
            <>
              <Error
                message={error.message}
                onClose={() => setError({ show: false, message: "" })}
              />
              {isVerificationPending && (
                <div className="flex justify-end">
                  <Button
                    type="text"
                    style={{ color: "#1890ff" }}
                    onClick={resendMailHandler}
                    disabled={isMailSent}
                    loading={resendMailLoading}
                  >
                    Resend Invitation
                  </Button>
                </div>
              )}
            </>
          )}
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationLoginSchema}
            onSubmit={onLogin}
            innerRef={form}
          >
            {({ submitCount, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <Field
                      component={FloaterInput}
                      name="email"
                      label="Work or SSO email"
                      placeholder="Work or SSO email"
                      submitCount={submitCount}
                      hasFeedback
                      required={true}
                      size="large"
                    />
                    <Field
                      component={FloaterInput}
                      name="password"
                      label="Password"
                      placeholder="Your secret password"
                      submitCount={submitCount}
                      hasFeedback
                      required={true}
                      size="large"
                      inputType="password"
                    />
                  </div>
                  <div className="flex justify-end">
                    <Button type="text" onClick={() => setView("forgot")}>
                      <Text style={{ color: "#F5A623" }}>Forgot password</Text>
                    </Button>
                  </div>

                  <Button
                    size="large"
                    onClick={onSubmit}
                    key="submit"
                    className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
                    type="primary"
                    loading={loading || loadingGoogle}
                  >
                    Login
                  </Button>

                  <Divider>
                    <Text style={{ color: "#64748B" }}>OR</Text>
                  </Divider>

                  <GoogleLoginBtn figma={figma} onSuccess={handleGoogleLogin} />
                  <div className="flex text-center items-center justify-center my-10">
                    <Text>New to Windu? </Text>
                    <Button
                      type="text"
                      className="p-0 m-0 ml-1.5"
                      onClick={() => setView("register")}
                    >
                      <Text style={{ color: "#F5A623" }} className="underline">
                        Register here
                      </Text>
                    </Button>
                  </div>
                  <div className="my-4 text-center">
                    <Text>
                      By Logging in, I agree to Windu's
                      <a
                        rel="noreferrer"
                        href="https://www.windu.io/privacy-policy/"
                        target="_blank"
                      >
                        &nbsp;
                        <Text className="text-black underline">
                          Privacy Policy
                        </Text>
                        &nbsp;
                      </a>
                      and
                      <br />
                      <a
                        rel="noreferrer"
                        href="https://www.windu.io/terms-service/"
                        target="_blank"
                      >
                        &nbsp;
                        <Text className="text-black underline">
                          Terms of Service
                        </Text>
                        &nbsp;
                      </a>
                    </Text>
                  </div>
                  {/* {!figma && (
                  <>
                    <Divider />
                    <div className="mt-4 text-center">
                      <Text
                        onClick={() => setShowSubmissionForm(true)}
                        className="cursor-pointer"
                        underline
                      >
                        Reset Password
                      </Text>
                    </div>
                  </>
                )} */}
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className="text-center">
          <Text>Copyright © Windu 2023 | All rights reserved</Text>
        </div>
      </div>
    </>
  );
};

export default withApollo(Login);
