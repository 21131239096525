import React from "react";

import { MinusSquareTwoTone } from "@ant-design/icons";

const RemoveSync = ({ onClick }) => (
  <MinusSquareTwoTone
    style={{ fontSize: 30, cursor: "pointer" }}
    twoToneColor={"#644ACB"}
    onClick={onClick}
  />
);

export default RemoveSync;
