import gql from "graphql-tag";

export const SEND_REMINDER = gql`
  mutation sendRateReminder($input: sendRateReminderInput) {
    sendRateReminder(input: $input) {
      _id
      email
    }
  }
`;
