import React from "react";
import { Drawer, Typography, Button, Select, Form } from "antd";
import ClientSelect from "components/ClientSelect";
import ManagerSelect from "components/ManagerSelect";

const { Title } = Typography;
const { Option } = Select;

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Archived", value: "Archived" },
];

const sortOptions = [
  { label: "Last updated", value: `{"updated_at": -1}` },
  { label: "Oldest updated", value: `{"updated_at": 1}` },
  { label: "Last created", value: `{"created_at": -1}` },
  { label: "Oldest created", value: `{"created_at": 1}` },
];

const relationshipOptions = [
  { label: "All", value: "" },
  { label: "Created by me", value: "Created by me" },
  { label: "Invited", value: "Invited" },
];

const ProjectFilters = ({
  onClose,
  visible,
  projectFilters,
  setProjectFilters,
  onApplyFilters,
  onResetFilters,
}) => {
  const [form] = Form.useForm();
  const { status, relationship, sort, client, manager } = projectFilters;

  return (
    <Drawer
      title={<Title level={4}>Project filters</Title>}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={400}
      footer={
        <div className="text-center">
          <Button
            onClick={() => onResetFilters(form)}
            size="large"
            className="w-32 mr-3"
          >
            Reset
          </Button>
          <Button
            onClick={() => onApplyFilters()}
            type="primary"
            className="w-32"
            size="large"
          >
            Apply
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        initialValues={{
          status: "",
          sort: `{"updated_at": -1}`,
          relationship: "",
        }}
      >
        <Form.Item label="Status" labelCol={{ span: 24 }} name="status">
          <Select
            className="w-full"
            size="large"
            onChange={(value) =>
              setProjectFilters({ ...projectFilters, status: value })
            }
            value={status}
          >
            {statusOptions.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Sort" labelCol={{ span: 24 }} name="sort">
          <Select
            className="w-full"
            size="large"
            onChange={(value) =>
              setProjectFilters({ ...projectFilters, sort: value })
            }
            value={sort}
          >
            {sortOptions.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Relationship"
          labelCol={{ span: 24 }}
          name="relationship"
        >
          <Select
            className="w-full"
            size="large"
            onChange={(value) =>
              setProjectFilters({ ...projectFilters, relationship: value })
            }
            value={relationship}
          >
            {relationshipOptions.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Client" labelCol={{ span: 24 }} name="client">
          <ClientSelect
            onChange={(value) => {
              setProjectFilters({ ...projectFilters, client: value });
            }}
            value={client}
            placeholder={"Filter by client"}
          />
        </Form.Item>
        <Form.Item label="Manager" labelCol={{ span: 24 }} name="manager">
          <ManagerSelect
            onChange={(value) => {
              console.log(value);
              setProjectFilters({ ...projectFilters, manager: value });
            }}
            value={manager}
            placeholder={"Filter by manager"}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ProjectFilters;
