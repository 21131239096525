import React, { useState } from "react";
import { Pagination, Typography, Col, Row, Input } from "antd";
import { useQuery } from "@apollo/client";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { GET_MANAGERS } from "graphql/queries/manager/getManagers";
import { array, func, bool } from "prop-types";
import { debounce } from "lodash";
import ManagerTable from "components/ManagerTable";

const { Title } = Typography;
const sizePerPage = 5;
const initialOffset = 0;
const initialSearch = "";

const Managers = () => {
  const history = useHistory();

  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
  });

  const { loading: managerLoading, data } = useQuery(GET_MANAGERS, {
    fetchPolicy: "cache-and-network",
    variables: { input: queryParameters },
  });


  const delayedQuery = React.useCallback(
    debounce((query) => {
      onSearch(query);
    }, 1000),
    []
  );

  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  return (
    <>
      <Row className="mb-6">
        <Col xs={20}>
          <Title level={4}>Managers List</Title>
        </Col>
      </Row>
      <Row className="mb-6">
        <Col lg={12} xs={12}>
          <Input.Search
            className="w-4/6"
            placeholder="Search by name or email"
            size="large"
            allowClear
            onChange={({ target }) => delayedQuery(target.value)}
            onSearch={(value) => onSearch(value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <ManagerTable
            managers={data?.getManagers?.data}
            loading={managerLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {!managerLoading && (
            <Pagination
              className="mt-3 text-center"
              current={data?.getManagers?.pagination?.current_page}
              onChange={onPageChange}
              total={data?.getManagers?.pagination?.total_data || 0}
              pageSize={queryParameters.size}
              showSizeChanger
              pageSizeOptions={["5", "10", "20", "50", "100"]}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};
Managers.propTypes = {
  projects: array,
  setProjectId: func,
  onRemove: func,
  setIsInviteMemberVisible: func,
  loading: bool,
};
export default Managers;
