import React, { useState, useEffect } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { Divider, Button } from "antd";
import _, { map } from "lodash";
import { AntSelect } from "components/FormikCustomInputs";
import RemoveSync from "../common/RemoveSync";
import AddSync from "../common/AddSync";
import { getRepos } from "api/Github";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { BellOutlined } from "@ant-design/icons";
import utility from "common/utility";

const Settings = ({ token, repos, githubUser }) => {
  const { values, setFieldValue, submitCount } = useFormikContext();
  const [repository, setRepository] = useState([]);
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  useEffect(() => {
    getRepos(id, token).then((res) => {
      const data = map(res?.data?.data?.viewer?.repositories?.nodes, (e) => {
        return {
          label: e?.nameWithOwner,
          value: e?.nameWithOwner,
        };
      });
      setRepository(data);
    });
  }, [getRepos]);

  useEffect(() => {
    setFieldValue("repos", repos);
  }, [repos, setFieldValue]);

  return (
    <>
      <FieldArray
        className="m-0"
        name="repos"
        render={(arrayHelpers) => {
          const repos = values.repos;
          return (
            <div>
              {repos && repos.length > 0 ? (
                repos.map((e, index) => (
                  <div className="flex items-center" key={index}>
                    <div>
                      {e.notification ? (
                        <div
                          className="p-2 mt-4 cursor-pointer"
                          style={{ background: "#FCF5DE" }}
                          onClick={() =>
                            setFieldValue(`repos.${index}.notification`, false)
                          }
                        >
                          <BellOutlined
                            style={{ fontSize: 20, color: "#F3A536" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="bg-gray-50  p-2 mt-4 cursor-pointer"
                          onClick={() => {
                            if (!_.includes(e.repoName, githubUser)) {
                              utility.setNotification(
                                "Something wrong happened",
                                "Only repository owner can allow notification",
                                "error",
                                "topRight"
                              );
                            }
                            setFieldValue(`repos.${index}.notification`, true);
                          }}
                        >
                          <BellOutlined
                            style={{ fontSize: 20, color: "#AEAEAE" }}
                          />
                        </div>
                      )}
                    </div>
                    <Field
                      size={"large"}
                      className="m-0"
                      component={AntSelect}
                      name={`repos.${index}.repoName`}
                      label="Select a Repository"
                      placeholder="Select a Repository"
                      selectOptions={repository}
                      required={true}
                      showSearch
                      inputType="select"
                    />

                    <Button
                      style={{
                        fontSize: 22,
                        marginTop: "15px",
                        color: "#644ACB",
                      }}
                      icon={<RemoveSync />}
                      type="link"
                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                    ></Button>
                  </div>
                ))
              ) : (
                <div className="flex items-center">
                  <Field
                    size={"large"}
                    className="m-0"
                    component={AntSelect}
                    name={`repos[0].repoName`}
                    label="Select a Repository"
                    placeholder="Select a Repository"
                    selectOptions={repository}
                    required={true}
                    showSearch
                    inputType="select"
                  />
                </div>
              )}
              <Button
                className="mt-3"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({ repoName: "", notification: false })
                } // insert an empty string at a position
              >
                Add Sync
              </Button>
            </div>
          );
        }}
      />
    </>
  );
};
export default Settings;
