import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import utility from "common/utility";
import { get, isNil } from "lodash";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { GET_MIRO } from "graphql/queries/Miro/getMiro";
import { UPDATE_MIRO } from "graphql/mutations/Miro/updateMiro";
import Card from "../Card";
import Board from "./Board";
import miroLogo from "../../../common/assets/miro.png";
import Auth from "./Auth";
import { useAuthWidgets } from "../common/useAuthWidgets";

import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import { useRecoilState } from "recoil";
import { setWorkspaceIntegration } from "../utils";

const Miro = () => {
  const [selected, setSelected] = useState("board");
  const [authenticated, setAuthenticated] = useState(false);
  const [url, setUrl] = useState(null);
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  const [updateMiro] = useMutation(UPDATE_MIRO, {});
  useAuthWidgets(authenticated, "MIRO", id);

  const {
    getMiro,
    loading: loadingGetMiro,
    refetch,
  } = useQuery(GET_MIRO, {
    variables: { input: { workspace: id } },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getMiro }) => {
      if (!isNil(getMiro)) {
        setUrl(getMiro?.url);
        setAuthenticated(true);
        const payload = {
          miro: JSON.stringify({
            access_token: getMiro?.url,
          }),
        };
        setWorkspaceIntegration(id, payload);
      }
    },

    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  /// getMiro

  useEffect(() => {
    refetch({ variables: { input: { workspace: id } } });
  }, [id]);

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const saveSync = (values) => {
    setSelected("view");
  };
  const handleConnect = (d) => {
    updateMiro({ variables: { input: { url: d?.id, workspace: id } } }).then(
      ({ data }) => {
        setUrl(data?.updateMiro?.url);
      }
    );
    setAuthenticated(true);
  };

  const handleChangeBoard = () => {
    window.miroBoardsPicker.open({
      clientId: process.env.REACT_APP_MIRO_CLIENT,
      action: "select",
      success: function (d) {
        updateMiro({
          variables: { input: { url: d?.id, workspace: id } },
        }).then(({ data }) => {
          setUrl(data?.updateMiro?.url);
        });
      },
    });
  };

  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "miro" });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="view">
        <a>View</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handleChangeBoard}>Change Board</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7555219722263-Miro"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );

  const bodyHeight = selected === "settings" ? "70%" : "83%";

  return authenticated ? (
    <Card
      bodyStyle={{
        height: enlargeModal.visible ? "94% " : bodyHeight,
        overflow: "auto",
      }}
      style={{ overflow: "auto", height: enlargeModal.visible ? "94vh" : 450 }}
      isEnlarge={!enlargeModal.visible}
      enlargeAction={enlargeAction}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ width: 25 }}
            className="mr-3"
            src={miroLogo}
            alt="Miro"
          />
          <h5>Miro</h5>
        </div>
      }
      action={[
        selected === "settings" && (
          <div className="text-right pr-3">
            <Button
              onClick={saveSync}
              type="default"
              style={{ background: "#004DC1", color: "white" }}
            >
              Save
            </Button>
          </div>
        ),
      ]}
      menu={menu}
    >
      <Board id={url} view={selected} setSelected={setSelected} />
    </Card>
  ) : (
    <Auth
      onConnect={handleConnect}
      setAuthenticated={setAuthenticated}
      isAuthenticated={authenticated}
    />
  );
};

export default Miro;
