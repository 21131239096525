import { Menu } from "antd";
import React from "react";
import Icon from "@ant-design/icons";
import Icons from "common/icons";
import moment from "moment";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTimer } from "contexts/timer-context";
import { last, cloneDeep, get } from "lodash";
import utility from "common/utility";
import { CONTINUE_ACTIVITY } from "graphql/mutations/member/continueActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { useMutation, useApolloClient } from "@apollo/client";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";

const ContinueActivityAction = ({ activity, setContinueWtihTimeboxModal }) => {
  const client = useApolloClient();
  const user = useRecoilValue(userSession);
  const setActivity = useSetRecoilState(activeActivity);
  const setRefetchIndicator = useSetRecoilState(refetchIndicator);
  const { startHandler } = useTimer(); // global startHandler from context
  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true };
    });
  };

  const [continueActivity] = useMutation(CONTINUE_ACTIVITY, {
    onCompleted: async ({ continueActivity }) => {
      handleRefetchingViews();
      utility.setNotification(
        "Success",
        "Activity has been continued",
        "success",
        "topRight"
      );

      setActivity({
        active: true,
        data: continueActivity,
      });

      if (stepIndex?.step == 6) {
        setStepIndex({ step: stepIndex.step + 1, isAcitve: true });
      }

      await client.refetchQueries({
        include: [GET_PAUSED_ACTIVITY],
      });
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      if (
        errorMessage !==
        "Error: There is another activity running. Please stop it before starting a new one"
      )
        setActivity({
          active: false,
          data: null,
        });

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const continueActivityFunction = (activity) => {
    const pausedCopy = cloneDeep(activity?.time?.paused);

    const lastPause = last(pausedCopy);
    const continueAt = moment.utc(new Date());
    const newPause = {
      ...lastPause,
      continue: continueAt.format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    pausedCopy[pausedCopy?.length - 1] = newPause;

    const activityBeContinued = {
      ...activity,
      time: { ...activity.time, paused: pausedCopy },
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
    };

    setActivity({
      active: true,
      data: activityBeContinued,
    });

    startHandler(continueAt);

    continueActivity({
      variables: {
        input: { activity_id: activity._id, time: moment.utc(new Date()) },
      },
    });
  };

  const handleContinueActivity = (activity) => {
    if (stepIndex?.step == 6) {
      continueActivityFunction(activity);
    } else {
      setContinueWtihTimeboxModal(activity);
    }
  };

  return (
    <div className="tour-contineuActivityMenu">
      <Menu.Item
        onClick={() => handleContinueActivity(activity)}
        className="py-4 flex items-center "
        icon={<Icon component={Icons.Continue} className="icon-medium mr-4" />}
        key="continue"
      >
        Continue
      </Menu.Item>
    </div>
  );
};

export default ContinueActivityAction;
