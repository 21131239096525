import { CalendarX } from "@phosphor-icons/react";
import { Button, Modal, Typography } from "antd";
import React from "react";

const RemovePastEvents = ({ isVisible, setVisible, handleContinue }) => {
  return (
    <Modal
      visible={isVisible}
      title=""
      width={400}
      style={{ minHeight: 400 }}
      closable
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="flex flex-col items-center justify-between h-72">
        <div className="bg-green-400 w-10 h-10 rounded-full flex items-center justify-center mt-4">
          <CalendarX size={24} color="#312b2b" />
        </div>

        <Typography.Text className="text-lg" strong>
          This activity was already scheduled and is now past its due date. If
          you proceed, we will discard the overdue activities.
        </Typography.Text>

        <div className="w-full">
          <Button
            className="w-full"
            type="primary"
            size="large"
            onClick={handleContinue}
          >
            Continue
          </Button>
          <Button
            className="w-full mt-4"
            size="large"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemovePastEvents;
