import gql from "graphql-tag";

export const GET_PROJECT_ACTIVITIES = gql`
  query getProjectActivities($input: getActivitiesInfo) {
    getProjectActivities(input: $input) {
      data {
        _id
        title
        description
        details
        created_at
        status
        activity_type
        tags
        key
        content
        timebox
        fileSources
        project {
          _id
          title
          color
        }
        handoff {
          _id
          handoff_at
          user {
            email
            avatar
            total_activities
            _id
          }
          time {
            total_time
            start
            end
          }
        }
        time {
          total_time
          start
          end
          paused {
            time
            continue
          }
        }
        created_by {
          email
          full_name
          avatar
          rates {
            currency
            rate
          }
        }
        project {
          _id
        }
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
