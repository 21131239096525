import React from "react";
import { DatePicker, Drawer, Switch, Form } from "antd";
import { showPaused } from "recoil/atoms/Timeline/ShowPaused";
import { showStop } from "recoil/atoms/Timeline/ShowStop";
import { useRecoilState } from "recoil";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import moment from "moment";
import MemberSelect from "components/MemberSelect";
import ProjectSelect from "components/ProjectSelect";
import { timelineDrawer } from "recoil/atoms/Timeline/QueryParams";
import queryString from "query-string";

const TimeLineFilters = ({ history, location }) => {
  const [showPausedState, setShowPausedState] = useRecoilState(showPaused);
  const [showStopState, setShowStopState] = useRecoilState(showStop);

  const [showTimelineFilters, setShowTimelineFilters] =
    useRecoilState(timelineDrawer);

  const {
    start_date,
    end_date,
    projectId,
    memberId,
    memberName,
    projectLabel,
    projectColor,
  } = queryString.parse(location.search);

  const query = {
    start_date,
    end_date,
    projectId,
    memberId,
    memberName,
    projectLabel,
    projectColor,
  };

  const handleSelectedDates = (data) => {
    const startDate = data[0]?.format();

    const endDate = data[1]?.format();
    history.push({
      search: queryString.stringify({
        ...query,
        start_date: startDate,
        end_date: endDate,
      }),
    });
  };
  return (
    <Drawer
      width={400}
      title={
        <>
          {moment(start_date).format("MMM Do YY")} - &nbsp;
          {moment(end_date).format("MMM Do YY")}
        </>
      }
      placement="right"
      closable={false}
      onClose={() => setShowTimelineFilters(false)}
      visible={showTimelineFilters}
      key="timelineFilters"
    >
      <Form>
        <Form.Item label="Date Range" labelCol={{ span: 24 }} name="dateRange">
          <DatePicker.RangePicker
            size="large"
            onChange={handleSelectedDates}
            defaultValue={[
              moment(moment().subtract(1, "month").toDate(), "YYYY/MM/DD"),
              moment(moment(), "YYYY/MM/DD"),
            ]}
            className="w-full"
          />
        </Form.Item>
        <Form.Item label="Project" labelCol={{ span: 24 }} name="projects">
          <ProjectSelect
            placeholder="Filter by project"
            className="w-full"
            onChange={(value, project) =>
              history.push({
                search: queryString.stringify({
                  ...query,
                  projectId: value,
                  projectLabel: project?.label,
                  projectColor: project?.color,
                }),
              })
            }
            onClear={() =>
              history.push({
                search: queryString.stringify({
                  ...query,
                  projectId: undefined,
                }),
              })
            }
            value={projectId}
          />
        </Form.Item>
        <Form.Item label="Members" labelCol={{ span: 24 }} name="members">
          <MemberSelect
            className="w-full"
            onChange={(value, member) =>
              history.push({
                search: queryString.stringify({
                  ...query,
                  memberId: value,
                  memberName: member?.full_name,
                }),
              })
            }
            value={memberId}
          />
        </Form.Item>
      </Form>

      {false && (
        <>
          <Switch
            className="mr-2 ml-3"
            checkedChildren={<div>Hide Pause</div>}
            unCheckedChildren={<div>Show Pause</div>}
            checked={showPausedState?.value}
            onChange={() =>
              setShowPausedState({ value: !showPausedState?.value })
            }
          />
          <Switch
            className="mr-2"
            checkedChildren={<div>Hide Stop</div>}
            unCheckedChildren={<div>Show Stop</div>}
            checked={showStopState?.value}
            onChange={() => setShowStopState({ value: !showStopState?.value })}
          />
        </>
      )}
    </Drawer>
  );
};
export default withRouter(TimeLineFilters);
