import { CloudUploadOutlined } from "@ant-design/icons";
import React from "react";
import Papa from "papaparse";
import _ from "lodash";
import { Upload, Typography, Button } from "antd";
import { CheckCircle } from "@phosphor-icons/react";

const { Dragger } = Upload;
const { Text } = Typography;

const UplocadCsvStep = ({
  importCsvData,
  setImportCsvData,
  files,
  setFiles,
}) => {
  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        // 'result' contains the parsed data
        const parsedData = result.data;
        setImportCsvData(parsedData);
        // Process the data and update your state as needed
      },
      error: (error) => {
        console.error("CSV Parsing Error:", error.message);
        // Provide feedback to the user about the error
      },
      header: true, // Set to true if your CSV has headers
    });
  };

  const uploadProps = {
    fileList: files,
    name: "file",
    multiple: false, // Allow only one file for now
    showUploadList: false,
    accept: ".csv, .tsv, .xlsx",
    beforeUpload: (file) => {
      parseCSV(file);
      return false; // Prevent default upload behavior
    },
    onChange({ fileList }) {
      setFiles(fileList);
    },
  };
  return (
    <div className="w-4/5 m-auto">
      {_.isEmpty(importCsvData) ? (
        <div className="flex flex-col items-center">
          <Typography.Text className="text-base">
            Upload your sheet from{" "}
            <Text className="text-lg" strong>
              Excel/CSV
            </Text>
          </Typography.Text>
          <div className="w-full">
            <Dragger {...uploadProps} className="my-2 p-4 w-full">
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <Typography.Text className="text-base">
                Drag file here or Click to browse
              </Typography.Text>
            </Dragger>
          </div>

          <Typography.Text className="mt-4" strong>
            Note: Update the CSV file with the necessary data while maintaining
            the required column structure and format.
          </Typography.Text>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <CheckCircle size={84} color="#4ec200" />
          <Text className="text-2xl my-2" strong>
            File successfully uploaded!{" "}
          </Text>

          <Text className="text-base">{files[0].name}</Text>

          <Button
            icon={<CloudUploadOutlined />}
            onClick={() => setImportCsvData(null)}
            type="text"
            className="mt-2"
          >
            Upload file again
          </Button>
        </div>
      )}
    </div>
  );
};

export default UplocadCsvStep;
