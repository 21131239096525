import React from "react";
import { useLocation } from "react-router-dom";
import Settings from "./Settings";
import queryString from "query-string";
import { GET_TRELLO } from "graphql/queries/Trello/getTrello";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import utility from "common/utility";
import { useFormikContext } from "formik";
import Board from "./Board";

const View = ({ view, setSelected }) => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const { values, setFieldValue, submitCount } = useFormikContext();

  const { data: trelloData } = useQuery(GET_TRELLO, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    onCompleted: ({ getTrello }) => {
      if (!_.isEmpty(getTrello)) {
        setFieldValue("settings", {
          ...getTrello,
          sync: _.map(getTrello.sync, (item) => {
            return {
              ...item,
              list_pull_clone: item.list_pull,
              list_pull: _.map(item?.list_pull, (item) => item?.id),
            };
          }),
        });
      }
      if (_.isEmpty(getTrello?.sync)) {
        setSelected("settings");
      }
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  return (
    <>
      {view === "settings" && <Settings workspaceId={id} />}
      {view === "board" && <Board workspaceId={id} setSelected={setSelected} />}
    </>
  );
};

export default View;
