import React from "react";
import { Typography } from "antd";

const { Text, Title } = Typography;

const TourContent = ({ title, text }) => {
  return (
    <div>
      <Title className="text-white">{title}</Title>
      <Text className="text-white">{text}</Text>
    </div>
  );
};

export default TourContent;
