import gql from "graphql-tag";

export const GET_MONDAY_COLUMNS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getMondayColumns(input: $input) {
      id
      name
      columns {
        id
        title
        type
        settings_str
      }
    }
  }
`;
