import gql from "graphql-tag";

export const CONNECT_TODOIST = gql`
  mutation ($input: connectTodoistInput) {
    connectTodoist(input: $input) {
      _id
      access_token
    }
  }
`;
