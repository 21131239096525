import gql from "graphql-tag";

export const REMOVE_MESSAGE = gql`
  subscription removeMessage {
    removeMessage {
      _id
      chatId
    }
  }
`;
