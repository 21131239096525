import { Avatar, Divider, Dropdown, Menu, Modal, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SELECTED_INTEGRATION } from "graphql/queries/Integrations/getSelectedIntegration";
import Loader from "components/Loader";
import useCheckIntegrationConnectionHook from "common/checkConnectionHook";
import GetConnectionStatus from "./GetConnectionStatus";
import { REMOVE_INTEGRATION } from "graphql/mutations/Integrations/removeIntegration";
import utility from "common/utility";

const SelectedIntegration = ({ visible, setVisible, refetch }) => {
  const [integrationData, setintegrationData] = useState([]);
  const [isRemoveItem, setisRemoveItem] = useState(false);
  const [getSelectedIntegration, { loading: integrationsLoading, error }] =
    useLazyQuery(GET_SELECTED_INTEGRATION, {
      variables: {
        name:
          _.lowerCase(visible?.label) === "meetings"
            ? "google"
            : _.lowerCase(visible?.label) === "jira cloud"
            ? "Jira Cloud"
            : _.lowerCase(visible?.label),
      },
      onCompleted: ({ getSelectedIntegration }) => {
        setintegrationData(getSelectedIntegration);
        refetch();
        if (_.isEmpty(getSelectedIntegration)) {
          setVisible(false);
        }
      },
    });

  useEffect(() => {
    getSelectedIntegration();
  }, []);

  const [removeIntegration, { loading }] = useMutation(REMOVE_INTEGRATION, {
    onCompleted: () => {
      getSelectedIntegration();
      utility.setNotification(
        "Integration removed",
        "Integration removed",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const dropDownMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => setisRemoveItem(true)}>Remove</Menu.Item>
        <Menu.Item onClick={() => setVisible(false)}>Close</Menu.Item>
      </Menu>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      closable={false}
      footer={null}
      width={500}
      title={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {getIntegrationLogo(_.lowerCase(visible?.label))}
            <Typography.Text className="ml-2 capitalize" strong>
              {visible?.label}
            </Typography.Text>
          </div>

          <div>
            <Dropdown overlay={dropDownMenu} trigger={["click"]} arrow>
              <EllipsisOutlined
                style={{ fontSize: 24 }}
                className="cursor-pointer"
              />
            </Dropdown>
          </div>
        </div>
      }
    >
      {integrationsLoading && <Loader />}
      {!integrationsLoading &&
        integrationData &&
        _.map(integrationData, (item) => {
          return (
            <>
              <div
                className="flex items-center justify-between"
                key={item?._id}
              >
                <div className="flex items-center">
                  <Avatar src={item?.avatar} size={"large"} />
                  <Typography.Text className="ml-2 text-base" strong>
                    {item?.user_name}
                  </Typography.Text>
                </div>
                {isRemoveItem ? (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    className="cursor-pointer"
                    onClick={() =>
                      removeIntegration({
                        variables: { integrationId: item._id },
                      })
                    }
                  >
                    Remove
                  </Tag>
                ) : (
                  <GetConnectionStatus
                    token={item?.access_token}
                    name={visible?.label}
                    integrationData={item}
                    getSelectedIntegration={getSelectedIntegration}
                  />
                )}
              </div>
              <Divider />
            </>
          );
        })}
    </Modal>
  );
};

export default SelectedIntegration;
