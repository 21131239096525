import gql from "graphql-tag";

export const VISITOR_TO_USER = gql`
  mutation ($input: registerInput) {
    visitorToUser(input: $input) {
      _id
      email
      full_name
    }
  }
`;
