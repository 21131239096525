import { Button, Modal, Upload } from "antd";
import React, { useState } from "react";
import _ from "lodash";
import UplocadCsvStep from "./Steps/UplocadCsv";
import MappingColumns from "./Steps/MappingColumns";

const CsvImportModal = ({ isVisible, setIsVisible, setImportedData }) => {
  const [files, setFiles] = React.useState([]);
  const [importCsvData, setImportCsvData] = useState(null);
  const [current, setCurrent] = useState(0);
  const [columnMappings, setColumnMappings] = useState({
    title: null,
    details: null,
    tags: null,
    priority: null,
    tasks: null,
  });

  const handleCancel = () => {
    setIsVisible(false);
    setFiles([]);
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "First",
      content: ({ ...props }) => {
        return <UplocadCsvStep {...props} />;
      },
    },
    {
      title: "Second",
      content: ({ ...props }) => {
        return <MappingColumns {...props} />;
      },
    },
  ];

  const handlePreview = () => {
    setImportedData({
      data: importCsvData,
      fields: columnMappings,
      preview: true,
      fileName: files[0]?.name,
    });
    setIsVisible(false);
  };

  return (
    <Modal
      title={`Import CSV`}
      visible={isVisible}
      width={800}
      onCancel={handleCancel}
      footer={null}
    >
      {steps[current].content({
        importCsvData,
        setImportCsvData,
        files,
        setFiles,
        setColumnMappings,
      })}

      <div className="flex justify-between mt-6 items-end">
        <div className="flex flex-col">
          {current > 0 && <Button onClick={() => prev()}>Back</Button>}
        </div>
        <div>
          {current !== 1 && current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={_.isEmpty(importCsvData)}
            >
              Continue
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              disabled={
                _.isEmpty(columnMappings?.title) ||
                _.isEmpty(columnMappings?.details)
              }
              onClick={() => handlePreview()}
            >
              Preview
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CsvImportModal;
