import gql from "graphql-tag";

export const GET_RECENT_ACTIVITY = gql`
  query {
    getRecentActivity {
      day {
        _id
        title
        description
        details
        created_at
        status
        tags
        key
        content
        fileSources
        timebox
        handoff {
          _id
          handoff_at
          user {
            _id
            email
            avatar
            full_name
            rates {
              currency
              rate
            }
          }
          time {
            total_time
            start
            end
          }
        }
        current_assigned {
          _id
          full_name
          email
        }
        activity_type
        project {
          title
          color
        }
        created_by {
          email
          avatar
          full_name
        }
        time {
          total_time
          start
          end
          created_at
        }
      }
      week {
        _id
        title
        description
        created_at
        status
        tags
        key
        content
        fileSources
        timebox
        handoff {
          _id
          handoff_at
          user {
            _id
            email
            avatar
            full_name
            occupation
            rates {
              currency
              rate
            }
          }
          time {
            total_time
            start
            end
          }
        }
        activity_type
        project {
          title
          color
        }
        created_by {
          email
          avatar
          full_name
        }
        time {
          total_time
          start
          end
          created_at
        }
      }
      month {
        _id
        title
        description
        status
        tags
        key
        content
        fileSources
        timebox
        handoff {
          _id
          handoff_at
          user {
            _id
            email
            avatar
            full_name
            occupation
            rates {
              currency
              rate
            }
          }
          time {
            total_time
            start
            end
          }
        }
        activity_type
        project {
          title
          color
        }
        created_by {
          email
          avatar
          full_name
        }
        created_at
        time {
          total_time
          start
          end
          created_at
        }
      }
    }
  }
`;
