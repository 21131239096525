import React from "react";
import { Typography, Row, Col } from "antd";
import winduLogo from "common/assets/windu_round_logo.svg";

const { Text } = Typography;
const NavHead = ({ collapsed }) => {
  const justifyContent = collapsed && { justifyContent: "center" };
  return (
    <Row style={{ width: "100%" }}>
      <Col
        style={{ marginTop: 15, ...justifyContent }}
        className="flex items-center"
        xs={24}
      >
        <img style={{ width: "40px" }} src={winduLogo} />
        {!collapsed && (
          <Text className="text-lg font-semibold ml-2 text-white">WINDU</Text>
        )}
      </Col>
    </Row>
  );
};

export default NavHead;
