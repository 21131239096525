import gql from "graphql-tag";

export const GET_WORKSPACE_INTEGRATION = gql`
  query ($name: String!, $workspace: String!) {
    getWorkspaceIntegration(name: $name, workspace: $workspace) {
      _id
      name
      user_name
      access_token
      refresh_token
      uniq_id
      avatar
    }
  }
`;
