import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

const SemiCircleProgressBar = ({
  percent = 0,
  pathColor = "#C6EBCE",
  progressColor = "#24B874",
  customContent = null,
}) => {
  const [percentage, setPercentage] = useState(percent);
  const [knobPosition, setKnobPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setPercentage(percent);

    // Calculate the position of the knob based on the percentage
    const totalDegrees = 240; // Total degrees for the semi-circle
    const radius = 40; // Radius of the circle

    let clampedPercentage = Math.max(0, Math.min(100, percent));

    // Map the percentage value to the knob position
    const knobPercentage = (clampedPercentage / 100) * 2; // Double the percentage

    // Calculate the angle in radians for the knob's position
    const angleInRadians =
      ((totalDegrees - knobPercentage * totalDegrees) / 2) * (Math.PI / 180);

    // Calculate the x and y coordinates for the knob's position
    const knobX = 50 - radius * Math.cos(angleInRadians);
    const knobY = 50 + radius * Math.sin(angleInRadians);

    setKnobPosition({ x: knobX, y: knobY });
  }, [percent]);

  const calculateDegreesFromPercentage = (totalDegrees, percentage) => {
    return (totalDegrees / 100) * percentage;
  };

  const calculateDash = (degrees, radius) => {
    return ((degrees * Math.PI) / 180) * 0.5 * radius;
  };

  const renderProgressBar = () => {
    const totalDegrees = 240; // Total degrees for the semi-circle
    const radius = 40; // Radius of the circle

    let clampedPercentage = Math.max(0, Math.min(100, percentage));

    const percentageDegrees = calculateDegreesFromPercentage(
      totalDegrees,
      clampedPercentage
    );

    const barDash = calculateDash(totalDegrees, radius);
    const barGap = calculateDash(360 - totalDegrees, radius) * 2;

    const progressDash = calculateDash(percentageDegrees, radius);
    const progressGap = calculateDash(360 - percentageDegrees, radius) * 2;

    return (
      <div className={styles.stat}>
        <svg viewBox="0 0 100 100">
          <path
            className={styles.bar}
            style={{
              stroke: pathColor,
              fill: "none",
              strokeDasharray: `${barDash} ${barGap}`,
            }}
            d={`M 10, 50 a ${radius},${radius} 0 1,0 80,0 a ${radius},${radius} 0 1,0 -80,0`}
          />
          <path
            className={styles.progress}
            style={{
              stroke: progressColor,
              fill: "none",
              strokeDasharray: `${progressDash} ${progressGap}`,
              transformOrigin: "center center",
              transform: `rotate(${
                0 - (totalDegrees - percentageDegrees) / 2
              }deg)`,
            }}
            d={`M 10, 50 a ${radius},${radius} 0 1,0 80,0 a ${radius},${radius} 0 1,0 -80,0`}
          />

          {/* Render the knob at the calculated position */}
          <circle
            cx={knobPosition.x}
            cy={knobPosition.y}
            r={5} // You can adjust the knob size as needed
            fill={progressColor}
            stroke={"white"} // You can change this to the color you want for the knob border
            strokeWidth={1} // Adjust the border width as needed
          />
        </svg>
      </div>
    );
  };

  return <div>{renderProgressBar()}</div>;
};

SemiCircleProgressBar.defaultProps = {
  percent: 0,
  pathColor: "#C6EBCE",
  progressColor: "#24B874",
  customContent: null,
};

export default SemiCircleProgressBar;
