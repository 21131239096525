import { useMutation, useQuery } from "@apollo/client";
import { Button, Typography } from "antd";
import {
  AntInput,
  AntSelect,
  AntTextArea,
  AntDatePicker,
} from "components/FormikCustomInputs";
import { Formik, Form, Field } from "formik";
import { GET_TODOIST_PROJECTS } from "graphql/queries/Todoist/getTodoistProject";
import React, { useState } from "react";
import _ from "lodash";
import utility from "common/utility";
import moment from "moment";
import { CREATE_TODOIST_TASK } from "graphql/mutations/Todoist/createTodoistTask";
import { parseWorkspaceIntegration } from "../utils";

import * as yup from "yup";

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  description: yup.string().trim().required("This field is required"),
  date: yup.date().required("This field is required").nullable(),
  todoistProject: yup.string().trim().required("This field is required"),
});

const NewTask = ({ workspaceId, setSelected, token }) => {
  const [todoistProjects, setTodoistProjects] = useState([]);

  const initialFormValues = {
    title: "",
    todoistProject: "",
    description: "",
    date: moment(),
  };

  const { data, loading } = useQuery(GET_TODOIST_PROJECTS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: workspaceId } },
    onCompleted: ({ getTodoistProjects }) => {
      const options = getTodoistProjects.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }));
      setTodoistProjects(options);
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [createTodoistTask, { loading: createTaskLoading }] = useMutation(
    CREATE_TODOIST_TASK,
    {
      onCompleted: () => {
        setSelected("board");
        utility.setNotification(
          "Task created successfully",
          "Task created successfully",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const onSubmit = (value) => {
    const payload = {
      ...value,
      token,
      date: value.date.format(),
    };
    createTodoistTask({ variables: { input: { ...payload } } });
  };

  return (
    <div>
      <Typography.Text strong>Add a task</Typography.Text>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ submitCount, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="flex">
                <Field
                  className="m-0"
                  component={AntInput}
                  name="title"
                  label="Title"
                  required
                  placeholder="Task title"
                  size="large"
                />
                <Field
                  className="m-0"
                  component={AntSelect}
                  name="todoistProject"
                  label="Select the project"
                  placeholder="Project A"
                  selectOptions={todoistProjects}
                  inputType="select"
                  hasFeedback
                  required={true}
                  size="large"
                  getPopupContainer={(node) => node.parentNode}
                />
              </div>
              <div className="flex">
                <Field
                  className="m-0"
                  component={AntTextArea}
                  name="description"
                  label="Description"
                  required
                  placeholder="Task title"
                  size="large"
                />
                <Field
                  className="m-0"
                  component={AntDatePicker}
                  name="date"
                  label="Plan date"
                  required
                  placeholder=""
                  size="large"
                  inputType="picker"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="flex mt-3 justify-end">
                <Button loading={createTaskLoading} onClick={handleSubmit}>
                  Add task
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewTask;
