import React, { useState, useEffect } from "react";
import Auth from "./Auth";
import { Form, Formik } from "formik";
import { Button, Menu } from "antd";
import { useRecoilState } from "recoil";
import { useMutation, useQuery } from "@apollo/client";

import { withRouter } from "react-router-dom";
import { getAuthToken, refreshToken } from "api/Jira";
import queryString from "query-string";

import Card from "../Card";
import JiraIcon from "../../../common/assets/jiraIcon.png";
import { UPDATE_JIRA } from "graphql/mutations/Jira/updateJira";
import utility from "common/utility";
import validationSchema from "./validationSchema";
import View from "./View";
import { userSession } from "recoil/atoms/User/UserSession";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { widgetsState } from "recoil/atoms/Dashboard/widgets";
import { useAuthWidgets } from "../common/useAuthWidgets";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import useCreateIntegrationHook from "common/createIntegration";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";
import { parseToken } from "../utils";

const Jira = ({ location }) => {
  const [user] = useRecoilState(userSession);
  const [authenticated, setAuthenticated] = useState(false);
  const [selected, setSelected] = useState("sprint");
  const [isRetrying, setIsRetrying] = useState(false);
  const { id } = queryString.parse(location.search);
  const { createIntegration } = useCreateIntegrationHook();

  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  const { loading, data } = useQuery(GET_WORKSPACE_INTEGRATION, {
    variables: { name: "Jira Cloud", workspace: id },
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setAuthenticated(getWorkspaceIntegration?.access_token);
      }
    },
  });

  useAuthWidgets(authenticated, "JIRA", id);
  const [updateJira] = useMutation(UPDATE_JIRA, {
    onError: (error) => {
      tryAndRefresh();
    },
  });

  useEffect(() => {
    const { integration, code, id } = queryString.parse(location.search);
    /// handle auth
    if (integration === "jira" && code) {
      createGoogleEvent(user?._id, "CONNECT_JIRA_WIDGET", "app_engagement");

      getAuthToken(code).then((res) => {
        const access_token = res?.data?.access_token;
        const refresh_token = res?.data?.refresh_token;

        createIntegration({
          type: "Jira Cloud",
          refresh_token,
          access_token,
          workspace: id,
        });
        setAuthenticated(access_token);
        window.history.pushState({ path: `` }, "", `/workspace?id=${id}`);
      });
    }
  }, [location]);

  useEffect(() => {
    if (isRetrying) {
      setSelected("settings");
    }
  }, [isRetrying, id]);

  const tryAndRefresh = () => {
    setIsRetrying(true);
    refreshToken(data?.getWorkspaceIntegration?.refresh_token)
      .then((r) => {
        const access_token = r?.data?.access_token;
        const refresh_token = r?.data?.refresh_token;

        createIntegration({
          type: "Jira Cloud",
          refresh_token,
          access_token,
          workspace: id,
        });
        setAuthenticated(access_token);

        setIsRetrying(false);
      })
      .catch(() => {
        setAuthenticated(false);
      });
  };

  const handleOption = (i) => {
    if (i?.key !== "help") {
      setSelected(i.key);
    }
  };

  const onSubmit = (values) => {
    updateJira({
      variables: { input: { ...values.settings, workspace: id } },
    }).then(() => {
      utility.setNotification(
        `${selected === "settings" ? "Settings Saved" : "Sync Saved"}`,
        `${
          selected === "settings"
            ? "Jira Settings Saved"
            : "Your jira is now synced with this workspace"
        }`,
        "success",
        "topRight"
      );
    });
  };

  const menu = (
    <Menu onClick={handleOption}>
      <Menu.Item key="sprint">
        <a>Sprint</a>
      </Menu.Item>
      <Menu.Item key="sync">
        <a>Sync</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a>Settings</a>
      </Menu.Item>
      <Menu.Item key="help">
        <a
          href="https://fishyvisions.zendesk.com/hc/en-us/sections/7546167142295-Jira"
          target={"_blank"}
        >
          Help
        </a>
      </Menu.Item>
    </Menu>
  );
  const bodyHeight = selected !== "sprint" ? "70%" : "83%";

  const form = React.useRef();
  const enlargeAction = () => {
    setEnglargeModal({ visible: true, widget: "jira cloud" });
  };

  return authenticated ? (
    <Formik
      initialValues={{
        settings: {
          server_name: undefined,
          server_url: undefined,
          sync: [],
          sprint_field: null,
        },
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={form}
      render={({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Card
            bodyStyle={{
              height: enlargeModal.visible ? " 90% " : bodyHeight,
              overflow: "auto",
            }}
            style={{
              overflow: "auto",
              height: enlargeModal.visible ? "94vh" : 450,
            }}
            isEnlarge={!enlargeModal.visible}
            enlargeAction={enlargeAction}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: 30 }}
                  className="mr-3"
                  src={JiraIcon}
                  alt="zoom"
                />
                <h5>Jira Software</h5>
              </div>
            }
            menu={menu}
            action={[
              selected !== "sprint" && (
                <div className="text-right pr-3">
                  <Button onClick={handleSubmit} type="default">
                    {selected === "settings" ? "Save" : "Save Sync "}
                  </Button>
                </div>
              ),
            ]}
          >
            <View
              token={authenticated}
              onRejected={() => tryAndRefresh()}
              view={selected}
              setSelected={setSelected}
            />
          </Card>
        </Form>
      )}
    />
  ) : (
    <Auth
      setAuthenticated={setAuthenticated}
      isAuthenticated={authenticated}
      updateJira={updateJira}
    />
  );
};

export default withRouter(Jira);
