import React from "react";
import { Typography } from "antd";
import Step from "./Step";
import {
  AimOutlined,
  UsergroupAddOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const Steps = ({ user }) => {
  return (
    <div className="p-5">
      <Title level={5}>Welcome {user?.full_name}</Title>
      <Text>
        Windu Figma Plugin makes it easier to attach designs to your current
        running activity. Please follow the steps to make sure you fully use the
        plugin!
      </Text>
      <Title level={5}>Steps to complete</Title>
      <Step
        icon={<ClockCircleOutlined />}
        title="Work on your designs while tracking your activity"
      />
      <Step icon={<AimOutlined />} title="Select your designs to attach" />
      <Step icon={<UsergroupAddOutlined />} title="Hand Off" />
    </div>
  );
};
export default Steps;
