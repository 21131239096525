import gql from "graphql-tag";

export const CREATE_PLAYBOOK_GOAL = gql`
  mutation ($input: createGoalInput) {
    createGoal(input: $input) {
      _id
      title
      priority
      details
      tags
      created_at
      key
      assigned {
        _id
        email
        full_name
        avatar
      }
      created_by {
        _id
        email
        full_name
        avatar
      }
      content
      fileSources
      playbook {
        _id
        title
        description
        activities {
          _id
          title
          tags
          priority
          tasks {
            _id
            description
            planned
            completed
            assigned {
              _id
              full_name
              email
              avatar
            }
          }
          assigned {
            _id
            email
            full_name
            avatar
          }
        }
      }
    }
  }
`;
