export const onBoardingInfoV2 = () => {
  return {
    Role: {
      name: "Role",
      question: "What's your primary mode?",
      options: [
        {
          key: "Command",
          label: "Command",
          value: "Command",
        },
        {
          key: "Focus",
          label: "Focus",
          value: "Focus",
        },
        {
          key: "Invest",
          label: "Invest",
          value: "Invest",
        },
      ],
    },
    WeeklyWorkHours: {
      name: "WeeklyWorkHours",
      question: "How many hours do you want to work for a typical week?",
      options: [
        {
          key: "10-20",
          value: "10-20",
          label: "10-20",
        },
        {
          key: "21-30",
          value: "21-30",
          label: "21-30",
        },
        {
          key: "31-40",
          value: "31-40",
          label: "31-40",
        },
        {
          key: "41-50",
          value: "41-50",
          label: "41-50",
        },
        {
          key: "50+",
          value: "50+",
          label: "50+",
        },
      ],
    },
    Work: {
      name: "expertise",
      question: "What do you specialize in?",
      options: [
        {
          key: "Design",
          label: "Design",
          value: "Design",
        },
        {
          key: "UX",
          label: "UX",
          value: "UX",
        },
        {
          key: "Engineering",
          label: "Engineering",
          value: "Engineering",
        },
        {
          key: "Devops",
          label: "Devops",
          value: "Devops",
        },
        {
          key: "Product Management",
          label: "Product Management",
          value: "Product Management",
        },
        {
          key: "Project Management",
          label: "Project Management",
          value: "Project Management",
        },
        {
          key: "Q/A Testing",
          label: "Q/A Testing",
          value: "Q/A Testing",
        },
        {
          key: "Sales",
          label: "Sales",
          value: "Sales",
        },
        {
          key: "Customer Service",
          label: "Customer Service",
          value: "Customer Service",
        },
        {
          key: "Research",
          label: "Research",
          value: "Research",
        },
        {
          key: "Human resources",
          label: "Human resources",
          value: "Human resources",
        },
        {
          key: "Data Analytics",
          label: "Data Analytics",
          value: "Data Analytics",
        },
        {
          key: "Marketing",
          label: "Marketing",
          value: "Marketing",
        },
        {
          key: "Others",
          label: "Others",
          value: "Others",
        },
      ],
    },

    TeamMembers: {
      name: "TeamMembers",
      question: "How many people are on your team?",
      options: [
        {
          key: "Only Me",
          value: "Only Me",
          label: "Only Me",
        },
        {
          key: "2-10",
          value: "2-10",
          label: "2-10",
        },
        {
          key: "11-25",
          value: "11-25",
          label: "11-25",
        },
        {
          key: "26-50",
          value: "26-50",
          label: "26-50",
        },
        {
          key: "51-100",
          value: "51-100",
          label: "51-100",
        },
        {
          key: "100+",
          value: "100+",
          label: "100+",
        },
      ],
    },
    Tools: {
      question: "What tools do you currently use or interested in using?",
      options: [
        { key: "Jira", value: "Jira", label: "Jira" },
        {
          key: "Github",
          value: "Github",
          label: "Github",
        },
        {
          key: "Figma",
          value: "Figma",
          label: "Figma",
        },
        {
          key: "Slack",
          value: "Slack",
          label: "Slack",
        },
        {
          key: "Monday.com",
          value: "Monday.com",
          label: "Monday.com",
        },
        {
          key: "Productboard",
          value: "Productboard",
          label: "Productboard",
        },
        {
          key: "Asana",
          value: "Asana",
          label: "Asana",
        },
        {
          key: "Google Meet",
          value: "Google Meet",
          label: "Google Meet",
        },
        {
          key: "Zoom",
          value: "Zoom",
          label: "Zoom",
        },
        {
          key: "Trello",
          value: "Trello",
          label: "Trello",
        },
        {
          key: "Others",
          value: "Others",
          label: "Others",
        },
      ],
    },
    CompanyWorkers: {
      name: "CompanyWorkers",
      options: [
        {
          key: "1-10",
          value: "1-10",
          label: "1-10",
        },
        {
          key: "11-25",
          value: "11-25",
          label: "11-25",
        },
        {
          key: "26-50",
          value: "26-50",
          label: "26-50",
        },
        {
          key: "51-100",
          value: "51-100",
          label: "51-100",
        },
        {
          key: "100+",
          value: "100+",
          label: "100+",
        },
      ],
    },
    Funding: {
      options: [
        {
          key: "pre-seed",
          value: "Pre-Seeded",
          label: "Pre-Seeded",
        },
        {
          key: "Seeded",
          value: "Seeded",
          label: "Seeded",
        },
        {
          key: "Series A",
          value: "Series A",
          label: "Series A",
        },
        {
          key: "Series B",
          value: "Series B",
          label: "Series B",
        },
        {
          key: "Series C and Beyond",
          value: "Series C and Beyond",
          label: "Series C and Beyond",
        },
        {
          key: "IPO",
          value: "IPO",
          label: "IPO",
        },
        {
          key: "100+",
          value: "Not sure",
          label: "Not Sure",
        },
      ],
    },
  };
};
