import gql from "graphql-tag";

export const GET_INVOICES = gql`
  query ($input: getInvoicesInput) {
    getInvoices(input: $input) {
      data {
        _id
        name
        code
        invoiceURL
        created_by
        status
        created_at
      }
      pagination {
        total_data
        total_pages
        current_page
      }
      overview {
        draft
        paid
        pending
        nroRates
        nroMembers
        membersWithoutRates {
          _id
          email
          avatar
          full_name
        }
      }
    }
  }
`;
