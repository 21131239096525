import axios from "axios";
import { UI_URL } from "common/constants";
export const JIRA_ACCESSIBLE_RESOURCE =
  "https://api.atlassian.com/oauth/token/accessible-resources";

export const JIRA_AUTHORIZE = (workspace) =>
  `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${
    process.env.REACT_APP_JIRA_CLIENT
  }&scope=offline_access manage:jira-configuration manage:jira-project read:jira-work read:me read:jira-user write:jira-work&redirect_uri=${UI_URL()}/workspace?integration=jira&state=${workspace}&response_type=code&prompt=consent`;

export const JIRA_REQUEST_ACCESS = "https://auth.atlassian.com/oauth/token";

export const JIRA_API = "https://api.atlassian.com/ex/jira";

export const getAuthToken = (code) => {
  return axios.post(
    `${JIRA_REQUEST_ACCESS}`,
    {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_JIRA_CLIENT,
      client_secret: process.env.REACT_APP_JIRA_CLIENT_SECRET,
      code: code,
      redirect_uri: `${UI_URL()}/workspace`,
    },
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
};

export const refreshToken = (token) => {
  return axios.post(
    `${JIRA_REQUEST_ACCESS}`,
    {
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_JIRA_CLIENT,
      client_secret: process.env.REACT_APP_JIRA_CLIENT_SECRET,
      refresh_token: token,
    },
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
};

export const getResources = (token) => {
  const Bearer = `Bearer ${token}`;
  return axios.get(`${JIRA_ACCESSIBLE_RESOURCE}`, {
    headers: {
      Authorization: Bearer,
    },
  });
};

export const JIRA_RESOURCE_PROJECT_SEARCH = (resource) => {
  return `${JIRA_API}/${resource}/rest/api/3/project/search`;
};

export const searchResourceProjects = (resource, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(JIRA_RESOURCE_PROJECT_SEARCH(resource), {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const JIRA_PROJECT_STATUSES = (resource, project) => {
  return `${JIRA_API}/${resource}/rest/api/3/project/${project}/statuses`;
};

export const searchStatuses = (resource, project, token) => {
  const Bearer = `Bearer ${token}`;
  return axios.get(JIRA_PROJECT_STATUSES(resource, project), {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const searchProjectWorkflowScheme = (resource, project, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(
    `${JIRA_API}/${resource}/rest/api/3/workflowscheme/project?projectId=${project}`,
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
      },
    }
  );
};

export const searchWorkflows = (resource, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(
    `${JIRA_API}/${resource}/rest/api/3/workflow/search?expand=transitions`,
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
      },
    }
  );
};

export const JIRA_PROJECT_ACTIVE_SPRINT_ISSUE_SEARCH = (
  resource,
  project,
  status
) => {
  return `${JIRA_API}/${resource}/rest/api/3/search?fields=*all`;
};

export const searchIssues = (resource, project, status, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.post(
    JIRA_PROJECT_ACTIVE_SPRINT_ISSUE_SEARCH(resource, project, status),
    {
      expand: ["names", "schema", "operations", "transitions"],
      jql: `project=${project} AND status in (${status}) AND sprint in openSprints () AND assignee = currentUser() `,
    },
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
      },
    }
  );
};

export const searchFields = (resource, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.get(`${JIRA_API}/${resource}/rest/api/3/field`, {
    headers: {
      Authorization: Bearer,
      Accept: "application/json",
    },
  });
};

export const transitionJiraActivity = (resource, issue, transition, token) => {
  const Bearer = `Bearer ${token}`;

  return axios.post(
    `${JIRA_API}/${resource}/rest/api/3/issue/${issue}/transitions`,
    {
      transition: {
        id: transition,
      },
    },
    {
      headers: {
        Authorization: Bearer,
        Accept: "application/json",
        "X-Atlassian-Token": "no-check",
      },
    }
  );
};
