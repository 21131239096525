import gql from "graphql-tag";

export const GET_VISITOR = gql`
  query {
    getVisitor {
      _id
      email
      stripe
      account {
        _id
        stripe
      }
    }
  }
`;
