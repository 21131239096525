import React, { useEffect, useState } from "react";
import { Avatar, Typography, List, Tag, Tooltip, Popover, Divider } from "antd";
import { withRouter } from "react-router-dom";
import querystring from "query-string";
import Icon from "@ant-design/icons";
import { useTimer } from "providers/useTimer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { head, last, truncate } from "lodash";
import moment from "moment";
import Icons from "common/icons";
import StartFistBump from "common/assets/FistBump/start.png";
import EndFistBump from "common/assets/FistBump/end.png";
import FistBumpAnimation from "common/assets/FistBump/animation.gif";

import styles from "./styles.module.less";
import { FISTBUMP_USER } from "graphql/mutations/Activity/fistBumpUser";
import utility from "common/utility";
import { useMutation } from "@apollo/client";
import { get, isEmpty } from "lodash";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilState } from "recoil";
import useOpenActivityDrawer from "common/openActivityDrawer";

const { Text } = Typography;

const AvatarTimer = ({
  activity,
  history,
  getActiveTimers,
  setshowDropdown,
  isFistBumpLoading,
  setIsFistBumpLoading,
}) => {
  const { startHandler, time } = useTimer("00:00:00");
  const [startGIF, setStartGIF] = useState(false);
  const [user] = useRecoilState(userSession);
  const { openActivityDrawer } = useOpenActivityDrawer();

  const [fistBumpUser] = useMutation(FISTBUMP_USER, {
    onCompleted: () => {
      getActiveTimers();
      setIsFistBumpLoading(false);
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    if (head(activity?.time?.paused)?.continue !== undefined) {
      const continueAt = last(activity?.time?.paused)?.continue;

      startHandler(continueAt);
    } else {
      const startTime = activity?.time?.start;
      startHandler(startTime);
    }
  }, [activity]);

  const sendFistBump = (activityId) => {
    setIsFistBumpLoading(true);
    setStartGIF(true);
    setTimeout(
      () => fistBumpUser({ variables: { input: { activity_id: activityId } } }),
      2000
    );
  };

  const alreadyFistBumped = !isEmpty(
    activity?.fist_bump?.filter((item) => item._id === user?._id)
  );

  const timerContent = (
    <div>
      <p>
        {truncate(utility.getPlainText(activity?.details), {
          length: 80,
          omission: "...",
        })}
      </p>
    </div>
  );

  return (
    <List.Item
      key={activity.created_by?.full_name}
      itemLayout="vertical"
      size="large"
      style={{ display: "flex" }}
    >
      <div className="flex">
        <div className="flex flex-col">
          <div>
            <Avatar size={48} shape="circle" src={activity?.created_by?.avatar}>
              {activity?.created_by?.full_name?.charAt(0)}
            </Avatar>
          </div>
          <div className="flex mt-2 items-baseline">
            <Popover
              content={<div style={{ maxWidth: 340 }}>{timerContent}</div>}
              title={
                <div className="flex justify-between">
                  <Text
                    className="mr-3 cursor-pointer"
                    onClick={() => {
                      openActivityDrawer(activity, true, false);
                      setshowDropdown(false);
                    }}
                  >
                    {activity?.title}
                  </Text>
                  <Tag
                    color={`${
                      activity?.project?.color &&
                      activity?.project?.color !== "none"
                        ? activity.project.color
                        : "#808080"
                    }`}
                  >
                    {activity?.project?.title}
                  </Tag>
                </div>
              }
              trigger="hover"
              placement="rightBottom"
              arrowPointAtCenter
            >
              <InfoCircleOutlined
                className="cursor-pointer icon-small"
                style={{ color: "#3C2E94" }}
              />
            </Popover>
            <Divider type="vertical" style={{ top: 5, height: 16 }} />
            <Tooltip placement="bottom" title="Timeline">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setshowDropdown(false);
                  history.push({
                    pathname: "/timeline",
                    search: querystring.stringify({
                      projectId: activity?.project?._id,
                      memberId: activity?.created_by?._id,
                      start_date: moment.utc().subtract(1, "months"),
                      end_date: moment.utc(),
                    }),
                  });
                }}
              >
                <Icon
                  className={`${styles.actionIcon} icon-small`}
                  component={Icons.Timeline}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="ml-6">
          <Text className="font-semibold capitalize text-base">
            {activity.created_by?.full_name}
          </Text>
          <div className="flex flex-col items-start">
            <div
              className="flex justify-center rounded mt-2 items-center px-4"
              style={{
                background: "#DFF3E4",
                border: "1px solid #63C276",
                color: "#63C276",
              }}
            >
              <Text style={{ color: "#62c376" }}>{time}</Text>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style={{ width: 90 }}>
          {alreadyFistBumped ? (
            <img
              src={EndFistBump}
              alt="Windu"
              style={{
                maxWidth: "100%",
                marginTop: "-16px",
                transform: "scale(1.2)",
              }}
            />
          ) : startGIF ? (
            <img
              src={FistBumpAnimation}
              alt="windu"
              style={{
                maxWidth: "100%",
                marginTop: "-16px",
                transform: "scale(1.2)",
              }}
            />
          ) : (
            <img
              src={StartFistBump}
              alt="windu"
              style={{
                maxWidth: "100%",
                marginTop: "-16px",
                transform: "scale(1.2)",
              }}
              onClick={() => sendFistBump(activity?._id)}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
    </List.Item>
  );
};
export default withRouter(AvatarTimer);
