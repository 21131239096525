import moment from "moment";
import queryString from "query-string";
import { INDIVIDUAL_PLAN, TEAM_PLAN } from "constants/plans";

export const routing = [
  {
    path: "/",
    inNav: true,
    label: "Dashboard",
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/projects",
    inNav: true,
    label: "Projects",
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "invest"],
  },
  {
    path: "/workspaces",
    label: "Workspaces",
    inNav: true,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus"],
    children: [{ path: "/workspaces", label: "Workspaces" }],
  },
  {
    path: "/projects/:id",
    inNav: false,
    label: "Projects",
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "invest"],
  },
  {
    path: "/people",
    inNav: true,
    label: "People",
    plan: [TEAM_PLAN],
    modes: ["command"],
  },
  {
    path: "/clients",
    inNav: true,
    label: "Clients",
    addon: "Invoicing",
    modes: ["command"],
  },
  {
    path: `/timeline`,
    inNav: true,
    label: "Timeline",
    plan: [TEAM_PLAN],
    search: queryString.stringify({
      start_date: moment.utc().subtract(1, "months"),
      end_date: moment().format(),
      projectId: "",
      memberId: "",
    }),
    modes: ["command"],
  },
  {
    path: "/import",
    inNav: true,
    label: "Import",
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    flag: "import",
    modes: ["command"],
  },
  {
    path: "/report",
    inNav: true,
    label: "Report",
    flag: "report",
    plan: [TEAM_PLAN],
    modes: ["command"],
  },
  {
    path: "/integration",
    inNav: false,
    label: "Integration",
    addon: "Integration",
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/messages",
    inNav: false,
    label: "Messages",
    addon: "Chat",
    modes: ["command", "focus", "invest"],
  },

  {
    path: "/workspace",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/settings",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/personalized",
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    inNav: false,
  },
  {
    path: "/quickstart",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/search",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/calendar",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
  {
    path: "/authorize",
    inNav: false,
    plan: [INDIVIDUAL_PLAN, TEAM_PLAN],
    modes: ["command", "focus", "invest"],
  },
];
