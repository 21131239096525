import gql from "graphql-tag";

export const GET_TRELLO_CARDS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTrelloCards(input: $input) {
      board_id
      board_name
      background
      background_color
      cards {
        id
        name
        desc
        idList
        url
        due
      }
    }
  }
`;
