import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Modal, Typography } from "antd";
import Jira from "components/Widgets/Jira";

import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import GitHub from "components/Widgets/GitHub";
import Monday from "components/Widgets/Monday";
import Todoist from "components/Widgets/Todoist";
import GoogleMeet from "components/Widgets/Meetings";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import styles from "./styles.module.css";
import Miro from "components/Widgets/Miro";
import Trello from "components/Widgets/Trello";
import Cookies from "js-cookie";
import Loader from "components/Loader";
import { enlargeState } from "recoil/atoms/Dashboard/enlarge";
import { useRecoilState } from "recoil";
import { GET_WORKSPACE_WIDGETS } from "graphql/queries/workpace/getWorkspaceWidgets";
import { useQuery } from "@apollo/client";
import utility from "common/utility";

const EnlargeWidget = () => {
  const [activeWidgetsSteps, setActiveWidgetsSteps] = useState([]);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [current, setCurrent] = useState(1);
  const [enlargeModal, setEnglargeModal] = useRecoilState(enlargeState);

  const { loading, data } = useQuery(GET_WORKSPACE_WIDGETS, {
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const handleCancel = () => {
    setEnglargeModal({ visible: false, widget: "" });
  };

  const steps = [
    {
      title: "monday",
      content: <Monday />,
    },
    {
      title: "meetings",
      content: <GoogleMeet />,
    },
    {
      title: "todoist",
      content: <Todoist />,
    },
    {
      title: "github",
      content: <GitHub />,
    },
    {
      title: "jira cloud",
      content: <Jira />,
    },
    {
      title: "miro",
      content: <Miro />,
    },
    {
      title: "trello",
      content: <Trello />,
    },
  ];
  useEffect(() => {
    // const allActiveWidgers = JSON.parse(Cookies.get(id));
    const toggledWidget = _.filter(
      data.getWorkspaceWidgets,
      (widget) => widget.toggle
    );
    const connectedWidgetsNames = _.map(toggledWidget, (item) =>
      item.widget?.label?.toLowerCase()
    );

    const filteredSteps = _.filter(steps, (item) =>
      _.includes(connectedWidgetsNames, item.title?.toLowerCase())
    );
    setActiveWidgetsSteps(filteredSteps);
    const widgetList = _.map(filteredSteps, (item) => item?.title);
    setCurrent(_.indexOf(widgetList, enlargeModal.widget));
  }, [enlargeModal.widget, data]);

  const next = () => {
    const nextCurrent =
      current === _.size(activeWidgetsSteps) - 1 ? 0 : current + 1;
    setCurrent(nextCurrent);
  };

  const prev = () => {
    const prevCurrent =
      current === 0 ? _.size(activeWidgetsSteps) - 1 : current - 1;
    setCurrent(prevCurrent);
  };

  if (_.isEmpty(activeWidgetsSteps)) {
    return <Loader size="large" />;
  }

  return (
    <Modal
      visible={enlargeModal.visible}
      width={"100%"}
      title={null}
      footer={null}
      onCancel={handleCancel}
      style={{ top: 10 }}
      bodyStyle={{ minHeight: "98vh", background: "#ECEFF3" }}
    >
      <div className="flex overflow-hidden gap-14" style={{ minHeight: 500 }}>
        {_.size(activeWidgetsSteps) === 1 && (
          <div style={{ minWidth: "94%" }} className="m-auto">
            {activeWidgetsSteps[Math.abs(current)]?.content}
          </div>
        )}

        {_.size(activeWidgetsSteps) > 1 && (
          <>
            {_.size(activeWidgetsSteps) > 2 ? (
              <div onClick={prev} className={styles.prevComponent}>
                <div className="flex flex-col items-center justify-center">
                  {getIntegrationLogo(
                    activeWidgetsSteps[
                      Math.abs(
                        current === 0
                          ? _.size(activeWidgetsSteps) - 1
                          : current - 1
                      )
                    ].title
                  )}
                  <Typography.Text strong className="capitalize text-xl ml-2">
                    {
                      activeWidgetsSteps[
                        Math.abs(
                          current === 0
                            ? _.size(activeWidgetsSteps) - 1
                            : current - 1
                        )
                      ].title
                    }
                  </Typography.Text>
                </div>
              </div>
            ) : (
              <div className={styles.prevComponent}></div>
            )}

            <div style={{ minWidth: "80%" }}>
              {activeWidgetsSteps[Math.abs(current)]?.content}
            </div>
            <div className={styles.nextComponent} onClick={next}>
              <div className="flex flex-col items-center justify-center">
                {getIntegrationLogo(
                  activeWidgetsSteps[
                    current === _.size(activeWidgetsSteps) - 1 ? 0 : current + 1
                  ].title
                )}
                <Typography.Text strong className="capitalize text-xl ml-2">
                  {
                    activeWidgetsSteps[
                      current === _.size(activeWidgetsSteps) - 1
                        ? 0
                        : current + 1
                    ].title
                  }
                </Typography.Text>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default EnlargeWidget;
