import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import useCheckIntegrationConnectionHook from "common/checkConnectionHook";
import Loader from "components/Loader";
import utility from "common/utility";
import { refreshToken as gooogleRefreshToken } from "api/Google";
import { UPDATE_INTEGRATION } from "graphql/mutations/Integrations/updateIntegration";
import { useMutation } from "@apollo/client";
import { refreshToken as jiraRefershToken } from "api/Jira";

const GetConnectionStatus = ({
  token,
  name,
  integrationData,
  getSelectedIntegration,
}) => {
  const [loading, setloading] = useState(true);
  const [userData, setuserData] = useState();
  const [error, setError] = useState();
  const type = _.lowerCase(name) === "meetings" ? "google" : _.lowerCase(name);
  const { checkIntegrationConnection } = useCheckIntegrationConnectionHook();

  const [updateIntegration, { loading: updaingIntegrationLoading }] =
    useMutation(UPDATE_INTEGRATION, {
      onCompleted: () => {
        getSelectedIntegration().then(() => {
          setloading(false);
        });
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Failed to update integration",
          errorMessage,
          "error",
          "topRight"
        );
      },
    });

  const tryAndRefresh = async ({ refreshToken }) => {
    try {
      setloading(true);
      let response;
      if (type === "google") {
        response = await gooogleRefreshToken(refreshToken);
      } else {
        response = await jiraRefershToken(refreshToken);
      }

      const access_token = response?.data?.access_token;
      const refresh_token = response?.data?.refresh_token;
      return { access_token, refresh_token };
    } catch (error) {
      setError(error);
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
      setloading(false);
      return { error };
    }
  };

  async function fetchData() {
    try {
      const { data, error } = await checkIntegrationConnection({
        type,
        token,
      });
      if (error) {
        if (type === "google" || type === "jira cloud") {
          const { refresh_token, access_token, error } = await tryAndRefresh({
            refreshToken: integrationData.refresh_token,
          });

          updateIntegration({
            variables: {
              input: {
                id: integrationData._id,
                refresh_token,
                refresh_token,
                access_token,
              },
            },
          });
        } else {
          setError(error);
          const errorMessage = _.get(error, "message", "Internal Error");
          utility.setNotification(
            "Something wrong happened",
            errorMessage,
            "error",
            "topRight"
          );
        }
      }
      setuserData(data);
      setloading(false);
    } catch (error) {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (!loading) {
    if (error) {
      return (
        <div className="flex items-center">
          <Tag icon={<CloseCircleOutlined />} color="error">
            error
          </Tag>
          <Button
            size="small"
            icon={<RedoOutlined />}
            onClick={fetchData}
          ></Button>
        </div>
      );
    } else
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          connected
        </Tag>
      );
  } else return <Loader />;
};

export default GetConnectionStatus;
