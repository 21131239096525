import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";
import { Formik, Form, Field } from "formik";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { bool, func } from "prop-types";
import Modal from "../BaseModal/Modal";
import { AntInputPassword } from "components/FormikCustomInputs";
import { UPDATE_USER_PASSWORD } from "graphql/mutations/user/updatePassword";
import validationSchema from "./validationSchema";
import utility from "common/utility";
import styles from "./styles.module.css";

const ChangePasswordModal = ({ isVisible, onClose, user, history }) => {
  const formRef = React.useRef();

  const [updateUserPassword, { loading }] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: ({ updateUserPassword }) => {
      Cookies.set("token", updateUserPassword?.token, {
        expires: 7,
      });
      utility.setNotification(
        "Password updated successfully",
        "Password updated",
        "success",
        "topRight"
      );
      history.push("/login");
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const onSubmit = (values) => {
    const { current, password } = values;

    updateUserPassword({
      variables: {
        input: {
          user_id: user._id,
          password,
          current,
          external: false,
        },
      },
    });
  };

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose();
  };

  return (
    <Modal
      title="Change Password"
      width={600}
      footer={null}
      visible={isVisible}
      onClose={handleCancel}
      style={{ top: 20 }}
      content={
        <Formik
          initialValues={{
            current: "",
            password: "",
            confirm: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ submitCount, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={AntInputPassword}
                  name="current"
                  label="Current password"
                  placeholder="Your current password"
                  submitCount={submitCount}
                  required={true}
                  hasFeedback
                  size="large"
                  className="rounded-xl"
                />
                <Field
                  component={AntInputPassword}
                  name="password"
                  label="New password"
                  placeholder="Introduce your new password"
                  submitCount={submitCount}
                  size="large"
                  hasFeedback
                  required={true}
                  rows={4}
                  className="rounded-xl"
                />
                <Field
                  component={AntInputPassword}
                  name="confirm"
                  label="Confirm new password"
                  placeholder="Confirm you new password"
                  submitCount={submitCount}
                  hasFeedback
                  required={true}
                  size="large"
                  className="rounded-xl"
                />
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center flex-col">
          <Button
            size="large"
            className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
            type="primary"
            onClick={() => handleDone()}
            loading={loading}
          >
            Change
          </Button>
          <Button
            size="large"
            onClick={() => handleCancel()}
            className="w-full mt-4"
          >
            Cancel
          </Button>
        </div>
      }
    />
  );
};

ChangePasswordModal.propTypes = {
  isVisible: bool,
  onClose: func,
};

export default withRouter(ChangePasswordModal);
