import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Typography } from "antd";
import { Formik, Form, Field } from "formik";
import { AntInput, AntInputPassword } from "components/FormikCustomInputs";
import utility from "common/utility";
import Modal from "../BaseModal/Modal";
import * as yup from "yup";
import { LOGIN_VISITOR } from "graphql/mutations/visitor.js/loginVisitos";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import Cookies from "js-cookie";
import { REGISTER_VISITOR } from "graphql/mutations/visitor.js/registerVisitor";

const VisitorOnboarding = ({ isVisible, onClose, title }) => {
  const formRef = React.useRef();
  const [user, setUser] = useRecoilState(userSession);

  const [login, { loading }] = useMutation(LOGIN_VISITOR, {
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    onCompleted: ({ visitorLogin }) => {
      const { token, user } = visitorLogin;
      Cookies.set("token", token, {
        expires: 30,
      });
      setUser(user);
      onClose();
    },
  });

  const [registerVisitor, { loading: registerLoading }] = useMutation(
    REGISTER_VISITOR,
    {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      onCompleted: ({ registerVisitor }) => {
        const { token, user } = registerVisitor;
        Cookies.set("token", token, {
          expires: 30,
        });
        setUser(user);
        onClose();
      },
    }
  );

  const onSubmit = (values) => {
    const { email, password } = values;
    if (title === "Login") {
      login({
        variables: {
          input: {
            email: email,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            password,
          },
        },
      });
    } else {
      registerVisitor({
        variables: {
          input: {
            email: email,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            password,
          },
        },
      });
    }
  };

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose();
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email().required("This field is required"),
    password: yup
      .string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  });

  return (
    <Modal
      title={title}
      width={600}
      footer={null}
      visible={isVisible}
      onClose={handleCancel}
      style={{ top: 20 }}
      content={
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ submitCount, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={AntInput}
                  name="email"
                  label="Email"
                  placeholder="Email"
                  submitCount={submitCount}
                  required={true}
                  hasFeedback
                  size="large"
                />
                <Field
                  component={AntInputPassword}
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  submitCount={submitCount}
                  size="large"
                  hasFeedback
                  required={true}
                  rows={4}
                />
                <div className="mt-2">
                  <Typography.Text>
                    By continuing, you agree to Windu's
                    <a
                      rel="noreferrer"
                      href="https://www.windu.io/terms-service/"
                      target="_blank"
                    >
                      &nbsp; Terms of Service &nbsp;
                    </a>
                    and acknowledge that you've read our
                    <a
                      rel="noreferrer"
                      href="https://www.windu.io/privacy-policy/"
                      target="_blank"
                    >
                      &nbsp; Privacy Policy
                    </a>
                  </Typography.Text>
                </div>
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center">
          <Button onClick={() => handleCancel()} className="w-32">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => handleDone()}
            className="ml-3 w-32"
            loading={loading || registerLoading}
          >
            Next
          </Button>
        </div>
      }
    />
  );
};

export default VisitorOnboarding;
