import React, { useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Setting from "./Setting";
import Board from "./Board";
import { GET_MONDAY_BOARD } from "graphql/queries/Monday/getMondayBoard";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import utility from "common/utility";
import { GET_MONDAY } from "graphql/queries/Monday/getMonday";
import { useFormikContext } from "formik";

const View = ({ view, onRejected, setSelected }) => {
  const location = useLocation();
  const [mondayBoard, setMondayBoard] = React.useState([]);
  const { id } = queryString.parse(location.search);
  const { values, setFieldValue, submitCount } = useFormikContext();

  const { data, loading, error } = useQuery(GET_MONDAY_BOARD, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    onCompleted: ({ getMondayBoards }) => {
      const options = getMondayBoards.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      }));

      setMondayBoard(options);
    },

    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const { data: mondayData } = useQuery(GET_MONDAY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    onCompleted: ({ getMonday }) => {
      if (getMonday) setFieldValue("settings", { ...getMonday });
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  return (
    <>
      {view === "board" && (
        <Board
          workspaceId={id}
          onRejected={onRejected}
          view={view}
          setSelected={setSelected}
        />
      )}
      {view === "settings" && (
        <Setting
          workspaceId={id}
          onRejected={onRejected}
          view={view}
          mondayBoard={mondayBoard}
          boardLoading={loading}
        />
      )}
    </>
  );
};
export default View;
