import gql from "graphql-tag";

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($input: updateInvoiceValuesInput) {
    updateInvoice(input: $input) {
      _id
      code
      name
      period
      payment_due
      total_due
      sub_total
      currency
      sent_to
      sent_at
      issue_date
      items {
        description
        hours
        cost
      }
    }
  }
`;