import {
  AppstoreAddOutlined,
  FileSearchOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import React from "react";
import {
  ADDON_INTEGRATIONS,
  ADDON_INVOICING,
  ADDON_CHAT,
} from "constants/plans";

export const getAddOnIcon = (item, checked) => {
  if (item == ADDON_INVOICING) {
    return (
      <FileSearchOutlined
        style={{ fontSize: 26, color: checked && "#F39A31" }}
      />
    );
  }
  if (item == ADDON_CHAT) {
    return (
      <WechatOutlined style={{ fontSize: 26, color: checked && "#F39A31" }} />
    );
  }
  if (item == ADDON_INTEGRATIONS) {
    return (
      <AppstoreAddOutlined
        style={{ fontSize: 26, color: checked && "#F39A31" }}
      />
    );
  }
};
