import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import useCheckIntegrationConnectionHook from "common/checkConnectionHook";
import Loader from "components/Loader";
import utility from "common/utility";

const CheckToken = ({ token, name, handleUseAction }) => {
  const [loading, setloading] = useState(true);
  const [error, setError] = useState();
  const type = _.lowerCase(name) === "meetings" ? "google" : _.lowerCase(name);
  const { checkIntegrationConnection } = useCheckIntegrationConnectionHook();

  async function fetchData() {
    try {
      const { data, error } = await checkIntegrationConnection({
        type,
        token,
      });
      if (error) {
        setError(error);
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      }
      setloading(false);
    } catch (error) {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (!loading) {
    if (error) {
      return (
        <div className="flex items-center">
          <Tag icon={<CloseCircleOutlined />} color="error">
            error
          </Tag>
          <Button
            size="small"
            icon={<RedoOutlined />}
            onClick={fetchData}
          ></Button>
        </div>
      );
    } else
      return (
        <Button type="primary" onClick={handleUseAction}>
          Use
        </Button>
      );
  } else return <Loader />;
};

export default CheckToken;
