import gql from "graphql-tag";

export const UPDATE_MESSAGE = gql`
  subscription updateMessage {
    updateMessage {
      _id
      content

      to
      from {
        _id
        email
        full_name
        avatar
      }
      chatId
      workspaceId
      type
      created_at
      unread
      edited
      fileSources
    }
  }
`;
