import gql from "graphql-tag";

export const GET_TANGO_ORDER = gql`
  query ($input: getTangoOrderHistoryInput) {
    getTangoOrderHistory(input: $input) {
      orders {
        amount
        currency
        createdAt
        status
        recipient {
          _id
          full_name
          email
          avatar
        }
      }
      page {
        number
        elementsPerBlock
        totalCount
        resultCount
      }
    }
  }
`;
