import React, { useState, useEffect } from "react";
import {
  InfoCircleOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Layout,
  Popover,
  Divider,
  Tag,
  Drawer,
  Tooltip,
} from "antd";
import { useQuery } from "@apollo/client";
import moment from "moment";
import moment_tz from "moment-timezone";
import { MenuOutlined } from "@ant-design/icons";
import { split, size, map, isEmpty, truncate, some, filter } from "lodash";
import { useTimer } from "contexts/timer-context";
import cx from "classnames";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { withApollo } from "@apollo/client/react/hoc";
import momentDurationFormatSetup from "moment-duration-format";
import { instanceOf, string } from "prop-types";
import styles from "./styles.module.less";
import Tour from "components/Tour";
import { activeActivity } from "recoil/atoms/Timer/activity";
import TimeCounter from "components/TimeCounter";
import ActiveTimers from "components/ActiveTimers";
import utility from "common/utility";
import Timer from "components/Timer";
import Reminder from "components/Reminder";
import Notifications from "./Notifications";
import Invitations from "components/Invitations";
import WorkspacesNotifications from "../WorkspaceMessages/WorkspacesNotifications";
import { GET_REQUESTS } from "graphql/queries/request/getRequests";
import NavMenu from "components/NavBar/NavMenu";
import Icons from "common/icons";
import Icon from "@ant-design/icons";
import { workspacesList } from "recoil/atoms/Workspaces";
import EditActivityPopover from "./EditActivityPopover";
import ActivityDrawer from "components/ActivityDrawer";
import { selectedActivity } from "recoil/atoms/Activity/selectedActivity";
import useOpenActivityDrawer from "common/openActivityDrawer";
import TimeboxProgressBar from "components/TimeboxProgressBar";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import ActivityHandoffModal from "components/Modals/ActivityHandoff";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import { requestList } from "recoil/atoms/Requests";
import { TEAM_PLAN } from "constants/plans";

const { Text, Paragraph } = Typography;
const { Header } = Layout;
momentDurationFormatSetup(moment);

const TopBar = ({ location, user, history, currentView, query = null }) => {
  const { pathname } = location;
  const activityId = queryString.parse(location?.search).activityId || false;
  const backlog = queryString.parse(location?.search)?.backlog || false;
  const [_, setPage] = useState([""]);
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const activity = useRecoilValue(activeActivity);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [isTrialUser, setIsTrialUser] = useState(false);
  const selected_activity = useRecoilValue(selectedActivity);
  const { openActivityDrawer } = useOpenActivityDrawer();
  const [handoffModal, setHandoffModal] = useState(false);
  const currentMode = useRecoilValue(selectedMode);
  const setRequests = useSetRecoilState(requestList);
  const [workspaceData] = useRecoilState(workspacesList);
  const [showInvitations, setShowInvitations] = useState(false);

  const tour = useRecoilValue(tourSteps);

  const { extra, plan } = user;
  const {
    foreignPlan: { plan: foreignPlan },
  } = extra;

  const isTeamPlan = plan?.name === TEAM_PLAN;
  const hasForeignPlan = foreignPlan === TEAM_PLAN;

  useQuery(GET_REQUESTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getRequests }) => {
      setRequests(getRequests);
    },
  });

  useEffect(() => {
    if (!selected_activity.toggle && activityId) {
      const payload = { _id: activityId };
      openActivityDrawer(payload, true, backlog);
    }
  }, []);

  useEffect(() => {
    if (workspaceData) {
      showNotifications();
    }
  }, [workspaceData]);

  useEffect(() => {
    if (user?.settings?.trial?.isActive) {
      const trialData = user?.settings?.trial;
      const daysLeft = moment(
        moment(trialData.trialEnd).format("YYYY-MM-DD")
      ).diff(moment().format("YYYY-MM-DD"), "days");
      const trialExpired = daysLeft <= 0;
      setIsTrialUser({ ...user?.settings?.trial, daysLeft, trialExpired });
    } else {
      setIsTrialUser({ isActive: false });
    }
  }, [user]);

  const { time } = useTimer(); // global timer from context

  useEffect(() => {
    getPage();
  }, [pathname]);

  useEffect(() => {
    if (!activity.active) {
      document.title = `Windu`;
    } else {
      document.title = `${time} - Windu`;
    }
  }, [time]);

  const getPage = () => {
    const pages = split(pathname, "/");
    setPage(pages);
  };

  const showNotifications = () => {
    const userId = user?._id;
    const externalProjects = filter(
      workspaceData,
      (workspace) => workspace?.project?.created_by?._id !== userId
    );

    const showNotifications = some(
      externalProjects,
      (e) => e?.project?.manager?._id === userId
    );

    setShowInvitations(showNotifications);
  };

  const pauses = map(activity.data?.time?.paused, (pause) => pause?.total);

  if (currentMode === "invest") {
    return (
      <div className="flex justify-end pt-5 pr-2">
        <Notifications />
      </div>
    );
  }

  const TrialHeader = () => {
    const backgroundColor =
      isTrialUser?.trialExpired || user?.settings?.payment_failed
        ? "#F59993"
        : "#967DDE";

    const text =
      isTrialUser?.trialExpired || user?.settings?.payment_failed
        ? "Your account is inactive. Please select a plan"
        : `FREE TRIAL - ${isTrialUser?.daysLeft} days remaining`;

    return (
      <div
        style={{
          height: 35,
          backgroundColor,
        }}
        className="w-full flex items-center justify-center"
      >
        <Typography.Text className="text-lg text-white">{text}</Typography.Text>
      </div>
    );
  };

  return (
    <>
      {(isTrialUser?.isActive || user?.settings?.payment_failed) && (
        <TrialHeader />
      )}

      <>
        {activity.active &&
          activity.data?.timebox !== "00:00:00" &&
          activity.data?.timebox != null && (
            <TimeboxProgressBar activity={activity?.data} />
          )}

        <Header style={{ height: 75 }} className="w-full bg-transparent p-0">
          <div
            className="site-page-header flex px-5 pt-3 h-16 items-center"
            style={{ backgroundColor: "#f0f2f5" }}
          >
            <div className={cx("mr-5", styles.iconMobile)}>
              <MenuOutlined
                className="text-white text-3xl"
                onClick={() => setIsDrawerVisible(true)}
                style={{ color: "#4d35c2" }}
              />
              <Drawer
                title={
                  <div className={styles.logoContainer}>
                    <Icon component={Icons.Logo} />
                  </div>
                }
                placement="left"
                onClose={() => setIsDrawerVisible(false)}
                visible={isDrawerVisible}
                width={200}
                bodyStyle={{ padding: 0, background: "rgb(20 9 53)" }}
                headerStyle={{
                  padding: 0,
                  background: "rgb(20 9 53)",
                  border: "none",
                }}
                drawerStyle={{ background: "rgb(20 9 53)" }}
              >
                <NavMenu
                  user={user}
                  history={history}
                  currentView={currentView}
                  query={query}
                  inHeader={true}
                />
              </Drawer>
            </div>
            {user?.user_type !== "Client" && <Timer />}
            {activity.active && (
              <>
                <Popover
                  className="max-w-lg min-w-min"
                  visible={showActivityDetails}
                  title={
                    <div className="flex justify-between items-center">
                      <Text
                        className="font-semibold text-base cursor-pointer"
                        onClick={() =>
                          openActivityDrawer(activity.data, true, false)
                        }
                      >
                        {activity.data?.title}
                      </Text>
                      <CloseOutlined
                        className="icon-small"
                        onClick={() => setShowActivityDetails(false)}
                      />
                    </div>
                  }
                  content={
                    <div
                      style={{ maxWidth: "500px", minWidth: "100px" }}
                      className="break-words"
                    >
                      <Paragraph className="text-justify">
                        {truncate(
                          utility.getPlainText(activity.data?.details),
                          {
                            length: 80,
                            omission: "...",
                          }
                        )}
                      </Paragraph>
                      <Tag
                        className="mt-2"
                        color={`${
                          activity.data.project.color &&
                          activity.data.project.color !== "none"
                            ? activity.data.project.color
                            : "#808080"
                        }`}
                      >
                        {activity?.data.project.title}
                      </Tag>
                      <Divider className="my-2" />
                      <div className="flex items-center">
                        {size(pauses) > 0 && (
                          <>
                            <Text>
                              {`Total time ${utility.calculateTimeTotal(
                                pauses
                              )}`}
                            </Text>
                            <Divider type="vertical" />
                          </>
                        )}

                        <Text>{`Session ${size(pauses) + 1} `}</Text>
                        <Divider type="vertical" />
                        <Text>
                          {`Started ${moment_tz(activity.data?.time.start)
                            .tz(user.timezone)
                            .fromNow()}`}
                        </Text>
                      </div>
                    </div>
                  }
                  trigger="hover"
                  placement="rightBottom"
                  arrowPointAtCenter
                >
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShowActivityDetails(true)}
                  >
                    <TimeCounter time={time} />
                    <InfoCircleOutlined className="icon-small ml-2" />
                  </div>
                </Popover>
                <Popover
                  visible={showEditActivity}
                  content={
                    showEditActivity && (
                      <EditActivityPopover
                        showEditActivity={showEditActivity}
                        setShowEditActivity={setShowEditActivity}
                      />
                    )
                  }
                  placement="bottomLeft"
                  title={
                    <div className="flex justify-between items-center">
                      <Text className="font-semibold text-base">
                        Edit {activity.data?.title}
                      </Text>
                      <CloseOutlined
                        className="icon-small"
                        onClick={() => setShowEditActivity(false)}
                      />
                    </div>
                  }
                >
                  <EditOutlined
                    onClick={() => setShowEditActivity(!showEditActivity)}
                    className="icon-small ml-2"
                  />
                </Popover>
              </>
            )}

            <Reminder />

            <div className="flex justify-end w-full items-center">
              {(isTeamPlan || showInvitations) && <Invitations />}
              <Notifications />
              {(isTeamPlan || hasForeignPlan) && <ActiveTimers />}
              {!isEmpty(workspaceData) && utility.haveChatAccess(user) && (
                <WorkspacesNotifications />
              )}
            </div>
          </div>
          {selected_activity.toggle && (
            <ActivityDrawer setHandoffModal={setHandoffModal} />
          )}
          {handoffModal && (
            <ActivityHandoffModal
              isVisible={handoffModal}
              setHandoffModal={setHandoffModal}
              selectedHandoffActivity={handoffModal?.data}
            />
          )}
          {tour.isAcitve && (
            <Tour
              isOpen={tour.isAcitve}
              onClose={() => {
                history.push("/quickstart?tour=completed");
              }}
            />
          )}
        </Header>
      </>
    </>
  );
};

TopBar.propTypes = {
  pathname: string,
  search: string,
  params: instanceOf(Object),
  user: instanceOf(Object),
  history: instanceOf(Object),
  client: instanceOf(Object),
};

export default withApollo(withRouter(TopBar));
