import React from "react";
import { Typography, Tag, List } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
const { Text } = Typography;

const RequestCard = ({
  description,
  status,
  invitee,
  _id,
  onRemove,
  updated_at,
  title,
}) => {
  const message = `${invitee} has been requested to be part of your workspace`;

  const renderStatus = () => {
    switch (status) {
      case "Pending":
        return <Tag color="orange">{status}</Tag>;
      case "Accepted":
        return <Tag color="#87d068">{status}</Tag>;
      default:
        return <Tag color="#f50">{status}</Tag>;
    }
  };

  return (
    <List.Item key={_id} className="p-5">
      <List.Item.Meta
        className="flex text-left"
        title={title}
        description={
          <>
            <div className="flex justify-between">
              <div className="mb-3 pr-3">{description}</div>
              <div className="ml-2">
                <CloseOutlined
                  onClick={() => onRemove(_id)}
                  className="bg-gray-200"
                  style={{ padding: 5, borderRadius: "50%", fontSize: 12 }}
                />
              </div>
            </div>
            <div>
              <Text className="text-gray-600">{renderStatus()}</Text>
              <Text className="text-xs">
                {moment(updated_at).format("MM/DD/YYYY, h:mm:ss a")}
              </Text>
            </div>
          </>
        }
      />
    </List.Item>
  );
};

export default RequestCard;
