import gql from "graphql-tag";

export const SEARCH_USER = gql`
  query ($value: String!) {
    searchUser(value: $value) {
      _id
      email
      avatar
      full_name
      user_type
      isOnline
    }
  }
`;
