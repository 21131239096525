import React from "react";
import TourContent from "./content";

// const steps = [
//   {
//     selector: ".tutorial-nav",
//     position: "center",
//     content: (
//       <TourContent
//         title="Navigation"
//         text="Here is your dedicated space for navigating throughout the application."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-timer",
//     position: "bottom",
//     content: (
//       <TourContent
//         title="Timer"
//         text="At the top right, you can start, stop, and pause an activity for any given project."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-dashboard",
//     position: "right",
//     content: (
//       <TourContent
//         title="Dashboard"
//         text="Check your activity progress, see paused activities, and plan future activities."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-projects",
//     position: "right",
//     content: (
//       <TourContent
//         title="Projects"
//         text="Create and manage project details and activities."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-teams",
//     position: "right",
//     content: (
//       <TourContent
//         title="Teams"
//         text="See an overview of your managers, as well as your team members and their activities."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-clients",
//     position: "right",
//     content: (
//       <TourContent title="Clients" text="View and manage your clients." />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-timeline",
//     position: "right",
//     content: <TourContent title="Timeline" text="View activity history." />,
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-messages",
//     position: "right",
//     content: (
//       <TourContent
//         title="Messages"
//         text="Chat with clients and team members."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-notifications",
//     position: "bottom",
//     content: (
//       <TourContent
//         title="Notifications"
//         text="Stay informed about projects, team members, and more via your notifications."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-requests",
//     position: "bottom",
//     content: (
//       <TourContent
//         title="Invitations"
//         text="Track your invitation requests here."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-active-timer",
//     position: "bottom",
//     content: (
//       <TourContent
//         title="Active Timers"
//         text="See your team members’ activities in real time."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
//   {
//     selector: ".tutorial-user",
//     position: "bottom",
//     content: (
//       <TourContent
//         title="User menu"
//         text="Manage your profile and account settings here."
//       />
//     ),
//     style: {
//       backgroundColor: "black",
//       color: "white",
//     },
//   },
// ];

const steps = [
  {
    id: 0,
    title: "Overview",
    target: ".tour-overviewwidget",
    content: "A high overview of what you got going on in windu.",
    disableBeacon: true,
  },
  {
    id: 1,
    title: "Planned Widget",
    target: ".tour-plannedwidget",
    content:
      "Any activity that has a date and is assigned to you will live here. By clicking the menu, you will also notice this is how you can start it, rearrange it and hand it off!",
    disableBeacon: true,
    placement: "right",
  },
  {
    id: 2,
    target: ".tour-recentwidget",
    content:
      "This will show you all the recent activity that goes through your projects. You can also segment and sort it!",
    placement: "bottom",
    title: "Recent Activity",
    disableBeacon: true,
    placement: "right",
  },

  {
    id: 3,
    title: "Project Performance",
    target: ".tour-projectperformancewidget",
    content:
      "Measure how much time is being spent on your project here with comparison to previous metrics.",
    disableBeacon: true,
  },
  {
    id: 4,
    title: "Continue Widget",
    target: ".tour-continuewidget",
    content:
      "All of your paused activities will stay here waiting for you to either continue, finish or handoff.",
    disableBeacon: true,
  },
  {
    id: 5,
    target: ".tour-firstcontinuewidget",
    title: "Continue Widget",
    content:
      "Looks like there is one ready for you to continue! Please go ahead and continue this activity. Please Click the menu icon and then click the continue option.",
    disableBeacon: true,
    showButtons: false,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: "none",
      },
    },
  },
  {
    id: 6,
    title: "Continue Widget",
    target: ".tour-contineuActivityMenu",
    content: "Click the conitnue option.",
    disableBeacon: true,
    placement: "right",
    hideBackButton: true,
    floaterProps: {
      disableAnimation: true, // disable animation for the floater
    },
    styles: {
      floater: { transform: "none !important", backgroundColor: "red" }, // hide the floater
      buttonNext: {
        display: "none",
      },
    },
  },
  {
    id: 7,
    title: "Continue Widget",
    target: ".tour-activeTimerPause",
    content: "Now lets pause it again!",
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: "none",
      },
    },
    hideBackButton: true,
  },
  {
    id: 8,
    title: "Continue Widget",
    target: ".tour-continuewidget-second",
    content:
      "See how your activity is back where it was originally, the only thing changed was the time you have spent on it.",
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: false,
  },
  {
    id: 9,
    target: ".tour-activityCountwidget",
    title: "Activity Count",
    content:
      "This analytical heat-map will show the count of activities that are being completed day by day.",
    disableBeacon: true,
    placement: "right",
    spotlightClicks: false,
  },
  {
    id: 10,
    target: ".tour-recentwidget",
    title: "Activity Details",
    content:
      "If you ever need to get more details from a specific activity, you can always click on it.",
    disableBeacon: true,
    spotlightClicks: false,
  },
  {
    id: 11,
    target: ".tour-recentwidget",
    title: "Activity Details",
    content: "Try clicking on an activity",
    disableBeacon: true,
    spotlightClicks: true,
    placement: "left",
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: "none",
      },
    },
    scrollToFirstStep: true,
    scrollingDisabled: true,
  },
  {
    id: 12,
    target: ".ant-drawer-content-wrapper",
    title: "Activity Details",
    content:
      "As you can see you are able to see more information about this activity. you will notice there is this new section (show activity collapse) which shows who has worked on it and for how long.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    placement: "left",
    floaterProps: {
      styles: {
        floater: {
          marginTop: "450px",
        },
        arrow: {
          display: "none",
        },
      },
    },
    scrollToFirstStep: true,
    scrollingDisabled: true,
  },
  {
    id: 13,
    target: ".tour-customizingLayout",
    title: "Customizing Layout",
    content: "You also have the ability to customize the layout.",
    disableBeacon: true,
    spotlightClicks: true,
    hideBackButton: true,
    scrollToFirstStep: true,
    scrollingDisabled: true,
    isLastStep: true,
  },
];

export { steps };
