import { CaretLeft } from "@phosphor-icons/react";
import { Typography } from "antd";
import { DemoCategoryData } from "constants/demoCategory";
import React, { useState } from "react";

import SelectedCategory from "./SelectedCategory";
import { Crisp } from "crisp-sdk-web";
import EmptyCardsImage from "common/assets/quickStart/articlesCard.svg";

const { Text } = Typography;

const CategoryPage = ({ setCategoryPageOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategoryHelpStatusSent, setIsCategoryHelpStatusSent] =
    useState(false);
  const [selectedHelpArticle, setSelectedHelpArticle] = useState(null);

  const CategoryCard = ({ category }) => {
    return (
      <div
        className="w-64 flex flex-col items-center p-2 rounded-lg cursor-pointer"
        style={{ background: "#fafafc" }}
        onClick={() => {
          setSelectedCategory(category);
          if (!isCategoryHelpStatusSent) {
            Crisp.session.pushEvent("helpCategory", {
              category: category.name,
            });
            setIsCategoryHelpStatusSent(true);
          }
        }}
      >
        <img src={EmptyCardsImage} />
        <div className="p-3 flex flex-col">
          <Text strong className="text-base">
            {category.name}
          </Text>
          <Text>{category.description}</Text>
        </div>
      </div>
    );
  };

  return (
    <>
      {selectedCategory ? (
        <SelectedCategory
          category={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setCategoryPageOpen={setCategoryPageOpen}
          setSelectedHelpArticle={setSelectedHelpArticle}
          selectedHelpArticle={selectedHelpArticle}
        />
      ) : (
        <div>
          <div className="flex items-center ml-2">
            <Text
              className="cursor-pointer"
              onClick={() => setCategoryPageOpen(null)}
            >
              Quick Start
            </Text>
            <CaretLeft size={16} />
            <Text className="font-semibold">Categories</Text>
          </div>

          <div className="my-4">
            <Text className="text-lg" strong>
              Welcome to Windu Demo! 👋
            </Text>
          </div>
          <div className="flex items-start gap-3">
            {DemoCategoryData.map((item, index) => (
              <CategoryCard category={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryPage;
