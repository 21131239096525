import gql from "graphql-tag";

export const GET_CALENDAR_PROJECTS = gql`
  query($input: getWorkspacesWidgetsInput) {
    getCalendarProjects(input: $input) {
      calendar_project_subscription {
        project {
          _id
          title
          manager_id {
            _id
          }
          created_by {
            _id
          }
          title
        }
        calendar
      }
      user {
        email
      }
    }
  }
`;
