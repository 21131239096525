import gql from "graphql-tag";

export const CREATE_BACKLOG_ACTIVITY = gql`
  mutation createBacklogActivity($input: createBacklogActivityInput) {
    createBacklogActivity(input: $input) {
      _id
      title
      details
      planned_date
      assigned {
        _id
      }
    }
  }
`;
