import gql from "graphql-tag";

export const GET_USER_WIDGETS = gql`
  query ($mode: String) {
    getUserWidgets(mode: $mode) {
      widget {
        _id
        name
        connection_required
      }
      _id
      i
      x
      y
      w
      h
    }
  }
`;
