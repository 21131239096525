import React from "react";
import {
  CheckCircleOutlined,
  FileSearchOutlined,
  FormOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Typography, Dropdown, Tooltip, List, Menu } from "antd";
import moment from "moment-timezone";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { Icon } from "@fishyvisions/windu-uikit";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { SEND_INVOICE } from "graphql/mutations/invoice/sendInvoiceEmail";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import { workspaceSelected } from "recoil/atoms/Workspaces";

const { Text, Link } = Typography;

const InvoiceList = ({
  invoiceId,
  loading,
  data,
  handleUpdateInvoiceStatus,
  handleDeleteInvoice,
  handleDownloadInvoice,
  handleCreateCopy,
  handleSelectInvoice,
  loadingPreview,
  invoiceHtml,
}) => {
  const history = useHistory();
  const location = useLocation();
  const user = useRecoilValue(userSession);
  const { id } = queryString.parse(location.search);
  const query = { id };

  const workspace = useRecoilValue(workspaceSelected);

  const editDraftHandler = (e, invoiceId) => {
    // redirect to create invoice page with selected invoice to edit in table
    e.stopPropagation();
    history.push({
      search: queryString.stringify({
        ...query,
        invoice: "editinvoice",
        invoiceId: invoiceId,
      }),
    });
  };

  const getInvoiceIcon = ({ status, invoiceId }) => {
    if (status === "PAID") {
      return (
        <CheckCircleOutlined
          style={{
            fontSize: "30px",
            backgroundColor: "#F6FFED",
            borderRadius: "50%",
            color: "#52C41A",
          }}
        />
      );
    }
    if (status === "DRAFT") {
      return (
        <FormOutlined
          onClick={(e) => editDraftHandler(e, invoiceId)}
          style={{
            fontSize: "16px",
            border: "3px solid #0095FF",
            borderRadius: "50%",
            padding: 5,
            backgroundColor: "E2F8FF",
            color: "#0095FF",
          }}
        />
      );
    }
    if (status === "PENDING") {
      return (
        <SendOutlined
          style={{
            fontSize: "16px",
            border: "3px solid #FAAD14",
            borderRadius: "50%",
            padding: 5,
            backgroundColor: "#FFFBE6",
            color: "#FAAD14",
          }}
        />
      );
    }

    return null;
  };

  const [sendInvoiceEmail] = useMutation(SEND_INVOICE, {
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      utility.setNotification(
        "Invoice has been sent",
        `Invoice sent successfully to the client email.`,
        "success",
        "topRight"
      );
    },
  });

  const InvoiceDropDown = ({ status, invoiceId, invoiceURL }) => {
    return (
      <Menu className="py-0">
        {status === "PENDING" && (
          <>
            <Menu.Item
              className="py-4 flex items-center"
              key="1"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PAID" })
              }
            >
              Mark as Paid
            </Menu.Item>
            <Menu.Item
              className="py-4 flex items-center"
              key="7"
              onClick={() =>
                sendInvoiceEmail({
                  variables: {
                    input: { projectId: workspace?.project._id, invoiceId },
                  },
                })
              }
            >
              Email invoice to client
            </Menu.Item>
          </>
        )}
        {status === "DRAFT" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="2"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PENDING" })
              }
            >
              Mark as Pending
            </Menu.Item>
          </>
        )}
        {/* {status === "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="3"
              onClick={() =>
                handleUpdateInvoiceStatus({ invoiceId, status: "PENDING" })
              }
            >
              Revert to pending payment
            </Menu.Item>
          </>
        )} */}
        {status !== "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="4"
              onClick={() => handleCreateCopy({ invoiceId })}
            >
              Create a Copy
            </Menu.Item>
          </>
        )}

        <>
          <Menu.Divider className="my-0" />
          <Menu.Item
            className="py-4 flex items-center"
            key="5"
            onClick={() => handleDownloadInvoice({ invoiceURL })}
          >
            Download PDF
          </Menu.Item>
        </>

        {status !== "PAID" && (
          <>
            <Menu.Divider className="my-0" />
            <Menu.Item
              className="py-4 flex items-center"
              key="6"
              onClick={() => handleDeleteInvoice({ invoiceId })}
            >
              Delete invoice
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  return (
    <div
      style={{ border: "1px solid #F0F0F0" }}
      className=" flex p-4 mt-6 rounded-sm"
    >
      <List
        style={{ width: "45%", height: "65vh" }}
        className="p-4 mt-6 rounded-sm"
        loading={loading}
        itemLayout="horizontal"
        dataSource={data?.getInvoices?.data}
        renderItem={(item) => {
          let invoiceText = "";
          if (item.status === "PENDING") invoiceText = "Invoice Pending";
          if (item.status === "DRAFT") invoiceText = "Invoice Draft";
          if (item.status === "PAID") invoiceText = "Invoice Paid";
          return (
            <div
              className="bg-gray-100 p-3 mt-2 rounded-md cursor-pointer"
              style={
                invoiceId === item._id
                  ? {
                      borderRight: "8px solid #3C2E94",
                      backgroundColor: "#EFEEF6",
                      paddingRight: 2,
                    }
                  : {}
              }
              onClick={() => handleSelectInvoice({ invoiceId: item._id })}
            >
              <div className="flex justify-between ">
                <div className="flex items-center ">
                  <div className="flex">
                    <div className="flex flex-col ">
                      <FileSearchOutlined style={{ fontSize: "50px" }} />
                      <Text style={{ fontSize: 12, color: "#5C4AC4" }}>
                        view preview
                      </Text>
                    </div>
                    <div className="flex ml-3 items-center">
                      <div>
                        <Tooltip title={invoiceText} placement="bottom">
                          {getInvoiceIcon({
                            status: item.status,
                            invoiceId: item._id,
                          })}
                        </Tooltip>
                      </div>
                      <div className="flex flex-col ml-3 text-left">
                        <Link className="font-semibold" onClick={() => {}}>{`${
                          item.name
                        } - ${
                          item.status === "DRAFT"
                            ? "Draft Invoice"
                            : `Invoice #${item.code}`
                        }`}</Link>
                        <Text className="text-gray-500">
                          {moment(new Date(item.created_at))
                            .tz(user.timezone)
                            .format("LL")}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mr-3">
                  <Dropdown
                    placement="bottomRight"
                    overlay={
                      <InvoiceDropDown
                        status={item.status}
                        invoiceId={item._id}
                        invoiceURL={item.invoiceURL}
                      />
                    }
                    trigger={["click"]}
                  >
                    <span
                      onClick={(e) => e.preventDefault()}
                      className="cursor-pointer"
                    >
                      <Icon type="Meatball" size="small" />
                    </span>
                  </Dropdown>
                </div>
              </div>
            </div>
          );
        }}
      />
      <div
        className="rounded-xl w-full ml-3"
        style={{
          border: "6px solid#EFEEF6",
          minHeight: "500px",
          padding: "5px",
        }}
      >
        {loadingPreview ? (
          <div>Loading preview... </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: invoiceHtml?.previewInvoice }}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceList;
