import gql from "graphql-tag";

export const STOP_TRELLO_ACTIVITY = gql`
  mutation ($input: stopTrelloActivityInput) {
    stopTrelloActivity(input: $input) {
      _id
      title
    }
  }
`;
