import React from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Button,
  Pagination,
  Result,
  Input,
  DatePicker,
  Select,
  Space,
} from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from "recharts";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PROJECT_ACTIVITIES } from "graphql/queries/projects/getProjectActivities";
import { GET_CONTRIBUTORS } from "graphql/queries/member/getContributors";
import { GET_PROJECT_TAGS } from "graphql/queries/projects/getProjectTags";
import { REMOVE_ACTIVITIES } from "graphql/mutations/Activity/removeActivities";
import { userSession } from "recoil/atoms/User/UserSession";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import utility from "common/utility";
import Loader from "components/Loader";
import ActivityTable from "components/ActivityTable";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const sizePerPage = 10;
const initialOffset = 0;
const initialSearch = "";

const activityStatus = [
  {
    label: "Finished",
    value: 3,
  },
  {
    label: "On Pause",
    value: 2,
  },
  {
    label: "Running",
    value: 1,
  },
];

const Activities = () => {
  const { id } = useParams();
  const user = useRecoilValue(userSession);
  const workspace = useRecoilValue(workspaceSelected);
  const { project } = workspace;

  const [activitiesList, setActivitiesList] = React.useState([]);
  const [contributors, setContributors] = React.useState([]);
  const [projectTags, setProjectTags] = React.useState([]);
  const [tagsStats, setTagsStats] = React.useState([]);
  const [activitiesRemove, setActivitiesRemove] = React.useState([]);
  const [queryParameters, setQueryParameters] = React.useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
    filters: {},
  });

  const {
    loading: loadingActivities,
    data: activitiesData,
    error: errorActivities,
    refetch: refetchActivities,
  } = useQuery(GET_PROJECT_ACTIVITIES, {
    variables: { input: { project: project?._id, ...queryParameters } },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjectActivities }) => {
      const { data } = getProjectActivities;
      setActivitiesList(data);
    },
  });

  const { loading: loadingContributors } = useQuery(GET_CONTRIBUTORS, {
    variables: { input: { project: project?._id } },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getContributors }) => {
      setContributors(getContributors);
    },
  });

  const [removeProjectActivities, { loading: removingActivities }] =
    useMutation(REMOVE_ACTIVITIES, {
      onCompleted: () => {
        setActivitiesRemove([]);
        refetchActivities();
        utility.setNotification(
          "Success",
          `Your activities has been removed!`,
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    });

  const { loading: projectTagLoading } = useQuery(GET_PROJECT_TAGS, {
    variables: {
      input: {
        projectId: project?._id,
        stats: true,
        filters: queryParameters.filters,
        search: queryParameters.search,
      },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getProjectTags }) => {
      const tags = getProjectTags?.tags || [];
      const stats = getProjectTags?.stats || [];
      setProjectTags(tags);
      setTagsStats(stats);
    },
  });

  if (errorActivities) {
    return (
      <Result
        status="error"
        title="Fetching Failed"
        subTitle={toString(errorActivities)}
      />
    );
  }

  const activitiesPagination = activitiesData?.getProjectActivities?.pagination;

  const onSearch = (query) => {
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const onPageChange = (value, pageSize) => {
    const selectedPage = value - 1;
    const newOffset = selectedPage * pageSize;
    setQueryParameters({
      ...queryParameters,
      offset: newOffset,
      size: pageSize,
    });
  };

  const delayedQuery = _.debounce((query) => {
    onSearch(query);
  }, 1000);

  const onDateFilter = (dates) => {
    const currentFilter = queryParameters.filters;
    let filters = {};

    if (_.size(dates)) {
      filters = {
        start: moment.utc(new Date(dates[0])),
        end: moment.utc(new Date(dates[1])),
      };
    }

    setQueryParameters({
      ...queryParameters,
      offset: 0,
      filters: { ...currentFilter, ...filters },
    });
  };

  const onStatusFilter = (status) => {
    const currentFilter = queryParameters.filters;
    setQueryParameters({
      ...queryParameters,
      offset: 0,
      filters: { ...currentFilter, status },
    });
  };

  const onTagsFilter = (tags) => {
    const currentFilter = queryParameters.filters;
    setQueryParameters({
      ...queryParameters,
      offset: 0,
      filters: { ...currentFilter, tags },
    });
  };

  const onMemberFilter = (members) => {
    const currentFilter = queryParameters.filters;
    setQueryParameters({
      ...queryParameters,
      offset: 0,
      filters: { ...currentFilter, members },
    });
  };

  const handleRemoveActivities = () => {
    removeProjectActivities({
      variables: {
        input: {
          activities: activitiesRemove,
          projectId: project._id,
        },
      },
    });
  };

  return (
    <div className="px-3">
      <Row>
        <Col xxl={16} xl={16} lg={16}>
          <div className="mt-6 ml-6">
            <div className="mb-3 flex justify-between">
              <Input.Search
                className="w-full mr-3"
                placeholder={`Search ${project?.title}'s activities`}
                size="large"
                allowClear
                onChange={({ target }) => {
                  if (target.value) {
                    delayedQuery(target.value);
                  }
                }}
                onSearch={(value) => onSearch(value)}
              />
              <RangePicker
                size="large"
                className="w-full"
                onChange={(dates) => onDateFilter(dates)}
              />
            </div>
            <div className="flex">
              <Space>
                <Select
                  placeholder="Tags"
                  allowClear
                  size="large"
                  className="w-64"
                  mode="tags"
                  options={_.map(projectTags, (tag) => {
                    return { value: tag, label: tag };
                  })}
                  onChange={onTagsFilter}
                />
                <Select
                  placeholder="Contributors"
                  allowClear
                  size="large"
                  className="w-64"
                  mode="multiple"
                  loading={loadingContributors}
                  options={_.map(contributors, (user) => {
                    return { value: user._id, label: user.email };
                  })}
                  onChange={onMemberFilter}
                  showSearch
                  notFoundContent={
                    <div className="flex justify-center">No Data</div>
                  }
                  filterOption={(input, option) =>
                    (_.lowerCase(option?.label) ?? "").includes(
                      _.lowerCase(input)
                    )
                  }
                />
                <Select
                  placeholder="Status"
                  className="w-32"
                  allowClear
                  size="large"
                  options={activityStatus}
                  onChange={onStatusFilter}
                />
              </Space>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xxl={18} xl={18} lg={18} flex={1}>
          <Card className={`w-full shadow-none border-none outline-none my-2`}>
            {_.size(activitiesRemove) > 0 && (
              <Row className="mb-6">
                <Col xl={{ span: 8 }} lg={8} xs={18} md={18} sm={18}>
                  <Button
                    type="primary"
                    danger
                    onClick={handleRemoveActivities}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            )}
            <ActivityTable
              activities={activitiesList}
              loading={loadingActivities || removingActivities}
              rowSelectionIndicator={
                user._id === project.created_by._id ||
                user._id === _.get(project.manager_id, "_id", null)
              }
              activitiesRemove={activitiesRemove}
              setActivitiesRemove={setActivitiesRemove}
            />
            {activitiesPagination && (
              <Pagination
                className="my-4 text-center"
                current={activitiesPagination?.current_page}
                onChange={onPageChange}
                total={activitiesPagination?.total_data || 0}
                pageSize={queryParameters.size}
                showTotal={(total, range) =>
                  `${range[0] || 0}-${range[1] || 0} of ${total || 0} items`
                }
              />
            )}
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} className="2xl:block xl:flex">
          <Card 
            className="w-full shadow-none outline-none mt-8 2xl:ml-0 xl:ml-2"
            loading={projectTagLoading}
          >
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="grid items-center justify-between">
                  <Text className="text-base font-semibold mb-3">Tags</Text>
                </div>
              </div>
            </div>
            <ResponsiveContainer minHeight={400} width="100%" height="100%">
              <BarChart
                data={tagsStats}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 40, bottom: 0 }}
              >
                <YAxis
                  dataKey="tag"
                  type="category"
                  tickLine={false}
                  interval={0}
                />
                <XAxis hide type="number" />
                <Bar
                  isAnimationActive={true}
                  fill="#8884d8"
                  dataKey="activities"
                />
                <ChartTooltip cursor={false} />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Activities;
