import React, { useState, useEffect } from "react";
import { Button, Segmented } from "antd";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue, useRecoilState } from "recoil";
import winduLogo from "common/assets/WinduLogo.svg";
import { UI_URL } from "common/constants";
import Timer from "./Timer";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { useTimer } from "contexts/timer-context";
import References from "./References";
import { RightSquareFilled, DownSquareFilled } from "@ant-design/icons";
import { UPDATE_ACTIVITY_SOURCES } from "graphql/mutations/Activity/updateActivitySources";
import { useMutation, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { api } from "common/constants";
import Steps from "./Steps";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";

const FigmaTimer = () => {
  const user = useRecoilValue(userSession);
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [expand, setExpand] = useState(false);
  const [height, setHeight] = useState("50px");
  const [selection, setSelection] = useState(false);
  const [references, setReferences] = useState([]);
  const [segment, setSegment] = useState("Steps");

  const [getCurrentActivity, { loading }] = useLazyQuery(GET_CURRENT_ACTIVITY, {
    fetchPolicy: "cache-and-network",
  });

  const [updateActivitySources, { loading: stopping }] = useMutation(
    UPDATE_ACTIVITY_SOURCES,
    {
      onCompleted: () => {
        getCurrentActivity();
      },
    }
  );
  const { time } = useTimer(); // global timer from context

  useEffect(() => {
    window.parent.postMessage(
      {
        pluginMessage: { activity, expand },
        pluginId: "*",
      },
      "*"
    );
  }, [activity]);

  const handleUpdateSources = (payload) => {
    // callback
    var b64 = Buffer.from(payload.data.pluginMessage.image).toString("base64");

    axios
      .post(`${api}/activity/upload/files?buffer=true`, {
        userId: user._id,
        base64Data: b64,
        key: payload.data.pluginMessage.meta.id,
      })
      .then((res) => {
        ///update current activity to add file attachments
        const path = res?.data?.Location;

        updateActivitySources({
          variables: {
            input: {
              activity_id: activity?.data?._id,
              fileSources: path,
            },
          },
        });
        const img = URL.createObjectURL(
          new Blob([payload.data.pluginMessage.image.buffer], {
            type: "image/png",
          })
        );
        setReferences((prev) => {
          return [...prev, img];
        });
      });
  };

  window.onmessage = (m) => {
    if (m?.data?.pluginMessage?.showHandoff !== undefined) {
      setSelection(m?.data?.pluginMessage?.showHandoff);
    }
    if (m.data.pluginMessage.image) {
      handleUpdateSources(m);
    }
  };

  const handleCollapse = () => {
    const expandValue = !expand;
    window.parent.postMessage(
      {
        pluginMessage: { expand: expandValue, activity },
        pluginId: "*",
      },
      "*"
    );
    setExpand(expandValue);
    if (expandValue) {
      setHeight("400px");
    } else {
      setHeight("50px");
    }
  };

  const handleAttach = () => {
    window.parent.postMessage(
      {
        pluginMessage: {
          addReference: true,
          expand: expand,
          activity: { active: true },
        },
        pluginId: "*",
      },
      "*"
    );
  };
  const renderBtn = selection;
  const renderHandoff = activity.active;
  const renderReferences = expand && segment === "Attachments";
  const renderSteps = expand && segment == "Steps";
  const handleSegment = (v) => {
    setSegment(v);
  };
  return (
    <div className="flex-start" style={{ height, alignItems: "center" }}>
      {renderBtn &&
        (activity?.active ? (
          <div>
            <Button
              onClick={handleAttach}
              style={{
                width: "100%",
                background: "#62C376",
                border: "#62C376",
                borderRadius: 0,
                color: "white",
              }}
            >
              Attach Selected
            </Button>
          </div>
        ) : (
          <Button
            style={{ width: "100%" }}
            onClick={() => window.open(UI_URL(), "_blank")}
          >
            Go to Windu
          </Button>
        ))}
      <div className="flex" style={{ width: "100%" }}>
        <img
          onClick={() => window.open(UI_URL(), "_blank")}
          className="cursor-pointer"
          style={{ height: " 50px", width: 50, padding: "10px" }}
          src={winduLogo}
        />
        <div className="flex" style={{ alignItems: "center" }}>
          <Timer
            onExpand={() => setExpand(true)}
            renderHandoff={renderHandoff}
            time={time}
          />
        </div>
        <div
          style={{
            justifyContent: "end",
            display: "flex",
            width: "100%",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          {expand ? (
            <DownSquareFilled
              style={{ fontSize: 25 }}
              className="cursor-pointer"
              onClick={handleCollapse}
            />
          ) : (
            <RightSquareFilled
              style={{ fontSize: 25 }}
              className="cursor-pointer"
              onClick={handleCollapse}
            />
          )}
        </div>
      </div>
      {expand && (
        <Segmented
          onChange={handleSegment}
          block
          options={["Steps", "Attachments"]}
        />
      )}
      {renderSteps && <Steps user={user} />}
      {renderReferences && (
        <References
          onAdd={(m) => handleUpdateSources(m)}
          references={references}
          onselectionchange={(v) => setSelection(v)}
          activity={activity}
        />
      )}
    </div>
  );
};

export default FigmaTimer;
