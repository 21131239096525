import gql from "graphql-tag";

export const REGISTER_VISITOR = gql`
  mutation ($input: visitorLoginInput) {
    registerVisitor(input: $input) {
      token
      user {
        _id
        email
        stripe
        account {
          _id
          stripe
        }
      }
    }
  }
`;
