import React from "react";
import { Dropdown, Layout, Menu, Tabs } from "antd";
import Users from "./users";
import Roles from "./roles";
import Integrations from "./Integration";
import { EllipsisOutlined } from "@ant-design/icons";
import { RESET_WORKSPACE_LAYOUT } from "graphql/mutations/widget/resetWorkspaceLayout";
import { RESET_DASHBOARD_LAYOUT } from "graphql/mutations/widget/resetDashboardLayout";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import { ADD_WORKSPACE_FIELD } from "graphql/mutations/Admin/addWorkspaceField";

const { TabPane } = Tabs;

const Admin = () => {
  const [resetWorkspaceLayout, { loading: worksapceLoading }] = useMutation(
    RESET_WORKSPACE_LAYOUT,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Layouts has been reset",
          `Layouts has been reset`,
          "success",
          "topRight"
        );
      },
    }
  );
  const [resetDashboardLayout, { loading: dashboardLoading }] = useMutation(
    RESET_DASHBOARD_LAYOUT,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Layouts has been reset",
          `Layouts has been reset`,
          "success",
          "topRight"
        );
      },
    }
  );
  const [addWorkspaceField, { loading: loading }] = useMutation(
    ADD_WORKSPACE_FIELD,
    {
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          error,
          "error",
          "topRight"
        );
      },
      onCompleted: () => {
        utility.setNotification(
          "Workspace field added",
          `Workspace field added`,
          "success",
          "topRight"
        );
      },
    }
  );
  const menu = () => {
    return (
      <Menu className="py-0">
        <Menu.Item
          className="py-4 flex items-center"
          key="1"
          onClick={() => resetDashboardLayout()}
        >
          Reset dashboard layouts
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          className="py-4 flex items-center"
          key="2"
          onClick={() => resetWorkspaceLayout()}
        >
          Reset worksapce layouts
        </Menu.Item>
        <Menu.Divider className="my-0" />
        <Menu.Item
          className="py-4 flex items-center"
          key="3"
          onClick={() => addWorkspaceField()}
        >
          Add Workspace Field
        </Menu.Item>
      </Menu>
    );
  };
  const TabDropDown = () => {
    return (
      <Dropdown overlay={() => menu()}>
        <EllipsisOutlined style={{ fontSize: 32 }} />
      </Dropdown>
    );
  };

  return (
    <Layout className="p-5 h-screen w-full">
      <Tabs
        defaultActiveKey="1"
        moreIcon={null}
        tabBarExtraContent={TabDropDown()}
      >
        <TabPane tab="Users" key="1">
          <Users />
        </TabPane>
        <TabPane tab="Roles" key="2">
          <Roles />
        </TabPane>
        <TabPane tab="Integrations" key="3">
          <Integrations />
        </TabPane>
      </Tabs>
    </Layout>
  );
};
export default Admin;
