import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form } from "antd";
import { Formik, Field } from "formik";
import { get, map, trim } from "lodash";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Modal from "../BaseModal/Modal";
import {
  AntDatePicker,
  AntInput,
  AntSelect,
} from "components/FormikCustomInputs";
import { UPDATE_USER } from "graphql/mutations/user/updateUser";
import { GET_USER } from "graphql/queries/getUser";
import validationSchema from "./validationSchema";
import { bool, func } from "prop-types";
import utility from "common/utility";
import { validMomentTimezones } from "common/constants";
import moment from "moment";
import styles from "./styles.module.css";
import { onBoardingInfoV2 } from "views/Personalize/onBoardingData";

const EditProfile = ({ isVisible, onClose, user }) => {
  const formRef = React.useRef();

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      utility.setNotification(
        "User information updated successfully",
        `information updated`,
        "success",
        "topRight"
      );

      handleCancel();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    awaitRefetchQueries: true,
    refetchQueries: [GET_USER],
  });

  const onSubmit = (values) => {
    const {
      fullName,
      timezone,
      phone_number = "",
      birthday,
      specialities,
    } = values;

    const phone = parsePhoneNumber(phone_number);

    updateUser({
      variables: {
        input: {
          name: fullName,
          timezone: trim(timezone),
          birthday,
          phone_number: {
            phone: phone?.number || "",
            short: phone?.country || "",
            code: phone?.countryCallingCode || "",
          },
          onboarding_data: {
            ...user.onboarding_data,
            expertise: specialities,
          },
        },
      },
    });
  };

  const handleDone = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    formRef.current.resetForm();
    onClose();
  };

  return (
    <Modal
      title="Edit Profile"
      width={600}
      footer={null}
      visible={isVisible}
      onClose={handleCancel}
      style={{ top: 20 }}
      content={
        <Formik
          initialValues={{
            fullName: user.full_name,
            specialities: user?.onboarding_data?.expertise,
            avatar: user?.avatar,
            timezone: user.timezone,
            phone_number: user?.phone_number?.phone,
            birthday: user?.birthday ? moment(user?.birthday) : "",
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ submitCount, handleSubmit, setFieldValue, values }) => {
            return (
              <Form onSubmit={handleSubmit} className={styles.editForm}>
                <Field
                  component={AntInput}
                  name="fullName"
                  label="Name"
                  placeholder="Name"
                  submitCount={submitCount}
                  hasFeedback
                  required={true}
                  size="large"
                  rows={4}
                />
                <Field
                  component={AntSelect}
                  name="specialities"
                  label="Specialities"
                  placeholder="Designer, developer, QA tester"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                  mode="multiple"
                  rows={4}
                  inputType="select"
                  options={map(onBoardingInfoV2().Work.options, (item) => {
                    return { value: item.value, label: item.value };
                  })}
                />
                <Field
                  component={AntDatePicker}
                  name="birthday"
                  label="Birthday"
                  placeholder="05/16/2000"
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                  rows={4}
                  className="w-full rounded-xl"
                  inputType="picker"
                  format="MM-DD-YYYY"
                />
                <Field
                  component={AntSelect}
                  name="timezone"
                  label="Timezone"
                  inputType="select"
                  placeholder="Select a Timezone"
                  selectOptions={map(validMomentTimezones, (value) => {
                    return { value, label: value };
                  })}
                  showSearch
                  submitCount={submitCount}
                  hasFeedback
                  size="large"
                  rows={4}
                />
                <div
                  className="field-container w-full"
                  style={{ margin: "0.25em" }}
                >
                  <Form.Item
                    className="mb-0"
                    name="phone_number"
                    label={"Phone number"}
                    placeholder="Phone number"
                    initialValue={values.phone_number}
                    required={true}
                    labelCol={{ span: 24 }}
                    help={
                      get(formRef?.current, "errors['phone_number']", null)
                        ? formRef?.current?.errors["phone_number"]
                        : null
                    }
                    validateStatus={
                      get(formRef?.current, "errors['phone_number']", null)
                        ? "error"
                        : "success"
                    }
                  >
                    <PhoneInput
                      international
                      placeholder="Enter phone number"
                      value={values.phone_number}
                      onChange={(value) => setFieldValue("phone_number", value)}
                      className="ant-input ant-input-lg focus:ant-input-focused input-phone-number"
                      style={{ borderRadius: 12 }}
                    />
                  </Form.Item>
                </div>
              </Form>
            );
          }}
        </Formik>
      }
      actions={
        <div className="flex justify-center items-center flex-col">
          <Button
            size="large"
            className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
            type="primary"
            onClick={() => handleDone()}
            loading={loading}
          >
            Save
          </Button>
          <Button
            size="large"
            onClick={() => handleCancel()}
            className="w-full mt-4"
          >
            Cancel
          </Button>
        </div>
      }
    />
  );
};

EditProfile.propTypes = {
  isVisible: bool,
  onClose: func,
};

export default EditProfile;
