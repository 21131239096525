import React, { useState, useEffect } from "react";
import Card from "../Card";
import CommonAuth from "../common/Auth";
import miroLogo from "../../../common/assets/miro.png";

const Auth = ({ onConnect }) => {
  const [pickerView, setPickerView] = useState(false);

  useEffect(() => {
    if (pickerView)
      window.miroBoardsPicker.open({
        clientId: process.env.REACT_APP_MIRO_CLIENT,
        action: "select",
        success: function (result) {
          onConnect(result);
        },
      });
  }, [pickerView]);

  return (
    <Card title="Miro" style={{ height: "140px" }}>
      <CommonAuth
        onConnect={() => setPickerView(true)}
        logo={miroLogo}
        text="Connect and embed your board to Windu"
      />
    </Card>
  );
};

export default Auth;
