import React, { useEffect, useState } from "react";
import { Typography, Row, Col } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { useQuery } from "@apollo/client";

const { Text, Title } = Typography;
const References = ({ activity, onselectionchange, references, onAdd }) => {
  const user = useRecoilValue(userSession);
  const [disabled, setDisabled] = useState(true);
  const { active, data } = activity;

  window.onmessage = (m) => {
    onselectionchange(m.data.pluginMessage.showHandoff);
    setDisabled(m.data?.pluginMessage.disableButton);

    if (m.data.pluginMessage.image) {
      ///
      onAdd(m);
    }
  };
  const listReferences = () =>
    data?.fileSources?.map((e) => {
      return (
        <Col className="mb-5" xs={12}>
          <div
            style={{ background: "#3C2E94", padding: "5px", color: "white" }}
          >
            <FileImageOutlined />
          </div>
          <img style={{ width: "100%" }} src={e} />
        </Col>
      );
    });
  if (!active)
    return (
      <div className="pt-5" style={{ textAlign: "center" }}>
        <div>
          <Text>
            In order to use this plugin you must have a running activity.
          </Text>
        </div>
      </div>
    );
  return (
    <div className="p-5">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={5}>References</Title>
      </div>
      <Row gutter={16}>{listReferences()}</Row>
    </div>
  );
};

export default References;
