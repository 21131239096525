import gql from "graphql-tag";

export const GET_ABSENT_MEETINGS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getAbsentMeetings(input: $input) {
      calendar
      project {
        _id
      }
    }
  }
`;
