import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { map, unionBy, debounce } from "lodash";
import { useLazyQuery } from "@apollo/client";
import Loader from "components/Loader";
import cx from "classnames";
import { GET_WHOLE_TEAM } from "graphql/queries/member/getWholeTeam";

const sizePerPage = 10;
const initialOffset = 0;

const MemberSelect = ({
  className,
  onChange,
  value,
  placeholder = "Filter by Members",
}) => {
  const [size] = useState(sizePerPage);
  const [offset, setoffset] = useState(initialOffset);
  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState("");

  const [getMembers, { loading }] = useLazyQuery(GET_WHOLE_TEAM, {
    variables: { input: { size, offset, search, includeContributors: true } },
    onCompleted: (data) => {
      setMembers(unionBy([], data?.getWholeTeam?.data, "_id"));
    },
  });

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setoffset((current) => current + 10);
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const delayedQuery = React.useCallback(
    debounce((query) => {
      setSearch(query);
    }, 1000),
    []
  );

  useEffect(() => {
    getMembers();
  }, [search]);

  return (
    <Select
      placeholder={placeholder}
      size="large"
      className={cx(className, "w-64")}
      onPopupScroll={onScroll}
      loading={loading}
      filterOption={false}
      options={map(members, (member) => {
        return {
          label: member.email,
          value: member._id,
          full_name: member.full_name,
        };
      })}
      allowClear
      showSearch
      onSearch={(v) => delayedQuery(v)}
      value={value || []}
      onChange={onChange}
      mode="multiple"
      dropdownRender={(menu) => (
        <div>
          {menu}
          {loading && (
            <div>
              <Loader size={"small"} />
            </div>
          )}
        </div>
      )}
    />
  );
};

export default MemberSelect;
