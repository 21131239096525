import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Sync from "./Sync";
import ServerSettingView from "./ServerSettingView";
import { GET_JIRA } from "graphql/queries/Jira/getJira";
import { useLazyQuery } from "@apollo/client";
import utility from "common/utility";
import { get, map, isNil } from "lodash";
import { searchResourceProjects } from "api/Jira";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import SprintView from "./SprintView";

const View = ({ token, view, onRejected, setSelected }) => {
  const { values, setFieldValue, submitCount } = useFormikContext();
  const [jiraProjects, setJiraProjects] = useState([]);
  const location = useLocation();

  const { id } = queryString.parse(location.search);

  const [getJira, { loading: loadingGetJira }] = useLazyQuery(GET_JIRA, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { input: { workspace: id } },
    onCompleted: ({ getJira }) => {
      setFieldValue("settings", { ...getJira });
      if (!isNil(getJira?.server_name)) {
        searchResourceProjects(getJira?.server_name, token)
          .then((r) => {
            const data = map(r?.data?.values, (e) => {
              return { label: e?.name, value: e?.id };
            });
            setJiraProjects(data);
          })
          .catch(() => {
            onRejected();
          });
      }
    },

    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  useEffect(() => {
    getJira({ variables: { input: { workspace: id } } });
  }, [view]);

  return (
    <>
      {view === "settings" && (
        <ServerSettingView token={token} id={id} onRejected={onRejected} />
      )}
      {view === "sync" && (
        <Sync
          id={id}
          token={token}
          onRejected={onRejected}
          jiraProjects={jiraProjects}
        />
      )}
      {view === "sprint" && (
        <SprintView
          token={token}
          id={id}
          onRejected={onRejected}
          setSelected={setSelected}
        />
      )}
    </>
  );
};
export default View;
