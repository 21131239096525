import { Button, Checkbox, Modal, Typography } from "antd";
import React from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import queryString from "query-string";
import { useRecoilValue } from "recoil";
import UserAvatar from "components/UserAvatar";
import { SEND_REMINDER } from "graphql/mutations/reminder/sendReminder";
import { useHistory, useLocation } from "react-router";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import utility from "common/utility";

const { Title, Text } = Typography;

const RateWarningModal = ({
  membersWithNotRates,
  rateWarningModal,
  setrateWarningModal,
}) => {
  const workspace = useRecoilValue(workspaceSelected);
  const history = useHistory();
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const query = { id };
  const handleContinue = () => {
    setrateWarningModal(false);
    history.push({
      search: queryString.stringify({
        ...query,
        invoice: "createinvoice",
      }),
    });
  };
  const handleCancel = () => {
    setrateWarningModal(false);
  };

  const [sendRateReminder, { loading: sending }] = useMutation(SEND_REMINDER, {
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        error,
        "error",
        "topRight"
      );
    },
    onCompleted: ({ sendRateReminder }) => {
      utility.setNotification(
        "Success",
        `Reminder has been sent`,
        "success",
        "topRight"
      );
    },
  });

  const handleSendReminder = (user) => {
    sendRateReminder({
      variables: {
        input: {
          title: "Don't forget to add your rate!",
          subject: `You still do not have your rate configured in workspace settings for, ${workspace?.project?.title}`,
          recipient: user,
          meta: { key: "WORKSPACE_ID", value: id },
        },
      },
    });
  };

  return (
    <div>
      <Modal
        title="Basic Modal"
        visible={rateWarningModal}
        footer={null}
        closable={false}
        width="650px"
      >
        <div className="m-auto w-11/12 text-base flex flex-col">
          <Title level={5}>
            Some of your members are missing their hourly rates
          </Title>
          <Text>
            If you continue to create an invoice now, their completed activities
            will not be included. Remind your team members to add their rates
            via their Workspace settings.
          </Text>
          <div className="mt-3">
            <Text>Rates missing from:</Text>
            <div
              style={{ border: "2px solid lightgrey" }}
              className="p-3 my-2 rounded-xl"
            >
              {_.map(membersWithNotRates, (member) => {
                return (
                  <div className="flex justify-between mt-3" key={member._id}>
                    <div>
                      <UserAvatar user={member} />
                      <Text style={{ marginLeft: "12px" }}>{member.email}</Text>
                    </div>
                    <Button
                      onClick={() => handleSendReminder(member._id)}
                      loading={sending}
                    >
                      Send Reminder
                    </Button>
                  </div>
                );
              })}
            </div>
            <Checkbox>Don’t show this message again</Checkbox>

            <div className="mt-5 mb-3 flex justify-evenly">
              <Button
                type="primary"
                size="large"
                className="px-10"
                onClick={handleContinue}
              >
                Continue
              </Button>
              <Button size="large" className="px-10" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RateWarningModal;
