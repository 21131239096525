import React, { useState } from "react";
import { Row, Col, Button, Typography, Dropdown, Menu, Tooltip } from "antd";
import Icon, { EllipsisOutlined } from "@ant-design/icons";
import Icons from "common/icons";
import { useQuery, useMutation } from "@apollo/client";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { filter, isEmpty, map, find, get } from "lodash";
import { UPDATE_WIDGETS_LAYOUT } from "graphql/mutations/widget/updateWidgetLayout";
import { GET_USER_WIDGETS } from "graphql/queries/widget/getUserWidgets";
import { customizeState } from "recoil/atoms/Dashboard/customize";
import { widgetsState } from "recoil/atoms/Dashboard/widgets";
import utility from "common/utility";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { WIDGETS } from "components/Widgets";
import { userSession } from "recoil/atoms/User/UserSession";
import CreateProjectModal from "components/Modals/CreateProjectModal";
import { defaultProjectFilters } from "common/constants";
import noProjectsImg from "common/assets/ConveyerBelt_Transparent-01.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";

const { Title } = Typography;

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
  const user = useRecoilValue(userSession);
  const currentMode = useRecoilValue(selectedMode);
  const location = useLocation();
  const [customize, setCustomize] = useRecoilState(customizeState);
  const [layoutState, setLayoutState] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [validWidgets, setValidWidgets] = useRecoilState(widgetsState);
  const withTour = queryString.parse(location?.search).withTour || false;
  const setTourValue = useSetRecoilState(tourSteps);

  const [updateWidgetLayout, { loading: updatingWidgets }] = useMutation(
    UPDATE_WIDGETS_LAYOUT,
    {
      onCompleted: () => {
        refetchGetUserWidgets();
        return utility.setNotification(
          "Success",
          `Layout Saved`,
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const {
    data: widgets,
    refetch: refetchGetUserWidgets,
    loading: loadingWidgets,
  } = useQuery(GET_USER_WIDGETS, {
    onCompleted: ({ getUserWidgets }) => {
      const validData = filter(getUserWidgets, function (data) {
        return WIDGETS[data?.widget?.name];
      });
      setValidWidgets((widgets) => {
        return { ...widgets, dashboard: validData };
      });
      if (withTour == "true") {
        setTourValue({
          step: 0,
          isAcitve: true,
        });
      }
    },
    variables: { mode: currentMode },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  React.useEffect(() => {
    refetchGetUserWidgets();
  }, [selectedMode]);

  const listWidgets = () => {
    return validWidgets?.dashboard?.map((data) => {
      const { x, y, w, h, _id, i } = data;
      return (
        <div key={i} data-grid={{ i, x, y, w, h, isResizable: false, _id }}>
          {WIDGETS[data?.widget?.name]}
        </div>
      );
    });
  };

  const handleCustomize = () => {
    if (customize && !isEmpty(layoutState)) {
      const reformatPayload = map(layoutState, (layout) => {
        const foundLayout = find(widgets.getUserWidgets, {
          widget: { name: layout.i },
        });
        return {
          _id: foundLayout?._id,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        };
      });
      updateWidgetLayout({ variables: { input: [...reformatPayload] } });
    }
    setCustomize(!customize);
  };

  const imgResponsive = {
    xxl: { span: 14 },
    xl: 12,
    lg: 12,
    xs: 12,
    md: 12,
    sm: 12,
  };

  const textResponsive = {
    xxl: { span: 6, offset: 4 },
    xl: { span: 6, offset: 4 },
    lg: { span: 8, offset: 4 },
    xs: 12,
    md: { span: 8, offset: 4 },
    sm: 12,
  };

  const menu = (
    <Menu className="py-0 pt-1">
      <Menu.Item
        className="py-4 flex items-center"
        onClick={() => setCustomize(!customize)}
        icon={<Icon component={Icons.Toggle} />}
      >
        Rearrange Widgets
      </Menu.Item>
    </Menu>
  );

  const loadWidgets = loadingWidgets;
  return (
    <>
      {user.nro_projects <= 0 && withTour != "true" ? (
        <Row className="flex h-full items-center">
          <Col {...textResponsive} className="block justify-center bg-red">
            <div className="block justify-center items-center text-center">
              <Title level={2} className="text-left">
                The time for action is now. It's never too late to start
                tracking.
              </Title>
            </div>
            <div className="block justify-center items-center text-center">
              <Title level={5} className="my-4 text-left">
                In order to start your first activity, you will first have to
                create a project.
              </Title>
            </div>
            <div className="flex items-center mt-5">
              <Button
                size="large"
                onClick={() => setIsVisible(true)}
                key="submit"
                type="primary"
              >
                Create project
              </Button>
            </div>
          </Col>
          <Col {...imgResponsive} className="flex justify-center bg-red">
            <img alt="windu invite client" src={noProjectsImg} />
          </Col>
        </Row>
      ) : (
        !loadWidgets && (
          <Row className="h-full">
            <Col span={24}>
              {currentMode !== "invest" && (
                <div className="flex items-center justify-start py-4 pl-5 tour-customizingLayout">
                  <Title level={4}>Dashboard</Title>
                  <div className="flex ml-4 mb-2">
                    <Tooltip title="Rearrange Widgets">
                      <Icon
                        component={Icons.Toggle}
                        onClick={() => setCustomize(!customize)}
                      />
                    </Tooltip>
                  </div>

                  {/* <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    arrow
                    className="ml-3"
                  >
                    <EllipsisOutlined
                      style={{ fontSize: 35 }}
                      className="cursor-pointer"
                    />
                  </Dropdown> */}

                  {customize && (
                    <>
                      <Button
                        className="ml-3"
                        onClick={handleCustomize}
                        type="primary"
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-3"
                        onClick={() => setCustomize(false)}
                        type="secondary"
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              )}

              <ResponsiveGridLayout
                draggableHandle=".drag-handle"
                onDragStop={(v) => setLayoutState(v)}
                className="layout"
                margin={[20, 25]}
                rowHeight={150}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 18, md: 18, sm: 18, xs: 12, xxs: 6 }}
                autoSize={false}
                layouts={{ lg: validWidgets?.dashboard }}
                isResizable={false}
              >
                {listWidgets()}
              </ResponsiveGridLayout>
            </Col>
          </Row>
        )
      )}
      <CreateProjectModal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        queryParameters={{
          size: 5,
          offset: 0,
          search: "",
          filters: defaultProjectFilters,
        }}
      />
    </>
  );
};

export default Dashboard;
