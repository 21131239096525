import gql from "graphql-tag";

export const CREATE_INVOICE_COPY = gql`
  mutation createInvoiceCopy($invoiceId: ID!, $workspaceId: ID!) {
    createInvoiceCopy(invoiceId: $invoiceId, workspaceId: $workspaceId) {
      _id
      code
      name
      period
      payment_due
      total_due
      sub_total
      currency
      sent_to
      sent_at
      issue_date
      items {
        description
        hours
        cost
      }
    }
  }
`;
