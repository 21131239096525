import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { instanceOf } from "prop-types";
import { useRecoilState, useSetRecoilState } from "recoil";
import moment from "moment";
import Cookies from "js-cookie";
import { get, map, first, lowerCase, isNil, isEmpty } from "lodash";
import utility from "common/utility";
import { setLogger } from "@optimizely/react-sdk";
import { wsClient } from "graphql/config/client";
import { GET_USER } from "graphql/queries/getUser";
import { REGISTER_DEVICE } from "graphql/mutations/device/registerDevice";
import { userSession } from "recoil/atoms/User/UserSession";
import { getProjects } from "recoil/atoms/Projects/getProjects";
import Loader from "components/Loader";
import { createGoogleConfig } from "api/GoogleAnalytics";
import { getFCMToken, deleteFCMToken } from "common/firebaseUtilities";
import Personalize from "views/Personalize";
import { selectedMode } from "recoil/atoms/Modes/selectedMode";
import Setting from "views/Setting";
import { Crisp } from "crisp-sdk-web";

setLogger(null);

const CrispMessagePreview = () => {
  Crisp.message.onMessageReceived((data) => {
    // Skip responses not being updates
    if (data.origin !== "update") {
      return;
    }

    // Skip messages types other than pickers
    if (data.type !== "picker") {
      return;
    }
    // Skip other ids currently we only have one picker
    if (data.content.id === "article-help-picker") {
      let selected_choice = data.content.choices.find(
        (choice) => choice.selected
      );
      if (selected_choice.value === "chat") {
        Crisp.session.pushEvent("agentsupport");
        Crisp.chat.show();
      } else if (selected_choice.value === "meeting") {
        Crisp.session.pushEvent("setmeeting");
      }

      return;
    } else {
      return;
    }
  });
};

const AppProvider = ({ children }) => {
  const [token, setToken] = React.useState(null);
  const [user, setUser] = useRecoilState(userSession);
  const setProjects = useSetRecoilState(getProjects);
  const setMode = useSetRecoilState(selectedMode);
  const mode = Cookies.get("mode");

  const { refetch } = useQuery(GET_USER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getUser }) => {
      setProjects(getUser?.projects);
      const addonsReferences = map(getUser?.addons, (addon) => addon.name);
      setUser({ ...getUser, addonsReferences });
      Crisp.setTokenId(getUser?._id);
      Crisp.user.setEmail(getUser?.email || "No email");
      Crisp.user.setNickname(getUser?.full_name || "No name");
      Crisp.load();
      CrispMessagePreview();

      const crispDataPayload = {
        id: getUser?._id,
        email: getUser?.email,
        name: getUser?.full_name,
        timezone: getUser?.timezone,
        nro_projects: getUser?.nro_projects,
        plan: getUser?.plan?.name,
        companyWorkers: getUser?.onboarding_data?.companyWorkers,
        teamMembers: getUser?.onboarding_data?.teamMembers,
      };
      Crisp.session.setData(crispDataPayload);

      if (
        getUser &&
        getUser.onboarding_data &&
        !isEmpty(getUser.onboarding_data.tools)
      ) {
        getUser.onboarding_data.tools.forEach((tool, index) => {
          crispDataPayload[`tool_${index + 1}`] = tool;
        });
      }

      if (!isEmpty(getUser?.settings?.mode)) {
        if (mode) {
          setMode(mode);
        } else {
          setMode(lowerCase(first(getUser?.settings?.mode)));
          Cookies.set("mode", lowerCase(first(getUser?.settings?.mode)));
        }
      }

      createGoogleConfig(getUser?._id);
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");
      if (errorMessage !== "you must be logged in to query this schema") {
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
        Cookies.remove("token");
        window.location.href = "/login";
      }
    },
  });

  const [registerDevice] = useMutation(REGISTER_DEVICE, {
    onCompleted: ({ registerDevice }) => {
      localStorage.setItem("FCMToken", registerDevice);
    },
    onError: () => {
      console.log("FCMToken registration error");
      // fcm unsubcribe
      deleteFCMToken();
    },
  });

  wsClient.on("reconnected", () => {
    const disconnected = localStorage.getItem("wsDisconnected");

    const msDiff = moment(moment(new Date()).format("MM/DD/YY HH:mm:ss")).diff(
      new Date(disconnected)
    );

    if (msDiff >= 10000) {
      refetch();
    }
  });

  React.useEffect(() => {
    if (Cookies.get("token")) {
      setToken(Cookies.get("token"));
    }
  }, [children]);

  React.useEffect(() => {
    // check if there is already a FCM token subcribe:
    if (!localStorage.getItem("FCMToken")) {
      // get token
      getFCMToken().then((token) => {
        registerDevice({
          variables: {
            input: { token, devicePlatform: "WEB" },
          },
        });
      });
    }
  }, []);

  if (!user) return <Loader size={"large"} />;

  if (user?.onboarding_data?.finished !== true)
    return <Personalize onSuccess={refetch} />;

  if (isNil(user?.plan?.name) && isEmpty(user?.extra?.foreignPlan?.plan)) {
    return <Setting forceView="subscriptions" />;
  }

  if (token) return React.cloneElement(children, { user });
};

AppProvider.propTypes = {
  children: instanceOf(Object),
};

export default AppProvider;
