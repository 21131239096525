import gql from "graphql-tag";

export const CREATE_TRELLO_ACTIVITY = gql`
  mutation ($input: createTrelloActivityInput) {
    createTrelloActivity(input: $input) {
      _id
      title
      description
    }
  }
`;
