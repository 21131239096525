import gql from "graphql-tag";

export const REMOVE_PROJECT = gql`
  mutation($input: projectInput) {
    removeProject(input: $input) {
      _id
      title
      description
      members {
        _id
      }
      created_at
      client {
        _id
      }
    }
  }
`;
