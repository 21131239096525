import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

import _ from "lodash";

const DuplicateActivites = ({ setVisibleView, visibleView, onClose }) => {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <Card
      title={"Duplicate Activity"}
      extra={
        visibleView === "new" ? (
          <DownOutlined onClick={() => setVisibleView("duplicate")} />
        ) : (
          <CloseOutlined onClick={handleOnClose} />
        )
      }
      className={visibleView === "new" ? styles.hideBodyStyle : "mb-5"}
    >
      {visibleView === "duplicate" && <div>Duplicate Activity details</div>}
    </Card>
  );
};

export default DuplicateActivites;
