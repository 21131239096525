import { Typography } from "antd";
import React from "react";

const Rally = () => {
  return (
    <div>
      <Typography.Title>Rally Page</Typography.Title>
    </div>
  );
};

export default Rally;
