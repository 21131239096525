import React, { useState, useCallback } from "react";
import { Field } from "formik";
import {
  AntInput,
  AntTextArea,
  AntSelect,
  AntItemField,
} from "components/FormikCustomInputs";
import { Button } from "antd";
import { map, isEmpty, debounce, trim, uniqBy } from "lodash";
import { useRecoilState } from "recoil";
import { useLazyQuery } from "@apollo/client";
import CreateSelectOption from "components/CreateSelectOption";
import { userSession } from "recoil/atoms/User/UserSession";
import Loader from "components/Loader";
import styles from "./styles.module.css";
import { CirclePicker } from "react-color";
import { GET_MEMBERS } from "graphql/queries/member/getMembers";

const sizePerPage = 6;
const initialOffset = 0;

const CreateProjectInfo = ({
  submitCount,
  setFieldValue,
  setProjectColor,
  isIndividualPlan,
}) => {
  const [user] = useRecoilState(userSession);
  const [filterSearchUser, setFilterSearchUser] = useState([]);
  const [newEmail, setNewEmail] = useState("");

  const handleAddEmail = () => {
    if (trim(newEmail)) {
      const data = {
        email: newEmail,
      };
      setFilterSearchUser(uniqBy([...filterSearchUser, data], "email"));
      setFieldValue("selectManager", newEmail);
    }
    setNewEmail("");
  };

  const handleAssignSelf = () => {
    setFieldValue("selectManager", user?.email);
  };

  const queryParameters = {
    size: sizePerPage,
    offset: initialOffset,
    search: "",
    project: "",
  };

  const [getMembers, { loading }] = useLazyQuery(GET_MEMBERS, {
    variables: { input: queryParameters },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getMembers }) => {
      setFilterSearchUser(getMembers.data);
    },
  });

  const handleSearch = useCallback(
    debounce(async (v) => {
      if (!isEmpty(v)) {
        getMembers({ variables: { input: { ...queryParameters, search: v } } });
      } else {
        setFilterSearchUser([]);
      }
    }, 300),
    []
  );
  return (
    <>
      <Field
        component={AntInput}
        name="title"
        label="Name of project"
        placeholder="Project A"
        submitCount={submitCount}
        hasFeedback
        required={true}
        size="large"
      />
      <Field
        component={AntTextArea}
        name="description"
        label="Project description"
        showCount
        maxLength={100}
        placeholder="Project description"
        submitCount={submitCount}
        hasFeedback
        required={true}
        size="large"
        rows={4}
      />
      {!isIndividualPlan && (
        <>
          <div className={styles.managerSelect}>
            <Field
              component={AntItemField}
              label="Select a Manager"
              required="true"
            />
            <Button
              style={{
                padding: "0px 0px 0px 12px",
                top: "-30px",
                marginBottom: "-10px",
              }}
              onClick={handleAssignSelf}
              type="link"
            >
              Assign myself
            </Button>
            <Field
              component={AntSelect}
              style={{ marginTop: "-10px" }}
              name="selectManager"
              placeholder="Select a Manager"
              submitCount={submitCount}
              selectOptions={map(filterSearchUser, (user) => {
                return { value: user.email, label: user.email };
              })}
              loading={loading}
              inputType="select"
              hasFeedback
              required={true}
              size="large"
              showSearch
              notFoundContent={
                loading ? (
                  <Loader size="small" />
                ) : (
                  <div className="flex justify-center">No Data</div>
                )
              }
              filterOption={false}
              onSearch={handleSearch}
              getPopupContainer={(node) => node.parentNode}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <CreateSelectOption
                    placeholder="Send invitation to unregistered email"
                    value={newEmail}
                    onChange={setNewEmail}
                    onClick={handleAddEmail}
                  />
                </div>
              )}
            />
          </div>
          <Field
            component={AntSelect}
            name="selectMembers"
            label="Add members"
            placeholder="Search by email"
            mode="tags"
            submitCount={submitCount}
            selectOptions={map(filterSearchUser, (user) => {
              return { value: user.email, label: user.email };
            })}
            loading={loading}
            inputType="select"
            hasFeedback
            size="large"
            showSearch
            notFoundContent={
              loading ? (
                <Loader size="small" />
              ) : (
                <div className="flex justify-center">No Data</div>
              )
            }
            filterOption={false}
            onSearch={handleSearch}
            getPopupContainer={(node) => node.parentNode}
            dropdownRender={(menu) => <div>{menu}</div>}
          />
        </>
      )}

      <div>
        <div style={{ marginLeft: "5px", marginTop: "15px" }}>
          <Field
            component={AntItemField}
            label="Set Project Color "
            style={{ marginBottom: "-20px" }}
          />
        </div>
        <CirclePicker
          onChange={(color) => setProjectColor(color.hex)}
          width="100%"
          colors={[
            "#f44336",
            "#9c27b0",
            "#2196f3",
            "#4caf50",
            "#ffd500",
            "#795548",
            "#607d8b",
            "#FFA500",
            "#673ab7",
            "#FFC0CB",
          ]}
        />
      </div>
    </>
  );
};

export default CreateProjectInfo;
