import { useMutation, useQuery } from "@apollo/client";
import { Card, Modal, Spin, Switch, Tag, Typography } from "antd";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import utility from "common/utility";
import { GET_ALL_WORKSAPCE_WIDGET } from "graphql/queries/widget/getAllWorksapceWidget";
import { TOGGLE_WORKSPACE_WIDGET } from "graphql/mutations/widget/toggleWorkspaceWidget";
import { LoadingOutlined } from "@ant-design/icons";

const WidgetToggle = ({ visible, setVisible, widgetRefetch }) => {
  const location = useLocation();
  const [allWidgets, setAllWidgets] = useState([]);
  const { id } = queryString.parse(location.search);

  const { data, refetch, loading } = useQuery(GET_ALL_WORKSAPCE_WIDGET, {
    notifyOnNetworkStatusChange: true,
    variables: { workspace: id },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getAllWorksapceWidget }) => {
      const groupByCategory = _.groupBy(
        getAllWorksapceWidget,
        "widget_category.name"
      );
      setAllWidgets(groupByCategory);
    },

    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
  });

  const [toggleWidget, { loading: toggleLoading }] = useMutation(
    TOGGLE_WORKSPACE_WIDGET,
    {
      onCompleted: ({ toggleWorkspaceWidget }) => {
        refetch({ variables: { workspace: id } });
        widgetRefetch(toggleWorkspaceWidget);
        utility.setNotification(
          "Widget toggled successfully",
          "Widget toggled successfully",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const toogleWidgetSwitch = (v, name) => {
    toggleWidget({
      variables: { input: { name: name, workspace: id } },
    });
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={900}
      title="Widget Management"
    >
      <Spin
        spinning={loading || toggleLoading}
        size="large"
        indicator={<LoadingOutlined spin />}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {_.map(allWidgets, (item, index) => {
            return (
              <div style={{ width: "400px" }} key={index}>
                <Card title={index}>
                  {_.map(item, (option, index) => {
                    return (
                      <div
                        className="flex justify-between mx-2 text-base mt-1 mb-3"
                        key={index}
                      >
                        <Typography.Text strong>
                          {option.label}
                          {!option.widget_category.is_released && (
                            <Tag className="ml-3" color="gold">
                              Coming Soon
                            </Tag>
                          )}
                        </Typography.Text>
                        <Switch
                          disabled={!option.widget_category.is_released}
                          onChange={(v) => toogleWidgetSwitch(v, option.name)}
                          defaultChecked={option?.isInWorkspace}
                        />
                      </div>
                    );
                  })}
                </Card>
              </div>
            );
          })}
        </div>
      </Spin>
    </Modal>
  );
};

export default WidgetToggle;
