import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import moment from "moment";
const { Text } = Typography;

const CustomTimePicker = ({ setFieldValue }) => {
  const [hours, sethours] = useState(0);
  const [minutes, setminutes] = useState(0);

  const handleHourIncrease = () => {
    sethours(hours + 1);
  };
  const handleHourDecrease = () => {
    if (hours > 0) {
      sethours(hours - 1);
    }
  };
  const handleMinutesIncrease = () => {
    if (minutes >= 55) {
      setminutes(0);
    } else {
      setminutes(minutes + 5);
    }
  };
  const handleMinutesDecrease = () => {
    if (minutes <= 0) {
      setminutes(55);
    } else {
      setminutes(minutes - 5);
    }
  };

  useEffect(() => {
    let date = moment();
    date.add(hours, "hours");
    date.add(minutes, "minutes");
    date.add("00", "secpmds");
    setFieldValue("time", date.format("MM/DD/YY HH:mm:ss"));
    setFieldValue("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [minutes, hours, setFieldValue]);

  return (
    <div className={styles.windu_custom_time_picker}>
      <div className="flex flex-col items-center w-14 ">
        <UpOutlined onClick={handleHourIncrease} />
        <div>
          <Text className="font-semibold">{hours}</Text> hrs
        </div>
        <DownOutlined onClick={handleHourDecrease} />
      </div>
      <div className="flex flex-col items-center ml-4 w-14">
        <UpOutlined onClick={handleMinutesIncrease} />
        <div>
          <Text className="font-semibold w-12">{minutes}</Text> mins
        </div>
        <DownOutlined onClick={handleMinutesDecrease} />
      </div>
    </div>
  );
};

export default CustomTimePicker;
