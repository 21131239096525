import React, { useEffect, useState } from "react";
import { isEmpty, find, last, isNil, filter, map, includes } from "lodash";
import { workspacesList } from "recoil/atoms/Workspaces";
import { useRecoilValue } from "recoil";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { CalendarBlank, X } from "@phosphor-icons/react";

import styles from "./styles.module.less";
import { Typography } from "antd";

const Tab = ({ data }) => {
  const location = useLocation();
  const history = useHistory();
  const workspaceData = useRecoilValue(workspacesList);

  const { id } = queryString.parse(location.search);
  const activeState = id === data?._id;

  const handleClose = (id) => {
    const workspaceFromLocalStorage = JSON.parse(
      localStorage.getItem("wstabs")
    );
    const updatedTabs = workspaceFromLocalStorage.filter((ws) => ws !== id);
    const availableWorkspace = map(workspaceData, (item) => item?._id);
    const refactorWorkspace = filter(updatedTabs, (item) => {
      if (item === "calendar") return true;
      else if (includes(availableWorkspace, item)) {
        return true;
      }
    });

    localStorage.setItem("wstabs", JSON.stringify(refactorWorkspace));
    const nextTabAfterRemove = last(refactorWorkspace);
    if (!isEmpty(refactorWorkspace)) {
      if (nextTabAfterRemove === "calendar") {
        history.push({
          pathname: `/calendar`,
        });
      } else {
        history.push({
          pathname: `/workspace`,
          search: `?id=${nextTabAfterRemove}`,
        });
      }
    } else {
      history.push({
        pathname: `/`,
      });
    }
  };

  return (
    <div
      style={{
        background: activeState ? "white" : "#EBEAF4",
        borderTop: `5px solid ${
          data?.project?.color === "none" ? "#808080" : data?.project?.color
        }`,
        display: "flex",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <div
        className={styles.tab}
        onClick={() => history.push(`/workspace?id=${data?._id}`)}
      >
        {data?.project?.title}
      </div>
      <X
        onClick={() => handleClose(data?._id)}
        className={`${styles.close} ml-5`}
        size={15}
      />
    </div>
  );
};
const CalendarTab = () => {
  const location = useLocation();
  const history = useHistory();
  const workspaceData = useRecoilValue(workspacesList);

  const activeState = location.pathname === "/calendar";

  const handleClose = (id) => {
    const updatedTabs = JSON.parse(localStorage.getItem("wstabs")).filter(
      (ws) => ws !== "calendar"
    );
    const availableWorkspace = map(workspaceData, (item) => item?._id);
    const refactorWorkspace = filter(updatedTabs, (item) => {
      if (includes(availableWorkspace, item)) {
        return true;
      }
    });

    localStorage.setItem("wstabs", JSON.stringify(refactorWorkspace));
    const nextTabAfterRemove = last(refactorWorkspace);
    if (!isEmpty(refactorWorkspace)) {
      history.push({
        pathname: `/workspace`,
        search: `?id=${nextTabAfterRemove}`,
      });
    } else {
      history.push({
        pathname: `/`,
      });
    }
  };

  return (
    <div
      style={{
        background: activeState ? "white" : "#EBEAF4",
        borderTop: `5px solid #604BC6`,
        display: "flex",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <div
        className={` ${styles.tab} flex items-center`}
        onClick={() => history.push(`/calendar`)}
      >
        <CalendarBlank size={24} color="#604BC6" />
        <Typography.Text className="ml-2">Calendar</Typography.Text>
      </div>
      <X
        onClick={() => handleClose("calendar")}
        className={`${styles.close} ml-5`}
        size={15}
      />
    </div>
  );
};

const WorkspaceTabs = () => {
  const workspaces = useRecoilValue(workspacesList);
  const data = localStorage.getItem("wstabs");

  if (isEmpty(JSON.parse(data))) return null;

  const showTabs = JSON.parse(data)?.map((ws) => {
    if (ws === "calendar") {
      return <CalendarTab />;
    } else {
      const findWorkspace = find(workspaces, ["_id", ws]);
      if (findWorkspace) {
        return <Tab data={findWorkspace} />;
      }
    }
  });

  return (
    <div
      style={{
        height: 40,
        background: "#C4C0DF",
        display: "flex",
        overflowY: "hidden",
        overflowX: "scroll",
      }}
    >
      {showTabs}
    </div>
  );
};

export default WorkspaceTabs;
