import React, { useState } from "react";
import { Row, Typography, Col, Button, Badge, Tag, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import ActivitiesList from "./ActivitiesList";
import { useRecoilState, useRecoilValue } from "recoil";
import { timelineDrawer } from "recoil/atoms/Timeline/QueryParams";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { map, isEmpty, size, isArray } from "lodash";
import { userSession } from "recoil/atoms/User/UserSession";

const { Title, Text } = Typography;

const TimeLine = ({ location, history }) => {
  const user = useRecoilValue(userSession);
  const [_, setShowTimelineFilters] = useRecoilState(timelineDrawer);
  const [showPauses, setShowPauses] = useState(false);
  const [hideEmptyDays, setHideEmptyDays] = useState(false);

  const {
    start_date,
    end_date,
    projectLabel,
    projectId,
    memberId,
    memberName,
    projectColor,
  } = queryString.parse(location.search);

  const query = {
    start_date,
    end_date,
    projectId,
    projectLabel,
    memberId,
    memberName,
    projectColor,
  };

  const clearProjectFilter = () => {
    history.push({
      search: queryString.stringify({
        ...query,
        projectId: undefined,
        projectLabel: undefined,
        projectColor: undefined,
      }),
    });
  };

  const clearMemberFilter = () => {
    history.push({
      search: queryString.stringify({
        ...query,
        memberId: undefined,
        memberName: undefined,
      }),
    });
  };

  const handleShowPauses = ({ target }) => {
    const { checked = false } = target;
    localStorage.setItem("showPauses", checked);
    setShowPauses(checked);
  };

  const handleHideEmptyDays = ({ target }, e) => {
    setHideEmptyDays(!hideEmptyDays);
  };

  React.useEffect(() => {
    const showPauses = localStorage.getItem("showPauses") === "true";
    setShowPauses(showPauses);
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col xs={13}>
          <div className="flex items-center">
            <div>
              <Title level={4}>Timeline of activities</Title>
              <div className="mt-1">
                {projectLabel && (
                  <Tag
                    closable
                    color={projectColor}
                    onClose={clearProjectFilter}
                  >
                    {projectLabel}
                  </Tag>
                )}

                {memberName && (
                  <div>
                    {isArray(memberName) ? (
                      map(memberName, (name) => {
                        return (
                          <Tag closable onClose={clearMemberFilter} key={name}>
                            {name}
                          </Tag>
                        );
                      })
                    ) : (
                      <Tag closable onClose={clearMemberFilter}>
                        {memberName}
                      </Tag>
                    )}
                  </div>
                )}
              </div>
            </div>

            <Button
              className="flex justify-center items-center ml-4"
              icon={<FilterOutlined />}
              size="large"
              onClick={() => setShowTimelineFilters(true)}
            >
              Filters
            </Button>
            {start_date && end_date && (
              <Text className="ml-3 text-lg" type="secondary">
                {`${moment(start_date).format("MM/DD/YY")}  -
              ${moment(end_date).format("MM/DD/YY")}`}
              </Text>
            )}
          </div>
        </Col>

        <Col xs={7} className="text-right">
          <Checkbox value={hideEmptyDays} onChange={handleHideEmptyDays}>
            Hide Empty Days
          </Checkbox>
          <Checkbox onChange={handleShowPauses} checked={showPauses}>
            Show Pauses
          </Checkbox>
        </Col>
        <Col xs={4}>
          <div className="flex items-center justify-around">
            <Badge color="#4E35C2" className="badge-timeline" text="Active" />
            <Badge color="#F5A623" className="badge-timeline" text="Pause" />
          </div>
        </Col>
      </Row>
      <ActivitiesList hideEmptyDays={hideEmptyDays} />
    </>
  );
};

export default withRouter(TimeLine);
