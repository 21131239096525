import React from "react";
import {
  Card,
  Input,
  Button,
  Form,
  Typography,
  Avatar,
  Tooltip,
  Spin,
  Upload,
  Badge,
  Progress,
  Image,
} from "antd";
import Icon, {
  LoadingOutlined,
  LinkOutlined,
  CloseCircleFilled,
  SendOutlined,
  SmileOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Icons from "common/icons";
import {
  useLazyQuery,
  useMutation,
  useSubscription,
  useApolloClient,
} from "@apollo/client";
import { useRecoilValue } from "recoil";
import Picker from "emoji-picker-react";
import Message from "components/ChatContent/Message";
import InfiniteScroll from "react-infinite-scroll-component";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { GET_FEED_CHAT } from "graphql/queries/message/getFeedChat";
import { NEW_MESSAGE } from "graphql/subscription/message/newMessage";
import { UPDATE_MESSAGE } from "graphql/subscription/message/updateMessage";
import { REMOVE_MESSAGE } from "graphql/subscription/message/removeMessage";
import { SEND_MESSAGE } from "graphql/mutations/message/sendMessage";
import { CREATE_CHAT } from "graphql/mutations/message/createChat";
import { GET_WORKSPACES_MESSAGES } from "graphql/queries/message/getWorkspacesMessages";
import { MARK_AS_READ } from "graphql/mutations/message/markAsReadMessages";
import { uploadChatFiles } from "api/ChatUpload";
import { userSession } from "recoil/atoms/User/UserSession";
import UserAvatar from "components/UserAvatar";
import _ from "lodash";
import Loader from "components/Loader";
import cx from "classnames";
import styles from "./styles.module.css";

const { Dragger } = Upload;
const limit = 50;
const page = 0;
const imgExtension = ["image/jpeg", "image/png"];

const fileIcons = {
  "application/pdf": Icons.PDF,
  "text/plain": Icons.TXT,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    Icons.DOC,
};

const Groupchat = () => {
  const user = useRecoilValue(userSession);
  const [newMessage, setNewMessage] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [isPickerVisible, setIsPickerVisible] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const workspace = useRecoilValue(workspaceSelected);
  const [groupChat, setGroupChat] = React.useState(null);
  const client = useApolloClient();
  const messagesEndRef = React.useRef(null);

  const [getFeedChat, { data, loading, refetch }] = useLazyQuery(
    GET_FEED_CHAT,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: async ({ getFeedChat }) => {
        let messagesObj = {};
        if (!_.isEmpty(groupChat)) {
          const gatherMessages = _.unionBy(
            groupChat.messages,
            getFeedChat.messages,
            "_id"
          );

          messagesObj = {
            ...groupChat,
            messages: gatherMessages,
          };
        } else {
          messagesObj = {
            chatId: getFeedChat._id,
            messages: getFeedChat.messages,
            participants: getFeedChat.participants,
          };
        }

        setGroupChat(messagesObj);
        // update the topbar messages indicator
        await client.refetchQueries({
          include: [GET_WORKSPACES_MESSAGES],
        });
      },
    }
  );

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const { data: messageData, error: messageError } =
    useSubscription(NEW_MESSAGE);

  const { data: updateMessageData, error: updateMessageError } =
    useSubscription(UPDATE_MESSAGE);

  const { data: removeMessageData, error: removeMessageError } =
    useSubscription(REMOVE_MESSAGE);

  const [sendMessage, { loading: sending }] = useMutation(SEND_MESSAGE, {
    onCompleted: () => {
      setNewMessage("");
      setFiles([]);

      scrollToBottom();
    },
  });

  const [createChat, { loading: creating }] = useMutation(CREATE_CHAT);
  const [markRead] = useMutation(MARK_AS_READ);

  const addTeamChatMessage = () => {
    const { newMessage } = messageData;
    // if the message received is part of the team chat we add it to the state

    if (groupChat.chatId === newMessage.chatId) {
      const messagesObj = {
        ...groupChat,
        messages: [
          {
            _id: newMessage._id,
            content: newMessage.content,
            from: newMessage.from,
            created_at: newMessage.created_at,
            unread: newMessage.unread,
            fileSources: newMessage.fileSources,
          },
          ...groupChat.messages,
        ],
      };

      setGroupChat(messagesObj);
      if (newMessage.from._id !== user._id) {
        markRead({
          variables: {
            input: {
              messageId: newMessage._id,
            },
          },
        });
      }
    }
  };

  const handleUpdateMessage = () => {
    const { updateMessage } = updateMessageData;
    // if the message received is part of the team chat we add it to the state

    if (groupChat.chatId === updateMessage.chatId) {
      const index = _.findIndex(
        groupChat.messages,
        (message) => message._id === updateMessage._id
      );

      if (index >= 0) {
        const chatCopy = _.cloneDeep(groupChat);

        chatCopy.messages[index] = {
          _id: updateMessage._id,
          content: updateMessage.content,
          from: updateMessage.from,
          created_at: updateMessage.created_at,
          unread: updateMessage.unread,
          fileSources: updateMessage.fileSources,
          edited: updateMessage.edited,
        };

        setGroupChat(chatCopy);
      }
    }
  };

  const handleRemoveMessage = () => {
    const { removeMessage } = removeMessageData;
    // if the message received is part of the team chat we add it to the state

    if (groupChat.chatId === removeMessage.chatId) {
      const index = _.findIndex(
        groupChat.messages,
        (message) => message._id === removeMessage._id
      );

      if (index >= 0) {
        const chatCopy = _.cloneDeep(groupChat);

        chatCopy.messages.splice(index, 1);
        setGroupChat(chatCopy);
      }
    }
  };

  const removeFileList = (position) => {
    const copy = _.cloneDeep(files);
    copy.splice(position, 1);
    setFiles(copy);
  };

  React.useEffect(() => {
    if (workspace) {
      setGroupChat(null);
      setNewMessage("");
      setFiles([]);
      getFeedChat({
        variables: {
          input: {
            limit: limit,
            page: page,
            workspaceId: workspace._id,
            type: "Team",
          },
        },
      });
    }
  }, [workspace]);

  React.useEffect(() => {
    if (messageError) console.warn(messageError);
    if (messageData) addTeamChatMessage();
  }, [messageError, messageData]);

  React.useEffect(() => {
    if (updateMessageError) console.warn(updateMessageError);
    if (updateMessageData) handleUpdateMessage();
  }, [updateMessageError, updateMessageData]);

  React.useEffect(() => {
    if (removeMessageError) console.warn(removeMessageError);
    if (removeMessageData) handleRemoveMessage();
  }, [removeMessageError, removeMessageData]);

  const loadMore = () => {
    const nextPage = data?.getFeedChat.currentPage + 1;

    getFeedChat({
      variables: {
        input: {
          limit: limit,
          page: nextPage,
          workspaceId: workspace._id,
          type: "Team",
        },
      },
    });
  };

  const getFileImg = ({ type }) => {
    return fileIcons[type];
  };

  const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      const extensionsAllowed = [...imgExtension, "application/pdf"];

      const filesCopied = e.clipboardData.files;

      const filesFiltered = _.filter(filesCopied, (file) =>
        extensionsAllowed.includes(file.type)
      );

      const listParsed = _.map(filesFiltered, (file, index) => {
        let preview = "";

        if (imgExtension.includes(file.type)) {
          preview = URL.createObjectURL(file);
        }

        return {
          uid: index,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          size: file.size,
          type: file.type,
          webkitRelativePath: file.webkitRelativePath,
          originFileObj: file,
          preview,
        };
      });

      setFiles([...files, ...listParsed]);
    }
  };

  const onEmojiClick = (_, emojiObject) => {
    setNewMessage(`${newMessage}${emojiObject.emoji}`);
    setIsPickerVisible(false);
  };

  const onSend = async () => {
    let filesObj = [];

    if (_.isEmpty(newMessage) && _.size(files) === 0) return;
    if (_.isEmpty(groupChat)) return;
    if (_.isEmpty(groupChat.chatId)) {
      createChat({
        variables: {
          input: {
            participants: _.map(groupChat.participants, (user) => user._id),
            type: "Team",
            workspaceId: workspace._id,
          },
        },
      }).then(async ({ data }) => {
        setGroupChat({ ...groupChat, chatId: data.createChat._id });

        // if there are files attached we upload them before sending the message
        if (files.length > 0) {
          filesObj = await uploadChatFiles({
            chatId: groupChat.chatId,
            fileList: files,
            updateProgress: setProgress,
          });
          setProgress(0);
        }
        sendMessage({
          variables: {
            input: {
              chatId: data.createChat._id,
              content: newMessage,
              fileSources: _.map(filesObj, (file) => file.Location),
            },
          },
        });
      });
    } else {
      if (files.length > 0) {
        filesObj = await uploadChatFiles({
          chatId: groupChat.chatId,
          fileList: files,
          updateProgress: setProgress,
        });
        setProgress(0);
      }

      sendMessage({
        variables: {
          input: {
            chatId: groupChat.chatId,
            content: newMessage,
            fileSources: _.map(filesObj, (file) => file.Location),
          },
        },
      });
    }
  };

  const hasMessages = !_.isEmpty(groupChat?.messages);

  const uploadProps = {
    fileList: files,
    name: "file",
    multiple: true,
    showUploadList: false,
    accept: ".txt, .doc, .docx, .jpg, .jpeg, .png, .pdf",
    beforeUpload: () => false,
    onChange({ fileList }) {
      const listParsed = _.map(fileList, (file) => {
        let preview = "";
        if (imgExtension.includes(file.type)) {
          preview = URL.createObjectURL(file.originFileObj);
        }

        return {
          ...file,
          preview,
        };
      });

      setFiles(listParsed);
    },
  };

  return (
    <div style={{ width: "92%" }}>
      <Card
        className={styles.chatContainer}
        extra={
          <>
            {_.size(groupChat?.participants) === 1 ? (
              <UserAvatar user={groupChat?.participants[0]} size="large" />
            ) : (
              <Avatar.Group
                maxCount={
                  _.size(groupChat?.participants) > 4
                    ? 4
                    : _.size(groupChat?.participants)
                }
              >
                {_.map(groupChat?.participants, (participant) => (
                  <Tooltip
                    title={participant.email}
                    placement="top"
                    key={participant._id}
                  >
                    <UserAvatar user={participant} />
                  </Tooltip>
                ))}
              </Avatar.Group>
            )}
          </>
        }
        title={
          <div className="flex items-center">
            <Typography.Title level={5} className='mr-3'>
              {workspace?.project?.title}
            </Typography.Title>
            <Button
              className={styles.iconContainer}
              icon={<SyncOutlined />}
              size="medium"
              onClick={() => refetch()}
            />
          </div>
        }
        headStyle={{ color: "#3C2E94", background: "#FBFBFC" }}
        actions={[
          <>
            {progress > 0 && (
              <Progress
                strokeLinecap="square"
                percent={progress}
                className="px-2"
              />
            )}

            <Form onFinish={onSend}>
              <Form.Item className="mb-1 px-2">
                <Dragger
                  {...uploadProps}
                  openFileDialogOnClick={false}
                  className={`${styles.draggerContainer}`}
                >
                  <div onPaste={(e) => handlePaste(e)}>
                    <Input
                      className="mt-3 break-words"
                      placeholder="Send a message"
                      bordered={false}
                      value={newMessage}
                      style={{ minHeight: "50px", marginTop: 0 }}
                      onChange={({ target }) => setNewMessage(target.value)}
                    />
                  </div>
                </Dragger>
              </Form.Item>
              <Form.Item className="mb-2 px-3">
                <div className="flex items-center justify-between h-full">
                  <div className="mx-3 flex items-center">
                    {_.map(files, (file, index) => {
                      if (!imgExtension.includes(file.type)) {
                        return (
                          <Badge
                            key={file.uid}
                            count={
                              <CloseCircleFilled
                                className="icon-small cursor-pointer bg-white rounded-full"
                                onClick={() => removeFileList(index)}
                              />
                            }
                            offset={[-5, 0]}
                          >
                            <Card
                              size="small"
                              style={{ boxShadow: "none" }}
                              className="bg-gray-100"
                            >
                              <div className="flex justify-center items-center">
                                <Icon
                                  component={getFileImg({ type: file.type })}
                                  className="icon-large mr-2"
                                />
                                <p>{_.truncate(file.name, { length: 20 })}</p>
                              </div>
                            </Card>
                          </Badge>
                        );
                      }
                      return (
                        <Badge
                          key={file.uid}
                          count={
                            <CloseCircleFilled
                              className="icon-small cursor-pointer bg-white rounded-full"
                              onClick={() => removeFileList(index)}
                            />
                          }
                          offset={[-5, 0]}
                        >
                          <Image src={file.preview} width={100} height={80} />
                        </Badge>
                      );
                    })}
                  </div>
                  <div className="flex items-center">
                    {isPickerVisible && (
                      <Picker
                        native={true}
                        onEmojiClick={onEmojiClick}
                        pickerStyle={{
                          position: "absolute",
                          bottom: "50px",
                          right: "70px",
                        }}
                      />
                    )}

                    <SmileOutlined
                      className="cursor-pointer icon mr-3"
                      onClick={() => setIsPickerVisible(!isPickerVisible)}
                    />
                    <Upload {...uploadProps} fileList={[]} className="mr-3">
                      <LinkOutlined className="icon" />
                    </Upload>
                    <Button
                      className="flex justify-center items-center"
                      size="large"
                      type="primary"
                      shape="circle"
                      onClick={() => onSend()}
                      icon={<SendOutlined className="ml-1 icon" />}
                    ></Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </>,
        ]}
      >
        {_.isEmpty(groupChat?.chatId) && loading ? (
          <div style={{ height: "calc(100vh - 440px)" }}>
            <Loader size="large" />
          </div>
        ) : (
          <div
            id="scrollableDiv"
            className={cx("overflow-y-auto flex px-2", {
              "flex-col-reverse": hasMessages,
              "justify-center": !hasMessages,
              "items-center": !hasMessages,
            })}
            style={{ height: "calc(100vh - 440px)" }}
          >
            <InfiniteScroll
              dataLength={_.size(groupChat?.messages)}
              next={() => loadMore()}
              inverse={true}
              hasMore={!_.isEmpty(data?.getFeedChat?.messages)}
              loader={loading && <Loader size="large" />}
              className="flex flex-col-reverse"
              scrollableTarget="scrollableDiv"
              scrollThreshold={"100%"}
              initialScrollY={0}
            >
              <div ref={messagesEndRef} />
              {creating ||
                (sending && (
                  <div className="m-3 flex">
                    <Typography.Text className="mr-3">
                      sending...
                    </Typography.Text>
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 16 }} spin />
                      }
                    />
                  </div>
                ))}
              {_.map(groupChat?.messages, (message, index) => {
                const withAvatar =
                  message.from.email !==
                  groupChat.messages[index + 1]?.from.email;

                return (
                  <Message
                    message={message}
                    key={message._id}
                    userInfo={message.from}
                    withAvatar={withAvatar}
                    messageType="group"
                    userRemoved={
                      !_.some(groupChat.participants, {
                        email: message.from.email,
                      })
                    }
                  />
                );
              })}
            </InfiniteScroll>
            {_.isEmpty(groupChat?.messages) &&
              !sending &&
              `You have 0 messages, send your first message!`}
          </div>
        )}
      </Card>
    </div>
  );
};

export default Groupchat;
