import gql from "graphql-tag";

export const GET_REQUESTS = gql`
  query {
    getRequests {
      _id
      status
      title
      description
      invitee
      created_at
      updated_at
      type
      sentAt
      project{
        title
      }
      inviter {
        _id
        email
      }
    }
  }
`;
