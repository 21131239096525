import React, { useState } from "react";
import { GET_CALENDAR_PROJECTS } from "graphql/queries/Google/getCalendarProjects";
import {
  compact,
  pickBy,
  groupBy,
  map,
  flatten,
  has,
  filter,
  sortBy,
  isEmpty,
  get,
} from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { listCalendarEvents } from "api/Google";
import {
  Tag,
  Collapse,
  List,
  Skeleton,
  Typography,
  Divider,
  Popconfirm,
} from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import Loader from "components/Loader";
import moment from "moment";
import EmptyData from "../../EmptyData";
import { returnEvents, getHeader } from "./utils";
import styles from "./styles.module.css";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { useRecoilState } from "recoil";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { useTimer } from "contexts/timer-context";
import {
  updateRecentActivities,
  updateContinueActivityList,
} from "common/cacheUtilities";
import utility from "common/utility";

const { Title, Text, Link } = Typography;
const { Panel } = Collapse;

const Upcoming = ({ token, view, onRejected, id }) => {
  const [collapse, setCollapse] = useState(["google", "zoom"]);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [activity] = useRecoilState(activeActivity);
  const { resetHandler, startHandler } = useTimer();

  useQuery(GET_CALENDAR_PROJECTS, {
    variables: { input: { workspace: id } },
    fetchPolicy: "network-only",
    onCompleted: ({ getCalendarProjects }) => {
      const calendarIds = map(
        getCalendarProjects?.calendar_project_subscription,
        (e) => {
          return e?.calendar;
        }
      );
      setEventsLoading(true);
      Promise.all(
        map(calendarIds, (cal) => {
          return listCalendarEvents(cal, token)
            .then((response) => {
              const data = response.data.items;
              const filterMeetings = returnEvents({
                data,
                getCalendarProjects,
                cal,
              });
              return filterMeetings;
            })
            .catch((error) => {
              onRejected();
              setEvents([]);
            });
        })
      )
        .then((values) => {
          const sortFlattenedDates = sortBy(flatten(values), (e) => {
            return new Date(e?.start?.dateTime);
          });
          const groupedEvents = groupBy(sortFlattenedDates, (event) => {
            const hasZoomLink = event?.location
              ?.toLowerCase()
              ?.includes("zoom");
            const hasHangoutLink = has(event, "hangoutLink");
            const hasMicrosoftTeams = event?.location
              ?.toLowerCase()
              ?.includes("microsoft");

            if (hasZoomLink) return "zoom";
            if (hasMicrosoftTeams) return "microsoft";
            if (hasHangoutLink) return "google";
          });

          const nullCheck = pickBy(groupedEvents, (value) => {
            return !value.includes(undefined);
          });

          setEventsLoading(false);
          setEvents(nullCheck);
        })
        .catch(() => {
          setEvents([]);
        });
    },

    onError: (error) => {},
  });

  const [pauseActivity, { loading: pausing }] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: ({ pauseActivity }) => {
      utility.setNotification(
        `${pauseActivity?.title} has been paused`,
        "",
        "success",
        "topRight"
      );
      resetHandler();
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    update: (cache, { data: { pauseActivity } }) => {
      updateRecentActivities(cache, pauseActivity, "paused");
      updateContinueActivityList(cache, pauseActivity, "paused");
    },
  });

  const eventTypes = Object.keys(events);

  const getJoinLink = (item, eventType) => {
    if (eventType === "google") {
      return item?.hangoutLink;
    }
    if (eventType === "microsoft") {
      var snippet = document.createElement("html");
      snippet.innerHTML = item?.description;
      var links = snippet.getElementsByTagName("a");
      var meetingLink = links[links.length - 1].href;
      return meetingLink;
    }
    if (eventType === "zoom") {
      return item?.location;
    }
  };

  const joinMeetingHandler = (joinLink) => {
    window.open(joinLink, "_blank");
    const time = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  if (isEmpty(eventTypes)) return <EmptyData />;
  return (
    <Collapse
      expandIconPosition="right"
      defaultActiveKey={["google", "zoom"]}
      ///  onChange={(key) => setCollapse([...collapse, key])}
      bordered={false}
      ghost={true}
      className={styles.collapse}
    >
      {eventTypes?.map((eventType) => {
        return (
          events[eventType] && (
            <>
              <Panel
                showArrow={false}
                header={
                  <div
                    onClick={() =>
                      setCollapse((collapsedAlready) => {
                        if (collapsedAlready.includes(eventType)) {
                          const stillActive = filter(
                            collapsedAlready,
                            (e) => e !== eventType
                          );
                          return stillActive;
                        }

                        return [...collapsedAlready, eventType];
                      })
                    }
                    style={{
                      background: "#F7F7FC",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    className="p-5"
                  >
                    <div className="flex">
                      {getHeader(eventType)}
                      <Tag className="ml-3" color="purple">
                        {events[eventType]?.length}
                      </Tag>
                      <div></div>
                    </div>
                    {collapse.includes(eventType) ? (
                      <CaretUpOutlined style={{ color: "#7433FF" }} />
                    ) : (
                      <CaretDownOutlined style={{ color: "#7433FF" }} />
                    )}
                  </div>
                }
                key={eventType}
              >
                <List
                  className="demo-loadmore-list"
                  loading={{ spinning: eventsLoading, indicator: <Loader /> }}
                  itemLayout="horizontal"
                  dataSource={compact(events[eventType])}
                  renderItem={(item) => {
                    const joinLink = getJoinLink(item, eventType);
                    return (
                      <List.Item>
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            avatar={
                              <>
                                <Text style={{ color: "#967DDE" }}>
                                  {moment(item.start.dateTime).format("MMMM")}
                                </Text>
                                <div>
                                  {moment(item.start.dateTime).format("DD")}
                                </div>
                              </>
                            }
                            title={
                              activity?.active ? (
                                <Popconfirm
                                  title={
                                    <div style={{ maxWidth: 420 }}>
                                      <Text className=" text-base">
                                        You currently have a active timer
                                        running, continuing to this meeting will
                                        pause it.
                                      </Text>
                                    </div>
                                  }
                                  onConfirm={() => joinMeetingHandler(joinLink)}
                                  okText="Yes"
                                  cancelText="No"
                                  className="cursor-pointer"
                                >
                                  <Title level={5}>{item.summary}</Title>
                                </Popconfirm>
                              ) : (
                                <Link href={joinLink} target="_blank">
                                  <Title level={5}>{item.summary}</Title>
                                </Link>
                              )
                            }
                            description={<Text>{item?.project?.title}</Text>}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "150px",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text style={{ color: "#D1D6DF" }}>From</Text>
                              <Text>
                                <strong>
                                  {moment(item?.start?.dateTime).format(
                                    "hh:mm A"
                                  )}
                                </strong>
                              </Text>
                            </div>
                            <Divider
                              style={{ borderColor: "#F5A623", height: "auto" }}
                              dashed
                              type="vertical"
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text style={{ color: "#D1D6DF" }}>To</Text>
                              <Text>
                                <strong>
                                  {moment(item?.end?.dateTime).format(
                                    "hh:mm A"
                                  )}
                                </strong>
                              </Text>
                            </div>
                          </div>
                        </Skeleton>
                      </List.Item>
                    );
                  }}
                />
              </Panel>
              <Divider />
            </>
          )
        );
      })}
    </Collapse>
  );
};
export default Upcoming;
