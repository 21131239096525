import gql from "graphql-tag";

export const GET_FIGMA_WINDU_USER = gql`
  query ($id: String!) {
    getUserByFigmaPlugin(id: $id) {
      token
      user {
        _id
        email
        full_name
      }
    }
  }
`;
