import React, { useState } from "react";
import { Typography, Row, Col, Button } from "antd";
import GoogleLoginBtn from "views/Onboard/GoogleLogin";
import { CalendarOutlined } from "@ant-design/icons";

import Card from "../Card";
import ConnectModal from "components/Modals/ConnectModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import styles from "../styles.module.css";

const { Text } = Typography;

const Auth = ({ handleGoogleLogin, setAuthenticated }) => {
  const [connectModal, setConnectModal] = useState(false);
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const GoogleButton = () => {
    return (
      <GoogleLoginBtn
        responseType="code"
        accessType="offline"
        prompt="consent"
        text="Connect"
        onSuccess={handleGoogleLogin}
        scope="https://www.googleapis.com/auth/calendar.readonly"
      />
    );
  };
  return (
    <Card style={{ height: "140px" }} title="Your Meetings">
      <Row>
        <Col md={2}>
          <CalendarOutlined
            style={{ color: "#4F3CBF", fontSize: "25px", width: "100%" }}
          />
        </Col>
        <Col md={15}>
          <div style={{ width: "80%", margin: "auto" }}>
            <Text>
              Connect your google calendar and track all your meetings
            </Text>
          </div>
        </Col>
        <Col md={7}>
          <Button
            onClick={() => setConnectModal({ label: "Google", workspace: id })}
            type="primary"
            size="large"
            color="#029875"
            className={styles.connectBtn + " px-7 w-full"}
          >
            Connect
          </Button>
        </Col>
      </Row>
      {connectModal && (
        <ConnectModal
          visible={connectModal}
          setVisible={setConnectModal}
          GoogleButton={GoogleButton}
          setAuthenticated={setAuthenticated}
        />
      )}
    </Card>
  );
};

export default Auth;
