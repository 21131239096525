import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required("This field is required"),
  password: yup
    .string()
    .required("This field is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  currency: yup.string().required("This field is required"),
  rate: yup.number().required("This field is required"),
});

export const validationRateOnlySchema = yup.object().shape({
  currency: yup.string().required("This field is required"),
  rate: yup.number().required("This field is required"),
});
