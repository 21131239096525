import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import CheckoutView from "views/Setting/View/Subscriptions/Cards/CheckoutView";
import Individual from "views/Setting/View/Subscriptions/Cards/Individual";
import Team from "views/Setting/View/Subscriptions/Cards/Team";
import { planInfoV2 } from "views/Setting/View/Subscriptions/planInfo";
import _ from "lodash";
import { Col, Divider, Row, Segmented, Typography } from "antd";
import { useLazyQuery } from "@apollo/client";
import { LIST_PLANS } from "graphql/queries/plan/listPlans";
import { INDIVIDUAL_PLAN, TEAM_PLAN } from "constants/plans";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";

const ChoosePlan = ({ handleFormSubmit, values }) => {
  const [pickedCurrency, setPickedCurrency] = useState("USD");
  const [rate, setRate] = useState(1);
  const location = useLocation();
  const [plans, setPlans] = useState(planInfoV2({}));
  const user = useRecoilValue(userSession);
  const [userCount, setUserCount] = useState(1);
  const [selectedAddons, setselectedAddons] = useState(user?.addons);
  const [selectedPlan, setSelectedPlan] = useState();
  const [planPeriod, setPlanPeriod] = useState("Annual");
  const { trial } = queryString.parse(location?.search);
  const isTrialUser = trial === "true" || trial === true ? true : false;

  const isAnnualPlan = planPeriod === "Annual";

  const [listPlans, { loading, data: listPlansData }] = useLazyQuery(
    LIST_PLANS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: ({ listPlans }) => {
        setPlans(listPlans);
      },
    }
  );
  useEffect(() => {
    listPlans();
  }, []);

  const handleSubscribe = (data, _, isTrial) => {
    setSelectedPlan({ ...data, isTrial });
  };

  const handleViewChange = () => {
    console.log("view Change");
  };

  return (
    <div>
      {_.isEmpty(selectedPlan) ? (
        <>
          <Row className="flex items-center justify-between">
            <Col className="flex flex-col">
              <Typography.Text strong className=" text-2xl">
                Choose a plan
              </Typography.Text>
              <Typography.Text className="text-base">
                Try for free for 14 days, No Credit Card required*
              </Typography.Text>
            </Col>
            <Col>
              <div className="flex flex-col">
                <div className="flex items-end w-full justify-end">
                  <Typography.Text
                    className="rounded-xl px-2 "
                    style={{ background: "#00EF7C" }}
                  >
                    25% off
                  </Typography.Text>
                </div>
                <Segmented
                  options={["Monthly", "Annual"]}
                  value={planPeriod}
                  onChange={(v) => {
                    setPlanPeriod(v);
                  }}
                  size="large"
                  className="mt-1"
                />
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <Individual
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                fromOnboarding={true}
                isTrialUser={isTrialUser}
                isAnnualPlan={isAnnualPlan}
              />
            </Col>
            <Col span={12}>
              <Team
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                setUserCount={setUserCount}
                userCount={userCount}
                fromOnboarding={true}
                isTrialUser={isTrialUser}
                isAnnualPlan={isAnnualPlan}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="flex justify-center w-full">
          <Col span={9}>
            {selectedPlan?.name === INDIVIDUAL_PLAN && (
              <Individual
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                selectedPlan={selectedPlan}
                isAnnualPlan={isAnnualPlan}
              />
            )}
            {selectedPlan?.name === TEAM_PLAN && (
              <Team
                rate={rate}
                currency={pickedCurrency}
                plans={plans}
                handleSubscribe={handleSubscribe}
                loading={loading}
                user={user}
                selectedPlan={selectedPlan}
                setUserCount={setUserCount}
                userCount={userCount}
                isAnnualPlan={isAnnualPlan}
              />
            )}
          </Col>
          <Col span={14} offset={1}>
            <CheckoutView
              rate={rate}
              currency={pickedCurrency}
              selectedPlan={selectedPlan}
              selectedAddons={selectedAddons}
              setselectedAddons={setselectedAddons}
              setSelectedPlan={setSelectedPlan}
              userCount={userCount}
              handleViewChange={handleViewChange}
              fromOnboarding={true}
              submitOnboardingDetails={() => handleFormSubmit(values)}
              isTrialUser={isTrialUser}
              isAnnualPlan={isAnnualPlan}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ChoosePlan;
