import React from "react";

export const NoDataImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 171.15 195.74"
    >
      <path
        fill="#010101"
        d="M228.74 165a75.58 75.58 0 11-75.57 75.57A75.65 75.65 0 01228.74 165m0-10a85.58 85.58 0 1085.57 85.57A85.57 85.57 0 00228.74 155z"
        transform="translate(-143.17 -130.36)"
      ></path>
      <rect
        width="33.19"
        height="18.61"
        x="68.79"
        fill="#010101"
        rx="3.68"
      ></rect>
      <rect
        width="18.27"
        height="27.11"
        x="76.25"
        fill="#010101"
        rx="3.29"
      ></rect>
      <rect
        width="24.69"
        height="13.25"
        x="150.77"
        y="166.79"
        fill="#010101"
        rx="6.62"
        transform="rotate(-45 -65.827 281.046)"
      ></rect>
      <rect
        width="13.59"
        height="6.53"
        x="161.65"
        y="175.48"
        fill="#010101"
        rx="1.88"
        transform="rotate(-45 -60.498 286.38)"
      ></rect>
      <rect
        width="13.25"
        height="24.69"
        x="290.28"
        y="161.07"
        fill="#010101"
        rx="6.62"
        transform="rotate(-45 67.956 281.052)"
      ></rect>
      <rect
        width="6.53"
        height="13.59"
        x="288.31"
        y="171.95"
        fill="#010101"
        rx="1.88"
        transform="rotate(-45 62.635 286.386)"
      ></rect>
      <path fill="#010101" d="M81.17 39H89.98V52.47H81.17z"></path>
      <path
        fill="#010101"
        d="M178.77 188.24H187.58V201.71H178.77z"
        transform="rotate(-45 -45.766 302.607)"
      ></path>
      <path
        fill="#010101"
        d="M159.9 233.8H168.71V247.27H159.9z"
        transform="rotate(-90 27.545 246.935)"
      ></path>
      <path
        fill="#010101"
        d="M178.77 279.36H187.58V292.83000000000004H178.77z"
        transform="rotate(-135 84.6 250.558)"
      ></path>
      <path
        fill="#010101"
        d="M224.33 298.23H233.14000000000001V311.70000000000005H224.33z"
        transform="rotate(-180 157.155 239.78)"
      ></path>
      <path
        fill="#010101"
        d="M269.89 279.36H278.7V292.83000000000004H269.89z"
        transform="rotate(135 229.715 191.261)"
      ></path>
      <path
        fill="#010101"
        d="M288.76 233.8H297.57V247.27H288.76z"
        transform="rotate(90 286.77 103.77)"
      ></path>
      <path
        fill="#010101"
        d="M269.89 188.24H278.7V201.71H269.89z"
        transform="rotate(45 360.083 -43.029)"
      ></path>
      <path
        d="M223.55 201l-32.92 57a6 6 0 005.19 9h65.84a6 6 0 005.19-9l-32.92-57a6 6 0 00-10.38 0z"
        transform="translate(-143.17 -130.36)"
      ></path>
      <path
        fill="#fff"
        d="M224.93 252.81a3.81 3.81 0 117.62 0 3.81 3.81 0 01-7.62 0zm7.13-31.68c0 2.24-.66 8-1.45 16.44l-.78 8.4h-2.18l-.78-8.4c-.79-8.46-1.45-14.2-1.45-16.44 0-2.6 1.27-3.69 3.32-3.69s3.32 1.09 3.32 3.69z"
        transform="translate(-143.17 -130.36)"
      ></path>
    </svg>
  );
};

export default NoDataImage;
