import gql from "graphql-tag";

export const GET_ACTIVE_CHATS = gql`
  query ($workspaceId: ID!) {
    getActiveChats(workspaceId: $workspaceId) {
      _id
      unreadMessages
      participants {
        _id
        email
        full_name
        avatar
        isOnline
      }
    }
  }
`;
