import React, { useState } from "react";
import utility from "common/utility";
import _ from "lodash";
import { STOP_TODOIST_ACTIVITY } from "graphql/mutations/Todoist/stopTodoistActivity";
import { STOP_TRELLO_ACTIVITY } from "graphql/mutations/Trello/stopTrelloActivity";
import { STOP_MONDAY_ACTIVITY } from "graphql/mutations/Monday/stopMondayActivity";
import { STOP_ACTIVITY } from "graphql/mutations/Activity/stopActivity";
import { GET_ACTIVITY_BY_PROJECT } from "graphql/queries/member/getActivityByProject";
import { GET_RECENT_ACTIVITY } from "graphql/queries/Activity/getRecentActivity";
import { GET_ACTIVITY_COUNT_WIDGET } from "graphql/queries/widget/getActivityCountWidget";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_JIRA } from "graphql/queries/Jira/getJira";
import { workspacesList } from "recoil/atoms/Workspaces";
import { transitionJiraActivity } from "api/Jira";
import { useRecoilValue } from "recoil";
import { GET_WORKSPACE_INTEGRATION } from "graphql/queries/Integrations/getWorkspaceIntegration";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";



const useStopActivityHook = () => {
  const workspaces = useRecoilValue(workspacesList);
  const [jiraActivity, setjiraActivity] = useState();
  const [jiraStopTime, setJiraStopTime] = React.useState();
  const [token, setToken] = useState(null);
  const [stopActivity, { loading: stopping }] = useMutation(STOP_ACTIVITY, {
    onCompleted: async ({ stopActivity }) => {
      utility.setNotification(
        `${stopActivity?.title} stopped`,
        "",
        "success",
        "topRight"
      );
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      GET_ACTIVITY_COUNT_WIDGET,
      GET_RECENT_ACTIVITY,
      GET_TODAY_WIDGET_DATA,
      GET_PAUSED_ACTIVITY,
      {
        query: GET_ACTIVITY_BY_PROJECT,
        variables: { days: 7 },
      },
    ],
  });

  const [stopMondayActivity, { loading: stopMondayActiviy }] = useMutation(
    STOP_MONDAY_ACTIVITY,
    {
      onCompleted: async ({ stopMondayActivity }) => {
        utility.setNotification(
          `${stopMondayActivity?.title} stopped`,
          "You should be able to see your activity created",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [
        GET_ACTIVITY_COUNT_WIDGET,
        GET_RECENT_ACTIVITY,
        {
          query: GET_ACTIVITY_BY_PROJECT,
          variables: { days: 7 },
        },
      ],
    }
  );

  const [getToken] = useLazyQuery(GET_WORKSPACE_INTEGRATION, {
    fetchPolicy: "network-only",
    onCompleted: ({ getWorkspaceIntegration }) => {
      if (getWorkspaceIntegration) {
        setToken(getWorkspaceIntegration?.access_token);
      }
    },
  });

  const [getJira] = useLazyQuery(GET_JIRA, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getJira }) => {
      const findSync = _.find(
        getJira?.sync,
        (sync) => sync?.windu_project === jiraActivity?.project?._id
      );
      transitionJiraActivity(
        getJira?.server_name,
        jiraActivity.meta,
        findSync?.column_push_finished,
        token
      )
        .then(() => {
          stopActivity({
            variables: {
              input: {
                activity_id: jiraActivity?._id,
                date_end: jiraStopTime,
              },
            },
          });
        })
        .catch((error) => {
          const errorCode = error?.response?.data?.code;
          const errorMessage = error?.response?.data?.errorMessages[0];
          if (
            errorMessage ===
            "Issue does not exist or you do not have permission to see it."
          ) {
            stopActivity({
              variables: {
                input: {
                  activity_id: jiraActivity?._id,
                  date_end: jiraStopTime,
                },
              },
            });
          } else {
            if (errorCode === 401 || 403) {
              utility.setNotification(
                `Jira Not Connected`,
                "Please relogin to your Jira account in the appropriate workspace",
                "error",
                "topRight"
              );
            } else {
              stopActivity({
                variables: {
                  input: {
                    activity_id: jiraActivity?._id,
                    date_end: jiraStopTime,
                  },
                },
              });
            }
          }
        });
    },
  });

  const [stopTrelloActivity, { loading: stopTrelloActiviy }] = useMutation(
    STOP_TRELLO_ACTIVITY,
    {
      onCompleted: ({ stopTrelloActivity }) => {
        utility.setNotification(
          `${stopTrelloActivity?.title} stopped`,
          "You should be able to see your activity created",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );
  const [stopTodoistActivity, { loading: stopTodoistActiviy }] = useMutation(
    STOP_TODOIST_ACTIVITY,
    {
      onCompleted: ({ stopTodoistActivity }) => {
        utility.setNotification(
          `${stopTodoistActivity?.title} stopped`,
          "You should be able to see your activity created",
          "success",
          "topRight"
        );
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const isLoading =
    stopTodoistActiviy || stopping || stopMondayActiviy || stopTrelloActiviy;

  const handleStopTimer = ({ endTime, activity, callback }) => {
    switch (activity?.activity_type) {
      case "jira":
        setjiraActivity(activity);
        const findWorkspace = _.find(
          workspaces,
          (workspace) => workspace?.project?._id === activity?.project?._id
        );
        getToken({
          variables: { name: "Jira Cloud", workspace: findWorkspace?._id },
        }).then(() => {
          setJiraStopTime(endTime);
          getJira({
            variables: { input: { workspace: findWorkspace?._id } },
          }).then(() => {
            if (callback) callback();
          });
        });

        break;
      case "monday":
        stopMondayActivity({
          variables: {
            input: {
              activity_id: activity?._id,
              date_end: endTime,
              board_id: JSON.parse(activity?.meta)?.board_id,
              status_id: JSON.parse(activity?.meta)?.status_id,
            },
          },
        }).then(() => {
          if (callback) callback();
        });
        break;
      case "trello":
        stopTrelloActivity({
          variables: {
            input: {
              activity_id: activity?._id,
              date_end: endTime,
              trello_board_id: JSON.parse(activity?.meta)?.board_id,
            },
          },
        }).then(() => {
          if (callback) callback();
        });

        break;
      case "todoist":
        stopTodoistActivity({
          variables: {
            input: {
              activity_id: activity?._id,
              date_end: endTime,
            },
          },
        }).then(() => {
          if (callback) callback();
        });
        break;
      default:
        stopActivity({
          variables: {
            input: {
              activity_id: activity?._id,
              date_end: endTime,
            },
          },
        }).then(() => {
          if (callback) callback();
        });

        break;
    }
  };
  return { handleStopTimer, isLoading };
};

export default useStopActivityHook;
