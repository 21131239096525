import gql from "graphql-tag";

export const UPDATE_ACTIVITY = gql`
  mutation ($input: updateActivityInput) {
    updateActivity(input: $input) {
      _id
      tasks {
        _id
        description
        assigned {
          _id
          full_name
          email
          avatar
        }
        planned
        completed
      }
    }
  }
`;
