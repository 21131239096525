import gql from "graphql-tag";

export const GET_TODOIST_PROJECTS = gql`
  query ($input: getWorkspacesWidgetsInput) {
    getTodoistProjects(input: $input) {
      id
      name
    }
  }
`;
