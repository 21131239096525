import React from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { Typography } from "antd";
import { AntSelect, AntInput } from "components/FormikCustomInputs";
import { map } from "lodash";
import cc from "currency-codes";
import { UPDATE_WORKSPACE_RATE } from "graphql/mutations/workspace/updateWorkspaceRate";
import { GET_WORKSPACE_RATE } from "graphql/queries/workpace/getWorkspaceRate";
import { useMutation, useQuery } from "@apollo/client";
import utility from "common/utility";
import Panel from "./Panels";
import BillingAccount from "./Panels/BillingAccount";

import * as yup from "yup";
import SlackBroadcast from "./Panels/SlackBroadcast";

const currency = map(cc.codes(), (e) => {
  return {
    label: e,
    value: e,
  };
});

const validationSchema = yup.object().shape({
  rate: yup.string().trim().required("This field is required"),
  currency: yup.string().trim().required("This field is required"),
});

const Form = ({ workspace, formRef, onSuccess, isOwner, isManager }) => {
  const [updateWorkspaceRate] = useMutation(UPDATE_WORKSPACE_RATE, {
    onCompleted: ({ updateWorkspaceRate }) => {
      utility.setNotification(
        "Rate Saved",
        "This rate has been saved for this workspace",
        "success",
        "topRight"
      );
      onSuccess();
    },
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        toString(error),
        "error",
        "topRight"
      );
    },
  });

  const { loading, error, data } = useQuery(GET_WORKSPACE_RATE, {
    variables: {
      input: {
        workspace: workspace,
      },
    },
    fetchPolicy: "network-only",
  });

  /// getUsersWorkspaceSettings
  const initialFormValues = {
    ...data?.getWorkspaceRate,
  };

  const handleDone = (values) => {
    const { currency, rate } = values;
    const payload = {
      currency,
      rate,
      workspaceId: workspace,
    };
    updateWorkspaceRate({ variables: { input: payload } });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormValues}
      onSubmit={(values) => handleDone(values)}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, submitCount }) => {
        return (
          <FormikForm onSubmit={handleSubmit}>
            <Panel header="Rate" className="mt-5">
              <div className="mr-auto flex flex-col">
                <Typography.Text className="font-semibold text-base">
                  My Currency
                </Typography.Text>
                <Field
                  component={AntSelect}
                  name="currency"
                  label="Choose your currency"
                  placeholder="Choose your currency"
                  submitCount={submitCount}
                  selectOptions={currency}
                  inputType="select"
                  hasFeedback
                  required={true}
                  size="large"
                  showSearch
                  getPopupContainer={(node) => node.parentNode}
                />
                <Typography.Text className="font-semibold text-base mt-3">
                  My Hourly Rate
                </Typography.Text>
                <Field
                  component={AntInput}
                  name="rate"
                  label="Set hourly rate"
                  placeholder="Set hourly rate"
                  submitCount={submitCount}
                  hasFeedback
                  required={true}
                  size="large"
                />
              </div>
            </Panel>

            {isOwner && <BillingAccount />}
            {isOwner && <SlackBroadcast />}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
