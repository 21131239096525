import React from "react";
import { Route } from "react-router-dom";
import { find, includes, size } from "lodash";
import NotFound from "views/Provisional/404";
import { routing } from "constants/routing";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const PrivateRoute = ({ user, component: Component, path, ...rest }) => {
  const { plan, extra } = user;
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      // Check if an input field is focused
      const inputLikeElements = [
        "input",
        "textarea",
        "select",
        "button",
        "a",
        "div",
      ];

      const isInputFocused = inputLikeElements.includes(
        document.activeElement.tagName.toLowerCase()
      );

      // Check if "/" key is pressed and an input field is not focused
      if (e.key === "/" && !isInputFocused && location.pathname !== "/search") {
        history.push("/search");
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [history, location]);

  const {
    foreignPlan: { plan: foreignPlan, addons: foreignAddons },
  } = extra;

  const userPlan = foreignPlan ? foreignPlan : plan?.name;
  const gatherAddons = [...user.addonsReferences, ...foreignAddons];

  const hasPermission = find(
    routing,
    (router) =>
      (path === router.path && size(router.plan) <= 0) ||
      (path === router.path &&
        (includes(router.plan, userPlan) ||
          includes(gatherAddons, router.addon)))
  );
  return (
    <Route
      {...rest}
      path={path}
      render={(props) =>
        hasPermission !== undefined ? (
          <Component user={user} {...props} />
        ) : (
          <NotFound />
        )
      }
    />
  );
};

export default PrivateRoute;
