import { Button, Card, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import ProgressSteps from "./ProgressSteps";
import ProgressCard from "./ProgressCard";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import HearAboutUs from "components/Modals/HearAboutUs";
import { GET_USER } from "graphql/queries/getUser";
import { UPDATE_SETTINGS_BY_USERID } from "graphql/mutations/user/updateSettingByUserId";
import { Crisp } from "crisp-sdk-web";
import { Lifebuoy, PlayCircle } from "@phosphor-icons/react";
import CategoryPage from "./Category";

const { Text, Title } = Typography;

const QuickStart = () => {
  const user = useRecoilValue(userSession);
  const [hearAboutUsModal, sethearAboutUsModal] = useState(false);
  const [categoryPageOpen, setCategoryPageOpen] = useState(false);

  const stepCovered = user?.settings?.quickStart
    ? user?.settings?.quickStart
    : {
        exampleProject: false,
        firstProject: false,
        inviteOther: false,
        heardAboutPrompt: false,
        defaultModeSet: false,
      };

  useEffect(() => {
    if (!stepCovered?.heardAboutPrompt) {
      sethearAboutUsModal(true);
    }
  }, []);

  const [updateUserSetting, { loading: updateUserSettingLoading }] =
    useMutation(UPDATE_SETTINGS_BY_USERID, {
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_USER],
    });

  return (
    <>
      {categoryPageOpen ? (
        <div>
          <CategoryPage setCategoryPageOpen={setCategoryPageOpen} />
        </div>
      ) : (
        <div className=" px-6" style={{ background: "#EFF0F6" }}>
          <Row className="flex justify-between items-center">
            <Col>
              <Title className="p-0 m-0" style={{ marginBottom: 6 }} level={3}>
                Quick Start
              </Title>
              <Text className="text-base">
                Complete your quick start steps to get Windu quickly up and
                running.
              </Text>
            </Col>
            <Col>
              <div className="flex items-center"></div>
            </Col>
          </Row>
          <Row className="mt-20">
            <Col sm={24} md={14}>
              <ProgressSteps stepCovered={stepCovered} />
            </Col>
            <Col sm={24} md={10}>
              <ProgressCard stepCovered={stepCovered} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={24} md={12}>
              <Card className="mb-4 rounded-xl mr-2">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setCategoryPageOpen(true)}
                >
                  <div className={styles.iconDiv}>
                    <PlayCircle
                      style={{ color: "#F39A31", fontSize: 34 }}
                      weight="fill"
                    />
                  </div>
                  <div className="flex flex-col items-start ml-2">
                    <Text className="text-base">
                      SEE BEST PRACTICES IN WINDU{" "}
                    </Text>
                    <Text className="text-base" strong>
                      View Demo
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={24} md={11}>
              <Card className="mb-4 rounded-xl ml-2">
                <div className="flex items-center">
                  <div className={styles.iconDiv}>
                    <Lifebuoy
                      style={{ color: "#F39A31", fontSize: 34 }}
                      weight="fill"
                    />
                  </div>
                  <div
                    className="flex flex-col items-start ml-2 cursor-pointer"
                    onClick={() => {
                      Crisp.chat.show();
                      Crisp.chat.open();
                    }}
                  >
                    <Text className="text-base">GET HELP</Text>
                    <Text className="text-base" strong>
                      Help Center
                    </Text>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          {hearAboutUsModal && (
            <HearAboutUs
              isVisible={hearAboutUsModal}
              user={user}
              onClose={() => {
                sethearAboutUsModal(false);
                updateUserSetting({
                  variables: {
                    input: {
                      quickStart: { ...stepCovered, heardAboutPrompt: true },
                    },
                  },
                });
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default QuickStart;
