import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Form, Input, InputNumber, Typography } from "antd";
import TimeField from "react-simple-timefield";
import _ from "lodash";
import utility from "common/utility";
import { getCurrencyExchangeRate } from "api/ExchangeRates";
import UserAvatar from "components/UserAvatar";
const EditableContext = React.createContext(null);

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const InputDefintion = {
    Hours: (
      <TimeField
        colon=":"
        showSeconds
        input={
          <Input
            ref={inputRef}
            defaultValue={"00:00:00"}
            onPressEnter={save}
            onBlur={save}
          />
        }
      />
    ),
    Description: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
    "No. Activities": (
      <InputNumber
        initialValues="1"
        min="0"
        stringMode
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
      />
    ),
    Cost: (
      <InputNumber
        min="0"
        step="0.00"
        stringMode
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
      />
    ),
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {InputDefintion[title]}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableTable = ({
  dataSource,
  setDataSource,
  columnsData,
  isRowExpanded,
  clientInfo,
  selectedCurrency,
  pagination,
  ...props
}) => {
  const [exchangeRates, setExchangeRates] = useState(true);
  const handleSave = (row) => {
    const newData = dataSource;
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource([...newData]);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsData.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  let rateConvertion = null;

  useEffect(() => {
    (async () => {
      const data = await getCurrencyExchangeRate({
        currency: selectedCurrency,
      });
      setExchangeRates(data);
    })();
  }, [selectedCurrency]);

  const expandedRowRender = (data) => {
    const newArrayData = data?.activitiesSelected;
    const groupByUser = _.groupBy(newArrayData, "created_by.email");
    const filterByActivity = _.map(groupByUser, (item, index) => {
      const gatherTime = _.map(item, (item) => item?.time?.total_time);
      const totalTime = utility.calculateTimeTotal(gatherTime);
      const memberRate = _.get(item[0], "created_by.rates.rate", "0");
      const memberCurrency = _.get(item[0], "created_by.rates.currency", "USD");
      const clientCurrency = _.get(clientInfo, "currency", "");
      let totalCost = 0;
      if (memberCurrency !== (selectedCurrency || clientCurrency)) {
        rateConvertion = _.find(
          exchangeRates,
          (_, index) => index === memberCurrency
        );
      }
      const totalTimeSplited = _.split(totalTime, ":");
      const pricePerHour = Number(totalTimeSplited[0]) * Number(memberRate);
      const pricePerMin =
        (Number(memberRate) / 60) * Number(totalTimeSplited[1]);
      const total = pricePerHour + pricePerMin;

      totalCost =
        memberCurrency === (selectedCurrency || clientCurrency)
          ? total
          : total / rateConvertion;

      return {
        _id: _.uniqueId("invoice_activity_"),
        user: item[0]?.created_by,
        activites: item,
        hours: totalTime,
        costs: totalCost,
        currency: selectedCurrency || clientCurrency,
      };
    });
    const columns = [
      {
        dataIndex: "user",
        key: "user",
        render: (value) => (
          <div className="flex items-center">
            <UserAvatar user={value} />
            <Typography.Title level={5} className="ml-2">
              {value?.full_name}
            </Typography.Title>
            <Typography.Text className="ml-3">
              {value?.occupation}
            </Typography.Text>
          </div>
        ),
        width: "50%",
      },
      {
        dataIndex: "activites",
        key: "noOfActivites",
        render: (value, item) => {
          return _.size(item.activites);
        },
        width: "12%",
      },
      {
        dataIndex: "hours",
        key: "hours",
        render: (value) => <Typography.Text>{value}</Typography.Text>,
        width: "12%",
      },
      {
        dataIndex: "costs",
        key: "costs",
        render: (value) => (
          <Typography.Text>{Number(value).toFixed(2)}</Typography.Text>
        ),
        width: "12%",
      },
      {
        dataIndex: "currency",
        key: "currency",
        render: (value) => <Typography.Text>{value}</Typography.Text>,
      },
    ];
    return (
      <div style={{ marginLeft: 0 }}>
        <Table
          columns={columns}
          dataSource={filterByActivity}
          pagination={false}
          showHeader={false}
        />
      </div>
    );
  };

  return (
    <Table
      components={components}
      expandable={
        isRowExpanded && {
          expandedRowRender,
          expandedRowKeys: dataSource.map((data) => {
            if (data?.activities) {
              return data?.key;
            }
          }),
          expandIconColumnIndex: -1,
        }
      }
      rowClassName={() => "editable-row"}
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      {...props}
    />
  );
};

export default EditableTable;
