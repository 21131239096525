import gql from "graphql-tag";

export const UPDATE_GITHUB_SYNC = gql`
  mutation ($input: updateGithubSyncInput) {
    updateGithubSync(input: $input) {
      repoName
      notification
    }
  }
`;
