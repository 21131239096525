import gql from "graphql-tag";

export const UPDATE_WIDGETS_LAYOUT = gql`
  mutation($input: [updateWidgetLayoutInput]) {
    updateWidgetLayout(input: $input) {
      widget {
        name
      }
      x
      y
      w
      h
    }
  }
`;
