import gql from "graphql-tag";

export const GET_USERS_ADMIN = gql`
  query ($size: Int!, $offset: Int!, $activeUsers: Boolean) {
    getUsers(size: $size, offset: $offset, activeUsers: $activeUsers) {
      data {
        _id
        email
        full_name
        avatar
        timezone
        created_at
        activityCount
        projects {
          status
          title
          _id
          created_by {
            full_name
            _id
            email
          }
          members {
            _id
          }
          client {
            email
          }
          total_activities
          total_time
          total_members
        }
        onboarding_data {
          finished
          defaultRole
          teamMembers
          companyWorkers
          tools
          weeklyWorkHours
          expertise
          redirected
        }
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
