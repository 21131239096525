import { CreditCard, UserCircle, Wallet } from "@phosphor-icons/react";
import { Avatar, Button, Spin, Table, Tag, Typography, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../../styles.module.css";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TANGO_ACCOUNT } from "graphql/queries/Rewards/getTangoAccount";
import Loader from "components/Loader";
import getSymbolFromCurrency from "currency-symbol-map";
import AddCreditCardModal from "components/Modals/AddCreditCard";
import AddFunds from "components/Modals/AddFund";
import utility from "common/utility";
import { CREATE_TANGO_ACCOUNT } from "graphql/mutations/Rewards/createTangoAccount";
import {
  LoadingOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { GET_TANGO_ORDER } from "graphql/queries/Rewards/getTangoHistory";
import UserAvatar from "components/UserAvatar";
import moment from "moment";

const { Text, Link, Title } = Typography;

const Rewards = () => {
  const [queryParameters, setQueryParameters] = useState({
    size: 5,
    page: 1,
  });
  const [addCreditCardModal, setAddCreditCardModal] = useState(false);
  const [tangoDetails, setTangoDetails] = useState(null);
  const [addFundModal, setAddFundModal] = useState(false);
  const {
    loading,
    data: tangoAccount,
    refetch,
  } = useQuery(GET_TANGO_ACCOUNT, {
    fetchPolicy: "network-only",
  });
  const {
    loading: tableLoading,
    data: tangoOrder,
    refetch: refetchOrder,
  } = useQuery(GET_TANGO_ORDER, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        page: queryParameters.page - 1,
        size: queryParameters.size,
      },
    },
  });
  const [createTangoAccount, { loading: accountLoading }] = useMutation(
    CREATE_TANGO_ACCOUNT,
    {
      onCompleted: () => {
        refetch();
        utility.setNotification(
          "Account created successfully",
          "Your tango account has been created successfully.",
          "success",
          "topRight"
        );
        setAddCreditCardModal(true);
      },
      onError: (error) => {
        utility.setNotification(
          "Something wrong happened",
          "Please try again.",
          "error",
          "topRight"
        );
      },
    }
  );

  useEffect(() => {
    if (tangoAccount?.getActiveTangoAccount) {
      setTangoDetails(tangoAccount?.getActiveTangoAccount);
    }
  }, [tangoAccount?.getActiveTangoAccount]);

  const columns = [
    {
      title: "Reciever",
      dataIndex: "recipient",
      key: "recipient",
      render: (data, row) =>
        row?.status === "COMPLETE" ? (
          <div className="flex items-center">
            <UserAvatar user={data} />

            <div className="flex flex-col ml-2">
              <Text strong>{data?.full_name}</Text>
              <Text type="secondary">{data?.email}</Text>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <Avatar icon={<UserOutlined />} />
            <div className="ml-2">
              <Text>N/A</Text>
            </div>
          </div>
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) =>
        row?.status === "COMPLETE" ? (
          <Text>
            {text} {row.currency}
          </Text>
        ) : (
          <Text>0</Text>
        ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => <Text>{moment(date).format("ll")}</Text>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) =>
        status === "COMPLETE" ? (
          <Tag color={"green"}>Complete</Tag>
        ) : (
          <Tag color={"red"}>Failed</Tag>
        ),
    },
  ];

  if (loading) return <Loader />;
  return (
    <div>
      <div>
        <Title level={3}>
          Fund account{" "}
          <Tooltip
            title={
              <div>
                See supported Countries {""}
                <Typography.Link
                  underline
                  className="font-light"
                  href="https://www.tangocard.com/reward-catalog"
                  target="_blank"
                >
                  here
                </Typography.Link>
              </div>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Title>

        <div className="p-4 bg-gray-50 rounded-md flex items-center gap-5 mt-6">
          {tangoDetails ? (
            <div className="flex items-center gap-5">
              <div
                className="flex flex-col justify-center items-center rounded-md w-56 h-28 bg-white "
                style={{ border: "1.5px dashed #D1CBBE" }}
              >
                <Text strong>Available Balance</Text>

                <div className="flex items-center mt-4">
                  <Wallet size={28} weight="fill" fill="#808080" />
                  <Text className="text-lg font-semibold ml-1">
                    {getSymbolFromCurrency(tangoDetails?.currencyCode)}
                    {tangoDetails?.currentBalance}
                  </Text>
                </div>
              </div>

              {tangoDetails?.ccDetails &&
                tangoDetails?.ccDetails.map((item) => {
                  return (
                    <div
                      className="flex flex-col justify-center items-center rounded-md w-56 h-28  bg-white "
                      style={{ border: "1.5px dashed #D1CBBE" }}
                    >
                      <Text strong>Add new balance</Text>
                      <div className="flex items-center my-1">
                        <Text>****-****-****-{item.lastFourDigits}</Text>
                      </div>

                      <Button
                        size="small"
                        onClick={() => setAddFundModal(item)}
                      >
                        <Text>+ Add fund</Text>
                      </Button>
                    </div>
                  );
                })}

              <div
                className="flex flex-col justify-center items-center rounded-md w-56 h-28 cursor-pointer bg-white "
                style={{ border: "1.5px dashed #D1CBBE" }}
                onClick={() => setAddCreditCardModal(true)}
              >
                <CreditCard size={28} weight="fill" fill="#808080" />
                <Text strong>+ Add a card</Text>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col justify-center items-center rounded-md w-56 h-28 cursor-pointer bg-white "
              style={{ border: "1.5px dashed #D1CBBE" }}
              onClick={() => createTangoAccount()}
            >
              {accountLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              ) : (
                <>
                  <UserCircle size={28} weight="fill" fill="#808080" />
                  <Text strong>Add an account</Text>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-6">
        <Text className="text-base font-medium">Order history</Text>
        <div className="pt-12 mt-6" style={{ border: "2px solid #EAECF0" }}>
          <Table
            columns={columns}
            loading={tableLoading}
            dataSource={tangoOrder?.getTangoOrderHistory?.orders}
            pagination={{
              current: queryParameters.page,
              pageSize: queryParameters.size,
              total: tangoOrder?.getTangoOrderHistory?.page?.totalCount,
              onChange: (page, pageSize) => {
                setQueryParameters({
                  ...queryParameters,
                  page,
                });
              },
              onShowSizeChange: (current, size) => {
                setQueryParameters({
                  ...queryParameters,
                  page: 1,
                  size,
                });
              },
            }}
            className={styles.rewardHistoryTable}
          />
        </div>
      </div>

      {addCreditCardModal && (
        <AddCreditCardModal
          isVisible={addCreditCardModal}
          setVisible={setAddCreditCardModal}
          refetch={refetch}
        />
      )}
      {addFundModal && (
        <AddFunds
          isVisible={addFundModal}
          setVisible={setAddFundModal}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default Rewards;
