import { atom } from "recoil";

export const selectedActivity = atom({
  key: "selectedActivity",
  default: {
    toggle: false,
    data: undefined,
    fromUrl: false,
  },
});
