import React, { useState } from "react";
import { isNil } from "lodash";
import InvoiceHistory from "./InvoiceHistory";
import RateWarningModal from "./RateWarningModal";
import TeamMembers from "./TeamMembers";

const Invoices = ({ workspace }) => {
  const [missingRateWarning, setmissingRateWarning] = useState(false);
  const [rateWarningModal, setrateWarningModal] = useState(false);
  const [membersWithNotRates, setMembersWithNotRates] = useState([]);

  if (!workspace) return null;
  return (
    <div className="flex justify-between">
      <TeamMembers missingRateWarning={missingRateWarning} />
      <InvoiceHistory
        missingAccount={isNil(workspace?.account)}
        hasClient={!isNil(workspace?.project?.client)}
        missingRateWarning={missingRateWarning}
        setmissingRateWarning={setmissingRateWarning}
        setrateWarningModal={setrateWarningModal}
        rateWarningModal={rateWarningModal}
        setMembersWithNotRates={setMembersWithNotRates}
      />
      <RateWarningModal
        rateWarningModal={rateWarningModal}
        setrateWarningModal={setrateWarningModal}
        membersWithNotRates={membersWithNotRates}
      />
    </div>
  );
};

export default Invoices;
