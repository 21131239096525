import gql from "graphql-tag";

export const LOGIN_VISITOR = gql`
  mutation ($input: visitorLoginInput) {
    visitorLogin(input: $input) {
      token
      user {
        _id
        email
        stripe
        account {
          _id
        }
      }
    }
  }
`;
