import React, { useState } from "react";
import {
  CloseCircleFilled,
  MessageOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Dropdown, Avatar, Badge, Tooltip } from "antd";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { useLocation } from "react-router";
import { useRecoilValue, useRecoilState } from "recoil";
import queryString from "query-string";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { userSession } from "recoil/atoms/User/UserSession";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import { workspacesMessagesList } from "recoil/atoms/Messages/WorkspacesMessages";
import { GET_ACTIVE_CHATS } from "graphql/queries/message/getActiveChats";
import { REMOVE_ACTIVE_CHAT } from "graphql/mutations/message/removeActiveChat";
import { NEW_MESSAGE } from "graphql/subscription/message/newMessage";
import { READ_MESSAGE } from "graphql/subscription/message/readMessage";
import { USER_STATUS_CHANGE } from "graphql/subscription/user/userStatusChange";
import ChatDropdown from "./ChatDropdown";
import ChatPopUp from "./ChatPopup";
import Groupchat from "./Groupchat";
import UserAvatar from "components/UserAvatar";
import _ from "lodash";

const WorkspaceMessages = () => {
  const workspace = useRecoilValue(workspaceSelected);
  const user = useRecoilValue(userSession);
  const workspaceMessages = useRecoilValue(workspacesMessagesList);
  const [chatSelected, setChatSelected] = useRecoilState(chatSelectedState);
  const [chatDropdownVisiblity, setchatDropdownVisiblity] = useState(false);
  const [chatPopUpVisiblity, setchatPopUpVisiblity] = useState(false);
  const [activeChats, setActiveChats] = useState([]);
  const [unreadIndividualsChats, setUnreadIndividualsChats] = useState(0);
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const { data: messageData, error: messageError } =
    useSubscription(NEW_MESSAGE);

  const { data: messageRead, error: messageReadError } =
    useSubscription(READ_MESSAGE);

  const { data: userStatusData, error: userStatusError } =
    useSubscription(USER_STATUS_CHANGE);

  const [getActiveChats, { loading }] = useLazyQuery(GET_ACTIVE_CHATS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getActiveChats }) => {
      setActiveChats(getActiveChats);
    },
  });

  const [removeActiveChat] = useMutation(REMOVE_ACTIVE_CHAT, {
    refetchQueries: [
      {
        query: GET_ACTIVE_CHATS,
        variables: { workspaceId: workspace?._id },
      },
    ],
  });

  const chatPopUpHandler = (chat) => {
    setChatSelected({
      participants: chat.participants,
      workspaceId: workspace._id,
      chatId: chat._id,
    });

    setchatPopUpVisiblity(true);
    setchatDropdownVisiblity(false);
  };
  const groupCloseHandler = ({ chatId }) => {
    removeActiveChat({ variables: { input: { chatId, workspaceId: id } } });
  };

  const updateIndicator = () => {
    const { newMessage } = messageData;

    if (
      newMessage.chatId !== chatSelected.chatId &&
      newMessage.type === "Individual" &&
      newMessage.from._id !== user._id
    ) {
      // increase counter
      setUnreadIndividualsChats(unreadIndividualsChats + 1);
      const index = _.findIndex(
        activeChats,
        (chat) => chat._id === newMessage.chatId
      );

      if (index >= 0) {
        const cloned = _.cloneDeep(activeChats);
        cloned[index].unreadMessages = cloned[index].unreadMessages + 1;

        setActiveChats(cloned);
      }
    }
  };

  const decreaseCounter = () => {
    const { markReadMessages } = messageRead;

    if (unreadIndividualsChats > 0) {
      setUnreadIndividualsChats(
        unreadIndividualsChats - markReadMessages.markedAsRead
      );
    }

    const index = _.findIndex(
      activeChats,
      (chat) => chat._id === markReadMessages.chatId
    );

    if (index >= 0) {
      const cloned = _.cloneDeep(activeChats);
      cloned[index].unreadMessages =
        cloned[index].unreadMessages - markReadMessages.markedAsRead;

      setActiveChats(cloned);
    }
  };

  React.useEffect(() => {
    if (messageError) console.warn(messageError);
    if (messageData) updateIndicator();
  }, [messageError, messageData]);

  React.useEffect(() => {
    if (messageReadError) console.warn(messageReadError);
    if (messageRead) decreaseCounter();
  }, [messageReadError, messageRead]);

  React.useEffect(() => {
    if (userStatusError) console.warn(userStatusError);
    if (userStatusData) {
      const { userStatusChange } = userStatusData;

      if (!_.isEmpty(chatSelected)) {
        const { participants } = chatSelected;
        const participantsClone = _.cloneDeep(participants);

        const index = _.findIndex(
          participantsClone,
          (user) => user._id === userStatusChange.id
        );
        if (index >= 0) {
          participantsClone[index].isOnline = userStatusChange.isOnline;

          setChatSelected({ ...chatSelected, participants: participantsClone });
        }
      }
    }
  }, [userStatusData, userStatusError]);

  React.useEffect(() => {
    if (workspaceMessages) {
      const workspaceFound = _.find(
        workspaceMessages,
        (item) => item._id === workspace?._id
      );

      if (workspaceFound) {
        const individualUnread = _.find(
          workspaceFound.unreadMessages,
          (value) => value.type === "Individual"
        );
        if (individualUnread) {
          setUnreadIndividualsChats(individualUnread.unread);
        }
      } else {
        setUnreadIndividualsChats(0);
      }
    }
  }, [workspaceMessages, workspace]);

  React.useEffect(() => {
    // get the active chats related to the workspace
    getActiveChats({
      variables: {
        workspaceId: id,
      },
    });
    //reset the state
    setchatDropdownVisiblity(false);
    setchatPopUpVisiblity(false);
  }, []);

  return (
    <div
      className="flex m-auto pt-2 px-4 justify-between w-full h-screen"
      style={{ height: "calc(100vh - 174px)" }}
    >
      <Groupchat />
      <div className="flex flex-col justify-between my-2">
        <div
          className="flex flex-col items-center overflow-x-hidden overflow-y-auto"
          style={{ maxHeight: 650 }}
        >
          {_.map(activeChats, (chat, index) => {
            if (_.size(chat.participants) > 2) {
              const participantsFiltered = _.filter(
                chat.participants,
                (participant) => participant._id !== user._id
              );

              const participantInfo = _.map(
                participantsFiltered,
                (participant) => participant.email
              ).join(" ");

              return (
                <Tooltip title={participantInfo} placement="left" key={index}>
                  <Badge count={chat.unreadMessages} offset={[-50, 50]}>
                    <span
                      className="absolute ml-12 z-50 text-white cursor-pointer"
                      onClick={() => groupCloseHandler({ chatId: chat._id })}
                    >
                      <CloseCircleFilled
                        style={{
                          backgroundColor: "white",
                          color: "#BAB2EA",
                          borderRadius: "50%",
                          fontSize: 22,
                        }}
                      />
                    </span>

                    <Avatar
                      style={{ backgroundColor: "rgb(114, 99, 204)" }}
                      size="large"
                      icon={<TeamOutlined className="icon-large" />}
                      className="mb-4 w-16 h-16 border-2 border-gray-300 bg-blue-400 cursor-pointer"
                      onClick={() =>
                        chatPopUpHandler({
                          ...chat,
                          participants: participantsFiltered,
                        })
                      }
                    />
                  </Badge>
                </Tooltip>
              );
            } else {
              const participant = _.find(
                chat.participants,
                (participant) => participant._id !== user._id
              );

              return (
                <Tooltip
                  title={participant?.email}
                  placement="left"
                  key={index}
                >
                  <Badge count={chat.unreadMessages} offset={[-50, 50]}>
                    <span
                      className="absolute ml-12 z-50 text-white cursor-pointer"
                      onClick={() =>
                        groupCloseHandler({ chatId: chat._id, isActive: false })
                      }
                    >
                      <CloseCircleFilled
                        style={{
                          backgroundColor: "white",
                          color: "#BAB2EA",
                          borderRadius: "50%",
                          fontSize: 22,
                        }}
                      />
                    </span>
                    <UserAvatar
                      size="large"
                      user={participant}
                      className="mb-4 w-16 h-16 border-2 border-gray-300 bg-blue-400 cursor-pointer"
                      onClick={() =>
                        chatPopUpHandler({
                          ...chat,
                          participants: [{ ...participant }],
                        })
                      }
                    />
                  </Badge>
                </Tooltip>
              );
            }
          })}
        </div>
        <div>
          {chatPopUpVisiblity && (
            <div
              className="absolute right-24 rounded-lg"
              style={{ height: "440px", width: "360px", marginTop: "-390px" }}
            >
              {chatSelected && (
                <ChatPopUp setchatPopUpVisiblity={setchatPopUpVisiblity} />
              )}
            </div>
          )}

          <Badge count={unreadIndividualsChats}>
            <div className="rounded" style={{ background: "#7263CC" }}>
              <Dropdown
                overlay={
                  <ChatDropdown
                    setchatDropdownVisiblity={setchatDropdownVisiblity}
                    setchatPopUpVisiblity={setchatPopUpVisiblity}
                    chatDropdownVisiblity={chatDropdownVisiblity}
                  />
                }
                trigger={["click"]}
                placement="topRight"
                visible={chatDropdownVisiblity}
                onVisibleChange={(flag) => setchatDropdownVisiblity(flag)}
              >
                <MessageOutlined
                  style={{
                    fontSize: "35px",
                    color: "white",
                    padding: "17px",
                  }}
                  onClick={() =>
                    setchatDropdownVisiblity(!chatDropdownVisiblity)
                  }
                />
              </Dropdown>
            </div>
          </Badge>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceMessages;
