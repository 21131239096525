import gql from "graphql-tag";

export const GET_WHOLE_TEAM = gql`
  query ($input: getProjectInfo) {
    getWholeTeam(input: $input) {
      data {
        _id
        email
        full_name
        avatar
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
