import React from "react";
import { Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import AdminLogin from "views/Admin/login";
import { GET_ADMIN_BY_CONTEXT } from "graphql/queries/Admin/getAdminByContext";
import { get } from "lodash";
import Loader from "components/Loader";

const AdminRoute = ({ component: Component }) => {
  const { loading, error, data } = useQuery(GET_ADMIN_BY_CONTEXT, {
    fetchPolicy: "cache-and-network",
  });
  const hasPermission =
    get(data, "getAdminByContext._id", undefined) !== undefined;

  if (loading) return <Loader />;
  return (
    <Route
      render={(props) =>
        hasPermission ? <Component {...props} /> : <AdminLogin />
      }
    />
  );
};

export default AdminRoute;
