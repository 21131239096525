import React, { useState } from "react";
import CreateInvoice from "./CreateInvoice";
import HistoryPreview from "./HistoryPreview";

const Invoice = () => {
  const [createInvoice, setcreateInvoice] = useState(false);
  if (createInvoice) {
    return <CreateInvoice setCreateInvoice={setcreateInvoice} />;
  } else {
    return <HistoryPreview setCreateInvoice={setcreateInvoice} />;
  }
};

export default Invoice;
