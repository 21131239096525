import gql from "graphql-tag";

export const GET_ALL_REFERRAL_USERS = gql`
  query {
    getAllReferralUser {
      userId {
        _id
        email
        full_name
        avatar
      }
      giftSent
      amount
    }
  }
`;
