import gql from "graphql-tag";

export const GET_TRELLO_LIST = gql`
  query ($input: getTrelloListsInput) {
    getTrelloLists(input: $input) {
      id
      name
    }
  }
`;
