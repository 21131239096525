import React from "react";
import { PlusSquareOutlined } from "@ant-design/icons";

const AddSync = ({ onClick }) => (
  <PlusSquareOutlined
    style={{ fontSize: 30, cursor: "pointer" }}
    twoToneColor={"#644ACB"}
    onClick={onClick}
  />
);

export default AddSync;
