import axios from "axios";
import Cookies from "js-cookie";
import { INTEGRATIONS } from "components/Member/Integrations/constants";

const client =
  process.env.REACT_APP_BE === "be-dev.windu.io"
    ? process.env.REACT_APP_DEV_TODOIST_CLIENT
    : process.env.REACT_APP_TODOIST_CLIENT;

export const TODOIST_API = "https://api.todoist.com/rest/v1";
export const TODOIST_AUTHORIZE = (workspace) => {
  return `https://todoist.com/oauth/authorize?client_id=${client}&scope=data:read,data:delete,data:read_write &state=${workspace}?integration=todoist`;
};

export const TODOIST_REQUEST_ACCESS = "https://todoist.com/oauth/access_token";

export const getTasks = () => {
  return axios.get(`${TODOIST_API}/tasks`, {
    headers: {
      Authorization: `Bearer ${Cookies.get(INTEGRATIONS.TODOIST)}`,
    },
  });
};

export const getComments = (task) => {
  return axios.get(`${TODOIST_API}/comments?task_id=${task}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get(INTEGRATIONS.TODOIST)}`,
    },
  });
};
