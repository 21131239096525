import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import GiftIcon from "common/assets/inviteOther/gift.svg";
import ThropyIcon from "common/assets/inviteOther/thropy.svg";
import styles from "../../styles.module.css";
import { UserAddOutlined } from "@ant-design/icons";
import { GET_ALL_REFERRAL_USERS } from "graphql/queries/user/getAllReferralUser";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import Loader from "components/Loader";
import UserAvatar from "components/UserAvatar";
import InviteOtherModal from "components/Modals/InviteOther";

const columns = [
  {
    title: "Name",
    dataIndex: "full_name",
    key: "full_name",
    render: (name, item) => {
      return (
        <div className="flex items-center">
          <UserAvatar user={item.userId} />
          <Text className="ml-3 text-base" strong>
            {name}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Point Score",
    dataIndex: "amount",
    key: "amount",
    render: (item) => {
      if (item) return `USD $${item}`;
    },
  },
  {
    title: "Reward Status",
    dataIndex: "giftSent",
    key: "giftSent",
    render: (item) => {
      if (item) return <Tag color="success">Issued</Tag>;
      else return <Tag color="processing">Pending</Tag>;
    },
  },
];

const { Text, Title } = Typography;
const Invites = () => {
  const [data, setData] = useState([]);
  const [inviteOtherModal, setInviteOtherModal] = useState(false);
  const { loading } = useQuery(GET_ALL_REFERRAL_USERS, {
    onCompleted: ({ getAllReferralUser }) => {
      const dataFormat = _.map(getAllReferralUser, (item) => {
        return {
          ...item,
          ...item?.userId,
        };
      });
      setData(dataFormat?.reverse());
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  if (loading) return <Loader />;
  return (
    <div>
      <Row>
        <Col span={11}>
          <Card
            className="rounded-lg"
            title={<Title level={4}>Total Invites</Title>}
            extra={<Text className="text-base"> {_.size(data)} Users</Text>}
          >
            <div className="flex items-center">
              <img src={ThropyIcon} style={{ height: 58 }} />
              <Text strong className=" text-5xl ml-6">
                {_.size(data)}
              </Text>
            </div>
          </Card>
        </Col>
        <Col span={11} className="ml-4">
          <Card
            title={<Title level={4}>Total Earnings</Title>}
            className="rounded-lg"
            extra={
              <Text className="text-base">
                {moment().format("DD MMM, YYYY")}
              </Text>
            }
          >
            <div className="flex items-center">
              <img src={GiftIcon} style={{ height: 58 }} />

              <Text strong className=" text-5xl ml-6">
                $
                {_.sum(
                  _.map(data, (item) => {
                    if (item?.amount) return item?.amount;
                    else return 0;
                  })
                )}
              </Text>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-6">
        <Card
          className="w-full rounded-lg"
          title={
            <div className="flex flex-col">
              <Text className="text-lg" strong>
                Invite history
              </Text>
              <Text className="text-base">
                Get giftcard credit for every friend you refer!
              </Text>
            </div>
          }
          extra={
            <Button
              size="large"
              className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none`}
              type="primary"
              onClick={() => setInviteOtherModal(true)}
            >
              <div className="flex items-center">
                <UserAddOutlined />
                <Text className="text-base text-white ml-2">Invite</Text>{" "}
              </div>
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
          />
        </Card>
      </Row>
      {inviteOtherModal && (
        <InviteOtherModal
          isVisible={inviteOtherModal}
          onClose={() => {
            setInviteOtherModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Invites;
