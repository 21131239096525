import React from "react";
import "@ant-design/compatible/assets/index.css";
import { Button, Result, Typography, Layout, Row, Col } from "antd";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import { toString } from "lodash";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import Loader from "components/Loader";
import { GET_USER_BY_TOKEN } from "graphql/queries/user/getUserByToken";
import { UPDATE_USER_PASSWORD } from "graphql/mutations/user/updatePassword";
import * as yup from "yup";
import utility from "common/utility";
import styles from "./styles.module.css";
import FloaterInput from "components/FloaterInput";
import OnboardingSvg from "common/assets/onboard.svg";

import LockIcon from "common/assets/lockIcon.svg";

const { Text, Title } = Typography;

const ResetPassword = ({ history, location: { search } }) => {
  const formRef = React.useRef();
  const token = queryString.parse(search).t;
  const { client } = queryString.parse(search);

  const { loading, data } = useQuery(GET_USER_BY_TOKEN, {
    variables: {
      token,
    },
    fetchPolicy: "network-only",
  });

  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: ({ updateUserPassword }) => {
      Cookies.set("token", updateUserPassword?.token, {
        expires: 7,
      });
      history.push("/");
    },
    onError: (error) => {
      utility.setNotification(
        "Something wrong happened",
        toString(error),
        "error",
        "topRight"
      );
    },
  });

  const isValid = data?.getUserByToken?._id;

  const handleDone = (values) => {
    updateUserPassword({
      variables: {
        input: {
          user_id: data?.getUserByToken?._id,
          password: values?.password,
          current: "",
          external: true,
        },
      },
    });
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords do not match")
      .required("This field is required"),
  });

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  if (loading) return <Loader size="large" />;

  if (isValid)
    return (
      <Layout className="flex justify-center min-h-screen">
        <Row style={{ height: "100vh" }} className="bg-white">
          <Col xs={24} sm={24} md={12} className="bg-white flex">
            <>
              <div
                className="px-12 text-center w-3/4 m-auto flex flex-col justify-between"
                style={{ marginTop: "20%", maxWidth: 500, height: "80vh" }}
              >
                <div>
                  <div className="flex justify-center">
                    <img src={LockIcon} alt="windu" />
                  </div>
                  <div className="mb-6">
                    <Title level={2} style={{ marginBottom: 0 }}>
                      Set New Password
                    </Title>
                    <Text className="text-base -mt-2 ">
                      Must be at least 8 characters.
                    </Text>
                  </div>
                  <Formik
                    initialValues={{ password: "", confirmPassword: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleDone}
                    innerRef={formRef}
                  >
                    {({ submitCount, handleSubmit }) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          <div className="text-left">
                            <Field
                              component={FloaterInput}
                              name="password"
                              label="Password"
                              placeholder="Your secret password"
                              submitCount={submitCount}
                              hasFeedback
                              required={true}
                              size="large"
                              inputType="password"
                            />
                            <Field
                              component={FloaterInput}
                              name="confirmPassword"
                              label="Confirm Password"
                              placeholder="Confirm password"
                              submitCount={submitCount}
                              hasFeedback
                              required={true}
                              size="large"
                              inputType="password"
                            />
                          </div>

                          <Button
                            size="large"
                            onClick={() => onSubmit()}
                            key="submit"
                            type="primary"
                            loading={loading}
                            className={`w-full my-4 ml-1 outline-none border-none ${styles.blackNextButton}`}
                          >
                            {client ? "Submit" : "Reset"}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>

                <div className="text-center">
                  <Text>Copyright © Windu 2023 | All rights reserved</Text>
                </div>
              </div>
            </>
          </Col>
          <Col xs={0} sm={0} md={12} className="m-auto p-5">
            <div
              className=" rounded-xl"
              style={{ background: "#B1A5EB", height: "92vh" }}
            >
              <img
                src={OnboardingSvg}
                alt="windu-onboarding"
                style={{ minHeight: "80vh" }}
                className="p-5"
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  return (
    <>
      <Result
        status="warning"
        title="It looks like your token expired"
        extra={
          <Button
            onClick={() => history.push("/login")}
            type="primary"
            key="console"
          >
            Resend password reset
          </Button>
        }
      />
    </>
  );
};

export default withRouter(ResetPassword);
