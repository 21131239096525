import React from "react";
import { Popconfirm, Tooltip } from "antd";
import AntIcon from "@ant-design/icons";
import Icons from "common/icons";
import moment from "moment";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useRecoilValue, useRecoilState } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { START_BACKLOG_ACTIVITY } from "graphql/mutations/Backlog/startBacklogActivity";
import { GET_BACKLOG } from "graphql/queries/Backlog/getBacklog";
import { useTimer } from "contexts/timer-context";
import utility from "common/utility";

const StartAction = ({ item, data }) => {
  const user = useRecoilValue(userSession);
  const workspace = useRecoilValue(workspaceSelected);
  const [activity, setActivity] = useRecoilState(activeActivity);

  const { startHandler, resetHandler } = useTimer(); // global startHandler from context

  const [startBacklogActivity] = useMutation(START_BACKLOG_ACTIVITY, {
    onError: () => {
      setActivity({
        active: false,
        data: null,
      });
      resetHandler(); // reset the counter if exists

      utility.setNotification(
        "Error",
        `Error start activity`,
        "error",
        "topRight"
      );
    },
    onCompleted: ({ startBacklogActivity }) => {
      setActivity({
        data: startBacklogActivity,
        active: true,
      });

      utility.setNotification(
        "Success",
        `Activity has been started`,
        "success",
        "topRight"
      );
    },
    refetchQueries: [
      {
        query: GET_BACKLOG,
        variables: { workspaceId: workspace._id },
      },
    ],
  });

  const onActivityStart = () => {
    if (activity.active) {
      utility.setNotification(
        "You cannnot start this activity",
        `There is a current activity already running`,
        "error",
        "topRight"
      );
      return;
    }

    const { data: itemData } = item;
    const { project } = workspace;
    const start_value = moment.utc(new Date());
    const newActivity = {
      created_by: {
        email: itemData.assigned.email,
        full_name: itemData.assigned.full_name,
        _id: itemData.assigned._id,
      },
      time: {
        paused: [],
        start: start_value.format("YYYY-MM-DDTHH:mm:ss[Z]"),
      },
      project: {
        _id: project._id,
        title: project.title,
      },
      title: itemData.title,
      description: itemData.description,
    };

    setActivity({
      active: true,
      data: newActivity,
    });

    startHandler(newActivity?.time?.start);

    const payload = {
      activityId: item.id,
      backlogId: data?.rootId,
      date_start: start_value,
    };

    const groups = _.filter(
      data.items,
      (item) => item.data.type === "backlog_group"
    );

    const subgroups = _.filter(
      data.items,
      (item) => item.data.type === "backlog_sub_group"
    );

    const belongsToGroup = _.find(groups, (group) =>
      _.includes(group.children, item.id)
    );

    const belongsToSubGroup = _.find(subgroups, (group) =>
      _.includes(group.children, item.id)
    );

    if (belongsToGroup || belongsToSubGroup) {
      // if belongs to a group or subgroup set the groupid
      payload.groupId = belongsToGroup?.id || belongsToSubGroup.id;
    }

    startBacklogActivity({
      variables: {
        input: {
          ...payload,
        },
      },
    });
  };

  return (
    <Tooltip placement="top" title="Start tracking">
      <Popconfirm
        placement="bottom"
        title="Click yes to start tracking this activity"
        onConfirm={() => onActivityStart()}
        okText="Yes"
        cancelText="No"
      >
        <AntIcon
          component={Icons.PlayBtn}
          className={`icon ${
            user._id === item?.data?.assigned?._id ? "visible" : "invisible"
          }`}
        />
      </Popconfirm>
    </Tooltip>
  );
};

export default StartAction;
