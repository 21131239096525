import React, { useState } from "react";
import { Button, Tabs, Tooltip } from "antd";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Members from "views/Members";
import Managers from "views/Managers";
import { GiftOutlined } from "@ant-design/icons";
import SendGiftCard from "components/Modals/SendGiftcard";

const { TabPane } = Tabs;

const Teams = () => {
  const history = useHistory();
  const { view } = queryString.parse(history.location.search);
  const defaultkey = view || "members";
  const [sendGiftcardModal, setSendGiftcardModal] = useState(false);
  return (
    <>
      {" "}
      <Tabs
        defaultActiveKey={defaultkey}
        moreIcon={null}
        tabBarExtraContent={
          <Tooltip placement="left" title="Send giftcards">
            <Button
              size="large"
              icon={<GiftOutlined />}
              onClick={() => setSendGiftcardModal(true)}
            />
          </Tooltip>
        }
      >
        <TabPane tab="Members List" key="members">
          <Members />
        </TabPane>
        <TabPane tab="Managers List" key="managers">
          <Managers />
        </TabPane>
      </Tabs>
      {sendGiftcardModal && (
        <SendGiftCard
          isVisible={sendGiftcardModal}
          setVisible={setSendGiftcardModal}
        />
      )}
    </>
  );
};

export default Teams;
