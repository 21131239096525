import {
  Badge,
  Card,
  Col,
  Divider,
  Popover,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import DragHandle from "../DragHandle";
import SemiCircleProgressBar from "components/SemiCircleProgressBar";
import styles from "./styles.module.css";
import { Info } from "@phosphor-icons/react";
import SuggestionSvg from "common/assets/suggestion.svg";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { useQuery } from "@apollo/client";
import utility from "common/utility";

const { Text, Title } = Typography;

const TodayWidget = () => {
  const [progress, setProgress] = useState(0);

  const [widgetData, setWidgetData] = useState({
    totalTime: 0,
    workedTime: 0,
    remainingTime: 0,
  });

  const { loading, data, refetch } = useQuery(GET_TODAY_WIDGET_DATA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const progressPercentage =
      (widgetData.workedTime / widgetData.totalTime) * 100;
    if (isFinite(progressPercentage) && progressPercentage >= 0) {
      setProgress(progressPercentage);
    }
  }, [widgetData]);

  useEffect(() => {
    if (data?.getTodayWidgetData) {
      const { getTodayWidgetData } = data;
      const { totalTime, workedTime, remainingTime } = getTodayWidgetData;

      const totalTimeNumber = parseFloat(totalTime);
      const workedTimeNumber = parseFloat(workedTime);
      if (totalTimeNumber > 0) {
        setWidgetData({
          totalTime: totalTimeNumber,
          workedTime: workedTimeNumber,
          remainingTime: totalTimeNumber - workedTimeNumber,
        });

        // Calculate progress percentage
        const progressPercentage = (workedTimeNumber / totalTimeNumber) * 100;

        setProgress(progressPercentage);
      }
    }
  }, [data]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <DragHandle />
      <Card
        bodyStyle={{ padding: 20, height: "100%" }}
        className="shadow-none border-none outline-none h-full"
        loading={loading}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex items-center">
            <Title style={{ margin: 0 }} level={4}>
              Today
            </Title>
            <Badge
              className="ml-3"
              key={"#F39A31"}
              color={"#F39A31"}
              text={moment().format("DD MMMM YYYY")}
            />
          </div>
          <div>
            <div className={styles.outerbox}>
              <SemiCircleProgressBar
                percent={progress}
                progressColor={progress >= 100 ? "#F39A31" : "#24B874"}
              />
              <div className={`${styles.midtext}`}>
                <Popover
                  title="Timeboxed"
                  content={
                    <div>
                      <Text strong className="text-lg">
                        {widgetData.totalTime > 0
                          ? utility.convertMinutesToHHMMSS(widgetData.totalTime)
                          : "00:00:00"}
                      </Text>
                      <Text
                        className="ml-2 p-1"
                        style={{ color: "#377E36", background: "#E0F0E4" }}
                      >
                        Total
                      </Text>
                    </div>
                  }
                >
                  <Title style={{ marginBottom: -2 }} level={3}>
                    {utility.convertMinutesToHHMMSS(widgetData.workedTime)}
                  </Title>
                  <Text style={{ fontSize: 12, color: "#7A7A7A" }}>
                    {widgetData.workedTime > 0
                      ? "You're doing great 🚀"
                      : "Ready to start working"}
                  </Text>
                </Popover>
              </div>
            </div>

            <div
              className="flex items-center justify-between p-3 rounded-md -mt-6"
              style={{ border: "1px solid #F2F2F2" }}
            >
              <Text strong>Time Left</Text>
              <div>
                <Text>Tracking</Text>
                <Divider type="vertical" />
                <Text style={{ color: "#3B2C6E" }}>
                  {widgetData.remainingTime > 0
                    ? utility.convertMinutesToHHMMSS(widgetData.remainingTime)
                    : "00:00:00"}
                </Text>
              </div>
            </div>

            <div className={styles.suggestionBox}>
              <div>
                <div className="flex flex-row">
                  <Text className="text-white text-xs">Suggestion</Text>
                  <Popover className="ml-1" title="Daily Suggestion">
                    <Info className="text-white" size={16} />
                  </Popover>
                </div>
                <div>
                  <Text className="text-white text-xs">
                    Set up gentle reminders at regular intervals, such as every
                    hour or two, to give yourself a break.
                  </Text>
                </div>
              </div>

              <img src={SuggestionSvg} alt="windu-suggestion" />
            </div>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <Badge
              key={"#0CA051"}
              color={"#0CA051"}
              text={<Text className="text-xm"> Total time spent </Text>}
            />
            <Badge
              key={"#8ED89E"}
              color={"#8ED89E"}
              text={<Text className="text-xm">Time left</Text>}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default TodayWidget;
