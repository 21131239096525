import gql from "graphql-tag";

export const CREATE_MONDAY_ACTIVITY = gql`
  mutation ($input: createMondayActivityInput) {
    createMondayActivity(input: $input) {
      _id
      title
      description
    }
  }
`;
