import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { Field, useFormikContext } from "formik";
import { map, find } from "lodash";
import { AntSelect, AntInput } from "components/FormikCustomInputs";
import { searchFields } from "api/Jira";
import { GET_JIRA_RESOURCES } from "graphql/queries/Jira/getJiraResources";
import { useLazyQuery } from "@apollo/client";
import Loader from "components/Loader";

const { Title } = Typography;

const ServerSettingView = ({ token, onRejected, id }) => {
  const [resources, setResources] = useState([]);
  const { values, setFieldValue, submitCount } = useFormikContext();

  const [getJiraResources, { loading }] = useLazyQuery(GET_JIRA_RESOURCES, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,

    variables: { input: { workspace: id, access_token: token } },
    onCompleted: ({ getJiraResources }) => {
      const data = map(getJiraResources, (e) => {
        return { value: e?.id, label: e?.name, data: e };
      });
      setResources(data);
    },
    onError: () => {
      onRejected();
    },
  });

  useEffect(() => {
    getJiraResources({
      variables: { input: { workspace: id, access_token: token } },
    });
  }, []);

  const handleChange = (v) => {
    const resource = find(resources, (e) => e.value === v);

    searchFields(resource?.value, token).then((e) => {
      const findSprintField = find(
        e?.data,
        (field) => field?.name === "Sprint"
      );
      console.log(e?.data);
      console.log(findSprintField);
      setFieldValue("settings", {
        ...values.settings,
        server_name: v,
        server_url: resource?.data?.url,
        sprint_field: findSprintField?.key,
      });
    });
  };

  if (loading) return <Loader size="large" />;

  return (
    <section>
      <Title level={4} style={{ color: "#3C2E94" }}>
        Server Settings
      </Title>
      <Field
        component={AntSelect}
        name="settings.server_name"
        label="Server Name"
        placeholder="Enter Server Name"
        hasFeedback
        required={true}
        size="large"
        selectOptions={resources}
        inputType="select"
        required={true}
        filterOption={false}
        getPopupContainer={(node) => node.parentNode}
        onCallBackChange={handleChange}
        submitCount={submitCount}
      />
      <Field
        component={AntInput}
        name="settings.server_url"
        label="Server URL"
        placeholder="e.g http://www.domain.atlassin.net"
        hasFeedback
        required={true}
        size="large"
        submitCount={submitCount}
      />
    </section>
  );
};
export default ServerSettingView;
