import gql from "graphql-tag";

export const GET_USER_ACTIVITIES = gql`
  query getUserActivities($input: getUserActivitiesInput) {
    getUserActivities(input: $input) {
      data {
        _id
        title
        description
        details
        status
        created_at
        updated_at
        time {
          total_time
          start
          end
          paused {
            time
            continue
          }
        }
        created_by {
          _id
          email
        }
        project {
          _id
          title
        }
      }
      pagination {
        total_data
        total_pages
        current_page
      }
    }
  }
`;
