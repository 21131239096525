import gql from "graphql-tag";

export const REMOVE_COMMENT = gql`
  mutation ($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      _id
      content
    }
  }
`;
