import { Input, Menu, Typography } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";
import { CloseOutlined } from "@ant-design/icons";

const AssigneSelectDropdown = ({
  initialValue,
  setValue: setSelect,
  value: selected,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState(initialValue);
  const [value, setValue] = useState(selected);

  const handleSearch = (v) => {
    setSearchValue(v);
    setFilteredMembers(
      initialValue.filter(
        (member) =>
          new RegExp(v, "i").test(member.full_name) ||
          new RegExp(v, "i").test(member.email)
      )
    );
  };
  useEffect(() => {
    if (!_.isEmpty(value)) {
      setSelect(value);
    }
  }, [value]);

  const handleClick = () => {
    setEditMode(true);
    setValue(null);
  };

  const handleInputBlur = () => {
    setEditMode(false);
  };

  return (
    <div className="bg-gray-200 rounded p-4">
      {editMode ? (
        <Input
          value={searchValue}
          onChange={(v) => handleSearch(v.target.value)}
          onBlur={handleInputBlur}
          autoFocus
          placeholder="Search by name or email"
        />
      ) : (
        <div onClick={handleClick}>
          {value ? (
            <div
              className="flex items-center justify-between p-1 rounded"
              style={{ border: "1px solid #d9d9d9" }}
            >
              <div>
                <UserAvatar user={value} size={24} className="mr-2" />
                <Typography.Text>{value?.email}</Typography.Text>
              </div>
              <CloseOutlined className="ml-2 text-xs" />
            </div>
          ) : (
            <Input
              value={searchValue}
              onChange={(v) => handleSearch(v.target.value)}
            />
          )}
        </div>
      )}

      <Menu className="max-h-48 overflow-y-auto bg-white shadow-none mt-2">
        {_.map(filteredMembers, (user) => (
          <Menu.Item
            style={{ background: user === value && "#F39A31" }}
            key={user?._id}
            onClick={() => setValue(user)}
          >
            <UserAvatar user={user} size={24} className="mr-2" />
            <Typography.Text>{user?.email}</Typography.Text>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default AssigneSelectDropdown;
