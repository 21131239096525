import { LeftSquareOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Radio, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import styles from "../styles.module.css";
import { LIST_ADDONS } from "graphql/queries/Addons/getAddons";
import { useMutation, useQuery } from "@apollo/client";
import Loader from "components/Loader";
import _ from "lodash";
import { CHECKOUT_SESSION } from "graphql/mutations/Stripe/checkoutSession";
import utility from "common/utility";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue } from "recoil";
import { createGoogleEvent } from "api/GoogleAnalytics";
import getSymbolFromCurrency from "currency-symbol-map";
import { getAddOnIcon } from "common/getAddonIcon";
import {
  ANNUAL_DISCOUNT_PERCENTAGE,
  INDIVIDUAL_PLAN,
  INDIVIDUAL_PLAN_AMOUNT,
  TEAM_PLAN,
  TEAM_PLAN_AMOUNT,
} from "constants/plans";
import { UPDATE_SUBSCRIPTION } from "graphql/mutations/Stripe/updateSubscription";
import SubscribedConfirmedModal from "components/Modals/SubscribedConfirmedModal";
import { GET_USER } from "graphql/queries/getUser";

const { Text, Title } = Typography;

const CheckoutView = ({
  selectedPlan,
  setselectedAddons,
  selectedAddons,
  setSelectedPlan,
  userCount,
  rate,
  currency,
  handleViewChange,
  fromOnboarding,
  submitOnboardingDetails,
  isTrialUser,
  isAnnualPlan,
}) => {
  const user = useRecoilValue(userSession);
  const isTrial = selectedPlan?.isTrial;
  const { loading, data } = useQuery(LIST_ADDONS, {
    notifyOnNetworkStatusChange: true,
  });

  const [isVisible, setIsVisible] = useState(false);

  const [checkoutSession, { loading: checkoutLoading }] = useMutation(
    CHECKOUT_SESSION,
    {
      onCompleted: ({ checkoutSession }) => {
        // do not direct if not needed to handle transaction
        window.location = checkoutSession?.url;
      },
      onError: (error) => {},
    }
  );

  const [updatePlan, { loading: updatingPlan }] = useMutation(
    UPDATE_SUBSCRIPTION,
    {
      onCompleted: () => {
        setIsVisible(true);
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_USER],
    }
  );

  const handleSubscribe = () => {
    const data = selectedPlan;
    if (data.name === INDIVIDUAL_PLAN) {
      createGoogleEvent(user?._id, "SUBSCRIPTION_CHECKOUT", "app_engagement");
      checkoutSession({
        variables: {
          input: {
            plan: data?._id,
            trialData: {
              isTrial: isTrial || isTrialUser,
              days: 14,
            },
            addons: selectedAddons.map((item) => item?._id),
            fromOnboarding: fromOnboarding ? true : false,
            isAnnualPlan,
          },
        },
      });
    } else {
      if (user?.extra?.uniqueProjectsParticipants.length > userCount) {
        utility.setNotification(
          "User limit exceed.",
          "Please set more users to your current plan or remove members to match the limit.",
          "warning",
          "topRight"
        );
      } else {
        createGoogleEvent(user?._id, "SUBSCRIPTION_CHECKOUT", "app_engagement");
        checkoutSession({
          variables: {
            input: {
              plan: data?._id,
              userCount: Number(userCount) + 1,
              trialData: {
                isTrial: isTrial || isTrialUser,
                days: 14,
              },
              addons: selectedAddons.map((item) => item?._id),
              fromOnboarding: fromOnboarding ? true : false,
              isAnnualPlan,
            },
          },
        });
      }
    }

    if (submitOnboardingDetails) {
      submitOnboardingDetails();
    }
  };

  const getTotalAmount = (isAnnualPlan) => {
    if (isAnnualPlan) {
      const addonAmount = _.sum(
        _.map(selectedAddons, (item) => {
          if (item?.period === "Annual") {
            return item.cost / 12;
          } else {
            return item.cost;
          }
        })
      );
      if (selectedPlan?.name === INDIVIDUAL_PLAN) {
        const withoutDiscountPrice = Number(
          (INDIVIDUAL_PLAN_AMOUNT + addonAmount) * rate.toFixed(2)
        ).toFixed(2);
        const discountedPrice = Number(
          withoutDiscountPrice -
            withoutDiscountPrice * (ANNUAL_DISCOUNT_PERCENTAGE / 100)
        ).toFixed(2);
        return discountedPrice;
      } else {
        const withoutDiscountPrice = Number(
          (addonAmount + TEAM_PLAN_AMOUNT + userCount * TEAM_PLAN_AMOUNT) *
            rate.toFixed(2)
        ).toFixed(2);
        const discountedPrice = Number(
          withoutDiscountPrice -
            withoutDiscountPrice * (ANNUAL_DISCOUNT_PERCENTAGE / 100)
        ).toFixed(2);
        return discountedPrice;
      }
    } else {
      const addonAmount = _.sum(
        _.map(selectedAddons, (item) => {
          if (item?.period === "Annual") {
            return item.cost / 12;
          } else {
            return item.cost;
          }
        })
      );
      if (selectedPlan?.name === INDIVIDUAL_PLAN) {
        return Number((8 + addonAmount) * rate.toFixed(2)).toFixed(2);
      } else {
        return Number(
          (addonAmount + 12 + userCount * 12) * rate.toFixed(2)
        ).toFixed(2);
      }
    }
  };

  const handleUpdatePlan = () => {
    if (user?.extra?.uniqueProjectsParticipants.length > userCount) {
      utility.setNotification(
        "User limit exceed.",
        "Please set more users to your current plan or remove members to match the limit.",
        "warning",
        "topRight"
      );
    } else {
      updatePlan({
        variables: {
          input: {
            plan: selectedPlan?._id,
            userCount:
              selectedPlan.name === INDIVIDUAL_PLAN ? 1 : Number(userCount) + 1,
            addons: selectedAddons.map((item) => item?._id),
            isAnnualPlan: isAnnualPlan,
          },
        },
      });
    }
  };

  const getAddonCost = (addon, withoutDiscount) => {
    const addonCost = addon?.cost;

    if (addon.period === "Annual") {
      // Apply the annual discount if withoutDiscount is not specified
      const discountedAddonCost =
        addonCost - addonCost * (ANNUAL_DISCOUNT_PERCENTAGE / 100);
      const totalAnnualAddonCost = discountedAddonCost / 12;

      if (withoutDiscount) {
        return (addonCost / 12).toFixed(2);
      } else {
        return totalAnnualAddonCost.toFixed(2);
      }
    } else {
      return addonCost.toFixed(2);
    }
  };

  const isCheckout =
    _.isEmpty(user?.plan) ||
    user?.settings?.trial?.isActive ||
    user?.settings?.payment_failed ||
    isTrial;

  if (loading) return <Loader />;
  return (
    <div className="h-full" style={{ width: "auto" }}>
      <div>
        <LeftSquareOutlined
          onClick={() => {
            setSelectedPlan(null);
          }}
          style={{ fontSize: 24 }}
        />
      </div>

      <div className="m-2">
        <Alert
          message="By chossing a annual plan you can save upto 25% on your subscription"
          type="info"
        />
      </div>
      {isTrial && (
        <div className="flex flex-col my-4">
          <Typography.Text className="text-xl" strong>
            Customize your Free plan with Add-ons
          </Typography.Text>
          <Typography.Text className="text-base">
            Try for free for 14 days, No Credit Card required*
          </Typography.Text>
        </div>
      )}
      <div
        className="p-4 mt-2"
        style={{ border: "2px solid rgba(0, 0, 0, 0.06)" }}
      >
        <div className="flex items-center justify-between">
          {selectedPlan?.name === INDIVIDUAL_PLAN ? (
            <div>
              <Title level={4}>{`${INDIVIDUAL_PLAN} Plan`}</Title>
              <Text className="text-base">
                {`${INDIVIDUAL_PLAN} Plan and addons`}{" "}
              </Text>
            </div>
          ) : (
            <div>
              <Title level={4}>{`${TEAM_PLAN} Plan`}</Title>
              <Text className="text-base">
                Includes up to {userCount} users and addons
              </Text>
            </div>
          )}

          <div className="flex items-center">
            {isAnnualPlan ? (
              <div>
                <Text
                  className="text-xl text-gray-700"
                  strong
                  delete={isTrial || isTrialUser || isAnnualPlan}
                >
                  {getSymbolFromCurrency(currency)}
                  {getTotalAmount()}
                </Text>
                <Text className="text-2xl ml-2" strong>
                  {getSymbolFromCurrency(currency)}
                  {getTotalAmount(isAnnualPlan)}
                </Text>
              </div>
            ) : (
              <Text className="text-2xl" strong>
                {getSymbolFromCurrency(currency)}
                {getTotalAmount()}
              </Text>
            )}

            <Text className="text-base">/month</Text>
          </div>
        </div>
        <Divider />
        <div>
          <div className="flex items-center ">
            <Text className="text-lg" strong>
              Add-Ons
            </Text>
            <Tooltip
              className="ml-2"
              title="Add-ons are optional extensions that can be added to windu to provide extra features or functionality."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
          <Text className="text-base">
            Choose the perfect add-on for your workspace needs
          </Text>
          <div className="mt-4 p-2">
            {_.map(
              _.filter(data?.getAddons, (addon) => {
                if (isAnnualPlan) {
                  return addon.period === "Annual";
                } else {
                  return addon.period === "Monthly";
                }
              }),
              (addon) => {
                const selected = _.some(selectedAddons, (item) =>
                  _.isEqual(item, addon)
                );
                return (
                  <div
                    className={`flex items-center justify-between cursor-pointer mb-4 p-4 rounded-md ${
                      selected ? styles.addonCardSelected : styles.addonCard
                    } `}
                    onClick={() => {
                      if (selected) {
                        setselectedAddons(
                          _.filter(
                            selectedAddons,
                            (item) => !_.isEqual(item, addon)
                          )
                        );
                      } else {
                        setselectedAddons([...selectedAddons, addon]);
                      }
                    }}
                  >
                    <div className="flex items-center w-1/3">
                      {getAddOnIcon(addon.name, selected)}
                      <Text className="text-base capitalize ml-2" strong>
                        {addon.name}
                      </Text>
                    </div>

                    <div>
                      {isAnnualPlan && (
                        <Text className="mr-1" delete>
                          {" "}
                          ${getAddonCost(addon, true)}{" "}
                        </Text>
                      )}
                      <Text className="text-lg " strong>
                        ${getAddonCost(addon, false)}
                      </Text>
                      <Text>/month</Text>
                    </div>

                    <div>
                      <Radio checked={selected} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {isCheckout || fromOnboarding ? (
        <Button
          size="large"
          className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none mt-4`}
          type="primary"
          onClick={handleSubscribe}
          checkoutLoading
          loading={checkoutLoading}
        >
          {isTrial || isTrialUser
            ? "Continue to Free Trial"
            : " Continue to checkout"}
        </Button>
      ) : (
        <Button
          size="large"
          className={`${styles.blackButton} text-white w-full rounded-lg outline-none border-none mt-4`}
          type="primary"
          onClick={handleUpdatePlan}
          checkoutLoading
          loading={updatingPlan}
        >
          Update the plan
        </Button>
      )}

      {isVisible && (
        <SubscribedConfirmedModal
          onClose={() => {
            setIsVisible(false);
            handleViewChange("profile");
          }}
          isVisible={isVisible}
          text="Congratulations! Your plan has been upgraded! 🎉 Any prorated adjustments for the current billing period will be reflected in your next invoice."
        />
      )}
    </div>
  );
};

export default CheckoutView;
