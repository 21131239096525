import React, { useState } from "react";
import { Select } from "antd";
import { map, debounce, uniqBy } from "lodash";
import { useQuery } from "@apollo/client";
import { GET_MANAGERS } from "graphql/queries/manager/getManagers";
import Loader from "components/Loader";
import cx from "classnames";

const sizePerPage = 5;
const initialOffset = 0;

const ManagerSelect = ({
  onChange,
  value,
  className,
  placeholder,
  onClear,
  initialSearch = "",
}) => {
  const [queryParameters, setQueryParameters] = useState({
    size: sizePerPage,
    offset: initialOffset,
    search: initialSearch,
  });
  const [managers, setManagers] = useState([
    {
      _id: "no assigned",
      email: "No assigned",
    },
  ]);

  const { loading } = useQuery(GET_MANAGERS, {
    fetchPolicy: "cache-and-network",
    variables: { input: queryParameters },
    onCompleted: ({ getManagers }) => {
      setManagers(
        uniqBy([...new Set([...managers, ...getManagers.data])], "_id")
      );
    },
  });

  const onScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setQueryParameters({
        ...queryParameters,
        offset: queryParameters.offset + 10,
      });
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const onSearch = (query) => {
    setManagers([]);
    setQueryParameters({ ...queryParameters, search: query, offset: 0 });
  };

  const delayedQuery = debounce((query) => {
    onSearch(query);
  }, 1000);

  const handleClear = () => {
    onClear();
    setQueryParameters({
      ...queryParameters,
      search: "",
      offset: 0,
    });
  };

  const selectValue = value || [];

  return (
    <Select
      placeholder={placeholder}
      size="large"
      className={cx(className, "w-full")}
      onPopupScroll={onScroll}
      options={map(managers, (manager) => {
        return {
          label: manager.email,
          value: manager._id,
        };
      })}
      showSearch
      value={!loading && selectValue}
      filterOption={false}
      loading={loading}
      allowClear
      onChange={onChange}
      onClear={handleClear}
      onSearch={(value) => delayedQuery(value)}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {loading && (
            <div>
              <Loader size={"small"} />
            </div>
          )}
        </div>
      )}
    />
  );
};

ManagerSelect.defaultProps = {
  onClear: () => {},
};
export default ManagerSelect;
