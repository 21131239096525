import { Divider, Input, Skeleton, Tag, Typography } from "antd";
import { GET_GLOBAL_SEARCH } from "graphql/queries/Search/getGlobalSearch";
import React, { useState, useCallback, useRef, useEffect } from "react"; // Import useRef and useEffect
import { debounce, isEmpty, size } from "lodash";
import { useLazyQuery } from "@apollo/client";
import utility from "common/utility";
import moment from "moment";
import useOpenActivityDrawer from "common/openActivityDrawer";
import {
  LoadingOutlined,
  PaperClipOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styles from "./styles.module.less";
import UserAvatar from "components/UserAvatar";

const { Text } = Typography;
const Search = () => {
  const { openActivityDrawer } = useOpenActivityDrawer();
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, { loading }] = useLazyQuery(GET_GLOBAL_SEARCH, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ globalSearch }) => {
      setSearchResult(globalSearch);
    },
  });

  const delayedSearchMainQuery = useCallback(
    debounce((query) => {
      if (query) {
        setSearchValue(query);
        searchQuery({ variables: { search: query } });
      }
    }, 500),
    [searchQuery]
  );

  const handleInputChange = ({ target }) => {
    delayedSearchMainQuery(target.value);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  }, []);

  const ActivityCard = ({ activity }) => {
    const fromBacklog = !isEmpty(activity?.workspace);
    return (
      <div
        onClick={() =>
          openActivityDrawer({ _id: activity?._id }, true, fromBacklog)
        }
        className="cursor-pointer"
      >
        <div className="flex flex-col my-2">
          <Text className="text-base font-semibold">{activity.title}</Text>
          <div className="flex items-center mt-1">
            {activity.tags?.map((tag) => (
              <Tag className="mr-2">{tag}</Tag>
            ))}
          </div>

          <div className="mt-2">
            <Text>
              {activity?.details && utility.getPlainText(activity.details)}
            </Text>
          </div>
        </div>
        <div>
          <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            Activity created by{" "}
            {activity?.initiated_by
              ? activity.initiated_by?.full_name
              : activity?.created_by?.full_name}
          </Text>
          <UserAvatar
            size="small"
            className="ml-2"
            user={
              activity?.initiated_by
                ? activity.initiated_by
                : activity?.created_by
            }
          />
        </div>

        <div className="flex items-center">
          <Text>{activity?.key}</Text>
          <Divider type="vertical" />
          <Text>{moment(activity?.created_at).format("YYYY-MM-DD")}</Text>
          <Divider type="vertical" />
          <div className="flex items-center">
            <PaperClipOutlined />
            <Text className="ml-1">{size(activity?.fileSources)}</Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-5 bg-white h-full flex flex-col">
      <div>
        <Input
          prefix={
            loading ? (
              <LoadingOutlined style={{ marginRight: 6, color: "#F39A31" }} />
            ) : (
              <SearchOutlined style={{ marginRight: 6, color: "#797F8F" }} />
            )
          }
          ref={inputRef}
          loading={loading}
          onChange={handleInputChange}
          size="large"
          placeholder="Search"
          allowClear
          className={styles.searchInput}
        />
      </div>

      <div>
        {!loading && searchResult.length !== 0 && (
          <div className="mt-4">
            <Text>
              Showing {searchResult.length} results for
              <Text className="text-base"> "{searchValue}"</Text>
            </Text>
          </div>
        )}
      </div>
      <div className="p-2 mt-2" style={{ overflowY: "auto" }}>
        {loading ? (
          <Skeleton active />
        ) : (
          searchResult.map((res, index) => (
            <div key={res._id}>
              <ActivityCard activity={res} />
              {index + 1 !== searchResult.length && <Divider />}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Search;
