import { Modal } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import _ from "lodash";
import { convertToRaw } from "draft-js";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import DuplicateActivites from "./DuplicateActivites";
import NewActivityContent from "./NewActivity";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { useTimer } from "contexts/timer-context";
import { CREATE_ACTIVITY } from "graphql/mutations/Activity/createActivity";
import { userSession } from "recoil/atoms/User/UserSession";
import { useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { map, upperFirst, isEmpty } from "lodash";
import { useMutation } from "@apollo/client";
import utility from "common/utility";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { uploadActivityFiles } from "api/UploadActivityFiles";
import { updateActivityOverview } from "common/cacheUtilities";

const initialFormValues = {
  title: "",
  project: undefined,
  selectedTags: [],
};

const validationSchema = yup.object().shape({
  title: yup.string().trim().required("This field is required"),
  details: yup.string().required("This field is required"),
  project: yup.string().required("This field is required"),
});

const StartActivityModal = ({ isVisible, onClose }) => {
  const [visibleView, setVisibleView] = useState("new");
  const user = useRecoilValue(userSession);
  const setActivity = useSetRecoilState(activeActivity);
  const setRefetchIndicator = useSetRecoilState(refetchIndicator);
  const { startHandler, resetHandler } = useTimer(); // global startHandler from context
  const [duplicateFile, setduplicateFile] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const form = React.useRef();
  const handleOnClose = () => {
    onClose();
  };

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true };
    });
  };

  const [createActivity, { loading: creating }] = useMutation(CREATE_ACTIVITY, {
    onCompleted({ createActivity }) {
      utility.setNotification(
        `${createActivity?.title} started`,
        "You should be able to see your activity timer",
        "success",
        "topRight"
      );
      createGoogleEvent(user?._id, "CREATE_ACTIVITY", "app_engagement");

      setActivity({
        data: createActivity,
        active: true,
      });
      handleRefetchingViews();
      form.current.resetForm();
      onClose();
    },
    onError(error) {
      setActivity({
        active: false,
        data: null,
      });
      resetHandler(); // reset the counter if exists
      utility.setNotification(
        "Something wrong happened",
        `${error?.message}`,
        "error",
        "topRight"
      );
    },
    update: (cache) => updateActivityOverview(cache, "add"),
  });

  const handleDone = async (values) => {
    const { title, details, project, timebox, files, selectedTags } = values;
    const start_value = moment.utc(new Date());
    const contentData = !isEmpty(details)
      ? JSON.stringify(convertToRaw(details?.getCurrentContent()))
      : "";
    const newActivity = {
      created_by: {
        email: user.email,
        full_name: user.full_name,
        _id: user._id,
      },
      time: {
        paused: [],
        start: start_value.format("YYYY-MM-DDTHH:mm:ss[Z]"),
      },
      project: {
        _id: project,
      },
      title,
      details: contentData,
    };

    setActivity({
      active: true,
      data: newActivity,
    });

    startHandler(newActivity?.time?.start);

    let filesObj = [];
    if (files.length > 0) {
      filesObj = await uploadActivityFiles({
        userId: user?._id,
        fileList: files,
        updateProgress: setProgress,
      });
      setProgress(0);
    }
    createActivity({
      variables: {
        input: {
          created_by: user._id,
          title: upperFirst(title),
          details: contentData,
          projectId: project,
          date_start: moment.utc(new Date()),
          tags: selectedTags,
          timebox,
          fileSources: map(filesObj, (file) => file.Location),
        },
      },
    });
  };

  return (
    <Modal
      closable={false}
      onCancel={handleOnClose}
      visible={isVisible}
      width={900}
      footer={null}
      style={{ background: "transparent", boxShadow: "none", border: "none" }}
      bodyStyle={{
        background: "transparent",
        boxShadow: "none",
        border: "none",
        padding: 0,
      }}
      className={styles.activityModalClass}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleDone(values)}
        innerRef={form}
      >
        {() => {
          return (
            <>
              {duplicateFile && (
                <DuplicateActivites
                  setVisibleView={setVisibleView}
                  visibleView={visibleView}
                  onClose={onClose}
                />
              )}

              <NewActivityContent
                setVisibleView={setVisibleView}
                visibleView={visibleView}
                form={form}
                isVisible={isVisible}
                onClose={onClose}
                progress={progress}
                creating={creating}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default StartActivityModal;
