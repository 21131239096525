import React, { useRef } from "react";
import { Button, Card, Typography } from "antd";
import { useLazyQuery } from "@apollo/client";
import { useRecoilValue, useRecoilState } from "recoil";
import { AntSelect } from "components/FormikCustomInputs";
import { chatSelectedState } from "recoil/atoms/Messages/ChatSelected";
import { Field, Form, Formik } from "formik";
import { RELATED_USER_SEARCH } from "graphql/queries/user/relatedUserSearch";
import { workspaceSelected } from "recoil/atoms/Workspaces";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";

const NewChat = ({ setnewChatVisiblity, onDone, newChatVisiblity }) => {
  const workspace = useRecoilValue(workspaceSelected);
  const [chatSelected, setChatSelected] = useRecoilState(chatSelectedState);
  const [relatedUsersList, setRelatedUsersList] = React.useState([]);
  const formRef = useRef();

  const [getUserRelated, { loading: loadingRelatedUsers }] = useLazyQuery(
    RELATED_USER_SEARCH,
    {
      onCompleted: ({ relatedUserSearch }) => {
        setRelatedUsersList(relatedUserSearch);
      },
    }
  );

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  const handleCancel = () => {
    setRelatedUsersList([]);
    formRef.current.resetForm();
    setnewChatVisiblity(false);
  };

  React.useEffect(() => {
    if (!newChatVisiblity) {
      formRef.current.resetForm();
    }
  }, [newChatVisiblity]);

  const handleDone = (values) => {
    const { selectUsers } = values;

    if (_.size(selectUsers) > 0) {
      const participants = _.map(selectUsers, (user) => JSON.parse(user));

      setChatSelected({
        participants,
        workspaceId: workspace._id,
      });

      setRelatedUsersList([]);
      formRef.current.resetForm();
      setnewChatVisiblity(false);
      onDone();
    }
  };

  const delayedRelatedUserSearch = React.useCallback(
    _.debounce((query) => {
      onSearch(query);
    }, 1000),
    []
  );

  const onSearch = (query) => {
    if (query.length > 2)
      getUserRelated({
        variables: {
          input: { project: workspace?.project?._id, search: query },
        },
      });
  };

  return (
    <Card
      className="p-5 bg-white -mt-12"
      style={{ width: "340px", minHeight: "210px", boxShadow: "none" }}
      bodyStyle={{ padding: 4 }}
    >
      <Formik
        initialValues={{ selectUsers: [] }}
        onSubmit={handleDone}
        innerRef={formRef}
      >
        {({ submitCount, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                component={AntSelect}
                name="selectUsers"
                label="Search by email"
                placeholder="Search by email"
                mode="multiple"
                submitCount={submitCount}
                inputType="select"
                hasFeedback
                required={true}
                size="large"
                showSearch
                loading={loadingRelatedUsers}
                onSearch={delayedRelatedUserSearch}
                selectOptions={_.map(relatedUsersList, (user) => {
                  return {
                    value: JSON.stringify(user),
                    label: (
                      <div>
                        <UserAvatar user={user} size={30} className="mr-2" />
                        <Typography.Text>{user?.full_name}</Typography.Text>
                      </div>
                    ),
                    user,
                  };
                })}
                getPopupContainer={(node) => node.parentNode}
                dropdownRender={(menu) => <div>{menu}</div>}
              />
            </Form>
          );
        }}
      </Formik>
      <div className="w-full flex mt-6 justify-around">
        <Button className="px-10" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" className="px-10" onClick={onSubmit}>
          Done
        </Button>
      </div>
    </Card>
  );
};

export default NewChat;
