import React, { useState, useEffect } from "react";
import { Button, Divider, Row } from "antd";
import { uniqBy, flatMap, map, find, first } from "lodash";
import { AntSelect } from "components/FormikCustomInputs";
import { Field, FieldArray, useFormikContext } from "formik";
import SelectProjects from "components/Fields/SelectProjects";
import {
  searchStatuses,
  searchWorkflows,
  searchProjectWorkflowScheme,
} from "api/Jira";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import RemoveSync from "../common/RemoveSync";
import AddSync from "../common/AddSync";
import Loader from "components/Loader";
import InvalidSettings from "./InvalidSettings";

const Sync = ({ token, jiraProjects, onRejected }) => {
  const [loading, setLoading] = useState(true);
  const [projectWorkflows, setProjectWorkflows] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [invalidSettings, setInvalidSettings] = useState(false);
  const [startFinishedTransitions, setStartFinishedTransitions] = useState({});
  const location = useLocation();
  const { id } = queryString.parse(location.search);

  const { values, setFieldValue } = useFormikContext();
  const resource = values?.settings?.server_name;

  useEffect(() => {
    const data = searchWorkflows(resource, token)
      .then((r) => {
        return Promise.all(
          map(r?.data?.values, (e, index) => {
            return new Promise((resolve, reject) => {
              resolve(e);
            });
          })
        );
      })
      .catch((e) => {
        const errorMessageForAdminSettings =
          "Only Jira administrators can access workflows.";
        const errorMessageFromJira = first(e?.response?.data?.errorMessages);
        if (errorMessageFromJira === errorMessageForAdminSettings) {
          setInvalidSettings(true);
        } else {
          onRejected();
        }
      });
    data.then((workflowsData) => {
      setWorkflows(workflowsData);
      return Promise.all(
        map(values?.settings?.sync, (e, index) => {
          searchStatuses(resource, e?.jira_project, token).then((r) => {
            const flatten = flatMap(r?.data, (flatMe) => {
              return flatMe?.statuses;
            });
            const data = uniqBy(flatten, function (e) {
              return e.name;
            });

            const formatData = map(data, (e) => {
              return { label: e?.name, value: e?.id };
            });
            setStatuses((prevState) => ({
              ...prevState,
              [index]: formatData,
            }));

            searchProjectWorkflowScheme(resource, e?.jira_project, token).then(
              (r) => {
                setProjectWorkflows((prevState) => ({
                  ...prevState,
                  [index]: r?.data?.values[0],
                }));

                const selectedProjectWorkflow =
                  r?.data?.values[0]?.workflowScheme?.defaultWorkflow;

                const findProjectTransitions = find(
                  workflowsData,
                  (workflow) => workflow?.id?.name === selectedProjectWorkflow
                );

                const formatData = map(
                  findProjectTransitions?.transitions,
                  (e) => {
                    return { label: e?.name, value: e?.id };
                  }
                );
                setStartFinishedTransitions((prevState) => ({
                  ...prevState,
                  [index]: formatData,
                }));
              }
            );
          });
        })
      ).then(() => {
        setLoading(false);
      });
    });
  }, []);

  const handleChange = (v, index) => {
    // set setFieldValue value for sprint_field for project
    const resource = values?.settings?.server_name;
    setFieldValue(`settings.sync[${index}]`, {
      ...values?.settings?.sync[index],
      jira_project: v,
    });
    searchStatuses(resource, v, token).then((r) => {
      searchProjectWorkflowScheme(resource, v, token).then((ws) => {
        setProjectWorkflows((prevStae) => ({
          ...prevStae,
          [index]: ws?.data?.values[0],
        }));
      });
      ///
      const flatten = flatMap(r?.data, (flatMe) => {
        return flatMe?.statuses;
      });
      const data = uniqBy(flatten, function (e) {
        return e.name;
      });

      const formatData = map(data, (e) => {
        return { label: e?.name, value: e?.id };
      });
      setStatuses((prevStae) => ({ ...prevStae, [index]: formatData }));
    });
  };

  const handlePullPushChange = (v, index) => {
    const selectedProjectWorkflow =
      projectWorkflows[index]?.workflowScheme?.defaultWorkflow;
    const findProjectTransitions = find(
      workflows,
      (workflow) => workflow?.id?.name === selectedProjectWorkflow
    );
    const formatData = map(findProjectTransitions?.transitions, (e) => {
      return { label: e?.name, value: e?.id };
    });

    setStartFinishedTransitions({ [index]: formatData });
  };

  const handlePushStart = (v, i) => {
    const findStatus = find(statuses[i], (status) => status?.value === v);
    const findWorkflowByStatus = find(
      startFinishedTransitions[i],
      (w) => w?.label === findStatus?.label
    );
    setFieldValue(`settings.sync[${i}]`, {
      ...values?.settings?.sync[i],
      column_push_start: {
        status: v,
        transition: findWorkflowByStatus?.value,
      },
    });
  };
  if (loading) return <Loader size="large" />;

  return (
    <>
      {invalidSettings && <InvalidSettings />}
      <FieldArray
        className="m-0"
        name="settings.sync"
        render={(arrayHelpers) => {
          const sync = values?.settings?.sync;
          const isGreaterThanZero = sync?.length > 0;

          return (
            <div>
              {sync && isGreaterThanZero ? (
                sync.map((e, index) => (
                  <>
                    <div
                      className={`flex items-center ${
                        isGreaterThanZero && "mt-5"
                      }`}
                      key={index}
                    >
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.jira_project`}
                        label="Select a Jira Project"
                        placeholder="Project A"
                        selectOptions={jiraProjects}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handleChange(v, index)}
                      />
                      <Divider
                        style={{
                          width: 15,
                          minWidth: 15,
                          borderColor: "#F5A623",
                          marginTop: 60,
                        }}
                        dashed
                      />

                      <SelectProjects
                        size={1000}
                        name={`settings.sync.${index}.windu_project`}
                        label="Select a Project"
                        placeholder="Project B"
                      />
                      <Button
                        style={{
                          fontSize: 22,
                          marginTop: "15px",
                          color: "#644ACB",
                        }}
                        icon={<RemoveSync />}
                        type="link"
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      ></Button>
                    </div>
                    <Row>
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.column_pull`}
                        label="Column to Pull Activities from"
                        placeholder="Select a Column"
                        selectOptions={statuses[index]}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        mode="multiple"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handlePullPushChange(v, index)}
                      />
                    </Row>
                    <Row>
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.column_push_start.status`}
                        label="Column to Push Started Activities"
                        placeholder="Select a Column"
                        selectOptions={statuses[index]}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                        onCallBackChange={(v) => handlePushStart(v, index)}
                      />
                    </Row>
                    <Row>
                      <Field
                        className="m-0"
                        component={AntSelect}
                        name={`settings.sync.${index}.column_push_finished`}
                        label="Column to Push Finished Activities"
                        placeholder="Select a Column"
                        selectOptions={startFinishedTransitions[index]}
                        inputType="select"
                        hasFeedback
                        required={true}
                        size="large"
                        filterOption={false}
                        getPopupContainer={(node) => node.parentNode}
                      />
                    </Row>
                  </>
                ))
              ) : (
                <>
                  <div className="flex items-center">
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].jira_project`}
                      label="Select a Jira Project"
                      placeholder="Project A"
                      selectOptions={jiraProjects}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handleChange(v, 0)}
                    />
                    <Divider
                      style={{
                        width: 15,
                        minWidth: 15,
                        borderColor: "#F5A623",
                        marginTop: 60,
                      }}
                      dashed
                    />

                    <SelectProjects
                      name={`settings.sync[0].windu_project`}
                      label="Select a Project"
                      placeholder="Project B"
                    />
                  </div>
                  <Row>
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].column_pull`}
                      label="Column to Pull Activities from"
                      placeholder="Select a Column"
                      selectOptions={statuses[0]}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handlePullPushChange(v, 0)}
                    />
                  </Row>
                  <Row>
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].column_push_start.status`}
                      label="Column to Push Started Activities"
                      placeholder="Select a Column"
                      selectOptions={statuses[0]}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                      onCallBackChange={(v) => handlePushStart(v, 0)}
                    />
                  </Row>
                  <Row>
                    <Field
                      className="m-0"
                      component={AntSelect}
                      name={`settings.sync[0].column_push_finished`}
                      label="Column to Push Finished Activities"
                      placeholder="Select a Column"
                      selectOptions={startFinishedTransitions[0]}
                      inputType="select"
                      hasFeedback
                      required={true}
                      size="large"
                      filterOption={false}
                      getPopupContainer={(node) => node.parentNode}
                    />
                  </Row>
                </>
              )}
              <Button
                className="mt-3"
                style={{ color: "#644ACB", border: "none", boxShadow: "none" }}
                icon={<AddSync />}
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    jira_project: "",
                    windu_project: "",
                    column_pull: "",
                    column_push_start: { status: "", transition: "" },
                    column_push_finished: "",
                  })
                } // insert an empty string at a position
              >
                Add Sync
              </Button>
            </div>
          );
        }}
      />
    </>
  );
};
export default Sync;
