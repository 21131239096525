import { atom } from "recoil";
import moment from "moment";

export const timelineQueryParameters = atom({
  key: "timelineQueryParameters",
  default: {},
});

export const timelineDrawer = atom({
  key: "timelineDrawer",
  default: false,
});
