import {
  Button,
  DatePicker,
  Modal,
  Select,
  Typography,
  Form as AntForm,
  Popover,
  Spin,
  Tag,
} from "antd";
import UserAvatar from "components/UserAvatar";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_WHOLE_TEAM } from "graphql/queries/member/getWholeTeam";
import Loader from "components/Loader";
import { useLazyQuery, useMutation } from "@apollo/client";
import _, { map, unionBy, debounce, isEmpty } from "lodash";
import utility from "common/utility";
import moment from "moment";
import { HANDOFF_ACTIVITY_RESPONSE } from "graphql/mutations/Activity/handoffActivityResponse";
import { DISMISS_NOTIFICATION } from "graphql/mutations/notification/dismissNotification";
import { GET_NOTIFICATIONS } from "graphql/queries/notification/getNotification";
import { TimeBoxField } from "components/CustomComponents/TimeboxInput";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { GET_USER_FREE_TIME } from "graphql/queries/timebox/getUserFreeTime";
import useOpenActivityDrawer from "common/openActivityDrawer";
import { GET_SELECTED_ACTIVITY } from "graphql/queries/Activity/getSelectedActivity";
import { GET_PROJECT_TAGS } from "graphql/queries/projects/getProjectTags";
import styled from "./styles.module.css";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";
import { GET_PLAN_CALENDAR_ACTIVITY } from "graphql/queries/Calendar/getPlanCalendarActivity";

const sizePerPage = 10;
const initialOffset = 0;

const HandoffResponse = ({
  isVisible,
  setHandoffModal,
  selectedHandoffActivity,
  selectedNotification,
  notificationRefectch,
}) => {
  const user = useRecoilValue(userSession);
  const [size] = useState(sizePerPage);
  const [offset, setoffset] = useState(initialOffset);
  const [members, setMembers] = useState([]);
  const [selectedMember, setselectedMember] = useState();
  const [search, setSearch] = useState("");
  const [selectedOption, setselectedOption] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [userFreetime, setUserFreetime] = useState("180");
  const [timebox, setTimebox] = useState("00:00:00");
  const [selectedTags, setSelectedTags] = useState([]);
  const [projectTags, setProjectTags] = useState([]);

  const { openActivityDrawer } = useOpenActivityDrawer();
  const [getMembers, { loading }] = useLazyQuery(GET_WHOLE_TEAM, {
    variables: {
      input: {
        size,
        offset,
        search,
      },
    },
    onCompleted: (data) => {
      const filterData = _.filter(
        data?.getWholeTeam?.data,
        (item) => item._id !== user._id
      );

      setMembers(unionBy([], filterData, "_id"));
    },
  });

  const [getActivity, { loading: getActivityLoading }] = useLazyQuery(
    GET_SELECTED_ACTIVITY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getSelectedActivity }) => {
        if (getSelectedActivity) {
          setSelectedTags(getSelectedActivity?.tags);
          getProjectTags({
            variables: {
              input: { projectId: getSelectedActivity?.project?._id },
            },
          });
        }
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const [getProjectTags, { loading: projectTagLoading }] = useLazyQuery(
    GET_PROJECT_TAGS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ getProjectTags }) => {
        setProjectTags(getProjectTags?.tags);
      },
    }
  );

  useEffect(() => {
    if (selectedOption === "accpect") {
      getActivity({
        variables: {
          activityId: JSON.parse(selectedHandoffActivity)?.activityId,
        },
      });
    }
  }, [selectedOption]);

  const [sendResponse, { loading: sendResponseLoading }] = useMutation(
    HANDOFF_ACTIVITY_RESPONSE,
    {
      onCompleted: (data) => {
        utility.setNotification(
          "Success",
          `Activity Request accpected`,
          "success",
          "topRight"
        );
        setHandoffModal(false);
        dismissNotification({ variables: { id: selectedNotification._id } });
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_PLAN_CALENDAR_ACTIVITY, GET_TODAY_WIDGET_DATA],
    }
  );

  const [getUserFreeTime, { loading: freeTimeloading }] = useLazyQuery(
    GET_USER_FREE_TIME,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          userId: user._id,
          date: selectedDate ? selectedDate : moment().format(),
        },
      },
      onCompleted: ({ getUserFreeTime }) => {
        setUserFreetime(getUserFreeTime);
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const delayedQuery = React.useCallback(
    debounce((query) => {
      setSearch(query);
    }, 1000),
    []
  );
  useEffect(() => {
    getMembers();
  }, [search]);

  const handleHandOff = () => {
    if (selectedOption === "accpect") {
      sendResponse({
        variables: {
          input: {
            activityId: JSON.parse(selectedHandoffActivity)?.activityId,
            response: "accpect",
            planned_date: selectedDate.format(),
            timebox,
            tags: selectedTags,
          },
        },
      });
    } else {
      sendResponse({
        variables: {
          input: {
            activityId: JSON.parse(selectedHandoffActivity)?.activityId,
            response: "rejected",
            member: selectedMember,
            planned_date: moment().format(),
          },
        },
      });
    }
  };

  const [dismissNotification] = useMutation(DISMISS_NOTIFICATION, {
    onCompleted: () => {
      notificationRefectch();
    },
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
      },
    ],
  });

  useEffect(() => {
    getUserFreeTime();
  }, [selectedDate]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <Modal
      visible={isVisible}
      title="Accept or Reject Hand Off"
      width={600}
      closable
      onCancel={() => setHandoffModal(false)}
      footer={null}
    >
      <div>
        <div className="w-full flex flex-col justify-center items-center">
          <UserAvatar user={user} size="large" />
          <Typography.Text className="m-2" strong>
            {user.full_name}
          </Typography.Text>
        </div>
        <div>
          <Button
            className="w-full text-white"
            style={{ background: "#967DDE" }}
            onClick={() =>
              openActivityDrawer(
                { _id: JSON.parse(selectedHandoffActivity)?.activityId },
                true
              )
            }
          >
            View Activity
          </Button>
        </div>
        <div
          className="flex mt-3  bg-gray-50 w-full p-4 flex-col rounded cursor-pointer"
          style={{
            border: selectedOption === "accpect" && "2px solid #F5A623 ",
          }}
          onClick={() => setselectedOption("accpect")}
        >
          <div className="flex flex-col">
            <Typography.Text strong className="text-lg">
              Accept
            </Typography.Text>
            <Typography.Text className="mt-2">
              Accept this activity and plan a day to work on it.
            </Typography.Text>
          </div>
          {selectedOption === "accpect" && (
            <div>
              <div className="py-2 mx-10 mt-2">
                <DatePicker
                  value={selectedDate}
                  className="w-full"
                  onChange={(v) => setSelectedDate(v)}
                  format="MM-DD-YYYY"
                  size="large"
                  disabledDate={(d) => !d || d.isBefore(moment())}
                />
              </div>
              {!_.isEmpty(projectTags) && (
                <div className={styled.radioButtonStyles}>
                  <AntForm.Item labelCol={{ span: 24 }} label="Tags">
                    <Spin
                      spinning={projectTagLoading}
                      indicator={<LoadingOutlined spin />}
                    >
                      {map(projectTags, (tag) => {
                        return (
                          <Tag.CheckableTag
                            key={tag}
                            checked={selectedTags?.indexOf(tag) > -1}
                            onChange={(checked) => handleChange(tag, checked)}
                            className="text-base"
                          >
                            {tag}
                          </Tag.CheckableTag>
                        );
                      })}
                    </Spin>
                  </AntForm.Item>
                </div>
              )}

              <div>
                <AntForm.Item
                  labelCol={{ span: 24 }}
                  label={
                    <div>
                      <Typography.Text className="mr-2">
                        Timebox
                      </Typography.Text>
                      <Popover
                        content={
                          <div className="mr-2 w-96">
                            <Typography.Text>
                              Our Time-Boxing technology helps make suggestions
                              for a user's workload, with rules, by analyzing a
                              user's behavior, and workload. This helps to
                              determine when work time begins affecting health,
                              which is where the chances of burnout increase.
                            </Typography.Text>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </div>
                  }
                >
                  <Spin
                    spinning={freeTimeloading}
                    indicator={<LoadingOutlined spin />}
                  >
                    <TimeBoxField
                      freeTime={userFreetime}
                      onChange={(v) => setTimebox(v)}
                    />
                  </Spin>
                </AntForm.Item>
              </div>
            </div>
          )}
        </div>
        <div
          className="flex bg-gray-50 w-full p-4 flex-col mt-5 rounded cursor-pointer"
          style={{
            border: selectedOption === "rejected" && "2px solid #F5A623 ",
          }}
          onClick={() => setselectedOption("rejected")}
        >
          <div className="flex flex-col">
            <Typography.Text strong className="text-lg">
              Reject
            </Typography.Text>
            <Typography.Text className="mt-2">
              Take a pass on this activity and assign it back.
            </Typography.Text>
          </div>
          {selectedOption === "rejected" && (
            <div className="py-2 mx-8 mt-2">
              <Select
                showSearch
                placeholder="Select a person"
                size="large"
                className="w-full"
                onChange={(v) => setselectedMember(v)}
                options={_.map(members, (user) => {
                  return {
                    value: user._id,
                    label: (
                      <div>
                        <UserAvatar user={user} size={30} className="mr-2" />
                        <Typography.Text>{user?.full_name}</Typography.Text>
                      </div>
                    ),
                    user,
                  };
                })}
                allowClear
                onSearch={(v) => delayedQuery(v)}
              />
            </div>
          )}
        </div>
        <div className="w-full flex items-center mt-5 justify-center">
          <Button
            type="primary"
            size="large"
            onClick={() => handleHandOff()}
            disabled={
              isEmpty(selectedOption) ||
              (selectedOption === "rejected" && isEmpty(selectedMember))
            }
            loading={sendResponseLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default HandoffResponse;
