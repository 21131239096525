import React from "react";
import { Typography, Tag, Row, Col, Tooltip } from "antd";
import UserAvatar from "components/UserAvatar";
import moment from "moment";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import useOpenActivityDrawer from "common/openActivityDrawer";
import utility from "common/utility";
import _ from "lodash";

const { Title, Text } = Typography;

const ActivityFeedCard = ({ activity }) => {
  const {
    title,
    description,
    details,
    project,
    created_by,
    time: { total_time, range, day_duration },
    activity_type,
  } = activity;
  const { openActivityDrawer } = useOpenActivityDrawer();
  return (
    <Row>
      <Col lg={1}>
        <div style={{ height: "100%", background: "#F5A623" }} />
      </Col>
      <Col lg={23} className="pl-4">
        <Row>
          <Col
            className="cursor-pointer"
            onClick={() => openActivityDrawer(activity, true)}
            lg={18}
          >
            <Title level={5}>{title || ""}</Title>
          </Col>
          <Col lg={6}>
            <Tooltip placement="top" title={created_by?.email}>
              <UserAvatar
                size={35}
                user={created_by}
                className="cursor-pointer"
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col lg={24} className="mb-3">
            <Text style={{ color: "#4E35C2" }}>
              {description
                ? description
                : _.truncate(utility.getPlainText(details), {
                    length: 80,
                    omission: "...",
                  })}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {project && (
              <Tag
                color={`${
                  project.color && project.color !== "none"
                    ? project.color
                    : "#808080"
                }`}
              >
                {project?.title}
              </Tag>
            )}
          </Col>
          <Col lg={12}>
            <div className="flex items-center">
              <div className="ml-2">
                {activity_type && getIntegrationLogo(activity_type)}
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
        {day_duration && (
          <Row>
            <Col lg={24}>
              <div className="flex items-center justify-between pt-4">
                <Text>{`Total active time: ${moment(
                  day_duration,
                  "HH:mm:ss"
                ).format("HH:mm:ss")}`}</Text>
              </div>
            </Col>
          </Row>
        )}
        {!day_duration && (
          <Row>
            <Col lg={24}>
              <div className="flex items-center justify-between pt-4">
                <Text>{`Total active time: ${total_time}`}</Text>
              </div>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ActivityFeedCard;
