import React, { useState } from "react";
import PropTypes from "prop-types";
import { Calendar } from "react-date-range";
import { colors, Icon } from "@fishyvisions/windu-uikit";
import { Popover } from "antd";

const CalendarPicker = ({ initialDate, onDateChange, label }) => {
  const [date, setDate] = useState(new Date(initialDate));

  const handleDateChange = (date) => {
    setDate(date);
    onDateChange(date);
  };
  return (
    <Popover
      placement="bottomRight"
      content={
        <Calendar
          color={colors.primary}
          date={date}
          onChange={(date) => handleDateChange(date)}
          minDate={new Date()}
        />
      }
      trigger="click"
    >
      <div className="flex items-center cursor-pointer">
        <Icon className="cursor-pointer" type="Calendar" size="small" />
        {label && <div className="pl-3">{label}</div>}
      </div>
    </Popover>
  );
};

CalendarPicker.propTypes = {
  initialDate: PropTypes.string,
  onDateChange: PropTypes.func,
};
export default CalendarPicker;
