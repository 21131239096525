import React, { useState } from "react";
import { Table, Typography, Tooltip, Badge, Checkbox } from "antd";
import { Icon } from "@fishyvisions/windu-uikit";
import { map, filter, isEmpty } from "lodash";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { times } from "common/constants";
import ActivityFeedCard from "components/ActivityFeedCard";
import Loader from "components/Loader";
import ActivityDateCard from "components/ActivityDateCard";
import RenderActivityBar from "./RenderActivityBar";
import { TIMELINE_CELL_WIDTH } from "common/constants";
import { userSession } from "recoil/atoms/User/UserSession";
import styles from "./styles.module.css";
import EmptyData from "../EmptyData";

const { Text } = Typography;

const ActivityFeed = ({ data, meta, loading }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const user = useRecoilValue(userSession);
  const { timezone } = user;

  const activityContent = () => {
    return times.map((time) => {
      return {
        title: () => (
          <div className="inline-flex">
            <Text>
              {moment(time.number, "HH").format("h")}
              {time.beforeNoon ? "AM" : "PM"}
            </Text>
          </div>
        ),
        width: `${TIMELINE_CELL_WIDTH}px`,
        render: (data) => {
          return (
            <RenderActivityBar
              data={data}
              row={data?.date}
              column={time?.number}
              timezone={timezone}
            />
          );
        },
      };
    });
  };

  const handleExpandAllRows = (e) => {
    const {
      target: { checked },
    } = e;

    if (checked) {
      const keys = map(data, (data) => data.date);
      setExpandedKeys(keys);
    } else {
      setExpandedKeys([]);
    }
  };

  const handleExpandRow = (record, e, expanded) => {
    if (!expanded) {
      setExpandedKeys([...expandedKeys, record.date]);
    } else {
      setExpandedKeys(filter(expandedKeys, (key) => key != record.date));
    }
  };

  const columns = [
    {
      width: 300,
      fixed: true,
      className: styles.columnCard,
      title: () => {
        return (
          <div className="flex w-full items-center justify-between">
            <Checkbox onChange={handleExpandAllRows}>Expand All</Checkbox>
            <Badge
              style={{ backgroundColor: "#52c41a" }}
              count={meta?.total_time}
            ></Badge>
          </div>
        );
      },
      render: (renderData, _, i) => {
        const monthName =
          i === 0
            ? moment(renderData.date)?.format("MMMM")
            : moment(renderData.date)?.format("MMMM") !==
                moment(data[i - 1]?.date).format("MMMM") &&
              moment(renderData.date)?.format("MMMM");
        if (renderData?.title) {
          return <ActivityFeedCard activity={renderData} />;
        }
        return <ActivityDateCard data={renderData} monthName={monthName} />;
      },
    },
    ...activityContent(),
  ];

  const tableLoading = {
    spinning: loading,
    indicator: <Loader size="large" />,
  };

  return (
    <Table
      locale={{
        emptyText: <EmptyData />,
      }}
      rowKey="date"
      childrenColumnName="children"
      className={`${styles.parentTable} ${styles.timelineTable}`}
      loading={tableLoading}
      pagination={false}
      columns={columns}
      dataSource={data}
      scroll={{ x: 2000, y: window.innerHeight - 250 }}
      expandable={{
        expandedRowKeys: expandedKeys,
        expandIcon: ({ expanded, _, record }) => {
          if (isEmpty(record?.children)) {
            return <span className="hidden"></span>;
          }
          if (expanded && record?.children) {
            return (
              <span
                className="cursor-pointer absolute inset-y-0 flex items-center"
                style={{ right: "2em" }}
                onClick={(e) => handleExpandRow(record, e, expanded)}
              >
                <Icon size="small" type="Down" />
              </span>
            );
          }
          if (!expanded && record?.children) {
            return (
              <Tooltip placement="right" title={"More details"}>
                <span
                  className="cursor-pointer absolute inset-y-0 flex items-center"
                  style={{ right: "2em" }}
                  onClick={(e) => handleExpandRow(record, e, expanded)}
                >
                  <Icon size="small" type="Right" />
                </span>
              </Tooltip>
            );
          }

          return <span className="hidden"></span>;
        },
      }}
    />
  );
};

export default ActivityFeed;
