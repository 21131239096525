import { Button, Modal, Select, Typography } from "antd";
import UserAvatar from "components/UserAvatar";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import { GET_WHOLE_TEAM } from "graphql/queries/member/getWholeTeam";
import Loader from "components/Loader";
import { useLazyQuery, useMutation } from "@apollo/client";
import _, { map, unionBy, debounce, isEmpty } from "lodash";
import utility from "common/utility";
import { HANDOFF_ACTIVITY } from "graphql/mutations/Activity/handoffActivity";
import moment from "moment";
import { GET_RECENT_ACTIVITY } from "graphql/queries/Activity/getRecentActivity";

const sizePerPage = 10;
const initialOffset = 0;

const ActivityHandoffModal = ({
  isVisible,
  setHandoffModal,
  selectedHandoffActivity,
  callback,
  onSelection,
}) => {
  const user = useRecoilValue(userSession);
  const [size] = useState(sizePerPage);
  const [offset, setoffset] = useState(initialOffset);
  const [members, setMembers] = useState([]);
  const [selectedMember, setselectedMember] = useState();
  const [search, setSearch] = useState("");

  const totalPauses = map(
    selectedHandoffActivity?.time?.paused,
    (pauses) => pauses.total
  );
  const handoffTime = moment();

  const timeSpent = utility.calculateTimeTotal(totalPauses);

  const [getMembers, { loading }] = useLazyQuery(GET_WHOLE_TEAM, {
    variables: {
      input: {
        size,
        offset,
        search,
        project: selectedHandoffActivity?.project._id,
      },
    },
    onCompleted: (data) => {
      const filterData = _.filter(
        data?.getWholeTeam?.data,
        (item) => item._id !== user._id
      );

      setMembers(unionBy([], filterData, "_id"));
    },
  });

  const [sendHandoffReq, { loading: handoffLoading }] = useMutation(
    HANDOFF_ACTIVITY,
    {
      onCompleted: (data) => {
        utility.setNotification(
          "Success",
          `Handoff request sent.`,
          "success",
          "topRight"
        );
        setHandoffModal(false);
        if (callback) callback();
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
      refetchQueries: [GET_RECENT_ACTIVITY],
    }
  );

  const delayedQuery = React.useCallback(
    debounce((query) => {
      setSearch(query);
    }, 1000),
    []
  );

  useEffect(() => {
    getMembers();
  }, [search]);

  const handleHandOff = () => {
    sendHandoffReq({
      variables: {
        input: {
          userId: selectedMember,
          activityId: selectedHandoffActivity._id,
          handoffTime: handoffTime.format(),
        },
      },
    });
  };

  return (
    <Modal
      visible={isVisible}
      title="Hand Off"
      width={600}
      closable
      onCancel={() => setHandoffModal(false)}
      footer={null}
    >
      <div>
        <div className="w-full m-2">
          <Typography.Text strong>From</Typography.Text>
          <div className="w-full bg-gray-50 flex justify-between p-6">
            <div className="flex items-center">
              <UserAvatar user={user} />
              <Typography.Text strong className="ml-2">
                {user.full_name}
              </Typography.Text>
            </div>
            <div className="flex flex-col">
              <div>
                <Typography.Text strong style={{ color: "#F5A623" }}>
                  HANDED OFF AT
                </Typography.Text>
                <Typography.Text className="ml-2" style={{ color: "#F5A623" }}>
                  {handoffTime.format("LT")}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text strong>ACTVITY LENGTH</Typography.Text>
                <Typography.Text className="ml-2">{timeSpent}</Typography.Text>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <sapn style={{ height: 70, borderLeft: "2px dashed #F5A623" }}></sapn>
        </div>
        <div className="w-full m-2">
          <Typography.Text strong>To</Typography.Text>
          <div className="w-full bg-gray-50 flex justify-center p-6">
            <Select
              showSearch
              placeholder="Select a person"
              size="large"
              className="w-full"
              onChange={(v) => setselectedMember(v)}
              options={_.map(members, (user) => {
                return {
                  value: user._id,
                  label: (
                    <div>
                      <UserAvatar user={user} size={30} className="mr-2" />
                      <Typography.Text>{user?.full_name}</Typography.Text>
                    </div>
                  ),
                  user,
                };
              })}
              allowClear
              onSearch={(v) => delayedQuery(v)}
            />
          </div>
        </div>
        <div className="w-full flex items-center mt-5 justify-center">
          <Button
            type="primary"
            size="large"
            onClick={() =>
              onSelection ? onSelection({ selectedMember }) : handleHandOff()
            }
            disabled={isEmpty(selectedMember)}
            loading={handoffLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActivityHandoffModal;
