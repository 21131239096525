import { useMutation } from "@apollo/client";
import { Button, Input, Modal, Typography } from "antd";
import utility from "common/utility";
import { ADD_FUNDS_TO_TANGO } from "graphql/mutations/Rewards/addFundsToTango";
import React, { useState } from "react";
import _ from "lodash";

const AddFunds = ({ isVisible, setVisible, refetch }) => {
  const [amount, setAmount] = useState(0);

  const [addFunds, { loading }] = useMutation(ADD_FUNDS_TO_TANGO, {
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    onCompleted: () => {
      refetch();
      utility.setNotification(
        "Success",
        "Funds added successfully",
        "success",
        "topRight"
      );
      setVisible(false);
    },
  });

  const handleSubmit = () => {
    if (amount < 1) {
      utility.setNotification(
        "Something wrong happened",
        "Invalid amount",
        "warning",
        "topRight"
      );
      return;
    } else {
      addFunds({
        variables: {
          input: {
            amount: Number(amount),
            token: isVisible?.token,
          },
        },
      });
    }
  };
  return (
    <Modal
      visible={isVisible}
      title="Add funds"
      width={400}
      closable
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Typography.Text>Amount</Typography.Text>

      <Input
        size="large"
        prefix="$"
        onChange={(e) => setAmount(e.target.value)}
        type="number"
        suffix="USD"
        value={amount}
        className="mt-2"
      />

      <div className="flex items-center justify-end mt-4 ">
        <Button onClick={() => setVisible(false)} size="large">
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          className="ml-4"
          onClick={handleSubmit}
          loading={loading}
        >
          + Add Fund
        </Button>
      </div>
    </Modal>
  );
};

export default AddFunds;
