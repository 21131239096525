import React, { useState } from "react";
import _ from "lodash";
import { Typography, Button } from "antd";
import { getIntegrationLogo } from "common/getIntegrationLogo";
import queryString from "query-string";
import winduLogo from "common/assets/WinduLogo.svg";
import { useRecoilValue } from "recoil";
import { userSession } from "recoil/atoms/User/UserSession";
import UserAvatar from "components/UserAvatar";
import { useMutation } from "@apollo/client";
import { MARK_USER_BY_FIGMA } from "graphql/mutations/Figma/markUserByFigmaPlugin";
import utility from "common/utility";
import { setTimeout } from "worker-timers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const { Text, Title } = Typography;

const AuthAvatarHeader = ({ img }) => {
  return (
    <div className="flex" style={{ width: "200px", height: " 200px" }}>
      {img}
    </div>
  );
};
const Authorize = () => {
  const { app, state, code } = queryString.parse(window.location.search);
  const history = useHistory();
  const user = useRecoilValue(userSession);
  const [slackLoading, setSlackLoading] = useState(false);
  const [markUserByFigmaPlugin, { loading: stopping }] = useMutation(
    MARK_USER_BY_FIGMA,
    {
      onCompleted: () => {
        utility.setNotification(
          "Success!",
          "You are now connected to the figma plugin! This page will close momentarily",
          "success",
          "topRight"
        );
        setTimeout(function () {
          window.close();
          history.push("/");
        }, 5000);
      },
      onError: (error) => {
        const errorMessage = _.get(error, "message", "Internal Error");
        utility.setNotification(
          "Something wrong happened",
          errorMessage,
          "error",
          "topRight"
        );
      },
    }
  );

  const addSlackApp = async () => {
    const BE_URL =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BE === "localhost:8081"
          ? `http://${process.env.REACT_APP_BE}`
          : `https://${process.env.REACT_APP_BE}`
        : `https://${process.env.REACT_APP_BE}`;

    try {
      await axios.get(
        `${BE_URL}/slack/getToken?code=${code}&userId=${user?._id}`
      );

      setSlackLoading(false);
      utility.setNotification(
        "Success!",
        "You have successfully connected to the Slack app. Please refresh the Slack app for the changes to take effect.",
        "success",
        "topRight"
      );

      setTimeout(function () {
        window.close();
        history.push("/");
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 200) {
        // Handle success logic here
        setSlackLoading(false);
        utility.setNotification(
          "Success!",
          "You have successfully connected to the Slack app. Please refresh the Slack app for the changes to take effect.",
          "success",
          "topRight"
        );

        setTimeout(function () {
          window.close();
          history.push("/");
        }, 2000);
      } else {
        // Handle other errors
        setSlackLoading(false);
        const errorMessage =
          error.response?.data?.message ||
          "Something went wrong. Please try again.";
        utility.setNotification("Error", errorMessage, "error", "topRight");
      }
    }
  };

  const handleAccpect = async () => {
    if (app === "figma") {
      markUserByFigmaPlugin({ variables: { id: state } });
    }
    if (app === "slack") {
      setSlackLoading(true);
      await addSlackApp();
    }
  };

  return (
    <div className="flex" style={{ height: "100%" }}>
      <div style={{ width: "600px", margin: "auto", textAlign: "center" }}>
        <UserAvatar size={55} user={user} />
        <Title>Authorize {app}</Title>
        <div className="flex items-center" style={{ justifyContent: "center" }}>
          <AuthAvatarHeader
            img={getIntegrationLogo(app, { width: 75, margin: "auto" })}
          />
          <div className="ml-6 mr-8">
            <Text className="text-4xl"> + </Text>
          </div>
          <AuthAvatarHeader
            user={user}
            img={<img style={{ width: 75, margin: "auto" }} src={winduLogo} />}
          />
        </div>
        <div>
          <Text>
            Accept to give permission to {app} to access your Windu data
          </Text>
        </div>
        <Button
          onClick={() => handleAccpect()}
          className="mt-3"
          loading={slackLoading}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default Authorize;
