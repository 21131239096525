import { CloseOutlined, DownOutlined, TagsOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Collapse, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import moment from "moment";
import _ from "lodash";
import UserAvatar from "components/UserAvatar";

const { Panel } = Collapse;
const { Text } = Typography;

const DuplicateActivites = ({
  setVisibleView,
  visibleView,
  onClose,
  duplicateTasks,
  setEditingItem,
  filteredActivities,
  setFilteredActivities,
  showDuplicateHeader,
}) => {
  const [discardedActivity, setDiscardedActivities] = useState([]);

  const handleOnClose = () => {
    onClose();
  };

  const handleSelect = (task) => {
    setEditingItem({ data: task });
    setVisibleView("new");
  };

  useEffect(() => {
    const filtered = duplicateTasks.filter(
      (item) =>
        !discardedActivity.find((disItem) => disItem._id === item.task._id)
    );
    if (filtered.length === 0) {
      setVisibleView("new");
    }
    setFilteredActivities(filtered);
  }, [duplicateTasks, discardedActivity]);

  const handleDiscard = (task) => {
    const updatedDiscardedActivities = [...discardedActivity, task];
    setDiscardedActivities(updatedDiscardedActivities);
  };

  if (filteredActivities.length === 0) return null;
  if (!showDuplicateHeader) return null;

  return (
    <Card
      title={
        <div>
          Duplicate Activity <Badge count={filteredActivities.length} />
        </div>
      }
      extra={
        visibleView === "new" ? (
          <DownOutlined onClick={() => setVisibleView("duplicate")} />
        ) : (
          <CloseOutlined onClick={handleOnClose} />
        )
      }
      className={visibleView === "new" ? styles.hideBodyStyle : "mb-5"}
    >
      <Collapse>
        {_.map(filteredActivities, (item, index) => {
          return (
            <Panel
              header={
                <div className="flex justify-between items-center ">
                  <div className="flex flex-col ">
                    <Text strong className="text-base">
                      {item?.task?.title}
                    </Text>
                    <div>
                      <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        Activity created by
                      </Text>
                      <UserAvatar
                        className="ml-1.5"
                        size={18}
                        user={item.task.created_by}
                      />
                      <Text
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        className="ml-1.5"
                      >
                        {item.task.created_by.full_name}
                      </Text>
                    </div>
                  </div>

                  <div>
                    <Text className="text-green-500">
                      {item.similarity}% Match
                    </Text>
                  </div>
                </div>
              }
              key={index}
            >
              <div className="flex w-full justify-between">
                <div
                  className=" w-3/4 flex flex-col pl-2  "
                  style={{ borderLeft: "5px solid red" }}
                >
                  <Text style={{ color: "#263238" }}>Description</Text>
                  <Text className="text-black mt-2">
                    {item.task.description}
                  </Text>
                  <div className="mt-2 flex items-center">
                    <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      {moment(item.task.created_at).format(
                        "dddd, MMMM D, YYYY"
                      )}
                    </Text>
                    <div className="flex items-center ml-3">
                      <TagsOutlined />
                      {_.map(item.task?.tags, (tag, index) => {
                        const isLastTag = index === item.task?.tags.length - 1;

                        return (
                          <Text
                            style={{ color: "rgba(0, 0, 0, 0.45)" }}
                            underline={true}
                            className="ml-1"
                          >
                            {tag}
                            {isLastTag ? "" : ", "}
                          </Text>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="flex items-center">
                  <Button onClick={() => handleDiscard(item.task)}>
                    Discard
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={() => handleSelect(item.task)}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </Card>
  );
};

export default DuplicateActivites;
