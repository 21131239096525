import { Button, Modal, Select, Typography } from "antd";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { useMutation } from "@apollo/client";
import { CANCEL_SUBSCRIPTION } from "graphql/mutations/Stripe/cancelSubscription";
import utility from "common/utility";
import _ from "lodash";
import { GET_USER } from "graphql/queries/getUser";
import { createGoogleEvent } from "api/GoogleAnalytics";
import { GET_WORKSPACES } from "graphql/queries/workpace/getWorkspaces";

const CancelSubscriptionModal = ({ visible, onClose, user }) => {
  const [reason, setReason] = useState("");
  const [loading, setloading] = useState(false);

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted: () => {
      onClose();
    },
    onError: (error) => {
      const errorMessage = _.get(error, "message", "Internal Error");
      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
      setloading(false);
    },
    refetchQueries: [GET_USER, GET_WORKSPACES],
  });

  const handleCancelSubscription = () => {
    setloading(true);
    createGoogleEvent(user?._id, "CANCEL_SUBSCRIPTION", "app_engagement");
    cancelSubscription({
      variables: {
        reason: reason,
      },
    });
  };

  return (
    <Modal
      title="Cancel Subscription"
      visible={visible}
      footer={null}
      width={450}
      onCancel={onClose}
    >
      <div className="flex flex-col">
        <Typography.Text className="text-base" strong>
          Are you sure you want to cancel your subscription?
        </Typography.Text>
        <Typography.Text style={{ color: "#808080" }}>
          we recommend transferring project ownership to another team member
          before canceling the subscription.
        </Typography.Text>
      </div>
      <div className={styles.reasonSelect}>
        <div className="my-3">
          <Typography.Text className="text-base">
            Select a reason
          </Typography.Text>
        </div>
        <Select
          size="large"
          className="w-full"
          options={[
            {
              value: "Not satisfied with the app",
              label: "Not satisfied with the app",
            },
            {
              value: "Found a better alternative",
              label: "Found a better alternative",
            },
            { value: "Financial reasons", label: "Financial reasons" },
            { value: "Technical issues", label: "Technical issues" },
            { value: "Lack of features", label: "Lack of features" },
            { value: "Unwanted auto-renewal", label: "Unwanted auto-renewal" },
            { value: "Not using the service", label: "Not using the service" },
            { value: "Other", label: "Other" },
          ]}
          placeholder="Select reason"
          onChange={(e) => setReason(e)}
        />
      </div>

      <div className="flex flex-col">
        <Button
          size="large"
          className={`${styles.redButton} text-white w-full rounded-lg outline-none border-none mt-4`}
          type="primary"
          onClick={() => handleCancelSubscription()}
          loading={loading}
        >
          Cancel Subscription
        </Button>
        <Button
          className="w-full my-2 bg-transparent text-black border-black"
          size="large"
          type="default"
          onClick={onClose}
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
