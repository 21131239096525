import React, { useState, useEffect } from "react";
import Icon from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import { useMutation, useLazyQuery, useSubscription } from "@apollo/client";
import utility from "common/utility";
import { get, has, head, last } from "lodash";
import moment from "moment";
import cx from "classnames";
import { useRecoilState } from "recoil";
import Icons from "common/icons";
import { START_ACTIVITY } from "graphql/subscription/activeUsers/startActivity";
import { STOP_ACTIVITY as STOP_ACTIVITY_SUB } from "graphql/subscription/activeUsers/stopActivity";
import { PAUSE_ACTIVITY } from "graphql/mutations/member/pauseActivity";
import { GET_CURRENT_ACTIVITY } from "graphql/queries/Activity/getCurrentActivity";
import { GET_RECENT_ACTIVITY } from "graphql/queries/Activity/getRecentActivity";
import { GET_PAUSED_ACTIVITY } from "graphql/queries/member/getPausedActivity";
import { activeActivity } from "recoil/atoms/Timer/activity";
import { refetchIndicator } from "recoil/atoms/Refetch/refetchIndicator";
import { userSession } from "recoil/atoms/User/UserSession";
import styles from "./styles.module.css";
import Loader from "components/Loader";
import { useTimer } from "contexts/timer-context";
import useStopActivityHook from "common/useStopActivityHook";
import { tourSteps } from "recoil/atoms/Tour/TourSteps";
import StartActivityModal from "components/Modals/StartActivityModal";
import { GET_TODAY_WIDGET_DATA } from "graphql/queries/Today/getTodayWidgetData";

const Timer = () => {
  const [activity, setActivity] = useRecoilState(activeActivity);
  const [startActivity, setStartActivity] = useState(false);
  const [_, setRefetchIndicator] = useRecoilState(refetchIndicator);
  const [user] = useRecoilState(userSession);
  const { resetHandler, startHandler } = useTimer(); // global timer from context
  const { handleStopTimer, isLoading } = useStopActivityHook();

  const handlingStartActivity = (activity) => {
    if (has(activity, "time.start")) {
      if (head(activity.time?.paused)?.continue !== undefined) {
        const continueAt = last(activity?.time.paused)?.continue;

        const start = moment(new Date(continueAt), "MM/DD/YY HH:mm:ss").format(
          "MM/DD/YY HH:mm:ss"
        );

        startHandler(start);
      } else {
        const start = activity?.time?.start;
        startHandler(start);
      }
      setActivity({
        active: true,
        data: activity,
      });
    }
  };
  const [stepIndex, setStepIndex] = useRecoilState(tourSteps);

  const [getCurrentActivity, { loading, refetch }] = useLazyQuery(
    GET_CURRENT_ACTIVITY,
    {
      onCompleted: ({ getCurrentActivity }) =>
        handlingStartActivity(getCurrentActivity),
    }
  );

  const { data: startActivityData, error: startActivityError } =
    useSubscription(START_ACTIVITY);

  const { data: stopActivityData, error: stopActivityError } =
    useSubscription(STOP_ACTIVITY_SUB);

  useEffect(() => {
    if (startActivityError) console.warn(startActivityError);
    if (startActivityData) {
      const { startActivity } = startActivityData;
      const { created_by } = startActivity;

      if (created_by._id === user._id && !activity.active) {
        handlingStartActivity(startActivity);
        setActivity({
          active: true,
          data: startActivity,
        });
      }

      handleRefetchingViews();
    }
  }, [startActivityError, startActivityData]);

  useEffect(() => {
    if (stopActivityError) console.warn(stopActivityError);
    if (stopActivityData) {
      const { stopActivity } = stopActivityData;

      const { created_by, _id } = stopActivity;
      if (
        created_by._id === user._id &&
        activity.active &&
        _id === activity.data._id
      ) {
        resetHandler();
        setActivity({ data: null, active: false });
      }

      handleRefetchingViews();
    }
  }, [stopActivityError, stopActivityData]);

  useEffect(() => {
    getCurrentActivity();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleRefetchingViews = () => {
    setRefetchIndicator((current) => {
      return { ...current, projects: true, timeline: true };
    });
  };

  const [pauseActivity, { loading: pausing }] = useMutation(PAUSE_ACTIVITY, {
    onCompleted: async ({ pauseActivity }) => {
      utility.setNotification(
        `${pauseActivity?.title} has been paused`,
        "",
        "success",
        "topRight"
      );
      resetHandler();

      if (stepIndex?.step == 7) {
        setStepIndex({ step: stepIndex.step + 1, isAcitve: true });
      }
    },
    onError: (error) => {
      const errorMessage = get(error, "message", "Internal Error");

      utility.setNotification(
        "Something wrong happened",
        errorMessage,
        "error",
        "topRight"
      );
    },
    refetchQueries: [
      GET_PAUSED_ACTIVITY,
      GET_RECENT_ACTIVITY,
      GET_TODAY_WIDGET_DATA,
    ],
  });

  const handleStopTimerFunction = () => {
    handleStopTimer({ activity: activity?.data, endTime: moment.utc() });
  };

  const stopBtn = (
    <Button
      className={cx(styles.timer, styles.timerOn, "tutorial-timer ml-3 pt-1")}
      shape="circle"
      icon={<Icon className={styles.icon} component={Icons["Stop"]} />}
      onClick={() => handleStopTimerFunction()}
    />
  );

  const pauseBtn = (
    <div className="tour-activeTimerPause">
      <Button
        className={cx(styles.timer, styles.timerPause, "pt-3")}
        shape="circle"
        icon={<Icon className={styles.icon} component={Icons["Pause"]} />}
        onClick={() => handlePause()}
      />
    </div>
  );

  const handlePause = () => {
    const time = moment.utc();
    pauseActivity({
      variables: { input: { activity_id: activity?.data?._id, time } },
    });
  };

  const { active } = activity;

  if (loading || pausing || isLoading)
    return (
      <div style={{ height: "65px" }} className="relative">
        <Loader />
      </div>
    );

  return (
    <>
      {active ? (
        <div className="flex items-center">
          <Tooltip placement="top" title="Pause">
            {pauseBtn}
          </Tooltip>
          <Tooltip placement="top" title="Stop">
            {stopBtn}
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center">
          <Tooltip title="Start Activity">
            <Button
              className={cx(styles.timer, styles.timerOff, "tutorial-timer")}
              shape="circle"
              icon={<Icon component={Icons["Play"]} className={styles.icon} />}
              onClick={() => setStartActivity(true)}
            />
          </Tooltip>
        </div>
      )}
      {startActivity && (
        <StartActivityModal
          onClose={() => setStartActivity(false)}
          isVisible={startActivity}
        />
      )}
    </>
  );
};

Timer.propTypes = {};

export default Timer;
